import Handler from "../Handler";

 class TerminatePrivateTableAckHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processTerminatePrivateTableAck(receivedData);
  }
}

export default TerminatePrivateTableAckHandler;
