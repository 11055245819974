import React, { useEffect, useRef, useState } from "react";
import {
  calculateTimeLeft,
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  GameTypeTextAbbr,
  parseDecimalValuesOfNumber,
} from "../../../../../Common/utils/GameUtils";
import BaseGameTileCard from "../../../../components/BaseGameTileCard/BaseGameTileCard";
import multiPlayersIcon from "../../../../../assets/lobby/MultiplePlayersIcon.svg";
import lobbyChip from "../../../../../assets/lobby/lobby-chip.png";
import lobbyStack from "../../../../../assets/lobby/lobby-stack.png";
import tilearrow from "../../../../../assets/lobby/tile-arrow.svg";
import deleteIcon from "../../../../../assets/lobby/deleteIcon.png";
import {
  BonusBarFilled,
  BonusBarWrapper,
  GameTileBottomContent,
  GameTileBottomLeftText,
  GameTileBottomRow,
  GameTileBottomRowContent,
  GameTileName,
  GameTileSectionContainer,
  GameTileSectionDivider,
  GameTileSectionDividerContainer,
  GameTileSectionImage,
  GameTileTopRow,
  GameTileTopRowContent,
  GameTileValue,
  GameTileValueContainer,
  GameType,
  GameTypeIconElement,
  RegCountAndMaxPlayerWrapper,
  RegPlayerCountText,
  RupeePrefix,
  TableOrTemplateName,
} from "./gameTileStyles";
import {
  GAME_STATUS_ACTIVE,
  PRIVATE_TABLE_DELETE_CONFIRMATION,
  PRIVATE_TABLE_GAME_DEF_INFO_SLIDER,
  PRIVATE_TABLE_HOSTING_DETAILS_SLIDER,
  PRIVATE_TABLE_LOBBY,
  PRIVATE_TABLE_TEMPLATE,
} from "../../../../data/Constants";
import { Typo11LightYellowContoMediumText } from "../../../../../Common/styleGuide/Typos";
import { requestPrivateTableinfoProtocol } from "../../../../utils/ProtocolUtils";
import { useDispatch, useSelector } from "react-redux";
import { resetPrivateTableGameDefInfo } from "../../../../redux/slices/lobbySlice";
import { openSlider } from "../../../../redux/slices/sliderSlice";
import { openModal } from "../../../../redux/slices/modalSlice";
export const GameTile = (props) => {
  const { gameDefinition } = props;
  const {
    smallBlind,
    bigBlind,
    maxPlayers,
    gameType,
    minBuyInAmount,
    maxbuyInAmount,
    flavourType,
    gameDefId,
    tableName,
    isTemplate,
    templateId,
    templateName,
    onlinePlayerCount,
    type,
    PTCompletionTime,
  } = gameDefinition;
  const [timeLeft, setTimeLeft] = useState(null);
  const [selectedGameDefId, setselectedGameDefId] = useState(null);
  const { userName, userId } = useSelector((state) => state.lobby.player);
  const accessToken = useSelector((state) => state.lobby.accessToken);
  const isGameInfoRecieved = useSelector(
    (state) => state.lobby.privateTable.isGameInfoRecieved
  );

  const gameDefData = useSelector(
    (state) =>
      state.lobby.privateTable.lobbyData[
        selectedGameDefId?.status === GAME_STATUS_ACTIVE
          ? "activeTables"
          : "completedTables"
      ]?.[[selectedGameDefId?.gameDefId]]
  );

  const sliderStatus = useSelector((state) => state.slider.visible);

  const timeoutRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isGameInfoRecieved && selectedGameDefId && gameDefData) {
      dispatch(
        openSlider({
          type: PRIVATE_TABLE_GAME_DEF_INFO_SLIDER,
          config: { ...gameDefData },
        })
      );
      setselectedGameDefId(null);
      dispatch(resetPrivateTableGameDefInfo());
    }
  }, [isGameInfoRecieved]);

  useEffect(() => {
    if (!sliderStatus) {
      setselectedGameDefId(null);
      dispatch(resetPrivateTableGameDefInfo());
    }
  }, sliderStatus);
  useEffect(() => {
    timeoutRef.current = setInterval(() => {
      setTimeLeft(calculateTimeLeft(PTCompletionTime));
    }, 1000);

    return () => {
      if (timeoutRef && timeoutRef?.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [PTCompletionTime]);

  const handleGameTileClick = (e) => {
    e.stopPropagation();
    if (type === PRIVATE_TABLE_LOBBY) {
      requestPrivateTableinfoProtocol({
        gameDefId: gameDefId,
        playerName: userName,
      });
      setselectedGameDefId(gameDefinition);
    } else if (type === PRIVATE_TABLE_TEMPLATE) {
      dispatch(
        openSlider({
          type: PRIVATE_TABLE_HOSTING_DETAILS_SLIDER,
          config: {
            gameDefinition: gameDefinition,
          },
        })
      );
    } else if (type === PRIVATE_TABLE_GAME_DEF_INFO_SLIDER) {
      return;
    }
  };

  const onDeleteTemplateClick = (e) => {
    e.stopPropagation();
    dispatch(
      openModal({
        type: PRIVATE_TABLE_DELETE_CONFIRMATION,
        config: {
          accessToken: accessToken,
          userId: userId,
          templateId: templateId,
        },
      })
    );
  };

  const formatEndTime = () => {
    if (timeLeft) {
      let formattedTime = "";

      if (timeLeft.hours) {
        formattedTime = String(timeLeft.hours).padStart(2, "0") + "hr : ";
      }
      if (timeLeft.minutes) {
        formattedTime =
          formattedTime + String(timeLeft.minutes).padStart(2, "0") + "min : ";
      }
      if (timeLeft.seconds) {
        formattedTime =
          formattedTime + String(timeLeft.seconds).padStart(2, "0") + "sec ";
      }
      return formattedTime;
    }
  };

  return (
    <BaseGameTileCard
      key={`pt-tab-gdid-${gameDefId}-gameTileCard`}
      gameDefId={gameDefId}
      flavourType={flavourType}
      maxPlayers={maxPlayers}
      gameType={gameType}
      smallBlind={smallBlind}
      bigBlind={bigBlind}
    >
      <GameTileTopRow>
        <GameTileTopRowContent onClick={(e) => handleGameTileClick(e)}>
          <GameTileSectionContainer left={true}>
            <GameTileValueContainer>
              <GameTileSectionImage src={lobbyChip} />
              <GameTileValue>
                {
                  <>
                    {parseDecimalValuesOfNumber(
                      smallBlind,
                      2,
                      checkToDisplayValueInK(smallBlind),
                      checkToDisplayValueInL(smallBlind)
                    )}
                    /
                    {parseDecimalValuesOfNumber(
                      bigBlind,
                      2,
                      checkToDisplayValueInK(bigBlind),
                      checkToDisplayValueInL(bigBlind)
                    )}
                  </>
                }
              </GameTileValue>
            </GameTileValueContainer>
            <GameTileValueContainer>
              <GameTileName>Blinds</GameTileName>
            </GameTileValueContainer>
          </GameTileSectionContainer>
          <GameTileSectionDividerContainer>
            <GameTileSectionDivider
              color={"rgba(9, 81, 99, 1)"}
            ></GameTileSectionDivider>
            <GameTileSectionDivider
              color={"rgba(7, 51, 62, 1)"}
            ></GameTileSectionDivider>
          </GameTileSectionDividerContainer>
          <GameTileSectionContainer>
            <GameTileValueContainer>
              <GameTileSectionImage src={lobbyStack} />
              <GameTileValue>
                <RupeePrefix>₹</RupeePrefix>
                {parseDecimalValuesOfNumber(minBuyInAmount, 2, true)}
              </GameTileValue>
            </GameTileValueContainer>
            <GameTileValueContainer>
              <GameTileName>Min Buy-In</GameTileName>
            </GameTileValueContainer>
          </GameTileSectionContainer>
          <GameTileSectionDividerContainer>
            <GameTileSectionDivider
              color={"rgba(9, 81, 99, 1)"}
            ></GameTileSectionDivider>
            <GameTileSectionDivider
              color={"rgba(7, 51, 62, 1)"}
            ></GameTileSectionDivider>
          </GameTileSectionDividerContainer>
          <GameTileSectionContainer>
            {isTemplate ? (
              <>
                <GameTileValueContainer>
                  <GameTileSectionImage src={lobbyStack} />
                  <GameTileValue>
                    <RupeePrefix>₹</RupeePrefix>
                    {parseDecimalValuesOfNumber(maxbuyInAmount, 2, true)}
                  </GameTileValue>
                </GameTileValueContainer>
                <GameTileValueContainer>
                  <GameTileName>Max Buy-In</GameTileName>
                </GameTileValueContainer>
              </>
            ) : (
              <>
                <RegCountAndMaxPlayerWrapper>
                  <GameTypeIconElement src={multiPlayersIcon} />
                  <RegPlayerCountText>{`${onlinePlayerCount || 0}/${maxPlayers}`}</RegPlayerCountText>
                </RegCountAndMaxPlayerWrapper>
                <>
                  <BonusBarWrapper>
                    <BonusBarFilled
                      fillPercentage={(onlinePlayerCount / maxPlayers) * 100}
                    />
                  </BonusBarWrapper>
                </>
              </>
            )}
          </GameTileSectionContainer>

          <GameTileSectionContainer minWidth={true} right={true}>
            {type !== PRIVATE_TABLE_GAME_DEF_INFO_SLIDER && (
              <GameTypeIconElement src={tilearrow} />
            )}
          </GameTileSectionContainer>
        </GameTileTopRowContent>
      </GameTileTopRow>
      <GameTileBottomRow>
        <GameTileBottomRowContent onClick={(e) => handleGameTileClick(e)}>
          <GameTileBottomContent tableName>
            <TableOrTemplateName
              isTemplateOrActiveGameDef={
                isTemplate || gameDefinition.status === GAME_STATUS_ACTIVE
              }
            >
              {isTemplate
                ? templateName
                : gameDefinition.status === GAME_STATUS_ACTIVE
                ? tableName.split("#")[0]
                : tableName}
            </TableOrTemplateName>
          </GameTileBottomContent>

          {gameDefinition.status === GAME_STATUS_ACTIVE && timeLeft && (
            <GameTileBottomContent endTimeLabel>
              <GameTileBottomLeftText endTimeLabel>
                Ends in : {formatEndTime()}
              </GameTileBottomLeftText>
            </GameTileBottomContent>
          )}
          <GameType gameType={gameType}>{GameTypeTextAbbr(gameType)}</GameType>

          {isTemplate && (
            <GameTileBottomContent
              onClick={(e) => {
                onDeleteTemplateClick(e);
              }}
            >
              <GameTypeIconElement iconType={"delete"} src={deleteIcon} />
            </GameTileBottomContent>
          )}
        </GameTileBottomRowContent>
      </GameTileBottomRow>
    </BaseGameTileCard>
  );
};
