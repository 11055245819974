import { getISTDateTimeFormat } from "../../Common/utils/GameUtils";
import {
  CLIENT_ERROR_EVENT,
  CLIENT_INFO_EVENT,
  CLIENT_LOG_EVENT,
  CLIENT_USER_JOURNEY_EVENT,
} from "../data/Constants";
import { getLobbySlice } from "./ReduxUtils";

const truncateJsonPayload = (payload, maxLength = 1000) => {
  // Stringify the payload
  let stringifiedPayload = JSON.stringify(payload);

  // If the payload is within the allowed length, return it as is
  if (stringifiedPayload.length <= maxLength) {
    return stringifiedPayload;
  }

  // If the stringified payload exceeds the maxLength, truncate it
  stringifiedPayload = stringifiedPayload.slice(0, maxLength);

  // Try to ensure the truncated payload is valid JSON
  try {
    JSON.parse(stringifiedPayload); // Validate the truncated JSON
  } catch (e) {
    // If invalid JSON, try to find the last valid JSON structure (e.g., closing braces)
    let openCount = 0;
    let closeCount = 0;
    for (let i = stringifiedPayload.length - 1; i >= 0; i--) {
      if (stringifiedPayload[i] === "}") closeCount++;
      if (stringifiedPayload[i] === "{") openCount++;
      if (openCount === closeCount) {
        stringifiedPayload = stringifiedPayload.slice(0, i + 1);
        break;
      }
    }
  }

  return stringifiedPayload;
};

export const getClientLogPayload = (payload) => {
  return {
    client: "POKER_REACT",
    channel: getLobbySlice()?.channel,
    version: process.env.REACT_APP_CLIENT_VERSION,
    username: getLobbySlice()?.player?.userName
      ? getLobbySlice()?.player?.userName
      : "NA",
    eventType: CLIENT_LOG_EVENT,
    timeStamp: getISTDateTimeFormat(),
    payload: truncateJsonPayload(payload),
  };
};

export const getClientLogErrorPayload = (payload) => {
  return {
    client: "POKER_REACT",
    channel: getLobbySlice()?.channel,
    version: process.env.REACT_APP_CLIENT_VERSION,
    username: getLobbySlice()?.player?.userName
      ? getLobbySlice()?.player?.userName
      : "NA",
    eventType: CLIENT_ERROR_EVENT,
    timeStamp: getISTDateTimeFormat(),
    payload: truncateJsonPayload(payload),
  };
};

export const getClientLogInfoPayload = (payload) => {
  return {
    client: "POKER_REACT",
    channel: getLobbySlice()?.channel,
    version: process.env.REACT_APP_CLIENT_VERSION,
    username: getLobbySlice()?.player?.userName
      ? getLobbySlice()?.player?.userName
      : "NA",
    eventType: CLIENT_INFO_EVENT,
    timeStamp: getISTDateTimeFormat(),
    payload: truncateJsonPayload(payload),
  };
};

export const getClientLogUserJourneyPayload = (payload) => {
  return {
    client: "POKER_REACT",
    channel: getLobbySlice()?.channel,
    version: process.env.REACT_APP_CLIENT_VERSION,
    username: getLobbySlice()?.player?.userName
      ? getLobbySlice()?.player?.userName
      : "NA",
    eventType: CLIENT_USER_JOURNEY_EVENT,
    timeStamp: getISTDateTimeFormat(),
    payload: truncateJsonPayload(payload),
  };
};
