import React, {
  memo,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
  useTransition,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "react-loading-skeleton/dist/skeleton.css";
import "swiper/css";
import "swiper/css/pagination";

import HomeLayout from "../../../Common/components/HomeLayout/HomeLayout.js";

import goldQuestionMarkIcon from "../../../assets/lobby/GoldQuestionMarkIcon.svg";
import goldBulbIcon from "../../../assets/lobby/GoldBulbIcon.svg";
import leaderboardMenuIcon from "../../../assets/lobby/LeaderboardMenuIcon.svg";
import playGoldIcon from "../../../assets/lobby/PlayGoldIcon.svg";
import goldArrow from "../../../assets/lobby/GoldArrow.svg";
import goldTicket from "../../../assets/lobby/GoldTicket.svg";
import CloseIcon from "../../../assets/lobby/CloseIcon.svg";
import HorizontalDivider from "../../../assets/lobby/HorizontalDivider.svg";
import VerticalDivider from "../../../assets/lobby/TicketVerticalDivider.svg";
import DashedDivider from "../../../assets/lobby/TicketDashedDivider.svg";
import GreenDoneIcon from "../../../assets/lobby/GreenDoneIcon.svg";
import ticketBgImage from "../../../assets/lobby/TicketBackground1x.png";
import happyHoursGoldImg from "../../../assets/lobby/happyHoursGoldIcon.png";
import happyHoursOn from "../../../assets/lobby/toggleOn.png";
import happyHoursOff from "../../../assets/lobby/toggleOff.png";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer.js";
import Tabbar from "../../../Common/components/Tabbar/Tabbar.js";
// import { addLobbyAlert } from "../../redux/slices/lobbySlice";
import {
  handleRemoveDisplayedCashLackGame,
  handleRemoveDisplayedTourneyLackGame,
  initializeLobbyConnection,
  sendLobbyDebugLog,
  updateCashAndFunGamesToggle,
  setIsPlayerJoiningFromLB,
  updateIsLackTourneyGamesDisplayed,
  requestUpdatedTourneyPlayerStatus,
  handleTourneyStart,
  handleClearDynamicTourneyStartData,
  showTourneyMaxTablesLimitReached,
  addLobbyToastMessage,
  updateShowTourneyRegisterOrDeregisterRequestLoading,
  fetchRequestTourneyRegistration,
  clearDeeplink,
  updateTourneyTicketsClaimInProgress,
  resetHomePageData,
  requestTourneyInfoData,
  requestTourneyEntriesDynamicData,
  requestTourneyTablesDynamicData,
  updateShowAllTicketsSection,
  updateLobbyCurrentTab,
  handleDeeplink,
} from "../../redux/slices/lobbySlice.js";
import LobbyGameTilesList from "../../components/LobbyGameTilesList/LobbyGameTilesList.js";
import { FooterTabConstants } from "../../components/Footer/constants.js";
import FullScreenPageContainer from "../../components/FullScreenPageContainer/FullScreenPageContainer.js";
import * as Constants from "../../data/Constants.js";
import TourneyInfo from "../TourneyInfo/TourneyInfo";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Container,
  CarousalWrapper,
  LobbyChildrenWrapper,
  BannerImageWrapper,
  LobbyDetailsContainer,
  LobbyDetailsHeaderWrapper,
  TabbarWrapper,
  GameTilesListSwiperContainer,
  GameTileSwiperSlideWrapper,
  MoreSectionContainer,
  MoreSectionMenuItemsWrapper,
  MoreSectionMenuItem,
  MoreSectionMenuIcon,
  MoreSectionMenuItemText,
  LoadingViewContainer,
  TourneysContainer,
  PromotionContainer,
  TourneysHeaderWrapper,
  PromotionContent,
  PromotionZeroTextDisplay,
  PromotionsBanner,
  TourneysGameTilesListContainer,
  TourneyLobbySkeletonLoadingContainer,
  TourneyTabsContainer,
  TourneyTabItemWrapper,
  TourneyTicketsWrapper,
  TourneyTabItemText,
  GoldArrowIconWrapper,
  TicketsIconWrapper,
  TicketsCountText,
  ModalContainer,
  TicketsModalContainer,
  TicketsHeaderContainer,
  TicketsHeaderText,
  CloseIconWrapper,
  TicketsBodyWrapper,
  TicketsWrapper,
  TicketTourneyDetailsWrapper,
  TourneyDetailsWrapper,
  TicketStatusWrapper,
  TourneyNameText,
  DividerImg,
  TourneyPrizeAndTicketDetailsWrapper,
  TourneyPrizeWrapper,
  TicketDetailLabelText,
  TicketDetailValueText,
  TicketVerticalLine,
  TicketDashedDividerLine,
  TourneyTicketValueWrapper,
  ExpiryText,
  ClaimButtonWrapper,
  ClaimText,
  RegisrationsFullText,
  ClaimedWrapper,
  DoneIconText,
  ExpiredText,
  GamesTabbarWrapper,
  NoTicketAvailableText,
  ShimmerLightingContainer,
  ShimmerThin,
  ShimmerFat,
} from "./styledComponents.js";
import { footerTabConstants } from "./LobbyConstants.js";
import {
  closeGameWindow,
  createTableData,
  hideGameTable,
  openLocalGameModal,
  showGameTable,
  updateActiveGame,
  watchAllGameTables,
} from "../../redux/slices/gameTableSlice.js";
import { closeModal, openModal } from "../../redux/slices/modalSlice.js";
import { messageToNativeClient } from "../../../Common/utils/platformCommunicationUtil.js";
import GameAudio from "../../audio/GameAudio.js";
import Skeleton from "react-loading-skeleton";
import colors from "../../../Common/styleGuide/Colors.js";
import TourneyGameTilesList from "../../components/TourneyGameTilesList/TourneyGameTilesList.js";
import ActiveGamesStickyButton from "./ActiveGamesStickyButton.js";
import LobbyModalComponent from "../../../Common/components/Modals/GlobalModal.js";
import Home from "./Home.js";
import { Toast } from "../../../Common/components/Toast/Toast.js";
import ForceLogoutModal from "../../../Common/components/Modals/ForceLogout/ForceLogoutModal.js";
import { Loader } from "../../components/Loader/Loader.js";
import CleverTapUtil from "../../../Common/utils/CleverTapUtil.js";
import {
  IP_Client_LobbyView,
  IP_Client_Play_now,
  IP_Client_TourneyLobby_View,
  Poker_Redirection_Fail,
  PR_Lobby_Banner,
  PR_Lobby_IPromotions,
  PR_HomeLobby_View,
} from "../../data/ClevertapConstants.js";
import {
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  getCurrentTimeStamp,
  getISTDateTimeFormat,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils.js";

import GameTables from "../Game/GameTables.js";
import { sendLobbyDebugProtocol } from "../../utils/ProtocolUtils.js";
import { closeSlider } from "../../redux/slices/sliderSlice.js";
import LobbyConnection from "../../io/LobbyConnection.js";
import { sendFaroLog } from "../../../Common/utils/FaroUtil.js";
import {
  generateUniqueId,
  returnGameTypeText,
} from "../../../Common/utils/PokerUtils.js";
import useClientLogger from "../../../Common/hooks/useClientLogger.js";
import HappyHoursCashBanner from "../../components/HappyHoursCashBanner/HappyHoursCashBanner.jsx";
import { logCleverTapEvent } from "../../../Common/utils/CleverTapUtilNew.js";
import { MP_POKER_TOURNEY_MY_TICKETS_ICON_CLICKED } from "../../data/MixPanelConstant.js";
import { getEventParams } from "../../utils/MixPanelUtils.js";
// import { getLobbySlice } from "../../utils/ReduxUtils.js";

const PokerLobby = ({}) => {
  const dispatch = useDispatch();
  // const lobby = useSelector((state) => state.lobby);
  const userBalance = useSelector((state) => state.lobby.balance);
  const a23Token = useSelector((state) => state.lobby.a23Token);
  const receivedBalanceResponse = useSelector(
    (state) => state.lobby.receivedBalanceResponse
  );
  const PKNFPromotionalBanners = useSelector(
    (state) => state.lobby.homeData.PKNFPromotionalBanners
  );
  const hideRakeBack = useSelector((state) => state.lobby.hideRakeBack);
  const showLobbyLoading = useSelector((state) => state.lobby.showLobbyLoading);
  const userName = useSelector((state) => state.lobby.player.userName);
  const isWebView = useSelector((state) => state.lobby.isWebView);
  const [selectedGameTypeTab, setSelectedGameTypeTab] = useState(
    Constants.TEXAS_HOLDEM
  );
  const happyHoursGameCount = useSelector(
    (state) => state.lobby.happyHoursGameCount
  );
  const [selectedTourneysTab, setSelectedTourneysTab] = useState(null);
  const [selectedTourneyVariant, setSelectedTourneyVariant] = useState(
    Constants.ALL_TAB
  );
  const isCashGamesToggled = useSelector(
    (state) => state.lobby.isCashGamesToggled
  );
  const isLeaderboardEnabled = useSelector(
    (store) => store.lobby.isLeaderboardEnabled
  );
  const [showFooterMoreSectionModal, setShowFooterMoreSectionModal] =
    useState(false);
  const [openHowToPlayPage, setOpenHowToPlayPage] = useState(false);
  const [openFAQsPage, setOpenFAQsPage] = useState(false);
  const [openQuizPage, setOpenQuizPage] = useState(false);
  const [gameDefinition, setGameDefinition] = useState(null);
  const [tempShowGameTable, setTempShowGameTable] = useState(false);
  const channel = useSelector((state) => state.lobby.channel);
  const currentTab = useSelector((state) => state.lobby.currentTab);
  const [tempTableId, setTempTableId] = useState(null);
  const [creatingNewGame, setCreatingNewGame] = useState(false);
  const [lobbyConnection, setLobbyConnection] = useState(false);
  const [isLackGamesDisplayed, setLackGamesDisplayed] = useState(false);
  const applicationVisibility = useSelector(
    (state) => state.lobby.applicationVisibility
  );
  const activeGameTable = useSelector((state) => state.gameTable.activeGame);
  const activeGames = useSelector((state) => state.gameTable.games);
  const activeGameIds = Object.keys(activeGames);
  const gamesAvailable = useSelector((state) => state.gameTable.gamesAvailable);
  const lackGames = useSelector((state) => state.lobby.lackGames.activeGames);
  const leaderBoardGameDefinition = useSelector(
    (state) => state.lobby.leaderBoardGameDefinition
  );
  const isPlayerJoiningFromLB = useSelector(
    (state) => state.lobby.isPlayerJoiningFromLB
  );
  const deeplinkData = useSelector((state) => state.lobby.deeplinkData);

  const showRedDotOnMyTourneys = useSelector(
    (state) => state.lobby.showRedDotOnMyTourneys
  );
  const tourneyTabs = useSelector((state) => state.lobby.tourneyTabs);
  const updatedTourneyTabs = useSelector(
    (state) => state.lobby.updatedTourneyTabs
  );
  const showTourneyLobbyLoading = useSelector(
    (state) => state.lobby.showTourneyLobbyLoading
  );
  const masterTourneysData = useSelector(
    (state) => state.lobby.tourneys.masterTourneysData
  );
  const masterGameDefinitions = useSelector(
    (state) => state.lobby.masterGameDefinitions
  );

  const privateTableActiveGameDefinitions = useSelector(
    (state) => state.lobby.privateTable.lobbyData.activeTables
  );

  const lackTourneys = useSelector((state) => state.lobby.lackTourneys);
  const dynamicTourneysStartData = useSelector(
    (state) => state.lobby.dynamicTourneysStartData
  );
  const isLackTourneyGamesDisplayed = useSelector(
    (state) => state.lobby.isLackTourneyGamesDisplayed
  );
  const gameVisible = useSelector((state) => state.gameTable.showGameTable);
  const token = useSelector((state) => state.lobby.accessToken);
  const a23TokenValue = useSelector((state) => state.lobby.a23Token);
  const [tileScrollStatus, setTileScrollStatus] = useState(false);
  const lobbyModal = useSelector((state) => state.modal);
  const showTourneyInfoPage = useSelector(
    (state) => state.lobby.showTourneyInfoPage
  );
  const selectedTourneyDetailsId = useSelector(
    (state) => state.lobby.selectedTourneyDetailsId
  );
  const tourneyTickets = useSelector((state) => state.lobby.tourneyTickets);
  const showTourneyRegisterOrDeregisterRequestLoading = useSelector(
    (state) => state.lobby.showTourneyRegisterOrDeregisterRequestLoading
  );
  const showTourneyFairPlayPolicyRestrictionPopup = useSelector(
    (state) => state.lobby.showTourneyFairPlayPolicyRestrictionPopup
  );
  const tourneyTicketsClaimInProgressDetails = useSelector(
    (state) => state.lobby.tourneyTicketsClaimInProgressDetails
  );
  const playerDetails = useSelector((state) => state.lobby.player);
  const tourneyRegisterAPIURL = useSelector(
    (state) => state.lobby.tourneyRegisterAPIURL
  );
  const receivedLobbyData = useSelector(
    (state) => state.lobby.receivedLobbyData
  );
  const receivedTourneyData = useSelector(
    (state) => state.lobby.receivedTourneyData
  );
  const resourceLoadFailure = useSelector(
    (state) => state.lobby.resourceLoadFailure
  );
  const swiperRef = useRef(null);
  const tourneyLobbySwiperRef = useRef(null);
  const [transitionPending, startTransition] = useTransition();
  // const [homeTileRedirectionData, setHomeTileRedirectionData] = useState({
  //   tab: null,
  //   subTab: null,
  // });
  const showAllTicketsSection = useSelector(
    (state) => state.lobby.showAllTicketsSection
  );
  const isHapticsEnabled = useSelector(
    (state) => state.gameTable.tableSettings?.haptics
  );
  const isBlockedHour = useSelector((state) => state.lobby.isBlockedHour);

  const showPrivateTableLobby = useSelector(
    (state) => state.lobby.showPrivateTableLobby
  );
  const { sendLog } = useClientLogger();
  const pushToKyc = useSelector((state) => state.lobby.pushToKyc);
  const userLocationCompliance = useSelector(
    (state) => state.lobby.userLocationCompliance
  );
  const cashGames_banner = useSelector((state) => state.lobby.cashGames_banner);
  const addCash_banner = useSelector((state) => state.lobby.addCash_banner);
  const addCashLimits_banner = useSelector((state) => state.lobby.addCashLimits_banner);
  const promotionsActiveOffers_banner = useSelector((state) => state.lobby.promotionsActiveOffers_banner);
  const missions_banner = useSelector((state) => state.lobby.missions_banner);
  const rewards_banner = useSelector((state) => state.lobby.rewards_banner);
  const location_games = useSelector((state) => state.lobby.location_games);
  const location_promotionsLeaderboard = useSelector((state) => state.lobby.location_promotionsLeaderboard);
  const location_missions = useSelector((state) => state.lobby.location_missions);
  const location_promotionsActiveOffers = useSelector((state) => state.lobby.location_promotionsActiveOffers);
  const location_rewards = useSelector((state) => state.lobby.location_rewards);
  const location_addcash = useSelector((state) => state.lobby.location_addcash);
  const location_addCashLimits = useSelector((state) => state.lobby.location_addCashLimits);

  useEffect(() => {
    if (channel) {
      dispatch(
        updateLobbyCurrentTab({
          selectedTab: Constants.REAL_MONEY_CHANNELS.includes(channel)
            ? FooterTabConstants.home
            : FooterTabConstants.games,
        })
      );
    }
  }, [channel]);

  useEffect(() => {
    if (resourceLoadFailure) {
      dispatch(openModal({ type: Constants.ASSET_ERROR }));
    }
  }, [resourceLoadFailure]);

  useEffect(() => {
    if (receivedBalanceResponse) {
      let timeStamp = getISTDateTimeFormat();
      logCleverTapEvent(
        currentTab === FooterTabConstants.home
          ? PR_HomeLobby_View
          : IP_Client_LobbyView,
        {
          Username: playerDetails.userName,
          Channel: channel,
          "Wallet balance": userBalance.totalBalance,
          Timestamp: timeStamp,
        }
      );

      messageToNativeClient({
        type: "plotlineEvents",
        eventName:
          currentTab === FooterTabConstants.home
            ? "IP_HomeLobbyView"
            : "PR_Client_LobbyView",
        eventProperties: {
          userName: playerDetails.userName,
          channel: channel,
          timeStamp,
        },
      });
    }
  }, [receivedBalanceResponse]);

  // useEffect(() => {
  //   if (currentTab !== Constants.HOME_LOBBY) {
  //     console.log("Resetting the home page data!");
  //     dispatch(resetHomePageData());
  //   }
  // }, [currentTab]);

  useEffect(() => {
    if (
      (currentTab === FooterTabConstants.games ||
        currentTab === FooterTabConstants.home) &&
      playerDetails.userName
    ) {
      let temp = new Date();
      let timeStamp = new Date(temp.getTime() + 330 * 60 * 1000);
      timeStamp = timeStamp.toISOString();
      messageToNativeClient({
        type: "plotlineEvents",
        eventName:
          currentTab === FooterTabConstants.home
            ? "IP_HomeLobbyView"
            : "PR_Client_LobbyView",
        eventProperties: {
          userName: playerDetails.userName,
          channel: channel,
          timeStamp,
        },
      });
    }
  }, [currentTab]);

  useEffect(() => {
    if (applicationVisibility) {
      if (
        lobbyModal.type === Constants.INSUFFICIENT_BALANCE &&
        lobbyModal.visible
      ) {
        dispatch(closeModal());
      }
    }
    sendLobbyDebugProtocol({
      payload: `Change in application visibility,  application is in ${
        applicationVisibility ? "FOREGROUND" : "BACKGROUND"
      }`,
    });
  }, [applicationVisibility]);

  useEffect(() => {
    if (showTourneyFairPlayPolicyRestrictionPopup) {
      dispatch(
        openModal({ type: Constants.TOURNEY_FAIRPLAY_POLICY_RESTRICTION })
      );
    }
  }, [showTourneyFairPlayPolicyRestrictionPopup]);

  // useEffect(() => {
  //   if (swiperRef?.current) {
  //     handleGameTypeTabChange(selectedGameTypeTab);
  //   }
  // }, [tempShowGameTable])
  // Resizing and lobby connection establishment

  //mount useEffect
  useEffect(() => {
    messageToNativeClient({ type: "hideHeader" });
    // setSelectedGameTypeTab(tabbarItemsList[0].value);
    setSelectedTourneysTab(tourneyTabbarItemsList[0].value);

    return () => {
      //TODO: Need to do this change before production release
      //not enabling this lobby socket clean up because during react hot reload scenarios this would re-trigger the useEffects which
      //will result in clean up of connection but not re-establishing it as state value 'lobbyConnection' remains true
      //***** this clean up should be there in the mount useEffect only and not the one with dependencies
      console.log("lobby unmount process.env.NODE_ENV:", process.env.NODE_ENV);
      if (process.env.NODE_ENV === Constants.APP_RUNNING_PRODUCTION_MODE) {
        console.log("At clean up, removing the lobby connection");
        LobbyConnection.getInstance().removeLobbyConnection();
      } else {
        console.log("At clean up, not removing the lobby connection");
      }
    };
  }, []);

  useEffect(() => {
    if (a23TokenValue && a23TokenValue.length > 0 && channel) {
      if (!lobbyConnection) {
        console.log("Initializing lobby connection");
        dispatch(initializeLobbyConnection({ dispatchFunction: dispatch }));
        setLobbyConnection(true);
      } else {
        //not sure whether a case like this even comes in @yashwanth
        console.log(
          "Not initializing the lobby connection even when the a23 token value is there as state value of lobbyConnection is",
          lobbyConnection,
          a23TokenValue
        );
      }
    }
  }, [a23TokenValue]);

  useEffect(() => {
    if (!isLackGamesDisplayed && lackGames?.length > 0) {
      processLackGames(lackGames);
    }
  }, [lackGames]);

  useEffect(() => {
    if (
      !isLackTourneyGamesDisplayed &&
      lackTourneys &&
      lackTourneys.length > 0 &&
      activeGameIds.length < 4
    ) {
      processLackTourneyGames(lackTourneys);
    }
  }, [lackTourneys]);

  //When we received tourney start, based on the active games
  useEffect(() => {
    checkForAvailableTourneyStart();
  }, [dynamicTourneysStartData]);

  // This is to toggle game table visibility based on tempShowGameTable
  useEffect(() => {
    // dispatch(stopGameTableFadeOut());
    if (!tempShowGameTable) {
      dispatch(closeSlider());
      if (gameVisible) {
        dispatch(hideGameTable());
        setCreatingNewGame(false);
      }
    } else {
      if (!gameVisible) {
        dispatch(showGameTable());
      }
      messageToNativeClient({ type: "openSideDrawer", isOpened: false });
    }
  }, [tempShowGameTable]);

  // This is added so that we can toggle game visibility in other components and trigger tempShowGameTable here in lobby
  // Example: Add Game Table Modal can create a new game directly or set tempShowGameTable to false so user can pick new blinds.
  // useEffect(() => {
  //   console.log("Yashwanth logging gameVisible:", gameVisible, getCurrentTimeStamp(), tempShowGameTable);
  //   if (gameVisible) {
  //     if (!tempShowGameTable) {
  //       console.log("Yashwanth logging Game Visible If condition:", getCurrentTimeStamp());
  //       // startTransition(() => {
  //       setTempShowGameTable(true);
  //       // });
  //     }
  //   } else {
  //     console.log("Yashwanth logging Game Visible else condition:", getCurrentTimeStamp());
  //     if (tempShowGameTable) {
  //       console.log("Yashwanth logging Game Visible else condition TRIGGERING TEMP:", getCurrentTimeStamp());
  //       // startTransition(() => {
  //       setTempShowGameTable(false);
  //       // });
  //       if (activeGameIds.length >= 1) {
  //         // dispatch(watchAllGameTables({ dispatchFunction: dispatch }));
  //       }
  //     }
  //   }
  // }, [gameVisible]);
  useEffect(() => {
    sendFaroLog({
      type: `USER SCREEN CHANGE TO ${gameVisible ? `GAME_TABLE ` : " LOBBY"}`,
    });
    if (gameVisible) {
      // if (!tempShowGameTable) {
      // startTransition(() => {
      setTempShowGameTable(true);
      // });
      // }
    } else {
      // if (tempShowGameTable) {
      // startTransition(() => {
      setTempShowGameTable(false);
      // });
      // }
    }
  }, [gameVisible]);

  // useEffect(() => {
  //   console.log("tempShowGameTable Use effect:", tempShowGameTable);
  //   if (!tempShowGameTable) {
  //     console.log("Inside if check gameVisible:", gameVisible);
  //     dispatch(closeSlider());
  //     if (gameVisible) {
  //       dispatch(hideGameTable());
  //       setCreatingNewGame(false);
  //     }
  //   } else {
  //     console.log("ELSE CASE gameVisible:", gameVisible);
  //     if (!gameVisible) {
  //       dispatch(showGameTable());
  //     }
  //     messageToNativeClient({ type: "openSideDrawer", isOpened: false });
  //   }

  //   if(tempShowGameTable && gameVisible){

  //   }

  // }, [tempShowGameTable, gameVisible])

  // This is to stop showing game tables on game deletion
  // useEffect(() => {
  //   // if (Object.keys(activeGames).length === 0) {
  //   //   console.log("Setting to false ANOTHER USE EFFECT");
  //   //   // startTransition(() => {
  //   //   setTempShowGameTable(false);
  //   //   // });
  //   // }
  //   // startTransition(() => {
  //   //Note: Please be careful while invoking any methods without an if check, as this get's invoked multiple times as the dependency is an object
  //   console.log("NUMBER OF ACTIVE GAMES:", activeGameIds.length);
  //   // setTempShowGameTable((activeGameIds.length >= 1 && gameVisible) ? true : false);
  //   // });
  // }, [activeGameIds]);

  useEffect(() => {
    if (currentTab === footerTabConstants.tourneys && !isPlayerJoiningFromLB) {
      if (
        deeplinkData?.Location === Constants.TOURNEY_LOBBY ||
        deeplinkData?.Location === Constants.TOURNEY_REGISTRATION
      ) {
        deeplinkData?.Location === Constants.TOURNEY_LOBBY &&
          dispatch(clearDeeplink());
      } else {
        // setSelectedTourneyVariant(getTourneyVariantTabbbarItems()[0]?.value);
      }
    }
  }, [selectedTourneysTab, currentTab]);

  useEffect(() => {
    if (Constants.FUN_CHANNELS.includes(channel)) {
      dispatch(clearDeeplink());
      return;
    }
    if (playerDetails?.userName !== "" && token !== "") {
      // let location = deeplinkData?.Location?.trim();
      // console.log("Anvita redirection", deeplinkData?.Location);
      let location =
        deeplinkData?.Location.indexOf(" ") >= 0
          ? deeplinkData?.Location.split(" ").join("").toLowerCase()
          : deeplinkData?.Location?.toLowerCase().trim();
      let restrictedFeatures = [
        Constants.POKER_LEADERBOARD.toLowerCase(),
        Constants.RAKEBACK_PAGE.toLowerCase(),
        Constants.MISSIONS_PAGE.toLowerCase(),
        // Constants.TOURNEY_LOBBY.toLowerCase(),
        Constants.TOURNEY_REGISTRATION.toLowerCase(),
        Constants.MYTICKET.toLowerCase(),
        Constants.GAME_TABLE.toLowerCase(),
        "buychips",
        "openaddcash",
        "addcash",
        // Constants.ACTIVE_OFFERS.toLowerCase(),
      ];
      // if (location === Constants.GAME_TABLE.toLowerCase()) {
      //   dispatch(openModal({ type: Constants.RESTRICTED_LOCATION_HOURS }));
      //   dispatch(clearDeeplink());
      // }
      // if (
      //   !playerDetails.isAllowLocation &&
      //   restrictedFeatures.includes(location?.toLowerCase())
      // ) {
      //   if (pushToKyc && cashGames_banner) {
      //     messageToNativeClient({
      //       type: "showKyc",
      //       from: "deeplink_" + location?.toLowerCase(),
      //       show: true,
      //     });
      //   }
      //   else if (userLocationCompliance && !location_games) {
      //     dispatch(
      //       openModal({
      //         type: Constants.RESTRICTED_LOCATION_HOURS,
      //       })
      //     );
      //   }
      //   else {
      //     dispatch(openModal({ type: Constants.RESTRICTED_LOCATION }));
      //     dispatch(clearDeeplink());
      //   }
      // } 
      // else if (
      //   pushToKyc &&
      //   cashGames_banner &&
      //   restrictedFeatures.includes(location?.toLowerCase())
      // ) {
      //   messageToNativeClient({
      //     type: "showKyc",
      //     from: "deeplink_" + location?.toLowerCase(),
      //     show: true,
      //   });
      // } 
      // if (
      //   !playerDetails.isAllowLocation &&
      //   restrictedFeatures.includes(location?.toLowerCase()) &&
      //   !userLocationCompliance
      // ) {
      //   dispatch(openModal({ type: Constants.RESTRICTED_LOCATION }));
      //   dispatch(clearDeeplink());
      // }
      // else 
      // {
        if (location) {
          if (
            location.toLowerCase() === "buychips" ||
            location.toLowerCase() === "openaddcash" ||
            location.toLowerCase() === "addcash"
          ) {
            if (pushToKyc && addCash_banner) {
              messageToNativeClient({
                type: "showKyc",
                from: "deeplink_" + location?.toLowerCase(),
                show: true,
              });
            }
            else if (!location_addcash) {
              if (userLocationCompliance) {
                dispatch(
                  openModal({
                    type: Constants.RESTRICTED_LOCATION_HOURS,
                  })
                );

              }
              else {
                dispatch(
                  openModal({
                    type: Constants.RESTRICTED_LOCATION,
                  })
                );
              }
            }
            else {
              messageToNativeClient({
                type: "openaddcash",
                "Bonus code": deeplinkData["Bonus code"] ?? "",
              });
            }
            dispatch(clearDeeplink());
          } else if (
            location.toLowerCase() === "openrakeback" &&
            !hideRakeBack
          ) {
            if (pushToKyc && addCashLimits_banner) {
              messageToNativeClient({
                type: "showKyc",
                from: "deeplink_" + location?.toLowerCase(),
                show: true,
              });
            }
            else if (!location_addCashLimits) {
              if (userLocationCompliance) {
                dispatch(
                  openModal({
                    type: Constants.RESTRICTED_LOCATION_HOURS,
                  })
                );

              }
              else {
                dispatch(
                  openModal({
                    type: Constants.RESTRICTED_LOCATION,
                  })
                );
              }
            }
            else{
            messageToNativeClient({ type: location });
            }
            dispatch(clearDeeplink());
          } else if (
            location.toLowerCase() === "openmissions" ||
            location === Constants.MISSIONS_PAGE.toLowerCase() ||
            location === "mission" ||
            location === "missions"
          ) {
            if (pushToKyc && missions_banner) {
              messageToNativeClient({
                type: "showKyc",
                from: "deeplink_" + location?.toLowerCase(),
                show: true,
              });
            }
            else if (!location_missions) {
              if(userLocationCompliance ){
                dispatch(
                  openModal({
                    type: Constants.RESTRICTED_LOCATION_HOURS,
                  })
                );
  
              }
              else{
                dispatch(
                  openModal({
                    type: Constants.RESTRICTED_LOCATION,
                  })
                );
              }
            }
            else{
            messageToNativeClient({ type: "openMissions" });
            }
            dispatch(clearDeeplink());
          } else if (
            location.toLowerCase() === Constants.GIFT_VOUCHER.toLowerCase() ||
            location.toLowerCase() === "vouchers"
          ) {
            if (pushToKyc && rewards_banner) {
              messageToNativeClient({
                type: "showKyc",
                from: "deeplink_" + location?.toLowerCase(),
                show: true,
              });
            }
            else if (!location_rewards) {
              if (userLocationCompliance) {
                dispatch(
                  openModal({
                    type: Constants.RESTRICTED_LOCATION_HOURS,
                  })
                );

              }
              else {
                dispatch(
                  openModal({
                    type: Constants.RESTRICTED_LOCATION,
                  })
                );
              }
            }
            else{
            messageToNativeClient({
              type: Constants.GIFT_VOUCHER.toLowerCase(),
            });
          }
          } else if (
            location.toLowerCase() === Constants.POKER_LEADERBOARD.toLowerCase()
          ) {
            if (pushToKyc && addCashLimits_banner) {
              messageToNativeClient({
                type: "showKyc",
                from: "deeplink_" + location?.toLowerCase(),
                show: true,
              });
            }
            else if (!location_addCashLimits) {
              if (userLocationCompliance) {
                dispatch(
                  openModal({
                    type: Constants.RESTRICTED_LOCATION_HOURS,
                  })
                );
              }
              else {
                dispatch(
                  openModal({
                    type: Constants.RESTRICTED_LOCATION,
                  })
                );
              }
            }
            else{
              let dataObject = {
                Game: deeplinkData.Game.trim(),
                Tab: deeplinkData.Tab.trim(),
                Location: deeplinkData?.Location?.trim(),
              };
            if (deeplinkData?.LBID) {
              messageToNativeClient({
                type: "openPokerLeaderboard",
                data: {
                  ...dataObject,
                  SubTab: deeplinkData.SubTab,
                  LBID: deeplinkData.LBID.trim(),
                },
              });
            } else if (deeplinkData?.SubTab) {
              messageToNativeClient({
                type: "openPokerLeaderboard",
                data: { ...dataObject, SubTab: deeplinkData.SubTab.trim() },
              });
            } else {
              messageToNativeClient({
                type: "openPokerLeaderboard",
                data: dataObject,
              });
            }
          }
            dispatch(clearDeeplink());
          } else if (
            location.toLowerCase() === Constants.CASH_LOBBY.toLowerCase()
          ) {
            let tab, subtab;
            if (deeplinkData?.Tab) {
              tab = deeplinkData?.Tab?.trim();
            }
            if (deeplinkData?.SubTab) {
              subtab = deeplinkData?.SubTab?.trim();
            }
            let gameType;
            if (tab) {
              dispatch(
                updateCashAndFunGamesToggle({
                  isCashGameToggled: tab === Constants.CASH_GRID,
                })
              );
            }
            if (subtab) {
              if (subtab.toLowerCase() === Constants.TEXAS.toLowerCase()) {
                gameType = Constants.TEXAS_HOLDEM;
              } else if (subtab.toLowerCase() === Constants.PLO.toLowerCase()) {
                gameType = Constants.OMAHA;
              } else if (
                subtab.toLowerCase() === Constants.PLO5.toLowerCase()
              ) {
                gameType = Constants.OMAHA5;
              } else if (
                subtab.toLowerCase() === Constants.PLO6.toLowerCase()
              ) {
                gameType = Constants.OMAHA6;
              } else {
                gameType = Constants.TEXAS_HOLDEM;
              }
              setSelectedGameTypeTab(gameType);
            }
            handleTabChange(FooterTabConstants.games);
            dispatch(clearDeeplink());
          } else if (
            location.toLowerCase() === Constants.TOURNEY_LOBBY.toLowerCase()
          ) {
            let tab = deeplinkData.Tab.trim();
            let subtab = deeplinkData?.SubTab?.trim();
            let tabName = "";
            if (tab.toLowerCase() === Constants.MY_TOURNEY_TAB.toLowerCase()) {
              tabName = Constants.MY_TOURNEYS;
            } else if (
              tab.toLowerCase() === Constants.ACTIVE_TAB.toLowerCase()
            ) {
              tabName = Constants.ACTIVE_TOURNEYS;
            } else if (
              tab.toLowerCase() === Constants.COMPLETED_TAB.toLowerCase()
            ) {
              tabName = Constants.COMPLETED_TOURNEYS;
            }
            handleTabChange(FooterTabConstants.tourneys);
            setSelectedTourneysTab(tabName);
            console.log("sneha log >> subtab:", subtab);
            if (
              subtab &&
              getTourneySubTabKeyForDeeplink(
                Constants.DEEPLINK_TOURNEY_TAB_MAPS[tab?.toLowerCase()],
                subtab
              )
            ) {
              console.log(
                "sneha log >> inside if ",
                Constants.DEEPLINK_TOURNEY_VARIANT_MAPS[subtab]
              );
              // console.log("sneha log >> inside if ", Constants.DEEPLINK_TOURNEY_VARIANT_MAPS[subtab]);
              setSelectedTourneyVariant(
                Constants.DEEPLINK_TOURNEY_VARIANT_MAPS[subtab]
              );
            } else {
              // console.log("sneha log >> inside else");
              setSelectedTourneyVariant(Constants.ALL_TAB);
            }
            dispatch(clearDeeplink());
          } else if (
            location.toLowerCase() === Constants.HOME_LOBBY.toLowerCase()
          ) {
            handleTabChange(FooterTabConstants.home);
            dispatch(clearDeeplink());
          } else if (
            location.toLowerCase() === Constants.MYTICKET.toLowerCase()
          ) {
            if (pushToKyc && cashGames_banner) {
              messageToNativeClient({
                type: "showKyc",
                from: "deeplink_" + location?.toLowerCase(),
                show: true,
              });
            }
            else if (!location_games) {
              if (userLocationCompliance) {
                dispatch(
                  openModal({
                    type: Constants.RESTRICTED_LOCATION_HOURS,
                  })
                );
              }
              else {
                dispatch(
                  openModal({
                    type: Constants.RESTRICTED_LOCATION,
                  })
                );
              }
            }
            else{
            handleTabChange(FooterTabConstants.tourneys);
            setSelectedTourneysTab(Constants.ACTIVE_TOURNEYS);

            dispatch(updateShowAllTicketsSection({ isOpen: true }));
            }
            dispatch(clearDeeplink());
          } else if (
            location.toLowerCase() === Constants.GAME_TABLE.toLowerCase() &&
            receivedLobbyData
          ) {
            if (pushToKyc && cashGames_banner) {
              messageToNativeClient({
                type: "showKyc",
                from: "deeplink_" + location?.toLowerCase(),
                show: true,
              });
            }
            else if (!location_games) {
              if (userLocationCompliance) {
                dispatch(
                  openModal({
                    type: Constants.RESTRICTED_LOCATION_HOURS,
                  })
                );
              }
              else {
                dispatch(
                  openModal({
                    type: Constants.RESTRICTED_LOCATION,
                  })
                );
              }
            }
            else {
              let gameDefinition =
                masterGameDefinitions[Number(deeplinkData.GameDefID.trim())];
              if (gameDefinition) {
                handleTabChange(FooterTabConstants.games);
                dispatch(
                  updateCashAndFunGamesToggle({
                    isCashGameToggled:
                      gameDefinition.flavourType.toLowerCase() ===
                      Constants.LB_REAL,
                  })
                );
                setSelectedGameTypeTab(gameDefinition.gameType);
              } else {
                //aishwarya add logs to faro
                console.log(
                  "gameid not found in master game defs",
                  deeplinkData.GameDefID
                );
              }
            }
          } else if (
            location.toLowerCase() ===
            Constants.TOURNEY_REGISTRATION.toLowerCase() &&
            receivedTourneyData
          ) {
            if (pushToKyc && cashGames_banner) {
              messageToNativeClient({
                type: "showKyc",
                from: "deeplink_" + location?.toLowerCase(),
                show: true,
              });
            }
            else if (!location_games) {
              if (userLocationCompliance) {
                dispatch(
                  openModal({
                    type: Constants.RESTRICTED_LOCATION_HOURS,
                  })
                );
              }
              else {
                dispatch(
                  openModal({
                    type: Constants.RESTRICTED_LOCATION,
                  })
                );
              }
            }
            else {
              let tourney = masterTourneysData.filter(
                (t) => t.tourneyId === deeplinkData.TourneyID.trim()
              );
              let tabName = "";
              handleTabChange(FooterTabConstants.tourneys);
              if (tourney.length === 1) {
                if (tourney[0].tourneyIdentifier === "myTourneys") {
                  tabName = Constants.MY_TOURNEYS;
                } else if (tourney[0].tourneyIdentifier === "upcomingTourneys") {
                  tabName = Constants.ACTIVE_TOURNEYS;
                } else if (tourney[0].tourneyIdentifier === "completedTourneys") {
                  tabName = Constants.COMPLETED_TOURNEYS;
                }
                setSelectedTourneysTab(tabName);
                setSelectedTourneyVariant(
                  getTourneyVariantTabbbarItems()[0]?.value
                );
              }
            }
          }
           else if (location === Constants.LEARN_POKER.toLowerCase()) {
            messageToNativeClient({
              type: "redirection",
              kind: "internal",
              url: process.env.REACT_APP_HOW_TO_PLAY,
            });
            dispatch(clearDeeplink());
          } 
          else if (location === Constants.ACTIVE_OFFERS.toLowerCase()) {
            if (pushToKyc && promotionsActiveOffers_banner) {
              messageToNativeClient({
                type: "showKyc",
                from: "deeplink_" + location?.toLowerCase(),
                show: true,
              });
            }
            else if (!location_promotionsActiveOffers) {
              if (userLocationCompliance) {
                dispatch(
                  openModal({
                    type: Constants.RESTRICTED_LOCATION_HOURS,
                  })
                );
              }
              else {
                dispatch(
                  openModal({
                    type: Constants.RESTRICTED_LOCATION,
                  })
                );
              }
            }
            else {
              messageToNativeClient({ type: "openActiveOffers" });
            }
            dispatch(clearDeeplink());
          }
          else if (location === Constants.MY_WALLET.toLowerCase()) {
            messageToNativeClient({ type: "openMyWallet", isOpened: true });
            dispatch(clearDeeplink());
          } else if (location === Constants.MY_PROFILE_DETAILS.toLowerCase()) {
            messageToNativeClient({ type: "openSideDrawer", isOpened: true });
            dispatch(clearDeeplink());
          } else if (location === Constants.REFER_AND_EARN.toLowerCase()) {
            messageToNativeClient({ type: "openR&E" });
            dispatch(clearDeeplink());
          } else if (
            location.toLowerCase() === Constants.REDEEM_ACE_POINTS.toLowerCase()
          ) {
            messageToNativeClient({
              type: Constants.REDEEM_ACE_POINTS.toLowerCase(),
            });
            dispatch(clearDeeplink());
          } else if (
            location.toLowerCase() ===
            Constants.LANGUAGE_PREFERENCES.toLowerCase()
          ) {
            messageToNativeClient({
              type: Constants.LANGUAGE_PREFERENCES.toLowerCase(),
            });
            dispatch(clearDeeplink());
          } else if (location.toLowerCase() === Constants.REDEEM_OPTIONS.toLowerCase()) {
            messageToNativeClient({
              type: Constants.REDEEM_OPTIONS.toLowerCase(),
            });
            dispatch(clearDeeplink());
          } else if (
            location.toLowerCase() === Constants.BONUS_SUMMARY.toLowerCase()
          ) {
            messageToNativeClient({
              type: Constants.BONUS_SUMMARY.toLowerCase(),
            });
            dispatch(clearDeeplink());
          } else if (
            location.toLowerCase() === Constants.PURCHASE_LIMITS.toLowerCase()
          ) {
            messageToNativeClient({
              type: Constants.PURCHASE_LIMITS.toLowerCase(),
            });
            dispatch(clearDeeplink());
          } else if (
            location.toLowerCase() === Constants.CHANGE_PASSWORD.toLowerCase()
          ) {
            messageToNativeClient({
              type: Constants.CHANGE_PASSWORD.toLowerCase(),
            });
            dispatch(clearDeeplink());
          }
        }
        dispatch(clearDeeplink());
      
    } else {
      dispatch(clearDeeplink());
    }
  }, [
    deeplinkData?.Location,
    deeplinkData?.Tab,
    deeplinkData?.SubTab,
    deeplinkData?.GameDefID,
    deeplinkData?.TourneyID,
    receivedLobbyData,
    receivedTourneyData,
  ]);

  useEffect(() => {
    if (isPlayerJoiningFromLB) {
      if (leaderBoardGameDefinition?.tourneyId && receivedTourneyData) {
        let tabName = "";
        // let tourney = upcomingTourneys.filter(t => t.tourneyId === leaderBoardGameDefinition.tourneyId);
        // if(tourney.length === 0){
        //   tourney = completedTourneys.filter(t => t.tourneyId === leaderBoardGameDefinition.tourneyId);
        //   tabName = Constants.COMPLETED_TOURNEYS;
        // } else if(tourney.length ===1){
        //   tabName = Constants.ACTIVE_TOURNEYS;
        // }

        let tourney = masterTourneysData.get(
          leaderBoardGameDefinition.tourneyId
        );
        if (tourney.length === 1) {
          if (tourney[0].tourneyIdentifier === "myTourneys") {
            tabName = Constants.MY_TOURNEYS;
          } else if (tourney[0].tourneyIdentifier === "upcomingTourneys") {
            tabName = Constants.ACTIVE_TOURNEYS;
          } else if (tourney[0].tourneyIdentifier === "completedTourneys") {
            tabName = Constants.COMPLETED_TOURNEYS;
          }
          console.log("OPEN TOURNEY PAGE", leaderBoardGameDefinition);
          handleTabChange(FooterTabConstants.tourneys);
          setSelectedTourneysTab(tabName);
          setSelectedTourneyVariant(leaderBoardGameDefinition.tournamentType);
        } else {
          dispatch(setIsPlayerJoiningFromLB({ flag: false }));
          dispatch(
            addLobbyToastMessage({
              type: Constants.TOAST_INFO,
              message: Constants.REDIRECTION_FAIL,
            })
          );
          let timeStamp = getISTDateTimeFormat();
          logCleverTapEvent(Poker_Redirection_Fail, {
            Username: playerDetails.userName,
            Channel: channel,
            Timestamp: timeStamp,
            KVPairs: deeplinkData,
          });
          sendFaroLog({
            type: Poker_Redirection_Fail,
            Username: playerDetails.userName,
            Channel: channel,
            Timestamp: timeStamp,
            KVPairs: deeplinkData,
          });
        }
      } else if (leaderBoardGameDefinition?.gameDefId && receivedLobbyData) {
        if (leaderBoardGameDefinition) {
          handleTabChange(FooterTabConstants.games);
          dispatch(
            updateCashAndFunGamesToggle({
              isCashGameToggled:
                leaderBoardGameDefinition.flavourType.toLowerCase() ===
                Constants.LB_REAL,
            })
          );
          setSelectedGameTypeTab(leaderBoardGameDefinition.gameType);
        }
      } else {
        dispatch(setIsPlayerJoiningFromLB({ flag: false }));
        dispatch(
          addLobbyToastMessage({
            type: Constants.TOAST_INFO,
            message: Constants.REDIRECTION_FAIL,
          })
        );
        sendFaroLog({
          type: Poker_Redirection_Fail,
          Username: playerDetails.userName,
          Channel: channel,
          Timestamp: getISTDateTimeFormat(),
          message:
            "leaderboardGameDefinition is undefined meaning gameid not in master game definitions",
        });
      }
    }
  }, [
    isPlayerJoiningFromLB,
    //for leaderBoardGameDefinition below add variable if any new type comes up
    leaderBoardGameDefinition?.tourneyId,
    leaderBoardGameDefinition?.gameDefId,
    receivedLobbyData,
    receivedTourneyData,
  ]);

  useEffect(() => {
    if (receivedLobbyData) {
      if (
        ((isPlayerJoiningFromLB && leaderBoardGameDefinition?.gameDefId) ||
          deeplinkData?.Location?.toLowerCase() ===
            Constants.GAME_TABLE.toLowerCase()) &&
        (playerDetails?.userConsentFlag || playerDetails?.gameJoinCheckAck)
      ) {
        messageToNativeClient({ type: "gameJoinCheck" });
      }
      if (
        ((isPlayerJoiningFromLB && leaderBoardGameDefinition?.gameDefId) ||
          deeplinkData?.Location?.toLowerCase() ===
            Constants.GAME_TABLE.toLowerCase()) &&
        !playerDetails?.userConsentFlag
      ) {
        if (isPlayerJoiningFromLB) {
          if (leaderBoardGameDefinition) {
            onGameTileClick(leaderBoardGameDefinition);
          } else {
            dispatch(setIsPlayerJoiningFromLB({ flag: false }));
            dispatch(
              addLobbyToastMessage({
                type: Constants.TOAST_INFO,
                message: Constants.REDIRECTION_FAIL,
              })
            );
          }
        } else if (
          deeplinkData?.Location?.toLowerCase() ===
            Constants.GAME_TABLE.toLowerCase() &&
          deeplinkData?.GameDefID
        ) {
          let gameDefinition =
            masterGameDefinitions[Number(deeplinkData.GameDefID.trim())];
          if (gameDefinition) {
            onGameTileClick(gameDefinition);
          } else {
            console.log(
              "no gamedefid in master game definitions",
              masterGameDefinitions,
              deeplinkData.GameDefID
            );
            let timeStamp = getISTDateTimeFormat();
            logCleverTapEvent(Poker_Redirection_Fail, {
              Username: playerDetails.userName,
              Channel: channel,
              Timestamp: timeStamp,
              KVPairs: deeplinkData,
            });
            dispatch(
              addLobbyToastMessage({
                type: Constants.TOAST_INFO,
                message: Constants.REDIRECTION_FAIL,
              })
            );
            sendFaroLog({
              type: Poker_Redirection_Fail,
              Username: playerDetails.userName,
              Channel: channel,
              Timestamp: timeStamp,
              KVPairs: deeplinkData,
            });
            dispatch(clearDeeplink());
          }
        }
      }
    }
  }, [
    isPlayerJoiningFromLB,
    playerDetails?.userConsentFlag,
    deeplinkData?.GameDefID,
    receivedLobbyData,
  ]);

  const getTourneySubTabKeyForDeeplink = (tab, subTab) => {
    console.log(
      "sneha log >> getTourneySubTabKeyForDeeplink : tab",
      tab,
      "subTab: ",
      subTab
    );
    let value = updatedTourneyTabs?.[tab].filter((item) => {
      let key = item.split(" ").join("");
      if (item === Constants.TOURNEY_TYPES.SIT_AND_GO) {
        key = Constants.SIT_N_GO;
      } else if (item === Constants.TOURNEY_TYPES.TICKET) {
        key = Constants.TICKETS;
      }
      if (key.toLowerCase() === subTab.split(" ").join(" ").toLowerCase()) {
        return item;
      }
    })?.[0];
    console.log("sneha log >> value", value);
    return value;
  };

  // This is the function to create new table
  const initiateCreateTable = (gameDefinition) => {
    dispatch(
      createTableData({
        tempTableId: generateUniqueId(),
        gameDefinition: gameDefinition,
        watchPlayer: true,
      })
    );
    // startTransition(() => {
    setTempShowGameTable(true); //IM-2461 kept this to avoid creating multiple games on multi tap
    setCreatingNewGame(false);
    // });
  };

  const processLackGames = (lackGamesDetails) => {
    setLackGamesDisplayed(true);

    const lackGamesDefinitions = lackGamesDetails.filter((eachGameDef) => {
      if (
        (eachGameDef.isPrivateTable &&
          privateTableActiveGameDefinitions.hasOwnProperty(
            eachGameDef.gameDefId
          )) ||
        masterGameDefinitions.hasOwnProperty(eachGameDef.gameDefId)
      ) {
        return eachGameDef;
      }
    });

    lackGamesDefinitions.forEach((eachGameDefinition) => {
      let currentLackGame = eachGameDefinition;
      if (currentLackGame?.nodeIp) {
        dispatch(
          createTableData({
            tempTableId: generateUniqueId(),
            gameDefinition: currentLackGame.isPrivateTable
              ? privateTableActiveGameDefinitions[currentLackGame.gameDefId]
              : masterGameDefinitions[currentLackGame.gameDefId],
            lackGameId: currentLackGame.gameId,
            lackTableId: currentLackGame.tableId,
            awsalbcookie: currentLackGame.awsALBCookie,
            gameEndpoint: currentLackGame.nodeIp,
            isTourneyGame: false,
            isTourneyLackGame: false,
            watchPlayer: true,
            isPrivateTable: currentLackGame.isPrivateTable,
          })
        );
        dispatch(
          handleRemoveDisplayedCashLackGame({ gameId: currentLackGame.gameId })
        );
      } else {
        var date = new Date();
        dispatch(
          sendLobbyDebugLog({
            message: `Error at processing lack games at client , data = ${JSON.stringify(
              currentLackGame
            )} --- at ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} `,
          })
        );
      }
    });
  };

  const checkForAvailableTourneyStart = () => {
    if (dynamicTourneysStartData.length > 0 && activeGameIds.length < 4) {
      dispatch(
        handleTourneyStart({
          tourneys: [...dynamicTourneysStartData],
          isTourneyLackGame: false,
        })
      );
      dispatch(handleClearDynamicTourneyStartData());
      if (!applicationVisibility) {
        messageToNativeClient({ type: "closeNotifications" });
        messageToNativeClient({
          type: "toastMessage",
          message: "Tourney starting now. Go back to join",
          toast_type: "TOAST_INFO",
        });
      }
    }
  };

  const processLackTourneyGames = (lackTourneyGamesDetails) => {
    dispatch(
      updateIsLackTourneyGamesDisplayed({ isLackTourneysDisplayed: true })
    );

    lackTourneyGamesDetails.forEach((currentLackTourneyGame) => {
      let currentLackTourneyGameDefinition = masterTourneysData.get(
        currentLackTourneyGame.tourneyId
      );
      console.log("CHECK_ME_GANESH", currentLackTourneyGameDefinition);
      let isActiveTourneyWithSameIdAvailable = false;
      // NOTE: Using below condition, if any active participating tourney tables are already open, we are avoiding to create a duplicate game window.
      if (
        currentLackTourneyGameDefinition &&
        !currentLackTourneyGameDefinition.watchPlayer
      ) {
        for (let game in activeGameIds) {
          let tempTableId = activeGameIds[game];
          let gameData = activeGames[tempTableId];
          if (
            gameData.isTourneyGame &&
            !gameData.watchPlayer &&
            gameData.gameDefinition.tourneyId ===
              currentLackTourneyGameDefinition.tourneyId
          ) {
            isActiveTourneyWithSameIdAvailable = true;
          }
        }
      }

      if (
        currentLackTourneyGameDefinition &&
        !isActiveTourneyWithSameIdAvailable &&
        currentLackTourneyGame &&
        currentLackTourneyGame?.domainName
      ) {
        console.log(
          "CREATING currentLackTourneyGame.tourneyId: ",
          currentLackTourneyGame.tourneyId
        );
        dispatch(
          createTableData({
            tempTableId: generateUniqueId(),
            gameDefinition: {
              ...currentLackTourneyGameDefinition,
              gameDefId: `${Math.floor(Math.random() * 49000)}`, //NOTE: For tourney we dont have any game def id so for that we are sending it a number , and it should be less than 50,000 value , can send any unique  random number ranges from 0 to50k
            },
            lackGameId: currentLackTourneyGame?.isTourneyLackGame
              ? `${Math.floor(Math.random() * 49000)}`
              : currentLackTourneyGame.gameId,
            lackTableId: currentLackTourneyGame.tableId,
            awsalbcookie: null,
            gameEndpoint: currentLackTourneyGame.domainName,
            isTourneyGame: true,
            isTourneyLackGame: currentLackTourneyGame?.isTourneyLackGame,
            watchPlayer: currentLackTourneyGame.watchPlayer
              ? currentLackTourneyGame.watchPlayer
              : false,
          })
        );

        dispatch(
          handleRemoveDisplayedTourneyLackGame({
            lackTourneyId: currentLackTourneyGame.tourneyId,
          })
        );
      } else if (
        isActiveTourneyWithSameIdAvailable &&
        currentLackTourneyGame &&
        currentLackTourneyGame.tourneyId
      ) {
        dispatch(
          handleRemoveDisplayedTourneyLackGame({
            lackTourneyId: currentLackTourneyGame.tourneyId,
          })
        );
      }
      // console.log("Setting temp show game table");
      // startTransition(() => {
      // setTempShowGameTable(true); //to be tested @Yashwanth
      // });
    });
  };

  const tabbarItemsList = [
    // {
    //   label: "All",
    //   value: Constants.ALL,
    // },
    {
      label: "HOLD’EM",
      value: Constants.TEXAS_HOLDEM,
    },
    {
      label: "OMAHA",
      value: Constants.OMAHA,
    },
    {
      label: "OMAHA 5",
      value: Constants.OMAHA5,
    },
    {
      label: "OMAHA 6",
      value: Constants.OMAHA6,
    },
  ];

  const tourneyTabbarItemsList = [
    {
      label: "ACTIVE",
      value: Constants.ACTIVE_TOURNEYS,
    },
    {
      label: "MY TOURNEYS",
      value: Constants.MY_TOURNEYS,
      showRedDot: showRedDotOnMyTourneys,
    },

    {
      label: "COMPLETED",
      value: Constants.COMPLETED_TOURNEYS,
    },
  ];

  const getTourneyTypeDisplayLabel = (tourneyType) => {
    switch (tourneyType) {
      case Constants.TOURNEY_TYPES.SIT_AND_GO:
        return "Sit & Go";
      case Constants.TOURNEY_TYPES.TICKET:
        return "Ticket";
      default:
        return tourneyType;
    }
  };

  // const getTourneyVariantTabbbarItems = () => {
  //   const tourneyVariantTabItems = updatedTourneyTabs[selectedTourneysTab]?.map(
  //     (eachTab) => {
  //       return {
  //         label: getTourneyTypeDisplayLabel(eachTab),
  //         value: eachTab,
  //       };
  //     }
  //   );
  //   return tourneyVariantTabItems;
  // };
  const getTourneyVariantTabbbarItems = () => {
    const tourneyVariantTabItems = [];
    const uniqueTabs = new Set();

    if (updatedTourneyTabs[selectedTourneysTab]) {
      updatedTourneyTabs[selectedTourneysTab].forEach((eachTab) => {
        const normalizedTab = eachTab.toLowerCase().includes("flight")
          ? "Flights"
          : eachTab;

        if (!uniqueTabs.has(normalizedTab)) {
          uniqueTabs.add(normalizedTab);
          tourneyVariantTabItems.push({
            label: getTourneyTypeDisplayLabel(normalizedTab),
            value: normalizedTab,
          });
        }
      });
    }

    return tourneyVariantTabItems;
  };

  const getTourneyDefinitionByTourneyId = (tourneyId) => {
    const tourney = masterTourneysData.get(tourneyId);
    return tourney ? tourney : false;
  };

  const handleTabChange = (selectedTab) => {
    // if (!playerDetails?.isAllowLocation) {
    //   let restrictedFeatures = [
    //     footerTabConstants.rakeback,
    //     footerTabConstants.leaderboard,
    //     // footerTabConstants.tourneys,
    //   ];
    //   if (restrictedFeatures.includes(selectedTab)) {
    //     return dispatch(openModal({ type: Constants.RESTRICTED_LOCATION }));
    //   }
    // }

    // if (!userLocationCompliance && !playerDetails?.isAllowLocation) {
    //     let restrictedFeatures = [
    //       footerTabConstants.rakeback,
    //       footerTabConstants.leaderboard,
    //       // footerTabConstants.tourneys,
    //     ];
    //     if (restrictedFeatures.includes(selectedTab)) {
    //       return dispatch(openModal({ type: Constants.RESTRICTED_LOCATION }));
    //     }
    // }

    if (selectedTab === FooterTabConstants.hamburgerMenu) {
      messageToNativeClient({ type: "openSideDrawer", isOpened: true });
      return;
    }
    if (selectedTab === FooterTabConstants.learnPoker) {
      messageToNativeClient({
        type: "redirection",
        kind: "internal",
        url: process.env.REACT_APP_HOW_TO_PLAY,
      });
      return;
    }
    if (selectedTab === FooterTabConstants.contactUs) {
      if (Constants.FUN_CHANNELS.includes(channel)) {
        messageToNativeClient({ type: "openPlayContactUs" });
        return;
      } else {
        messageToNativeClient({ type: "openContactUs" });
        return;
      }
    }
    if (selectedTab === footerTabConstants.more) {
      setShowFooterMoreSectionModal(true);
    } else if (selectedTab === footerTabConstants.rakeback) {
      if (pushToKyc && addCashLimits_banner) {
        messageToNativeClient({
          type: "showKyc",
          from: "deeplink_" + selectedTab?.toLowerCase(),
          show: true,
        });
        return;
      }
      else if (!location_addCashLimits) {
        if (userLocationCompliance) {
          dispatch(
            openModal({
              type: Constants.RESTRICTED_LOCATION_HOURS,
            })
          );
        }
        else {
          dispatch(
            openModal({
              type: Constants.RESTRICTED_LOCATION,
            })
          );
        }
        return;
      }
      else{
      return messageToNativeClient({ type: "openRakeBack" });
      }
    } else if (selectedTab === footerTabConstants.rules) {
      // setOpenHowToPlayPage(true);
      //below call is used to reduce the blink effect (IM-634)
      messageToNativeClient({
        type: "redirection",
        kind: "internal",
        url: "https://a23.hdworks.in/poker/howtoplay.html?src=mapp&platformapp=Y",
      });
    } else if (selectedTab === footerTabConstants.leaderboard) {
      if (pushToKyc && addCashLimits_banner) {
        messageToNativeClient({
          type: "showKyc",
          from: "deeplink_" + selectedTab?.toLowerCase(),
          show: true,
        });
        return;
      }
      else if (!location_addCashLimits) {
        if (userLocationCompliance) {
          dispatch(
            openModal({
              type: Constants.RESTRICTED_LOCATION_HOURS,
            })
          );
        }
        else {
          dispatch(
            openModal({
              type: Constants.RESTRICTED_LOCATION,
            })
          );
        }
        return;
      }
      else{
      if (isLeaderboardEnabled) {
        dispatch(setIsPlayerJoiningFromLB({ flag: false }));
        messageToNativeClient({ type: "openPokerLeaderboard" });
      } else {
        dispatch(
          addLobbyToastMessage({
            type: Constants.TOAST_INFO,
            message: `Leaderboard will be available soon`,
          })
        );
      }
    }
      return;
    } else {
      if (selectedTab === footerTabConstants.tourneys) {
        handleTourneysTabChange(Constants.ACTIVE_TOURNEYS);

        setSelectedTourneyVariant(
          getTourneyVariantTabbbarItems()?.length > 0
            ? getTourneyVariantTabbbarItems()[0]?.value
            : Constants.ALL_TAB
        );
        let timeStamp = getISTDateTimeFormat();
        logCleverTapEvent(IP_Client_TourneyLobby_View, {
          Username: playerDetails.userName,
          Channel: channel,
          "Wallet balance": userBalance.totalBalance,
          Timestamp: timeStamp,
        });
      }
      if (selectedTab === footerTabConstants.home) {
        dispatch(updateCashAndFunGamesToggle({ isCashGameToggled: true }));
      }
      dispatch(updateLobbyCurrentTab({ selectedTab: selectedTab }));
    }
  };

  const handleCloseFooterMoreSectionModal = () => {
    setShowFooterMoreSectionModal(false);
  };

  const handleGameTypeTabChange = (selectedTab, index = false) => {
    // setSwitchingTabs(true);
    if (index) {
      switch (selectedTab.realIndex) {
        case 0:
          // setSelectedGameTypeTab(Constants.ALL);
          setSelectedGameTypeTab(Constants.TEXAS_HOLDEM);
          return;
        case 1:
          // setSelectedGameTypeTab(Constants.TEXAS_HOLDEM);
          setSelectedGameTypeTab(Constants.OMAHA);
          return;
        case 2:
          // setSelectedGameTypeTab(Constants.OMAHA);
          setSelectedGameTypeTab(Constants.OMAHA5);
          return;
        case 3:
          setSelectedGameTypeTab(Constants.OMAHA6);
        // case 3:
        //   setSelectedGameTypeTab(Constants.OMAHA5);
        //   return;
      }
    } else {
      setSelectedGameTypeTab(selectedTab);
      switch (selectedTab) {
        // case Constants.ALL:
        //   swiperRef?.current?.swiper?.slideToLoop(0);
        //   return;
        case Constants.TEXAS_HOLDEM:
          // swiperRef?.current?.swiper?.slideToLoop(1);
          swiperRef?.current?.swiper?.slideToLoop(0);
          return;
        case Constants.OMAHA:
          // swiperRef?.current?.swiper?.slideToLoop(2);
          swiperRef?.current?.swiper?.slideToLoop(1);
          return;
        case Constants.OMAHA5:
          // swiperRef?.current?.swiper?.slideToLoop(3);
          swiperRef?.current?.swiper?.slideToLoop(2);
          return;
        case Constants.OMAHA6:
          swiperRef?.current?.swiper?.slideToLoop(3);
          return;
      }
    }
  };

  const handleTourneysTabChange = (selectedTab) => {
    if (selectedTourneysTab !== selectedTab) {
      setSelectedTourneysTab(selectedTab);
      setSelectedTourneyVariant(Constants.ALL_TAB);
    }
  };

  const handleTourneysVariantChange = (selectedTab, swipeByIndex = false) => {
    if (swipeByIndex) {
      let swipedTab = getTourneyVariantTabbbarItems()[selectedTab]?.value;
      if (swipedTab !== selectedTab) {
        setSelectedTourneyVariant(swipedTab);
      }
    } else {
      if (selectedTourneyVariant !== selectedTab) {
        setSelectedTourneyVariant(selectedTab);
      }
      let selectedTabIndex = getTourneyVariantTabbbarItems().findIndex(
        (eachTabVariant) => eachTabVariant.value === selectedTab
      );

      tourneyLobbySwiperRef?.current?.swiper?.slideToLoop(selectedTabIndex);
    }
  };

  const handleCashFunToggle = (value) => {
    dispatch(updateCashAndFunGamesToggle({ isCashGameToggled: value }));
  };

  const renderHeader = () => {
    return (
      <Header
        channel={channel}
        userBalance={userBalance}
        isCashGamesToggled={isCashGamesToggled}
        currentTab={currentTab}
        handleCashAndPracticeGameToggle={handleCashFunToggle}
        onHeaderA23LogoClick={handleGameTypeTabChange}
      />
    );
  };

  const openWalletPage = () => {
    messageToNativeClient({ type: "openMyWallet", isOpened: true });
  };

  const openSideDrawer = (isOpened) => {
    messageToNativeClient({ type: "openSideDrawer", isOpened: isOpened });
  };
  const formatTourneyDate = (timeStamp, formatString) => {
    try {
      return moment.unix(timeStamp).format(formatString);
    } catch (e) {
      sendLog({
        type: Constants.CLIENT_ERROR_EVENT,
        payload: "ERROR IN FORMATTING DATE IN TOURNEY GAME TILE",
      });
      console.warn("ERROR IN FORMATTING DATE IN TOURNEY GAME TILE");
    }
  };

  const renderFooterMoreSectionModal = () => {
    return (
      <MoreSectionContainer onTouchStart={handleCloseFooterMoreSectionModal}>
        <MoreSectionMenuItemsWrapper>
          <MoreSectionMenuItem
            onTouchStart={() => handleTabChange(footerTabConstants.leaderboard)}
          >
            <MoreSectionMenuIcon src={leaderboardMenuIcon} />
            <MoreSectionMenuItemText>Leaderboard</MoreSectionMenuItemText>
          </MoreSectionMenuItem>
          <MoreSectionMenuItem onTouchStart={() => setOpenHowToPlayPage(true)}>
            <MoreSectionMenuIcon src={playGoldIcon} />
            <MoreSectionMenuItemText>How to play</MoreSectionMenuItemText>
          </MoreSectionMenuItem>
          <MoreSectionMenuItem onTouchStart={() => setOpenQuizPage(true)}>
            <MoreSectionMenuIcon src={goldBulbIcon} />
            <MoreSectionMenuItemText>Quiz</MoreSectionMenuItemText>
          </MoreSectionMenuItem>
          <MoreSectionMenuItem onTouchStart={() => setOpenFAQsPage(true)}>
            <MoreSectionMenuIcon src={goldQuestionMarkIcon} />
            <MoreSectionMenuItemText>FAQs</MoreSectionMenuItemText>
          </MoreSectionMenuItem>
          <MoreSectionMenuItem onTouchStart={() => openWalletPage()}>
            <MoreSectionMenuIcon src={goldQuestionMarkIcon} />
            <MoreSectionMenuItemText>Open Wallet</MoreSectionMenuItemText>
          </MoreSectionMenuItem>
          <MoreSectionMenuItem onTouchStart={() => openSideDrawer(true)}>
            <MoreSectionMenuIcon src={goldQuestionMarkIcon} />
            <MoreSectionMenuItemText>Open Side drawer</MoreSectionMenuItemText>
          </MoreSectionMenuItem>
          <MoreSectionMenuItem onTouchStart={() => openSideDrawer(false)}>
            <MoreSectionMenuIcon src={goldQuestionMarkIcon} />
            <MoreSectionMenuItemText>Close Side drawer</MoreSectionMenuItemText>
          </MoreSectionMenuItem>
        </MoreSectionMenuItemsWrapper>
      </MoreSectionContainer>
    );
  };

  const renderFooter = () => {
    return channel && !showPrivateTableLobby ? (
      <Footer
        lobbyCallback={gameToLobbyCallBack}
        selectedTab={currentTab}
        onTabChange={handleTabChange}
      />
    ) : null;
  };

  const sortOnlyActiveGameDefinitionsByOrderIdAndStatus = (
    gameDefinitionsList
  ) => {
    //Filtering only active game definitions.
    const filteredActiveGames = [...gameDefinitionsList].filter(
      (eachGameDefinition) =>
        eachGameDefinition.status === Constants.GAME_STATUS_ACTIVE
    );
    //Sorting them based on order sequence.
    let sortedGames = [...filteredActiveGames].sort(
      (a, b) => a.orderId - b.orderId
    );
    return sortedGames;
  };

  // const getCurrentTabGameDefinitions = (tab) => {
  //   // const { realStakeGameVariants, playStateGameVariants } = lobby;
  //   const { TEXAS_HOLDEM, OMAHA, OMAHA5 } = Constants;
  //   switch (tab) {
  //     case Constants.ALL:
  //       console.log('Real Games ', realGames);
  //       return isCashGamesToggled ? realGames : funGames;
  //     case TEXAS_HOLDEM:
  //       return isCashGamesToggled ? realTexasHoldem : funTexasHoldem;
  //     case OMAHA:
  //       return isCashGamesToggled ? realOmaha : funOmaha;
  //     case OMAHA5:
  //       return isCashGamesToggled ? realOmaha5 : funOmaha5;
  //     default:
  //       return {};
  //   }
  // };

  // const renderCourosalBanners = () => {
  //   let banners = [banner1Image, banner2Image, banner3Image];
  //   // let banners = [banner1Image];
  //   return (
  //     <CarousalWrapper>
  //       <Swiper
  //         spaceBetween={0}
  //         slidesPerView={1}
  //         loop={true}
  //         autoPlay={{
  //           delay: 2000,
  //           disableOnInteraction: false,
  //         }}
  //         pagination={{
  //           clickable: false,
  //         }}
  //         speed={300}
  //         // speed={6000}
  //         watchSlidesProgress
  //       >
  //         {banners.map((eachBanner, index) => (
  //           <SwiperSlide key={"lobbySlider" + eachBanner}>
  //             <BannerImageWrapper src={eachBanner} />
  //           </SwiperSlide>
  //         ))}
  //       </Swiper>
  //     </CarousalWrapper>
  //   );
  // };

  const handleGamesSectionChange = (realIndex) => {
    setSelectedGameTypeTab(tabbarItemsList[realIndex].value);
    return;
  };

  const handleLobbyTabSwipe = (data) => {
    // switch (data?.realIndex) {
    //   case 0:
    //     setSelectedGameTypeTab(Constants.ALL);
    //     break;
    //   case 1:
    //     setSelectedGameTypeTab(Constants.TEXAS_HOLDEM);
    //     break;
    //   case 2:
    //     setSelectedGameTypeTab(Constants.OMAHA);
    //     break;
    //   case 3:
    //     setSelectedGameTypeTab(Constants.OMAHA5);
    //     break;
    // }
    return;
  };

  const renderPokerLobbyDetails = () => {
    return (
      <LobbyDetailsContainer>
        <LobbyDetailsHeaderWrapper>
          <GamesTabbarWrapper>
            <Tabbar
              key={selectedGameTypeTab}
              type={Constants.GAMES_TAB_BAR}
              gamesTab={true}
              isInfiniteTabbar={false}
              fullWidth={true}
              slidesPerView={4}
              tabItems={tabbarItemsList}
              selectedTab={selectedGameTypeTab}
              onTabChange={handleGameTypeTabChange}
            />
          </GamesTabbarWrapper>
        </LobbyDetailsHeaderWrapper>

        {showLobbyLoading ? (
          <LoadingViewContainer>
            <Skeleton
              baseColor={colors.primaryBlue}
              highlightColor={colors.tempNavy2}
              style={{ margin: "6px auto" }}
              count={10}
              height={85}
              borderRadius={6}
            />
          </LoadingViewContainer>
        ) : (
          <>
            <HappyHoursCashBanner />
            <Swiper
              loop={true}
              slidesPerView={1}
              simulateTouch={true}
              ref={swiperRef}
              initialSlide={tabbarItemsList.findIndex(
                (item) => item.value === selectedGameTypeTab
              )}
              // onSlideChange={(data) =>
              //   handleGameTypeTabChange(data, true)
              // }
              onSlideChangeTransitionEnd={(data) =>
                handleGameTypeTabChange(data, true)
              }
            >
              {activeGameIds?.length > 0 && (
                <ActiveGamesStickyButton
                  tileScrollStatus={tileScrollStatus}
                  toggleGameTable={
                    () =>
                      // startTransition(() => {
                      setTempShowGameTable(true)
                    // })
                  }
                />
              )}
              {tabbarItemsList.map((tab, index) => {
                return (
                  <SwiperSlide
                    key={`gameTileSwiperSlide-${index}-${tab.value}`}
                  >
                    <LobbyGameTilesList
                      isCashGameToggled={
                        Constants.FUN_CHANNELS.includes(channel)
                          ? false
                          : isCashGamesToggled
                      }
                      tab={tab.value}
                      setTileScrollStatus={setTileScrollStatus}
                      gameTileClickHandler={onGameTileClick}
                      gameType={tab.value}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </>
        )}
      </LobbyDetailsContainer>
    );
  };

  // const createHappyHoursLobbyTile = () => {
  //   // Condition for rendering happy hours game tile can be here
  //   return (
  //     <HappyHoursLobbyContainer>
  //       <HappyHoursLobbySection>
  //         <HappyHoursTitle>
  //           <HappyHoursGoldGlass src={happyHoursGoldImg} />
  //           Happy Hours Live
  //           <HappyHoursTimeContainer start={false}>
  //             Ends in 10:05
  //           </HappyHoursTimeContainer>
  //         </HappyHoursTitle>
  //         <HappyHoursSubtitle>
  //           Play More, Win More - It's Zero Rake Hours!
  //         </HappyHoursSubtitle>
  //       </HappyHoursLobbySection>
  //       <HappyHoursLobbySection>
  //         <HappyHoursSortContainer
  //           onClick={() => setHappyHoursSort(!happyHoursSort)}
  //         >
  //           Sort Bets
  //           <HappyHoursToggle
  //             src={happyHoursSort ? happyHoursOn : happyHoursOff}
  //           />
  //         </HappyHoursSortContainer>
  //       </HappyHoursLobbySection>
  //     </HappyHoursLobbyContainer>
  //   );
  // };

  const removeSitAndGoMissedTourneysFromSelectedTabList = (tourneyIdsList) => {
    return [...tourneyIdsList].filter((tourneyId) => {
      const eachTourney = masterTourneysData.get(tourneyId); // Get full tourney details

      if (
        eachTourney?.tourneyStatus === Constants.TOURNEY_STATUS_MISSED &&
        eachTourney?.tournamentType === Constants.SIT_AND_GO_TOURNEYS
      ) {
        return false; // Exclude missed Sit & Go tournaments
      }

      return true; // Keep all other tourneys
    });
  };

  // const getTourneyDataByTypeAndVariant = (
  //   activeTourneyTab,
  //   activeTourneyVariant
  // ) => {
  //   const { MY_TOURNEYS, ACTIVE_TOURNEYS, COMPLETED_TOURNEYS } = Constants;
  //   let tourneysList = [];
  //   switch (activeTourneyTab) {
  //     case MY_TOURNEYS:
  //       if (
  //         activeTourneyVariant === updatedTourneyTabs?.[selectedTourneysTab][0]
  //       ) {
  //         return [...myTourneys];
  //       } else {
  //         tourneysList = myTourneys.filter(
  //           (eachTourney) => eachTourney.tournamentType === activeTourneyVariant
  //         );
  //         return tourneysList;
  //       }
  //     case ACTIVE_TOURNEYS:
  //       if (
  //         activeTourneyVariant === updatedTourneyTabs?.[selectedTourneysTab][0]
  //       ) {
  //         return [...upcomingTourneys];
  //       } else {
  //         tourneysList = upcomingTourneys.filter(
  //           (eachTourney) => eachTourney.tournamentType === activeTourneyVariant
  //         );
  //         return tourneysList;
  //       }

  //     case COMPLETED_TOURNEYS:
  //       if (
  //         activeTourneyVariant === updatedTourneyTabs?.[selectedTourneysTab][0]
  //       ) {
  //         return [...completedTourneys];
  //       } else {
  //         tourneysList = completedTourneys.filter(
  //           (eachTourney) => eachTourney.tournamentType === activeTourneyVariant
  //         );
  //         return tourneysList;
  //       }
  //     default:
  //       return tourneysList;
  //   }
  // };
  const getTourneyDataByTypeAndVariant = (
    activeTourneyTab,
    activeTourneyVariant
  ) => {
    const { MY_TOURNEYS, ACTIVE_TOURNEYS, COMPLETED_TOURNEYS } = Constants;

    const normalizeTourneyType = (type) =>
      type.toLowerCase().includes("flight") ? "Flights" : type;

    let tourneyIdentifier;
    switch (activeTourneyTab) {
      case MY_TOURNEYS:
        tourneyIdentifier = "myTourneys";
        break;
      case ACTIVE_TOURNEYS:
        tourneyIdentifier = "upcomingTourneys";
        break;
      case COMPLETED_TOURNEYS:
        tourneyIdentifier = "completedTourneys";
        break;
      default:
        return new Set();
    }

    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0);
    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);

    let filteredTourneys = new Map(
      [...masterTourneysData.entries()].filter(([tourneyId, tourney]) => {
        const isMatchingTab = tourney.tourneyIdentifier === tourneyIdentifier;
        const isMatchingVariant =
          activeTourneyVariant === tourneyTabs[0] ||
          normalizeTourneyType(tourney.tournamentType) === activeTourneyVariant;

        //  Always include Flight Parents **if** they belong to `tourneyIdentifier`

        if (tourney.isFlightParentTourney && isMatchingTab && isMatchingVariant)
          return true;

        // Include Flight Tourneys **if** they belong to `tourneyIdentifier` and are relevant
        const shouldIncludeFlight =
          tourney.isFlightTourney &&
          isMatchingTab &&
          (tourney.highlighted ||
            (new Date(tourney.tourneyStartTime) >= todayStart &&
              new Date(tourney.tourneyStartTime) <= todayEnd));

        return isMatchingTab && (isMatchingVariant || shouldIncludeFlight);
      })
    );

    console.log("ganesh_final", [...filteredTourneys.keys()]);
    return filteredTourneys.keys();
  };

  const handleTourneyRegister = (selectedTourney) => {
    if (!showTourneyRegisterOrDeregisterRequestLoading) {
      const { userName, IP, userId, avatar, deviceId, state,playerStatus } = playerDetails;

      if (
        playerStatus === Constants.PSEUDO_LOCKED_PLAYER ||
        playerStatus === Constants.PSEUDO_EXPIRED_PLAYER
      ) {
        return dispatch(
          openModal({
            type: Constants.RESTRICTED_ACCESS,
          })
        );
      }
      // if (!playerDetails.isAllowLocation) {
      if (pushToKyc && cashGames_banner) {
        messageToNativeClient({
          type: "showKyc",
          from: "ticket",
          show: true,
        });
        return;
      }
      else if (!location_games) {
        if (userLocationCompliance) {
          return dispatch(
            openModal({
              type: Constants.RESTRICTED_LOCATION_HOURS,
            })
          );
        }
        else {
          return dispatch(
            openModal({
              type: Constants.RESTRICTED_LOCATION
            }));
        }
      }
      // } 
      const requestPayload = {
        playerName: userName,
        tourneyId: selectedTourney?.tourneyId,
        channel: channel,
        appId: 135,
        ip: IP,
        userId: userId,
        profilePic: avatar,
        requestor: "client",
        entryType: 1, // For register entry type is 1
        deviceId: deviceId,
        state: state,
        a23Token: a23Token,
      };
      dispatch(
        updateShowTourneyRegisterOrDeregisterRequestLoading({
          isLoading: true,
        })
      );
      dispatch(
        updateTourneyTicketsClaimInProgress({
          isLoading: true,
          tourneyId: selectedTourney?.tourneyId,
        })
      );
      dispatch(
        fetchRequestTourneyRegistration({
          data: requestPayload,
          apiURL: tourneyRegisterAPIURL,
        })
      );
    }
  };

  const navigateToTourneyDetailsPageForTourney = (selectedTourney) => {
    dispatch(requestTourneyInfoData({ tourneyId: selectedTourney.tourneyId }));
    dispatch(
      requestTourneyEntriesDynamicData({ tourneyId: selectedTourney.tourneyId })
    );
    dispatch(
      requestTourneyTablesDynamicData({ tourneyId: selectedTourney.tourneyId })
    );
  };

  const renderAllTicketsDetails = () => {
    const tourneysDetails = [];
    tourneyTickets.forEach((eachTicket) => {
      const ticketTourney = getTourneyDefinitionByTourneyId(
        eachTicket.tourneyId
      );
      if (ticketTourney) {
        tourneysDetails.push(ticketTourney);
      }
    });
    const sortedTourneysList = tourneysDetails.sort(
      (currentTourney, nextTourney) => {
        // Define the order of statuses
        const statusOrder = { Claim: 1, Claimed: 2, Expired: 3, FULL: 4 }; // Added FULL as well to be handled

        // Get statuses for both tournaments
        const currentStatus = getTourneyTicketStatus(currentTourney);
        const nextStatus = getTourneyTicketStatus(nextTourney);

        // First, sort by status
        const statusComparison =
          statusOrder[currentStatus] - statusOrder[nextStatus];

        if (statusComparison !== 0) {
          // If statuses are different, return the status comparison result
          return statusComparison;
        }

        // If statuses are the same, sort by tourneyStartTime
        return currentTourney.tourneyStartTime - nextTourney.tourneyStartTime;
      }
    );

    return sortedTourneysList.map((ticketTourney) => {
      const {
        tourneyName,
        breakEven,
        dynamicPoolPrize,
        prizePool,
        buyIn,
        tourneyRake,
        tourneyStartTime,
        tourneyIdentifier,
        tourneyId,
        isFlightTourney,
        parentTourneyPrizePool,
      } = ticketTourney;
      return (
        <TicketsWrapper
          key={`tourney-${tourneyId}`}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();

            dispatch(updateShowAllTicketsSection({ isOpen: false }));
            navigateToTourneyDetailsPageForTourney(ticketTourney);
          }}
          bgImage={ticketBgImage}
        >
          <TicketTourneyDetailsWrapper>
            <TourneyDetailsWrapper>
              <TourneyNameText>{`${tourneyName}`}</TourneyNameText>
              <DividerImg src={HorizontalDivider} />
              <TourneyPrizeAndTicketDetailsWrapper>
                <TourneyPrizeWrapper>
                  <TicketDetailLabelText>
                    Tournament Prize
                  </TicketDetailLabelText>
                  <TicketDetailValueText>
                    {isFlightTourney
                      ? parentTourneyPrizePool > 0
                        ? `₹${parseDecimalValuesOfNumber(
                            parentTourneyPrizePool,
                            2,
                            checkToDisplayValueInK(parentTourneyPrizePool),
                            checkToDisplayValueInL(parentTourneyPrizePool)
                          )}`
                        : ""
                      : breakEven
                      ? dynamicPoolPrize > 0
                        ? `₹${parseDecimalValuesOfNumber(
                            dynamicPoolPrize,
                            2,
                            checkToDisplayValueInK(dynamicPoolPrize),
                            checkToDisplayValueInL(dynamicPoolPrize)
                          )}`
                        : ""
                      : prizePool > 0
                      ? `₹${parseDecimalValuesOfNumber(
                          prizePool,
                          2,
                          checkToDisplayValueInK(prizePool),
                          checkToDisplayValueInL(prizePool)
                        )}`
                      : ""}
                  </TicketDetailValueText>
                </TourneyPrizeWrapper>
                {buyIn + tourneyRake > 0 && (
                  <>
                    <TicketVerticalLine src={VerticalDivider} />
                    <TourneyTicketValueWrapper>
                      <TicketDetailLabelText>
                        Ticket Value
                      </TicketDetailLabelText>
                      <TicketDetailValueText>
                        {buyIn + tourneyRake}
                      </TicketDetailValueText>
                    </TourneyTicketValueWrapper>
                  </>
                )}
              </TourneyPrizeAndTicketDetailsWrapper>
            </TourneyDetailsWrapper>
            <TicketDashedDividerLine src={DashedDivider} />
            <TicketStatusWrapper>
              <TicketDetailLabelText>
                {tourneyIdentifier !== "completedTourneys"
                  ? `Tourney starts at`
                  : `Tourney started at`}
              </TicketDetailLabelText>
              <ExpiryText>
                {formatTourneyDate(tourneyStartTime, "Do MMM, h:mmA")}
              </ExpiryText>
              {getTourneyTicketStatusByTourneyData(ticketTourney)}
            </TicketStatusWrapper>
          </TicketTourneyDetailsWrapper>
        </TicketsWrapper>
      );
    });
  };
  const handleTicketClick = () => {
    const MixPanel_params = getEventParams(
      MP_POKER_TOURNEY_MY_TICKETS_ICON_CLICKED,
      playerDetails,
      userBalance,
      channel,
      {}
    );

    messageToNativeClient({
      type: Constants.MIXPANEL_EVENT,
      eventName: MP_POKER_TOURNEY_MY_TICKETS_ICON_CLICKED,
      eventProperties: MixPanel_params,
    });
  };
  const getTourneyGameTilesByType = (
    activeTourneyTab,
    activeTourneyVariant
  ) => {
    if (showTourneyLobbyLoading) {
      return (
        <TourneyLobbySkeletonLoadingContainer>
          <Skeleton
            baseColor={colors.primaryBlue}
            highlightColor={colors.tempNavy2}
            style={{ margin: "7px 0px" }}
            count={10}
            height={65}
            borderRadius={8}
          />
        </TourneyLobbySkeletonLoadingContainer>
      );
    }
    return (
      <Swiper
        loop={true}
        slidesPerView={1}
        simulateTouch={true}
        ref={tourneyLobbySwiperRef}
        initialSlide={getTourneyVariantTabbbarItems().findIndex(
          (eachTabVariant) => eachTabVariant.value === selectedTourneyVariant
        )}
        onSlideChangeTransitionEnd={(data) =>
          handleTourneysVariantChange(data.realIndex, true)
        }
      >
        {getTourneyVariantTabbbarItems().map((tourneyTypeVariant) => (
          <SwiperSlide
            key={`tourney-tiles-list-${activeTourneyTab}-${tourneyTypeVariant.value}`}
          >
            <TourneyGameTilesList
              selectedTourneyVariant={selectedTourneyVariant}
              isActiveTabList={
                selectedTourneysTab === Constants.ACTIVE_TOURNEYS
              }
              isCompletedTabList={
                selectedTourneysTab === Constants.COMPLETED_TOURNEYS
              }
              tourneysIdsList={removeSitAndGoMissedTourneysFromSelectedTabList(
                getTourneyDataByTypeAndVariant(
                  activeTourneyTab,
                  tourneyTypeVariant.value
                )
              )}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };

  const getTourneyTicketStatus = (ticketTourney) => {
    const {
      tourneyStatus,
      isAlreadyRegisteredTourney,
      lateRegistrationAllowed,
      lateRegistrationStatus,
      registeredPlayerCount,
      maxPlayers,
      inTheMoney,
      tourneyId,
    } = ticketTourney;

    if (registeredPlayerCount === maxPlayers) {
      return "FULL";
    }

    switch (tourneyStatus) {
      case Constants.TOURNEY_STATUS_LIVE:
        if (
          lateRegistrationAllowed &&
          lateRegistrationStatus !== "FINISHED" &&
          !inTheMoney
        ) {
          return isAlreadyRegisteredTourney ? "Claimed" : "Claim";
        } else if (isAlreadyRegisteredTourney) {
          return "Claimed";
        } else {
          return "Expired";
        }
      case Constants.TOURNEY_ANNOUNCED:
      case Constants.TOURNEY_REGISTRATION_STARTED:
        return isAlreadyRegisteredTourney ? "Claimed" : "Claim";
      default:
        return "Expired";
    }
  };

  const getTourneyTicketStatusByTourneyData = (ticketTourney) => {
    const {
      tourneyStatus,
      isAlreadyRegisteredTourney,
      lateRegistrationAllowed,
      lateRegistrationStatus,
      registeredPlayerCount,
      maxPlayers,
      inTheMoney,
    } = ticketTourney;
    if (registeredPlayerCount === maxPlayers) {
      return (
        <ClaimedWrapper>
          <RegisrationsFullText>FULL</RegisrationsFullText>
        </ClaimedWrapper>
      );
    } else {
      switch (tourneyStatus) {
        case Constants.TOURNEY_STATUS_LIVE:
          if (
            lateRegistrationAllowed &&
            lateRegistrationStatus !== "FINISHED" &&
            !inTheMoney
          ) {
            return isAlreadyRegisteredTourney ? (
              <ClaimedWrapper>
                <DoneIconText src={GreenDoneIcon} />

                <ClaimText>Claimed</ClaimText>
              </ClaimedWrapper>
            ) : (
              <ClaimButtonWrapper
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleTourneyRegister(ticketTourney);
                }}
              >
                {tourneyTicketsClaimInProgressDetails &&
                tourneyTicketsClaimInProgressDetails[
                  `${ticketTourney.tourneyId}`
                ] ? (
                  <Loader size={20} />
                ) : (
                  <ClaimText>Claim</ClaimText>
                )}
              </ClaimButtonWrapper>
            );
          } else if (isAlreadyRegisteredTourney) {
            return (
              <ClaimedWrapper>
                <DoneIconText src={GreenDoneIcon} />

                <ClaimText>Claimed</ClaimText>
              </ClaimedWrapper>
            );
          } else {
            return <ExpiredText>Expired</ExpiredText>;
          }
        case Constants.TOURNEY_ANNOUNCED:
        case Constants.TOURNEY_REGISTRATION_STARTED:
          return isAlreadyRegisteredTourney ? (
            <ClaimedWrapper>
              <DoneIconText src={GreenDoneIcon} />

              <ClaimText>Claimed</ClaimText>
            </ClaimedWrapper>
          ) : (
            <ClaimButtonWrapper
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleTourneyRegister(ticketTourney);
              }}
            >
              {tourneyTicketsClaimInProgressDetails &&
              tourneyTicketsClaimInProgressDetails[ticketTourney.tourneyId] ? (
                <Loader size={20} />
              ) : (
                <ClaimText>Claim</ClaimText>
              )}
            </ClaimButtonWrapper>
          );
          {
            /* <ExpiredText>Expired</ExpiredText> */
          }
        default:
          //TODO:By default we will display it as expired. not required, we can remove by checking with product
          return <ExpiredText>Expired</ExpiredText>;
      }
    }
  };

  const handleBannerClick = (item, index) => {
    CleverTapUtil.getInstance().logEvent(
      (item?.displayType?.includes("promo")
        ? PR_Lobby_IPromotions
        : PR_Lobby_Banner) +
        (index + 1),
      {
        Username: userName,
        Channel: channel,
        Timestamp: getISTDateTimeFormat(),
        RedirectionName: item?.redirectionData?.Location,
      }
    );
    if (item.Location) {
      dispatch(handleDeeplink({ deepLink: item }));
    } else if (item.redirectionData) {
      if (
        item?.redirectionData?.Location?.toLowerCase() ===
        "redirectioninsideapp"
      ) {
        let webUrl = item?.redirectionData?.webUrl;
        if (webUrl) {
          messageToNativeClient({
            type: "redirection",
            kind: "internal",
            url: webUrl,
          });
          return;
        }
      }
      let newRedirectionObject = {};
      let redirectionObjectKeys = Object.keys(item.redirectionData);
      for (let key of redirectionObjectKeys) {
        newRedirectionObject[key] = item.redirectionData[key].replace(
          /\s/g,
          ""
        );
        // console.log("New Item is ", key, newRedirectionObject[key]);
      }
      dispatch(handleDeeplink({ deepLink: newRedirectionObject }));
    }
  };

  const renderPrmotionPage = () => {
    console.log("DATA_UPDATED_LOBBY ", PKNFPromotionalBanners);
    return (
      <PromotionContainer>
        <PromotionContent id='promotions-content'>
          {PKNFPromotionalBanners.length > 0 ? (
            <>
              {" "}
              {PKNFPromotionalBanners &&
                PKNFPromotionalBanners.map((promoObj, index) => (
                  <PromotionsBanner
                    key={"promobanner-" + index}
                    src={promoObj.imageUrl}
                    onClick={() => handleBannerClick(promoObj, index)}
                  />
                ))}
            </>
          ) : (
            <PromotionZeroTextDisplay>
              Exciting offers loading for you
            </PromotionZeroTextDisplay>
          )}
        </PromotionContent>
      </PromotionContainer>
    );
  };

  const renderTourneysPage = () => {
    return (
      <TourneysContainer>
        <TourneysHeaderWrapper>
          {/* <TourneyTabsContainer>
            {tourneyTabbarItemsList.map((eachTourneyTab) => (
              <TourneyTabItemWrapper>
                <TourneyTabItemText
                  isSelected={selectedTourneysTab === eachTourneyTab.value}
                  onClick={() => {
                    handleTourneysTabChange(eachTourneyTab.value);
                  }}
                >
                  <GoldArrowIconWrapper
                    isSelected={selectedTourneysTab === eachTourneyTab.value}
                    src={goldArrow}
                  />

                  {eachTourneyTab.label}
                </TourneyTabItemText>
              </TourneyTabItemWrapper>
            ))}
          </TourneyTabsContainer> */}
          {/* <TourneyTicketsWrapper
            isTicketsAvailable={tourneyTickets.length > 0}
            onClick={() => {
              setOpenTicketsModal(true);
            }}
          >
            <TicketsIconWrapper src={goldTicket} />
            {tourneyTickets.length > 0 && (
              <TicketsCountText>{`(${tourneyTickets.length})`}</TicketsCountText>
            )}
          </TourneyTicketsWrapper> */}
          <TourneyTabsContainer>
            <Tabbar
              key={selectedTourneysTab}
              type={Constants.TOURNEYS_TAB_BAR}
              isInfiniteTabbar={false}
              tabItems={tourneyTabbarItemsList}
              selectedTab={selectedTourneysTab}
              onTabChange={handleTourneysTabChange}
            />
            <TourneyTicketsWrapper
              id='my-tickets-icon'
              isTicketsAvailable={tourneyTickets.length > 0}
              onClick={() => {
                // if (!playerDetails.isAllowLocation) {
                if (pushToKyc && cashGames_banner) {
                  messageToNativeClient({
                    type: "showKyc",
                    from: "ticket",
                    show: true,
                  });
                  return;
                }
                else if (!location_games) {
                  if (userLocationCompliance) {
                    return dispatch(
                      openModal({
                        type: Constants.RESTRICTED_LOCATION_HOURS,
                      })
                    );
                  }
                  else {
                    return dispatch(
                      openModal({
                        type: Constants.RESTRICTED_LOCATION,
                      })
                    );
                  }
                }
                else {
                  return dispatch(updateShowAllTicketsSection({ isOpen: true }));
                }
                // else if (pushToKyc && cashGames_banner) {
                //   messageToNativeClient({
                //     type: "showKyc",
                //     from: "ticket",
                //     show: true,
                //   });
                //   return;
                // } 
              }}
            >
              {tourneyTickets.length > 0 && (
                <ShimmerLightingContainer>
                  <ShimmerThin></ShimmerThin>
                  <ShimmerFat></ShimmerFat>
                </ShimmerLightingContainer>
              )}
              <TicketsIconWrapper src={goldTicket} />
              {tourneyTickets.length > 0 && (
                <TicketsCountText>{`(${tourneyTickets.length})`}</TicketsCountText>
              )}
            </TourneyTicketsWrapper>
          </TourneyTabsContainer>
        </TourneysHeaderWrapper>
        <ActiveGamesStickyButton
          tileScrollStatus={tileScrollStatus}
          toggleGameTable={
            () =>
              // startTransition(() => {
              setTempShowGameTable(true)
            // })
          }
        />
        {getTourneyVariantTabbbarItems()?.length > 0 && (
          <TabbarWrapper>
            <Tabbar
              type={Constants.TOURNEYS_VARIANT_TAB_BAR}
              isInfiniteTabbar={true}
              // useDefaultTabItemWrapper={true}
              slidesPerView={4}
              tabItems={getTourneyVariantTabbbarItems()}
              selectedTab={selectedTourneyVariant}
              onTabChange={handleTourneysVariantChange}
              isTourneySubTab
            />
          </TabbarWrapper>
        )}

        <TourneysGameTilesListContainer>
          {getTourneyGameTilesByType(
            selectedTourneysTab,
            selectedTourneyVariant
          )}
        </TourneysGameTilesListContainer>
        {showAllTicketsSection && (
          <ModalContainer
            onClick={() => {
              dispatch(updateShowAllTicketsSection({ isOpen: false }));
            }}
          >
            <TicketsModalContainer isOpen={showAllTicketsSection}>
              <TicketsHeaderContainer>
                <TicketsHeaderText>{`All Tickets  ${
                  tourneyTickets.length > 0
                    ? "(" + tourneyTickets.length + ")"
                    : ""
                } `}</TicketsHeaderText>
                <CloseIconWrapper
                  src={CloseIcon}
                  onClick={() => {
                    dispatch(updateShowAllTicketsSection({ isOpen: false }));
                  }}
                />
              </TicketsHeaderContainer>
              <TicketsBodyWrapper
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
              >
                {tourneyTickets.length === 0 && (
                  <NoTicketAvailableText>
                    You've no tickets at the moment.
                  </NoTicketAvailableText>
                )}

                {renderAllTicketsDetails()}
              </TicketsBodyWrapper>
            </TicketsModalContainer>
          </ModalContainer>
        )}
      </TourneysContainer>
    );
  };

  const renderCurrentActiveTab = (currentTab) => {
    const { home, games, tourneys, leaderboard, rules, more, promotions } =
      footerTabConstants;

    switch (currentTab) {
      case home:
        return (
          <Home
            tileScrollStatus={tileScrollStatus}
            toggleGameTable={
              () =>
                // startTransition(() => {
                setTempShowGameTable(true)
              // })
            }
            // tileRedirection={handleHomeLobbyTileRedirections}
            gameTileClick={onGameTileClick}
          />
        );
      case games:
        return renderPokerLobbyDetails();
      case tourneys:
        return renderTourneysPage();
      case promotions:
        return renderPrmotionPage();
      case leaderboard:
        // return <LeaderboardPage />;
        break;
      case rules:
        return null;
      case more:
        return "More view";
      default:
        // Added this to make sure there is a gap between the header and footer before we set an active tab (current tab) after checking channels
        return (
          <div
            style={{
              flex: 1,
              backgroundColor: "transparent",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader size={20} />
          </div>
        );
    }
  };

  const doActiveGamesContainFunGames = () => {
    let funGamesExist = false;
    for (let game in activeGameIds) {
      let tempTableId = activeGameIds[game];
      let gameData = activeGames[tempTableId];
      if (gameData?.isFunGame) {
        funGamesExist = true;
        break;
      }
    }
    return funGamesExist;
  };

  const onGameTileClick = (gameDefinition) => {
    console.log("gameDefinition: ", gameDefinition);
    // console.log("FIRST playerDetails: ", playerDetails);
    // console.log("PLATYER DETAIULS: ", getLobbySlice().player);
    // let playerDetails = getLobbySlice().player;
    // let userBalance = getLobbySlice().balance;
    //to be worked on @yashwanth
    // if(playerDetails.playerStatus === Constants.TO_BE_DELETED_PLAYER || playerDetails.playerStatus === Constants.DELETED_PLAYER){
    //   dispatch(
    //     openModal({
    //       type: Constants.DELETED_PLAYER_LOGOUT,
    //     })
    //   );
    //   return;
    // }

    // if (!transitionPending) {
    let timeStamp = getISTDateTimeFormat();
    // CT Event for Game Tile / Play Now click
    logCleverTapEvent(IP_Client_Play_now, {
      Username: playerDetails.userName,
      Channel: channel,
      "Wallet balance": userBalance.totalBalance,
      "Cash / Fun":
        gameDefinition?.flavourType === Constants.LB_REAL ? "Cash" : "Fun",
      "Entry fee": gameDefinition?.minBuyInAmount,
      "No. of players": gameDefinition?.maxPlayers,
      GameID: gameDefinition?.gameDefId,
      "Game Format": gameDefinition?.gameType,
      Blinds: `${gameDefinition?.smallBlind}/${gameDefinition?.bigBlind}`,
      "Buy-in": `${gameDefinition?.minBuyInAmount}`,
      "Play now timestamp": timeStamp,
    });
    if (
      gameDefinition?.flavourType?.toLowerCase() === Constants.LB_REAL &&
      (playerDetails?.userConsentFlag || playerDetails?.gameJoinCheckAck)
    ) {
      messageToNativeClient({ type: "gameJoinCheck" });
      return;
    }

    // First checking if players location is allowed before letting them add cash due to insufficient balance etc.

    if (gameDefinition.flavourType === Constants.LB_REAL) {
      // if (location_games) {
      //   return dispatch(
      //     openModal({
      //       type: Constants.RESTRICTED_LOCATION_HOURS,
      //     })
      //   );
      // }
      // if (!playerDetails?.isAllowLocation) {
      //   if (pushToKyc && cashGames_banner) {
      //     messageToNativeClient({
      //       type: "showKyc",
      //       from: "game",
      //       show: true,
      //     });
      //     return;
      //   }
      //   else if (userLocationCompliance && !location_games) {
      //     return dispatch(
      //       openModal({
      //         type: Constants.RESTRICTED_LOCATION_HOURS,
      //       })
      //     );
      //   }
      //   dispatch(
      //     openModal({
      //       type: Constants.RESTRICTED_LOCATION,
      //     })
      //   );
      //   return;
      // } 
      if (pushToKyc && cashGames_banner) {
        messageToNativeClient({
          type: "showKyc",
          from: "game",
          show: true,
        });
        return;
      }
      else if (!location_games) {
        if (userLocationCompliance) {
          return dispatch(
            openModal({
              type: Constants.RESTRICTED_LOCATION_HOURS,
            })
          );
        }
        else {
         return dispatch(
            openModal({
              type: Constants.RESTRICTED_LOCATION,
            })
          );
        }
      }
      else if (
        playerDetails.playerStatus === Constants.PSEUDO_LOCKED_PLAYER 
      ) {
        return dispatch(
          openModal({
            type: Constants.RESTRICTED_ACCESS,
          })
        );
      }
    }
      // else if (pushToKyc && cashGames_banner) {
      //   messageToNativeClient({
      //     type: "showKyc",
      //     from: "game",
      //     show: true,
      //   });
      //   return;
      // }
      // else if (userLocationCompliance && !location_games) {
      //   return dispatch(
      //     openModal({
      //       type: Constants.RESTRICTED_LOCATION_HOURS,
      //     })
      //   );
      // }
    

    //   if (gameDefinition.flavourType === Constants.LB_REAL) {
    //     // if(location_games){
    //     //   return dispatch(
    //     //     openModal({
    //     //       type: Constants.RESTRICTED_LOCATION_HOURS,
    //     //     })
    //     //   );
    //     //   }
    //     //if pushtokyc is true and cashgames banner is true, showkyc
    //     if (pushToKyc && cashGames_banner) {
    //       messageToNativeClient({
    //         type: "showKyc",
    //         from: "game",
    //         show: true,
    //       });
    //       return;
    //     }
    // //if pushtokyc is true and user in compliant state and games aren't allowed, then showkyc
    //     else if(pushToKyc && userLocationCompliance && !location_games){
    //       messageToNativeClient({
    //         type: "showKyc",
    //         from: "game",
    //         show: true,
    //       });
    //       return;
    //     }
    //       //if pushtokyc is false and user in compliant state and games aren't allowed, then show restriction pop-up
    //     else if (!pushToKyc && userLocationCompliance && !location_games) {
    //       return dispatch(
    //         openModal({
    //           type: Constants.RESTRICTED_LOCATION_HOURS,
    //         })
    //       );
    //     } else {
    //       if (!playerDetails?.isAllowLocation) {
    //         dispatch(
    //           openModal({
    //             type: Constants.RESTRICTED_LOCATION,
    //           })
    //         );
    //         return;
    //       } else if (playerDetails.playerStatus === "plocked") {
    //         return dispatch(
    //           openModal({
    //             type: Constants.RESTRICTED_ACCESS,
    //           })
    //         );
    //       }
    //     }
    //     // if (!playerDetails?.isAllowLocation) {
    //     //   dispatch(
    //     //     openModal({
    //     //       type: Constants.RESTRICTED_LOCATION,
    //     //     })
    //     //   );
    //     //   return;
    //     // } else if (playerDetails.playerStatus === "plocked") {
    //     //   return dispatch(
    //     //     openModal({
    //     //       type: Constants.RESTRICTED_ACCESS,
    //     //     })
    //     //   );
    //     // }
    //   }
    setGameDefinition(gameDefinition);
    if (isPlayerJoiningFromLB) {
      dispatch(setIsPlayerJoiningFromLB({ flag: false }));
    }
    if (
      deeplinkData?.Location?.toLowerCase() ===
      Constants.GAME_TABLE.toLowerCase()
    ) {
      dispatch(clearDeeplink());
    }
    if (
      gameDefinition.flavourType === Constants.LB_REAL &&
      userBalance.totalBalance < gameDefinition.minBuyInAmount
    ) {
      dispatch(
        openModal({
          type: Constants.INSUFFICIENT_BALANCE,
          // tempTableId: activeGameTable,
          config: {
            amountToAdd:
              gameDefinition.minBuyInAmount - userBalance.totalBalance,
            totalBalance: userBalance?.totalBalance,
            depositAndWinnings:
              Number(userBalance?.depositBalance) +
              Number(userBalance?.redeemableBalance),
            usableBonus: userBalance?.bonus,
            buyInAmount: gameDefinition.minBuyInAmount,
            isTourneyGame: false,
            blindsText: `(${returnGameTypeText(gameDefinition.gameType)} ${
              gameDefinition.smallBlind
            }/${gameDefinition.bigBlind})`,
          },
        })
      );
    } else if (
      gameDefinition.flavourType === Constants.LB_PLAY &&
      userBalance.playChips < gameDefinition.minBuyInAmount
    ) {
      dispatch(
        openModal({
          type: Constants.INSUFFICIENT_FUN_CHIPS_BALANCE,
        })
      );
    } else {
      handleGameCreationLogic(gameDefinition);
    }
    try {
      if (isHapticsEnabled) {
        GameAudio.getInstance().playVibration(Constants.VIBRATE_LIGHT);
      }
    } catch (e) {}
  };

  const handleGameCreationLogic = (gameDefinition) => {
    // Returning since this is where the transition for a game creation is still in progress.
    if (creatingNewGame) {
      return;
    }
    // Do not create game if 4 objects exist (max 4 games at a time)
    if (activeGameIds.length === 4) {
      dispatch(
        openModal({
          type: Constants.MAX_TABLE_LIMIT,
          // tempTableId: activeGameTable,
        })
      );
    }
    // If it is fun game, make sure there is only 1 in his active games.
    if (gameDefinition?.flavourType === Constants.LB_PLAY) {
      if (doActiveGamesContainFunGames()) {
        dispatch(
          openModal({
            type: Constants.MAX_FUN_GAMES_LIMIT,
            // tempTableId: activeGameTable,
          })
        );
        setGameDefinition(null);
        return;
      }
    }
    // Create new game if above checks pass
    if (activeGameIds.length < 4 && !creatingNewGame) {
      setCreatingNewGame(true);
      initiateCreateTable(gameDefinition);
      setGameDefinition(null);
      return;
    }
    return;
  };

  const gameToLobbyCallBack = (paramsObject = {}) => {
    if (paramsObject.type === Constants.CLOSE_GAME_WINDOW_CALL_BACK) {
      setTempTableId(activeGameTable);
      if (activeGameTable && gamesAvailable) {
      } else {
        // startTransition(() => {
        setTempShowGameTable(false);
        // });
      }
      dispatch(
        closeGameWindow({
          tempTableId: paramsObject.tempTableId,
          removeTable: true,
        })
      );
    }
    // else if (paramsObject.type === Constants.HIDE_GAME_WINDOW_CALL_BACK) {
    //   // startTransition(() => {
    //   setTempShowGameTable(false);
    //   // });
    //   if (activeGameIds.length >= 1) {
    //     dispatch(watchAllGameTables({ dispatchFunction: dispatch }));
    //   }
    // }
    else if (paramsObject.type === "MINI_GAME_TABLE_CALL_BACK") {
      dispatch(updateActiveGame(paramsObject.tempTableId));
      // startTransition(() => {
      setTempShowGameTable(true);
      // });
    }
    // else if (paramsObject.type === "testCall") {
    //   console.log("WHILE PASSING playerDetails: ", playerDetails);
    //   onGameTileClick({
    //     orderId: 3,
    //     gameDefId: 296,
    //     gameMode: "real",
    //     poolId: "296",
    //     gameType: "TEXAS_HOLDEM",
    //     tableName: "test",
    //     flavourType: "real",
    //     smallBlind: 0.25,
    //     bigBlind: 0.5,
    //     isWatchPlayerEnable: true,
    //     tableTime: 25,
    //     startGameTime: 0,
    //     nextGameTimer: 0,
    //     minBuyInAmount: 25,
    //     maxbuyInAmount: 950,
    //     isRITEnabled: false,
    //     maxPlayers: 6,
    //     maxJoinAndWatchPlayers: 9,
    //     maxWatchPlayersCount: 7,
    //     maxJoinWaitListCount: 0,
    //     isWaitListEnabled: false,
    //     isSplitEnabled: false,
    //     minPlayersToStartGame: 2,
    //     isEnabled: true,
    //     status: "Active",
    //     maxTables: 0,
    //     onlineCount: 1,
    //     channelApplicable: ["IPAPS", "IPIPS"],
    //     isStraddleEnabled: false,
    //     isSNG: false,
    //     sgtExtraTime: 0,
    //     sgtETCount: 0,
    //     rake: 15,
    //     headsUpRake: 10,
    //     sitOutTimeInMins: 5,
    //     reserveTime: 15,
    //     rakeCapForStartPlayersLETo3: 25,
    //     rakeCapForStartPlayersGT3: 32,
    //     limitType: "NL",
    //     buyInTimer: 0,
    //     rebuyInTimer: 0,
    //     timeBank: 0,
    //     extraTimeBank: 5,
    //     isRankSuggestionEnabled: false,
    //     anonymous: false,
    //     leaderboard: false,
    //     turbo: false,
    //     prizePotLimit: "NL",
    //     bonusPercentage: 100,
    //     antiBankingTime: 0,
    //     lbGameDefId: "1_1_296",
    //     passiveUserSitOutCount: 0,
    //   });
    // }
  };

  return (
    // RENDER METHOD
    <>
      {tempShowGameTable ? (
        <GameTables
          accessToken={a23TokenValue}
          gameDefinition={gameDefinition}
          gameToLobbyCallBack={gameToLobbyCallBack}
          tempTableIdProp={tempTableId}
          activeGamesLength={activeGameIds.length}
          playerStatus={playerDetails.playerStatus}
        />
      ) : showTourneyInfoPage ? (
        <TourneyInfo
          key={`ID-${selectedTourneyDetailsId}`}
          toggleGameTable={
            () =>
              // startTransition(() => {
              setTempShowGameTable(true)
            // })
          }
        />
      ) : (
        <HomeLayout
          isWebView={isWebView}
          header={renderHeader()}
          footer={renderFooter()}
        >
          <Container id={"lobby-parent"}>
            <LobbyChildrenWrapper id={"lobby-children"}>
              {/* {renderCourosalBanners()} */}
              {/* {renderPokerLobbyDetails()} */}
              {renderCurrentActiveTab(currentTab)}
            </LobbyChildrenWrapper>
            {openHowToPlayPage && (
              <FullScreenPageContainer
                url={
                  "https://a23.hdworks.in/poker/howtoplay.html?src=mapp&platformapp=Y&header=N"
                }
                title={"How to play"}
                handleBackButton={() => setOpenHowToPlayPage(false)}
              />
            )}
            {openFAQsPage && (
              <FullScreenPageContainer
                url={
                  "https://a23.hdworks.in/poker/faqs.html?src=mapp&platformapp=Y&header=N"
                }
                title={"FAQs"}
                handleBackButton={() => setOpenFAQsPage(false)}
              />
            )}
            {openQuizPage && (
              <FullScreenPageContainer
                url={"https://a23.hdworks.in/poker/quiz.html"}
                title={"Quiz"}
                handleBackButton={() => setOpenQuizPage(false)}
              />
            )}
          </Container>
          {showFooterMoreSectionModal && renderFooterMoreSectionModal()}
        </HomeLayout>
      )}
      <Toast isLobbyToasts={true} />
      {transitionPending ? (
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0,0,0,0.3)",
          }}
        >
          <Loader size={25} />
        </div>
      ) : null}
      {!tempShowGameTable ? <LobbyModalComponent /> : null}
    </>
  );
};

export default PokerLobby;
