import { cloneDeep, min } from "lodash";
import axios from "axios";
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";

import LobbyConnection from "../../io/LobbyConnection";
import Configuration from "../../io/Configuration";
import BaseClient from "../../io/BaseClient";
import LobbyListener from "../../io/LobbyListener";

import LobbyService from "../services/LobbyService";
import LobbyDataHandler from "../../handlers/lobby/LobbyDataHandler";
import LobbyConnectionAckHandler from "../../handlers/lobby/LobbyConnectionAckHandler";
import HeartBeatLobbyHandler from "../../handlers/lobby/HeartBeatLobbyHandler";
import LackHandler from "../../handlers/lobby/LackHandler";
import {
  getFromLocalStorage,
  setToLocalStorage,
} from "../../../Common/utils/StorageUtils";
import {
  IS_HAPTICS_EBABLED,
  IS_HAPTICS_SUPPORT,
  IS_SOUND_ENABLED,
} from "../../data/Constants";
import LobbyReconnectionAckHandler from "../../handlers/lobby/LobbyReconnectionAckHandler";
import * as Constants from "../../data/Constants";
import GameDefinitionUpdateHandler from "../../handlers/lobby/GameDefinitionUpdateHandler";
import UpdatePlayerCountHandler from "../../handlers/lobby/UpdatePlayerCountHandler";
import TourneyLobbydataHandler from "../../handlers/lobby/TourneyLobbydataHandler";
import TourneyInfoAckHandler from "../../handlers/lobby/TourneyInfoAckHandler";
import TourneyRegisteredCountHandler from "../../handlers/lobby/TourneyRegisteredCountHandler";
import TourneyStatusChangeHandler from "../../handlers/lobby/TourneyStatusChangeHandler";
import TourneyPlayerStatusHandler from "../../handlers/lobby/TourneyPlayerStatusHandler";
import TourneyStartHandler from "../../handlers/lobby/TourneyStartHandler";
import DynamicTourneyLobbyDataHandler from "../../handlers/lobby/DynamicTourneyLobbyDataHandler";
import TourneyPrizeHandler from "../../handlers/lobby/TourneyPrizeHandler";
import TourneyCompleteHandler from "../../handlers/lobby/TourneyCompleteHandler";
import TourneyRanksDataHandler from "../../handlers/lobby/TourneyRanksDataHandler";
import TourneyCancleHandler from "../../handlers/lobby/TourneyCancleHandler";
import TourneyTableDataHandler from "../../handlers/lobby/TourneyTableDataHandler";
import GameActiveStatusHandler from "../../handlers/lobby/GameActiveStatusHandler";
import PlayerConfigHandler from "../../handlers/lobby/PlayerConfigHandler";
import TourneySlabChangeHandler from "../../handlers/lobby/TourneySlabChangeHandler";
import ChipsReloadAckHandler from "../../handlers/lobby/ChipsReloadAckHandler";
import TourneyPrizePoolUpdateHandler from "../../handlers/lobby/TourneyPrizePoolUpdateHandler";
import GetFunChipsBalanceAckHandler from "../../handlers/lobby/GetFunChipsBalanceAckHandler";
import TourneyLateRegEndedHandler from "../../handlers/lobby/TourneyLateRegEndedHandler";
import TourneyTicketsHandler from "../../handlers/lobby/TourneyTicketsHandler";
import { sendFaroLog } from "../../../Common/utils/FaroUtil";
import ActiveGamesHandler from "../../handlers/lobby/ActiveGamesHandler";
import TourneyPlayerAutoRegisteredHandler from "../../handlers/lobby/TourneyPlayerAutoRegisteredHandler";
import TourneyNewChildTourneyHandler from "../../handlers/lobby/TourneyNewChildTourneyHandler";
import TourneyRemoveHandler from "../../handlers/lobby/TourneyRemoveHandler";
import TourneyDynamicCountsUpdateHandler from "../../handlers/lobby/TourneyDynamicCountsUpdateHandler";
import { Poker_Redirection_Fail } from "../../data/ClevertapConstants";
import {
  checkIfFoldDeviceInMobileMode,
  getISTDateTimeFormat,
} from "../../../Common/utils/GameUtils";
import CleverTapUtil from "../../../Common/utils/CleverTapUtil";
import UpdateDepositorTourneyHandler from "../../handlers/lobby/UpdateDepositorTourneyHandler";
import TourneyInTheMoneyHandler from "../../handlers/lobby/TourneyInTheMoneyHandler";
import TourneyInBreakHandler from "../../handlers/lobby/TourneyInBreakHandler";
import TourneyDepositorTicketReceiveHandler from "../../handlers/lobby/TourneyDepositorTicketReceiveHandler";
import { messageToNativeClient } from "../../../Common/utils/platformCommunicationUtil";
import SpecialBetHandler from "../../handlers/lobby/SpecialBetHandler";
import SpecialBetGameActiveStatusHandler from "../../handlers/lobby/SpecialBetGameActiveStatusHandler";
import HandRankingTagLineHandler from "../../handlers/lobby/HandRankingTagLineHandler";
import BlockStateHandler from "../../handlers/lobby/BlockStateHandler";
import { logCleverTapEvent } from "../../../Common/utils/CleverTapUtilNew";
import { sendCreatePrivateTableProtocol } from "../../utils/ProtocolUtils";
import PrivateTableCreateGameDefAckHandler from "../../handlers/lobby/PrivateTableCreateGameDefAckHandler";
import PrivateTableCreateGameDefFailureHandler from "../../handlers/lobby/PrivateTableCreateGameDefFailureHandler";
import PrivateTablesLobbyDataHandler from "../../handlers/lobby/PrivateTablesLobbyDataHandler";
import DynamicPrivateTableLobbyDataHandler from "../../handlers/lobby/DynamicPrivateTableLobbyDataHandler";
import DynamicPrivateTableStatusChangeHandler from "../../handlers/lobby/DynamicPrivateTableStatusChangeHandler";
import PrivateTableGameDefInfoHandler from "../../handlers/lobby/PrivateTableGameDefInfoHandler";
import TourneyFlightQualifiedPlayersAckHandler from "../../handlers/lobby/TourneyFlightQualifiedPlayersAckHandler";
import TourneyFlightStackDetailsAckHandler from "../../handlers/lobby/TourneyFlightStackDetailsAckHandler";
import PrivateTableGameDefPlayerCount from "../../handlers/lobby/PrivateTableGameDefPlayerCount";
import PrivateTableGameDefInfoFailure from "../../handlers/lobby/PrivateTableGameDefInfoFailure";

export const fetchRequestTourneyRegistration = createAsyncThunk(
  "/lobbySlice/tourneyRegistration",
  async (requestDetails, { rejectWithValue }) => {
    try {
      sendFaroLog({
        requestType: "TOURNEY_REGISTER_INITIATE",
        requestPayload: requestDetails,
      });
      let response = await axios.post(
        requestDetails.apiURL,
        requestDetails.data
      );
      return response;
    } catch (e) {
      return rejectWithValue(requestDetails); //NOTE: need to change this.
    }
  }
);

export const fetchRequestTourneyDeRegistration = createAsyncThunk(
  "/lobbySlice/tourneyDeRegistration",
  async (requestDetails, { rejectWithValue }) => {
    try {
      sendFaroLog({
        requestType: "TOURNEY_DE_REGISTER_INITIATE",
        requestPayload: requestDetails,
      });
      let response = await axios.post(
        requestDetails.apiURL,
        requestDetails.data
      );
      return response;
    } catch (e) {
      return rejectWithValue(requestDetails); //NOTE: need to change this.
    }
  }
);

export const fetchTourneyReEntry = createAsyncThunk(
  "/lobbySlice/tourneyReEntry",
  async (requestDetails, { rejectWithValue }) => {
    try {
      sendFaroLog({
        requestType: "TOURNEY_RE_ENTRY_INTIATE",
        requestDetails: requestDetails,
      });
      let response = await axios.post(
        requestDetails.apiURL,
        requestDetails.data
      );
      return response;
    } catch (e) {
      return rejectWithValue(requestDetails);
    }
  }
);

export const fetchPrivateTableCreationConfigs = createAsyncThunk(
  "/lobbySlice/privateTableCreationConfigs",
  async (requestDetails, { rejectWithValue }) => {
    return handleAsyncThunk(requestDetails, rejectWithValue);
  }
);

export const privateTableJoinRequest = createAsyncThunk(
  "/lobbySlice/joinPrivateTable",
  async (requestDetails, { rejectWithValue }) => {
    return handleAsyncThunk(requestDetails, rejectWithValue);
  }
);

export const fetchPrivateTableTemplates = createAsyncThunk(
  "/lobbySlice/fetchPrivateTableTemplates",
  async (requestDetails, { rejectWithValue }) => {
    return handleAsyncThunk(requestDetails, rejectWithValue);
  }
);

export const deletePrivateTableTemplate = createAsyncThunk(
  "/lobbySlice/deletePrivateTableTemplate",
  async (requestDetails, { rejectWithValue }) => {
    return handleAsyncThunk(requestDetails, rejectWithValue);
  }
);

async function handleAsyncThunk(requestDetails, rejectWithValue) {
  try {
    const response = await axios.post(
      requestDetails.apiURL,
      requestDetails.payload,
      requestDetails.headers
    );
    return response;
  } catch (error) {
    return rejectWithValue(error.message || "Something went wrong");
  }
}

export const getTourneyRegisterFailureMessageByCode = (messageCode) => {
  const {
    REGISTER_SUCCESS,
    REGISTER_ACK_NULL,
    A23_TOKEN_DECRYPTION_FAILED_IN_REGISTER,
    TOURNEY_CHECK_FAILED,
    ERROR_IN_REGISTER_CONTROLLER,
    LOW_BALANCE,
    DE_REGISTER_SUCCESS,
    DE_REGISTER_ACK_NULL,
    PLAYER_NOT_FOUND_IN_TOURNEY,
    A23_TOKEN_DECRYPTION_FAILED_IN_DE_REGISTER,
    ERROR_IN_DE_REGISTER_CONTROLLER,
    TOURNEY_STARTED,
    TOAST_SUCCESS,
    TOAST_INFO,
    TOAST_ERROR,
    TOAST_WARNING,
    TOURNEY_REGISTRATIONS_FULL,
    TOURNEY_RE_ENTRY_SUCCESS,
    TOURNEY_RE_ENTRY_IN_THE_MONEY,
    TOURNEY_IN_THE_BUBBLE,
    TOURNEY_RE_ENTRY_COUNT_EXCEEDED,
    TOURNEY_RE_ENTRY_LEVEL_EXCEEDED,
    TOURNEY_RE_ENTRY_LOW_BALANCE,
    TOURNEY_RE_ENTRY_PLAYER_NOT_REGISTERED,
    TOURNEY_RE_ENTRY_PLAYER_NOT_BUSTED_OUT,
    TOURNEY_RE_ENTRY_TOURNEY_NOT_FOUND,
    TOURNEY_REGISTRATION_FAILED_DUE_TO_FAIR_PLAY_POLICY,
    TOURNEY_DIRECT_ENTRIES_FULL,
    TOURNEY_TICKET_ENTRIES_FULL,
    TOURNEY_RE_ENTRY_PROCESSING_FAILED,
    TOURNEY_FLIGHT_PARENT_REGISTER_FAILURE,
  } = Constants;
  switch (messageCode) {
    case REGISTER_SUCCESS:
      return {
        type: TOAST_SUCCESS,
        message: "Tourney Registration Successful",
      };
    case TOURNEY_RE_ENTRY_SUCCESS:
      return {
        type: TOAST_SUCCESS,
        message: "Tourney Re-entry Successful",
      };
    case REGISTER_ACK_NULL:
      return {
        type: TOAST_ERROR,
        message: "Failed to register, Please try again",
      };
    case TOURNEY_REGISTRATIONS_FULL:
      return {
        type: TOAST_ERROR,
        message: "Tourney Registrations are Full",
      };

    case TOURNEY_CHECK_FAILED:
      return {
        type: TOAST_ERROR,
        message: "Failed to register, Please try again",
      };

    case A23_TOKEN_DECRYPTION_FAILED_IN_REGISTER:
      return {
        type: TOAST_ERROR,
        message: "Failed to register, Please try again",
      };

    case ERROR_IN_REGISTER_CONTROLLER:
      return {
        type: TOAST_ERROR,
        message: "Failed to register, Please try again",
      };

    case LOW_BALANCE:
      return {
        type: TOAST_ERROR,
        message: "Failed to register due to insufficient funds",
      };
    case DE_REGISTER_SUCCESS:
      return {
        type: TOAST_SUCCESS,
        message: "Tourney has been deregistered",
      };
    case DE_REGISTER_ACK_NULL:
      return {
        type: TOAST_ERROR,
        message: "Failed to deregister, Please try again",
      };

    case PLAYER_NOT_FOUND_IN_TOURNEY:
      return {
        type: TOAST_ERROR,
        message: "Failed to deregister, Please try again",
      };

    case A23_TOKEN_DECRYPTION_FAILED_IN_DE_REGISTER:
      return {
        type: TOAST_ERROR,
        message: "Failed to deregister, Please try again",
      };
    case ERROR_IN_DE_REGISTER_CONTROLLER:
      return {
        type: TOAST_ERROR,
        message: "Failed to deregister, Please try again",
      };
    case TOURNEY_STARTED:
      return {
        type: TOAST_ERROR,
        message: "Tourney started, Failed to deregister",
      };
    case TOURNEY_RE_ENTRY_IN_THE_MONEY:
      return {
        type: TOAST_ERROR,
        message: "Tourney is in the bubble stage. Re-entry has been closed.",
      };
    case TOURNEY_IN_THE_BUBBLE:
      return {
        type: TOAST_ERROR,
        message:
          "Uh oh! We can't register you for this tourney right now. Tourney is currently in the bubble stage.",
      };
    case TOURNEY_DIRECT_ENTRIES_FULL:
      return {
        type: TOAST_ERROR,
        message: "Direct entries are full. Register through add cash",
      };
    case TOURNEY_TICKET_ENTRIES_FULL:
      return {
        type: TOAST_ERROR,
        message: "Unable to register. Tourney is full",
      };
    case TOURNEY_RE_ENTRY_COUNT_EXCEEDED:
    case TOURNEY_RE_ENTRY_LEVEL_EXCEEDED:
    case TOURNEY_RE_ENTRY_LOW_BALANCE:
    case TOURNEY_RE_ENTRY_PLAYER_NOT_REGISTERED:
    case TOURNEY_RE_ENTRY_PLAYER_NOT_BUSTED_OUT:
    case TOURNEY_RE_ENTRY_TOURNEY_NOT_FOUND:
      return {
        type: TOAST_ERROR,
        message: "Unable to process your Re-entry request",
      };
    case TOURNEY_RE_ENTRY_PROCESSING_FAILED:
      return {
        type: TOAST_ERROR,
        message:
          "Re-entry failed due to ongoing Player Rank updates. Please try again shortly.",
      };
    case TOURNEY_FLIGHT_PARENT_REGISTER_FAILURE:
      return {
        type: TOAST_ERROR,
        message: "Unable to process your request",
      };
    // case TOURNEY_REGISTRATION_FAILED_DUE_TO_FAIR_PLAY_POLICY:
    //   return {
    //     type: TOAST_ERROR,
    //     message:
    //       "We are unable to register you to the tournament due to our fair play policy. Apologies for the inconvenience caused.",
    //   };
  }
};

const initialState = {
  player: {
    userName: "",
    subscriptionType: "",
    state: "",
    level: "",
    isAllowLocation: "",
    initialisAllowLocation: "",
    avatar: "",
    IP: "",
    deviceId: "",
    latitude: "",
    longitude: "",
    userId: "",
    deepLink: {
      isAllowLocation: "true",
    },
    playerStatus: null,
  },
  balance: {
    totalBalance: "0.0",
    bonus: "0.0",
    depositBalance: "0.0",
    redeemableBalance: "0.0",
    playChips: 0,
  },
  displayName: "",
  currentTab: null,
  a23Token: "",
  accessToken: "",
  channel: "",
  isHapticsSupport: false,
  isWebView: true,
  isCleverTapEnabledFromNative: false,
  resourceLoadFailure: false,
  showLobbyLoading: true,
  showTourneyLobbyLoading: true,
  masterGameDefinitions: [],
  masterGameDefinitionsCount: 0,
  isHappyHoursEnabled: false,
  happyHoursGameCount: {
    total: 0,
    TEXAS_HOLDEM: 0,
    OMAHA: 0,
    OMAHA5: 0,
    OMAHA6: 0,
  },
  isFileSaveEnabled: false,
  happyHoursMinStartTime: Infinity,
  happyHoursMaxEndTime: -Infinity,
  happyHoursMinGameDefId: null,
  happyHoursMaxGameDefId: null,
  happyHoursTileSort: true,
  activeGamesExist: true,
  realStakeGameVariants: {
    texasHoldem: [],
    omaha: [],
    omaha5: [],
    omaha6: [],
  },
  playStateGameVariants: {
    texasHoldem: [],
    omaha: [],
    omaha5: [],
    omaha6: [],
  },
  hideRakeBack: false,
  showGameTable: false,
  showTourneyInfoPage: false,
  showTourneyInfoLoading: false,
  lackGames: {
    playerId: "",
    activeGames: [],
  },
  lackTourneys: [],
  isLackGamesShown: false,
  isLackGamesReceived: false,
  onlinePlayerCount: 0,
  currentLobbyTime: undefined,
  selectedFlightDetailsTabbar: "similar",
  tourneys: {
    masterTourneysData: new Map(),
  },
  showRedDotOnMyTourneys: false,
  dynamicTourneysStartData: [],
  tourneyTickets: [],
  tourneyTicketsClaimInProgressDetails: {},
  tourneyRegisterAPIURL: process.env.REACT_APP_TOURNEY_REGISTER_API_URL,
  tourneyDeRegisterAPIURL: process.env.REACT_APP_TOURNEY_DE_REGISTER_API_URL,
  tourneyReEntryAPIURL: process.env.REACT_APP_TOURNEY_RE_ENTRY_API_URL,
  tourneyTabs: [],
  selectedTourneyDetailsId: null,
  showTourneyRegisterConfirmationPopup: false,
  showTourneyAddCashTicketRegistrationPopup: false,
  showTourneyReEntryConfirmationPopup: false,
  showTourneyRegisterOrDeregisterRequestLoading: false,
  showTourneyReEntryRequestLoading: false,
  showTourneyInsufficientFundsModal: false,
  showTourneyFairPlayPolicyRestrictionPopup: false,
  showLateRegisterSuccessPopup: false,
  toastMessages: [],
  receivedTokenResponse: false,
  receivedBalanceResponse: false,
  receivedLobbyData: false,
  receivedTourneyData: false,
  applicationVisibility: true,
  isLackTourneyGamesDisplayed: false,
  isCashGamesToggled: false,
  //leaderboard
  isPlayerJoiningFromLB: false,
  leaderBoardGameDefinition: undefined,
  applicableLeaderboards: {
    real: [],
    play: [],
    tourney: [],
    private: [],
    mega: [],
  },
  isNewLeaderboard: false,
  isLeaderboardWinner: false,
  isLeaderboardEnabled: false,
  //rakeback
  rakebackDefinitions: {
    texasHoldem: [],
    omaha: [],
    omaha5: [],
    omaha6: [],
    //handle texas six plus when it is implemented
  },
  rakeBackCurrentLevelName: undefined,
  rakeBackCurrentLevel: undefined,
  rakeBackPercent: undefined,
  rakebackChangeType: undefined,
  showRakebackToast: undefined,
  complianceToastMessage: "",
  deeplinkData: undefined,
  notificationsCount: 0,
  pushToKyc: false,
  cashGames_banner: false,
  addCash_banner: false,
  addCashLimits_banner: false,
  promotionsActiveOffers_banner: false,
  missions_banner: false,
  rewards_banner: false,
  location_games: true,
  location_bonusSummary: true,
  location_promotionsLeaderboard: true,
  location_missions: true,
  location_promotionsActiveOffers: true,
  location_rewards: true,
  location_loyalty: true,
  location_addcash: true,
  location_addCashLimits: true,
  location_redeem: true,
  location_kyc: true,
  userLocationCompliance: false,
  locationComplianceMessage: "",
  homeData: {
    rakeback: null,
    leaderboard: null,
    missions: null,
    gridTilesData: [],
    horizontalBanners: [],
    CTPromotionalBanners: [],
    PKNFPromotionalBanners: [],
    bottomVerticalBanners: [],
    dataUpdated: false,
    recommendedGames: [],
    topLoadedBanners: [],
    bottomLoadedBanners: [],
    // loadingStates: {
    //   topBanners: true,
    //   promotionalBanners: true,
    //   gridTiles: true,
    //   recommendedGames: true,
    //   progressBars: true
    // }
  },
  showAllTicketsSection: false,
  handRankingTagLineData: {
    cashGameTagLineEnabled: false,
    cashGameTagLineRanks: [],
    cashGameTagLineGdid: [],
    tourneyTagLineEnabled: false,
    tourneyTagLineRanks: [],
  },

  updatedTourneyTabs: {
    ACTIVE_TOURNEYS: [],
    MY_TOURNEYS: [],
    COMPLETED_TOURNEYS: [],
  },
  complianceStateNamesArray: [
    "TAMIL!NADU",
    "TAMIL_NADU",
    "TN",
    "Tamil Nadu",
    "tamil nadu",
    "tamilnadu",
    "tn",
  ],
  isStateInCompliance: false,
  blockStartTime: { hours: 0, minutes: 0 },
  blockEndTime: { hours: 4, minutes: 59 },
  // blockingHours:[0,4], //[0,4]
  isBlockedHour: false,
  privateTable: {
    allowedToJoinPT: false,
    allowedToHostPT: false,
    hostedTableCount: 0,
    gameDefIds: [],
    tableDetails: {},
    templateData: [],
    lobbyData: {
      activeTables: {},
      completedTables: {},
      runningGameDefIds: [], // will hold only the running game def ids, and is a subset of activeTables
    },
    configs: {
      defaultConfig: {},
    },
    navigateToGameTable: false,
    isTemplateSaved: false,
    isTemplateDeleted: false,
    isGameInfoRecieved: false,
    isJoinTableResponseReceived: false,
  },
};

function returnGameDefinitionMap(gameDefinitionList) {
  let object = {};
  for (let i = 0; i < gameDefinitionList.length; i++) {
    let gameDefinition = gameDefinitionList[i];
    //  in order to sink master definitions with funGamesData
    if (gameDefinition.flavourType === Constants.LB_PLAY) {
      gameDefinition.gameType = gameDefinition.gameVariant;
    }
    // console.log('Game Definition is ', gameDefinition);
    let gameDefId = gameDefinition.gameDefId;
    // map.set(gameDefinition.gameDefId, gameDefinition);
    object[gameDefId] = gameDefinition;
  }
  // console.log("Master Game Definitions are ", object);
  return object;
}

function returnArrayOfGameDefinitionIDs(gameDefinitionList) {
  let array = [];
  for (let i = 0; i < gameDefinitionList.length; i++) {
    let gameDefinition = gameDefinitionList[i];
    if (gameDefinition.status === Constants.GAME_STATUS_ACTIVE) {
      array.push(gameDefinition.gameDefId);
    }
  }
  return array;
}

function updateGameDefiniton(gameDefinitionsList, gameDefinition) {
  let newGamesList = gameDefinitionsList.filter(
    (gameDef) => gameDef.gameDefId !== gameDefinition.gameDefId
  );
  newGamesList.push(gameDefinition);
  newGamesList.sort((a, b) => a.smallBlind - b.smallBlind);
  return newGamesList;
}

function getGameDefinition(masterGameDefinitionsList, gameDefId) {
  let gameDefinition = masterGameDefinitionsList.find(
    (eachGameDefinition) => eachGameDefinition.gameDefId === gameDefId
  );
  return gameDefinition;
}

function createLobbyConnection(state, dispatchFunction) {
  let clientType = "mipman"; //TODO: need to validate this.
  let returnArray = [];
  let lobbyService = new LobbyService(dispatchFunction);

  let serverAddress = process.env.REACT_APP_LOBBY_SERVER;
  let serverPort = process.env.REACT_APP_LOBBY_PORT;
  let redirectionURL = "";

  returnArray.push(serverAddress);
  returnArray.push(redirectionURL);

  let handlersList = {};
  handlersList["ld"] = new LobbyDataHandler(lobbyService);
  // handlersList["blockstate"] = new BlockStateHandler(lobbyService);
  handlersList["cona"] = new LobbyConnectionAckHandler(lobbyService);
  if (!Constants.FUN_CHANNELS.includes(state.channel)) {
    handlersList["lack"] = new LackHandler(lobbyService);
    handlersList["activegames"] = new ActiveGamesHandler(lobbyService);
    handlersList["tourneyinfoa"] = new TourneyInfoAckHandler(lobbyService);
    handlersList["trc"] = new TourneyRegisteredCountHandler(lobbyService);
    handlersList["tsc"] = new TourneyStatusChangeHandler(lobbyService);
    handlersList["tps"] = new TourneyPlayerStatusHandler(lobbyService);
    handlersList["tld"] = new TourneyLobbydataHandler(lobbyService);
    handlersList["tourneystart"] = new TourneyStartHandler(lobbyService);
    handlersList["dtld"] = new DynamicTourneyLobbyDataHandler(lobbyService);
    handlersList["tourneyprize"] = new TourneyPrizeHandler(lobbyService);
    handlersList["tourneycomplete"] = new TourneyCompleteHandler(lobbyService);
    handlersList["ranksdata"] = new TourneyRanksDataHandler(lobbyService);
    handlersList["tourneycancel"] = new TourneyCancleHandler(lobbyService);
    handlersList["tourneylateregended"] = new TourneyLateRegEndedHandler(
      lobbyService
    );
    handlersList["tickets"] = new TourneyTicketsHandler(lobbyService);
    handlersList["newchildtourney"] = new TourneyNewChildTourneyHandler(
      lobbyService
    );
    handlersList["tourneyremove"] = new TourneyRemoveHandler(lobbyService);
    handlersList["tcd"] = new TourneyDynamicCountsUpdateHandler(lobbyService);
    handlersList["playerautoregistered"] =
      new TourneyPlayerAutoRegisteredHandler(lobbyService);
    handlersList["slabchange"] = new TourneySlabChangeHandler(lobbyService);
    handlersList["prizepoolupdate"] = new TourneyPrizePoolUpdateHandler(
      lobbyService
    );
    handlersList["tabledata"] = new TourneyTableDataHandler(lobbyService);
    handlersList["updatedepositortourney"] = new UpdateDepositorTourneyHandler(
      lobbyService
    );
    handlersList["depositorpopup"] = new TourneyDepositorTicketReceiveHandler(
      lobbyService
    );
    handlersList["hrtl"] = new HandRankingTagLineHandler(lobbyService);
  }

  handlersList["gu"] = new GameDefinitionUpdateHandler(lobbyService);
  handlersList["upc"] = new UpdatePlayerCountHandler(lobbyService);
  handlersList["g"] = new HeartBeatLobbyHandler(lobbyService);

  handlersList["rcona"] = new LobbyReconnectionAckHandler(lobbyService);
  // handlersList["conf"] = new ConnectionFailHandler(lobbyService);
  // handlersList["opengame"] = new openGameDefinitionsHandler(lobbyService);
  // handlersList["closegame"] = new closeGameDefinitionsHandler(lobbyService);
  handlersList["gameactivestatus"] = new GameActiveStatusHandler(lobbyService);
  handlersList["playerconfig"] = new PlayerConfigHandler(lobbyService);
  handlersList["chipsreloada"] = new ChipsReloadAckHandler(lobbyService);
  handlersList["getplayerchipsa"] = new GetFunChipsBalanceAckHandler(
    lobbyService
  );
  handlersList["inthemoney"] = new TourneyInTheMoneyHandler(lobbyService);
  handlersList["breakstart"] = new TourneyInBreakHandler(lobbyService);

  handlersList["specialbet"] = new SpecialBetHandler(lobbyService);
  handlersList["sbgu"] = new SpecialBetGameActiveStatusHandler(lobbyService);
  handlersList["flightqualifiedplayersa"] =
    new TourneyFlightQualifiedPlayersAckHandler(lobbyService);
  handlersList["flightstackdetailsa"] = new TourneyFlightStackDetailsAckHandler(
    lobbyService
  );
  handlersList["flightstackdetailsf"] = new TourneyFlightStackDetailsAckHandler(
    lobbyService
  );

  // Private table handlers
  handlersList["ptcreategda"] = new PrivateTableCreateGameDefAckHandler(
    lobbyService
  );
  handlersList["ptcreategdf"] = new PrivateTableCreateGameDefFailureHandler(
    lobbyService
  );
  handlersList["privatetablesld"] = new PrivateTablesLobbyDataHandler(
    lobbyService
  );
  handlersList["dptld"] = new DynamicPrivateTableLobbyDataHandler(lobbyService);
  handlersList["dptsc"] = new DynamicPrivateTableStatusChangeHandler(
    lobbyService
  );

  handlersList["privatetableinfoa"] = new PrivateTableGameDefInfoHandler(
    lobbyService
  );
  handlersList["privateTableinfof"] = new PrivateTableGameDefInfoFailure(
    lobbyService
  );
  handlersList["privatetablespc"] = new PrivateTableGameDefPlayerCount(
    lobbyService
  );
  let configuration = new Configuration();
  configuration.setHandlersList(handlersList);

  configuration.setConnectionType(process.env.REACT_APP_CONNECTION_TYPE);
  configuration.setServerAddress(serverAddress);
  configuration.setServerPort(serverPort);

  // configuration.setHeartBeatMessage("g#");
  configuration.setHeartBeatMessage("g");
  configuration.setHeartBeatInterval(3);
  configuration.setConnectionTimeout(10);
  configuration.setReadTimeout(15);
  configuration.setReconnectionTimeout(10);
  configuration.setProtocolDelimiter("#");
  configuration.setReconnectionMode(false);

  let clientListener = new LobbyListener(state, dispatchFunction, clientType);
  let baseClient = new BaseClient(
    configuration,
    clientListener,
    "lobbyListener"
  );
  clientListener.setCommunicationChannel(baseClient);
  baseClient.start();

  lobbyService.messageHandler = clientListener;

  returnArray.push(baseClient);
  // returnArray.push(googleClientId);

  //CleverTap changes
  return returnArray;
}

function updateTourneyTabberItemsBasedOnAvailability(state) {
  let activeTabberItems = new Set();
  let myTourneysTabberItems = new Set();
  let completedTourneysTabberItems = new Set();

  state.tourneys.masterTourneysData.forEach((tourney, tourneyId) => {
    let key = tourney.tournamentType;

    if (tourney.tourneyIdentifier === "upcomingTourneys") {
      activeTabberItems.add(key);
    }

    if (tourney.tourneyIdentifier === "myTourneys") {
      myTourneysTabberItems.add(key);
    }

    if (
      tourney.tourneyIdentifier === "completedTourneys" &&
      !(
        tourney.tournamentType === Constants.SIT_AND_GO_TOURNEYS &&
        tourney.tourneyPlayerStatus === Constants.TOURNEY_STATUS_MISSED
      )
    ) {
      completedTourneysTabberItems.add(key);
    }
  });

  let updatedTabs = {
    ACTIVE_TOURNEYS: [Constants.ALL_TAB, ...activeTabberItems],
    MY_TOURNEYS: [Constants.ALL_TAB, ...myTourneysTabberItems],
    COMPLETED_TOURNEYS: [Constants.ALL_TAB, ...completedTourneysTabberItems],
  };

  state.updatedTourneyTabs = { ...updatedTabs };
}

function updateTourneySimilarFlights(tourneysMap, newTourneyData) {
  const newTourneyId = newTourneyData.tourneyId;
  const parentTourneyId = newTourneyData.parentTourney;
  const similarFlights = newTourneyData.similarFlights || [];

  // Ensure we modify the existing tourneysMap
  const updateSimilarFlights = (tourneyId, newId) => {
    if (tourneysMap.has(tourneyId)) {
      let tourney = tourneysMap.get(tourneyId);
      if (!tourney.similarFlights.includes(newId)) {
        tourney.similarFlights.push(newId);
      }
    }
  };

  // Step 1: Update Parent Tourney's similarFlights (if parent exists)
  if (parentTourneyId && tourneysMap.has(parentTourneyId)) {
    updateSimilarFlights(parentTourneyId, newTourneyId);
  }

  // Step 2: Update each similar flight tourney to include the new tourneyId
  similarFlights.forEach((flightTourneyId) => {
    updateSimilarFlights(flightTourneyId, newTourneyId);
  });

  // Step 3: Add newTourneyData to tourneysMap
  tourneysMap.set(newTourneyId, newTourneyData);
  return tourneysMap;
}

function processPrivateTableCreation(state, gdid, mode, gameDefinition) {
  if (!state.privateTable.lobbyData.runningGameDefIds.includes(Number(gdid))) {
    state.privateTable.tableDetails[gdid] = {
      gameDefId: Number(gdid),
      gameDefinition: {
        ...gameDefinition,
        flavourType: Constants.LB_REAL,
        accessCode: gdid,
        mode: mode,
      },
    };
    state.privateTable.initiateMatchmakingForGdid = gdid;
    state.privateTable.lobbyData.runningGameDefIds.push(Number(gdid));
  } else {
    state.privateTable.navigateToGameTable = true;
  }
}

const LobbySlice = createSlice({
  name: "lobby",
  initialState,
  reducers: {
    updateApplicationVisibility: (state, action) => {
      let { status } = action.payload;
      state.applicationVisibility = status;
    },
    initializeLobbyConnection: (state, action) => {
      let payload = action.payload;

      let baseClient = createLobbyConnection(
        current(state),
        payload.dispatchFunction
      );
      state.serverConnectIP = baseClient[0];
      state.redirectionURL = baseClient[1];
      // newState.googleClientId = baseClient[3]; //not in use anymore

      let lobbyConnection = LobbyConnection.getInstance();
      lobbyConnection.addLobbyConnection(baseClient[2]);
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload.accessToken;
    },
    setPlayerDetails: (state, action) => {
      const {
        body,
        screenName,
        displayName,
        userSubcriptionType,
        channel,
        userID,
        a23Token,
        userLevel,
        deepLink,
        avatar,
        isAllowLocation,
        isHapticsSupport,
        deviceType,
        deviceModel,
        deviceOS,
        deviceId,
        ip,
        lat,
        lng,
        playerStatus,
        referCode,
        filesave,
        pushToKYC,
        cashGames_banner,
        cashGamesBanner,
        addCash_banner,
        addCashLimits_banner,
        promotionsActiveOffers_banner,
        missions_banner,
        rewards_banner,
        state: userState,
      } = action.payload;
      /** description of value received in key "userSubcriptionType" --
       * 1. "pseudo" for newly signed up user who received bonus but not yet added cash,
       * 2. "premium" for user who did add cash atleast once,
       * 3. "regular" for player who neither received bonus nor did an add cash */

      if (userState) {
        //leaving this as a backup for now @yashwanth, actual values received from lobby server via blockstate# protocol
        state.isStateInCompliance =
          state.complianceStateNamesArray.includes(userState);
      }

      state.player = {
        userName: screenName,
        subscriptionType: userSubcriptionType,
        displayName: displayName,
        state: userState,
        level: userLevel,
        isAllowLocation: isAllowLocation === "true",
        initialisAllowLocation: isAllowLocation === "true",
        avatar: avatar,
        IP: ip,
        deviceId: deviceId,
        deviceType: deviceType,
        deviceModel: deviceModel,
        deviceOS: deviceOS,
        latitude: lat,
        longitude: lng,
        userId: userID,
        deepLink: deepLink,
        // playerStatus: playerStatus ? playerStatus : null,
        playerStatus:
          playerStatus && playerStatus !== "null"
            ? playerStatus
            : Constants.PLAYER_STATUS_NOT_AVAILABLE,
        referCode: referCode ? referCode : null,
      };
      state.isFoldDeviceInMobileMode = checkIfFoldDeviceInMobileMode({
        deviceType: deviceType,
        deviceModel: deviceModel,
      });
      //setting banner details from token data
      state.addCash_banner = addCash_banner === "true" || addCash_banner;
      state.addCashLimits_banner =
        addCashLimits_banner === "true" || addCash_banner;
      state.promotionsActiveOffers_banner =
        promotionsActiveOffers_banner === "true" ||
        promotionsActiveOffers_banner;
      state.missions_banner = missions_banner === "true" || missions_banner;
      state.rewards_banner = rewards_banner === "true" || rewards_banner;

      // isFileSaveEnabled is used in saving High hand Acknowledgement
      state.isFileSaveEnabled = filesave === "true";
      state.isHapticsSupport = isHapticsSupport;
      state.channel = channel;
      state.a23Token = a23Token;
      state.accessToken = body;
      state.player.channel = state.channel;
      state.pushToKyc = pushToKYC === "true" || pushToKYC === true;
      state.cashGames_banner =
        cashGames_banner === "true" ||
        cashGames_banner === true ||
        cashGamesBanner === true;
      setToLocalStorage(IS_HAPTICS_SUPPORT, isHapticsSupport);
      if (getFromLocalStorage(IS_SOUND_ENABLED) === null) {
        setToLocalStorage(IS_SOUND_ENABLED, true);
      }
      if (getFromLocalStorage(IS_HAPTICS_EBABLED) === null) {
        setToLocalStorage(IS_HAPTICS_EBABLED, true);
      }
      state.receivedTokenResponse = true;

      //commented below line as parsing of only an empty string is throwing an error 'unexpected EOF' - iOS data problem from native client
      // let isAllowedState = JSON.parse(isAllowLocation);
      let isAllowedState = isAllowLocation === "true"; //to convert string to a boolean - if value is empty/undefined it returns false
      state.isCashGamesToggled = isAllowedState;
      state.isCashGamesToggled = true; //this is only temporary value
      // return newState;
    },
    setChannel: (state, action) => {
      state.channel = action.payload.channel;
    },
    setIsWebView: (state, action) => {
      state.isWebView = action.payload.isWebView;
    },

    setCleverTapEnabledFromNative: (state, action) => {
      const { ctEventsSupport } = action.payload;
      state.isCleverTapEnabledFromNative = ctEventsSupport === "enabled";
    },

    updatePlayerBalance: (state, action) => {
      state.balance.bonus = action.payload.extraCash;
      state.balance.totalBalance = action.payload.body;
      state.balance.depositBalance = action.payload.depositBalance;
      state.balance.redeemableBalance = action.payload.redeemableBalance;
      // state.player.playerStatus = action.payload?.playerStatus
      //   ? action.payload.playerStatus
      //   : null;
      state.player.level = action.payload.userLevel;
      //stored by the playerStatus value by converting to lower case as we are receiving in camel case
      state.player.playerStatus =
        action.payload.playerStatus && action.payload.playerStatus !== "null"
          ? action.payload.playerStatus.toLowerCase()
          : Constants.PLAYER_STATUS_NOT_AVAILABLE;
      state.receivedBalanceResponse = true;
      if (
        state.receivedBalanceResponse &&
        state.receivedTokenResponse &&
        state.receivedLobbyData
      ) {
        state.showLobbyLoading = false;
        state.showTourneyLobbyLoading = false;
      }
    },

    updatePlayerPlayChipsBalance: (state, action) => {
      const { playChips } = action.payload;
      state.balance.playChips = playChips;
    },
    updateDisplayName: (state, action) => {
      const { displayName } = action.payload;
      state.displayName = displayName;
    },
    handlePlayChipsBalanceReload: (state, action) => {
      const { status, reloadedPlayChips } = action.payload;
      if (status === "success") {
        state.balance.playChips = reloadedPlayChips;
        state.toastMessages.push({
          type: Constants.TOAST_SUCCESS,
          message: "Chips successfully reloaded.",
        });
      } else if (status === "failure") {
        state.toastMessages.push({
          type: Constants.TOAST_ERROR,
          message: "Failed to reload, Please try again.",
        });
      }
    },
    processFunChipsBalance: (state, action) => {
      const { playChips } = action.payload;
      state.balance.playChips = playChips;
    },

    handleDeeplink: (state, action) => {
      let deeplinkData = action.payload.deepLink;
      state.deeplinkData = deeplinkData;
    },

    clearDeeplink: (state, action) => {
      state.deeplinkData = undefined;
    },

    joinGameFromLeaderBoard: (state, action) => {
      let lbData = action.payload.deepLinkData;
      state.isPlayerJoiningFromLB = true;
      let lbDef = lbData.lbGameDefId.split("_");
      let lbType = Number(lbDef[0]);
      let isTourney = lbType === 2 || lbType === 11;
      let lbGameDefId = Number(lbDef[2]);
      if (isTourney) {
        if (lbType === 2) {
          let tourneyDefinitions = state.tourneys.masterTourneysData;
          let leaderBoardGameDefinition = tourneyDefinitions.filter(
            (tourney) => tourney.tourneyId === lbData.tourneyId
          );
          if (leaderBoardGameDefinition.length === 1)
            state.leaderBoardGameDefinition = leaderBoardGameDefinition[0];
          else {
            logCleverTapEvent(Poker_Redirection_Fail, {
              Username: state.player.userName,
              Channel: state.channel,
              Timestamp: getISTDateTimeFormat(),
              KVPairs: action.payload,
            });
            sendFaroLog({
              type: Poker_Redirection_Fail,
              Username: state.player.userName,
              Channel: state.channel,
              Timestamp: getISTDateTimeFormat(),
              message: `Tourney not found in master list ${lbData.tourneyId}`,
            });
          }
        }
      } else {
        // let gameType;
        // if (lbGameFormat === 1) {
        //   gameType = "texasHoldem";
        // } else if (lbGameFormat === 2) {
        //   gameType = "omaha";
        // } else if (lbGameFormat === 17) {
        //   gameType = "omaha5";
        // }
        //handle data for lbType = 7
        // let gameDefinitions =
        //   lbType === 1
        //     ? state.realStakeGameVariants
        //     : lbType === 4
        //     ? state.playStateGameVariants
        //     : lbType === 7
        //     ? []
        //     : [];
        // let leaderBoardGameDefinition = gameDefinitions[gameType].filter(
        //   (gameDefinition) => gameDefinition.gameDefId === lbGameDefId
        // );
        if (lbType === 1 || lbType === 4) {
          let leaderboardGameDefinition =
            state.masterGameDefinitions?.[lbGameDefId];
          if (leaderboardGameDefinition)
            state.leaderBoardGameDefinition = leaderboardGameDefinition;
          else {
            console.error(
              "no game with lb def id",
              lbData.lbGameDefId,
              "gameId",
              lbData.lbGameDefId.split("_")[2]
            );
            logCleverTapEvent(Poker_Redirection_Fail, {
              Username: state.player.userName,
              Channel: state.channel,
              Timestamp: getISTDateTimeFormat(),
              KVPairs: action.payload,
            });
            sendFaroLog({
              type: Poker_Redirection_Fail,
              Username: state.player.userName,
              Channel: state.channel,
              Timestamp: getISTDateTimeFormat(),
              message: `leaderboardGameDefinition is undefined meaning gameid not in master game definitions ${lbGameDefId}`,
            });
            // state.isPlayerJoiningFromLB = false;
          }
        }
      }
      // console.log("lb game definition", state.leaderBoardGameDefinition);
    },

    setIsPlayerJoiningFromLB: (state, action) => {
      state.isPlayerJoiningFromLB = action.payload.flag;
      state.leaderBoardGameDefinition = undefined;
    },

    leaderBoardGamesList: (state, action) => {
      let lbData = action.payload.serverData;
      console.log("lb list", lbData);
      let applicableLeaderboards = {
        real: [],
        play: [],
        tourney: [],
        private: [],
        mega: [],
      };

      for (const element of lbData.pokerleaderboards) {
        let currentLeaderboard = element;
        let currentLeaderboardFlavour =
          currentLeaderboard.gameDefIds.length > 0
            ? Number(currentLeaderboard.gameDefIds[0].split("_")[0])
            : undefined;
        if (currentLeaderboardFlavour) {
          if (currentLeaderboardFlavour === Constants.FLAVOUR_TYPES["real"]) {
            applicableLeaderboards.real.push(currentLeaderboard);
          } else if (
            currentLeaderboardFlavour === Constants.FLAVOUR_TYPES["play"]
          ) {
            applicableLeaderboards.play.push(currentLeaderboard);
          } else if (
            currentLeaderboardFlavour === Constants.FLAVOUR_TYPES["tourney"]
          ) {
            applicableLeaderboards.tourney.push(currentLeaderboard);
          } else if (
            currentLeaderboardFlavour === Constants.FLAVOUR_TYPES["private"]
          ) {
            applicableLeaderboards.private.push(currentLeaderboard);
          } else if (
            currentLeaderboardFlavour === Constants.FLAVOUR_TYPES["mega"]
          ) {
            applicableLeaderboards.mega.push(currentLeaderboard);
          }
        }
      }
      state.isNewLeaderboard = lbData.isNewLb === "true";
      state.isLeaderboardEnabled = lbData.isEnabled === "true";
      state.applicableLeaderboards = applicableLeaderboards;
      // console.log("POKER LBS", state);
    },

    setRakebackGameDefinitions: (state, action) => {
      let rakeBackData = action.payload.serverData;
      let gameDefIds = rakeBackData.gameDefIds;
      let isRBblocked = rakeBackData?.isRBBlocked === true;
      let rakebackDefinitions = {
        texasHoldem: [],
        omaha: [],
        omaha5: [],
        omaha6: [],
        //handle texas six plus when it is implemented
      };
      for (const current of gameDefIds) {
        let gamedefArray = current.split("_");
        //Fix for IM-2580 below notations we need to refer to 1st index instead of 0 index
        if (Number(gamedefArray[1]) === 1) {
          rakebackDefinitions.texasHoldem.push(Number(gamedefArray[2]));
        } else if (Number(gamedefArray[1]) === 2) {
          rakebackDefinitions.omaha.push(Number(gamedefArray[2]));
        } else if (Number(gamedefArray[1]) === 17) {
          rakebackDefinitions.omaha5.push(Number(gamedefArray[2]));
        } else if (Number(gamedefArray[1]) === 3) {
          rakebackDefinitions.omaha6.push(Number(gamedefArray[2]));
        } else {
          console.log("INCORRECT VARIANT - NOT HANDLED ", gamedefArray[0]);
        }
      }
      state.rakebackDefinitions = rakebackDefinitions;
      state.rakeBackPercent = Number(rakeBackData.rakeBackPercent);
      state.rakeBackCurrentLevel = Number(rakeBackData.currentLevel);
      state.rakeBackCurrentLevelName = rakeBackData.currentLevelName;
      state.hideRakeBack = isRBblocked;
      console.log("POKER rakeback updated bets", state.rakebackDefinitions);
    },

    openRakebackPage: (state, action) => {
      if (!state.hideRakeBack) {
        messageToNativeClient({ type: "openRakeBack" });
      } else {
        const toastMessage = {
          type: Constants.TOAST_INFO,
          message: Constants.REDIRECTION_FAIL,
        };
        state.toastMessages.filter(
          (toast) => toast.message === toastMessage.message
        ).length === 0 && state.toastMessages.push(toastMessage);
      }
    },

    removeRakebackToast: (state, action) => {
      state.showRakebackToast = false;
    },
    updateComplianceToastMessage: (state, action) => {
      const { playerName, playedTime } = action.payload;
      let hours = Math.floor(playedTime / 60);
      let minutes = playedTime % 60;
      if (hours > 0 && minutes > 0) {
        state.complianceToastMessage = `You have been playing continuously for ${hours} hr and ${minutes} mins. Please consider taking a break.`;
      } else if (hours > 0) {
        state.complianceToastMessage = `You have been playing continuously for ${hours} hrs. Please consider taking a break.`;
      } else if (minutes > 0) {
        state.complianceToastMessage = `You have been playing continuously for ${minutes} mins. Please consider taking a break.`;
      } else {
        state.complianceToastMessage = "";
      }
    },
    removeComplianceToastMessage: (state, action) => {
      state.complianceToastMessage = "";
    },

    updatePlayerRakebackLevel: (state, action) => {
      let rakeBackData = action.payload.serverData;
      let gameDefIds = rakeBackData.gameDefIds;
      let rakebackDefinitions = {
        texasHoldem: [],
        omaha: [],
        omaha5: [],
        omaha6: [],
        //handle texas six plus when it is implemented
      };
      for (const current of gameDefIds) {
        let gamedefArray = current.split("_");
        if (Number(gamedefArray[0]) === 1) {
          rakebackDefinitions.texasHoldem.push(Number(gamedefArray[2]));
        } else if (Number(gamedefArray[0]) === 2) {
          rakebackDefinitions.omaha.push(Number(gamedefArray[2]));
        } else if (Number(gamedefArray[0]) === 17) {
          rakebackDefinitions.omaha5.push(Number(gamedefArray[2]));
        } else if (Number(gamedefArray[0]) === 3) {
          rakebackDefinitions.omaha6.push(Number(gamedefArray[2]));
        } else {
          console.log("INCORRECT VARIANT - NOT HANDLED ", gamedefArray[0]);
        }
      }
      state.rakebackDefinitions = rakebackDefinitions;
      state.rakeBackPercent = Number(rakeBackData.rakeBackPercent);
      state.rakeBackCurrentLevel = Number(rakeBackData.currentLevel);
      state.rakeBackCurrentLevelName = rakeBackData.currentLevelName;
      state.rakebackChangeType = rakeBackData.type;
      state.showRakebackToast = rakeBackData.type === "claim";
      // console.log(
      //   "POKER rakeback updated bets",
      //   state.rakebackDefinitions
      // );
    },

    updateGameDefinitionsMasterdata: (state, action) => {
      // Master Game Definition Logic
      // state.masterGameDefinitions = action.payload;
      const { key, data } = action.payload;

      let gameDefMap = returnGameDefinitionMap(data);

      if (key === Constants.LOBBY_DATA) {
        state.masterGameDefinitions = gameDefMap;
      } else if (key === Constants.SPECIAL_BETS_DATA) {
        // existing game definitions + special game definitions
        let newData = {
          ...current(state.masterGameDefinitions),
          ...gameDefMap,
        };
        state.masterGameDefinitions = newData;
        state.specialbets = returnArrayOfGameDefinitionIDs(data);
      }
      // state.masterGameDefinitions = returnGameDefinitionMap(action.payload);
      //calculating the length again just to avoid any rare case of duplicate game definitions (need to fine tune this) @yashwanth
      state.masterGameDefinitionsCount = Object.keys(
        state.masterGameDefinitions
      ).length;

      // let data = action.payload;
      state.receivedLobbyData = true;

      state.happyHoursGameCount = {
        total: 0,
        TEXAS_HOLDEM: 0,
        OMAHA: 0,
        OMAHA5: 0,
        OMAHA6: 0,
      };

      // Loop through all game definitions to update happy hours data
      Object.values(state.masterGameDefinitions).forEach((game) => {
        if (
          game.happyHoursTimerStarted &&
          game.status?.toLowerCase() === "active"
        ) {
          state.isHappyHoursEnabled = true;
        }

        if (
          game.happyHoursTimerStarted &&
          game.status?.toLowerCase() === "active"
        ) {
          if (game.happyHoursStartTime < state.happyHoursMinStartTime) {
            state.happyHoursMinStartTime = game.happyHoursStartTime;
            state.happyHoursMinGameDefId = game.gameDefId;
          }

          if (game.happyHoursEndTime > state.happyHoursMaxEndTime) {
            state.happyHoursMaxEndTime = game.happyHoursEndTime;
            state.happyHoursMaxGameDefId = game.gameDefId;
          }

          state.happyHoursGameCount.total += 1;
          if (game.gameType === Constants.TEXAS_HOLDEM) {
            state.happyHoursGameCount.TEXAS_HOLDEM += 1;
          }
          if (game.gameType === Constants.OMAHA) {
            state.happyHoursGameCount.OMAHA += 1;
          }
          if (game.gameType === Constants.OMAHA5) {
            state.happyHoursGameCount.OMAHA5 += 1;
          }
          if (game.gameType === Constants.OMAHA6) {
            state.happyHoursGameCount.OMAHA6 += 1;
          }
        }

        if (game.happyHoursConfiguredTime) {
          state.happyHoursConfiguredTime = game.happyHoursConfiguredTime;
        }
      });

      // Real Games Logic
      let realStakeGames = Object.values(state.masterGameDefinitions)
        .filter(
          (eachGameDefinition) =>
            eachGameDefinition.flavourType.toLowerCase() === Constants.LB_REAL
        )
        .sort((a, b) => a.smallBlind - b.smallBlind);
      let texasRealHoldemGames = realStakeGames.filter(
        (eachGameDefinition) =>
          eachGameDefinition.gameType === Constants.TEXAS_HOLDEM
      );
      let omahaRealGames = realStakeGames.filter(
        (eachGameDefinition) => eachGameDefinition.gameType === Constants.OMAHA
      );
      let omaha5RealGames = realStakeGames.filter(
        (eachGameDefinition) => eachGameDefinition.gameType === Constants.OMAHA5
      );
      let omaha6RealGames = realStakeGames.filter(
        (eachGameDefinition) => eachGameDefinition.gameType === Constants.OMAHA6
      );
      let realGamesData = {
        texasHoldem: texasRealHoldemGames,
        omaha: omahaRealGames,
        omaha5: omaha5RealGames,
        omaha6: omaha6RealGames,
      };

      state.realStakeGameVariants = realGamesData;
      state.realGames = returnArrayOfGameDefinitionIDs(realStakeGames);
      state.realTexasHoldem =
        returnArrayOfGameDefinitionIDs(texasRealHoldemGames);
      state.realOmaha = returnArrayOfGameDefinitionIDs(omahaRealGames);
      state.realOmaha5 = returnArrayOfGameDefinitionIDs(omaha5RealGames);
      state.realOmaha6 = returnArrayOfGameDefinitionIDs(omaha6RealGames);

      // Fun Games Logic
      let funGameDefinitions = [...data]
        .filter(
          (eachGameDefinition) =>
            eachGameDefinition.flavourType.toLowerCase() === Constants.LB_PLAY
        )
        .sort((a, b) => a.smallBlind - b.smallBlind);
      let playStakeGames = funGameDefinitions.map((eachFunGame) => {
        let updatedGameDetails = { ...eachFunGame };
        updatedGameDetails.originalGameType = eachFunGame.gameType;
        updatedGameDetails.gameType = eachFunGame.gameVariant;
        return updatedGameDetails;
      });
      let texasHoldemGames = playStakeGames.filter(
        (eachGameDefinition) =>
          eachGameDefinition.gameType === Constants.TEXAS_HOLDEM
      );
      let omahaGames = playStakeGames.filter(
        (eachGameDefinition) => eachGameDefinition.gameType === Constants.OMAHA
      );
      let omaha5Games = playStakeGames.filter(
        (eachGameDefinition) => eachGameDefinition.gameType === Constants.OMAHA5
      );
      let omaha6Games = playStakeGames.filter(
        (eachGameDefinition) => eachGameDefinition.gameType === Constants.OMAHA6
      );
      let funGamesData = {
        texasHoldem: texasHoldemGames,
        omaha: omahaGames,
        omaha5: omaha5Games,
        omaha6: omaha6Games,
      };
      state.playStateGameVariants = funGamesData;

      state.funGames = returnArrayOfGameDefinitionIDs(playStakeGames);
      state.funTexasHoldem = returnArrayOfGameDefinitionIDs(texasHoldemGames);
      state.funOmaha = returnArrayOfGameDefinitionIDs(omahaGames);
      state.funOmaha5 = returnArrayOfGameDefinitionIDs(omaha5Games);
      state.funOmaha6 = returnArrayOfGameDefinitionIDs(omaha6Games);

      // We are not sending lack request upon the reconnection scenario. as it causing duplicate table scenario.
      // NOTE: moving this lack request after receiving tld# data
      // if (!state.isLackGamesReceived) {
      //   let lackProtocol =
      //     "lack#" +
      //     JSON.stringify({
      //       playerName: state.player.userName,
      //     });
      //   // LobbyConnection.getInstance()
      //   //   .lobbyConnection.clientListener.getCommunicationChannel()
      //   //   .sendMessage(lackProtocol);
      //   LobbyConnection.getInstance().writeMessage(lackProtocol);
      // }

      state.showLobbyLoading = false;
    },

    updateGameActiveStatus: (state, action) => {
      const { gameDefIds, status } = action.payload;

      let activeGamesExist = true; //false;
      let numberOfInactiveGames = 0;
      let masterGameDefinitionsCount = state.masterGameDefinitionsCount;

      for (let i = 0; i < gameDefIds.length; i++) {
        let gameDefId = gameDefIds[i];
        if (state.masterGameDefinitions.hasOwnProperty(gameDefId)) {
          state.masterGameDefinitions[gameDefId].status = status;

          if (status.toLowerCase() === "inactive") {
            numberOfInactiveGames = numberOfInactiveGames + 1;
          }
        }
        // if (status?.toLowerCase() === "active") {
        //   activeGamesExist = true;
        // }
      }

      if (masterGameDefinitionsCount === numberOfInactiveGames) {
        activeGamesExist = false;
      }
      state.activeGamesExist = activeGamesExist;
    },

    sendLobbyDebugLog: (state, action) => {
      const { message } = action.payload;

      const logPayload = {
        playerName: state?.player?.userName,
        logMessage: message,
      };
      let debugProtocol = `DB#${JSON.stringify(logPayload)}`;
      LobbyConnection.getInstance().writeMessage(debugProtocol);
    },

    lobbyPlayData: (state, action) => {
      state.playStateGameVariants = action.payload;
    },

    lobbyRealData: (state, action) => {
      state.realStakeGameVariants = action.payload;
    },
    lobbyUpdatedGameDefinition: (state, action) => {
      const { gameDefinition } = action.payload;
      state.isHappyHoursEnabled = false;

      if (gameDefinition.happyHoursConfiguredTime) {
        state.happyHoursConfiguredTime =
          gameDefinition.happyHoursConfiguredTime;
      }

      // Check if the gameDefinition exists in masterGameDefinitions
      if (state.masterGameDefinitions[gameDefinition?.gameDefId]) {
        state.masterGameDefinitions[gameDefinition?.gameDefId] = gameDefinition;

        // Reset happy hours tracking
        state.happyHoursMinStartTime = Infinity;
        state.happyHoursMaxEndTime = -Infinity;
        state.happyHoursMinGameDefId = null;
        state.happyHoursMaxGameDefId = null;

        // Recalculate happy hours based on updated data
        Object.values(state.masterGameDefinitions).forEach((game) => {
          if (
            game.happyHoursTimerStarted &&
            game.status?.toLowerCase() === "active"
          ) {
            state.isHappyHoursEnabled = true;
          }

          if (
            game.happyHoursTimerStarted &&
            game.status?.toLowerCase() === "active"
          ) {
            // Update minimum happy hours start time
            if (game.happyHoursStartTime < state.happyHoursMinStartTime) {
              state.happyHoursMinStartTime = game.happyHoursStartTime;
              state.happyHoursMinGameDefId = game.gameDefId;
            }

            // Update maximum happy hours end time
            if (game.happyHoursEndTime > state.happyHoursMaxEndTime) {
              state.happyHoursMaxEndTime = game.happyHoursEndTime;
              state.happyHoursMaxGameDefId = game.gameDefId;
            }
          }
        });

        return;
      }

      if (gameDefinition.isHappyHoursEnabled) {
        state.happyHoursGameCount.total += 1;
      }
      if (gameDefinition.gameType === Constants.TEXAS_HOLDEM) {
        state.happyHoursGameCount.TEXAS_HOLDEM += 1;
      }
      if (gameDefinition.gameType === Constants.OMAHA) {
        state.happyHoursGameCount.OMAHA += 1;
      }
      if (gameDefinition.gameType === Constants.OMAHA5) {
        state.happyHoursGameCount.OMAHA5 += 1;
      }
      if (gameDefinition.gameType === Constants.OMAHA6) {
        state.happyHoursGameCount.OMAHA6 += 1;
      }

      // Add new game definition logic
      if (gameDefinition.flavourType.toLowerCase() === Constants.LB_REAL) {
        state.realGames = [...state.realGames, gameDefinition.gameDefId].sort(
          (a, b) => a.smallBlind - b.smallBlind
        );
        if (gameDefinition.gameType === Constants.TEXAS_HOLDEM) {
          state.realTexasHoldem = [
            ...state.realTexasHoldem,
            gameDefinition.gameDefId,
          ].sort((a, b) => a.smallBlind - b.smallBlind);
        } else if (gameDefinition.gameType === Constants.OMAHA) {
          state.realOmaha = [...state.realOmaha, gameDefinition.gameDefId].sort(
            (a, b) => a.smallBlind - b.smallBlind
          );
        } else if (gameDefinition.gameType === Constants.OMAHA5) {
          state.realOmaha5 = [
            ...state.realOmaha5,
            gameDefinition.gameDefId,
          ].sort((a, b) => a.smallBlind - b.smallBlind);
        } else if (gameDefinition.gameType === Constants.OMAHA6) {
          state.realOmaha6 = [
            ...state.realOmaha6,
            gameDefinition.gameDefId,
          ].sort((a, b) => a.smallBlind - b.smallBlind);
        }
      } else if (
        gameDefinition.flavourType.toLowerCase() === Constants.LB_PLAY
      ) {
        state.funGames = [...state.funGames, gameDefinition.gameDefId].sort(
          (a, b) => a.smallBlind - b.smallBlind
        );
        if (gameDefinition.gameType === Constants.TEXAS_HOLDEM) {
          state.funTexasHoldem = [
            ...state.funTexasHoldem,
            gameDefinition.gameDefId,
          ].sort((a, b) => a.smallBlind - b.smallBlind);
        } else if (gameDefinition.gameType === Constants.OMAHA) {
          state.funOmaha = [...state.funOmaha, gameDefinition.gameDefId].sort(
            (a, b) => a.smallBlind - b.smallBlind
          );
        } else if (gameDefinition.gameType === Constants.OMAHA5) {
          state.funOmaha5 = [...state.funOmaha5, gameDefinition.gameDefId].sort(
            (a, b) => a.smallBlind - b.smallBlind
          );
        } else if (gameDefinition.gameType === Constants.OMAHA6) {
          state.funOmaha6 = [...state.funOmaha6, gameDefinition.gameDefId].sort(
            (a, b) => a.smallBlind - b.smallBlind
          );
        }
      }

      // Add the new game definition to masterGameDefinitions
      state.masterGameDefinitions[gameDefinition.gameDefId] = gameDefinition;

      // Recalculate masterGameDefinitions count
      state.masterGameDefinitionsCount = Object.keys(
        state.masterGameDefinitions
      ).length;

      // Update happy hours tracking
      if (
        gameDefinition.happyHoursTimerStarted &&
        gameDefinition.status?.toLowerCase() === "active"
      ) {
        state.isHappyHoursEnabled = true;
      }

      if (
        gameDefinition.happyHoursTimerStarted &&
        gameDefinition.status?.toLowerCase() === "active"
      ) {
        // Check if the game affects the min/max times
        if (gameDefinition.happyHoursStartTime < state.happyHoursMinStartTime) {
          state.happyHoursMinStartTime = gameDefinition.happyHoursStartTime;
          state.happyHoursMinGameDefId = gameDefinition.gameDefId;
        }
        if (gameDefinition.happyHoursEndTime > state.happyHoursMaxEndTime) {
          state.happyHoursMaxEndTime = gameDefinition.happyHoursEndTime;
          state.happyHoursMaxGameDefId = gameDefinition.gameDefId;
        }
      }
    },

    upadatePlayersCount: (state, action) => {
      const { playerCountMap } = action.payload;
      // console.log("Player Count Map is ", playerCountMap);
      // const { realStakeGameVariants, playStateGameVariants } = state;
      for (let i = 0; i < playerCountMap.length; i++) {
        let playerCountItem = playerCountMap[i];
        if (
          state.masterGameDefinitions.hasOwnProperty(playerCountItem.gameDefId)
        ) {
          state.masterGameDefinitions[playerCountItem.gameDefId].onlineCount =
            playerCountItem.playerCount;
        }
      }
    },

    updateHappyHoursTileSort: (state) => {
      state.happyHoursTileSort = !state.happyHoursTileSort;
    },

    handleHandRankingTagLineData: (state, action) => {
      const {
        cashGameTagLineEnabled,
        cashGameTagLineRanks,
        cashGameTagLineGdid,
        tourneyTagLineEnabled,
        tourneyTagLineRanks,
      } = action.payload;

      state.handRankingTagLineData.cashGameTagLineEnabled =
        cashGameTagLineEnabled;
      state.handRankingTagLineData.tourneyTagLineEnabled =
        tourneyTagLineEnabled;
      state.handRankingTagLineData.cashGameTagLineGdid = cashGameTagLineGdid;
      state.handRankingTagLineData.cashGameTagLineRanks = cashGameTagLineRanks;
      state.handRankingTagLineData.tourneyTagLineRanks = tourneyTagLineRanks;

      if (cashGameTagLineEnabled) {
        for (let i = 0; i < cashGameTagLineGdid.length; i++) {
          if (
            state.masterGameDefinitions.hasOwnProperty(cashGameTagLineGdid[i])
          ) {
            state.masterGameDefinitions[
              cashGameTagLineGdid[i]
            ].tagLineEnabled = true;
          }
        }
      }
    },

    sortLobbyGamesByHappyHours: (state) => {
      if (state.happyHoursTileSort) {
        // Sort games by smallBlind if Happy Hours is enabled
        state.realGames = Object.values(state.masterGameDefinitions)
          .filter(
            (game) => game.flavourType.toLowerCase() === Constants.LB_REAL
          )
          .sort((a, b) => {
            if (
              (a.happyHoursEnabled && b.happyHoursEnabled) ||
              (a.happyHoursTimerStarted && b.happyHoursTimerStarted)
            ) {
              return b.smallBlind - a.smallBlind; // Sort Happy Hours games by smallBlind
            }
            if (a.happyHoursEnabled || a.happyHoursTimerStarted) return -1; // Happy Hours games come first
            if (b.happyHoursEnabled || b.happyHoursTimerStarted) return 1;
            return a.smallBlind - b.smallBlind; // Default sorting for non-Happy Hours games
          })
          .map((game) => game.gameDefId);

        state.realTexasHoldem = state.realGames.filter(
          (gameId) =>
            state.masterGameDefinitions[gameId]?.gameType ===
            Constants.TEXAS_HOLDEM
        );

        state.realOmaha = state.realGames.filter(
          (gameId) =>
            state.masterGameDefinitions[gameId]?.gameType === Constants.OMAHA
        );

        state.realOmaha5 = state.realGames.filter(
          (gameId) =>
            state.masterGameDefinitions[gameId]?.gameType === Constants.OMAHA5
        );

        state.realOmaha6 = state.realGames.filter(
          (gameId) =>
            state.masterGameDefinitions[gameId]?.gameType === Constants.OMAHA6
        );
      } else {
        // Revert to default sorting by smallBlind
        let realStakeGames = Object.values(state.masterGameDefinitions)
          .filter(
            (game) => game.flavourType.toLowerCase() === Constants.LB_REAL
          )
          .sort((a, b) => a.smallBlind - b.smallBlind);

        state.realGames = realStakeGames.map((game) => game.gameDefId);

        state.realTexasHoldem = realStakeGames
          .filter((game) => game.gameType === Constants.TEXAS_HOLDEM)
          .map((game) => game.gameDefId);

        state.realOmaha = realStakeGames
          .filter((game) => game.gameType === Constants.OMAHA)
          .map((game) => game.gameDefId);

        state.realOmaha5 = realStakeGames
          .filter((game) => game.gameType === Constants.OMAHA5)
          .map((game) => game.gameDefId);

        state.realOmaha6 = realStakeGames
          .filter((game) => game.gameType === Constants.OMAHA6)
          .map((game) => game.gameDefId);
      }
    },

    handleTourneysLobbyData: (state, action) => {
      const { upcomingTourneys, completedTourneys, tourneyTiles } =
        action.payload;

      state.receivedTourneyData = true;
      let updatedUpcomingTourneysData = [...upcomingTourneys].map(
        (eachTourney) => {
          eachTourney["tourneyIdentifier"] = "upcomingTourneys";
          eachTourney["tourneyPlayerStatus"] = "NOT_REGISTERED";
          eachTourney["isAlreadyRegisteredTourney"] = false;
          eachTourney["isFlightTourney"] = eachTourney.tournamentType
            .toLowerCase()
            .includes("flight");

          eachTourney["isFlightParentTourney"] =
            eachTourney.tournamentType ===
            Constants.TOURNEY_TYPES.FLIGHT_PARENT;
          return eachTourney;
        }
      );
      let updatedCompletedTourneys = [...completedTourneys].map(
        (eachTourney) => {
          eachTourney["tourneyIdentifier"] = "completedTourneys";
          eachTourney["tourneyPlayerStatus"] = "COMPLETED";
          eachTourney["isFlightTourney"] = eachTourney.tournamentType
            .toLowerCase()
            .includes("flight");
          eachTourney["isFlightParentTourney"] =
            eachTourney.tournamentType ===
            Constants.TOURNEY_TYPES.FLIGHT_PARENT;

          return eachTourney;
        }
      );

      let tourneysMap = new Map();
      [...updatedUpcomingTourneysData, ...updatedCompletedTourneys].forEach(
        (tourney) => tourneysMap.set(tourney.tourneyId, tourney)
      );

      state.tourneys.masterTourneysData = tourneysMap;

      state.tourneyTabs = [...tourneyTiles];
      if (
        state.receivedBalanceResponse &&
        state.receivedTokenResponse &&
        state.receivedLobbyData
      ) {
        // state.showLobbyLoading = false;
        state.showTourneyLobbyLoading = false;
      }
      updateTourneyTabberItemsBasedOnAvailability(state);
      if (!state.isLackGamesReceived) {
        let lackProtocol =
          "lack#" +
          JSON.stringify({
            playerName: state.player.userName,
          });
        // LobbyConnection.getInstance()
        //   .lobbyConnection.clientListener.getCommunicationChannel()
        //   .sendMessage(lackProtocol);
        LobbyConnection.getInstance().writeMessage(lackProtocol);
      }
    },

    handleDynamicTourneyLobbyData: (state, action) => {
      const { tournaments } = action.payload;

      let updatedTourneysMap = new Map(state.tourneys.masterTourneysData); // Keep old data

      tournaments.forEach((eachTourney) => {
        let existingTourney =
          updatedTourneysMap.get(eachTourney.tourneyId) || {}; // Preserve old data
        let updatedTourney = {
          ...existingTourney, // Keep old data
          ...eachTourney, // Override only new data
          tourneyIdentifier: "upcomingTourneys",
          tourneyPlayerStatus: "NOT_REGISTERED",
          isAlreadyRegisteredTourney: false,
          isFlightTourney: eachTourney.tournamentType
            .toLowerCase()
            .includes("flight"),
          isFlightParentTourney:
            eachTourney.tournamentType ===
            Constants.TOURNEY_TYPES.FLIGHT_PARENT,
        };

        updatedTourneysMap.set(eachTourney.tourneyId, updatedTourney);

        if (updatedTourney?.isFlightTourney) {
          updatedTourneysMap = updateTourneySimilarFlights(
            updatedTourneysMap,
            updatedTourney
          );
        }
      });

      state.tourneys.masterTourneysData = updatedTourneysMap;

      state.receivedTourneyData = true;
      updateTourneyTabberItemsBasedOnAvailability(state);
    },

    handleTourneyRegisteredCountChange: (state, action) => {
      const { tourneyRegistrationCountMap } = action.payload;

      Object.keys(tourneyRegistrationCountMap).map((eachTourneyId) => {
        let tourneyDetails =
          state.tourneys.masterTourneysData.get(eachTourneyId);
        if (tourneyDetails) {
          tourneyDetails.registeredPlayerCount =
            tourneyRegistrationCountMap[eachTourneyId];

          //NOTE: When managing a Map in Redux, always create a new Map instance when performing updates to avoid directly mutating the state. This ensures compliance with Redux’s immutability principles and prevents errors related to frozen objects.
          state.tourneys.masterTourneysData = new Map(
            state.tourneys.masterTourneysData
          ).set(eachTourneyId, tourneyDetails);
          updateTourneyTabberItemsBasedOnAvailability(state);
        }
      });
    },

    handleTourneyStatusChange: (state, action) => {
      const { tourneyId, status, tourneyStartTime } = action.payload;
      let tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);

      if (tourneyDetails) {
        tourneyDetails.tourneyStatus = status;
        tourneyDetails.tourneyStartTime = tourneyStartTime;
        //NOTE: When managing a Map in Redux, always create a new Map instance when performing updates to avoid directly mutating the state. This ensures compliance with Redux’s immutability principles and prevents errors related to frozen objects.
        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(tourneyId, tourneyDetails);
        updateTourneyTabberItemsBasedOnAvailability(state);
      }
    },

    handleTourneyComplete: (state, action) => {
      const { tourneyId } = action.payload;

      let tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);

      if (tourneyDetails) {
        tourneyDetails.tourneyStatus = Constants.TOURNEY_STATUS_COMPLETED;
        tourneyDetails.tourneyIdentifier = "completedTourneys";
        tourneyDetails.isAlreadyRegisteredTourney = false;

        //NOTE: When managing a Map in Redux, always create a new Map instance when performing updates to avoid directly mutating the state. This ensures compliance with Redux’s immutability principles and prevents errors related to frozen objects.
        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(tourneyId, tourneyDetails);

        updateTourneyTabberItemsBasedOnAvailability(state);
      }
    },

    handleTourneyPlayerAutoRegister: (state, action) => {
      const { tourneyId, registerAck } = action.payload;

      let tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);
      if (tourneyDetails) {
        tourneyDetails.registeredPlayerCount = registerAck.tourneyPlayerCount;
        tourneyDetails.tourneyIdentifier = "myTourneys";
        tourneyDetails.isAlreadyRegisteredTourney = true;
        //NOTE: When managing a Map in Redux, always create a new Map instance when performing updates to avoid directly mutating the state. This ensures compliance with Redux’s immutability principles and prevents errors related to frozen objects.
        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(tourneyId, tourneyDetails);
        updateTourneyTabberItemsBasedOnAvailability(state);
      }
    },

    handleTourneyDynamicCountsUpdate: (state, action) => {
      const { tourneyId, reEntryCount, reBuyCount, addOnCount } =
        action.payload;

      let tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);
      if (tourneyDetails) {
        tourneyDetails.totalReEntryCount = reEntryCount;
        tourneyDetails.totalRebuyCount = reBuyCount;
        tourneyDetails.totalAddOnCount = addOnCount;

        //NOTE: When managing a Map in Redux, always create a new Map instance when performing updates to avoid directly mutating the state. This ensures compliance with Redux’s immutability principles and prevents errors related to frozen objects.
        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(tourneyId, tourneyDetails);
        updateTourneyTabberItemsBasedOnAvailability(state);
      }
    },

    handleTourneyCompletedStackDetails: (state, action) => {
      const { tourneyId } = action.payload;
      let tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);
      if (tourneyDetails) {
        tourneyDetails["stackDetails"] = { ...action.payload };
        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(tourneyId, tourneyDetails);
        updateTourneyTabberItemsBasedOnAvailability(state);
      }
    },

    handleTourneyQualifiedPlayersDetails: (state, action) => {
      const { tourneyId, qualifiedPlayers } = action.payload;
      let tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);

      if (tourneyDetails) {
        tourneyDetails["qualifiedPlayers"] = qualifiedPlayers;
        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(tourneyId, tourneyDetails);
      }
    },

    handleTourneyRemove: (state, action) => {
      const { tourneyId } = action.payload;
      state.tourneys.masterTourneysData.delete(tourneyId);
      updateTourneyTabberItemsBasedOnAvailability(state);
    },

    handleNewChildTourneyData: (state, action) => {
      const { tourneyId, childTourneys } = action.payload;

      let tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);
      if (tourneyDetails) {
        tourneyDetails.childTourneys = [...childTourneys];

        //NOTE: When managing a Map in Redux, always create a new Map instance when performing updates to avoid directly mutating the state. This ensures compliance with Redux’s immutability principles and prevents errors related to frozen objects.
        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(tourneyId, tourneyDetails);
        updateTourneyTabberItemsBasedOnAvailability(state);
      }
    },

    requestTourneyInfoData: (state, action) => {
      const { tourneyId, defaultSelectedTab } = action.payload;
      const requestPayload = {
        tourneyId: tourneyId,
        playerName: state.player.userName,
      };
      const tourneyInfoProtocol =
        "TourneyInfo#" + JSON.stringify({ ...requestPayload });

      LobbyConnection.getInstance().writeMessage(tourneyInfoProtocol);
      const tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);
      if (tourneyDetails) {
        tourneyDetails["hideRegistrationCTAButtons"] = false;
        tourneyDetails["tourneyEntriesData"] = [];
        if (defaultSelectedTab) {
          tourneyDetails["defaultSelectedTab"] = defaultSelectedTab;
        }
        //NOTE: When managing a Map in Redux, always create a new Map instance when performing updates to avoid directly mutating the state. This ensures compliance with Redux’s immutability principles and prevents errors related to frozen objects.
        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(tourneyId, tourneyDetails);

        console.log("im_at_first_level", tourneyId);
        state.selectedTourneyDetailsId = tourneyId;
      }
      state.showTourneyInfoPage = true;
      state.showTourneyInfoLoading = true;
      state.toastMessages = [];
    },

    updateTourneyRegistrationCTAButtonsHide: (state, action) => {
      const { isHidden } = action.payload;
      const tourneyId = state.selectedTourneyDetailsId;
      if (tourneyId) {
        const tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);
        tourneyDetails.hideRegistrationCTAButtons = isHidden;
        //NOTE: When managing a Map in Redux, always create a new Map instance when performing updates to avoid directly mutating the state. This ensures compliance with Redux’s immutability principles and prevents errors related to frozen objects.
        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(tourneyId, tourneyDetails);
      }
    },

    requestTourneyEntriesDynamicData: (state, action) => {
      const { tourneyId } = action.payload;
      const entriesDetailsPayload = {
        tourneyId: tourneyId,
        playerName: state.player.userName,
      };
      const entriesProtocol =
        "ranksData#" + JSON.stringify({ ...entriesDetailsPayload });
      LobbyConnection.getInstance().writeMessage(entriesProtocol);
    },

    requestUpdatedTourneyPlayerStatus: (state, action) => {
      let tourneyId = action.payload?.tourneyId;
      if (typeof tourneyId !== "string" && typeof tourneyId !== "number") {
        console.warn("Invalid tourneyId:", tourneyId);
        tourneyId = null; // Fallback to null if invalid
      }
      let playerName = state?.player?.userName;

      const tpsPayload = { playerName, tourneyId };
      console.log("tpsPayload:", tpsPayload); // Debugging log

      try {
        const tpsProtocol = "tps#" + JSON.stringify(tpsPayload);
        LobbyConnection.getInstance().writeMessage(tpsProtocol);
      } catch (error) {
        console.log("JSON stringify failed at tps :", error);
      }
    },

    requestFlightTourneyStackDetails: (state, action) => {
      const payload = {
        playerName: state.player.userName,
        tourneyId: action?.payload?.tourneyId ? action.payload.tourneyId : null,
      };
      const flightStackDetailProtocol =
        "FlightStackDetails#" + JSON.stringify({ ...payload });
      LobbyConnection.getInstance().writeMessage(flightStackDetailProtocol);
    },

    requestFlightTourneyQualifiedPlayers: (state, action) => {
      const payload = {
        playerName: state.player.userName,
        tourneyId: action?.payload?.tourneyId ? action.payload.tourneyId : null,
      };
      const flightQualifiedPlayersProtocol =
        "FlightQualifiedPlayers#" + JSON.stringify({ ...payload });
      LobbyConnection.getInstance().writeMessage(
        flightQualifiedPlayersProtocol
      );
    },

    requestFunChipsReload: (state, action) => {
      const requestPayload = {
        playerName: state.player.userName,
      };
      const reloadPayload =
        "ChipsReload#" + JSON.stringify({ ...requestPayload });

      LobbyConnection.getInstance().writeMessage(reloadPayload);
    },

    requestCurrentFunChipsBalance: (state, action) => {
      const requestPayload = {
        playerName: state.player.userName,
      };
      const fetchFunChipsBalance =
        "getPlayerChips#" + JSON.stringify({ ...requestPayload });
      LobbyConnection.getInstance().writeMessage(fetchFunChipsBalance);
    },

    requestTourneyTablesDynamicData: (state, action) => {
      const { tourneyId } = action.payload;
      const tablesDetailsPayload = {
        tourneyId: tourneyId,
        playerName: state.player.userName,
      };
      const tablesProtocol =
        "tabledata#" + JSON.stringify({ ...tablesDetailsPayload });
      LobbyConnection.getInstance().writeMessage(tablesProtocol);
    },

    handleTourneyRanksData: (state, action) => {
      let { tourneyId, rankDataList, yourRank } = action.payload;
      const tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);
      if (tourneyDetails) {
        let index = rankDataList.findIndex(
          (item) => item["playerName"] === state.player.userName
        );
        console.log("Yashwanth logging index:", index);
        if (index >= 0 && yourRank) {
          let currentUserRankDetails = yourRank; //rankDataList[index];

          rankDataList.splice(index, 1);
          let updatedRanksDataList = rankDataList;

          rankDataList = [currentUserRankDetails, ...updatedRanksDataList];
        }
        tourneyDetails.tourneyEntriesData = rankDataList;
        //NOTE: When managing a Map in Redux, always create a new Map instance when performing updates to avoid directly mutating the state. This ensures compliance with Redux’s immutability principles and prevents errors related to frozen objects.
        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(tourneyId, tourneyDetails);
      }
    },
    handleTourneyTablesData: (state, action) => {
      const { tourneyTableDetailsList, activeTableCount } = action.payload;
      let updatedTableData = tourneyTableDetailsList.map((eachTable) => {
        return {
          domainName: eachTable?.domainName,
          nodeIp: eachTable?.nodeIp,
          tableId: eachTable?.tableID,
          players: eachTable?.numberOfPlayers,
          largest: eachTable?.highestChips,
          smallest: eachTable?.lowestChips,
        };
      });
      let tourneyId = state.selectedTourneyDetailsId;
      let tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);
      if (tourneyDetails) {
        if (tourneyDetails?.tourneyIdentifier === "completedTourneys") {
          tourneyDetails["tourneyTablesStatusList"] = [];
        } else {
          tourneyDetails["tourneyTablesStatusList"] = [...updatedTableData];
        }
        tourneyDetails["activeTableCount"] = activeTableCount;
        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(tourneyId, tourneyDetails);
      }
    },

    updateRemindMeStatusToServer: (state, action) => {
      const { tourneyId, RemindMeEnable } = action.payload;
      const isReminded = RemindMeEnable === "true";

      if (tourneyId && isReminded) {
        const requestBody = {
          tourneyId: tourneyId,
          playerName: state.player.userName,
          reminder: isReminded,
        };

        const remindMeProtocol = "rm#" + JSON.stringify({ ...requestBody });
        LobbyConnection.getInstance().writeMessage(remindMeProtocol);

        const tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);
        tourneyDetails.reminder = isReminded;
        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(tourneyId, tourneyDetails);
      }
    },

    updateTourneyInfoData: (state, action) => {
      let tourneyDetails = state.tourneys.masterTourneysData.get(
        state.selectedTourneyDetailsId
      );

      if (tourneyDetails) {
        tourneyDetails = { ...tourneyDetails, ...action.payload };
        //NOTE: When managing a Map in Redux, always create a new Map instance when performing updates to avoid directly mutating the state. This ensures compliance with Redux’s immutability principles and prevents errors related to frozen objects.
        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(state.selectedTourneyDetailsId, tourneyDetails);
      }

      state.showTourneyInfoLoading = false;
    },

    closeTourneyInfoPage: (state, action) => {
      const tourneyDetails = state.tourneys.masterTourneysData.get(
        state.selectedTourneyDetailsId
      );
      if (tourneyDetails) {
        tourneyDetails.defaultSelectedTab = null;
        //NOTE: When managing a Map in Redux, always create a new Map instance when performing updates to avoid directly mutating the state. This ensures compliance with Redux’s immutability principles and prevents errors related to frozen objects.
        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(state.selectedTourneyDetailsId, tourneyDetails);
      }
      state.showTourneyInfoPage = false;
      state.selectedTourneyDetailsId = null;
      state.showLateRegisterSuccessPopup = false;
    },

    closeTourneyInfoPageOnlyIfLoading: (state, action) => {
      if (state.showTourneyInfoLoading) {
        state.showTourneyInfoPage = false;
        state.selectedTourneyDetailsId = null;
        state.showLateRegisterSuccessPopup = false;
      }
    },

    updateCashAndFunGamesToggle: (state, action) => {
      const { isCashGameToggled } = action.payload;
      state.isCashGamesToggled = isCashGameToggled;
      state.showPrivateTableLobby = false;
    },

    updateNotificationCount: (state, action) => {
      const { notificationsCount } = action.payload;
      state.notificationsCount = notificationsCount ? notificationsCount : 0;
    },
    updateConfigsPlayer: (state, action) => {
      const { locationConfigData } = action.payload;
      const {
        pushToKyc,
        userLocationCompliance,
        locationComplianceMessage,
        location_games,
        location_bonusSummary,
        location_promotionsLeaderboard,
        location_missions,
        location_promotionsActiveOffers,
        location_rewards,
        location_loyalty,
        location_addcash,
        location_addCashLimits,
        location_redeem,
        location_kyc,
      } = locationConfigData;
      state.pushToKyc = pushToKyc;
      state.userLocationCompliance = userLocationCompliance;
      state.location_games = location_games;
      state.location_bonusSummary = location_bonusSummary;
      state.location_promotionsLeaderboard = location_promotionsLeaderboard;
      state.location_missions = location_missions;
      state.location_promotionsActiveOffers = location_promotionsActiveOffers;
      state.location_rewards = location_rewards;
      state.location_loyalty = location_loyalty;
      state.location_addcash = location_addcash;
      state.location_addCashLimits = location_addCashLimits;
      state.location_redeem = location_redeem;
      state.location_kyc = location_kyc;
      if (userLocationCompliance) {
        //message is sent only if user is in compliance state
        state.locationComplianceMessage = locationComplianceMessage.replace(
          /\+/g,
          "\n"
        );
        state.player.isAllowLocation = false;
      } else {
        //message is sent to empty if already set previously
        state.locationComplianceMessage = "";
        state.player.isAllowLocation = state.player.initialisAllowLocation;
      }
    },
    updateKycStatus: (state, action) => {
      const { pushToKYC } = action.payload;
      state.pushToKyc = pushToKYC === "true" || pushToKYC === true;
    },
    updateHomePageData: (state, action) => {
      try {
        let { homeData } = action.payload;
        let { horizantalBanners, promotionalbanners, serverData } = homeData;
        // Filter out banners where displaytype contains "PKNF" (case-insensitive)
        let filteredHorizontalBanners = horizantalBanners.filter(
          (banner) => !banner.displaytype.toLowerCase().includes("pknf")
        );

        let filteredPromotionalBanners = promotionalbanners.filter(
          (banner) => !banner.displaytype.toLowerCase().includes("pknf")
        );

        if (serverData) {
          let { rakeback, missions, leaderboard } = serverData;
          if ("currentRBPoints" in rakeback) {
            state.homeData.rakeback = rakeback;
          } else {
            state.homeData.rakeback = null;
          }
          if (missions.missionDescription) {
            state.homeData.missions = missions;
          } else {
            state.homeData.missions = null;
          }
          if (leaderboard.lbName) {
            state.homeData.leaderboard = leaderboard;
          } else {
            state.homeData.leaderboard = null;
          }
        }
        state.homeData.horizontalBanners = filteredHorizontalBanners;
        state.homeData.CTPromotionalBanners = filteredPromotionalBanners;
        state.homeData.dataUpdated = true;
      } catch (err) {
        console.log("Spruha log >>> Home Page Error is >>> ", err);
      }
    },
    updateNDUPromotionalBanners: (state, action) => {
      let { nduBannersData } = action.payload;

      // Convert existing banners to a Map for quick lookup
      let existingPKNFBannersMap = new Map(
        state.homeData.PKNFPromotionalBanners?.map((banner) => [
          banner.displaytype.toLowerCase(),
          banner,
        ]) || []
      );

      nduBannersData.forEach((banner) => {
        let lowerDisplayType = banner.displaytype.toLowerCase();

        if (lowerDisplayType.startsWith("pknf")) {
          if (!existingPKNFBannersMap.has(lowerDisplayType)) {
            existingPKNFBannersMap.set(lowerDisplayType, banner);
          }
        }
      });

      state.homeData.PKNFPromotionalBanners = Array.from(
        existingPKNFBannersMap.values()
      );

      console.log(
        "PKNF BANNERS UPDATED ",
        state.homeData.PKNFPromotionalBanners
      );
    },
    resetHomePageData: (state, action) => {
      state.homeData = {
        rakeback: null,
        leaderboard: null,
        missions: null,
        horizontalBanners: [],
        CTPromotionalBanners: [],
        dataUpdated: false,
        recommendedGames: [],
        PKNFPromotionalBanners: [],
      };
    },

    updateResourceLoadFailure: (state, action) => {
      state.resourceLoadFailure = true;
    },

    checkIfResourcesFailed: (state, action) => {},

    updateHomePageRecommendedData: (state, action) => {
      let { lbGameDefIds } = action.payload;
      let gameDefinitions = [];
      for (let lbGameId of lbGameDefIds) {
        let gameDefId = lbGameId.split("_")[2];
        if (gameDefId) {
          let gameDefinition = state.masterGameDefinitions[gameDefId];
          if (gameDefinition?.status.toLowerCase() === "active") {
            gameDefinitions.push(gameDefinition);
          }
        }
      }
      state.homeData.recommendedGames = gameDefinitions;
    },

    updateGridTiles: (state, action) => {
      state.homeData.gridTilesData = action.payload.gridTiles;
    },

    updateTopBanners: (state, action) => {
      state.homeData.topLoadedBanners = action.payload.topBanners;
    },

    updateBottomBanners: (state, action) => {
      state.homeData.bottomLoadedBanners = action.payload.bottomBanners;
    },

    updateShowLobbyLoading: (state, action) => {
      state.showLobbyLoading = action.payload.showLobbyLoading;
    },

    updateSelectedFlightDetailsTabbar: (state, action) => {
      state.selectedFlightDetailsTabbar = action.payload.selectedTab;
    },

    setShowGameTable: (state, action) => {
      state.showGameTable = action.payload.showGameTable;
    },

    setShowPrivateTableLobby: (state, action) => {
      state.showPrivateTableLobby = action.payload.showPrivateTableLobby;
    },

    updateShowTourneyInfoPage: (state, action) => {
      state.showTourneyInfoPage = action.payload.showTourneyInfoPage;
    },

    updateIsLackTourneyGamesDisplayed: (state, action) => {
      state.isLackTourneyGamesDisplayed =
        action.payload.isLackTourneysDisplayed;
    },

    updateLackGamesDetails: (state, action) => {
      const { playerId, activeGames } = action.payload;
      state.isLackGamesReceived = true;
      state.lackGames = {
        playerId: playerId,
        activeGames: activeGames,
      };
    },

    updateLackReceivedStatus: (state, action) => {
      state.isLackGamesReceived = true;
    },

    updateLackGamesShowStatus: (state, action) => {
      state.isLackGamesShown = true;
    },

    handleRemoveDisplayedCashLackGame: (state, action) => {
      const { gameId } = action.payload;
      state.lackGames.activeGames = state.lackGames.activeGames.filter(
        (eachLackGame) => eachLackGame.gameId !== gameId
      );
    },
    updateLobbyHeartBeatDetails: (state, action) => {
      const { onlinePlayerCount, currentTime } = action.payload;
      state.onlinePlayerCount = onlinePlayerCount ?? 0;
      state.currentTimeStamp = currentTime;
      // state.currentLobbyTime = new Date(currentTime);

      const currentTimeInDateObjectFormat = new Date(currentTime);
      state.currentLobbyTime = currentTimeInDateObjectFormat;

      if (state.isStateInCompliance) {
        const hours = currentTimeInDateObjectFormat.getHours();
        const minutes = currentTimeInDateObjectFormat.getMinutes();
        // const seconds = currentTimeInDateObjectFormat.getSeconds();
        console.log("Redux state:", state);
        console.log("blockStartTime:", state.blockStartTime.hours);
        console.log("blockStartTime:", state.blockStartTime.minutes);
        console.log("blockEndTime:", state.blockEndTime);

        // Convert start and end time into total minutes from the start of the day
        const startTotalMinutes =
          state.blockStartTime.hours * 60 + state.blockStartTime.minutes;
        const endTotalMinutes =
          state.blockEndTime.hours * 60 + state.blockEndTime.minutes;
        const currentTotalMinutes = hours * 60 + minutes;

        console.log("startTotalMinutes:", startTotalMinutes);
        console.log("endTotalMinutes:", endTotalMinutes);
        console.log("currentTotalMinutes:", currentTotalMinutes);

        // // Check if the current time is within the start and end time range
        // if (
        //   startTotalMinutes <= currentTotalMinutes &&
        //   currentTotalMinutes <= endTotalMinutes
        // ) {
        //   console.log("BLOCKED HOUR IF CHECK");
        //   state.isBlockedHour = true;
        // } else {
        //   console.log("BLOCKED HOUR ELSE CHECK");
        //   state.isBlockedHour = false;
        // }

        // If the block end time is before the start time (i.e., spanning midnight), adjust the range
        let isWithinRange = false;

        // If blockEndTime is less than blockStartTime, then it crosses midnight
        if (endTotalMinutes < startTotalMinutes) {
          // Check if current time is after start time OR before end time (i.e., crossing midnight)
          isWithinRange =
            currentTotalMinutes >= startTotalMinutes ||
            currentTotalMinutes < endTotalMinutes;
        } else {
          // Normal range check (within the same day)
          isWithinRange =
            currentTotalMinutes >= startTotalMinutes &&
            currentTotalMinutes <= endTotalMinutes;
        }

        if (isWithinRange) {
          console.log("BLOCKED HOUR IF CHECK");
          state.isBlockedHour = true;
        } else {
          console.log("BLOCKED HOUR ELSE CHECK");
          state.isBlockedHour = false;
        }

        // Check if the time is between 05:00:00 AM and 11:59:59 PM
        // state.isNonBlockingHours = hours < 0 || hours >= 5;
        // state.isNonBlockingHours =
        //   hours < state.blockingHours[0] || hours > state.blockingHours[1];

        // Check if the time is between 05:00:01 AM and 11:59:59 PM
        // if (
        //   hours > 5 ||
        //   (hours === 5 && minutes > 0) ||
        //   (hours === 5 && minutes === 0 && seconds > 0)
        // ) {
        //   state.isNonBlockingHours = true;
        // } else {
        //   state.isNonBlockingHours = false;
        // }
        console.log("Current lobby time:", state.currentLobbyTime);
      }

      // state.isNonBlockingHours = false; //for test purpose temp value
    },

    updateShowTourneyRegisterConfirmationPopup: (state, action) => {
      state.showTourneyRegisterConfirmationPopup = action.payload.isOpen;
    },

    updateShowAllTicketsSection: (state, action) => {
      state.showAllTicketsSection = action.payload.isOpen;
    },

    updateLobbyCurrentTab: (state, action) => {
      state.currentTab = action.payload.selectedTab;
    },

    updateShowTourneyAddCashTicketRegistrationPopup: (state, action) => {
      state.showTourneyAddCashTicketRegistrationPopup = action.payload.isOpen;
    },
    updateShowTourneyReEntryConfirmationPopup: (state, action) => {
      state.showTourneyReEntryConfirmationPopup = action.payload.isOpen;
    },

    updateShowTourneyRegisterOrDeregisterRequestLoading: (state, action) => {
      state.showTourneyRegisterOrDeregisterRequestLoading =
        action.payload.isLoading;
    },

    updateTourneyTicketsClaimInProgress: (state, action) => {
      const { tourneyId, isLoading } = action.payload;
      state.tourneyTicketsClaimInProgressDetails[`${tourneyId}`] = isLoading;
    },

    updateShowTourneyReEntryRequestLoading: (state, action) => {
      state.showTourneyReEntryRequestLoading = action.payload.isLoading;
    },

    updateShowTourneyInsufficientFundsModal: (state, action) => {
      state.showTourneyInsufficientFundsModal = action.payload.isOpen;
    },

    updateShowTourneyFairPlayPolicyRestruction: (state, action) => {
      state.showTourneyFairPlayPolicyRestrictionPopup = action.payload.isOpen;
    },

    updateShowLateRegisterSuccessPopup: (state, action) => {
      state.showLateRegisterSuccessPopup = action.payload.isOpen;
    },

    updateTourneyPlayerStatusChange: (state, action) => {
      const { playerStatusObjList } = action.payload;

      let updatedTourneysData = new Map(state.tourneys.masterTourneysData);

      playerStatusObjList.forEach((eachTourneyStatus) => {
        const {
          tourneyId,
          tourneyPlayerStatus,
          reminder,
          tourneyStatus,
          activePlayerForTourney,
          bustedOut,
        } = eachTourneyStatus;

        if (!updatedTourneysData.has(tourneyId)) return; // Skip if tourneyId not found

        let eachTourney = updatedTourneysData.get(tourneyId);

        // Ensure `tourneyPlayerStatus` exists
        if (!Object.keys(eachTourneyStatus).includes("tourneyPlayerStatus")) {
          let prevTourneyStatus = { ...eachTourneyStatus };
          eachTourneyStatus["tourneyPlayerStatus"] =
            Constants.TOURNEY_PLAYER_STATUS_MISSED;

          const logPayload = {
            type: "TPS DATA ISSUE tourneyPlayerStatus KEY MISSED.",
            playerName: state?.player?.userName,
            receivedData: prevTourneyStatus,
          };

          let debugProtocol = `DB#${JSON.stringify(logPayload)}`;
          LobbyConnection.getInstance().writeMessage(debugProtocol);
        }

        // Update tourney details
        eachTourney.tourneyStatus = tourneyStatus;

        if (!eachTourney?.bustedOut) {
          eachTourney["bustedOut"] = bustedOut;
        }

        eachTourney["activePlayerForTourney"] = activePlayerForTourney;

        switch (tourneyPlayerStatus) {
          case Constants.TOURNEY_PLAYER_STATUS_WON:
            eachTourney.tourneyPlayerStatus = tourneyPlayerStatus;
            eachTourney.tourneyIdentifier = "completedTourneys";
            eachTourney.tourneyStatus = Constants.TOURNEY_STATUS_WON;
            eachTourney.isAlreadyRegisteredTourney = false;
            eachTourney.reminder = reminder;
            break;

          case Constants.TOURNEY_STATUS_COMPLETED:
            eachTourney.tourneyPlayerStatus = tourneyPlayerStatus;
            eachTourney.tourneyIdentifier = "completedTourneys";
            eachTourney.tourneyStatus = Constants.TOURNEY_STATUS_COMPLETED;
            eachTourney.isAlreadyRegisteredTourney = false;
            break;

          case Constants.TOURNEY_PLAYER_STATUS_CANCELLED:
            eachTourney.tourneyPlayerStatus = tourneyPlayerStatus;
            eachTourney.tourneyIdentifier = "completedTourneys";
            eachTourney.tourneyStatus = Constants.TOURNEY_STATUS_CANCELLED;
            eachTourney.isAlreadyRegisteredTourney = false;
            eachTourney.reminder = reminder;
            break;

          case Constants.TOURNEY_PLAYER_STATUS_MISSED:
            if (eachTourney.tourneyStatus !== Constants.TOURNEY_STATUS_LIVE) {
              eachTourney.tourneyPlayerStatus = tourneyPlayerStatus;
              eachTourney.tourneyStatus =
                Constants.TOURNEY_PLAYER_STATUS_MISSED;
              eachTourney.isAlreadyRegisteredTourney = false;
              eachTourney.reminder = reminder;
              eachTourney.tourneyIdentifier = "completedTourneys";
            }
            break;

          case Constants.TOURNEY_PLAYER_STATUS_REGISTERED:
            eachTourney.tourneyPlayerStatus = tourneyPlayerStatus;
            eachTourney.tourneyIdentifier = "myTourneys";
            eachTourney.reminder = reminder;
            eachTourney.isAlreadyRegisteredTourney = true;
            break;

          case Constants.TOURNEY_PLAYER_STATUS_RE_ENTRY:
            eachTourney.tourneyPlayerStatus = tourneyPlayerStatus;
            eachTourney.reminder = reminder;
            eachTourney.tourneyIdentifier = "myTourneys";
            eachTourney.isAlreadyRegisteredTourney = false;
            eachTourney.lateRegistrationAllowed = false;
            break;

          default:
            if (!tourneyPlayerStatus) {
              eachTourney.tourneyPlayerStatus = "NOT_REGISTERED";
              eachTourney.tourneyIdentifier = "upcomingTourneys";
              eachTourney.reminder = reminder;
              eachTourney.isAlreadyRegisteredTourney = false;
            }
        }
        updatedTourneysData.set(tourneyId, { ...eachTourney });
      });
      state.showRedDotOnMyTourneys = [...updatedTourneysData.values()].some(
        (tourney) => tourney.tourneyIdentifier === "myTourneys"
      );
      // Update state with new Map
      state.tourneys.masterTourneysData = updatedTourneysData;
      updateTourneyTabberItemsBasedOnAvailability(state);
    },

    updateTourneyPlayerBustedOut: (state, action) => {
      const { tourneyId, bustedOut } = action.payload;

      let tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);

      if (tourneyDetails) {
        if (tourneyDetails && !tourneyDetails?.bustedOut) {
          tourneyDetails["bustedOut"] = bustedOut;
        }
        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(tourneyId, tourneyDetails);
        updateTourneyTabberItemsBasedOnAvailability(state);
      }
    },

    updateTourneySlabChange: (state, action) => {
      const { tourneyId, winnersPayoutWithBonus, numberOfWinners } =
        action.payload;
      let tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);

      if (tourneyDetails) {
        tourneyDetails.displayWinners = numberOfWinners;
        tourneyDetails.winnersPayoutWithBonus = winnersPayoutWithBonus;

        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(tourneyId, tourneyDetails);
        updateTourneyTabberItemsBasedOnAvailability(state);
      }
    },
    updateTourneyPrizePoolUpdate: (state, action) => {
      const { tourneyId, updatedPrizePool } = action.payload;

      let tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);
      if (tourneyDetails) {
        tourneyDetails.breakEven = false;
        tourneyDetails.prizePool = updatedPrizePool;
        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(tourneyId, tourneyDetails);
        updateTourneyTabberItemsBasedOnAvailability(state);
      }
    },

    updateTourneyLateRegistrationEnded: (state, action) => {
      const { tourneyId, updatedPrizePool } = action.payload;

      let tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);
      if (tourneyDetails) {
        tourneyDetails.lateRegistrationAllowed = false;
        tourneyDetails.lateRegEnded = true;

        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(tourneyId, tourneyDetails);
        updateTourneyTabberItemsBasedOnAvailability(state);
      }
    },

    updateDepositorTourneyDetails: (state, action) => {
      const { tourneyId, addCashTicketRegistration } = action.payload;
      let tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);
      if (tourneyDetails) {
        tourneyDetails.addCashTicketRegistration = addCashTicketRegistration;
        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(tourneyId, tourneyDetails);
        updateTourneyTabberItemsBasedOnAvailability(state);
      }
    },

    updateTourneyInTheMoney: (state, action) => {
      const { tourneyId } = action.payload;

      let tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);
      if (tourneyDetails) {
        tourneyDetails.lateRegistrationAllowed = false;
        tourneyDetails.lateRegEnded = true;
        tourneyDetails.inTheMoney = true;
        tourneyDetails.hideRegistrationCTAButtons = true;
        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(tourneyId, tourneyDetails);
        updateTourneyTabberItemsBasedOnAvailability(state);
      }
    },

    updateTourneyInBreak: (state, action) => {
      const { tourneyId, breakStartTime, breakEndTime, breakInProgress } =
        action.payload;

      let tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);
      if (tourneyDetails) {
        tourneyDetails.breakStartTime = breakStartTime;
        tourneyDetails.breakEndTime = breakEndTime;
        tourneyDetails.breakInProgress = breakInProgress;

        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(tourneyId, tourneyDetails);
        updateTourneyTabberItemsBasedOnAvailability(state);
      }
    },

    handleTourneyStart: (state, action) => {
      //NOTE: For fresh tourney start , we are appending it to same lackTourneys list and stating the game using that to avoid multiple changes in different places.
      //Even for the original Lack tourneys protocol we will update the same list to maintain consistant flow.
      action.payload.tourneys.forEach((eachLackTourney) => {
        state.lackTourneys.push({
          ...eachLackTourney,
          isTourneyLackGame: action.payload.isTourneyLackGame,
        });
      });
      state.isLackTourneyGamesDisplayed = false;
    },

    handleRemoveDisplayedTourneyLackGame: (state, action) => {
      const { lackTourneyId } = action.payload;
      state.lackTourneys = state.lackTourneys.filter(
        (eachLackTourney) => eachLackTourney.tourneyId !== lackTourneyId
      );
    },

    addLobbyToastMessage: (state, action) => {
      const toastMessage = action.payload;
      state.toastMessages.filter(
        (toast) => toast.message === toastMessage.message
      ).length === 0 && state.toastMessages.push(toastMessage);
    },

    removeLobbyToastMessage: (state, action) => {
      const { message } = action.payload;

      let index = state.toastMessages.indexOf(message);
      state.toastMessages.splice(index, 1);
      // return currentState;
    },
    handleTourneyPrize: (state, action) => {
      const { tourneyId, playerName, playerWinning } = action.payload;
      //TODO: removing toast for now, need to show when no game tables are not open using this tourneyId, need to add this logic and display the toast.
      // state.toastMessages.push({
      //   type: Constants.TOAST_SUCCESS,
      //   message: `Congratulations! You won ${playerWinning} for playing tourney: ${tourneyId}`,
      // });
    },
    handleTourneyCancle: (state, action) => {
      const { tourneyId } = action.payload;
      let tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);
      if (tourneyDetails) {
        tourneyDetails.tourneyStatus = Constants.TOURNEY_STATUS_CANCELLED;
        tourneyDetails.isAlreadyRegisteredTourney = false;
        tourneyDetails.tourneyIdentifier = "completedTourneys";

        state.tourneys.masterTourneysData = new Map(
          state.tourneys.masterTourneysData
        ).set(tourneyId, tourneyDetails);
        updateTourneyTabberItemsBasedOnAvailability(state);
      }
    },
    showTourneyMaxTablesLimitReached: (state, action) => {
      state.toastMessages.push({
        type: Constants.TOAST_INFO,
        message: action.payload.message,
      });
    },
    handleDynamicTourneysStartData: (state, action) => {
      const { tourneyDetails } = action.payload;
      state.dynamicTourneysStartData.push(tourneyDetails);
      state.showLateRegisterSuccessPopup = false;
    },
    handleClearDynamicTourneyStartData: (state, action) => {
      state.dynamicTourneysStartData = [];
    },

    setPlayerConsentDetails: (state, action) => {
      const { userConsentFlag } = action.payload;
      if (userConsentFlag == "true") {
        state.player.userConsentFlag = true;
        state.player.gameJoinCheckAck = true;
      } else {
        state.player.userConsentFlag = false;
        state.player.gameJoinCheckAck = false;
      }
    },

    setGameJoinCheckAck: (state, action) => {
      const { gameJoinCheckAck } = action.payload;
      if (gameJoinCheckAck == "true") {
        state.player.gameJoinCheckAck = true;
        state.player.userConsentFlag = true;
      } else {
        state.player.gameJoinCheckAck = false;
        state.player.userConsentFlag = false;
      }
    },

    handleTourneyTicketsData: (state, action) => {
      const { tickets } = action.payload;
      state.tourneyTickets = [...tickets];
    },

    handleBlockedStatesDetails: (state, action) => {
      const { states, blockStartTime, blockEndTime } = action.payload;
      //"blockStartTime":"19:10","blockEndTime":"19:20"

      state.complianceStateNamesArray = states;

      let blockStartTimeDetails = blockStartTime.split(":");
      let blockEndTimeDetails = blockEndTime.split(":");
      state.blockStartTime = {
        hours: Number(blockStartTimeDetails[0]),
        minutes: Number(blockStartTimeDetails[1]),
      };
      state.blockEndTime = {
        hours: Number(blockEndTimeDetails[0]),
        minutes: Number(blockEndTimeDetails[1]),
      };

      console.log("Block Start time:", state.blockStartTime);
      console.log("Block End time:", state.blockEndTime);
      console.log("states: ", states);
      console.log("state.player.state:", state.player.state);

      state.isStateInCompliance = states.includes(state.player.state);
      console.log("isStateInCompliance:", state.isStateInCompliance);
    },

    setPrivateTablePermissions: (state, action) => {
      const { allowedToHostPT, allowedToJoinPT } = action.payload;
      state.privateTable.allowedToJoinPT = allowedToJoinPT;
      state.privateTable.allowedToHostPT = allowedToHostPT;
    },

    updatePrivateTableHostingDetails: (state, action) => {
      const { gameDefId, gameDefinition } = action.payload;
      state.privateTable.tableDetails[gameDefId] = {
        ...state.privateTable.tableDetails[gameDefId],
        gameDefinition: gameDefinition,
      };
    },

    handlePrivateTableCreateGameDef: (state, action) => {
      const { gdid, mode, gameDefinition } = action.payload;

      processPrivateTableCreation(state, gdid, mode, gameDefinition);
    },

    resetInitiateMatchmakingForGdid: (state, action) => {
      state.privateTable.initiateMatchmakingForGdid = null;
      state.privateTable.navigateToGameTable = false;
    },

    handlePrivateTableCreateGameDefFailure: (state, action) => {
      state.toastMessages.push({
        type: Constants.TOAST_ERROR,
        message: "Private table creation failed",
      });
    },

    removePrivateTableGameDefFromRunningGame: (state, action) => {
      const { gameDef } = action.payload;
      if (
        state.privateTable.lobbyData.runningGameDefIds.includes(Number(gameDef))
      ) {
        state.privateTable.lobbyData.runningGameDefIds =
          state.privateTable.lobbyData.runningGameDefIds.filter(
            (item) => item !== gameDef
          );
      }
    },

    handleProcessPrivateTablesLobbyData: (state, action) => {
      const { activeTables, completedTables } = action.payload;

      const activeAndCompletedGameDef = [...activeTables, ...completedTables];

      activeAndCompletedGameDef.forEach((data) => {
        let gameDefData = data;
        if (gameDefData.hostName === state.player.userName) {
          gameDefData.mode = Constants.HOSTED_PRIVATE_TABLE;
          state.privateTable.hostedTableCount++;
        } else {
          gameDefData.mode = Constants.JOINED_PRIVATE_TABLE;
        }
        if (gameDefData.status === Constants.GAME_STATUS_ACTIVE) {
          state.privateTable.lobbyData.activeTables[gameDefData.gameDefId] =
            gameDefData;
        } else {
          state.privateTable.lobbyData.completedTables[gameDefData.gameDefId] =
            gameDefData;
        }
      });
    },

    handleDynamicPrivateTableLobbyData: (state, action) => {
      const { gameDefinitions } = action.payload;

      gameDefinitions.forEach((gameDef) => {
        if (gameDef.isPrivateTable) {
          if (gameDef.status === Constants.GAME_STATUS_ACTIVE) {
            gameDef.mode = Constants.HOSTED_PRIVATE_TABLE;
            state.privateTable.lobbyData.activeTables[gameDef.gameDefId] = {
              ...gameDef,
            };
          } else {
            state.privateTable.lobbyData.completedTables[gameDef.gameDefId] = {
              ...gameDef,
            };
          }

          if (gameDef.hostName === state.player.userName) {
            state.privateTable.hostedTableCount++;
          }
        }
      });
    },

    handlePrivateTableStatusChange: (state, action) => {
      const { gameDefId, status } = action.payload;
      if (status === Constants.PRIVATE_TABLE_COMPLETED_STATUS) {
        let gameDefinition =
          state.privateTable.lobbyData.activeTables[gameDefId];

        delete state.privateTable.lobbyData.activeTables[gameDefId];

        state.privateTable.lobbyData.completedTables[gameDefId] =
          gameDefinition;
        state.privateTable.lobbyData.completedTables[gameDefId].status =
          Constants.PRIVATE_TABLE_COMPLETED_STATUS;
      } else if (status === Constants.PRIVATE_TABLE_EXPIRED_STATUS) {
        delete state.privateTable.lobbyData.activeTables[gameDefId];
        delete state.privateTable.lobbyData.completedTables[gameDefId];
      }
    },

    handleTemplateSaveStatus: (state, action) => {
      const { status } = action.payload;
      state.privateTable.isTemplateSaved =
        status === Constants.TEMPLATE_SAVED_SUCCESSFULLY;
    },

    handlePrivateTableGameDefInfo: (state, action) => {
      const { data } = action.payload;
      const {
        gameDefId,
        privateTableInfos,
        gameDefStatus,
        totalGamesPlayed,
        tableDuration,
      } = data;
      let updatedData = [];
      privateTableInfos.forEach((item) => {
        updatedData.push({
          Name: item.playerName,
          Buyin: item.buyInAmount,
          "Wins/Loss": item.profitLoss,
        });
      });

      const isActiveGameDef = gameDefStatus === Constants.GAME_STATUS_ACTIVE;

      let obj = {
        privateTableInfos: [...updatedData],
        totalGamesPlayed: totalGamesPlayed,
        tableDuration: tableDuration,
      };

      state.privateTable.lobbyData[
        isActiveGameDef ? "activeTables" : "completedTables"
      ][gameDefId] = {
        ...state.privateTable.lobbyData[
          isActiveGameDef ? "activeTables" : "completedTables"
        ][gameDefId],
        ...obj,
      };

      state.privateTable.isGameInfoRecieved = true;
    },

    handlePrivateTableGameDefInfoFailure: (state, action) => {
      state.privateTable.isGameInfoRecieved = false;
      state.toastMessages.push({
        type: Constants.TOAST_ERROR,
        message: "Failed to get info. Please try again.",
      });
    },

    resetPrivateTableGameDefInfo: (state, action) => {
      state.privateTable.isGameInfoRecieved = false;
    },

    handlePrivateTableGameDefPlayerCount: (state, action) => {
      const { data } = action.payload;
      data.forEach((item) => {
        if (
          state.privateTable.lobbyData.activeTables[item.gameDefId]?.status ===
          Constants.GAME_STATUS_ACTIVE
        ) {
          state.privateTable.lobbyData.activeTables[
            item.gameDefId
          ].onlinePlayerCount = item.playerCount;
        } else if (
          state.privateTable.lobbyData.completedTables[item.gameDefId]
        ) {
          state.privateTable.lobbyData.completedTables[
            item.gameDefId
          ].onlinePlayerCount = item.playerCount;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequestTourneyRegistration.fulfilled, (state, action) => {
        sendFaroLog({
          requestType: "TOURNEY_REGISTER_FULFILLED",
          responseData: action.payload.data,
        });
        const {
          status,
          tourneyId,
          tourneyPlayerCount,
          entryStatus,
          messageCode,
          lowBalanceData,
        } = action.payload.data;
        if (status === 200) {
          let tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);
          if (tourneyDetails) {
            tourneyDetails.tourneyPlayerStatus = Constants.TOURNEY_REGISTERED;
            // tourneyDetails.reminder = reminder
            tourneyDetails.tourneyIdentifier = "myTourneys";
            tourneyDetails.registeredPlayerCount = tourneyPlayerCount;
            tourneyDetails.isAlreadyRegisteredTourney = true;

            state.tourneys.masterTourneysData = new Map(
              state.tourneys.masterTourneysData
            ).set(tourneyId, tourneyDetails);
            if (
              tourneyDetails?.tourneyStatus === Constants.TOURNEY_STATUS_LIVE &&
              tourneyDetails?.lateRegistrationAllowed
            ) {
              state.showLateRegisterSuccessPopup = true;
            }
          }
        } else if (status === 800) {
          let tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);
          if (tourneyDetails) {
            tourneyDetails = {
              ...tourneyDetails,
              lowBalanceDetails: {
                amountToAdd: lowBalanceData?.amount_to_add,
                totalBalance: lowBalanceData?.total_balance,
                depositAndWinnings: lowBalanceData?.deposit_and_winning,
                usableBonus: lowBalanceData?.usable_bonus,
                buyInAmount: lowBalanceData?.buyInAmount,
                isTourneyGame: true,
              },
            };
            state.tourneys.masterTourneysData = new Map(
              state.tourneys.masterTourneysData
            ).set(tourneyId, tourneyDetails);
            if (lowBalanceData) {
              state.showTourneyInsufficientFundsModal = true;
            }
          }
          if (
            messageCode ===
              Constants.TOURNEY_REGISTRATION_FAILED_DUE_TO_FAIR_PLAY_POLICY ||
            messageCode ===
              Constants.TOURNEY_REGISTRATION_FAILED_DUE_TO_FAIR_PLAY_POLICY_TWO
          ) {
            state.showTourneyFairPlayPolicyRestrictionPopup = true;
          }
        }
        let toast = getTourneyRegisterFailureMessageByCode(messageCode);
        if (toast) {
          state.toastMessages.push(toast);
        }
        if (messageCode === Constants.TOURNEY_BLANKET_CHECKS_ARE_PASSED) {
          state.showTourneyAddCashTicketRegistrationPopup = true;
        }
        state.showTourneyRegisterOrDeregisterRequestLoading = false;
        state.tourneyTicketsClaimInProgressDetails[`${tourneyId}`] = false;
        state.showTourneyRegisterConfirmationPopup = false;

        const tpsPayload = {
          playerName: state.player.userName,
          tourneyId: tourneyId,
        };
        const tpsProtocol = "tps#" + JSON.stringify({ ...tpsPayload });
        LobbyConnection.getInstance().writeMessage(tpsProtocol);
      })
      .addCase(fetchRequestTourneyRegistration.rejected, (state, action) => {
        const { messageCode } = action.payload.data;
        sendFaroLog({
          requestType: "TOURNEY_REGISTER_REJECTED",
          responseData: action.payload.data,
        });
        let toast = getTourneyRegisterFailureMessageByCode(messageCode);
        if (toast) {
          state.toastMessages.push(toast);
        }
        state.showTourneyRegisterOrDeregisterRequestLoading = false;
        state.tourneyTicketsClaimInProgressDetails = {};
      })
      .addCase(fetchRequestTourneyDeRegistration.fulfilled, (state, action) => {
        sendFaroLog({
          requestType: "TOURNEY_DE_REGISTER_FULFILLED",
          responseData: action.payload.data,
        });
        const {
          status,
          tourneyId,
          tourneyPlayerCount,
          entryStatus,
          messageCode,
        } = action.payload.data;
        if (status === 200) {
          let tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);

          if (tourneyDetails) {
            tourneyDetails.tourneyPlayerStatus = "Deregistered";
            tourneyDetails.isAlreadyRegisteredTourney = false;
            // tourneyDetails.reminder = false;
            tourneyDetails.tourneyIdentifier = "upcomingTourneys";
            tourneyDetails.registeredPlayerCount = tourneyPlayerCount;

            state.tourneys.masterTourneysData = new Map(
              state.tourneys.masterTourneysData
            ).set(tourneyId, tourneyDetails);
          }
        }
        let toast = getTourneyRegisterFailureMessageByCode(messageCode);
        if (toast) {
          state.toastMessages.push(toast);
        }
        state.showTourneyRegisterConfirmationPopup = false;
        state.showTourneyRegisterOrDeregisterRequestLoading = false;
        state.tourneyTicketsClaimInProgressDetails[`${tourneyId}`] = false;
        // state.showTourneyInfoPage = false

        const tpsPayload = {
          playerName: state.player.userName,
          tourneyId: tourneyId,
        };
        const tpsProtocol = "tps#" + JSON.stringify({ ...tpsPayload });
        LobbyConnection.getInstance().writeMessage(tpsProtocol);
      })
      .addCase(fetchRequestTourneyDeRegistration.rejected, (state, action) => {
        const { messageCode } = action.payload.data;
        sendFaroLog({
          requestType: "TOURNEY_DE_REGISTER_REJECTED",
          responseData: action.payload.data,
        });
        let toast = getTourneyRegisterFailureMessageByCode(messageCode);
        if (toast) {
          state.toastMessages.push(toast);
        }
        state.showTourneyRegisterOrDeregisterRequestLoading = false;
        state.tourneyTicketsClaimInProgressDetails = {};
      })
      .addCase(fetchTourneyReEntry.fulfilled, (state, action) => {
        const {
          status,
          tourneyId,
          tourneyPlayerCount,
          entryStatus,
          messageCode,
          lowBalanceDetails,
        } = action.payload.data;
        sendFaroLog({
          requestType: "TOURNEY_RE_ENTRY_FULFILLED",
          responseDetails: action.payload.data,
        });
        if (status === 200) {
          let tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);
          if (tourneyDetails) {
            tourneyDetails.tourneyPlayerStatus = Constants.TOURNEY_REGISTERED;
            // tourneyDetails.reminder = reminder
            tourneyDetails.tourneyIdentifier = "myTourneys";
            tourneyDetails.registeredPlayerCount = tourneyPlayerCount;
            tourneyDetails.isAlreadyRegisteredTourney = true;
            if (tourneyDetails?.bustedOut) {
              tourneyDetails["bustedOut"] = false;
            }
            state.tourneys.masterTourneysData = new Map(
              state.tourneys.masterTourneysData
            ).set(tourneyId, tourneyDetails);
          }
        } else if (status === 800) {
          let tourneyDetails = state.tourneys.masterTourneysData.get(tourneyId);
          if (tourneyDetails) {
            tourneyDetails = {
              ...tourneyDetails,
              lowBalanceDetails: lowBalanceDetails,
            };
            state.tourneys.masterTourneysData = new Map(
              state.tourneys.masterTourneysData
            ).set(tourneyId, tourneyDetails);
            if (lowBalanceDetails) {
              state.showTourneyInsufficientFundsModal = true;
            }
          }
        }
        let toast = getTourneyRegisterFailureMessageByCode(messageCode);
        if (toast) {
          state.toastMessages.push(toast);
        }
        state.showTourneyReEntryConfirmationPopup = false;
        state.showTourneyReEntryRequestLoading = false;

        const tpsPayload = {
          playerName: state.player.userName,
          tourneyId: tourneyId,
        };
        const tpsProtocol = "tps#" + JSON.stringify({ ...tpsPayload });
        LobbyConnection.getInstance().writeMessage(tpsProtocol);
      })
      .addCase(fetchTourneyReEntry.rejected, (state, action) => {
        const { messageCode } = action.payload.data;
        sendFaroLog({
          requestType: "TOURNEY_RE_ENTRY_REJECTED",
          responseDetails: action.payload.data,
        });
        let toast = getTourneyRegisterFailureMessageByCode(messageCode);
        if (toast) {
          state.toastMessages.push(toast);
        }
        state.showTourneyReEntryRequestLoading = false;
      })
      .addCase(fetchPrivateTableCreationConfigs.fulfilled, (state, action) => {
        const { data } = action.payload;
        const { creationConfigs, defaultTableConfigs } = data;

        if (data.statusCode === 208 && defaultTableConfigs) {
          const {
            extraTimeBank,
            extraTimeMaxCap,
            extraTimeTopup,
            extraTimeTopupInterval,
            limitType,
          } = defaultTableConfigs;

          state.privateTable.configs.defaultConfig = {
            extraTimeBank: extraTimeBank,
            extraTimeMaxCap: extraTimeMaxCap,
            extraTimeTopup: extraTimeTopup,
            extraTimeTopupInterval: extraTimeTopupInterval,
            limitType: limitType,
          };
        } else if (data.statusCode === 207 && creationConfigs) {
          let blinds = [];
          let configBlinds = creationConfigs.blinds;
          configBlinds.forEach((blind) => {
            blinds.push({
              smallBlind: blind[0],
              bigBlind: blind[1],
            });
          });

          state.privateTable.configs = {
            ...state.privateTable.configs.defaultConfig,
            ...creationConfigs,
          };
          state.privateTable.configs.blinds = blinds;
        }
      })
      .addCase(fetchPrivateTableCreationConfigs.rejected, (state, action) => {
        state.toastMessages.push({
          type: Constants.TOAST_ERROR,
          message: "Something went wrong, please try again later.",
        });
        sendFaroLog({
          requestType: "FAILED_TO_FETCH_PRIVATE_TABLE_CREATION_CONFIGS",
          responseData: action.payload,
        });
      })
      .addCase(privateTableJoinRequest.fulfilled, (state, action) => {
        const { data } = action.payload;
        if (data?.isAllowed) {
          const { poolId, poolDetails } = data;
          processPrivateTableCreation(
            state,
            poolId,
            Constants.JOINED_PRIVATE_TABLE,
            poolDetails
          );
        } else {
          state.privateTable.isJoinTableResponseReceived = true;
          state.toastMessages.push({
            type: Constants.TOAST_WARNING,
            message: "Join Code is invalid. Please check and retry.",
          });
          sendFaroLog({
            requestType: "NOT_ALLOWED_TO_JOIN_PRIVATE_TABLE",
            responseData: data,
          });
        }
      })
      .addCase(privateTableJoinRequest.rejected, (state, action) => {
        state.privateTable.isJoinTableResponseReceived = true;
        state.toastMessages.push({
          type: Constants.TOAST_ERROR,
          message: "Something went wrong, please try again later.",
        });
        sendFaroLog({
          requestType: "FAILED_TO_JOIN_PRIVATE_TABLE",
          responseData: action.payload,
        });
      })
      .addCase(fetchPrivateTableTemplates.fulfilled, (state, action) => {
        const { data } = action.payload;
        if (
          data?.statusCode === Constants.TEMPLATE_FETCHED_SUCCESSFULLY &&
          data?.data.length > 0
        ) {
          state.privateTable.templateData = [...data.data];
          state.privateTable.isTemplateDeleted = false;
        }
      })
      .addCase(fetchPrivateTableTemplates.rejected, (state, action) => {
        state.toastMessages.push({
          type: Constants.TOAST_WARNING,
          message: "Something went wrong, please try again later.",
        });

        sendFaroLog({
          requestType: "FAILED_TO_FETCH_PRIVATE_TABLE_TEMPLATES",
          responseData: action.payload,
        });
      })
      .addCase(deletePrivateTableTemplate.fulfilled, (state, action) => {
        const { statusCode } = action.payload.data;
        if (statusCode === Constants.TEMPLATE_DELETED_SUCCESSFULLY) {
          state.privateTable.isTemplateDeleted = true;
          // because we are fetching the fresh template data again after deleting
          state.privateTable.templateData = [];
          state.toastMessages.push({
            type: Constants.TOAST_INFO,
            message: "Template deleted successfully!",
          });
        }
      })
      .addCase(deletePrivateTableTemplate.rejected, (state, action) => {
        state.toastMessages.push({
          type: Constants.TOAST_ERROR,
          message: "Failed to delete template. Please try again later",
        });
        sendLobbyDebugLog({
          type: "FAILED_TO_DELETE_PRIVATE_TABLE_TEMPLATES",
          error: action.payload,
        });
      });
  },
});

export const {
  initializeLobbyConnection,
  lobbyPlayData,
  lobbyRealData,
  setAccessToken,
  setPlayerDetails,
  setCleverTapEnabledFromNative,
  setChannel,
  setIsWebView,
  updatePlayerBalance,
  updateShowLobbyLoading,
  lobbyConAck,
  setShowGameTable,
  setShowPrivateTableLobby,
  updateLackGamesDetails,
  updateGameDefinitionsMasterdata,
  updateLackGamesShowStatus,
  sendLobbyDebugLog,
  updateLobbyHeartBeatDetails,
  lobbyUpdatedGameDefinition,
  upadatePlayersCount,
  handleTourneysLobbyData,
  requestTourneyInfoData,
  updateShowTourneyInfoPage,
  updateTourneyInfoData,
  closeTourneyInfoPage,
  handleTourneyRegisteredCountChange,
  handleTourneyStatusChange,
  updateShowTourneyRegisterConfirmationPopup,
  addLobbyToastMessage,
  removeLobbyToastMessage,
  updateTourneyPlayerStatusChange,
  updateShowTourneyRegisterOrDeregisterRequestLoading,
  handleTourneyStart,
  updateApplicationVisibility,
  updateShowTourneyInsufficientFundsModal,
  handleDynamicTourneyLobbyData,
  updateIsLackTourneyGamesDisplayed,
  handleTourneyPrize,
  updateRemindMeStatusToServer,
  handleTourneyComplete,
  handleRemoveDisplayedTourneyLackGame,
  requestTourneyEntriesDynamicData,
  handleTourneyRanksData,
  handleRemoveDisplayedCashLackGame,
  handleTourneyCancle,
  joinGameFromLeaderBoard,
  setIsPlayerJoiningFromLB,
  leaderBoardGamesList,
  requestTourneyTablesDynamicData,
  handleTourneyTablesData,
  updateGameActiveStatus,
  updateTourneySlabChange,
  updateCashAndFunGamesToggle,
  updatePlayerPlayChipsBalance,
  updateLackReceivedStatus,
  handlePlayChipsBalanceReload,
  requestFunChipsReload,
  setRakebackGameDefinitions,
  updateTourneyPrizePoolUpdate,
  requestCurrentFunChipsBalance,
  processFunChipsBalance,
  updateShowTourneyReEntryRequestLoading,
  updatePlayerRakebackLevel,
  updateTourneyLateRegistrationEnded,
  requestUpdatedTourneyPlayerStatus,
  showTourneyMaxTablesLimitReached,
  handleDynamicTourneysStartData,
  handleClearDynamicTourneyStartData,
  handleTourneyTicketsData,
  handleDeeplink,
  clearDeeplink,
  updateShowTourneyReEntryConfirmationPopup,
  setPlayerConsentDetails,
  setGameJoinCheckAck,
  updateTourneyTicketsClaimInProgress,
  updateHomePageData,
  handleTourneyPlayerAutoRegister,
  handleNewChildTourneyData,
  handleTourneyRemove,
  resetHomePageData,
  updateGridTiles,
  updateHomePageRecommendedData,
  handleTourneyDynamicCountsUpdate,
  removeRakebackToast,
  updateNotificationCount,
  updateConfigsPlayer,
  updateKycStatus,
  updateBottomBanners,
  updateTopBanners,
  updateShowTourneyFairPlayPolicyRestruction,
  updateShowLateRegisterSuccessPopup,
  updateResourceLoadFailure,
  updateTourneyRegistrationCTAButtonsHide,
  closeTourneyInfoPageOnlyIfLoading,
  updateDepositorTourneyDetails,
  updateShowTourneyAddCashTicketRegistrationPopup,
  updateTourneyInTheMoney,
  updateTourneyInBreak,
  updateShowAllTicketsSection,
  updateLobbyCurrentTab,
  updateTourneyPlayerBustedOut,
  openRakebackPage,
  updateDisplayName,
  sortLobbyGamesByHappyHours,
  updateHappyHoursTileSort,
  handleHandRankingTagLineData,
  updateMasterGameDefHandRankingTagLine,
  handleBlockedStatesDetails,
  updatePrivateTableHostingDetails,
  setPrivateTablePermissions,
  handlePrivateTableCreateGameDef,
  handlePrivateTableCreateGameDefFailure,
  resetInitiateMatchmakingForGdid,
  handleProcessPrivateTablesLobbyData,
  removePrivateTableGameDefFromRunningGame,
  handleDynamicPrivateTableLobbyData,
  handlePrivateTableStatusChange,
  handleTemplateSaveStatus,
  handlePrivateTableGameDefInfo,
  resetPrivateTableGameDefInfo,
  requestFlightTourneyStackDetails,
  requestFlightTourneyQualifiedPlayers,
  handleTourneyCompletedStackDetails,
  handleTourneyQualifiedPlayersDetails,
  updateSelectedFlightDetailsTabbar,
  handlePrivateTableGameDefPlayerCount,
  handlePrivateTableGameDefInfoFailure,
  updateNDUPromotionalBanners,
  updateComplianceToastMessage,
  removeComplianceToastMessage,
} = LobbySlice.actions;

export default LobbySlice.reducer;
