import Handler from "../Handler";

class HeartBeatLobbyHandler extends Handler {
  // g --- {"onlinePlayerCount":6,"currentTime":1740559531626}
  execute(data) {
    data = JSON.parse(data);
    this.service.processLobbyHeartBeat(data);
  }
}

export default HeartBeatLobbyHandler;
