import React, { memo, useEffect, useRef, useState, useCallback } from "react";
import { PotAmountChip } from "./FramerPotStyles";
import redChip from "../../../assets/gameTable/chips.png";
import blackChip from "../../../assets/gameTable/chip-black.png";
import blueChip from "../../../assets/gameTable/chip-blue.png";
import greenChip from "../../../assets/gameTable/chip-green.png";
import yellowChip from "../../../assets/gameTable/chip-yellow.png";

const FramerChipComponent = ({
  index,
  shouldAnimate,
  winner,
  winningPotData,
  chipIndex,
}) => {
  let allChipImages = [redChip, blueChip, yellowChip, greenChip, blackChip];
  let chipRef = useRef(null);
  const [chipLeftEndingCoordinate, setChipEndingLeftCoordinate] = useState(0);
  const [chipTopEndingCoordinate, setChipEndingTopCoordinate] = useState(0);

  const returnHorizontalPotTranslation = useCallback((player) => {
    if (chipRef?.current) {
      let chipLeftValue = chipRef?.current?.getBoundingClientRect().left;
      let playerCoordinateLeft =
        winningPotData?.playerCoordinates[player]?.left;
      return playerCoordinateLeft - chipLeftValue;
    }
    return 0;
  }, [winningPotData]);

  const returnVerticalPotTranslation = useCallback((player) => {
    if (chipRef?.current) {
      let chipTopValue = chipRef?.current?.getBoundingClientRect().top;
      let playerCoordinateTop = winningPotData?.playerCoordinates[player]?.top;
      return playerCoordinateTop - chipTopValue;
    }
    return 0;
  }, [winningPotData]);

  useEffect(() => {
    if (shouldAnimate) {
      setChipEndingLeftCoordinate(returnHorizontalPotTranslation(winner));
      setChipEndingTopCoordinate(returnVerticalPotTranslation(winner));
    } else {
      setChipEndingLeftCoordinate(0);
      setChipEndingTopCoordinate(0);
    }
  }, [shouldAnimate, returnHorizontalPotTranslation, returnVerticalPotTranslation, winner]);

  const chipAnimationSequence = [
    {
      left: chipLeftEndingCoordinate,
      top: chipTopEndingCoordinate,
      position: "absolute",
      transition: {
        delay: 0.1 * chipIndex,
      },
    },
    {
      opacity: 0,
      transition: {
        delay: 0.15 * chipIndex,
      },
    },
  ];

  return (
    <PotAmountChip
      ref={chipRef}
      src={allChipImages[index]}
      initial={{
        opacity: 1,
        scale: 1,
      }}
      animate={shouldAnimate ? chipAnimationSequence : null}
    />
  );
};

export default memo(FramerChipComponent);
