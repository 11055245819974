import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";
import {
  Typo10GrayContoRegularText,
  Typo10SecondaryRedContoMediumText,
  Typo11DoveGrayContoMediumText,
  Typo11OffWhiteContoBoldText,
  Typo11PebbleGrayContoBoldText,
  Typo11TourneyBonusBarFilledContoBoldText,
  Typo12DoveGrayContoBoldText,
  Typo12GrayContoRegularText,
  Typo12PrimaryGoldContoBoldText,
  Typo12PureWhiteContoBoldText,
  Typo12WhiteContoMediumText,
  Typo14PureWhiteContoBoldText,
  Typo14WhiteContoBoldText,
  Typo16WhiteContoBoldText,
} from "../../../Common/styleGuide/Typos";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
export const CarousalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  width: 100%;
  margin: auto;
  height: "fit-content";
  background-color: inherit;
  position: relative;
  /* background-color: orange; */
  .swiper-pagination {
    bottom: 2px;
  }
  .swiper-pagination-bullet {
    width: 4px;
    height: 4px;
    background-color: ${colors.pebbleGray};
    opacity: 0.6;
  }
  .swiper-pagination-bullet-active {
    width: 12px;
    height: 4px;
    border-radius: 8px;
    background-color: ${colors.lightYellow};
    opacity: 1;
  }
`;

export const BannerImageWrapper = styled.img`
  width: 100%;
  height: auto;
  /* border-radius: 5px; */
`;

export const LobbyChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #061a1f;

  height: 100%;
  align-items: center;
`;

export const LobbyDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 6px);
  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    touch-action: pan-y;
  }
`;

export const LobbyDetailsHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid ${colors.tempNavy2};
  box-sizing: border-box;
  background: rgb(3, 32, 40);
`;

export const LobbyDetailsTitleAndCashFunToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 36px;
  padding: 17px 12px 0px 12px;
  justify-content: space-between;
  box-sizing: border-box;
`;

export const GamesTabbarWrapper = styled.div`
  display: flex;
  width: 100%;
  /* padding: 12px; */
  padding-bottom: 0px;

  box-sizing: border-box;
  background: "linear-gradient(0deg, #075063, #075063), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))";

  .tab-item {
    min-width: 25%;
    height: 43px;
    min-height: 43px;
  }
`;

export const TabbarWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 43px;
  min-height: 43px;
  background: "linear-gradient(0deg, #075063, #075063), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))";
`;

export const LobbyBodyTitleText = styled(Typo14WhiteContoBoldText)`
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
`;

export const CashFunToggleSwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  align-items: center;
`;

export const FunAndCashToggleSwitch = styled.img`
  height: 20px;
  width: 36px;
  margin-left: 4px;
  margin-right: 4px;
`;

export const ToggleSwitchText = styled(Typo12GrayContoRegularText)`
  line-height: 16px;
  color: ${(props) => (props.isActive ? colors.lightYellow : colors.gray)};
`;

export const GameTilesListSwiperContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  overflow: scroll;
  scroll-snap-type: x mandatory;

  height: 100%;
  ::-webkit-scrollbar {
    display: none;

    width: 0;
    background: transparent;
  }
`;

export const GameTileSwiperSlideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: var(--app-width);
  height: 100%;
  scroll-snap-align: center;
  scroll-snap-stop: always; //to make it move only one tab at a time
`;

export const MoreSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--app-height);
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
  background-color: ${colors.black60};
  animation: fade-in forwards 0.25s;
`;

export const MoreSectionMenuItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 144px;
  min-height: 160px;
  border-radius: 8px;
  background-color: ${colors.primaryBlue};
  position: absolute;
  bottom: 76px;
  right: 12px;
  z-index: 101;
  padding: 0px 12px 0px 12px;
  div:last-child {
    border-bottom: none;
  }
  border: 1px solid ${colors.navy2};
`;

export const MoreSectionMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${colors.navy2};
`;

export const MoreSectionMenuIcon = styled.img`
  height: 16px;
  width: 16px;
  padding: 12px 0px 12px 0px;
`;

export const MoreSectionMenuItemText = styled(Typo12WhiteContoMediumText)`
  margin-left: 8px;
`;

export const LoadingViewContainer = styled.div`
  width: 95%;
  margin: 6px auto;
  height: 70%;
`;

export const TourneyLobbySkeletonLoadingContainer = styled.div`
  width: 95%;
  margin: auto;
  height: 100%;
`;

export const TourneysContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    touch-action: pan-y;
  }
`;

export const PromotionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const TourneysHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  background: rgb(3, 32, 40);
`;
export const PromotionHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  color: white;
  background: rgb(3, 32, 40);
`;

export const PromotionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

export const PromotionZeroTextDisplay = styled(Typo12GrayContoRegularText)`
  color: #bebab9;
  margin: auto;
`;

export const PromotionsBanner = styled.img`
  margin: 2% 3%;
  border-radius: 6px;
`;

export const TourneyTilesListSwiperContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  overflow: scroll;
  scroll-snap-type: x mandatory;

  height: 100%;
  ::-webkit-scrollbar {
    display: none;

    width: 0;
    background: transparent;
  }
`;

export const TourneysGameTilesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 14px 0px 14px 0px;
  box-sizing: border-box;
  overflow: scroll;
  padding-bottom: 10px;
  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    touch-action: pan-y;
    overflow-y: scroll;
  }
`;
export const ActiveGamesStickyContainer = styled.div`
  position: fixed;
  right: 0;
  top: 80%;
  z-index: 12;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.primaryDeepBlue};
  border-radius: 25px 0px 0px 25px;
  box-shadow: 0px 0px 5px #eabf69;
  border-top: 1px solid #eabf69;
  border-left: 1px solid #eabf69;
  border-bottom: 1px solid #eabf69;
  overflow: hidden;
  /* transform: ${(props) =>
    props.tileScrollStatus ? "translateX(110%)" : null}; */
  opacity: ${(props) => (props.tileScrollStatus ? 0.25 : 1)};
  transition: 0.25s ease-out;
  animation: fade-in 1s forwards;
`;

export const ShimmerLightingContainer = styled.div`
  position: absolute;
  left: -25%;
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: shimmer-animation 2s infinite;

  @keyframes shimmer-animation {
    0% {
      left: -25%;
    }
    100% {
      left: 125%;
    }
  }
`;

export const NumberOfTablesContainer = styled.div`
  /* position: absolute; */
  /* top: 50%; */
  /* left: 0; */
  /* transform: translate(-50%, -50%); */
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  /* background: ${colors.primaryDeepBlue}; */
  background-color: #eabf69;
  border: 1px solid #eabf69;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;
  margin: 0px 7px;
`;

export const RippleCircle = styled.div`
  position: absolute;
  background-color: #eabf69;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  z-index: 1;
  animation: active-table-ripple 1s infinite;

  @keyframes active-table-ripple {
    from {
      opacity: 1;
      transform: scale3d(0.75, 0.75, 1);
    }
    to {
      opacity: 0;
      transform: scale3d(2, 2, 1);
    }
  }
`;

export const NumberOfTablesValue = styled.span`
  font-family: "Conto";
  font-weight: bold;
  color: black;
  font-size: 10px;
  z-index: 2;
`;

export const ShimmerThin = styled.div`
  width: 2px;
  height: calc(100% + 25px);
  background-color: #fbfbfb17;
  transform: rotate(15deg);
`;

export const ShimmerFat = styled.div`
  margin-left: 2px;
  width: 10px;
  height: calc(100% + 25px);
  background-color: #fbfbfb17;
  transform: rotate(15deg);
`;

export const ActiveGamesText = styled.span`
  color: white;
  font-family: "Conto";
  font-weight: 550;
  font-size: 12px;
  margin: 12px 0px;
  z-index: 7;
`;

export const ActiveTablesIcon = styled.img`
  width: 20px;
  height: auto;
  margin: 0px 7.5px 0px 7.5px;
  z-index: 7;
`;

export const ActiveTablesArrow = styled.img`
  width: 12px;
  height: auto;
  transform: rotate(90deg);
  margin: 0px 7.5px;
  z-index: 7;
`;

export const TourneyTabsContainer = styled.div`
  display: flex;
  width: 100%;

  /* border: 1px solid yellow; */
  justify-content: space-between;
  align-content: center;
  padding: 12px 0px 0px 12px;
  /* background: "linear-gradient(0deg, #075063, #075063), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))"; */
  background: rgb(3, 32, 40);
  margin-bottom: 8px;
  .tab-item-container {
    height: 32px !important;
    min-height: 32px !important;
  }
  .default-tab-item {
    height: 32px !important;
    min-height: 32px !important;
  }
`;

export const TourneyTabItemWrapper = styled.div`
  display: flex;
  width: 33.33%;
  justify-content: center;
  align-items: center;
`;

export const TourneyTicketsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.isTicketsAvailable ? "50px" : "28px")};
  max-width: auto;
  height: 28px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background: #1f4048;
  border-width: 1px, 0px, 1px, 1px;

  border-style: solid;

  border-color: #336070;
  border-right: none;
  position: relative;
  overflow: hidden;

  box-shadow: ${(props) =>
    props.isTicketsAvailable ? "0px 0px 5px #eabf69;" : "none"};
  border-top: ${(props) =>
    props.isTicketsAvailable ? "1px solid #eabf69" : "inherit"};
  border-left: ${(props) =>
    props.isTicketsAvailable ? "1px solid #eabf69" : "inherit"};
  border-bottom: ${(props) =>
    props.isTicketsAvailable ? "1px solid #eabf69" : "inherit"};
`;

export const TourneyTabItemText = styled(Typo12DoveGrayContoBoldText)`
  color: ${(props) =>
    props.isSelected ? colors.primaryGold : colors.doveGray};
`;

export const GoldArrowIconWrapper = styled.img`
  width: 12px;
  height: 10px;
  margin-right: 4px;
  opacity: ${(props) => (props.isSelected ? 1 : 0)};
`;

export const TicketsIconWrapper = styled.img`
  width: 14px;
  height: 14px;
`;

export const TicketsCountText = styled(Typo12PrimaryGoldContoBoldText)`
  margin-left: 4px;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: var(--app-width);
  height: var(--app-height);
  position: absolute;

  bottom: 0px;
  z-index: 12;
  background: ${colors.black60};
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
`;

export const TicketsModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 320px;
  overflow-y: scroll;
  background: ${colors.tourneyInfoBackground};
  border-radius: 12px 12px 0px 0px;
  /* animation: expansion 0.25s linear;

  @keyframes expansion {
    from {
      min-height: 0px;
    }
    to {
      min-height: 320px;
    }
  } */

  animation: ${(props) =>
    props.isOpen
      ? "themes-move-up forwards 0.25s"
      : "themes-move-down forwards 0.25s"};

  @keyframes themes-move-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes themes-move-down {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }
`;

export const TicketsHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 46px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, #075063, #075063),
    linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  padding: 16px 24px;
  box-sizing: border-box;
`;

export const TicketsHeaderText = styled(Typo16WhiteContoBoldText)`
  line-height: normal;
  margin: 0 auto;
`;

export const CloseIconWrapper = styled.img``;

export const TicketsBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 12px 16px 24px 16px;
  box-sizing: border-box;
  overflow-y: scroll;
  background: #011014;
`;

export const NoTicketAvailableText = styled.span`
  font-size: 12px;
  color: #fff;
  text-align: center;
  margin: auto auto;
`;

export const TicketsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90px;
  /* background: linear-gradient(108.91deg, #075063 0%, #032028 162.87%); */
  background-image: ${(props) =>
    props.bgImage ? `url(${props.bgImage})` : ""};
  background-repeat: round;
  background-size: cover;
  margin-bottom: 12px;
`;

export const TicketTourneyDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
`;

export const TourneyDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

export const TicketStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  padding-left: 10%;
  box-sizing: border-box;
`;

export const TourneyNameText = styled(Typo14PureWhiteContoBoldText)``;

export const DividerImg = styled.img`
  width: 85%;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const TicketDetailLabelText = styled(Typo10GrayContoRegularText)``;

export const TicketDetailValueText = styled(Typo12PureWhiteContoBoldText)``;

export const TourneyPrizeAndTicketDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const TourneyPrizeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const TourneyTicketValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 12px;
  box-sizing: border-box;
`;

export const TicketVerticalLine = styled.img``;

export const TicketDashedDividerLine = styled.img``;

export const ExpiryText = styled(Typo10SecondaryRedContoMediumText)`
  margin-top: 2px;
  margin-bottom: 12px;
`;

export const ClaimButtonWrapper = styled.div`
  display: flex;
  width: 89px;
  height: 27px;
  padding: 6px 12px 6px 12px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 2px solid ${colors.tourneyBonusBarFilled};
  justify-content: center;
  align-items: center;
`;

export const ClaimText = styled(Typo11TourneyBonusBarFilledContoBoldText)``;

export const RegisrationsFullText = styled(Typo11DoveGrayContoMediumText)``;

export const ClaimedWrapper = styled.div`
  display: flex;
  width: 89px;
  height: 27px;
  padding: 0px 12px 6px 12px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
`;

export const DoneIconText = styled.img`
  height: 10px;
  width: 10px;
  margin-right: 4px;
`;

export const ExpiredText = styled(Typo11PebbleGrayContoBoldText)`
  display: flex;
  width: 89px;
  height: 27px;
  padding: 0px 12px 6px 12px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
`;
