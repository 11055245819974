import { createSlice } from "@reduxjs/toolkit";
import LobbyConnection from "../../io/LobbyConnection";
import { CLIENT_OFFLINE_LOGS } from "../../data/Constants";

const initialState = {
  logs: JSON.parse(localStorage.getItem(CLIENT_OFFLINE_LOGS)) || [], // Load logs from local storage
  isOnline: navigator.onLine, // Track online/offline status
};

// Helper function to send log through WebSocket
const sendLogViaWebSocket = (log) => {
  if (LobbyConnection.getInstance()) {
    let mltProtocol = "cmlt#" + JSON.stringify({ ...log });
    LobbyConnection.getInstance().writeMessage(mltProtocol);
  }
};

// Helper function to update local storage
const updateLocalStorageLogs = (logs) => {
  localStorage.setItem(CLIENT_OFFLINE_LOGS, JSON.stringify(logs));
};

const clientLogsSlice = createSlice({
  name: "clientLogs",
  initialState,
  reducers: {
    // Add a new log
    addClientLog: (state, action) => {
      const log = action.payload;
      if (state.isOnline) {
        // If online, send the log immediately
        sendLogViaWebSocket(log);
      } else {
        // If offline, store the log in state and local storage
        state.logs.push(log);
        updateLocalStorageLogs(state.logs); // Persist to local storage
      }
    },

    // Set online status
    setClientLogsOnlineStatus: (state, action) => {
      state.isOnline = action.payload;

      if (state.isOnline) {
        // If coming back online, send all stored logs
        state.logs.forEach((log) => {
          sendLogViaWebSocket(log);
        });

        // Clear logs after sending
        state.logs = [];
        updateLocalStorageLogs(state.logs); // Clear local storage
      }
    },
  },
});

export const { addClientLog, setClientLogsOnlineStatus } =
  clientLogsSlice.actions;
export default clientLogsSlice.reducer;
