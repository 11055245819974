import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GAME_STAGE_PRE_FLOP,
  OMAHA,
  OMAHA5,
  OMAHA6,
  PORTRAIT,
  TEXAS_HOLDEM,
} from "../../../Poker/data/Constants";
import raiseThumb from "../../../assets/gameTable/RaiseThumb.svg";
import raiseEditIcon from "../../../assets/gameTable/editIcon.png";
import {
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  parseDecimalValuesOfNumber,
} from "../../utils/GameUtils";
import {
  Container,
  RaisePresetsAndSliderContainer,
  RaisePresetsWrapper,
  RaiseSliderWrapper,
  RaisePresetButton,
  PresetButtonText,
  RaiseSlider,
  RaiseActionInputContainer,
  RaiseCustomInput,
  RaiseEditIcon,
  RaiseInputTooltip,
  RaiseDisplayValue,
} from "./RaiseActionStyles";
import {
  disableMultitableSwipe,
  enableMultitableSwipe,
} from "../../../Poker/redux/slices/gameTableSlice";

const RAISE_PRESET_ALL_IN = "All In";
const RAISE_2X_POT = "2x Pot";

function RaiseAction(props) {
  const { tempTableId } = props;
  const [raisePresets, setRaisePresets] = useState([]);
  const tableOrientation = useSelector(
    (state) => state.gameTable.tableOrientation
  );
  const mySeat = useSelector(
    (state) => state.gameTable.games[tempTableId]?.playerSeat
  );
  const gameStage = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameStage
  );
  const currentUserData = useSelector((state) =>
    state.gameTable.games[tempTableId].playerData.find(
      (player) => player.position === mySeat
    )
  );
  const defaultBigBlindAmount = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameDefinition?.bigBlind
  );
  const maximumRaiseValue = useSelector(
    (state) => state.gameTable.games[tempTableId]?.maxRaiseAmount
  );
  // currentUserData?.atTableAmount + currentUserData?.betAmount;
  const currentRunningBetAmount = useSelector(
    (state) => state.gameTable.games[tempTableId]?.callAmount
  );
  const currentRaiseAmount = currentUserData?.currentRaiseAmount;
  // currentUserData.currentRaiseAmount +
  const minimumRaiseValue = useSelector(
    (state) => state.gameTable.games[tempTableId]?.minRaiseAmount
  );
  const currentGameType = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameDefinition?.gameType
  );
  const bigBlindValue = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameDefinition.bigBlind
  );
  const BBDisplay = useSelector(
    (state) => state.gameTable.tableSettings.BBDisplay
  );
  const bettingOptions = useSelector(
    (state) => state.gameTable.tableSettings.bettingOptions
  );
  const timeoutRef = useRef(null);
  const customInputRef = useRef(null);

  const [showCustomInputTooltip, setShowCustomInputTooltip] = useState(false);

  const dispatch = useDispatch();
  // currentRunningBetAmount === 0
  //   ? 2 * defaultBigBlindAmount
  //   : currentRunningBetAmount + currentRaiseAmount;
  const currentTableTotalPotAmount = useSelector(
    (state) => state.gameTable.games[tempTableId].totalPotAmt
  );
  // const sliderStepValue = (maximumRaiseValue - minimumRaiseValue) / 100;
  // Adding a minimum value dependent on either small blind or 1 rupee. Cannot be in minor decimals.
  // const sliderStepValue = Math.min(1, defaultBigBlindAmount / 2);
  // Removed the top code for now. That is causing a bug.

  // const sliderStepValue = (maximumRaiseValue - minimumRaiseValue) / 100;

  //NOTE: this is the latest slider step value as per product suggestion.
  // const sliderStepValue = bigBlindValue;

  // const sliderStepValue = 1;

  const [raiseValue, setRaiseValue] = useState(minimumRaiseValue);
  const [customRaiseValue, setCustomRaiseValue] = useState(minimumRaiseValue);

  useEffect(() => {
    updateRaisePresets();
    return () => {
      if (timeoutRef && timeoutRef?.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [bettingOptions]);

  useEffect(() => {
    if (BBDisplay) {
      setCustomRaiseValue(raiseValue / bigBlindValue);
    } else {
      setCustomRaiseValue(raiseValue);
    }
  }, [BBDisplay]);

  const updateRaisePresets = () => {
    // Extract user preferences
    const {
      defaultPreflopPresetValue,
      defaultPostflopPresetValue,
      preflopPresetValues,
      postflopPresetValues,
    } = bettingOptions;

    let defaultRaiseValue = minimumRaiseValue;

    let maxLimit =
      currentGameType === OMAHA ||
      currentGameType === OMAHA5 ||
      currentGameType === OMAHA6
        ? maximumRaiseValue
        : currentUserData?.atTableAmount;

    let raisePresetsValues = [];

    if (gameStage === GAME_STAGE_PRE_FLOP) {
      raisePresetsValues = Object.entries(preflopPresetValues)
        .map(([key, value]) => ({
          label: `${value}x BB`,
          value: defaultBigBlindAmount * value,
          isEnabled:
            maxLimit >= defaultBigBlindAmount * value &&
            defaultBigBlindAmount * value >= minimumRaiseValue &&
            maxLimit > defaultBigBlindAmount,
        }))
        .filter(
          (preset, index, self) =>
            index === self.findIndex((p) => p.value === preset.value) // Filter out duplicate values
        )
        .sort((a, b) => b.value - a.value); // Sort by value in descending order

      // Add the 'All In' option manually
      raisePresetsValues.unshift({
        label: RAISE_PRESET_ALL_IN,
        value: currentUserData?.atTableAmount + currentUserData?.betAmount,
        isEnabled: true,
      });

      let defaultValue = defaultPreflopPresetValue * bigBlindValue;

      // New condition for OMAHA, OMAHA5, OMAHA6
      if (
        (currentGameType === OMAHA ||
          currentGameType === OMAHA5 ||
          currentGameType === OMAHA6) &&
        defaultPreflopPresetValue > maximumRaiseValue
      ) {
        defaultRaiseValue = maximumRaiseValue;
      } else if (
        defaultValue > minimumRaiseValue &&
        defaultValue <= maximumRaiseValue
      ) {
        defaultRaiseValue = defaultValue;
      }
    } else if (gameStage !== "") {
      raisePresetsValues = Object.entries(postflopPresetValues)
        .map(([key, value]) => ({
          label: `${value}x Pot`,
          value: currentTableTotalPotAmount * value,
          isEnabled:
            maxLimit >= currentTableTotalPotAmount * value &&
            currentTableTotalPotAmount * value >= minimumRaiseValue &&
            currentTableTotalPotAmount * value >= defaultBigBlindAmount,
        }))
        .filter(
          (preset, index, self) =>
            index === self.findIndex((p) => p.value === preset.value) // Filter out duplicate values
        )
        .sort((a, b) => b.value - a.value); // Sort by value in descending order

      // Add the 'All In' option manually
      raisePresetsValues.unshift({
        label: RAISE_PRESET_ALL_IN,
        value: maxLimit + currentUserData?.betAmount,
        isEnabled: true,
      });

      let defaultValue = defaultPostflopPresetValue * bigBlindValue;

      // Validate default raise value
      if (
        defaultValue > minimumRaiseValue &&
        defaultValue <= maximumRaiseValue
      ) {
        defaultRaiseValue = defaultValue;
      }
    }

    // Ensure defaultRaiseValue is valid
    if (
      defaultRaiseValue < minimumRaiseValue ||
      defaultRaiseValue > maximumRaiseValue
    ) {
      defaultRaiseValue = minimumRaiseValue;
    }

    let raiseActionBetValue = parseDecimalValuesOfNumber(minimumRaiseValue);

    if (minimumRaiseValue >= maximumRaiseValue) {
      defaultRaiseValue = Number(parseDecimalValuesOfNumber(maximumRaiseValue));
      raiseActionBetValue = parseDecimalValuesOfNumber(
        Number(parseDecimalValuesOfNumber(maximumRaiseValue))
      );
    }

    if (raiseActionBetValue) {
      props.handleRaiseActionBet(raiseActionBetValue);
    }
    if (defaultRaiseValue) {
      setRaiseValue(defaultRaiseValue);
      props.handleRaiseActionBet(defaultRaiseValue);
    }

    setRaisePresets(raisePresetsValues); // Set the filtered and sorted presets
  };

  const handleSliderChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log("Yashwanth logging event.target.value: ", event.target.value);
    const value = Number(
      parseDecimalValuesOfNumber(Number(event.target.value))
    );
    setRaiseValue(value);
    setCustomRaiseValue(BBDisplay ? value / bigBlindValue : value);
    props.handleRaiseActionBet(value);
  };

  const handlePresetSelection = (value) => {
    const formattedValue = Number(parseDecimalValuesOfNumber(value));
    props.handleRaiseActionBet(formattedValue);
    setRaiseValue(formattedValue);
    setCustomRaiseValue(
      BBDisplay ? formattedValue / bigBlindValue : formattedValue
    );
  };

  const handleCustomRaiseValueChange = (amount) => {
    let value = amount.replace(/^0+/, "");
    const formattedValue = Number(parseDecimalValuesOfNumber(value));
    setShowCustomInputTooltip(
      formattedValue < minimumRaiseValue || formattedValue > maximumRaiseValue
    );
    setCustomRaiseValue(amount);
  };

  const handleValidateCustomRaiseValue = (customAmount) => {
    let selectedAmount = parseDecimalValuesOfNumber(customAmount);
    if (
      selectedAmount >= minimumRaiseValue &&
      selectedAmount <= maximumRaiseValue
    ) {
      props.handleRaiseActionBet(selectedAmount);
      setRaiseValue(selectedAmount);
      setShowCustomInputTooltip(false);
    } else if (selectedAmount < minimumRaiseValue) {
      props.handleRaiseActionBet(minimumRaiseValue);
      setRaiseValue(minimumRaiseValue);
      setCustomRaiseValue(minimumRaiseValue);
      setShowCustomInputTooltip(false);
    } else if (selectedAmount > maximumRaiseValue) {
      props.handleRaiseActionBet(maximumRaiseValue);
      setRaiseValue(maximumRaiseValue);
      setCustomRaiseValue(maximumRaiseValue);
      setShowCustomInputTooltip(false);
    }
    timeoutRef.current = setTimeout(() => {
      props.handleIsKeyboardOpened && props.handleIsKeyboardOpened(false);
    }, 100);
  };

  const handleCustomInputOnFocus = () => {
    props.handleIsKeyboardOpened(true);
    if (customInputRef && customInputRef?.current) {
      customInputRef?.current?.focus();
    }
    setCustomRaiseValue(BBDisplay ? raiseValue / bigBlindValue : raiseValue);
  };

  const renderRaisePresetButton = (preset) => {
    return (
      <RaisePresetButton
        key={preset.label}
        onTouchStart={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handlePresetSelection(preset.value);
        }}
        isSelected={raiseValue === preset.value ? true : false}
        isDisabled={!preset.isEnabled}
      >
        <PresetButtonText>{preset.label}</PresetButtonText>
      </RaisePresetButton>
    );
  };
  const handleKeypadEnterPress = (event) => {
    if (event.which === 13) {
      event.preventDefault();
      event.target.blur();
    }
  };

  return (
    <Container isPortraitMode={tableOrientation === PORTRAIT}>
      <RaiseActionInputContainer onClick={handleCustomInputOnFocus}>
        {showCustomInputTooltip && (
          <RaiseInputTooltip>
            {customRaiseValue < minimumRaiseValue
              ? `Minimum Raise = ${
                  BBDisplay
                    ? parseDecimalValuesOfNumber(
                        minimumRaiseValue / bigBlindValue,
                        2
                      ) + " BB"
                    : minimumRaiseValue
                }`
              : `Maximum Raise = ${
                  BBDisplay
                    ? parseDecimalValuesOfNumber(
                        maximumRaiseValue / bigBlindValue,
                        2
                      ) + " BB"
                    : maximumRaiseValue
                }`}
          </RaiseInputTooltip>
        )}
        {BBDisplay ? (
          <RaiseCustomInput
            ref={customInputRef}
            type={"number"}
            inputMode={"decimal"}
            step={".01"}
            value={`${customRaiseValue}`}
            isKeyboardOpened={props.isRaiseActionKeyboardOpened}
            onChange={(e) => handleCustomRaiseValueChange(e.target.value)}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            min={parseDecimalValuesOfNumber(
              minimumRaiseValue / bigBlindValue,
              2
            )}
            max={parseDecimalValuesOfNumber(
              maximumRaiseValue / bigBlindValue,
              2
            )}
            onFocus={() => {
              handleCustomInputOnFocus();
            }}
            onBlur={() => {
              handleValidateCustomRaiseValue(customRaiseValue * bigBlindValue);
            }}
            onKeyDown={handleKeypadEnterPress}
          />
        ) : (
          <RaiseCustomInput
            ref={customInputRef}
            type={"number"}
            inputMode={"decimal"}
            step={".01"}
            value={`${customRaiseValue}`}
            isKeyboardOpened={props.isRaiseActionKeyboardOpened}
            onChange={(e) => handleCustomRaiseValueChange(e.target.value)}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            min={minimumRaiseValue}
            max={maximumRaiseValue}
            onFocus={() => {
              handleCustomInputOnFocus();
            }}
            onBlur={() => {
              handleValidateCustomRaiseValue(customRaiseValue);
            }}
            onKeyDown={handleKeypadEnterPress}
          />
        )}
        <RaiseDisplayValue isKeyboardOpened={props.isRaiseActionKeyboardOpened}>
          {BBDisplay
            ? parseDecimalValuesOfNumber(raiseValue / bigBlindValue, 1) + " BB"
            : parseDecimalValuesOfNumber(
                raiseValue,
                2,
                checkToDisplayValueInK(raiseValue),
                checkToDisplayValueInL(raiseValue)
              )}
        </RaiseDisplayValue>
        <RaiseEditIcon src={raiseEditIcon} />
      </RaiseActionInputContainer>
      <RaisePresetsAndSliderContainer>
        <RaisePresetsWrapper>
          {raisePresets.map((eachPreset) => {
            if (currentGameType === TEXAS_HOLDEM) {
              return renderRaisePresetButton(eachPreset);
            } else if (
              (currentGameType === OMAHA ||
                currentGameType === OMAHA5 ||
                currentGameType === OMAHA6) &&
              eachPreset.label !== RAISE_2X_POT
            ) {
              if (
                eachPreset.label === RAISE_PRESET_ALL_IN &&
                currentUserData?.atTableAmount < currentTableTotalPotAmount
              ) {
                return renderRaisePresetButton(eachPreset);
              } else if (eachPreset.label !== RAISE_PRESET_ALL_IN) {
                return renderRaisePresetButton(eachPreset);
              }
            }
          })}
        </RaisePresetsWrapper>
        {/* <RaiseSliderAndSliderMarkingsContainer></RaiseSliderAndSliderMarkingsContainer> */}
        <RaiseSliderWrapper
          sliderValue={
            ((parseDecimalValuesOfNumber(raiseValue, 2) - minimumRaiseValue) /
              (maximumRaiseValue - minimumRaiseValue)) *
            100
          }
          raiseThumb={raiseThumb}
          onTouchStart={() => dispatch(disableMultitableSwipe())}
          onTouchEnd={() => dispatch(enableMultitableSwipe())}
        >
          {/* <RaiseSliderFloatingSelectedValueContainer>
            <RaiseValueText>
              {console.log(
                "raiseValue PART ONE:",
                raiseValue,
                " type:",
                typeof raiseValue
              )}
              {console.log(
                "raiseValue PART TWO:",
                parseDecimalValuesOfNumber(
                  raiseValue,
                  2,
                  checkToDisplayValueInK(raiseValue),
                  checkToDisplayValueInL(raiseValue)
                ),
                " type:",
                typeof parseDecimalValuesOfNumber(
                  raiseValue,
                  2,
                  checkToDisplayValueInK(raiseValue),
                  checkToDisplayValueInL(raiseValue)
                )
              )}
              {console.log(
                "raiseValue PART THREE converting to number:",
                Number(
                  parseDecimalValuesOfNumber(
                    raiseValue,
                    2,
                    checkToDisplayValueInK(raiseValue),
                    checkToDisplayValueInL(raiseValue)
                  )
                ),
                " type:",
                Number(
                  parseDecimalValuesOfNumber(
                    raiseValue,
                    2,
                    checkToDisplayValueInK(raiseValue),
                    checkToDisplayValueInL(raiseValue)
                  )
                )
              )}
              {BBDisplay
                ? parseDecimalValuesOfNumber(raiseValue / bigBlindValue, 1) +
                  " BB"
                : // Number(
                  //       parseDecimalValuesOfNumber(
                  //         raiseValue,
                  //         2,
                  //         checkToDisplayValueInK(raiseValue),
                  //         checkToDisplayValueInL(raiseValue)
                  //       )
                  //     )
                  parseDecimalValuesOfNumber(
                    raiseValue,
                    2,
                    checkToDisplayValueInK(raiseValue),
                    checkToDisplayValueInL(raiseValue)
                  )}
            </RaiseValueText>
          </RaiseSliderFloatingSelectedValueContainer> */}
          <RaiseSlider
            type={"range"}
            step={"0.01"}
            value={parseDecimalValuesOfNumber(raiseValue, 2)}
            min={
              minimumRaiseValue < maximumRaiseValue
                ? minimumRaiseValue
                : maximumRaiseValue - 1
            }
            max={maximumRaiseValue}
            onChange={handleSliderChange}
            disabled={
              minimumRaiseValue > maximumRaiseValue ||
              props.isRaiseActionKeyboardOpened
            }
          />
        </RaiseSliderWrapper>
      </RaisePresetsAndSliderContainer>
    </Container>
  );
}

export default RaiseAction;
