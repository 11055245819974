import { configureStore } from "@reduxjs/toolkit";
import { enableMapSet } from "immer";

// Import all slices
import lobbyReducer from "./slices/lobbySlice";
import gameReducer from "./slices/gameTableSlice";
import sliderReducer from "./slices/sliderSlice";
import modalReducer from "./slices/modalSlice";
import buttonReducer from "./slices/buttonSlice";
import alertReducer from "./slices/alertSlice";
import clientLogsReducer from "./slices/clientLogsSlice";

enableMapSet(); // Enable support for Map & Set in Immer

// Configure the Redux store
const store = configureStore({
  reducer: {
    lobby: lobbyReducer,
    modal: modalReducer,
    gameTable: gameReducer,
    slider: sliderReducer,
    button: buttonReducer,
    alert: alertReducer,
    clientLogs: clientLogsReducer,
  },
  // Middleware configuration if needed
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
