import Handler from "../Handler";

class PlayedTimeWarningHandler extends Handler {
  execute(message) {
    const receivedData = JSON.parse(message);
    this.service.processGameDurationWarn(receivedData);
  }
}

export default PlayedTimeWarningHandler;
