import styled from "styled-components";
import colors from "../../../styleGuide/Colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: var(--app-height);
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  transform: translate(5%, -10%);
  button {
    margin-bottom: 5%;
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  max-width: 450px;
  min-height: 200px;
  gap: 5px;
  height: auto;
  border-radius: 8px;
  background: ${colors.darkGreen};
  animation: springIn 0.4s forwards;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 16px 16px 20px 16px;
  width: 100%;
  box-sizing: border-box;
`;

