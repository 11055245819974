import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";
import {
  Typo12GrayContoRegularText,
  Typo14OffWhiteContoMediumText,
  Typo14OffWhiteContoRegularText,
  Typo12WhiteContoMediumText,
  Typo16WhiteContoMediumText,
  Typo14LightYellowContoBoldText,
  Typo10WhiteContoMediumText,
  Typo11GrayContoRegularText,
} from "../../../Common/styleGuide/Typos";

export const TourneyEntriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100% - 52px);
  overflow: scroll;
`;

export const FlightTourneyEntriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100% - 52px);
  overflow: scroll;
  box-sizing: border-box;
  padding: 16px;
`;

export const TotalPrizeAndNoOfWinnersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: auto;
  border-bottom: 1px solid ${colors.secondaryBlue};
  box-sizing: border-box;
  padding: 12px 0;
`;

export const TotalPrizeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  justify-content: center;
  width: 120px;
`;

export const TotalPrizeText = styled(Typo12GrayContoRegularText)`
  line-height: 24px;
  text-align: center;
`;
export const PrizeAmountText = styled(Typo14OffWhiteContoMediumText)`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 24px;
  text-align: center;
`;

export const TrophyIconWrapper = styled.img`
  height: 24px;
  width: 24px;
`;

export const PlayersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  justify-content: center;
  align-items: center;
  width: 120px;
`;

export const PlayersText = styled(Typo12GrayContoRegularText)`
  line-height: 24px;
  text-align: center;
`;
export const PlayersCountText = styled(Typo14OffWhiteContoMediumText)`
  line-height: 24px;
`;

export const TourneyEntriesTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 12px;
  position: relative;
`;

export const HeaderContentScrollIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: auto;
  // border-radius: 0px 0px 4px 4px;
  // border: 1px solid ${colors.secondaryBlue};
  max-height: 67vh;
  overflow-y: scroll;
  background: linear-gradient(180deg, #053a47 0%, #042f3a 100%);
`;

// export const TableWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   // height: auto;
//   border-radius: 4px;
//   border: 1px solid ${colors.secondaryBlue};
//   max-height: 67vh;
//   overflow-y: scroll;
// `;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: auto;
  // border-radius: 0px 0px 4px 4px;
  border-radius: 0px;
  border: 1px solid ${colors.secondaryBlue};
  border-top-style: none;
  border-bottom-style: none;
  // max-height: 67vh;
  // max-height: 65vh;
  overflow-y: scroll;
`;

// export const TableHeaderWrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   width: 100%;
//   justify-content: space-between;
//   padding: 10px 12px 10px 12px;
//   border-bottom: 1px solid ${colors.secondaryBlue};
//   height: 45px;
//   min-height: 45px;

//   box-sizing: border-box;
//   span:first-child {
//     justify-content: flex-start;
//   }
//   span:last-child {
//     justify-content: flex-end;
//     padding-right: 16px;
//   }
//   // background-color: blue;
// `;

export const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 10px 12px 10px 12px;
  // border-bottom: 1px solid ${colors.secondaryBlue};
  border: 1px solid ${colors.secondaryBlue};
  border-radius: 4px 4px 0px 0px;
  height: 45px;
  min-height: 45px;

  box-sizing: border-box;
  span:first-child {
    justify-content: flex-start;
  }
  span:last-child {
    justify-content: flex-end;
    // padding-right: 16px;
  }
`;

export const TableHeaderItemText = styled(Typo12GrayContoRegularText)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 33.33%;
  line-height: 24px;
`;

// export const TableRow = styled.div`
//   display: flex;
//   flex-direction: row;
//   width: 100%;
//   justify-content: space-between;
//   padding: 0px 12px 0px 12px;
//   box-sizing: border-box;
//   height: 48px;
//   min-height: 48px;
//   span:first-child {
//     justify-content: flex-start;
//   }
//   span:last-child {
//     justify-content: flex-end;
//     padding-right: 16px;
//   }
//   background-color: ${(props) =>
//     props.isOwnPlayerName ? " #336070" : "inherit"};
// `;

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 0px 12px 0px 12px;
  box-sizing: border-box;
  // height: 48px;
  // min-height: 48px;
  min-height: fit-content;
  span:first-child {
    justify-content: flex-start;
  }
  span:last-child {
    justify-content: flex-end;
    // padding-right: 16px;
  }
  background-color: ${(props) =>
    props.isOwnPlayerName ? " #336070" : "inherit"};
  border-bottom: 1px solid transparent;
  border-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.2) 0%,
      /* Added black overlay */ rgba(51, 96, 112, 0.2) 10%,
      /* Blend with teal */ rgba(51, 96, 112, 0.8) 33.85%,
      rgba(51, 96, 112, 0.8) 66.67%,
      rgba(51, 96, 112, 0.2) 90%,
      rgba(0, 0, 0, 0.2) 100% /* Black overlay at the end */
    )
    1;
`;
export const FlightTableRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  /* padding: 0px 12px 0px 12px; */
  box-sizing: border-box;
  // height: 48px;
  // min-height: 48px;
  min-height: fit-content;
  span:first-child {
    justify-content: flex-start;
  }
  span:last-child {
    justify-content: flex-end;
    // padding-right: 16px;
  }
  background-color: ${(props) =>
    props.isOwnPlayerName ? " #336070" : "inherit"};
  border-bottom: 1px solid transparent;
  border-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.2) 0%,
      /* Added black overlay */ rgba(51, 96, 112, 0.2) 10%,
      /* Blend with teal */ rgba(51, 96, 112, 0.8) 33.85%,
      rgba(51, 96, 112, 0.8) 66.67%,
      rgba(51, 96, 112, 0.2) 90%,
      rgba(0, 0, 0, 0.2) 100% /* Black overlay at the end */
    )
    1;
`;

export const TableItemText = styled(Typo14OffWhiteContoMediumText)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 33.33%;
  line-height: 24px;
  flex-wrap: wrap;
`;

export const PlayerEntryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.33%;
  justify-content: flex-start;
`;

export const PlayerNameText = styled(Typo14OffWhiteContoMediumText)`
  line-height: 24px;
`;

export const ChipsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ChipIcon = styled.img`
  height: 10px;
  width: 10px;
  margin-right: 4px;
`;

export const ChipsCount = styled(Typo14OffWhiteContoRegularText)`
  line-height: 24px;
`;

export const HorizontalSeperator = styled.img`
  width: 100%;

  margin-top: 4px;
  margin-bottom: 4px;
`;

export const TableMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 64vh;
  justify-content: center;
  align-items: center;
`;

export const MessageText = styled(Typo12WhiteContoMediumText)``;

export const TicketIcon = styled.img``;

export const AddIconText = styled(Typo16WhiteContoMediumText)`
  font-weight: 700;
  margin-left: 4px;
  margin-right: 4px;
`;

export const TicketsCountText = styled(Typo14LightYellowContoBoldText)`
  margin-right: 2px;
`;

export const EntriesTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  // padding: 10px 12px 10px 12px;
  padding: 10px 12px 0px 0px;
  // border-bottom: 1px solid ${colors.secondaryBlue};
  border: 1px solid ${colors.secondaryBlue};
  border-radius: 0px 0px 4px 4px;
  border-top-style: none;
  height: fit-content;
  // height: 45px;
  // min-height: 45px;

  box-sizing: border-box;
  // span:first-child {
  //   justify-content: flex-start;
  // }
  // span:last-child {
  //   justify-content: flex-end;
  //   padding-right: 16px;
  // }
`;

export const EntriesText = styled(Typo12GrayContoRegularText)`
  line-height: 24px;
  display: flex;
  width: 100%;
  justify-content: center !important;
  align-items: center !important;
  height: 100%;
`;

export const FlightTourneyEntriesDetailsTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(5, 58, 71, 0.8) 0%,
    rgba(4, 47, 58, 0.8) 100%
  );
  border: 0.5px solid #336070;
  border-radius: 8px; /* No need for !important */
  height: 100%;
  max-height: 500px;
  overflow-y: scroll;
  margin-bottom: 12px;
`;

export const TableHeader = styled.div`
  display: flex;
  width: 100%;
  font-weight: bold;
  border-bottom: 0.5px solid #336070;
`;

export const EntriesTableRow = styled.div`
  display: flex;
  width: 100%;
  /* border-bottom: 0.5px solid #336070; */
  border-bottom: 1px solid transparent;
  border-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.2) 0%,
      /* Added black overlay */ rgba(51, 96, 112, 0.2) 10%,
      /* Blend with teal */ rgba(51, 96, 112, 0.8) 33.85%,
      rgba(51, 96, 112, 0.8) 66.67%,
      rgba(51, 96, 112, 0.2) 90%,
      rgba(0, 0, 0, 0.2) 100% /* Black overlay at the end */
    )
    1;
`;

export const Column = styled.div`
  padding: 10px;
  text-align: ${(props) => (props.align ? props.align : "start")};
  width: ${(props) => props.width || "25%"};
`;

export const TableItemValueText = styled(Typo10WhiteContoMediumText)``;

export const TableHeaderText = styled(Typo11GrayContoRegularText)`
  line-height: 14.91px;
`;

export const UpcomingFlightsContainer = styled.div`
  display: flex;
  flex-direction: "row";
  height: 40px;
  width: 100%;
  border-radius: 6px;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #075063;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  box-sizing: border-box;
  box-shadow: ${(props) =>
    props.highlight ? " 0px 0px 6px 0px rgba(234, 191, 105, 1)" : ""};
`;

export const UpcomingFlightsText = styled(Typo12WhiteContoMediumText)`
  line-height: 16.26px;
`;

export const RightArrowIconWrapper = styled.img`
  transform: rotate(180deg);
  height: 12px;
`;
