import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MiniGameTableSVG from "../../../assets/gameTable/miniGameTable.svg";
import {
  BUY_IN,
  DISCONNECTION,
  GAME_CONNECTION_PROTOCOL_SUCCESS,
  HIDE_GAME_WINDOW_CALL_BACK,
  MIXPANEL_EVENT,
  NEW_TABLE,
} from "../../data/Constants";
import GameConnectionsN from "../../io/GameConnectionsN";
import {
  hideGameTable,
  openLocalGameModal,
  watchAllGameTables,
} from "../../redux/slices/gameTableSlice";
import { getGameSlice, getPlayerObject } from "../../utils/ReduxUtils";
import {
  MiniGameTableContainer,
  MiniGameTableImage,
  PlusIcon,
} from "./MiniGameTableStyle";
import { messageToNativeClient } from "../../../Common/utils/platformCommunicationUtil";
import CleverTapUtil from "../../../Common/utils/CleverTapUtil";
import { IP_Client_GameTable_AddTable_Click } from "../../data/ClevertapConstants";
import { closeTourneyInfoPage } from "../../redux/slices/lobbySlice";
import { getISTDateTimeFormat } from "../../../Common/utils/GameUtils";
import { logCleverTapEvent } from "../../../Common/utils/CleverTapUtilNew";
import { MP_POKER_GAMETABLE_ADD_TABLE_CLICKED } from "../../data/MixPanelConstant";
import { getEventParams } from "../../utils/MixPanelUtils";

const AddGameTable = (props) => {
  const buttonStatus = useSelector((state) => state.button.value);
  const activeGame = useSelector((state) => state.gameTable.activeGame);
  const activeGameConnection = useSelector(
    (state) => state.gameTable.games[activeGame]?.gameConnectionStatus
  );
  const gameOrientation = useSelector(
    (state) => state.gameTable.tableOrientation
  );
  const currentGameDefinition = useSelector(
    (state) => state.gameTable.games[activeGame]?.gameDefinition
  );
  
  const userBalance = useSelector((state) => state.lobby.balance);
  const userName = useSelector((state) => state.lobby.player.userName);
  const isAllowLocation = useSelector(
    (state) => state.lobby.player.isAllowLocation
  );
  const playerDetails = useSelector(
    (state) => state.lobby.player  );

  const location_games = useSelector((state) => state.lobby.location_games);
  const modalType = useSelector(
    (state) => state.gameTable.games[activeGame]?.gameModal?.type
  );
  const isTourneyGame = useSelector(
    (state) => state.gameTable.games[activeGame]?.isTourneyGame
  );
  const isFunGame = useSelector(
    (state) => state.gameTable.games[activeGame]?.isFunGame
  );
  const isPrivateTable = useSelector(
    (state) => state.gameTable.games[activeGame].privateTable.isPrivateTable
  );
  const channel = useSelector((state) => state.lobby.channel);
  const dispatch = useDispatch();
const ActivePlayerCount=useSelector((state)=>state.gameTable?.games[activeGame]?.activeplayerCount);
const SitoutPlayerCount=useSelector((state)=>state.gameTable?.games[activeGame]?.sitoutplayerCount);

  const joinNewGameTable = () => {
    let timeStamp = getISTDateTimeFormat();
    messageToNativeClient({
      type: "plotlineEvents",
      eventName: "PR_Client_GameTable_AddTable_Click",
      eventProperties: {
        userName: getPlayerObject().userName,
        channel,
        currentCashBalance: userBalance?.totalBalance,
        timeStamp,
        bigBlind: currentGameDefinition.bigBlind,
        smallBlind: currentGameDefinition.smallBlind,
        noOfPlayers: currentGameDefinition.maxPlayers,
        gameType: currentGameDefinition.gameType,
      },
    });
    const MixPanel_params = getEventParams(
      MP_POKER_GAMETABLE_ADD_TABLE_CLICKED,
      playerDetails,
      userBalance,
      channel,
      currentGameDefinition,
    );

    messageToNativeClient({
      type: MIXPANEL_EVENT,
      eventName: MP_POKER_GAMETABLE_ADD_TABLE_CLICKED,
      eventProperties: {...MixPanel_params,ActivePlayerCount,SitoutPlayerCount}
    })
    logCleverTapEvent(IP_Client_GameTable_AddTable_Click, {
      Username: userName,
      Channel: channel,
      "Wallet balance": userBalance?.totalBalance,
      Timestamp: timeStamp,
      "Big blind": currentGameDefinition?.bigBlind,
      "No. of players": currentGameDefinition?.maxPlayers,
      "Game type": currentGameDefinition?.gameType,
      "Table name": currentGameDefinition?.tableName,
      "Game mode": currentGameDefinition?.gameMode,
    });
    // Closes all option menus in game table
    // close()
    if (isTourneyGame) {
      // const { gameToLobbyCallBack } = props;
      dispatch(closeTourneyInfoPage());
      // gameToLobbyCallBack &&
      //   gameToLobbyCallBack({ type: HIDE_GAME_WINDOW_CALL_BACK });

      dispatch(watchAllGameTables({ dispatchFunction: dispatch }));
      dispatch(hideGameTable());

      return;
    }
    if (isPrivateTable) {
      dispatch(hideGameTable());
      return;
    }
    if (buttonStatus) {
      if (userBalance?.totalBalance >= currentGameDefinition?.minBuyInAmount) {
        if (modalType === BUY_IN) {
          let tableData = getGameSlice().games[activeGame];
          let playerSeat = tableData.setBuyInValueForPlayerPosition;
          console.log("Sending DReserve protocol");

          let gameConnection =
            GameConnectionsN.getInstance().getGameConnection(activeGame);
          gameConnection.listener.requestRemoveReserveSeat({
            playerName: getPlayerObject().userName,
            position: playerSeat,
            tableId: tableData.tableId,
          });
        }
        dispatch(
          openLocalGameModal({ type: NEW_TABLE, tempTableId: activeGame })
        );
      } else {
        dispatch(hideGameTable());
      }
    }
    return;
  };

  return modalType !== DISCONNECTION &&
    !isFunGame &&
    location_games &&
    activeGameConnection === GAME_CONNECTION_PROTOCOL_SUCCESS ? (
    <MiniGameTableContainer
      orientation={gameOrientation}
      id="mini-gametable-container"
      onClick={() => joinNewGameTable()}
    >
      <MiniGameTableImage id="mini-gametable-image" src={MiniGameTableSVG} />
      <PlusIcon>+</PlusIcon>
    </MiniGameTableContainer>
  ) : null;
};

export default AddGameTable;
