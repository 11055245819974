import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BuyIn } from "./BuyIn/BuyIn";
import HandRanking from "./HandRanking/HandRanking";
import { Information } from "./Information/Information";
import { DarkScreen, GlobalModal, RelativeWrapper } from "./modalStyle";
import PlayerStats from "./PlayerStats/PlayerStats";
import {
  closeLocalGameModal,
  handleSitOutButtonOnDRebuyIn,
} from "../../../Poker/redux/slices/gameTableSlice";
import DisconnectionModal from "./Disconnection/DisconnectionModal";
import {
  BUY_IN,
  DISCONNECTION,
  FOLD_CONFIRM,
  GAME_LOOSER_MODAL,
  HAND_RANKING,
  INFORMATION,
  INSUFFICIENT_BALANCE,
  INSUFFICIENT_BALANCE_WITH_LEAVE_SEAT,
  LEAVE_SEAT,
  LEAVE_TABLE,
  MAX_TABLE_LIMIT,
  NEW_TABLE,
  PLAYER_STATS,
  REBUY_IN,
  RELOGIN_FAILURE,
  TOURNEY_GAME_COMPLETE,
  TOURNEY_GAME_WINNER_MODAL,
  GAME_TABLE_WATCH_CHANNEL_EXIST,
  REPORT_AN_ISSUE,
  INSUFFICIENT_FUN_CHIPS_BALANCE,
  RESTRICT_FUN_CHIPS_RELOAD,
  TOURNEY_REBUYIN_WITH_DOUBLE_REBUY,
  MAX_FUN_GAMES_LIMIT,
  MAINTENANCE,
  TOURNEY_ADDON,
  RESTRICTED_LOCATION,
  DELETED_PLAYER_LOGOUT,
  ACCOUNT_ISSUE_GAME_JOIN,
  WALLET_BALANCE_ISSUE_GAME_JOIN,
  DATA_LOST_ISSUE_GAME_JOIN,
  RESTRICTED_LOCATION_GAME_EXIT,
  CONNECTION_REQUESTS_LIMIT_REACHED,
  TOURNEY_WATCH_GAME_COMPLETED,
  TOURNEY_CANCEL_WITH_REFUND,
  TOURNEY_CANCEL_WITH_HOLD,
  JOIN_FAILURE_ACCOUNT_DELETED,
  ACKNOWLEDGE_HIGH_STRENGTH_WIN,
  RESTRICTED_LOCATION_HOURS,
  END_PRIVATE_TABLE,
  PRIVATE_TABLE_ADD_ANNOUNCEMENT,
  TERMINATE_PRIVATE_TABLE,
} from "../../../Poker/data/Constants";
import SelectNewTable from "./NewGame/NewTable";
import Confirm from "./Confirm/Confirm";
import { getGameSlice, getPlayerObject } from "../../../Poker/utils/ReduxUtils";
import GameConnectionsN from "../../../Poker/io/GameConnectionsN";
import {
  sendDRebuyInProtocol,
  sendLobbyDebugProtocol,
} from "../../../Poker/utils/ProtocolUtils";
import GameLooserModal from "../../../Poker/components/GameLooserModal/GameLooserModal";
import TourneyWinnerModal from "../../../Poker/components/TourneyWinnerModal/TourneyWinnerModal";
import TourneyCompleteModal from "../../../Poker/components/TourneyCompleteModal/TourneyCompleteModal";
import GameTableWatchChannelExistModal from "../../../Poker/components/GameTableWatchChannelExistModal/GameTableWatchChannelExistModal";
import MaxTableLimit from "./MaxTableLimit/MaxTableLimit";
import ReportIssue from "../../../Poker/components/ReportIssue/ReportIssue";
import TourneyRebuyModal from "../../../Poker/components/TourneyRebuyModal/TourneyRebuyModal";
import MaxFunTablesLimit from "./MaxTableLimit/MaxFunTablesLimit";
import Maintenance from "./MaintenancePopup/Maintenance";
import DeletedPlayerForceLogout from "./ForceLogout/DeletedPlayerForceLogout";
import { AcknowledgeHighStrengthWin } from "./AckHighHandStrengthWin/AcknowledgeHighStrengthWin";
import { Announcement } from "./Announcement/Announcement";
import { closeSlider } from "../../../Poker/redux/slices/sliderSlice";

const Modal = ({ tempTableId }) => {
  const dispatch = useDispatch();
  const modalType = useSelector(
    (state) => state.gameTable.games[tempTableId].gameModal.type
  );
  const modalStatus = useSelector(
    (state) => state.gameTable.games[tempTableId].gameModal.visible
  );
  const modalConfig = useSelector(
    (state) => state.gameTable.games[tempTableId].gameModal.config
  );
  const modalData = useSelector(
    (state) => state.gameTable.games[tempTableId].gameModal.data
  );

  const gameId = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameId
  );

  const tourneyId = useSelector(
    (state) => state.gameTable.games[tempTableId].gameDefinition?.tourneyId
  );

  useEffect(() => {
    if (modalType) {
      sendLobbyDebugProtocol({
        gameId: gameId,
        tourneyId: tourneyId,
        debuglog: `Modal Opened: ${modalType}`,
      });
      if (modalType === END_PRIVATE_TABLE) {
        dispatch(closeSlider());
      }
    } else {
      sendLobbyDebugProtocol({
        gameId: gameId,
        tourneyId: tourneyId,
        debuglog: `Modal Closed`,
      });
    }
  }, [modalType]);

  const createModal = () => {
    console.log("OPENED_MODAL ", modalType);
    switch (modalType) {
      case BUY_IN:
        // console.log('Triggering buy in flow', tempTableId)
        return (
          <BuyIn
            tempTableId={tempTableId}
            handleModalClose={handleModalClose}
          />
        );
      case REBUY_IN:
        return (
          <BuyIn
            tempTableId={tempTableId}
            handleModalClose={handleModalClose}
          />
        );
      case INSUFFICIENT_BALANCE:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={INSUFFICIENT_BALANCE}
            handleModalClose={handleModalClose}
            modalConfig={modalConfig}
            modalType={"game"}
          />
        );
      case RELOGIN_FAILURE:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={RELOGIN_FAILURE}
            handleModalClose={handleModalClose}
          />
        );
      case INSUFFICIENT_BALANCE_WITH_LEAVE_SEAT:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={INSUFFICIENT_BALANCE_WITH_LEAVE_SEAT}
            handleModalClose={handleModalClose}
            modalConfig={modalConfig}
          />
        );
      case INSUFFICIENT_FUN_CHIPS_BALANCE:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={INSUFFICIENT_FUN_CHIPS_BALANCE}
            handleModalClose={handleModalClose}
            modalConfig={modalConfig}
          />
        );
      case RESTRICT_FUN_CHIPS_RELOAD:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={RESTRICT_FUN_CHIPS_RELOAD}
            handleModalClose={handleModalClose}
            modalConfig={modalConfig}
          />
        );

      case INFORMATION:
        return <Information tempTableId={tempTableId} />;
      case HAND_RANKING:
        return <HandRanking tempTableId={tempTableId} />;
      case PLAYER_STATS:
        return <PlayerStats tempTableId={tempTableId} />;
      case LEAVE_TABLE:
        console.log("Creating LEAVE TABLE ALERT");
        return (
          <Confirm
            tempTableId={tempTableId}
            type={LEAVE_TABLE}
            handleModalClose={handleModalClose}
          />
        );
      case "join-failure":
      // return <Confirm type="leave-table" />;
      case LEAVE_SEAT:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={LEAVE_SEAT}
            handleModalClose={handleModalClose}
          />
        );
      case FOLD_CONFIRM:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={FOLD_CONFIRM}
            handleModalClose={handleModalClose}
          />
        );
      case CONNECTION_REQUESTS_LIMIT_REACHED:
      case ACCOUNT_ISSUE_GAME_JOIN:
      case WALLET_BALANCE_ISSUE_GAME_JOIN:
      case DATA_LOST_ISSUE_GAME_JOIN:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={modalType}
            // handleModalClose={() => {}}
            handleModalClose={handleModalClose}
          />
        );
      case TOURNEY_WATCH_GAME_COMPLETED:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={modalType}
            handleModalClose={handleModalClose}
          />
        );
      case DISCONNECTION:
        return (
          <DisconnectionModal tempTableId={tempTableId} type={DISCONNECTION} />
        );
      // case RELOGIN_FAILURE:
      //   return (
      //     <DisconnectionModal
      //       tempTableId={tempTableId}
      //       type={RELOGIN_FAILURE}
      //     />
      //   );
      case NEW_TABLE:
        return <SelectNewTable tempTableId={tempTableId} />;
      case GAME_LOOSER_MODAL:
        return (
          <GameLooserModal
            tempTableId={tempTableId}
            modalConfig={modalConfig}
          />
        );
      case TOURNEY_GAME_WINNER_MODAL:
        return <TourneyWinnerModal tempTableId={tempTableId} />;
      case TOURNEY_GAME_COMPLETE:
        return <TourneyCompleteModal tempTableId={tempTableId} />;
      case GAME_TABLE_WATCH_CHANNEL_EXIST:
        return (
          <GameTableWatchChannelExistModal
            tempTableId={tempTableId}
            modalConfig={modalConfig}
          />
        );
      case MAX_TABLE_LIMIT:
        return <MaxTableLimit tempTableId={tempTableId} />;
      case MAX_FUN_GAMES_LIMIT:
        return <MaxFunTablesLimit tempTableId={tempTableId} />;
      case REPORT_AN_ISSUE:
        return <ReportIssue tempTableId={tempTableId} />;
      case TOURNEY_REBUYIN_WITH_DOUBLE_REBUY:
        return <TourneyRebuyModal tempTableId={tempTableId} />;
      case MAINTENANCE:
        return <Maintenance />;
      case ACKNOWLEDGE_HIGH_STRENGTH_WIN:
        return (
          <AcknowledgeHighStrengthWin
            tempTableId={tempTableId}
            modalConfig={modalConfig}
          />
        );
      case TOURNEY_ADDON:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={TOURNEY_ADDON}
            handleModalClose={handleModalClose}
          />
        );
      case DELETED_PLAYER_LOGOUT:
        return <DeletedPlayerForceLogout />;
      case RESTRICTED_LOCATION_GAME_EXIT:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={RESTRICTED_LOCATION_GAME_EXIT}
            handleModalClose={() => null}
          />
        );
      case TOURNEY_CANCEL_WITH_REFUND:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={TOURNEY_CANCEL_WITH_REFUND}
            handleModalClose={handleModalClose}
          />
        );
      case TOURNEY_CANCEL_WITH_HOLD:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={TOURNEY_CANCEL_WITH_HOLD}
            handleModalClose={handleModalClose}
          />
        );
      case JOIN_FAILURE_ACCOUNT_DELETED:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={JOIN_FAILURE_ACCOUNT_DELETED}
            handleModalClose={handleModalClose}
          />
        );
      case RESTRICTED_LOCATION_HOURS:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={RESTRICTED_LOCATION_HOURS}
            handleModalClose={handleModalClose}
          />
        );

      case END_PRIVATE_TABLE:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={END_PRIVATE_TABLE}
            handleModalClose={() => null}
            modalConfig={modalConfig}
          />
        );

      case PRIVATE_TABLE_ADD_ANNOUNCEMENT:
        return (
          <Announcement tempTableId={tempTableId} modalConfig={modalConfig} />
        );

      case TERMINATE_PRIVATE_TABLE:
        return (
          <Confirm
            tempTableId={tempTableId}
            type={TERMINATE_PRIVATE_TABLE}
            handleModalClose={handleModalClose}
          />
        );
      default:
        return;
    }
  };

  const handleModalClose = () => {
    if (modalType === BUY_IN) {
      let tableData = getGameSlice().games[tempTableId];
      let playerSeat = tableData.setBuyInValueForPlayerPosition;
      console.log("Sending DReserve protocol");

      let gameConnection =
        GameConnectionsN.getInstance().getGameConnection(tempTableId);
      gameConnection.listener.requestRemoveReserveSeat({
        playerName: getPlayerObject().userName,
        position: playerSeat,
        tableId: tableData.tableId,
      });
      //hitting protocol directly @yashwanth
      // dispatch(requestRemoveReserveSeat({tempTableId: tempTableId}));
      return;
    } else if (modalType === REBUY_IN) {
      sendDRebuyInProtocol({
        tempTableId,
      });
      dispatch(
        handleSitOutButtonOnDRebuyIn({
          tempTableId,
        })
      );
    } else if (
      modalType === "join-failure" ||
      modalType === "Disconnection" ||
      modalType === RELOGIN_FAILURE ||
      modalType === DELETED_PLAYER_LOGOUT ||
      modalType === CONNECTION_REQUESTS_LIMIT_REACHED ||
      modalType === ACCOUNT_ISSUE_GAME_JOIN ||
      modalType === WALLET_BALANCE_ISSUE_GAME_JOIN ||
      modalType === DATA_LOST_ISSUE_GAME_JOIN ||
      modalType === TOURNEY_WATCH_GAME_COMPLETED ||
      modalType === RESTRICTED_LOCATION_HOURS
    ) {
      return;
    } else {
      dispatch(
        closeLocalGameModal({
          tempTableId: tempTableId,
        })
      );
      return;
    }
  };

  const modalBackgroundActions = () => {
    if (modalType === BUY_IN || modalType === REBUY_IN) {
      // dispatch(requestRemoveReserveSeat({}));
      return;
    } else if (
      modalType === "join-failure" ||
      modalType === "Disconnection" ||
      modalType === RELOGIN_FAILURE ||
      modalType === DISCONNECTION ||
      modalType === RESTRICTED_LOCATION ||
      modalType === RESTRICTED_LOCATION_HOURS ||
      modalType === TOURNEY_GAME_COMPLETE ||
      modalType === TOURNEY_GAME_WINNER_MODAL ||
      modalType === GAME_LOOSER_MODAL ||
      modalType === DELETED_PLAYER_LOGOUT ||
      modalType === ACCOUNT_ISSUE_GAME_JOIN ||
      modalType === WALLET_BALANCE_ISSUE_GAME_JOIN ||
      modalType === DATA_LOST_ISSUE_GAME_JOIN ||
      modalType === TOURNEY_WATCH_GAME_COMPLETED
    ) {
      return;
    } else {
      dispatch(
        closeLocalGameModal({
          tempTableId: tempTableId,
        })
      );
      return;
    }
  };

  return (
    <>
      {modalStatus && modalType ? (
        <GlobalModal id='global-modal'>
          <RelativeWrapper id='relative-wrapper'>
            <DarkScreen
              id='darkscreen'
              onClick={() => modalBackgroundActions()}
            />
            {createModal()}
          </RelativeWrapper>
        </GlobalModal>
      ) : null}
    </>
  );
};

export default Modal;
