import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";

export const HomePageContainer = styled.div`
  width: 100%;
  flex: 1;
  background: var(--Color-Greens-Background-Teal);
  color: white;
  font-family: "Conto";
  overflow-y: scroll;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  /* margin: auto;
  width: 95%; */
  gap: 6px;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  margin-bottom: 12px;
`;

export const SubtitleText = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: rgba(186, 186, 186, 1);
`;

export const HeaderIcon = styled.img`
  height: 13px;
  width: auto;
`;

export const HomePageTitle = styled.span`
  color: white;
  font-family: "Conto";
  font-size: 13px;
  font-weight: 700;
`;

export const HorizontalBannersContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  /* margin: 1rem auto; */
  /* padding: 0px 12px; */
`;

export const SectionContainer = styled.div`
  margin: 1.5rem auto 1rem auto;
  width: 100%;
  padding: ${(props) => (props.rightGap ? "0px 12px" : "0px 0px 0px 12px")};
`;

export const TrustSectionContainer = styled.div`
  /* min-height: 250px; */
  margin: 1.5rem auto 2.5rem auto;
  width: 100%;
  padding: 0px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const SwipableContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  /* gap: 10px; */
  align-items: center;
  justify-content: flex-start;
`;

export const SwipeCard = styled.div`
  /* min-width: 250px; */
  width: fit-content;
  white-space: nowrap;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  /* overflow: hidden; */
  border: 1px solid #075063;
  background-color: #06323e;
  animation: ${(props) => (props.isLoading ? "ripple 2s infinite" : null)};
  margin-right: 10px;
`;

export const RecommendedSwipeCard = styled.div`
  /* min-width: 60%; */
  width: 100%;
  max-width: 50%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  overflow: hidden;
  gap: 10px;
  border: 1px solid rgba(88, 182, 198, 0.4);
  /* background: ; */
  padding: 8px 12px;
  white-space: nowrap;
  animation: ${(props) => (props.isLoading ? "ripple 2s infinite" : null)};
  margin-right: 10px;
`;

export const NewRecommendedSwipeCard = styled.div`
  position: relative;
  width: 100%;
  max-width: 50%;
  min-height: 50px;
  padding: 10px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: #33607040;
  border: 1px solid #33607066;
  border-radius: 8px;
  animation: ${(props) =>
    props.isLoading ? "ripple 2s infinite" : "fade-in 0.5s forwards"};
`;

export const RecommendedCardLeft = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 50%;
  height: 100%;
  gap: 6px;
  /* background-color: green; */
`;

export const RecommendedCardRight = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 35%;
  height: 100%;
  justify-content: space-between;
  align-items: flex-end;
  /* gap: 12px; */
  /* background-color: red; */
`;

export const RecommendedCardCTA = styled.div`
  width: 24px;
  height: 24px;
  background-color: #ffffff1a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: red;
  border-radius: 50%;
`;

export const RecommendedType = styled.div`
  background-color: ${(props) =>
    props.type === "OMAHA"
      ? "#5A571E"
      : props.type === "OMAHA5"
      ? "#67401A"
      : props.type === "OMAHA6"
      ? "#298888"
      : "#631414"};
  font-size: 10px;
  color: ${colors.primaryBlue};
  font-weight: 700;
  border-radius: 24px;
  padding: 3px 8px;
  color: white;
`;

export const RecommendedText = styled.span`
  font-size: 12px;
  font-weight: 700;
`;

export const RecommendedOnlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;

export const GameTypeIconElement = styled.img`
  /* width: 8px; */
  height: 10px;
  width: auto;
`;

export const GameTileBottomLeftText = styled.span`
  font-size: 11px;
  font-weight: 500;
  font-family: "Conto";
  color: #bebab9;
`;

export const RecommendedActionButton = styled.button`
  background: linear-gradient(180deg, #2baa3a 0%, #0f6820 100%);
  border-top: 1px solid rgba(82, 255, 105, 1);
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 10px;
  font-weight: 700;
  color: white;
  font-family: "Conto";
  justify-self: flex-end;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.6);
`;

export const LeftSection = styled.div`
  height: 100%;
  min-width: 50px;
  padding: 0px 6px;
  background-color: #164958;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px 0px 0px 8px;
`;

export const TopText = styled.span`
  color: ${colors.primaryGold};
  font-weight: 700;
  font-size: 10px;
`;

export const BottomText = styled.span`
  color: white;
  font-size: 16px;
  font-weight: 700;
`;

export const RightSection = styled.div`
  height: 100%;
  min-width: 140px;
  /* padding: 0px 12px; */
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const RightSectionContentContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
`;

export const RightSectionText = styled.span`
  font-weight: 500;
  font-size: 12px;
`;

export const RightSectionProgressContainer = styled.div`
  width: 100%;
  min-width: 100px;
  max-width: 144px;
  height: 10px;
  border-radius: 100px;
  background-color: rgba(3, 29, 36, 1);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 5px black inset;
`;

export const RightSectionProgressInnerContainer = styled.div`
  width: calc(100% - 2px);
  height: calc(100% - 2px);
`;

export const RightSectionProgressAmount = styled.div`
  /* width: ${(props) => (props.percentage ? props.percentage + "%" : "0%")}; */
  height: 100%;
  border-radius: 100px;
  background-color: #336070;
  border-top: 1px solid rgba(242, 242, 242, 0.54);
  width: ${(props) => `${props.percentage}%`};
  animation: animate-progress 1s forwards;
  animation-delay: 1s;

  @keyframes animate-progress {
    from {
      width: 0%;
    }
    to {
      width: ${(props) =>
        props.percentage ? props.percentage + "%" : "0%"} !important;
    }
  }
`;

export const IconsGridContainer = styled.div`
  display: grid;
  padding: 0rem 12px;
  gap: 8px;
  grid-template-columns: 1fr 1fr 1fr;
  /* background-color: ${colors.primaryBlue}; */
  margin-bottom: 1rem;
`;

export const LoadingGridIcon = styled.div`
  width: 100%;
  height: 140px;
  background-color: #33607040;
  align-items: center;
  border-radius: 8px;
  animation: ripple 2s infinite;
`;

export const GridIcon = styled.div`
  width: 100%;
  /* height: auto; */
  /* background: linear-gradient(
      177.63deg,
      rgba(66, 190, 64, 0.26) -1.97%,
      rgba(7, 80, 99, 0.22) 68.29%,
      #075063 98.16%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)); */
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  /* aspect-ratio: 1; */
  border-radius: 8px;
  animation: fade-in 0.5s forwards;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.24); */
  /* border-top: 1px solid #40746a; */
`;

export const GridImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.6);
`;

export const HorizontalCard = styled.div`
  margin: auto;
  width: 100%;
  aspect-ratio: 5;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 5px; */
  /* border: 1px solid #075063; */
  overflow: hidden;
`;

export const HorizontalImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const PromotionalBannersContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  /* gap: 6px; */
  padding: 0px 12px;
  div:nth-child(2) {
    margin-left: 6px;
    margin-right: 6px;
  }
`;

export const PromotionsCard = styled.div`
  /* width: 150px; */
  /* width: auto; */
  /* height: 200px; */
  /* min-width: 150px; */
  width: 100%;
  height: auto;
  /* aspect-ratio: 0.742; */
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #075063;
`;

export const PromotionsImage = styled.img`
  width: 100%;
  /* width: 100%; */
  /* max-width: calc(var(--app-width) - 24px); */
  border-radius: 5px;
  /* border: 1px solid #075063; */
`;

export const ReferBanner = styled.img`
  width: 100%;
  border-radius: 5px;
  border: 1px solid #075063;
`;

export const ReferContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* gap: 10%; */
  width: 100%;
  animation: fade-in 0.5s forwards;
`;

export const ReferImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  /* max-height: 20vh; */
  /* margin-right: 10%; */
  /* object-fit: scale-down; */
`;

export const ReferContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const ReferText = styled.div`
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  color: ${colors.primaryGold};
`;

export const ReferCTA = styled.div`
  background: ${colors.ctaPrimaryColor};
  padding: 6px 12px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: 700;
`;

export const ReferActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  /* gap: 1rem; */
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 25%;
  max-width: 50%;
  font-size: 12px;
  font-weight: 500;
  color: rgba(88, 182, 198, 1);
  text-decoration: underline;
  gap: 5px;
  height: 20px;
`;

export const KnowMoreSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HowToPlayContainer = styled.div`
  width: 100%;
  padding: 0px 12px;
  margin: 2rem 0rem;
  animation: fade-in 0.5s forwards;
`;

// export const HowToPlayPokerBanner = styled.div`
/* width: 100%; */
/* border-radius: 8px; */
/* border: 1px solid rgba(88, 182, 198, 0.4); */
/* overflow: hidden; */
// `

export const KnowMoreOption = styled.div`
  width: 96%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  gap: 1rem;
`;

export const KnowMoreIcon = styled.div`
  /* width: 10%; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const KnowMoreOptionName = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: white;
`;

export const KnowMoreOptionArrow = styled.div`
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TrustText = styled.div`
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -2%;
  color: #4d5761;
`;

export const GreenArrow = styled.img`
  width: 25px;
  height: auto;
  margin: 0px 5px;
`;

export const LeaderboardDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 12px;
  /* background-color: red; */
`;

export const MissionsDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  white-space: nowrap;
`;

export const LeaderboardSubdetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 10px;
  color: #bababa;
`;

export const LeaderboardDetailsDivider = styled.div`
  width: 2px;
  height: 12px;
  background: linear-gradient(
    rgba(205, 127, 50, 0.1),
    rgba(205, 127, 50, 0.75),
    rgba(205, 127, 50, 0.1)
  );
`;

export const MissionDetailsText = styled.span`
  font-size: 10px;
  color: rgba(242, 242, 242, 1);
`;

export const TopBannerSkeleton = styled.div`
  width: 100%;
  aspect-ratio: 5;
  border-radius: 0px;
  background-color: #33607040;
  animation: ripple 2s infinite;
`;

export const NoScrollContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* background-color: red; */
  gap: 8px;
`;

export const PromotionalBannerSkeleton = styled.div`
  width: 150px;
  border-radius: 8px;
  height: 175px;
  background-color: #33607040;
  animation: ripple 2s infinite;
  /* margin-right: 12px; */
`;

export const PlayersCornerSection = styled.div`
  width: 100%;
  margin: 0.5rem auto 1rem auto;
  display: flex;
  flex-direction: column;
`;

export const PlayersCornerTrackerSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 12px; */
  padding: 0px 12px;
`;

export const PlayersCornerCard = styled.div`
  position: relative;
  min-height: 74px;
  border-radius: 100px 12px 12px 100px;
  padding: 4px;
  background-color: #33607080;
  border: 1px solid #075063;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  transform: translateX(-200%);
  animation: show-up-from-right 0.5s forwards;
  animation-delay: ${(props) => props.index * 0.25 + "s"};
  margin-bottom: 12px;
  /* transform: translateX(-10px); */

  @keyframes show-up-from-right {
    0% {
      transform: translateX(-200%);
    }
    100% {
      transform: translateX(0%);
    }
  }
`;

export const PlayersCornerCardLeftConnector = styled.div`
  height: 4px;
  width: 25px;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-100%, -50%);
  background-color: #33607080;
  border: 1px solid #075063;
`;

export const PlayersCornerCardImageContainer = styled.div`
  height: 66px;
  width: 66px;
  background: var(--Color-Greens-Background-Teal);
  border-radius: 100px;
  border: 3px solid #07506340;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlayersCornerCardImage = styled.img`
  width: 60%;
  height: auto;
`;

export const PlayersCornerCardInfo = styled.div`
  height: 100%;
  width: 60%;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 6px;
`;

export const PlayerCornerCardTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #fffdea;
  margin-bottom: 4px;
`;

export const PlayerCornerCardSubtitle = styled.div`
  font-size: 11px;
  color: #bababa;
  margin-top: 4px;
`;

export const PlayersCornerCardCTAContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* background-color: yellow; */
  height: 100%;
  width: 20%;
  color: #fffdea;
  padding-right: 6px;
`;

export const PlayersCornerCardCTA = styled.div`
  font-size: 11px;
  border-bottom: 1px solid #fffdea50;
  font-weight: 700;
  display: flex;
  align-items: center;
  /* gap: 4px; */

  /* background-color: red; */
`;

export const TypeText = styled.span`
  margin-right: 4px;
`;


export const PrivateTableBanner = styled.img`
  width: 97%;
  height: 3.6rem;
  border-radius: 3px;
`;