import React from "react";
import {
  CloseButton,
  Container,
  Header,
  HeaderText,
  MySessionBottomData,
  MySessionBottomDataHeader,
  MySessionBottomDataValue,
  MySessionStatsContainer,
  SessionGraphData,
  SessionGraphDataColoredBox,
  SessionGraphDataText,
  SessionGraphRow,
  SessionGraphRowLeft,
  SessionGraphRowRight,
  TablePlayerDataText,
  TablePlayersData,
  TablePlayersDataHeaderText,
  TablePlayersDetailsContainer,
  TableRow,
} from "./TableStatsStyle";
import close from "../../../assets/common/close.svg";
import CircularProgress from "./CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  closeSlider,
  updateSliderAnimatingFlag,
} from "../../redux/slices/sliderSlice";
import { parseDecimalValuesOfNumber } from "../../../Common/utils/GameUtils";
import { getDisplayName } from "../../utils/ReduxUtils";
const TableStatistics = ({ sliderDirection, closeSliderProp }) => {
  const tableStatsData = useSelector(
    (state) => state.gameTable.games[state.gameTable.activeGame].tableStats
  );
  const playerSeat = useSelector(
    (state) => state.gameTable.games[state.gameTable.activeGame].playerSeat
  );
  const loggedPlayer = useSelector((state) => state.lobby.player)
  let playerDisplayNameMap = useSelector((state) =>
    state.gameTable.games[state.gameTable.activeGame]?.displayNameMap);
  const displayName = useSelector((state) => state.lobby?.displayName);
  const dispatch = useDispatch();

  const createCircularProgress = (percentageValue) => {
    return (
      <CircularProgress
        percentageValue={Math.floor(percentageValue)}
        percentageDisplay={percentageValue}
        outerColor={"#EABF69"}
        innerColor={"#032028"}
      />
    );
  };

  const createMySessionData = () => {
    return (
      <MySessionStatsContainer>
        <SessionGraphRow>
          <SessionGraphRowLeft>
            {createCircularProgress(tableStatsData.handsWonPercent)}
          </SessionGraphRowLeft>
          <SessionGraphRowRight>
            <SessionGraphData>
              <SessionGraphDataColoredBox
                color={"#EABF69"}
              ></SessionGraphDataColoredBox>
              <SessionGraphDataText>
                Hands Won ({tableStatsData.handsWon})
              </SessionGraphDataText>
            </SessionGraphData>
            <SessionGraphData>
              <SessionGraphDataColoredBox></SessionGraphDataColoredBox>
              <SessionGraphDataText>
                Hands Played ({tableStatsData.handsPlayed})
              </SessionGraphDataText>
            </SessionGraphData>
          </SessionGraphRowRight>
        </SessionGraphRow>
        <SessionGraphRow>
          <SessionGraphRowLeft>
            {createCircularProgress(tableStatsData.flopsSeenPercent)}
          </SessionGraphRowLeft>
          <SessionGraphRowRight>
            <SessionGraphData>
              <SessionGraphDataColoredBox
                color={"#EABF69"}
              ></SessionGraphDataColoredBox>
              <SessionGraphDataText>
                Flops Seen ({tableStatsData.flopsSeen})
              </SessionGraphDataText>
            </SessionGraphData>
            <SessionGraphData>
              <SessionGraphDataColoredBox></SessionGraphDataColoredBox>
              <SessionGraphDataText>
                Hands Played ({tableStatsData.handsPlayed})
              </SessionGraphDataText>
            </SessionGraphData>
          </SessionGraphRowRight>
        </SessionGraphRow>
      </MySessionStatsContainer>
    );
  };

  const createBottomStatsRow = () => {
    return (
      <SessionGraphRow>
        <MySessionBottomData>
          <MySessionBottomDataValue>
            {tableStatsData.totalBuyIn >= 0
              ? `₹${parseDecimalValuesOfNumber(
                  tableStatsData.totalBuyIn,
                  2,
                  true
                )}`
              : `-₹${parseDecimalValuesOfNumber(
                  -tableStatsData.totalBuyIn,
                  2,
                  true
                )}`}
          </MySessionBottomDataValue>
          <MySessionBottomDataHeader>Total buy-In</MySessionBottomDataHeader>
        </MySessionBottomData>
        <MySessionBottomData>
          <MySessionBottomDataValue>
            {tableStatsData.totalBet >= 0
              ? `₹${parseDecimalValuesOfNumber(
                  tableStatsData.totalBet,
                  2,
                  true
                )}`
              : `-₹${parseDecimalValuesOfNumber(
                  -tableStatsData.totalBet,
                  2,
                  true
                )}`}{" "}
          </MySessionBottomDataValue>
          <MySessionBottomDataHeader>Total Bet</MySessionBottomDataHeader>
        </MySessionBottomData>
        <MySessionBottomData>
          <MySessionBottomDataValue>
            {tableStatsData.netWin >= 0
              ? `₹${parseDecimalValuesOfNumber(tableStatsData.netWin, 2, true)}`
              : `-₹${parseDecimalValuesOfNumber(
                  -tableStatsData.netWin,
                  2,
                  true
                )}`}
          </MySessionBottomDataValue>
          <MySessionBottomDataHeader>Profit/Loss</MySessionBottomDataHeader>
        </MySessionBottomData>
      </SessionGraphRow>
    );
  };

  return (
    <Container
      onAnimationEnd={() => {
        if (sliderDirection === "close") {
          dispatch(closeSlider());
        }
        dispatch(updateSliderAnimatingFlag({ value: false }));
      }}
      sliderDirection={sliderDirection}
    >
      <Header>
        <HeaderText>Table Statistics</HeaderText>
        <CloseButton src={close} onClick={() => closeSliderProp()} />
      </Header>
      <TablePlayersDetailsContainer>
        <TablePlayersData>
          <TableRow>
            <TablePlayersDataHeaderText textAlign='left'>
              Players
            </TablePlayersDataHeaderText>
            <TablePlayersDataHeaderText>Buy-Ins</TablePlayersDataHeaderText>
            <TablePlayersDataHeaderText textAlign='right'>
              Net Winnings
            </TablePlayersDataHeaderText>
          </TableRow>
          {tableStatsData?.tableStatsListClient?.map((player, index) => {
            return (
              <TableRow key={"table-stats-player-" + index}>
                <TablePlayerDataText textAlign='left'>
                  {getDisplayName(player.playerName, playerDisplayNameMap, loggedPlayer,displayName)}
                </TablePlayerDataText>
                <TablePlayerDataText>
                  {`₹${parseDecimalValuesOfNumber(player.totalBuyIn, 2, true)}`}
                </TablePlayerDataText>
                <TablePlayerDataText
                  textAlign='right'
                  isWinningsText={true}
                  isNegative={player.netWin < 0}
                >
                  {player.netWin >= 0
                    ? `₹${parseDecimalValuesOfNumber(player.netWin, 2, true)}`
                    : `-₹${parseDecimalValuesOfNumber(
                        -player.netWin,
                        2,
                        true
                      )}`}
                </TablePlayerDataText>
              </TableRow>
            );
          })}
        </TablePlayersData>
      </TablePlayersDetailsContainer>
      {playerSeat !== undefined && playerSeat !== null ? (
        <>
          <div style={{ textAlign: "center", margin: "20px 0px" }}>
            <HeaderText style={{ textAlign: "center", margin: "20px 0px" }}>
              My Session Stats
            </HeaderText>
          </div>
          {createMySessionData()}
          {createBottomStatsRow()}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "80%",
            margin: "auto",
            position: "relative",
            top: "25%",
          }}
        >
          <TablePlayerDataText>
            My session stats available only for seated players.
          </TablePlayerDataText>
        </div>
      )}
    </Container>
  );
};

export default TableStatistics;
