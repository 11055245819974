import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";

import {
  Typo10CarnationContoBoldText,
  Typo10PureWhiteContoRegularText,
  Typo10PureWhiteContoMediumText,
  Typo14PureWhiteContoBoldText,
  Typo10SilverChaliceContoMediumText,
  Typo14OffWhiteContoMediumText,
  Typo10OffWhiteContoMediumText,
  Typo10PastelGreenContoBoldText,
  Typo10CloudyContoBoldText,
  Typo11LightYellowContoMediumText,
  Typo10LightYellowContoBoldText,
  Typo10TourneySecondaryGreenMediumText,
  Typo14LightYellowContoBoldText,
  Typo11GrayContoMediumText,
  Typo12PureWhiteContoBoldText,
  Typo11TourneySecondaryGreenMediumText,
  Typo11DoveGrayContoMediumText,
  Typo10GrayContoRegularText,
  Typo10LightYellowContoMediumText,
  Typo12LightYellowContoMediumText,
  Typo16WhiteContoMediumText,
  Typo11PrimaryRedContoMediumText,
  Typo11PrimaryGoldMediumText,
  Typo10SecondaryRedContoMediumText,
} from "../../../Common/styleGuide/Typos";

export const TourneyGameTileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: linear-gradient(180deg, #084e60 2.27%, #023643 102.27%);
  border-radius: 5px;
  width: 100%;
  height: 117px;
  min-height: 117px;
  padding: 10px 12px 10px 12px;
  box-sizing: border-box;
  box-shadow: 0px 2px 1px 0px #00000066;
  margin-bottom: 12px;
  border: 1px solid #33607080;
`;

export const TourneyGameFlightTileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: linear-gradient(180deg, #084e60 2.27%, #023643 102.27%);
  border-radius: 5px;
  width: 100%;
  height: 117px;
  min-height: 117px;
  padding: 10px 12px 10px 12px;
  box-sizing: border-box;
  box-shadow: 0px 2px 1px 0px #00000066;
  margin-bottom: 12px;
  border: 1px solid #33607080;
  box-shadow: ${(props) =>
    props.highlightTile ? " 0px 0px 6px 0px rgba(234, 191, 105, 1)" : ""};
`;

export const TourneyTileHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 22px;
`;

export const TourneyNameAndTypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 75%;
  justify-content: flex-start;
  align-items: center;
`;

export const RegCountAndMaxPlayerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 40%;
  justify-content: flex-start;
  align-items: center;
  margin-right: 12px;
  box-sizing: border-box;
`;

export const TourneyStatusWrapper1 = styled.div`
  display: flex;
  flex-direction: row;
  width: 80px;
  height: 22px;
  background: #053a48;
  border-top-left-radius: 4px;
  justify-content: center;
  align-items: center;
  clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
`;

export const LateRegStatusText = styled(Typo10CarnationContoBoldText)``;

export const TimerText = styled(Typo10PureWhiteContoMediumText)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
`;

export const RegPlayerCountText = styled(Typo10GrayContoRegularText)`
  margin-left: 4px;
  margin-right: 4px;
`;

export const TourneyTileDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 58px;
`;

export const PrizeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 30%;
  height: auto;
  justify-content: center;
  align-items: center;
`;

export const FlightPrizeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
`;

export const BuyInFeeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 30%;
  height: auto;
  justify-content: center;
  align-items: center;
`;

export const WinnersDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 30%;
  height: auto;
  justify-content: center;
  align-items: center;
`;

export const WinnersText = styled(Typo11GrayContoMediumText)``;

export const TrophyIconWrapper = styled.img`
  height: 18px;
  width: 18px;
`;

export const FlightTourneyTrophyIconWrapper = styled.img`
  height: 24px;
  width: 24px;
`;

export const ChipsIconWrapper = styled.img`
  height: 22px;
  width: 22px;
`;

export const WinnerBadgeIcon = styled.img`
  width: 15px;
  height: 18px;
  margin-right: 10px;
`;

export const TourneyDetailsTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
`;

export const TourneyDetailPrimaryText = styled(Typo14LightYellowContoBoldText)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height:22px;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;s
`;

export const TicketsCountText = styled(Typo14LightYellowContoBoldText)`
  margin-right: 2px;
`;

export const TourneyDetailSecondaryText = styled(
  Typo10SilverChaliceContoMediumText
)``;

export const Divider = styled.img`
  width: 2px;
  height: 24px;
`;

export const PlayIconWrapper = styled.img`
  width: 8px;
  height: 6px;
`;

export const TourneyFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 28px;
  justify-content: space-between;
  align-items: center;
  /* border-top: 1px solid #0c6379; */

  box-sizing: border-box;
  padding-top: 4px;
`;

export const TourneyNameText = styled(Typo11LightYellowContoMediumText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
`;

export const TourneyTypeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: 50px;
  height: 22px;
  padding: 4px 12px 4px 12px;
  box-sizing: border-box;
  margin-left: 12px;
  border-radius: 100px;
  opacity: 0px;
  background: #00000080;
`;

export const TourneyTypeText = styled(Typo10LightYellowContoBoldText)`
  white-space: nowrap;
  overflow: hidden;
`;

export const GroupPlayerIconElement = styled.img`
  height: 10px;
  padding-left: 8px;
  padding-right: 4px;
`;

export const BonusBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 60px;
  height: 6px;
  border-radius: 6px;
  /* background: ${colors.tourneyBonusBarPrimary}; */
  background: #101e23;
  margin-left: 4px;
  align-items: center;
`;

export const BonusBarFilled = styled.div`
  display: flex;
  flex-direction: row;
  width: ${(props) =>
    props.fillPercentage ? `${props.fillPercentage}%` : "0%"};
  height: 4px;
  border-radius: 6px;
  /* background: ${colors.tourneyBonusBarFilled}; */
  background: #26923b;

  margin: 1px;
`;

export const StatusText = styled(Typo10PastelGreenContoBoldText)``;

export const MissedStatusText = styled(Typo10CloudyContoBoldText)``;

export const GameTypeIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: flex-start;
  align-items: center;
  margin-right: 6px;
  img:first-child {
    margin-left: 0px;
  }
  img:last-child {
    margin-right: 0px;
  }
`;

export const GameTypeIconElement = styled.img`
  width: 16px;
  height: 16px;
  margin: 0px 4px 0px 4px;
`;

export const TourneyFooterCardIconsAndTypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: flex-end;
  align-items: center;
`;

export const IconsAndDetailsWrapper = styled.div`
  display: flex;
  width: auto;
  justify-content: flex-end;
  align-items: center;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  border-bottom: 2px solid ${colors.tourneySecondaryGreen};
  margin-left: 2px;
`;

export const DetailsText = styled(Typo10TourneySecondaryGreenMediumText)`
  margin-left: 4px;
`;

export const ArrowIcon = styled.img`
  margin-left: 4px;
`;

export const TourneyTileDynamicCtaOrStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 30%;
  height: auto;
  justify-content: flex-end;
  align-items: center;
  .join-back-cta {
    border: 1px solid ${colors.tourneySecondaryGreen};
  }
`;

export const ButtonTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoTicketButtonWrapper = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  span {
    color: ${colors.gray};
  }
`;

export const ButtonPrimaryText = styled(Typo10PureWhiteContoMediumText)`
  margin-right: 4px;
  white-space: nowrap;
`;

export const ButtonSecondaryText = styled(Typo12PureWhiteContoBoldText)``;

export const JoinBackText = styled(Typo11TourneySecondaryGreenMediumText)`
  padding-left: 8px;
  padding-right: 8px;
`;

export const AnnouncedText = styled(Typo11TourneySecondaryGreenMediumText)``;

export const TourneyStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: flex-end;
  align-items: center;
`;

export const TourneyStatusIconWrapper = styled.img`
  margin-right: 6px;
`;

export const CompletedText = styled(Typo11GrayContoMediumText)``;

export const MissedText = styled(Typo11GrayContoMediumText)``;

export const CancelledText = styled(Typo11GrayContoMediumText)``;

export const RegisteredText = styled(Typo11TourneySecondaryGreenMediumText)``;

export const YouWonText = styled(Typo11TourneySecondaryGreenMediumText)``;

export const RegisrationsFullText = styled(Typo11DoveGrayContoMediumText)``;

export const TimeText = styled(Typo10LightYellowContoMediumText)``;

export const DividerWrapper = styled.img``;

export const RegistrationDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  justify-content: flex-end;
  align-items: center;
`;

export const RegStartsText = styled(Typo10GrayContoRegularText)`
  margin-bottom: 6px;
`;

export const RegStartsTimeText = styled(Typo12LightYellowContoMediumText)``;

export const GoldOpenIcon = styled.span`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: ${colors.secondaryGreen} !important;
  margin-right: 4px;
  opacity: 1;
  animation: fade 2800ms linear infinite;

  @keyframes fade {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
`;

export const TicketIcon = styled.img`
  margin-left: 4px;
`;

export const MediumTicketIcon = styled.img`
  /* margin-top: 4px; */
`;

export const AddIconText = styled(Typo16WhiteContoMediumText)`
  font-weight: 700;
  margin-left: 4px;
  margin-right: 4px;
`;

export const LateRegRunningTimerWrapper = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${colors.dainTree};
  width: 50px;
  height: 22px;
  border-radius: 12px;
  margin-left: 8px;
`;

export const LateRegTimerText = styled(Typo10SecondaryRedContoMediumText)``;
