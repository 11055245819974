//TourneyFlightQualifiedPlayersAckHandler.js

import Handler from "../Handler";

class TourneyFlightQualifiedPlayersAckHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processFlightQualifiedPlayersAck(receivedData);
  }
}
export default TourneyFlightQualifiedPlayersAckHandler;

// FlightQualifiedPlayersA#{List of ranks} --> Response
