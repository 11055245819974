import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import goldGloryIcon from "../../../assets/lobby/goldGloryIcon.png";
import tourneyVerticalSeperator from "../../../assets/lobby/TourneyVerticalSeperator.svg";
import tourneyHorizontalSeperator from "../../../assets/lobby/TourneyHorizontalSeperator.svg";
import CloseIcon from "../../../assets/lobby/CloseIcon.svg";
import Subtract from "../../../assets/gameTable/Subtract.svg";
import maskPlayerIcon from "../../../assets/lobby/MaskPlayerIcon.png";
import FirstWinner from "../../../assets/lobby/FirstWinner.png";
import SecondWinner from "../../../assets/lobby/SecondWinner.png";
import ThirdWinner from "../../../assets/lobby/ThirdWinner.png";

import GrayAccordianIcon from "../../../assets/lobby/GrayAccordianIcon.svg";
import goldTicket from "../../../assets/lobby/GoldTicket.svg";
import breakCoffeeIcon from "../../../assets/lobby/BreakCoffeeIcon.png";
import RightLeafyGreenArrow from "../../../assets/lobby/RightLeafyGreenArrow.png";
import grayInfoIcon from "../../../assets/lobby/GrayInfoIcon.png";
import GroupGoldIcon from "../../../assets/lobby/GroupGoldIcon.png";
import GoldRoundShimmer from "../../../assets/lobby/GoldRoundShimmer.png";
import FlightTickets from "../../../assets/lobby/FlightTickets.png";

import {
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";
import { ScrollDownArrow } from "../../../Common/components/ScrollDownArrow/ScrollDownArrow";

import {
  SIT_AND_GO_TOURNEYS,
  TICKETS,
  TICKET_BASED,
  TIMER_TOURNEYS,
  TOURNEY_ANNOUNCED,
  TOURNEY_INFO_ENTRIES_TAB,
  TOURNEY_INFO_FLIGHTS_TAB,
  TOURNEY_PLAYER_STATUS_CANCELLED,
  TOURNEY_PLAYER_STATUS_COMPLETED,
  TOURNEY_PLAYER_STATUS_MISSED,
  TOURNEY_REGISTERED,
  TOURNEY_REGISTRATION_CLOSE_BEFORE_START_IN_SECONDS,
  TOURNEY_REGISTRATION_ENDED,
  TOURNEY_REGISTRATION_STARTED,
  TOURNEY_STATUS_CANCELLED,
  TOURNEY_STATUS_COMPLETED,
  TOURNEY_STATUS_LIVE,
  TOURNEY_STATUS_MISSED,
  TOURNEY_STATUS_WON,
} from "../../data/Constants";
import {
  updateTourneyInBreak,
  updateTourneyRegistrationCTAButtonsHide,
} from "../../redux/slices/lobbySlice";

import TourneyTileCard from "../TourneyTileCard/TourneyTileCard";

import {
  TourneyDetailsContainer,
  TourneyDetailsWrapper,
  TourneyStartsInAndPrizeAmountWrapper,
  PrizeAmountContainer,
  TrophyIconWrapper,
  PrizeAmountWrapper,
  PrizeWorthText,
  PrizeAmountText,
  PrizeBreakupText,
  TourneyDetailTable,
  TourneyBuyInAndWinnersWrapper,
  BuyInWrapper,
  BuyInText,
  BuyInAmountText,
  VerticalSeperator,
  PlayersWrapper,
  PlayersText,
  PlayersCountText,
  WinnersWrapper,
  WinnersText,
  WinnersCountText,
  HorizontalSeperator,
  TotalAddonsAndRebuyWrapper,
  TableItemText,
  AddOnsAndRebuyItemWrapper,
  AddOnAndRebuyAmountDetailWrapper,
  RebuyAmountText,
  UnlimitedRebuysText,
  ViewBlindStructureText,
  LateRegistrationWrapper,
  GoldOpenIcon,
  LateRegistrationText,
  TourneyStatsContainer,
  TourneyStatsText,
  TourneyStatItemWrapper,
  TourneyStatItemName,
  TourneyStatItemValue,
  ModalContainer,
  PrizeBreakUpModalContainer,
  PrizeBreakupHeader,
  PrizeBreakupHeaderText,
  PrizeBreakupBodyWrapper,
  CloseIconWrapper,
  TableWrapper,
  TableHeaderWrapper,
  HeaderItemText,
  TableRow,
  TableRowItemText,
  BlindStructureContainer,
  BlindStructureHeader,
  BlindStructureText,
  BlindStructureBodyWrapper,
  TourneyRulesContainer,
  RulesListOrderedWrapper,
  RulesListItemWrapper,
  DescriptionLabelText,
  AccordianIcon,
  MaxRunTimeText,
  SitAndGoMessageText,
  ChildTourneysText,
  ChildTourneysContainer,
  ViewMoreOrLessWrapper,
  ExpansionPanelText,
  ExpansionAccordianIcon,
  TicketIcon,
  AddIconText,
  ParentTourneysText,
  ParentTourneysContainer,
  BlindStructureHeaderItemText,
  BlindStructureTableRow,
  BlindStructureTableHeaderWrapper,
  BlindStructureTableRowItemText,
  TicketsCountText,
  RebuyAmountValue,
  DynamicPayoutStructureText,
  TourneyDetailsTableHeader,
  TourneyDetailsTableBodyWrapper,
  TourneyDetailsText,
  BlindsRightArrow,
  TourneyStatsTableBodyWrapper,
  TourneyStatsTableHeader,
  TourneyTimersContainer,
  TourneyTimersWrapper,
  CurrentTimerTitle,
  RunningTimeWrapper,
  TimePeriodAndPeriodNameWrapper,
  TimePeriodWrapper,
  PeriodNameText,
  PeriodValueText,
  LateRegistrationTimerText,
  GreenActiveIcon,
  ActivePlayersCountDetails,
  ActivePlayerCountText,
  RemainingText,
  ReEntriesWrapper,
  ReEntriesText,
  ReEntryCountText,
  TourneyOnBreakContainer,
  CoffeeIconWrapper,
  TourneyBreakMessageText,
  BreakTimeText,
  PlayerIconWrapper,
  DepositorTourneyEntriesWrapper,
  DirectEntriesWrapper,
  GrayInfoIconWrapper,
  TicketEntriesWrapper,
  DirectEntriesAndTicketEntriesCountText,
  TooltipText,
  TooltipArrow,
  TooltipWrapper,
  FlightTourneyStartsInAndPrizeAmountWrapper,
  TourneyWinnersAndGameTypeContainer,
  GameTypeIconWrapper,
  GameTypeWrapper,
  GameTypeAndPlayersWrapper,
  MaxPlayersIcon,
  FlightTourneySimilarFlightsContainer,
  FlightTourneyParentTourneyContainer,
  IconWrapper,
  RotatingIcon,
  CenterIcon,
  SimilarFlightsText,
  IconAndTextWrapper,
  ParentTourneyText,
  TourneyRulesAccordianContainer,
  TourneyRulesHeadingText,
  TourneyRulesBodyWrapper,
  ParentPrizeWorthText,
  TourneyWinnersContainer,
  TourneyConcludedText,
  WinnersListWrapper,
  WinnerWrapper,
  WinnerIconWrapper,
  WinnerUserNameText,
  WinnerPrizeAmountText,
  FirstWinnerIconWrapper,
  ViewAllWinnersText,
  TourneyStackDetailsWrapper,
  StackLabelText,
  StackValueText,
  FlightTourneyWinnersAndGameTypeContainer,
  GameTypeAndMaxPlayersContainer,
  NextPrizeJumpAmountText,
  WinnerIconContainer,
} from "./tourneyDetailsStyles";
import TourneyFlightParentAndSimilarFlightsCard from "../TourneyFlightParentAndSimilarFlightsCard/TourneyFlightParentAndSimilarFlightsCard";
import { returnGameTypeText } from "../../../Common/utils/PokerUtils";
import moment from "moment";
import TourneyFlightsPlaceholder from "../TourneyFlightsPlaceholder/TourneyFlightsPlaceholder";

function TourneyDetails(props) {
  const {
    buyIn,
    tourneyRake,
    registeredPlayerCount,
    maxPlayers,
    displayWinners,
    totalAddOns,
    addOnAmount,
    rebuyFee,
    startingStack,
    playersPerTable,
    prizePool,
    currentLevel,
    nextLevel,
    nextBreak,
    highestStack,
    blindStructure,
    tourneyStartTime,
    tourneyStatus,
    lateRegistrationAllowed,
    maxRebuyCount,
    tourneyRules,
    winnersPayoutWithBonus,
    breakEven,
    dynamicPoolPrize,
    tournamentType,
    lateRegistrationDuration,
    rebuyLevel,
    childTourneys,
    prizePoolTickets,
    lateRegEnded,
    reEntry,
    parentTourney,
    levelDuration,
    totalAddOnCount,
    totalRebuyCount,
    totalReEntryCount,
    isAlreadyRegisteredTourney,
    tourneyId,
    prizePoolType,
    payoutType,
    hideRegistrationCTAButtons,
    tourneyIdentifier,
    activePlayerForTourney,
    breakInProgress,
    breakEndTime,
    averageChips,
    lateRegEndTime,
    depositTourneyFlag,
    allowedFreeRegistrations,
    allowedTicketRegistrations,
    isFlightTourney,
    isFlightParentTourney,
    similarFlights,
    parentTourneyName,
    blindStructureEndLevel,
    startingChipsCount,
    gameType,
    maxAllowedPlayers,
    qualifiedPlayerCount,
    lowestStack,
    activeTables,
    nextPrizeAmount,
    nextPrizeJumpRank,
    currentAvailableFlights,
    flightXThreshold,
    blindStructureLevel,
    tourneyEndTime,
    flightTotalReEntries,
    flightsTotalEntries,
    allFlightsEnded,
    currentPrizeAmount,
  } = props.tourneyDetails;
  const dispatch = useDispatch();
  const masterTourneysData = useSelector(
    (state) => state.lobby.tourneys.masterTourneysData
  );

  const currentLobbyTime = useSelector((state) => state.lobby.currentLobbyTime);
  const [openPrizeBreakupModal, setOpenPrizeBreakupModal] = useState(false);
  const [openBlindStructure, setOpenBlindStructure] = useState(false);
  const [remainingStartTime, setRemainingStartTime] = useState(-1);

  const [timerId, setTimerId] = useState(null);
  const [isRulesExpansionPanelOpen, setIsRulesExpansionPanelOpen] =
    useState(false);
  const [isChildTourneysExpansionOpened, setIsChildTourneysExpansionOpened] =
    useState(false);
  const [isTourneyRulesAccordianOpened, setIsTourneyRulesAccordianOpened] =
    useState(false);
  const [showDirectEntriesTooltip, setShowDirectEntriesTooltip] =
    useState(false);

  const [showTicketEntriesTooltip, setShowTicketEntriesTooltip] =
    useState(false);
  const [tooltipTimeout, setToolTipTimeout] = useState(null);

  const [lateRegEndTimestamp, setLateRegEndTimestamp] = useState(0);
  const [breakTimeInSeconds, setBreakTimeInSeconds] = useState(breakEndTime);

  const lateRegEndTimerIntervalRef = useRef(null);
  const breakTimeIntervalRef = useRef(null);

  useEffect(() => {
    handleLateRegisterTimer();
    if (tourneyStatus === TOURNEY_STATUS_LIVE) {
      handleTourneyStatusLiveTimer();
    } else {
      handleTourneyRemainingTimeToStartTimer();
    }

    if (getChildTourneysDetails().length < 2) {
      setIsChildTourneysExpansionOpened(true);
    }

    return () => {
      clearInterval(timerId);
      if (lateRegEndTimerIntervalRef?.current) {
        clearInterval(lateRegEndTimerIntervalRef.current);
        lateRegEndTimerIntervalRef.current = null;
      }
      if (breakTimeIntervalRef?.current) {
        clearInterval(breakTimeIntervalRef.current);
        breakTimeIntervalRef.current = null;
      }
      clearRunningTooltipTimer();
    };
  }, []);

  useEffect(() => {
    handleTourneyBreakTimer();
  }, [breakInProgress]);

  useEffect(() => {
    if (tourneyStatus === TOURNEY_STATUS_LIVE) {
      getRunningTimeInSeconds();
    } else {
      if (
        remainingStartTime > 0 &&
        remainingStartTime <
          TOURNEY_REGISTRATION_CLOSE_BEFORE_START_IN_SECONDS &&
        !hideRegistrationCTAButtons
      ) {
        dispatch(updateTourneyRegistrationCTAButtonsHide({ isHidden: true }));
      }
      getRemainingTimeInSeconds();
    }
  }, [remainingStartTime]);

  useEffect(() => {
    if (
      tourneyStatus === TOURNEY_STATUS_LIVE &&
      !lateRegEnded &&
      lateRegEndTime > 0
    ) {
      runLateRegisterEndsInTimer();
    }

    // Cleanup the interval on unmount or when dependencies change
    return () => {
      if (lateRegEndTimerIntervalRef.current) {
        clearInterval(lateRegEndTimerIntervalRef.current);
      }
    };
  }, [tourneyStatus, lateRegEnded, lateRegEndTime]);

  useEffect(() => {
    if (
      breakInProgress &&
      breakTimeInSeconds > 0 &&
      !breakTimeIntervalRef?.current
    ) {
      runBreakEndsInTimer();
    }
    if (breakTimeInSeconds <= 0) {
      if (breakTimeIntervalRef.current) {
        clearInterval(breakTimeIntervalRef.current);
        breakTimeIntervalRef.current = null;
      }
      dispatch(
        updateTourneyInBreak({
          breakInProgress: false,
          breakStartTime: 0,
          breakEndTime: 0,
        })
      );
    }
  }, [breakTimeInSeconds]);

  useEffect(() => {
    if (tourneyStatus === TOURNEY_STATUS_LIVE) {
      if (timerId) {
        clearInterval(timerId);
        setTimerId(null);
      }
      handleTourneyStatusLiveTimer();
      if (
        tourneyStatus === TOURNEY_STATUS_LIVE &&
        !lateRegEnded &&
        lateRegEndTime > 0
      ) {
        handleLateRegisterTimer();
        runLateRegisterEndsInTimer();
      }
    } else if (!timerId) {
      handleTourneyRemainingTimeToStartTimer();
    }
  }, [tourneyStatus]);

  const getTourneyDefinitionByTourneyId = (tourneyId) => {
    const tourney = masterTourneysData.get(tourneyId);
    return tourney ? tourney : false;
  };

  const handleTourneyStatusLiveTimer = () => {
    const difference = currentLobbyTime - new Date(tourneyStartTime * 1000);
    setRemainingStartTime(Math.floor(difference / 1000));
  };

  const handleTourneyRemainingTimeToStartTimer = () => {
    const difference = new Date(tourneyStartTime * 1000) - currentLobbyTime;
    setRemainingStartTime(Math.floor(difference / 1000));
  };

  const handleTourneyBreakTimer = () => {
    const difference = new Date(breakEndTime * 1000) - currentLobbyTime;
    setBreakTimeInSeconds(Math.floor(difference / 1000));
  };

  const handleLateRegisterTimer = () => {
    if (lateRegEndTime > 0 && tourneyStatus === TOURNEY_STATUS_LIVE) {
      const difference = new Date(lateRegEndTime * 1000) - currentLobbyTime;
      setLateRegEndTimestamp(Math.floor(difference / 1000));
    } else {
      const difference =
        new Date(lateRegEndTime * 1000) - new Date(tourneyStartTime * 1000);
      setLateRegEndTimestamp(Math.floor(difference / 1000));
    }
  };

  const getRemainingTimeInSeconds = () => {
    if (!timerId) {
      let intervalTimerId = setInterval(() => {
        setRemainingStartTime((prev) => prev - 1);
      }, 1000);
      setTimerId(intervalTimerId);
    }
  };

  const getRunningTimeInSeconds = () => {
    if (!timerId) {
      let intervalTimerId = setInterval(() => {
        setRemainingStartTime((prev) => prev + 1);
      }, 1000);
      setTimerId(intervalTimerId);
    }
  };

  const formatTourneyDate = (timeStamp, formatString = "DD MMM, hh:mmA") => {
    try {
      return moment.unix(timeStamp).format(formatString);
    } catch (error) {
      console.warn("ERROR IN FORMATTING DATE IN TOURNEY GAME TILE:", error);
    }
  };

  const handleToolTipTimer = () => {
    let tooltipTimer = setTimeout(() => {
      setShowDirectEntriesTooltip(false);
      setShowTicketEntriesTooltip(false);
      clearRunningTooltipTimer();
    }, 3000);
    setToolTipTimeout(tooltipTimer);
  };

  const clearRunningTooltipTimer = () => {
    if (tooltipTimeout) {
      clearTimeout(tooltipTimeout);
      setToolTipTimeout(null);
    }
  };

  const runLateRegisterEndsInTimer = () => {
    // Clear any existing interval
    if (lateRegEndTimerIntervalRef.current) {
      clearInterval(lateRegEndTimerIntervalRef.current);
    }

    // Start a new interval
    lateRegEndTimerIntervalRef.current = setInterval(() => {
      setLateRegEndTimestamp((prev) => {
        if (prev <= 1) {
          clearInterval(lateRegEndTimerIntervalRef.current);
          lateRegEndTimerIntervalRef.current = null;
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const runBreakEndsInTimer = () => {
    if (!breakTimeIntervalRef?.current) {
      breakTimeIntervalRef.current = setInterval(() => {
        setBreakTimeInSeconds((prev) => prev - 1);
      }, 1000);
    }
  };
  const convertTimeToMinutesAndSeconds = (timeInSeconds, showUnits = false) => {
    if (timeInSeconds > 0) {
      if (timeInSeconds < 60) {
        //seconds
        return showUnits ? `${timeInSeconds} sec` : timeInSeconds;
      } else if (timeInSeconds >= 60) {
        //Minutes
        let mins = Math.floor(timeInSeconds / 60);
        return showUnits ? `${mins} min` : mins;
      }
    } else {
      return 0;
    }
  };

  const getRunningTimerDetails = (runningTime) => {
    const timePeriods = [];
    if (runningTime >= 0) {
      if (runningTime === 0) {
        timePeriods.push(
          ...[
            { timePeriod: "00", periodName: "Hrs" },
            { timePeriod: "00", periodName: "Min" },
            { timePeriod: "00", periodName: "Sec" },
          ]
        );
      } else if (runningTime < 60) {
        timePeriods.push(
          ...[
            { timePeriod: "00", periodName: "Hrs" },
            { timePeriod: "00", periodName: "Min" },
            {
              timePeriod: `${
                runningTime > 9 ? runningTime : `0${runningTime}`
              }`,
              periodName: "Sec",
            },
          ]
        );
      } else if (runningTime >= 60 && runningTime < 3600) {
        let mins = Math.floor((runningTime % 3600) / 60);
        let secs = runningTime % 60;
        timePeriods.push(
          ...[
            { timePeriod: "00", periodName: "Hrs" },
            {
              timePeriod: `${mins > 9 ? mins : `0${mins}`}`,
              periodName: "Min",
            },
            {
              timePeriod: `${secs > 9 ? secs : `0${secs}`}`,
              periodName: "Sec",
            },
          ]
        );
      } else if (runningTime >= 3600 && runningTime < 86400) {
        let hrs = Math.floor(runningTime / 3600);
        let mins = Math.floor((runningTime % 3600) / 60);
        let secs = runningTime % 60;
        timePeriods.push(
          ...[
            {
              timePeriod: `${hrs > 9 ? hrs : `0${hrs}`}`,
              periodName: "Hrs",
            },
            {
              timePeriod: `${mins > 9 ? mins : `0${mins}`}`,
              periodName: "Min",
            },
            {
              timePeriod: `${secs > 9 ? secs : `0${secs}`}`,
              periodName: "Sec",
            },
          ]
        );
      } else if (runningTime > 86400) {
        let days = Math.floor(runningTime / 86400);
        let hrs = Math.floor((runningTime % 86400) / 3600);
        let mins = Math.floor(((runningTime % 86400) % 3600) / 60);
        timePeriods.push(
          ...[
            {
              timePeriod: `${days > 9 ? days : `0${days}`}`,
              periodName: "Days",
            },
            {
              timePeriod: `${hrs > 9 ? hrs : `0${hrs}`}`,
              periodName: "Hrs",
            },
            {
              timePeriod: `${mins > 9 ? mins : `0${mins}`}`,
              periodName: "Min",
            },
          ]
        );
      }
    } else if (runningTime <= 0) {
      timePeriods.push(
        ...[
          { timePeriod: "00", periodName: "Hrs" },
          { timePeriod: "00", periodName: "Min" },
          { timePeriod: "00", periodName: "Sec" },
        ]
      );
    }

    return timePeriods.map((eachTimePeriod) => (
      <TimePeriodAndPeriodNameWrapper key={eachTimePeriod.periodName}>
        <TimePeriodWrapper>
          <PeriodValueText>{eachTimePeriod.timePeriod}</PeriodValueText>
        </TimePeriodWrapper>
        <PeriodNameText>{eachTimePeriod.periodName}</PeriodNameText>
      </TimePeriodAndPeriodNameWrapper>
    ));
  };

  const handleTourneyNavigation = () => {
    props.handleTourneyNavigation &&
      props.handleTourneyNavigation(parentTourney);
  };

  const handleOpenPrizeBreakupModal = () => {
    setOpenPrizeBreakupModal(true);
  };

  const navigateToFlightsTab = () => {
    props.handleTourneysTabChange(TOURNEY_INFO_FLIGHTS_TAB);
  };

  const handleClosePrizeBreakup = () => {
    setOpenPrizeBreakupModal(false);
  };

  const handleOpenBlindStructure = () => {
    setOpenBlindStructure(true);
  };

  const handleCloseBlindStructure = () => {
    setOpenBlindStructure(false);
  };

  const handleRulesAccordian = () => {
    setIsRulesExpansionPanelOpen(!isRulesExpansionPanelOpen);
  };

  const getChildTourneysDetails = () => {
    let childTourneysList = [];
    childTourneys.forEach((eachTourneyId) => {
      const childTourneyDetails =
        getTourneyDefinitionByTourneyId(eachTourneyId);
      if (childTourneyDetails) {
        childTourneysList.push({ ...childTourneyDetails });
      }
    });
    const sortedTourneysList = [...childTourneysList].sort(
      (currentTourney, nextTourney) => {
        // Check if either or both tourneys are CANCELLED
        const isCurrentCancelled =
          currentTourney.tourneyStatus === TOURNEY_STATUS_CANCELLED;
        const isNextCancelled =
          nextTourney.tourneyStatus === TOURNEY_STATUS_CANCELLED;

        // If only one is CANCELLED, move it to the end
        if (isCurrentCancelled && !isNextCancelled) return 1;
        if (!isCurrentCancelled && isNextCancelled) return -1;
        // Otherwise, sort by tourneyStartTime (descending)
        return nextTourney.tourneyStartTime - currentTourney.tourneyStartTime;
      }
    );
    return sortedTourneysList;
  };

  const renderTourneyRules = (hideRulesTitleAndAccordian = false) => {
    let tourneyRulesList = tourneyRules?.split("\n");
    if (tourneyRulesList?.length > 0) {
      let rules = tourneyRulesList.map((eachRule, index) => (
        <RulesListItemWrapper key={`eachRule-${index}`}>
          {eachRule}
        </RulesListItemWrapper>
      ));

      return (
        <>
          <TourneyRulesContainer
            isOpen={
              hideRulesTitleAndAccordian
                ? hideRulesTitleAndAccordian
                : isRulesExpansionPanelOpen
            }
            rulesLength={tourneyRulesList.length}
          >
            {!hideRulesTitleAndAccordian && (
              <DescriptionLabelText>Tourney Rules:</DescriptionLabelText>
            )}
            <RulesListOrderedWrapper>{rules}</RulesListOrderedWrapper>
          </TourneyRulesContainer>
          {/* Hiding the accordian for the shorter content which height is less thanb 92px , here 28px is consider as each item average height */}
          {!hideRulesTitleAndAccordian && tourneyRulesList.length * 28 > 92 && (
            <AccordianIcon
              onClick={handleRulesAccordian}
              isOpen={isRulesExpansionPanelOpen}
              src={Subtract}
            />
          )}
        </>
      );
    } else {
      return null;
    }
  };

  const handleDirectEntriesInfoIconClick = () => {
    if (tooltipTimeout !== null) {
      clearRunningTooltipTimer();
      setShowTicketEntriesTooltip(false);
    }
    setShowDirectEntriesTooltip(true);
    handleToolTipTimer();
  };

  const handleTicketEntriesInfoIconClick = () => {
    if (tooltipTimeout !== null) {
      clearRunningTooltipTimer();
      setShowDirectEntriesTooltip(false);
    }
    setShowTicketEntriesTooltip(true);
    handleToolTipTimer();
  };

  const getCurrentLevelDisplayValue = (selectedLevel = 0) => {
    if (typeof selectedLevel === "string" && selectedLevel.includes("/")) {
      selectedLevel = Number(selectedLevel.split("/")[0]);
    }
    return selectedLevel;
  };

  const getSelectedLevelBlindStructure = (selectedLevel = 0) => {
    let currentBlindStructure =
      isFlightTourney && !isFlightParentTourney
        ? getTourneyDefinitionByTourneyId(parentTourney)?.blindStructure ?? []
        : blindStructure ?? [];
    let selectedBlindDetails = currentBlindStructure.find(
      (eachLevel) => eachLevel.level === selectedLevel
    );
    return selectedBlindDetails
      ? `(${parseDecimalValuesOfNumber(
          selectedBlindDetails.sb,
          2,
          checkToDisplayValueInK(selectedBlindDetails.sb),
          checkToDisplayValueInL(selectedBlindDetails.sb)
        )}/${parseDecimalValuesOfNumber(
          selectedBlindDetails.bb,
          2,
          checkToDisplayValueInK(selectedBlindDetails.bb),
          checkToDisplayValueInL(selectedBlindDetails.bb)
        )})`
      : "";
  };

  const renderFlightTourneyDetailsSection = () => {
    return (
      <TourneyDetailTable>
        <TourneyDetailsTableHeader>
          <TourneyDetailsText>Tourney Details</TourneyDetailsText>

          <ViewBlindStructureText onClick={handleOpenBlindStructure}>
            Blind Structure
            <BlindsRightArrow src={RightLeafyGreenArrow} />
          </ViewBlindStructureText>
        </TourneyDetailsTableHeader>
        <TourneyDetailsTableBodyWrapper>
          <TourneyBuyInAndWinnersWrapper>
            <BuyInWrapper>
              <BuyInText>Buy-In</BuyInText>
              <BuyInAmountText isFlightParent={isFlightParentTourney}>
                {isFlightParentTourney
                  ? "Qualification only"
                  : tournamentType === TICKET_BASED
                  ? TICKETS
                  : buyIn + tourneyRake}
              </BuyInAmountText>
            </BuyInWrapper>
            <VerticalSeperator src={tourneyVerticalSeperator} />
            <PlayersWrapper>
              <PlayersText>Entries</PlayersText>
              {isFlightTourney ? (
                <PlayersCountText>{`${parseDecimalValuesOfNumber(
                  registeredPlayerCount,
                  2,
                  checkToDisplayValueInK(registeredPlayerCount),
                  checkToDisplayValueInL(registeredPlayerCount)
                )}`}</PlayersCountText>
              ) : (
                <PlayersCountText>{`${parseDecimalValuesOfNumber(
                  registeredPlayerCount,
                  2,
                  checkToDisplayValueInK(registeredPlayerCount),
                  checkToDisplayValueInL(registeredPlayerCount)
                )}/${parseDecimalValuesOfNumber(
                  maxPlayers,
                  2,
                  checkToDisplayValueInK(maxPlayers),
                  checkToDisplayValueInL(maxPlayers)
                )}`}</PlayersCountText>
              )}
              {(tourneyStatus === TOURNEY_STATUS_LIVE ||
                tourneyStatus === TOURNEY_STATUS_MISSED) &&
              tourneyIdentifier !== "completedTourneys" &&
              activePlayerForTourney > 0 ? (
                <ActivePlayersCountDetails>
                  <PlayerIconWrapper src={maskPlayerIcon} />
                  <ActivePlayerCountText>
                    {`${parseDecimalValuesOfNumber(
                      activePlayerForTourney,
                      2,
                      checkToDisplayValueInK(activePlayerForTourney),
                      checkToDisplayValueInL(activePlayerForTourney)
                    )}`}
                  </ActivePlayerCountText>
                  <RemainingText>Active</RemainingText>
                </ActivePlayersCountDetails>
              ) : null}
            </PlayersWrapper>
            {totalReEntryCount > 0 && (
              <>
                <VerticalSeperator src={tourneyVerticalSeperator} />
                <ReEntriesWrapper>
                  <ReEntriesText>Re-entries</ReEntriesText>
                  <ReEntryCountText>
                    {totalReEntryCount > 0 ? totalReEntryCount : "-"}
                  </ReEntryCountText>
                </ReEntriesWrapper>
              </>
            )}
            {/* <VerticalSeperator src={tourneyVerticalSeperator} />
                <WinnersWrapper>
                  <WinnersText>Winners</WinnersText>
                  <WinnersCountText>
                    {parseDecimalValuesOfNumber(
                      displayWinners,
                      2,
                      checkToDisplayValueInK(displayWinners),
                      checkToDisplayValueInL(displayWinners)
                    )}
                  </WinnersCountText>
                </WinnersWrapper> */}
          </TourneyBuyInAndWinnersWrapper>

          {lowestStack > 0 && (
            <TourneyBuyInAndWinnersWrapper>
              <TourneyStackDetailsWrapper>
                <StackLabelText>Smallest Stack</StackLabelText>
                <StackValueText>
                  {`${parseDecimalValuesOfNumber(
                    lowestStack,
                    2,
                    checkToDisplayValueInK(lowestStack),
                    checkToDisplayValueInL(lowestStack)
                  )}`}
                </StackValueText>
              </TourneyStackDetailsWrapper>
              <VerticalSeperator src={tourneyVerticalSeperator} />
              <TourneyStackDetailsWrapper>
                <StackLabelText>Average Stack</StackLabelText>
                <StackValueText>{`${parseDecimalValuesOfNumber(
                  averageChips,
                  2,
                  checkToDisplayValueInK(averageChips),
                  checkToDisplayValueInL(averageChips)
                )}`}</StackValueText>
              </TourneyStackDetailsWrapper>
              <VerticalSeperator src={tourneyVerticalSeperator} />
              <TourneyStackDetailsWrapper>
                <StackLabelText>Largest Stack</StackLabelText>
                <StackValueText>{`${parseDecimalValuesOfNumber(
                  highestStack,
                  2,
                  checkToDisplayValueInK(highestStack),
                  checkToDisplayValueInL(highestStack)
                )}`}</StackValueText>
              </TourneyStackDetailsWrapper>
            </TourneyBuyInAndWinnersWrapper>
          )}
          {/* {(totalAddOns > 0 ||
                maxRebuyCount > 0 ||
                reEntry ||
                depositTourneyFlag) && (
                <HorizontalSeperator src={tourneyHorizontalSeperator} />
              )}
              <TotalAddonsAndRebuyWrapper>
                {depositTourneyFlag && (
                  <DepositorTourneyEntriesWrapper>
                    <DirectEntriesWrapper>
                      <TableItemText>Direct Entries</TableItemText>
                      {showDirectEntriesTooltip && (
                        <TooltipWrapper>
                          <TooltipText>
                            No of direct registrations allowed
                          </TooltipText>
                          <TooltipArrow></TooltipArrow>
                        </TooltipWrapper>
                      )}
                      <GrayInfoIconWrapper
                        src={grayInfoIcon}
                        onClick={handleDirectEntriesInfoIconClick}
                      />
                      <DirectEntriesAndTicketEntriesCountText>
                        {allowedFreeRegistrations}
                      </DirectEntriesAndTicketEntriesCountText>
                    </DirectEntriesWrapper>
                    <TicketEntriesWrapper>
                      <TableItemText>Ticket Entries</TableItemText>
                      {showTicketEntriesTooltip && (
                        <TooltipWrapper tooltipPosition={"left"}>
                          <TooltipText>
                            No of tickets issues through add cash
                          </TooltipText>
                          <TooltipArrow tooltipPosition={"left"}></TooltipArrow>
                        </TooltipWrapper>
                      )}
                      <GrayInfoIconWrapper
                        src={grayInfoIcon}
                        onClick={handleTicketEntriesInfoIconClick}
                      />
                      <DirectEntriesAndTicketEntriesCountText>
                        {allowedTicketRegistrations}
                      </DirectEntriesAndTicketEntriesCountText>
                    </TicketEntriesWrapper>
                  </DepositorTourneyEntriesWrapper>
                )}
                {totalAddOns > 0 && (
                  <AddOnsAndRebuyItemWrapper>
                    <TableItemText>
                      {`Total Add-ons`}
                      <RebuyAmountValue>{totalAddOnCount} </RebuyAmountValue>
                    </TableItemText>
                    <AddOnAndRebuyAmountDetailWrapper>
                      <TableItemText>Add-on fee </TableItemText>
                      <RebuyAmountText>{`₹${addOnAmount}`}</RebuyAmountText>
                    </AddOnAndRebuyAmountDetailWrapper>
                  </AddOnsAndRebuyItemWrapper>
                )}
                {maxRebuyCount > 0 && (
                  <AddOnsAndRebuyItemWrapper>
                    <TableItemText>
                      {`Total Re-buys`}
                      <RebuyAmountValue>{`${totalRebuyCount}`}</RebuyAmountValue>
                    </TableItemText>
                    <AddOnAndRebuyAmountDetailWrapper>
                      <TableItemText>Re-buy fees</TableItemText>
                      <RebuyAmountValue>{`₹${rebuyFee}`}</RebuyAmountValue>
                    </AddOnAndRebuyAmountDetailWrapper>
                  </AddOnsAndRebuyItemWrapper>
                )}
                {reEntry && (
                  <AddOnsAndRebuyItemWrapper>
                    <TableItemText>{`Total Re-Entries  ${totalReEntryCount}`}</TableItemText>
                    <AddOnAndRebuyAmountDetailWrapper>
                      <TableItemText>Re-entry fees</TableItemText>
                      <RebuyAmountText>{`₹${reEntry.reEntrySettings.reentryEntryFee}`}</RebuyAmountText>
                    </AddOnAndRebuyAmountDetailWrapper>
                  </AddOnsAndRebuyItemWrapper>
                )}
              </TotalAddonsAndRebuyWrapper>
              {maxRebuyCount > 0 && (
                <UnlimitedRebuysText>
                  {`${maxRebuyCount} re-buys allowed till Level ${rebuyLevel}`}
                </UnlimitedRebuysText>
              )}
              {reEntry && (
                <UnlimitedRebuysText>
                  {`${reEntry.reEntrySettings.noOfReentry} re-entries allowed till Level ${reEntry.reEntrySettings.reentryLevel}`}
                </UnlimitedRebuysText>
              )} */}
        </TourneyDetailsTableBodyWrapper>
        <TourneyStatsTableBodyWrapper>
          {isFlightParentTourney && currentAvailableFlights === 0 && (
            <>
              <TourneyStatItemWrapper>
                <TourneyStatItemName>Total Prize Pool</TourneyStatItemName>
                <TourneyStatItemValue>{`₹${parseDecimalValuesOfNumber(
                  prizePool,
                  2,
                  checkToDisplayValueInK(prizePool),
                  checkToDisplayValueInL(prizePool)
                )}`}</TourneyStatItemValue>
              </TourneyStatItemWrapper>
              <HorizontalSeperator src={tourneyHorizontalSeperator} />
            </>
          )}
          {isFlightParentTourney &&
            tourneyIdentifier === "completedTourneys" && (
              <>
                <TourneyStatItemWrapper>
                  <TourneyStatItemName>Total Winners</TourneyStatItemName>
                  <TourneyStatItemValue>
                    {registeredPlayerCount}
                  </TourneyStatItemValue>
                </TourneyStatItemWrapper>
                <HorizontalSeperator src={tourneyHorizontalSeperator} />
              </>
            )}
          {flightTotalReEntries > 0 && flightsTotalEntries > 0 && (
            <>
              <TourneyStatItemWrapper>
                <TourneyStatItemName>Total Entries</TourneyStatItemName>
                <TourneyStatItemValue>{`${
                  flightTotalReEntries + flightsTotalEntries
                } (${flightsTotalEntries} Unique)`}</TourneyStatItemValue>
              </TourneyStatItemWrapper>
              <HorizontalSeperator src={tourneyHorizontalSeperator} />
            </>
          )}
          {isFlightParentTourney &&
            tourneyIdentifier !== "completedTourneys" && (
              <>
                <TourneyStatItemWrapper>
                  <TourneyStatItemName>Game Starts At</TourneyStatItemName>
                  <TourneyStatItemValue>{`Level ${blindStructureLevel}`}</TourneyStatItemValue>
                </TourneyStatItemWrapper>
                <HorizontalSeperator src={tourneyHorizontalSeperator} />
              </>
            )}
          {isFlightTourney && tourneyIdentifier === "completedTourneys" && (
            <>
              <TourneyStatItemWrapper>
                <TourneyStatItemName>Game Ended At</TourneyStatItemName>
                <TourneyStatItemValue>
                  {formatTourneyDate(tourneyEndTime)}
                </TourneyStatItemValue>
              </TourneyStatItemWrapper>
              <HorizontalSeperator src={tourneyHorizontalSeperator} />
            </>
          )}
          {activeTables > 0 && (
            <>
              <TourneyStatItemWrapper>
                <TourneyStatItemName>Active Tables</TourneyStatItemName>
                <TourneyStatItemValue>{activeTables}</TourneyStatItemValue>
              </TourneyStatItemWrapper>
              <HorizontalSeperator src={tourneyHorizontalSeperator} />
            </>
          )}

          {qualifiedPlayerCount > 0 && (
            <>
              <TourneyStatItemWrapper>
                <TourneyStatItemName>Players Qualified</TourneyStatItemName>
                <TourneyStatItemValue>
                  {qualifiedPlayerCount}
                </TourneyStatItemValue>
              </TourneyStatItemWrapper>
              <HorizontalSeperator src={tourneyHorizontalSeperator} />
            </>
          )}
          {activeTables > 0 && (
            <>
              {/* relying on activeTables key to ensure tourney is running */}
              <TourneyStatItemWrapper>
                <TourneyStatItemName>Current Blinds</TourneyStatItemName>
                <TourneyStatItemValue>{`Level ${getCurrentLevelDisplayValue(
                  currentLevel
                )} ${getSelectedLevelBlindStructure(
                  getCurrentLevelDisplayValue(currentLevel)
                )}`}</TourneyStatItemValue>
              </TourneyStatItemWrapper>
              <HorizontalSeperator src={tourneyHorizontalSeperator} />
            </>
          )}
          {currentPrizeAmount && currentPrizeAmount?.amount > 0 && (
            <>
              {/* relying on activeTables key to ensure tourney is running */}
              <TourneyStatItemWrapper>
                <TourneyStatItemName>Current Payout</TourneyStatItemName>
                <TourneyStatItemValue>{`${parseDecimalValuesOfNumber(
                  currentPrizeAmount.amount + currentPrizeAmount.bonus,
                  2,
                  checkToDisplayValueInK(
                    currentPrizeAmount.amount + currentPrizeAmount.bonus
                  ),
                  checkToDisplayValueInL(
                    currentPrizeAmount.amount + currentPrizeAmount.bonus
                  )
                )}`}</TourneyStatItemValue>
              </TourneyStatItemWrapper>
              <HorizontalSeperator src={tourneyHorizontalSeperator} />
            </>
          )}

          {/* {!isFlightParentTourney && (
            <>
              <TourneyStatItemWrapper>
                <TourneyStatItemName>Game Ends At</TourneyStatItemName>
                <TourneyStatItemValue>{`Level ${blindStructureEndLevel}`}</TourneyStatItemValue>
              </TourneyStatItemWrapper>
              <HorizontalSeperator src={tourneyHorizontalSeperator} />
            </>
          )} */}
          {nextPrizeJumpRank > 0 &&
            nextPrizeAmount &&
            nextPrizeAmount?.amount > 0 && (
              <>
                <TourneyStatItemWrapper>
                  <TourneyStatItemName>Next Prize Jump at</TourneyStatItemName>
                  <TourneyStatItemValue>
                    {`Rank : ${nextPrizeJumpRank}`}
                    <NextPrizeJumpAmountText>{`(₹${parseDecimalValuesOfNumber(
                      nextPrizeAmount.amount + nextPrizeAmount.bonus,
                      2,
                      checkToDisplayValueInK(
                        nextPrizeAmount.amount + nextPrizeAmount.bonus
                      ),
                      checkToDisplayValueInL(
                        nextPrizeAmount.amount + nextPrizeAmount.bonus
                      )
                    )})`}</NextPrizeJumpAmountText>
                  </TourneyStatItemValue>
                </TourneyStatItemWrapper>
                <HorizontalSeperator src={tourneyHorizontalSeperator} />{" "}
              </>
            )}
          {reEntry && (
            <>
              <TourneyStatItemWrapper>
                <TourneyStatItemName>Re-Entry fees</TourneyStatItemName>
                <TourneyStatItemValue>
                  {`₹${reEntry.reEntrySettings.reentryEntryFee}`}
                </TourneyStatItemValue>
              </TourneyStatItemWrapper>
              <HorizontalSeperator src={tourneyHorizontalSeperator} />
            </>
          )}
          {/* {!isFlightParentTourney && (
            <>
              <TourneyStatItemWrapper>
                <TourneyStatItemName>Starting Stack</TourneyStatItemName>
                <TourneyStatItemValue>
                  {parseDecimalValuesOfNumber(
                    startingChipsCount,
                    2,
                    checkToDisplayValueInK(startingChipsCount),
                    checkToDisplayValueInL(startingChipsCount)
                  )}
                </TourneyStatItemValue>
              </TourneyStatItemWrapper>
              <HorizontalSeperator src={tourneyHorizontalSeperator} />
            </>
          )} */}

          {tourneyStatus === TOURNEY_STATUS_LIVE && (
            <>
              {/* <TourneyStatItemWrapper>
                <TourneyStatItemName>Highest Stack</TourneyStatItemName>
                <TourneyStatItemValue>
                  {parseDecimalValuesOfNumber(
                    highestStack,
                    2,
                    checkToDisplayValueInK(highestStack),
                    checkToDisplayValueInL(highestStack)
                  )}
                </TourneyStatItemValue>
              </TourneyStatItemWrapper>
              <HorizontalSeperator src={tourneyHorizontalSeperator} />
              <TourneyStatItemWrapper>
                <TourneyStatItemName>Average Stack</TourneyStatItemName>
                <TourneyStatItemValue>
                  {parseDecimalValuesOfNumber(
                    averageChips,
                    2,
                    checkToDisplayValueInK(averageChips),
                    checkToDisplayValueInL(averageChips)
                  )}
                </TourneyStatItemValue>
              </TourneyStatItemWrapper> */}
              {/* <HorizontalSeperator src={tourneyHorizontalSeperator} /> */}
              <TourneyStatItemWrapper>
                <TourneyStatItemName>Current Level</TourneyStatItemName>
                <TourneyStatItemValue>{currentLevel}</TourneyStatItemValue>
              </TourneyStatItemWrapper>
              <HorizontalSeperator src={tourneyHorizontalSeperator} />
              <TourneyStatItemWrapper>
                <TourneyStatItemName>Next Level</TourneyStatItemName>
                <TourneyStatItemValue>{nextLevel}</TourneyStatItemValue>
              </TourneyStatItemWrapper>
              <HorizontalSeperator src={tourneyHorizontalSeperator} />
              <TourneyStatItemWrapper>
                <TourneyStatItemName>Next Break</TourneyStatItemName>
                <TourneyStatItemValue>{nextBreak}</TourneyStatItemValue>
              </TourneyStatItemWrapper>
            </>
          )}
          {/* <HorizontalSeperator src={tourneyHorizontalSeperator} /> */}
          <TourneyStatItemWrapper>
            <FlightTourneyWinnersAndGameTypeContainer
              isFlightParent={isFlightParentTourney}
            >
              <GameTypeAndMaxPlayersContainer>
                <GameTypeAndPlayersWrapper>
                  <GameTypeIconWrapper></GameTypeIconWrapper>
                  <TourneyStatItemValue>
                    {returnGameTypeText(gameType)}
                  </TourneyStatItemValue>
                </GameTypeAndPlayersWrapper>
                <GameTypeAndPlayersWrapper>
                  <MaxPlayersIcon src={GroupGoldIcon} />
                  <TourneyStatItemValue>{`${playersPerTable} Max`}</TourneyStatItemValue>
                </GameTypeAndPlayersWrapper>
              </GameTypeAndMaxPlayersContainer>
              {isFlightParentTourney && allFlightsEnded && (
                <PrizeBreakupText onClick={handleOpenPrizeBreakupModal}>
                  Prize Breakup
                  <BlindsRightArrow src={RightLeafyGreenArrow} />
                </PrizeBreakupText>
              )}
            </FlightTourneyWinnersAndGameTypeContainer>
          </TourneyStatItemWrapper>
        </TourneyStatsTableBodyWrapper>
      </TourneyDetailTable>
    );
  };

  const getTimerTextBasedOnTourneyStage = (currentTourneyStatus) => {
    switch (currentTourneyStatus) {
      case TOURNEY_STATUS_LIVE:
        if (remainingStartTime >= 0) {
          if (!lateRegEnded && lateRegEndTime > 0) {
            return "Late registration ends in ";
          }
          return "Running since";
        } else {
          return "About to start";
        }
      case TOURNEY_ANNOUNCED:
      case TOURNEY_REGISTRATION_STARTED:
      case TOURNEY_REGISTRATION_ENDED:
        if (remainingStartTime >= 0) {
          return "Starts In";
        } else {
          return "About to start";
        }

      default:
        return "";
    }
  };

  const renderTourneyTimer = () => {
    if (
      tourneyIdentifier === "completedTourneys" ||
      tourneyStatus === TOURNEY_STATUS_MISSED
    ) {
      return;
    } else if (tourneyStatus !== TOURNEY_STATUS_MISSED) {
      if (
        tourneyStatus !== TOURNEY_STATUS_LIVE &&
        tournamentType === SIT_AND_GO_TOURNEYS
      ) {
        return;
      }
      return (
        <TourneyTimersContainer>
          <TourneyTimersWrapper>
            <CurrentTimerTitle>
              {getTimerTextBasedOnTourneyStage(tourneyStatus)}
            </CurrentTimerTitle>
            <RunningTimeWrapper>
              {tourneyStatus !== TOURNEY_STATUS_CANCELLED &&
                tourneyStatus !== TOURNEY_STATUS_COMPLETED &&
                getRunningTimerDetails(
                  tourneyStatus === TOURNEY_STATUS_LIVE &&
                    !lateRegEnded &&
                    lateRegEndTime > 0
                    ? lateRegEndTimestamp
                    : remainingStartTime
                )}
            </RunningTimeWrapper>
            {lateRegistrationAllowed && !lateRegEnded && (
              <LateRegistrationWrapper>
                <>
                  <GoldOpenIcon />
                  {tourneyStatus === TOURNEY_STATUS_LIVE &&
                  !lateRegEnded &&
                  lateRegEndTime > 0 ? (
                    <LateRegistrationText>
                      {`Started since `}
                      <LateRegistrationTimerText>{`${convertTimeToMinutesAndSeconds(
                        remainingStartTime,
                        true
                      )}`}</LateRegistrationTimerText>
                    </LateRegistrationText>
                  ) : (
                    <LateRegistrationText>
                      {`Late Registration available for `}
                      <LateRegistrationTimerText>{`${convertTimeToMinutesAndSeconds(
                        lateRegEndTimestamp,
                        true
                      )}`}</LateRegistrationTimerText>
                    </LateRegistrationText>
                  )}
                </>
              </LateRegistrationWrapper>
            )}
            {lateRegEnded && (
              <LateRegistrationWrapper>
                <>
                  {" "}
                  <GoldOpenIcon />
                  <LateRegistrationText>
                    {`Late registration ended`}
                  </LateRegistrationText>
                </>
              </LateRegistrationWrapper>
            )}
          </TourneyTimersWrapper>
        </TourneyTimersContainer>
      );
    }
  };

  const getTopThreeRanks = (players) => {
    let rankMap = new Map(); // To store unique ranks

    for (let player of players) {
      if (!rankMap.has(player.rank) && player.rank > 0 && player.rank <= 3) {
        rankMap.set(player.rank, player);
      }
      if (rankMap.size === 3) break; // Stop once we have 3 unique ranks
    }

    // Convert Map values to an array and sort in the order [2, 1, 3]
    const rankOrder = [2, 1, 3];
    return Array.from(rankMap.values()).sort(
      (a, b) => rankOrder.indexOf(a.rank) - rankOrder.indexOf(b.rank)
    );
  };
  const renderTourneyWinnersSection = () => {
    let topRanks = getTopThreeRanks(props.tourneyDetails?.top3RankList);
    return (
      <TourneyWinnersContainer>
        <TourneyConcludedText>Tourney Concluded</TourneyConcludedText>
        <WinnersListWrapper>
          {topRanks.map((eachWinner) => {
            const {
              rank,
              displayName,
              chipStack,
              winningAmount,
              winningBonusAmount,
            } = eachWinner;
            let winningAmountSum = winningAmount + winningBonusAmount;
            return (
              <WinnerWrapper>
                <WinnerIconContainer>
                  {rank === 1 ? (
                    <FirstWinnerIconWrapper src={FirstWinner} />
                  ) : rank === 2 ? (
                    <WinnerIconWrapper src={SecondWinner} />
                  ) : (
                    <WinnerIconWrapper src={ThirdWinner} />
                  )}
                </WinnerIconContainer>
                <WinnerUserNameText>{displayName}</WinnerUserNameText>
                <WinnerPrizeAmountText>
                  {winningAmountSum > 0
                    ? `₹${parseDecimalValuesOfNumber(
                        winningAmountSum,
                        2,
                        checkToDisplayValueInK(winningAmountSum),
                        checkToDisplayValueInL(winningAmountSum)
                      )}`
                    : "-"}
                </WinnerPrizeAmountText>
              </WinnerWrapper>
            );
          })}
        </WinnersListWrapper>
        <ViewAllWinnersText
          onClick={() => {
            props.handleTourneysTabChange(TOURNEY_INFO_ENTRIES_TAB);
          }}
        >
          {`View Top ${
            registeredPlayerCount > 50 ? 50 : registeredPlayerCount
          } Winners`}
        </ViewAllWinnersText>
      </TourneyWinnersContainer>
    );
  };

  const renderTourneyDetailsSectionByTypeValidation = () => {
    if (isFlightTourney) {
      return (
        <TourneyDetailsWrapper>
          {isFlightParentTourney &&
          tourneyIdentifier === "completedTourneys" ? (
            renderTourneyWinnersSection()
          ) : (
            <FlightTourneyStartsInAndPrizeAmountWrapper>
              <PrizeAmountContainer>
                <TrophyIconWrapper src={goldGloryIcon} />
                {isFlightParentTourney &&
                tourneyIdentifier !== "completedTourneys" ? (
                  <PrizeAmountWrapper>
                    <ParentPrizeWorthText>
                      Survivors From All Flights <br />
                      will be auto registered to this event
                    </ParentPrizeWorthText>
                  </PrizeAmountWrapper>
                ) : (
                  <PrizeAmountWrapper>
                    <PrizeWorthText>Survivors Qualify for</PrizeWorthText>

                    <PrizeAmountText>{parentTourneyName}</PrizeAmountText>
                  </PrizeAmountWrapper>
                )}
              </PrizeAmountContainer>
              {!isFlightParentTourney && (
                <PrizeBreakupText onClick={handleTourneyNavigation}>
                  Details
                  <BlindsRightArrow src={RightLeafyGreenArrow} />
                </PrizeBreakupText>
              )}
            </FlightTourneyStartsInAndPrizeAmountWrapper>
          )}

          {tourneyStatus === TOURNEY_STATUS_LIVE &&
          lateRegistrationAllowed &&
          !lateRegEnded ? (
            <>
              {renderTourneyTimer()}
              {renderFlightTourneyDetailsSection()}
            </>
          ) : (
            <>
              {renderFlightTourneyDetailsSection()}
              {renderTourneyTimer()}
            </>
          )}

          {!isFlightParentTourney && (
            <TourneyFlightParentAndSimilarFlightsCard
              isParentTourney={true}
              parentTourneyName={parentTourneyName}
              handleTourneyNavigation={handleTourneyNavigation}
            />
          )}
          {similarFlights.length > 0 && currentAvailableFlights > 0 && (
            <TourneyFlightParentAndSimilarFlightsCard
              isParentTourney={false}
              similarFlights={currentAvailableFlights}
              threshold={flightXThreshold}
              handleTourneyNavigation={navigateToFlightsTab}
            />
          )}
          {childTourneys.length > 0 && (
            <TourneyFlightParentAndSimilarFlightsCard
              isParentTourney={false}
              similarFlights={childTourneys.length}
              satellites={true}
              handleTourneyNavigation={navigateToFlightsTab}
            />
          )}
          <TourneyRulesAccordianContainer
            isOpened={isTourneyRulesAccordianOpened}
            onClick={() => {
              setIsTourneyRulesAccordianOpened(!isTourneyRulesAccordianOpened);
            }}
          >
            <TourneyRulesHeadingText>Tourney Rules</TourneyRulesHeadingText>
            <ExpansionAccordianIcon
              isOpened={isTourneyRulesAccordianOpened}
              src={GrayAccordianIcon}
            />
          </TourneyRulesAccordianContainer>
          {isTourneyRulesAccordianOpened && (
            <TourneyRulesBodyWrapper>
              {renderTourneyRules(true)}
            </TourneyRulesBodyWrapper>
          )}
        </TourneyDetailsWrapper>
      );
    }
    return (
      <TourneyDetailsWrapper>
        <TourneyStartsInAndPrizeAmountWrapper>
          <PrizeAmountContainer>
            <TrophyIconWrapper src={goldGloryIcon} />
            <PrizeAmountWrapper>
              <PrizeWorthText>Prizes Worth</PrizeWorthText>
              <PrizeAmountText>
                {/* if dynamicPoolPrize or prizePool is 0, we should not display it in the details */}
                {breakEven
                  ? dynamicPoolPrize > 0
                    ? `₹${parseDecimalValuesOfNumber(
                        dynamicPoolPrize,
                        2,
                        checkToDisplayValueInK(dynamicPoolPrize),
                        checkToDisplayValueInL(dynamicPoolPrize)
                      )}`
                    : ""
                  : prizePool > 0
                  ? `₹${parseDecimalValuesOfNumber(
                      prizePool,
                      2,
                      checkToDisplayValueInK(prizePool),
                      checkToDisplayValueInL(prizePool)
                    )}`
                  : ""}
                {prizePoolTickets > 0 && (
                  <>
                    {((breakEven && dynamicPoolPrize > 0) || prizePool > 0) && (
                      <AddIconText>+</AddIconText>
                    )}
                    <TicketsCountText>{prizePoolTickets}</TicketsCountText>
                    <TicketIcon src={goldTicket} />
                  </>
                )}
              </PrizeAmountText>
            </PrizeAmountWrapper>
          </PrizeAmountContainer>
          <PrizeBreakupText onClick={handleOpenPrizeBreakupModal}>
            Prize Breakup
          </PrizeBreakupText>
        </TourneyStartsInAndPrizeAmountWrapper>
        {tournamentType === TIMER_TOURNEYS && (
          <MaxRunTimeText>{`Maximum run time : ${props.tourneyDetails?.totalTimeForTimerTourney} minutes`}</MaxRunTimeText>
        )}
        {tournamentType === SIT_AND_GO_TOURNEYS &&
          tourneyStatus === TOURNEY_REGISTRATION_STARTED && (
            <SitAndGoMessageText>
              Tourney starts when all players join
            </SitAndGoMessageText>
          )}
        <TourneyDetailTable>
          <TourneyDetailsTableHeader>
            <TourneyDetailsText>Tourney Details</TourneyDetailsText>

            <ViewBlindStructureText onClick={handleOpenBlindStructure}>
              Blind Structure
              <BlindsRightArrow src={RightLeafyGreenArrow} />
            </ViewBlindStructureText>
          </TourneyDetailsTableHeader>
          <TourneyDetailsTableBodyWrapper>
            <TourneyBuyInAndWinnersWrapper>
              <BuyInWrapper>
                <BuyInText>Buy-In</BuyInText>
                <BuyInAmountText>
                  {tournamentType === TICKET_BASED
                    ? TICKETS
                    : buyIn + tourneyRake}
                </BuyInAmountText>
              </BuyInWrapper>
              <VerticalSeperator src={tourneyVerticalSeperator} />
              <PlayersWrapper>
                <PlayersText>Entries</PlayersText>
                <PlayersCountText>{`${parseDecimalValuesOfNumber(
                  registeredPlayerCount,
                  2,
                  checkToDisplayValueInK(registeredPlayerCount),
                  checkToDisplayValueInL(registeredPlayerCount)
                )}/${parseDecimalValuesOfNumber(
                  maxPlayers,
                  2,
                  checkToDisplayValueInK(maxPlayers),
                  checkToDisplayValueInL(maxPlayers)
                )}`}</PlayersCountText>
                {(tourneyStatus === TOURNEY_STATUS_LIVE ||
                  tourneyStatus === TOURNEY_STATUS_MISSED) &&
                tourneyIdentifier !== "completedTourneys" &&
                activePlayerForTourney > 0 ? (
                  <ActivePlayersCountDetails>
                    <PlayerIconWrapper src={maskPlayerIcon} />
                    <ActivePlayerCountText>
                      {`${parseDecimalValuesOfNumber(
                        activePlayerForTourney,
                        2,
                        checkToDisplayValueInK(activePlayerForTourney),
                        checkToDisplayValueInL(activePlayerForTourney)
                      )}`}
                    </ActivePlayerCountText>
                    <RemainingText>Active</RemainingText>
                  </ActivePlayersCountDetails>
                ) : null}
              </PlayersWrapper>
              <VerticalSeperator src={tourneyVerticalSeperator} />
              <ReEntriesWrapper>
                <ReEntriesText>Re-entries</ReEntriesText>
                <ReEntryCountText>
                  {totalReEntryCount > 0 ? totalReEntryCount : "-"}
                </ReEntryCountText>
              </ReEntriesWrapper>

              <VerticalSeperator src={tourneyVerticalSeperator} />
              <WinnersWrapper>
                <WinnersText>Winners</WinnersText>
                <WinnersCountText>
                  {parseDecimalValuesOfNumber(
                    displayWinners,
                    2,
                    checkToDisplayValueInK(displayWinners),
                    checkToDisplayValueInL(displayWinners)
                  )}
                </WinnersCountText>
              </WinnersWrapper>
            </TourneyBuyInAndWinnersWrapper>
            {(totalAddOns > 0 ||
              maxRebuyCount > 0 ||
              reEntry ||
              depositTourneyFlag) && (
              <HorizontalSeperator src={tourneyHorizontalSeperator} />
            )}
            <TotalAddonsAndRebuyWrapper>
              {depositTourneyFlag && (
                <DepositorTourneyEntriesWrapper>
                  <DirectEntriesWrapper>
                    <TableItemText>Direct Entries</TableItemText>
                    {showDirectEntriesTooltip && (
                      <TooltipWrapper>
                        <TooltipText>
                          No of direct registrations allowed
                        </TooltipText>
                        <TooltipArrow></TooltipArrow>
                      </TooltipWrapper>
                    )}
                    <GrayInfoIconWrapper
                      src={grayInfoIcon}
                      onClick={handleDirectEntriesInfoIconClick}
                    />
                    <DirectEntriesAndTicketEntriesCountText>
                      {allowedFreeRegistrations}
                    </DirectEntriesAndTicketEntriesCountText>
                  </DirectEntriesWrapper>
                  <TicketEntriesWrapper>
                    <TableItemText>Ticket Entries</TableItemText>
                    {showTicketEntriesTooltip && (
                      <TooltipWrapper tooltipPosition={"left"}>
                        <TooltipText>
                          No of tickets issues through add cash
                        </TooltipText>
                        <TooltipArrow tooltipPosition={"left"}></TooltipArrow>
                      </TooltipWrapper>
                    )}
                    <GrayInfoIconWrapper
                      src={grayInfoIcon}
                      onClick={handleTicketEntriesInfoIconClick}
                    />
                    <DirectEntriesAndTicketEntriesCountText>
                      {allowedTicketRegistrations}
                    </DirectEntriesAndTicketEntriesCountText>
                  </TicketEntriesWrapper>
                </DepositorTourneyEntriesWrapper>
              )}
              {totalAddOns > 0 && (
                <AddOnsAndRebuyItemWrapper>
                  <TableItemText>
                    {`Total Add-ons`}
                    <RebuyAmountValue>{totalAddOnCount} </RebuyAmountValue>
                  </TableItemText>
                  <AddOnAndRebuyAmountDetailWrapper>
                    <TableItemText>Add-on fee </TableItemText>
                    <RebuyAmountText>{`₹${addOnAmount}`}</RebuyAmountText>
                  </AddOnAndRebuyAmountDetailWrapper>
                </AddOnsAndRebuyItemWrapper>
              )}
              {maxRebuyCount > 0 && (
                <AddOnsAndRebuyItemWrapper>
                  <TableItemText>
                    {`Total Re-buys`}
                    <RebuyAmountValue>{`${totalRebuyCount}`}</RebuyAmountValue>
                  </TableItemText>
                  <AddOnAndRebuyAmountDetailWrapper>
                    <TableItemText>Re-buy fees</TableItemText>
                    <RebuyAmountValue>{`₹${rebuyFee}`}</RebuyAmountValue>
                  </AddOnAndRebuyAmountDetailWrapper>
                </AddOnsAndRebuyItemWrapper>
              )}
              {reEntry && (
                <AddOnsAndRebuyItemWrapper>
                  <TableItemText>{`Total Re-Entries  ${totalReEntryCount}`}</TableItemText>
                  <AddOnAndRebuyAmountDetailWrapper>
                    <TableItemText>Re-entry fees</TableItemText>
                    <RebuyAmountText>{`₹${reEntry.reEntrySettings.reentryEntryFee}`}</RebuyAmountText>
                  </AddOnAndRebuyAmountDetailWrapper>
                </AddOnsAndRebuyItemWrapper>
              )}
            </TotalAddonsAndRebuyWrapper>
            {maxRebuyCount > 0 && (
              <UnlimitedRebuysText>
                {`${maxRebuyCount} re-buys allowed till Level ${rebuyLevel}`}
              </UnlimitedRebuysText>
            )}
            {reEntry && (
              <UnlimitedRebuysText>
                {`${reEntry.reEntrySettings.noOfReentry} re-entries allowed till Level ${reEntry.reEntrySettings.reentryLevel}`}
              </UnlimitedRebuysText>
            )}
          </TourneyDetailsTableBodyWrapper>
        </TourneyDetailTable>
        {renderTourneyTimer()}

        {renderTourneyRules()}

        {parentTourney &&
          parentTourney.length > 0 &&
          getTourneyDefinitionByTourneyId(parentTourney) && (
            <ParentTourneysContainer isExpansionOpened={false}>
              <ParentTourneysText>Parent Tourney</ParentTourneysText>
              <TourneyTileCard
                tourneyDetails={getTourneyDefinitionByTourneyId(parentTourney)}
                hideStatusSection={false}
              />
            </ParentTourneysContainer>
          )}

        {childTourneys && getChildTourneysDetails().length > 0 && (
          <ChildTourneysContainer
            isExpansionOpened={isChildTourneysExpansionOpened}
          >
            <ChildTourneysText>Child Tourneys</ChildTourneysText>
            {getChildTourneysDetails().map((childTourneyDetails) => {
              return (
                <TourneyTileCard
                  tourneyDetails={childTourneyDetails}
                  hideStatusSection={true}
                />
              );
            })}
          </ChildTourneysContainer>
        )}
        {getChildTourneysDetails().length > 2 && (
          <ViewMoreOrLessWrapper
            onClick={() => {
              setIsChildTourneysExpansionOpened(
                !isChildTourneysExpansionOpened
              );
            }}
          >
            <ExpansionPanelText>View more</ExpansionPanelText>
            <ExpansionAccordianIcon
              isOpened={isChildTourneysExpansionOpened}
              src={GrayAccordianIcon}
            />
          </ViewMoreOrLessWrapper>
        )}
        <TourneyStatsContainer>
          <TourneyStatsTableHeader>
            <TourneyStatsText>Tourney Stats</TourneyStatsText>
          </TourneyStatsTableHeader>
          <TourneyStatsTableBodyWrapper>
            <TourneyStatItemWrapper>
              <TourneyStatItemName>Starting Stack</TourneyStatItemName>
              <TourneyStatItemValue>
                {parseDecimalValuesOfNumber(
                  startingStack,
                  2,
                  checkToDisplayValueInK(startingStack),
                  checkToDisplayValueInL(startingStack)
                )}
              </TourneyStatItemValue>
            </TourneyStatItemWrapper>
            <HorizontalSeperator src={tourneyHorizontalSeperator} />
            <TourneyStatItemWrapper>
              <TourneyStatItemName>Players Per Table</TourneyStatItemName>
              <TourneyStatItemValue>{`Max ${playersPerTable} Players`}</TourneyStatItemValue>
            </TourneyStatItemWrapper>
            {tourneyStatus === TOURNEY_STATUS_LIVE && (
              <>
                <HorizontalSeperator src={tourneyHorizontalSeperator} />
                <TourneyStatItemWrapper>
                  <TourneyStatItemName>Highest Stack</TourneyStatItemName>
                  <TourneyStatItemValue>
                    {parseDecimalValuesOfNumber(
                      highestStack,
                      2,
                      checkToDisplayValueInK(highestStack),
                      checkToDisplayValueInL(highestStack)
                    )}
                  </TourneyStatItemValue>
                </TourneyStatItemWrapper>
                <HorizontalSeperator src={tourneyHorizontalSeperator} />
                <TourneyStatItemWrapper>
                  <TourneyStatItemName>Average Stack</TourneyStatItemName>
                  <TourneyStatItemValue>
                    {parseDecimalValuesOfNumber(
                      averageChips,
                      2,
                      checkToDisplayValueInK(averageChips),
                      checkToDisplayValueInL(averageChips)
                    )}
                  </TourneyStatItemValue>
                </TourneyStatItemWrapper>
                <HorizontalSeperator src={tourneyHorizontalSeperator} />
                <TourneyStatItemWrapper>
                  <TourneyStatItemName>Current Level</TourneyStatItemName>
                  <TourneyStatItemValue>{currentLevel}</TourneyStatItemValue>
                </TourneyStatItemWrapper>
                <HorizontalSeperator src={tourneyHorizontalSeperator} />
                <TourneyStatItemWrapper>
                  <TourneyStatItemName>Next Level</TourneyStatItemName>
                  <TourneyStatItemValue>{nextLevel}</TourneyStatItemValue>
                </TourneyStatItemWrapper>
                <HorizontalSeperator src={tourneyHorizontalSeperator} />
                <TourneyStatItemWrapper>
                  <TourneyStatItemName>Next Break</TourneyStatItemName>
                  <TourneyStatItemValue>{nextBreak}</TourneyStatItemValue>
                </TourneyStatItemWrapper>
              </>
            )}
          </TourneyStatsTableBodyWrapper>
        </TourneyStatsContainer>

        {breakInProgress && breakTimeInSeconds > 0 && (
          <TourneyOnBreakContainer>
            <CoffeeIconWrapper src={breakCoffeeIcon} />
            <TourneyBreakMessageText>
              Tournament is in currently on break for
            </TourneyBreakMessageText>
            <BreakTimeText>{`${convertTimeToMinutesAndSeconds(
              breakTimeInSeconds,
              true
            )}`}</BreakTimeText>
          </TourneyOnBreakContainer>
        )}
      </TourneyDetailsWrapper>
    );
  };

  const renderBlindStructureDetails = () => {
    let currentBlindStructure =
      isFlightTourney && !isFlightParentTourney
        ? getTourneyDefinitionByTourneyId(parentTourney)?.blindStructure ?? []
        : blindStructure ?? [];

    // Restrict list to only till blindStructureEndLevel if isFlightTourney is true
    if (isFlightTourney && !isFlightParentTourney) {
      currentBlindStructure = currentBlindStructure.filter(
        (eachLevel) => eachLevel.level <= blindStructureEndLevel
      );
    }

    return (
      <BlindStructureContainer
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <BlindStructureHeader>
          <BlindStructureText>Blind Structure</BlindStructureText>
          <CloseIconWrapper
            src={CloseIcon}
            onClick={handleCloseBlindStructure}
          />
        </BlindStructureHeader>
        <BlindStructureBodyWrapper>
          <TableWrapper>
            <BlindStructureTableHeaderWrapper>
              <BlindStructureHeaderItemText textPosition={"flex-start"}>
                Level
              </BlindStructureHeaderItemText>
              <BlindStructureHeaderItemText>Blind</BlindStructureHeaderItemText>
              <BlindStructureHeaderItemText>Ante</BlindStructureHeaderItemText>
              <BlindStructureHeaderItemText>
                Minutes
              </BlindStructureHeaderItemText>
            </BlindStructureTableHeaderWrapper>
            {currentBlindStructure.map((eachLevel, index) => (
              <>
                <BlindStructureTableRow
                  disableRow={
                    isFlightTourney && !isFlightParentTourney
                      ? eachLevel.level > blindStructureEndLevel
                      : false
                  }
                >
                  <BlindStructureTableRowItemText textPosition={"flex-start"}>
                    {eachLevel.level}
                  </BlindStructureTableRowItemText>
                  <BlindStructureTableRowItemText>{`${parseDecimalValuesOfNumber(
                    eachLevel.sb,
                    2,
                    checkToDisplayValueInK(eachLevel.sb),
                    checkToDisplayValueInL(eachLevel.sb)
                  )}/${parseDecimalValuesOfNumber(
                    eachLevel.bb,
                    2,
                    checkToDisplayValueInK(eachLevel.bb),
                    checkToDisplayValueInL(eachLevel.bb)
                  )}`}</BlindStructureTableRowItemText>
                  <BlindStructureTableRowItemText>
                    {parseDecimalValuesOfNumber(
                      eachLevel.ante,
                      2,
                      checkToDisplayValueInK(eachLevel.ante),
                      checkToDisplayValueInL(eachLevel.ante)
                    )}
                  </BlindStructureTableRowItemText>
                  <BlindStructureTableRowItemText>
                    {levelDuration > 0 ? levelDuration / 60 : levelDuration}
                  </BlindStructureTableRowItemText>
                </BlindStructureTableRow>
                {currentBlindStructure.length !== index + 1 && (
                  <HorizontalSeperator src={tourneyHorizontalSeperator} />
                )}
                {currentBlindStructure.length > 6 && (
                  <ScrollDownArrow bottom={16} right={20} />
                )}
              </>
            ))}
          </TableWrapper>
        </BlindStructureBodyWrapper>
      </BlindStructureContainer>
    );
  };

  return (
    <TourneyDetailsContainer id={`tourney-details-page-${tourneyId}`}>
      {renderTourneyDetailsSectionByTypeValidation()}
      {openPrizeBreakupModal && (
        <ModalContainer onClick={handleClosePrizeBreakup}>
          <PrizeBreakUpModalContainer
            isOpen={openPrizeBreakupModal}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <PrizeBreakupHeader>
              <PrizeBreakupHeaderText>Prize Breakup</PrizeBreakupHeaderText>
              <CloseIconWrapper
                src={CloseIcon}
                onClick={handleClosePrizeBreakup}
              />
            </PrizeBreakupHeader>
            <PrizeBreakupBodyWrapper>
              {isFlightTourney && isFlightParentTourney && !allFlightsEnded ? (
                <TourneyFlightsPlaceholder
                  message={
                    "Prize Structure will be visible once all flights have concluded"
                  }
                />
              ) : (
                <TableWrapper>
                  <TableHeaderWrapper>
                    <HeaderItemText>Position</HeaderItemText>
                    <HeaderItemText>Prizes</HeaderItemText>
                  </TableHeaderWrapper>
                  {winnersPayoutWithBonus &&
                    Object.keys(winnersPayoutWithBonus).map(
                      (eachPayout, index) => {
                        return (
                          <>
                            <TableRow>
                              <TableRowItemText>{`${
                                Object.keys(winnersPayoutWithBonus)[index]
                              }`}</TableRowItemText>
                              <TableRowItemText>
                                {winnersPayoutWithBonus[eachPayout]?.amount >
                                  0 &&
                                  `₹${parseDecimalValuesOfNumber(
                                    winnersPayoutWithBonus[eachPayout]?.amount,
                                    2,
                                    checkToDisplayValueInK(
                                      winnersPayoutWithBonus[eachPayout]?.amount
                                    ),
                                    checkToDisplayValueInL(
                                      winnersPayoutWithBonus[eachPayout]?.amount
                                    )
                                  )}`}
                                {winnersPayoutWithBonus[`${eachPayout}`]
                                  ?.bonus > 0 && (
                                  <>
                                    {winnersPayoutWithBonus[eachPayout]
                                      ?.amount > 0 && (
                                      <AddIconText>+</AddIconText>
                                    )}
                                    {`₹${parseDecimalValuesOfNumber(
                                      winnersPayoutWithBonus[`${eachPayout}`]
                                        ?.bonus,
                                      2,
                                      checkToDisplayValueInK(
                                        winnersPayoutWithBonus[`${eachPayout}`]
                                          ?.bonus
                                      ),
                                      checkToDisplayValueInL(
                                        winnersPayoutWithBonus[`${eachPayout}`]
                                          ?.bonus
                                      )
                                    )}
                                  (Bonus)`}
                                  </>
                                )}
                                {winnersPayoutWithBonus[`${eachPayout}`]
                                  ?.ticket && (
                                  <>
                                    {(winnersPayoutWithBonus[`${eachPayout}`]
                                      ?.amount > 0 ||
                                      winnersPayoutWithBonus[`${eachPayout}`]
                                        ?.bonus > 0) && (
                                      <AddIconText>+</AddIconText>
                                    )}
                                    <TicketIcon src={goldTicket} />
                                  </>
                                )}
                              </TableRowItemText>
                            </TableRow>

                            <HorizontalSeperator
                              src={tourneyHorizontalSeperator}
                            />
                          </>
                        );
                      }
                    )}
                </TableWrapper>
              )}

              {!isFlightTourney && prizePoolType === "Dynamic" && (
                <DynamicPayoutStructureText>{`Note: The prize breakdown may change based on the number of entries.`}</DynamicPayoutStructureText>
              )}
            </PrizeBreakupBodyWrapper>
          </PrizeBreakUpModalContainer>
        </ModalContainer>
      )}
      {openBlindStructure && (
        <ModalContainer onClick={handleCloseBlindStructure}>
          {renderBlindStructureDetails()}
        </ModalContainer>
      )}
    </TourneyDetailsContainer>
  );
}

export default TourneyDetails;
