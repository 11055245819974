import styled from "styled-components";
import { Typo14CasalContoMediumText } from "../../../Common/styleGuide/Typos";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  background-color: #02181e;
  margin: 16px 0px;
  border-radius: 8px;
`;

export const EmptyFolderIconWrapper = styled.img`
  height: 50px;
  width: 50px;
`;

export const MessageText = styled(Typo14CasalContoMediumText)`
  margin-top: 16px;
  width: 75%;
  text-align: center;
`;
