import styled, { keyframes } from "styled-components";
import colors from "../../../Common/styleGuide/Colors";
import {
  Typo10OffWhiteContoRegularText,
  Typo10WhiteContoMediumText,
  Typo10WhiteTwoBoldText,
  Typo10YellowOrangeBoldText,
  Typo11DownyContoMediumText,
  Typo12GrayContoMediumText,
  Typo12GrayContoRegularText,
  Typo12LeafyGreenContoMediumText,
  Typo12OffWhiteContoMediumText,
  Typo12PrimaryGoldContoMediumText,
  Typo12PrimaryGoldContoRegularText,
  Typo12TourneySecondaryGreenMediumText,
  Typo12WhiteContoMediumText,
  Typo12WhiteContoRegularText,
  Typo12WhiteTwoContoBoldText,
  Typo12WhiteTwoContoMediumText,
  Typo14LightYellowContoBoldText,
  Typo14OffWhiteContoMediumText,
  Typo14PrimaryGoldContoMediumText,
  Typo14SecondaryRedContoMediumText,
  Typo16PrimaryGoldBoldText,
  Typo16PrimaryGoldMediumText,
  Typo16WhiteContoBoldText,
  Typo16WhiteContoMediumText,
  Typo16WhiteTwoContoBoldText,
  Typo8WhiteTwoRegularText,
} from "../../../Common/styleGuide/Typos";

export const TourneyDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 59px);
  overflow: scroll;
`;

export const TourneyDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  margin-bottom: 20px;
`;

export const TourneyStartsInAndPrizeAmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: ${(props) =>
    props.isSitAndGoTourney ? "space-evenly" : "space-between"};

  align-items: center;
  padding: 12px;
  box-sizing: border-box;
  padding: 12px 24px 12px 24px;
`;

export const FlightTourneyStartsInAndPrizeAmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  justify-content: ${(props) =>
    props.isSitAndGoTourney ? "space-evenly" : "space-between"};
  border-radius: 8px;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
  padding: 12px 24px 12px 24px;
  background: rgba(2, 24, 30, 1);
`;

export const TourneyWinnersAndGameTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

export const FlightTourneyWinnersAndGameTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: ${(props) =>
    props.isFlightParent ? "space-between" : "center"};
  align-items: center;
  gap: 24px;
`;

export const GameTypeAndMaxPlayersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const GameTypeAndPlayersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 14px;
  justify-content: center;
  align-items: center;
`;

export const GameTypeIconWrapper = styled.span`
  width: 7px;
  height: 7px;
  background: rgba(240, 116, 92, 1);
  border-radius: 4px;
  margin-right: 4px;
`;

export const MaxPlayersIcon = styled.img`
  height: 12px;
  width: 12px;
  margin-right: 4px;
`;

export const FlightTourneySimilarFlightsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 58px;
  padding: 12px 16px 12px 12px;
  box-sizing: border-box;
  background: linear-gradient(
    91.67deg,
    rgba(51, 96, 112, 0.65) 0%,
    rgba(7, 80, 99, 0.65) 100%
  );
  border-radius: 8px;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;
`;

export const IconAndTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const FlightTourneyParentTourneyContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 58px;
  padding: 12px 16px 12px 12px;
  box-sizing: border-box;
  background: transparent;
  border: 1px solid rgba(7, 80, 99, 1);
  border-radius: 8px;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;
`;

export const IconWrapper = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const RotatingIcon = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 36px;
  width: 36px;

  animation: ${rotate} 8s linear infinite;
`;

export const CenterIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 24px;
  width: 24px;
  transform: translate(-50%, -50%);
`;

export const SimilarFlightsText = styled(Typo12PrimaryGoldContoMediumText)`
  line-height: 16.26px;
  margin-left: 4px;
`;

export const ParentTourneyText = styled(Typo12WhiteContoMediumText)`
  line-height: 16.26px;
  margin-left: 4px;
`;

export const TourneyStartTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
`;

export const TourneyRulesAccordianContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 12px 16px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    #075063;
  border-radius: ${(props) => (props.isOpened ? "8px 8px 0px 0px" : "8px")};
`;

export const TourneyRulesHeadingText = styled(Typo12WhiteContoMediumText)`
  line-height: 16.26px;
`;

export const TourneyRulesBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 100%;
  border: 1px solid rgba(7, 80, 99, 1);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 12px;
`;

export const StartsIntext = styled(Typo12GrayContoRegularText)`
  line-height: 24px;
`;

export const TimeText = styled(Typo14SecondaryRedContoMediumText)`
  line-height: 24px;
  min-width: 80px;
`;

export const PrizeAmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  align-items: center;
`;

export const TrophyIconWrapper = styled.img`
  height: 48px;
  width: 48px;
  margin-right: 12px;
`;

export const PrizeAmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PrizeWorthText = styled(Typo12GrayContoRegularText)`
  line-height: 16px;
  margin-bottom: 2px;
`;

export const ParentPrizeWorthText = styled(Typo12GrayContoRegularText)`
  line-height: 16px;
  margin-bottom: 2px;
  margin-left: 12px;
`;

export const PrizeAmountText = styled(Typo16PrimaryGoldBoldText)`
  line-height: 21px;
  display: flex;
  justify-content: left;
  align-items: center;
`;

export const PrizeBreakupText = styled(Typo12LeafyGreenContoMediumText)`
  line-height: 17px;
  border-bottom: 1.2px solid ${colors.leafyGreen};
`;

export const TourneyDetailTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-radius: 8px;
  border: 1px solid #336070;
  box-sizing: border-box;
  margin-top: 16px;
  background: linear-gradient(
    180deg,
    rgba(5, 58, 71, 0.8) 0%,
    rgba(4, 47, 58, 0.8) 100%
  );
  margin-bottom: 16px;
`;

export const TourneyDetailsTableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #336070;

  width: 100%;
  height: 43px;
  padding: 12px 16px 12px 16px;
  box-sizing: border-box;
`;

export const TourneyDetailsTableBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 12px 16px 12px 16px;
`;

export const TourneyDetailsText = styled(Typo12WhiteTwoContoBoldText)`
  line-height: 17px;
`;

export const TourneyBuyInAndWinnersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: space-around;
`;

export const BuyInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-right: 12px; */
  width: 25%;
`;

export const TourneyStackDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
`;

export const StackLabelText = styled(Typo12GrayContoRegularText)`
  line-height: 24px;
`;

export const StackValueText = styled(Typo14OffWhiteContoMediumText)`
  line-height: 24px;
  font-size: ${(props) => (props.isFlightParent ? "10px" : "14px")};
`;

export const BuyInText = styled(Typo12GrayContoRegularText)`
  line-height: 24px;
`;

export const BuyInAmountText = styled(Typo14OffWhiteContoMediumText)`
  line-height: 24px;
  font-size: ${(props) => (props.isFlightParent ? "10px" : "14px")};
`;

export const VerticalSeperator = styled.img`
  height: 40px;
`;

export const PlayersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
`;

export const PlayersText = styled(Typo12GrayContoRegularText)`
  line-height: 24px;
`;

export const PlayersCountText = styled(Typo14OffWhiteContoMediumText)`
  line-height: 24px;
`;

export const WinnersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-left: 12px; */
  width: 25%;
`;

export const WinnersText = styled(Typo12GrayContoRegularText)`
  line-height: 24px;
`;

export const WinnersCountText = styled(Typo14OffWhiteContoMediumText)`
  line-height: 24px;
`;

export const HorizontalSeperator = styled.img`
  width: 100%;

  margin-top: 4px;
  margin-bottom: 4px;
`;

export const TotalAddonsAndRebuyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 4px;
`;

export const AddOnsAndRebuyItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
`;

export const TableItemText = styled(Typo12GrayContoRegularText)`
  line-height: 24px;
`;

export const AddOnAndRebuyAmountDetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RebuyAmountText = styled(Typo14OffWhiteContoMediumText)`
  line-height: 24px;
  margin-left: 8px;
`;

export const RebuyAmountValue = styled(Typo14OffWhiteContoMediumText)`
  line-height: 24px;
  margin-left: 8px;
  color: ${colors.offWhite};
  /* font-size: 12px; */
  /* font-weight: 400; */
`;

export const UnlimitedRebuysText = styled(Typo10OffWhiteContoRegularText)`
  margin: 0 auto;
  line-height: 24px;
  font-size: 12px;
`;

export const ViewBlindStructureText = styled(Typo12LeafyGreenContoMediumText)`
  line-height: 24px;
  border-bottom: 1.2px solid ${colors.leafyGreen};
  width: auto;
`;

export const BlindsRightArrow = styled.img`
  height: 8px;
  width: 5px;
  margin-left: 4px;
`;

export const GoldOpenIcon = styled.span`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #ffb834;
  margin-right: 6px;
`;

export const LateRegistrationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  /* padding-left: 16px; */
`;

export const LateRegistrationText = styled(Typo12WhiteTwoContoMediumText)`
  line-height: 24px;
`;

export const LateRegistrationTimerText = styled(
  Typo12TourneySecondaryGreenMediumText
)``;

export const TourneyStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #336070;
  box-sizing: border-box;
  background: linear-gradient(
    180deg,
    rgba(5, 58, 71, 0.8) 0%,
    rgba(4, 47, 58, 0.8) 100%
  );
`;

export const TourneyStatsTableBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 12px 16px 12px 16px;
`;

export const TourneyStatsTableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #336070;
  width: 100%;
  height: 43px;
  padding: 12px 16px 12px 16px;
  box-sizing: border-box;
`;

export const TourneyStatsText = styled(Typo14OffWhiteContoMediumText)`
  line-height: 24px;
  margin-bottom: 8px;
`;

export const TourneyStatItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
`;

export const TourneyStatItemName = styled(Typo12GrayContoRegularText)`
  line-height: 24px;
`;

export const TourneyStatItemValue = styled(Typo12OffWhiteContoMediumText)`
  line-height: 24px;
`;

export const NextPrizeJumpAmountText = styled(Typo12PrimaryGoldContoMediumText)`
  margin-left: 4px;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: var(--app-width);
  height: var(--app-height);
  position: absolute;

  bottom: 0px;
  z-index: 5;
  background: ${colors.black60};
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
`;

export const PrizeBreakUpModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 0px;
  min-height: 360px;
  overflow: scroll;
  background: ${colors.tourneyInfoBackground};
  border-radius: 12px 12px 0px 0px;

  /* animation: expansion 0.25s linear;

  @keyframes expansion {
    from {
      min-height: 0px;
    }
    to {
      min-height: 320px;
    }
  } */

  animation: ${(props) =>
    props.isOpen
      ? "themes-move-up forwards 0.25s"
      : "themes-move-down forwards 0.25s"};

  @keyframes themes-move-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes themes-move-down {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }
`;

export const PrizeBreakupHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 12px 12px 0px 0px;
  padding: 16px 24px;
  box-sizing: border-box;
`;

export const PrizeBreakupHeaderText = styled(Typo16WhiteContoBoldText)`
  line-height: normal;
  margin: 0 auto;
`;

export const CloseIconWrapper = styled.img``;

export const PrizeBreakupBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 12px 16px 12px 16px;
  box-sizing: border-box;
  overflow: hidden;
  background: black;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 1px solid ${colors.secondaryBlue};
  background: ${colors.tourneyInfoBackground};
  border-radius: 4px;
  overflow: scroll;
`;

export const TableHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 8px 12px;
  border-bottom: 1px solid ${colors.secondaryBlue};
  height: 40px;
  min-height: 40px;
`;

export const BlindStructureTableHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 8px 12px;
  min-height: 40px;
  border-bottom: 1px solid ${colors.secondaryBlue};
`;

export const HeaderItemText = styled(Typo12GrayContoRegularText)`
  line-height: 24px;
`;
export const BlindStructureHeaderItemText = styled(Typo12GrayContoRegularText)`
  line-height: 24px;
  display: flex;
  justify-content: ${(props) =>
    props.textPosition ? props.textPosition : "center"};
  align-items: center;
  width: 25%;
`;

export const TableRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 8px 12px;
  height: 40px;
  min-height: 40px;
`;

export const BlindStructureTableRow = styled.div`
  display: flex;
  width: 100%;
  min-height: 40px;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 8px 12px;
  opacity: ${(props) => (props.disableRow ? 0.4 : 1)};
`;

export const TableRowItemText = styled(Typo12WhiteContoMediumText)`
  line-height: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const BlindStructureTableRowItemText = styled(
  Typo12WhiteContoMediumText
)`
  line-height: 24px;
  justify-content: ${(props) =>
    props.textPosition ? props.textPosition : "center"};
  width: 25%;
  align-items: center;
  display: flex;
`;

export const BlindStructureContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 0px;
  min-height: 400px;

  overflow: scroll;
  background: ${colors.tourneyInfoBackground};
  border-radius: 12px 12px 0px 0px;
  animation: expansion 0.25s linear;

  @keyframes expansion {
    from {
      height: 0px;
      min-height: 0px;
    }
    to {
      min-height: 400px;
    }
  }
`;

export const BlindStructureHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 12px 12px 0px 0px;
  padding: 16px 24px;
  box-sizing: border-box;
`;

export const BlindStructureText = styled(Typo16WhiteContoBoldText)`
  line-height: normal;
  margin: 0 auto;
`;

export const BlindStructureBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 12px 16px 12px 16px;
  box-sizing: border-box;
  overflow: hidden;
  background: black;
`;

export const TourneyRulesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${(props) =>
    props.isOpen
      ? ` ${props.rulesLength * 28 > 92 ? "auto" : `92px`}`
      : "92px"};
  box-sizing: border-box;
  background-color: ${colors.secondaryDeepBlue};
  border-radius: 4px;
  overflow-wrap: anywhere;
  padding: 12px 0;
  overflow: hidden;
  text-align: left;
  margin-bottom: 4px;
  transition: height 300ms;
`;

export const RulesListOrderedWrapper = styled.ol`
  list-style-position: inside;
`;

export const RulesListItemWrapper = styled.li`
  color: ${colors.white};
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  margin-left: 0px !important;
`;

export const DescriptionLabelText = styled(Typo14OffWhiteContoMediumText)`
  line-height: 24px;
  margin-bottom: 8px;
`;

export const AccordianIcon = styled.img`
  width: 16px;
  height: 8px;
  margin: 0 auto;
  margin-bottom: 12px;
  rotate: ${(props) => (props.isOpen ? "180deg" : "0deg")};
`;

export const MaxRunTimeText = styled(Typo12WhiteContoMediumText)`
  line-height: 24px;
  text-align: center;
  margin-bottom: 12px;
`;

export const SitAndGoMessageText = styled(Typo12GrayContoMediumText)`
  line-height: 24px;
  text-align: center;
  margin-bottom: 12px;
`;

export const ChildTourneysText = styled(Typo14OffWhiteContoMediumText)`
  line-height: 24px;
  margin-bottom: 8px;
`;

export const ChildTourneysContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  height: ${(props) => (props.isExpansionOpened ? "auto" : "290px")};
  overflow: hidden;
`;

export const ParentTourneysText = styled(Typo14OffWhiteContoMediumText)`
  line-height: 24px;
  margin-bottom: 8px;
`;

export const ParentTourneysContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  // height: ${(props) => (props.isExpansionOpened ? "auto" : "290px")};
  margin: 3% 0;
  overflow: hidden;
`;

export const ViewMoreOrLessWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  box-sizing: border-box;
`;

export const ExpansionPanelText = styled(Typo12GrayContoMediumText)``;

export const ExpansionAccordianIcon = styled.img`
  height: 8px;
  width: 12px;
  margin-left: 8px;
  rotate: ${(props) => (props.isOpened ? "180deg" : "0deg")};
`;

export const TicketIcon = styled.img`
  height: 13px;
  width: 13px;
`;

export const AddIconText = styled(Typo16WhiteContoMediumText)`
  font-weight: 700;
  margin-left: 4px;
  margin-right: 4px;
`;

export const TicketsCountText = styled(Typo14PrimaryGoldContoMediumText)`
  margin-right: 2px;
`;

export const DynamicPayoutStructureText = styled(Typo12GrayContoRegularText)`
  line-height: 24px;
  display: flex;
  width: 100%;
  justify-content: center !important;
  align-items: center !important;
  text-align: center;
  margin-top: 8px;
  /* height: 100%; */
`;

export const TourneyTimersContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 16px 0px 16px 0px;
  box-sizing: border-box;
`;

export const TourneyTimersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 16px 0px 16px 0px;
  box-sizing: border-box;
  border-radius: 6px;
  /* background: linear-gradient(0deg, #02181e, #02181e),
    linear-gradient(0deg, #162930, #162930); */
  background: #162930;
`;

export const CurrentTimerTitle = styled(Typo12GrayContoRegularText)`
  line-height: 16.26px;
`;

export const RunningTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 8px;
`;

export const TimePeriodAndPeriodNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
`;

export const TimePeriodWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 32px;
  border-right: 1.2px solid rgb(52, 84, 94);
  border-bottom: 1.2px solid rgb(23, 50, 58);

  border-radius: 4px;
  background: linear-gradient(
    180deg,
    rgba(53, 93, 108, 0.65) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );

  box-shadow: 2px 2px 1px 0px #02181e inset;
`;

export const PeriodNameText = styled(Typo11DownyContoMediumText)`
  margin-top: 4px;
  line-height: 14.91px;
`;

export const PeriodValueText = styled(Typo16WhiteTwoContoBoldText)`
  line-height: 21.68px;
`;

export const GreenActiveIcon = styled.span`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: ${colors.tourneySecondaryGreen};
  margin-right: 6px;
`;

export const ActivePlayersCountDetails = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
`;

export const ActivePlayerCountText = styled(Typo12PrimaryGoldContoRegularText)`
  line-height: 13.55px;
`;

export const RemainingText = styled(Typo12PrimaryGoldContoRegularText)`
  line-height: 10.84px;
  margin-left: 4px;
`;

export const ReEntriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-left: 12px; */
  width: 25%;
`;

export const ReEntriesText = styled(Typo12GrayContoRegularText)`
  line-height: 24px;
`;

export const ReEntryCountText = styled(Typo14OffWhiteContoMediumText)`
  line-height: 24px;
`;

export const TourneyOnBreakContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 26px;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 4;
  bottom: 59px;
  left: 0px;
  background: #67401a;
`;

export const CoffeeIconWrapper = styled.img`
  height: 14px;
  width: 14px;
  margin-top: -4px;
`;

export const TourneyBreakMessageText = styled(Typo10WhiteContoMediumText)`
  line-height: 13.55px;
  margin-left: 4px;
`;

export const BreakTimeText = styled(Typo10YellowOrangeBoldText)`
  line-height: 13.55px;
  margin-left: 4px;
`;

export const PlayerIconWrapper = styled.img`
  height: 10px;
  width: 10px;
  margin-right: 4px;
`;

export const DepositorTourneyEntriesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
`;

export const DirectEntriesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const GrayInfoIconWrapper = styled.img`
  height: 12px;
  width: 12px;
  margin-left: 4px;
`;

export const TicketEntriesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const DirectEntriesAndTicketEntriesCountText = styled(
  Typo14OffWhiteContoMediumText
)`
  line-height: 24px;
  margin-left: 8px;
`;

export const TooltipText = styled(Typo12WhiteContoRegularText)`
  display: flex;
  background-color: ${colors.vampireGrey};
  color: white;
  position: absolute;
  min-width: 80%;
  max-width: auto;

  border-radius: 4px;
  padding: 2px 4px 2px 4px;
  box-sizing: border-box;

  background-color: #135364;

  white-space: nowrap;
  overflow: hidden;
  box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 1);
`;

export const TooltipArrow = styled.span`
  position: absolute;
  display: block;
  width: 0px;
  left: 50%;
  bottom: 0;
  border: 10px solid transparent;
  border-bottom: 0;
  border-top: 10px solid;
  border-top-color: #135364;
  transform: ${(props) =>
    props.tooltipPosition
      ? `translateX(140px) translateY(30px)`
      : "translateX(68px) translateY(30px)"};
`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -25px;
  left: ${(props) => (props.tooltipPosition ? "-70%" : "0px")};
`;

export const TourneyWinnersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

export const TourneyConcludedText = styled(Typo16WhiteContoBoldText)`
  line-height: 21.68px;
`;

export const WinnersListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const WinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const FirstWinnerIconWrapper = styled.img`
  height: 88px;
  width: 88px;
`;

export const WinnerIconContainer = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WinnerIconWrapper = styled.img`
  height: 66px;
  width: 66px;
`;

export const WinnerUserNameText = styled(Typo12GrayContoMediumText)`
  line-height: 16.26px;
`;

export const WinnerPrizeAmountText = styled(Typo16WhiteContoBoldText)`
  line-height: 21.68px;
`;

export const ViewAllWinnersText = styled(Typo12LeafyGreenContoMediumText)`
  line-height: 17px;
  border-bottom: 1.2px solid ${colors.leafyGreen};
`;
