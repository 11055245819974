import Handler from "./../Handler";

class DynamicPrivateTableLobbyDataHandler extends Handler {
  // dptld#
  execute(data) {
    let receivedData = JSON.parse(data);
    this.service.processDynamicPrivateTableLobbyData(receivedData);
  }
}

export default DynamicPrivateTableLobbyDataHandler;
