import React, { useEffect, useState } from "react";
import {
  AddCashButton,
  ArrowIcon,
  ArrowOption,
  ArrowOptionIcon,
  ArrowOptionLeft,
  ArrowOptionName,
  ArrowOptionRight,
  BottomRowOne,
  BottomRowTwo,
  ConsolidatedMenuBottomSection,
  ConsolidatedMenuContainer,
  ConsolidatedMenuHeader,
  GameControl,
  GameControlIcon,
  GameControlName,
  GameControlsContainer,
  GameInfoButton,
  GameToggleButton,
  HandRankingButton,
  WalletInfoContainer,
  WalletText,
} from "./ConsolidatedMenuStyle";
// import * as gameAssets from "../../routes/Game/gameAssets";
import goToLobby from "../../../assets/gameTable/optionMenu/goToLobby.svg";
import leaveSeat from "../../../assets/gameTable/optionMenu/leaveSeat.svg";
import leaveTable from "../../../assets/gameTable/optionMenu/leaveTable.svg";
import sitOut from "../../../assets/gameTable/optionMenu/sitOut.svg";
import info from "../../../assets/gameTable/info.svg";
import themes from "../../../assets/gameTable/optionMenu/theme.svg";
import setting from "../../../assets/gameTable/setting.svg";
import handRanking from "../../../assets/gameTable/optionMenu/handRanking.svg";
import landscapeMode from "../../../assets/gameTable/optionMenu/landscapeMode.svg";
import goldWalletIcon from "../../../assets/lobby/GoldWalletIcon.svg";
import close from "../../../assets/common/close.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  closeSlider,
  openSlider,
  updateSliderAnimatingFlag,
} from "../../redux/slices/sliderSlice";
import {
  ACCOUNT_ISSUE_GAME_JOIN,
  CONNECTION_REQUESTS_LIMIT_REACHED,
  DATA_LOST_ISSUE_GAME_JOIN,
  DISCONNECTION,
  FUN_CHANNELS,
  GAME_CONNECTION_PROTOCOL_SUCCESS,
  GAME_INFO,
  GAME_LOOSER_MODAL,
  GAME_TABLE_WATCH_CHANNEL_EXIST,
  HAND_RANKING,
  LB_PLAY,
  LB_REAL,
  LEAVE_SEAT,
  LEAVE_TABLE,
  MIXPANEL_EVENT,
  RELOGIN_FAILURE,
  RESTRICTED_LOCATION_GAME_EXIT,
  RESTRICTED_LOCATION_HOURS,
  TABLE_SETTINGS,
  THEMES,
  TOURNEY_CANCEL_WITH_HOLD,
  TOURNEY_CANCEL_WITH_REFUND,
  TOURNEY_GAME_COMPLETE,
  TOURNEY_GAME_WINNER_MODAL,
  TOURNEY_WATCH_GAME_COMPLETED,
  WALLET_BALANCE_ISSUE_GAME_JOIN,
} from "../../data/Constants";
import {
  hideGameTable,
  openLocalGameModal,
  toggleSitOutAction,
  watchAllGameTables,
} from "../../redux/slices/gameTableSlice";
import { Loader } from "../Loader/Loader";
import { DisabledToggle } from "../../../Common/components/Slider/TableSettings/TableSettingsStyle";
import { Toggle } from "../../../Common/components/Slider/TableSettings/TableSettingsStyle";
import toggleOff from "../../../assets/lobby/NewToggleOff.png";
import toggleOn from "../../../assets/lobby/NewToggleOn.png";
import { messageToNativeClient } from "../../../Common/utils/platformCommunicationUtil";
import rightArrow from "../../../assets/common/right-arrow.png";
import {
  closeTourneyInfoPage,
  requestCurrentFunChipsBalance,
} from "../../redux/slices/lobbySlice";
import CleverTapUtil from "../../../Common/utils/CleverTapUtil";
import {
  IP_Client_ExitTable_Click,
  IP_Client_LeaveSeat_Click,
} from "../../data/ClevertapConstants";
import { getISTDateTimeFormat } from "../../../Common/utils/GameUtils";
import TableUtils from "../../utils/TableUtils";
import { logCleverTapEvent } from "../../../Common/utils/CleverTapUtilNew";
import { getEventParams } from "../../utils/MixPanelUtils";
import {
  MP_POKER_GAMETABLE_EXIT_TABLE_CLICKED,
  MP_POKER_GAMETABLE_LEAVE_SEAT_CLICKED,
  MP_POKER_GAMETABLE_SITOUT_TOGGLE_CLICKED,
} from "../../data/MixPanelConstant";

const ConsolidatedMenu = ({ sliderDirection, closeSliderProp }) => {
  const dispatch = useDispatch();
  const lobby = useSelector((state) => state.lobby);
  const activeGame = useSelector((state) => state.gameTable.activeGame);
  const sitOutSetting = useSelector(
    (state) => state.gameTable.games[activeGame]?.sitOutOption
  );
  const sitOutTimer = useSelector(
    (state) => state.gameTable.games[activeGame]?.playerSitOutTimer
  );
  const gameData = useSelector((state) => state.gameTable.games[activeGame]);
  const restrictUserFromLeavingTable = useSelector(
    (state) => state.gameTable.games[activeGame]?.restrictUserFromLeavingTable
  );
  const restrictSitOut = useSelector(
    (state) => state.gameTable.games[activeGame]?.restrictUserFromSitOut
  );
  const sitOutButtonDisabled = useSelector(
    (state) => state.gameTable.games[activeGame]?.sitOutButtonDisabled
  );
  const mySeat = useSelector(
    (state) => state.gameTable.games[activeGame]?.playerSeat
  );
  const modalType = useSelector(
    (state) => state.gameTable.games[activeGame]?.gameModal?.type
  );
  const isTourneyGame = useSelector(
    (state) => state.gameTable.games[activeGame]?.isTourneyGame
  );
  const isPrivateTable = useSelector(
    (state) => state.gameTable.games[activeGame].privateTable.isPrivateTable
  );
  const [loading, setLoading] = useState(false);
  const [lastClickTime, setLastClickTime] = useState(0);
  const gameConnectionStatus = useSelector(
    (state) => state.gameTable.games[activeGame]?.gameConnectionStatus
  );
  const isFunGame = useSelector(
    (state) => state.gameTable.games[activeGame]?.isFunGame
  );
  const channel = useSelector((state) => state.lobby.channel);
  const player = useSelector((state) => state.lobby.player);
  const userBalance = useSelector((state) => state.lobby.balance);

  const handleActionWithInterval = (callback) => {
    // You can adjust the time gap (500ms in this example)
    const timeGap = 500;
    const currentTime = new Date().getTime();

    // Check if the time gap is met
    if (currentTime - lastClickTime >= timeGap) {
      // Perform your button click logic here
      callback();
    }
    setLastClickTime(currentTime);
  };

  const closeMenu = () => {
    dispatch(closeSlider());
  };

  const handleBottomRowClick = (type) => {
    closeMenu();

    if (type === "hand-rankings") {
      dispatch(
        openLocalGameModal({ type: HAND_RANKING, tempTableId: activeGame })
      );
    }

    if (type === "game-info") {
      // dispatch(
      //   openLocalGameModal({ type: INFORMATION, tempTableId: activeGame })
      // );
      dispatch(openSlider({ type: GAME_INFO }));
    }

    if (type === "add-cash") {
      let temp = new Date();
      let timeStamp = new Date(temp.getTime() + 330 * 60 * 1000);
      timeStamp = timeStamp.toISOString();
      messageToNativeClient({
        type: "plotlineEvents",
        eventName: "PR_Client_CashFunToggle_Click",
        eventProperties: {
          userName: player.userName,
          channel: channel,
          currentCashBalance: userBalance.totalBalance,
          timeStamp: timeStamp,
          clickLocation: "GameTable_AddCash",
        },
      });
      messageToNativeClient({ type: "openaddcash" });
    }
  };

  let gameControls = [
    {
      name: "Exit Table",
      asset: leaveTable,
      action: () => {
        let timeStamp = getISTDateTimeFormat();
        messageToNativeClient({
          type: "plotlineEvents",
          eventName: "PR_Client_ExitTable_Click",
          eventProperties: {
            userName: player.userName,
            channel,
            currentCashBalance: userBalance.totalBalance,
            timeStamp,
            bigBlind: gameData.bigBlind,
            smallBlind: gameData.smallBlind,
            noOfPlayers: gameData.maxPlayers,
            gameType: gameData.gameType,
          },
        });

        const MixPanel_params = getEventParams(
          MP_POKER_GAMETABLE_EXIT_TABLE_CLICKED,
          player,
          userBalance,
          channel,
          gameData?.gameDefinition
        );

        messageToNativeClient({
          type: MIXPANEL_EVENT,
          eventName: MP_POKER_GAMETABLE_EXIT_TABLE_CLICKED,
          eventProperties: MixPanel_params,
        });
        logCleverTapEvent(IP_Client_ExitTable_Click, {
          Username: player.userName,
          Channel: channel,
          "Wallet balance": userBalance?.totalBalance,
          Timestamp: timeStamp,
          "Big blind": gameData.gameDefinition?.bigBlind,
          "No. of players": gameData.gameDefinition?.maxPlayers,
          "Game type": gameData.gameDefinition?.gameType,
          "Table name": gameData.gameDefinition?.tableName,
          "Game mode": gameData.gameDefinition?.gameMode,
        });
        if (!restrictUserFromLeavingTable) {
          if (isTourneyGame) {
            dispatch(closeTourneyInfoPage());
          }
          if (isFunGame) {
            dispatch(requestCurrentFunChipsBalance());
          }
          closeMenu();
          // TableUtils.getInstance().removeTableObserver(activeGame, false);
          dispatch(
            openLocalGameModal({ type: LEAVE_TABLE, tempTableId: activeGame })
          );
        }
      },
      availabilityOffline: false,
    },
    {
      name: "Leave Seat",
      asset: leaveSeat,
      action: () => {
        let timeStamp = getISTDateTimeFormat();
        messageToNativeClient({
          type: "plotlineEvents",
          eventName: "PR_Client_LeaveSeat_Click",
          eventProperties: {
            userName: player.userName,
            channel,
            currentCashBalance: userBalance.totalBalance,
            timeStamp,
            bigBlind: gameData.gameDefinition.bigBlind,
            smallBlind: gameData.gameDefinition.smallBlind,
            noOfPlayers: gameData.gameDefinition.maxPlayers,
            gameType: gameData.gameDefinition.gameType,
          },
        });
        const MixPanel_params = getEventParams(
          MP_POKER_GAMETABLE_LEAVE_SEAT_CLICKED,
          player,
          userBalance,
          channel,
          {
            ...gameData?.gameDefinition,
            GameVariant: gameData?.gameDefinition?.flavourType
              ? gameData?.gameDefinition?.flavourType === LB_REAL
                ? "Cash"
                : LB_PLAY
              : null,
          }
        );

        messageToNativeClient({
          type: MIXPANEL_EVENT,
          eventName: MP_POKER_GAMETABLE_LEAVE_SEAT_CLICKED,
          eventProperties: MixPanel_params,
        });
        logCleverTapEvent(IP_Client_LeaveSeat_Click, {
          Username: player.userName,
          Channel: channel,
          "Wallet balance": userBalance?.totalBalance,
          Timestamp: timeStamp,
          "Big blind": gameData.gameDefinition?.bigBlind,
          "No. of players": gameData.gameDefinition?.maxPlayers,
          "Game type": gameData.gameDefinition?.gameType,
          "Table name": gameData.gameDefinition?.tableName,
          "Game mode": gameData.gameDefinition?.gameMode,
        });
        closeMenu();
        if (!restrictUserFromLeavingTable) {
          dispatch(
            openLocalGameModal({ type: LEAVE_SEAT, tempTableId: activeGame })
          );
        }
      },
      availabilityOffline: false,
    },
    {
      name: "Sit Out",
      asset: sitOut,
      action: () => {
        const MixPanel_params = getEventParams(
          MP_POKER_GAMETABLE_SITOUT_TOGGLE_CLICKED,
          player,
          userBalance,
          channel,
          gameData?.gameDefinition
        );

        messageToNativeClient({
          type: MIXPANEL_EVENT,
          eventName: MP_POKER_GAMETABLE_SITOUT_TOGGLE_CLICKED,
          eventProperties: MixPanel_params,
        });
        if (sitOutSetting && !sitOutTimer) {
          return dispatch(toggleSitOutAction({ type: "sitOutCancel" }));
        } else {
          if (!sitOutButtonDisabled && !restrictSitOut) {
            setLoading(true);
            return dispatch(toggleSitOutAction({ type: "toggle" }));
          }
        }
      },
      availabilityOffline: false,
    },
    {
      name: "Go to Lobby",
      asset: goToLobby,
      action: () => {
        // if (!gameData?.disableOrientationChange) {
        if (isTourneyGame) {
          dispatch(closeTourneyInfoPage());
        }
        if (isFunGame) {
          dispatch(requestCurrentFunChipsBalance());
        }
        closeMenu();
        dispatch(watchAllGameTables({ dispatchFunction: dispatch }));
        dispatch(hideGameTable({ tempTableId: activeGame }));
        // }
      },
      availabilityOffline: true,
    },
    // {
    //   name:
    //     tableOrientation === "PORTRAIT" ? "Landscape Mode" : "Portrait Mode",
    //   asset: landscapeMode,
    //   action: () => {
    //     if (!gameData?.disableOrientationChange) {
    //       closeMenu();
    //       dispatch(changeTableOrientation());
    //     }
    //     return;
    //   },
    //   availabilityOffline: false,
    // },
  ];
  if (isTourneyGame || isPrivateTable) {
    gameControls = [...gameControls].filter(
      (eachControl) => eachControl.name !== "Leave Seat"
    );
  }

  let arrowOptions = [
    {
      name: "Table Themes",
      asset: themes,
      action: () => {
        closeMenu();
        dispatch(openSlider({ type: THEMES }));
      },
      availabilityOffline: false,
    },
    {
      name: "Table Settings",
      asset: setting,
      action: () => {
        closeMenu();
        dispatch(openSlider({ type: TABLE_SETTINGS }));
      },
      availabilityOffline: false,
    },
  ];

  useEffect(() => {
    if (!sitOutButtonDisabled) {
      setLoading(false);
    }
  }, [sitOutButtonDisabled]);

  return (
    <ConsolidatedMenuContainer
      onAnimationEnd={() => {
        if (sliderDirection === "close") {
          dispatch(closeSlider());
        }
        dispatch(updateSliderAnimatingFlag({ value: false }));
      }}
      sliderDirection={sliderDirection}
    >
      <div>
        <ConsolidatedMenuHeader>
          {isTourneyGame ? "Tourney" : "Game"} Controls
          <img onClick={() => closeSliderProp()} src={close}></img>
        </ConsolidatedMenuHeader>

        <GameControlsContainer>
          {gameControls.map((control, index) => {
            //TOURNEY_GAME_COMPLETE added here as it is not having the redux value "restrictUserFromLeavingTable" set to true
            if (
              (modalType === DISCONNECTION ||
                modalType === RELOGIN_FAILURE ||
                modalType === CONNECTION_REQUESTS_LIMIT_REACHED ||
                modalType === ACCOUNT_ISSUE_GAME_JOIN ||
                modalType === WALLET_BALANCE_ISSUE_GAME_JOIN ||
                modalType === DATA_LOST_ISSUE_GAME_JOIN ||
                modalType === RESTRICTED_LOCATION_GAME_EXIT ||
                modalType === TOURNEY_GAME_COMPLETE ||
                modalType === GAME_TABLE_WATCH_CHANNEL_EXIST ||
                modalType === TOURNEY_WATCH_GAME_COMPLETED ||
                modalType === TOURNEY_CANCEL_WITH_HOLD ||
                modalType === TOURNEY_CANCEL_WITH_REFUND ||
                modalType === RESTRICTED_LOCATION_HOURS) &&
              !control.availabilityOffline
            ) {
              return;
            }

            if (control.name === "Sit Out" || control.name === "Sit In") {
              if (isTourneyGame) {
                //NOTE: For tourneys we are hiding sitout option in consolidated menu
                return;
              }
              return (
                <GameControl
                  restricted={isTourneyGame}
                  mySeat={mySeat}
                  name={control.name}
                  onClick={() => {
                    if (!isTourneyGame) {
                      handleActionWithInterval(control.action);
                    }
                  }}
                  key={"Consolidated-Menu-Game-Control-" + index}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    <GameControlIcon src={control.asset} />
                    <GameControlName>{control.name}</GameControlName>
                  </div>
                  <GameToggleButton>
                    {loading || restrictSitOut ? (
                      // !gameData?.showStartGameTimer &&
                      // !restrictUserFromLeavingTable ?
                      <div
                        style={{
                          width: "50px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Loader size={20} />
                      </div>
                    ) : restrictSitOut ? (
                      <DisabledToggle
                        src={sitOutSetting ? toggleOn : toggleOff}
                      />
                    ) : (
                      <Toggle src={sitOutSetting ? toggleOn : toggleOff} />
                    )}
                  </GameToggleButton>
                </GameControl>
              );
            } else if (
              control.name === "Go to Lobby" ||
              control.name === "Leave Seat" ||
              control.name === "Exit Table"
              // (control.name === tableOrientation) === "PORTRAIT"
              //   ? "Landscape Mode"
              //   : "Portrait Mode"
            ) {
              return (
                <GameControl
                  restricted={restrictUserFromLeavingTable}
                  // disableModeChange={gameData?.disableOrientationChange}
                  mySeat={mySeat}
                  name={control.name}
                  onClick={() => control.action()}
                  key={"Consolidated-Menu-Game-Control-" + index}
                >
                  <GameControlIcon src={control.asset} />
                  <GameControlName>{control.name}</GameControlName>
                </GameControl>
              );
            } else {
              return (
                <GameControl
                  restricted={restrictUserFromLeavingTable}
                  disableModeChange={
                    gameData?.disableOrientationChange ||
                    restrictUserFromLeavingTable
                  }
                  mySeat={mySeat}
                  name={control.name}
                  onClick={() => control.action()}
                  key={"Consolidated-Menu-Game-Control-" + index}
                >
                  <GameControlIcon src={control.asset} />
                  <GameControlName>{control.name}</GameControlName>
                </GameControl>
              );
            }
          })}
        </GameControlsContainer>

        {gameConnectionStatus === GAME_CONNECTION_PROTOCOL_SUCCESS &&
          arrowOptions.map((arrowOption, index) => {
            //TOURNEY_GAME_COMPLETE added here as it is not having the redux value "restrictUserFromLeavingTable" set to true
            //some of the modal types might not be required as they could be filtered out based on "gameConnectionStatus" - till phase 1 release
            if (
              (modalType === DISCONNECTION ||
                modalType === RELOGIN_FAILURE ||
                modalType === CONNECTION_REQUESTS_LIMIT_REACHED ||
                modalType === ACCOUNT_ISSUE_GAME_JOIN ||
                modalType === WALLET_BALANCE_ISSUE_GAME_JOIN ||
                modalType === DATA_LOST_ISSUE_GAME_JOIN ||
                modalType === RESTRICTED_LOCATION_GAME_EXIT ||
                modalType === TOURNEY_GAME_COMPLETE ||
                modalType === TOURNEY_GAME_WINNER_MODAL ||
                modalType === GAME_LOOSER_MODAL ||
                modalType === GAME_TABLE_WATCH_CHANNEL_EXIST ||
                modalType === TOURNEY_WATCH_GAME_COMPLETED ||
                modalType === TOURNEY_CANCEL_WITH_HOLD ||
                modalType === TOURNEY_CANCEL_WITH_REFUND) &&
              !arrowOption.availabilityOffline
            ) {
              return;
            }
            return (
              <ArrowOption
                id={arrowOption.name.replace(/\s/g, "")}
                onClick={() => arrowOption.action()}
                key={"Arrow-Option-Consolidated-Menu-" + index}
              >
                <ArrowOptionLeft>
                  <ArrowOptionIcon src={arrowOption.asset} />
                  <ArrowOptionName>{arrowOption.name}</ArrowOptionName>
                </ArrowOptionLeft>
                <ArrowOptionRight>
                  <ArrowIcon src={rightArrow} />
                </ArrowOptionRight>
              </ArrowOption>
            );
          })}
        <div
          style={{ width: "100%", height: "2px", backgroundColor: "#0D4D5E" }}
        ></div>
      </div>

      {modalType === DISCONNECTION ||
      modalType === RELOGIN_FAILURE ||
      modalType === CONNECTION_REQUESTS_LIMIT_REACHED ||
      modalType === ACCOUNT_ISSUE_GAME_JOIN ||
      modalType === WALLET_BALANCE_ISSUE_GAME_JOIN ||
      modalType === DATA_LOST_ISSUE_GAME_JOIN ||
      modalType === RESTRICTED_LOCATION_GAME_EXIT ||
      modalType === TOURNEY_GAME_COMPLETE ||
      modalType === TOURNEY_GAME_WINNER_MODAL ||
      modalType === GAME_LOOSER_MODAL ||
      modalType === GAME_TABLE_WATCH_CHANNEL_EXIST ||
      modalType === TOURNEY_WATCH_GAME_COMPLETED ||
      modalType === TOURNEY_CANCEL_WITH_HOLD ||
      modalType === TOURNEY_CANCEL_WITH_REFUND ? null : (
        //above values contain tourney alert types too to avoid closure of the alert which needs mandatory action
        <ConsolidatedMenuBottomSection>
          <BottomRowOne>
            {!isTourneyGame && (
              <GameInfoButton onClick={() => handleBottomRowClick("game-info")}>
                <GameControlIcon style={{ marginLeft: "10%" }} src={info} />
                <GameControlName>Game Info</GameControlName>
              </GameInfoButton>
            )}
            <HandRankingButton
              isTourneyGame={isTourneyGame}
              onClick={() => handleBottomRowClick("hand-rankings")}
            >
              <GameControlIcon
                style={{ marginLeft: `${isTourneyGame ? 0 : 10}%` }}
                src={handRanking}
              />
              <GameControlName>Hand Rankings</GameControlName>
            </HandRankingButton>
          </BottomRowOne>
          {!FUN_CHANNELS.includes(channel) && (
            <BottomRowTwo>
              <WalletInfoContainer>
                <GameControlIcon src={goldWalletIcon} />
                <WalletText>&#8377;{lobby.balance.totalBalance}</WalletText>
              </WalletInfoContainer>
              <AddCashButton onClick={() => handleBottomRowClick("add-cash")}>
                + Cash
              </AddCashButton>
            </BottomRowTwo>
          )}
        </ConsolidatedMenuBottomSection>
      )}
    </ConsolidatedMenuContainer>
  );
};

export default ConsolidatedMenu;
