import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";
import {
  Typo14PureWhiteContoMediumText,
  Typo14PrimaryGoldContoMediumText,
  Typo10GrayContoRegularText,
  Typo14WhiteContoBoldText,
  Typo12WhiteContoBoldText,
} from "../../../Common/styleGuide/Typos.js";

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 9;
  top: 40%;
  left: 0px;
`;

export const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 30px;
  border-top: 1px solid ${colors.tourneyBannerBorder};
  border-bottom: 1px solid ${colors.tourneyBannerBorder};
  background: ${colors.tourneyBannerBackground};
  padding: 12px 20px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
`;

export const TournamentBreakTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const BannerMessageText = styled(Typo14PureWhiteContoMediumText)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ChipIconWrapper = styled.img`
  width: 12px;
  height: 12px;
`;

export const BlindsDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SmallBlindAndBigBlindDetailsText = styled(
  Typo12WhiteContoBoldText
)`
  margin-left: 6px;
`;

export const TimerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  margin-top: 12px;
  height: auto;
`;

export const MinutesAndSecondsWrapper = styled.div`
  display: flex;
  width: auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .time-divider {
    font-size: 16px;
    margin-top: -20px;
    margin-left: 6px;
    margin-right: 4px;
  }
`;

export const TimeText = styled(Typo14PrimaryGoldContoMediumText)``;

export const TimeTextWrapper = styled.div`
  display: flex;
  width: 18px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  border-bottom: 0.5px solid var(--Secondary-Gold, #e6c683);
  background: #031d24;
  box-shadow: 0px 0.5px 1px 0px rgba(158, 195, 216, 0.25),
    0px 1px 3px 0px rgba(0, 0, 0, 0.73) inset;
  margin-left: 4px;
`;

export const MinutesAndSecondChildrenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
`;

export const TimeNumbersParentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  margin-right: 4px;
`;

export const TimeLabelText = styled(Typo10GrayContoRegularText)`
  line-height: normal;
  margin-top: 4px;
  margin-left: 4px;
`;

export const TourneyAddOnButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

export const PrimaryCTAButtonText = styled(Typo14WhiteContoBoldText)`
  line-height: normal;
`;
