import React from "react";

import GoldRoundShimmer from "../../../assets/lobby/GoldRoundShimmer.png";
import FlightTickets from "../../../assets/lobby/FlightTickets.png";
import RightLeafyGreenArrow from "../../../assets/lobby/RightLeafyGreenArrow.png";

import {
  FlightTourneyParentTourneyContainer,
  FlightTourneySimilarFlightsContainer,
  IconAndTextWrapper,
  IconWrapper,
  RotatingIcon,
  SimilarFlightsText,
  CenterIcon,
  ParentTourneyText,
  BlindsRightArrow,
  PrizeBreakupText,
} from "./similarFlightsStyledComponents";
import { useDispatch } from "react-redux";
import { updateSelectedFlightDetailsTabbar } from "../../redux/slices/lobbySlice";

function TourneyFlightParentAndSimilarFlightsCard(props) {
  const dispatch = useDispatch();
  const {
    isParentTourney,
    similarFlights,
    satellites,
    parentTourneyName,
    threshold,
  } = props;

  const navigateToFlightsTab = () => {
    if (satellites) {
      dispatch(
        updateSelectedFlightDetailsTabbar({ selectedTab: "satellites" })
      );
    }
    props.handleTourneyNavigation && props.handleTourneyNavigation();
  };

  const renderParentFlightTourneyCard = () => {
    return (
      <FlightTourneyParentTourneyContainer>
        <IconAndTextWrapper>
          <IconWrapper>
            <RotatingIcon src={GoldRoundShimmer} />
            <CenterIcon src={FlightTickets} />
          </IconWrapper>
          <ParentTourneyText>{parentTourneyName}</ParentTourneyText>
        </IconAndTextWrapper>
        <PrizeBreakupText onClick={navigateToFlightsTab}>
          Details
          <BlindsRightArrow src={RightLeafyGreenArrow} />
        </PrizeBreakupText>
      </FlightTourneyParentTourneyContainer>
    );
  };

  const renderSimilarFlightsCard = () => {
    return (
      <FlightTourneySimilarFlightsContainer
        highlightTile={similarFlights <= threshold}
      >
        <IconAndTextWrapper>
          <IconWrapper>
            <RotatingIcon src={GoldRoundShimmer} />
            <CenterIcon src={FlightTickets} />
          </IconWrapper>

          {!satellites && (
            <SimilarFlightsText>
              {similarFlights === 1
                ? `ONLY ${similarFlights} SIMILAR FLIGHT AVAILABLE`
                : `${similarFlights} SIMILAR FLIGHTS`}
            </SimilarFlightsText>
          )}

          {satellites && (
            <SimilarFlightsText>{`${similarFlights} ${
              satellites ? "SATELLITE" : "SIMILAR"
            } FLIGHTS`}</SimilarFlightsText>
          )}
        </IconAndTextWrapper>
        <PrizeBreakupText onClick={navigateToFlightsTab}>
          Details
          <BlindsRightArrow src={RightLeafyGreenArrow} />
        </PrizeBreakupText>
      </FlightTourneySimilarFlightsContainer>
    );
  };

  return isParentTourney
    ? renderParentFlightTourneyCard()
    : renderSimilarFlightsCard();
}

export default TourneyFlightParentAndSimilarFlightsCard;
