import styled from "styled-components";

export const TableWrapper = styled.div`
  width: 100%;
  border: 1px solid #075063;
  border-radius: 4px;
  margin: 5px 0;
  min-height: 100px;
  height: ${(props) => (props.height ? props.height : 200)}px;
  overflow: auto;
`;

export const TableContainer = styled.table`
  width: ${(props) => (props.width ? props.width : 100)}%;
  table-layout: fixed;
  border-collapse: collapse;
`;

export const TableHeader = styled.thead`
  height: 35px;
  position: sticky;
  top: 0;
  background: #05353f;
  border-bottom: 1px solid rgb(7, 80, 99);
`;

export const HeaderColumn = styled.th`
  width: 100%;
  text-transform: capitalize;
  font-family: Conto;
  font-size: 13px;
  font-weight: 400;
  color: #bababa;
  padding: 0 3%;
  text-align: center;
  &:first-child {
    text-align: left;
  }
  &:last-child {
    text-align: right;
  }
`;

export const TableBody = styled.tbody`
  width: 100%;
`;

export const Row = styled.tr`
  height: 35px;
`;

export const Column = styled.td`
  width: ${(props) => (props.colWidth ? props.colWidth + "%" : "auto")};
  text-transform: capitalize;
  text-align: center;
  font-family: Conto;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #fffdea;
  padding: 0 3%;

  &:first-child {
    text-align: left;
  }
  &:last-child {
    text-align: right;
  }
`;

export const StrokeLine = styled.div`
  width: 20rem;
  height: 1.5px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    #075063,
    rgb(230 198 131 / 0%)
  );
`;

export const NoDataWrapper = styled.div`
  height: 30vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
