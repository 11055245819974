import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";

export const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  min-height: ${(props) => (props.isTemplateDataAvailable ? 60 : 30)}vh;
  width: 100%;
  animation: ${(props) =>
    props.sliderDirection === "open"
      ? "themes-move-up forwards 0.25s"
      : "themes-move-down forwards 0.25s"};
  background-color: rgb(4, 54, 66);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
`;

export const Header = styled.div`
  background: rgba(4, 42, 52, 1);
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  height: 54px;
  color: white;
  font-weight: 700;
  font-size: 16px;
  border-bottom: 1px solid rgba(7, 80, 99, 1);
  position: relative;
`;

export const Icon = styled.img`
  position: absolute;
  width: ${(props) => props.width}px;
  right: ${(props) => (props.right ? props.right + "px" : "unset")};
  left: ${(props) => (props.left ? props.left + "px" : "unset")};
`;

export const Label = styled.div`
  background: linear-gradient(
    91.77deg,
    #eabf69 0.39%,
    #fffdea 50.39%,
    #eabf69 100.39%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  padding-top: 3%;
`;

export const GameTypeContainer = styled.div`
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 15px auto;
`;

export const GameTypeCards = styled.div`
  width: 40%;
  height: 145px;
  margin: 7px 10px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid
    ${(props) => (props.active ? colors.tourneySecondaryGreen : "transparent")};
`;

export const GameTypeLabel = styled.span`
  background: linear-gradient(
    91.77deg,
    #eabf69 0.39%,
    #fffdea 50.39%,
    #eabf69 100.39%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  position: absolute;
  bottom: 10px;
  left: ${(props) => (props.isTexasGame ? 15 : 30)}px;
`;

export const GameTypeImg = styled.img`
  width: 100%;
`;

export const SelectedImg = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 7% 0;
  button {
    width: 30%;
    height: 37px;
    span {
      font-size: 12px;
    }
  }
`;

// start fresh or select from template

export const StartFreshBox = styled.div`
  background: linear-gradient(270deg, #075063 0%, #336070 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
  border-radius: 5px;
  padding: 2px;
  // width: 33%;
  text-align: center;
  height: 63px;
  margin: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const PlusIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-bottom: 4px;
`;

export const SelectFromTemplateContainer = styled.div`
  margin: 15px;
  height: ${(props) => (props.isTemplateDataAvailable ? 37 : 10)}vh;
  overflow: auto;
`;

export const TemplateLabelWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 15px;
`;

export const TemplateIcon = styled.img`
  width: 15px;
  margin-right: 7px;
`;
