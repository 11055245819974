import styled from "styled-components";
import {
  Typo12WhiteContoBoldText,
  Typo12PrimaryGoldContoMediumText,
  Typo10GrayContoBoldText,
  Typo12GrayContoBoldText,
  Typo10PebbleGrayContoBoldText,
  Typo10GrayContoRegularText,
} from "../../../../../Common/styleGuide/Typos";
import colors from "../../../../../Common/styleGuide/Colors";

export const ListViewcontainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: var(--app-width);
  flex: 1;
  padding: 12px;
  overflow: scroll;
`;

export const GameTileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const GameDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const CTAButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 25%;
  justify-content: flex-end;
  align-items: center;
`;

export const GameTypeAndIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  /* background-color: green; */
  padding-bottom: 6px;
  border-bottom: 1px solid transparent;
  border-image: linear-gradient(
    #3a4c71 20%,
    #3a4c71 80%,
    #3a4c71 80%,
    #3a4c71 20%
  );

  border-image-slice: 1;
  border: none;
`;

export const SeperatorImageElement = styled.img`
  width: 100%;
`;

export const GameTypeIconWrapper = styled.img`
  height: 12px;
  width: 12px;
`;

export const GameTypeWrapper = styled.div`
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;
`;

export const GameTypeIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: flex-start;
  align-items: center;

  img:first-child {
    margin-left: 0px;
  }
  img:last-child {
    margin-right: 0px;
  }
`;

export const GameTypeDetailText = styled(Typo12WhiteContoBoldText)`
  line-height: 16px;
  text-align: center;
  margin-left: 4px;
`;

export const GameTypeIconElement = styled.img`
  height: ${(props) => (props.iconType === "delete" ? 16 : 10)}px;
  width: auto;
  margin-right: ${(props) => (props.iconType === "delete" ? 0 : 6)}px;
`;

export const TopRowIconWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  gap: 6px;
`;

export const GreenDot = styled.div`
  width: 6px;
  height: 6px;
  background: linear-gradient(
    to bottom,
    rgba(16, 201, 26, 1),
    rgba(14, 163, 12, 1)
  );
  border: 1px solid rgba(0, 255, 9, 1);
  border-radius: 50%;
`;

export const TopRowIcon = styled.img`
  flex: 1;
  max-width: 16px;
  height: 16px;
  /* aspect-ratio: 1; */
`;

export const RupeeSymbol = styled.img`
  position: absolute;
  top: 40%;
  left: 35%;
`;

export const GameDetailsAndPlayersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 3px;
  align-items: center;
  justify-content: flex-start;
`;

export const BetAmountWrapper = styled.div`
  display: flex;
  width: 42%;
  flex-direction: row;
  align-items: center;
`;

export const BetAmountText = styled(Typo12PrimaryGoldContoMediumText)`
  line-height: 16px;
`;

export const BuyInText = styled(Typo10GrayContoBoldText)`
  line-height: 14px;
  text-align: center;
  margin-left: 4px;
`;

export const MaxPlayersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 28%;
  align-items: center;
  border-right: 1px solid ${colors.tempNavy2};
  border-left: 1px solid ${colors.tempNavy2};
  justify-content: center;
  align-items: center;

  /* border: 1px solid red; */
`;

export const MaxPlayerIconElement = styled.img`
  height: 10px;
  padding-left: 18px;
  padding-right: 8px;
`;

export const MaxPlayerCountText = styled(Typo12GrayContoBoldText)`
  line-height: 16px;
  text-align: center;
  padding-right: 18px;
`;

export const LivePlayersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 30%;
  justify-content: flex-end;
  align-items: center;
`;

export const ActiveIconElement = styled.img`
  width: 6px;
  height: 6px;
`;

export const LivePlayersCountText = styled(Typo10PebbleGrayContoBoldText)`
  line-height: 14px;
  margin-left: 4px;
`;

export const ChipsStackIconWrapper = styled.img`
  margin-right: 4px;
  height: 10px;
`;

export const GameTileSectionContainer = styled.div`
  width: ${(props) => (props.minWidth ? "25%" : "100%")};
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  align-items: ${(props) =>
    props.left ? "flex-start" : props.right ? "flex-end" : "center"};
`;

export const GameTileValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* gap: 6px; */
`;

export const GameTileValue = styled.div`
  color: #fffdea;
  font-weight: bold;
  font-family: "Conto";
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const RupeePrefix = styled.span`
  font-size: 15px;
  font-weight: normal;
  /* position: relative;
  top: 2px;
  right: 1px; */
`;

export const GameTileName = styled.span`
  color: #adadad;
  font-family: "Conto";
  font-size: 11px;
  font-weight: 500;
`;

export const GameTileSectionImage = styled.img`
  height: 12px;
  width: auto;
  margin-right: 6px;
`;

export const GameTileSectionDividerContainer = styled.div`
  position: relative;
  height: 60%;
  width: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const GameTileSectionDivider = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${(props) => (props.color ? props.color : "#15373f")};
`;

export const GreenCircle = styled.div`
  width: 6px;
  height: 6px;
  background: ${colors.leafyGreen};
  border-radius: 50%;
  box-shadow: 0px 0px 2px ${colors.leafyGreen};
  margin-right: 4px;
`;

export const GameTileTopRow = styled.div`
  padding: 0px 0px;
  /* width: calc(100% - 32px); */
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: red; */
  /* align-items: center; */
  height: 60px;
`;

export const GameTileTopRowContent = styled.div`
  width: 90%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const GameTileBottomRowContent = styled.div`
  width: 90%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const GameTileBottomRow = styled.div`
  border-top: 0.5px solid #2c515e;
  background-color: #032f3a;
  border-radius: 0px 0px 5px 5px;
  height: 30px;
`;

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 6px; */
  height: 14px;
  font-size: 10px;
  color: white;
  font-weight: 500;
  /* min-width: 40px; */
`;

export const GameType = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  font-size: 10px;
  color: white;
  font-weight: bold;
  /* min-width: 40px; */
  padding: 0px 8px;
  border-radius: 12px;
  background-color: ${(props) =>
    props.gameType === "OMAHA"
      ? "#5A571E"
      : props.gameType === "OMAHA5"
      ? "#67401A"
      : "#631414"};
`;

export const GameIconsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  justify-content: flex-end;
`;

export const GameTileBottomLeftSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const GameTileBottomRightSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  justify-content: flex-end;
  align-items: center;
`;

export const GameTileBottomContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.endTimeLabel ? "end" : "space-between")};
  width: ${(props) => (props.endTimeLabel ? "60%" : props.tableName ? "70%" : "auto")};
  height: 100%;
  align-items: center;
  margin-right: ${(props) => (props.endTimeLabel ? 10 : 0)}px;
`;

export const GameTileBottomLeftText = styled.span`
  font-size: 11px;
  font-weight: 500;
  font-family: "Conto";
  color: ${(props) => (props.endTimeLabel ? colors.secondaryRed : "#bebab9")};
`;

export const RegPlayerCountText = styled(Typo10GrayContoRegularText)`
  margin-left: 4px;
  margin-right: 4px;
`;
export const BonusBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 60px;
  height: 6px;
  border-radius: 6px;
  /* background: ${colors.tourneyBonusBarPrimary}; */
  background: #101e23;
  margin-left: 4px;
  align-items: center;
`;

export const BonusBarFilled = styled.div`
  display: flex;
  flex-direction: row;
  width: ${(props) =>
    props.fillPercentage ? `${props.fillPercentage}%` : "0%"};
  height: 4px;
  border-radius: 6px;
  background: #26923b;
  margin: 1px;
`;

export const RegCountAndMaxPlayerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 40%;
  justify-content: flex-start;
  align-items: center;
  margin-right: 12px;
  box-sizing: border-box;
`;

export const TableOrTemplateName = styled.span`
  color: ${props=> props.isTemplateOrActiveGameDef ? colors.lightYellow: colors.pebbleGray};
  font-weight: 500;
  font-family: "Conto";
  font-size: 11px;
`;
