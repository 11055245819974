import { AnimatePresence, motion } from "framer-motion";
import React, { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { cardThemes } from "../../../assets/cards";
import { GAME_STAGE_SHOWDOWN } from "../../data/Constants";
import colors from "../../../Common/styleGuide/Colors";
import "./flipCardStyles.css";
import FlipCard from "./NewFlipCard";

const RITCommunityCards = (props) => {
  const { currentGameId, tempTableId } = props;
  const communityCards = useSelector(
    (state) => state.gameTable.games[tempTableId]?.RITCommunityCards
  );
  const gameTableDimensions = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameTableDimensions
  );
  const showCommunityCardsImmediately = useSelector(
    (state) => state.gameTable.games[tempTableId].showCommunityCardsImmediately
  );

  const cardDeckTheme = useSelector(
    (state) => state.gameTable.themeData.cardDeck
  );
  const [winningHand, setWinningHand] = useState([]);
  const playerDetails = useSelector((state) =>
    state.gameTable.games[tempTableId]?.playerData.find(
      (player) =>
        player.position === state.gameTable.games[tempTableId]?.playerSeat
    )
  );
  const doCommunityCardsExist = useSelector(
    (state) => state.gameTable.games[tempTableId]?.doCommunityCardsExist
  );
  const gameStage = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameStage
  );
  const playerData = useSelector(
    (state) => state.gameTable.games[tempTableId]?.playerData
  );
  const { showHandStrength } = useSelector(
    (state) => state.gameTable.tableSettings
  );
  const isWinnerDeclared = useSelector(
    (state) => state.gameTable.games[tempTableId].isWinnerDeclared
  );
  const [communityCardsBackup, setCommunityCardsBackup] = useState([]);
  const [cardWidth, setCardWidth] = useState(null);
  const [isRabbitCardFlipped, setIsRabbitCardFlipped] = useState(false);
  const winningPotData = useSelector(
    (state) => state.gameTable.games[tempTableId]?.winningPotDisplay
  );
  const intervalRef = useRef(null);

  useEffect(() => {
    setIsRabbitCardFlipped(false);
  }, [currentGameId]);

  useEffect(() => {
    if (gameTableDimensions?.width) {
      setCardWidth((gameTableDimensions?.width * 0.6) / 5);
    }
    return () => clearTimeout(intervalRef?.current);
  }, [gameTableDimensions?.width]);

  useEffect(() => {
    if (isWinnerDeclared && winningPotData) {
      getWinningHandCards();
    } else {
      setWinningHand([]);
      clearTimeout(intervalRef?.current);
    }

    return () => {
      clearTimeout(intervalRef?.current);
    };
  }, [isWinnerDeclared, winningPotData, playerData]);

  const getWinningHandCards = () => {
    if (winningPotData) {
      let winningPlayer = playerData.find(
        (player) => player.userName === winningPotData?.winnerNames[0]
      );
      if (
        winningPotData?.winnerNames.includes(winningPlayer?.userName) &&
        winningPlayer?.winningHand
      ) {
        setWinningHand(winningPlayer?.winningHand);
      } else {
        setWinningHand([]);
      }
    }
  };

  const returnHorizontalExitAmountForCard = (index) => {
    switch (index) {
      case 0:
        return cardWidth * 2;
      case 1:
        return cardWidth;
      case 2:
        return 0;
      case 3:
        return -cardWidth;
      case 4:
        return -cardWidth * 2;
    }
  };

  useEffect(() => {
    if (communityCards.length > 0) {
      setCommunityCardsBackup(communityCards);
    }
  }, [communityCards]);

  const handleCardFlip = () => {
    setIsRabbitCardFlipped(true);
  };

  const returnCommunityCardDelay = (index) => {
    if (showCommunityCardsImmediately) {
      return 0;
    }
    // Count how many real cards exist before this index
    const realCardsBefore = communityCards
      .slice(0, index)
      .filter((card) => cardThemes[cardDeckTheme][card]).length;

    // If we have all 5 real cards, use original logic
    if (
      communityCards.filter((card) => cardThemes[cardDeckTheme][card])
        .length === 5
    ) {
      return index < 3 ? 0 : index * 0.55;
    }

    // For partial real cards, delay based on position among real cards
    return realCardsBefore * 0.25;
  };

  const renderCards = (array) => {
    return array.map((card, index) => {
      if (card === "cardBack")
        return <motion.div style={{ width: cardWidth }}></motion.div>;
      if (!cardThemes[cardDeckTheme][card]) return null;
      return (
        <AnimatePresence
          key={"AnimatePresence-CommunityCard-" + card + "-" + index}
        >
          {doCommunityCardsExist && (
            <motion.div
              key={"new-community-card-" + card + "-" + index}
              initial={{ opacity: 0, scale: 0 }}
              exit={{
                y: -50,
                x: returnHorizontalExitAmountForCard(index),
                scale: 0.25,
                opacity: 0,
                transition: {
                  delay: 0,
                },
              }}
              animate={{
                opacity: 1,
                scale: 1,
                y: winningHand.includes(card) ? -10 : null,
                filter:
                  !winningHand.includes(card) && isWinnerDeclared
                    ? "brightness(0.6)"
                    : null,
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              transition={{
                opacity: {
                  delay: returnCommunityCardDelay(index),
                },
                scale: {
                  delay: returnCommunityCardDelay(index),
                },
              }}
            >
              <img
                style={{
                  width: cardWidth,
                  height: "auto",
                }}
                src={cardThemes[cardDeckTheme][card]}
                alt={`Card ${card}`}
              />
              {playerDetails?.possibleWinningHandCards?.includes(card) &&
              !playerDetails?.isFolded &&
              showHandStrength &&
              !isWinnerDeclared &&
              gameStage !== GAME_STAGE_SHOWDOWN ? (
                <div
                  style={{
                    height: "4px",
                    background: colors.winnerCardHighLight,
                    width: "99%",
                    border: "1px solid green",
                    borderRadius: "0px 0px 100px 100px",
                  }}
                ></div>
              ) : null}
            </motion.div>
          )}
        </AnimatePresence>
      );
    });
  };

  return (
    communityCards.length > 0 && (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          gap: "5px",
          position: "absolute",
          top: 0,
          transform: "translateY(75%)",
          // minHeight: "55px",
          // transform: 'translateY(-50%)',
          width: gameTableDimensions?.width * 0.65,
        }}
      >
        {cardWidth && communityCards.length > 0
          ? renderCards(communityCards)
          : null}
      </div>
    )
  );
};

export default RITCommunityCards;
