import { createSlice, current } from "@reduxjs/toolkit";
import { CREATE_PRIVATE_TABLE_SLIDER, PRIVATE_TABLE_ENTER_CODE_SLIDER, PRIVATE_TABLE_GAME_DEF_INFO_SLIDER, PRIVATE_TABLE_HOSTING_DETAILS_SLIDER } from "../../data/Constants";
// import { cloneDeep } from "lodash";

const sliderSlice = createSlice({
  name: "slider",
  initialState: {
    visible: false,
    type: null,
    config: null,
    animating: false,
  },
  reducers: {
    openSlider: (state, action) => {
      // let newState = cloneDeep(current(state));
      state.visible = true;
      let data = action.payload;
      state.type = data.type;
      if (data.config) {
        state.config = data.config;
      } else {
        state.config = undefined;
      }
      state.animating = true;
      // return newState;
    },
    closeSlider: (state, action) => {
      // to prevent below lobby slider type closing on turn change or any action made on game table
      // created a new method to close slider of below types
      if (
        state.type !== PRIVATE_TABLE_GAME_DEF_INFO_SLIDER &&
        state.type !== PRIVATE_TABLE_HOSTING_DETAILS_SLIDER &&
        state.type !== PRIVATE_TABLE_ENTER_CODE_SLIDER &&
        state.type !== CREATE_PRIVATE_TABLE_SLIDER
      ) {
        state.visible = false;
        state.type = null;
        state.config = null;
        state.animating = false;
      }
      // return newState;
    },
    updateSliderAnimatingFlag: (state, action) => {
      let { value } = action.payload;
      // let newState = cloneDeep(current(state));
      state.animating = value;
      // return newState;
    },

    closePrivateTableSliders: (state, action) => {
      state.visible = false;
      state.type = null;
      state.config = null;
      state.animating = false;
    },
  },
});

export const { openSlider, closeSlider, updateSliderAnimatingFlag,closePrivateTableSliders } = sliderSlice.actions;
export default sliderSlice.reducer;
