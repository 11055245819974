import React from "react";
import { TextInputContainer } from "./inputFieldStyle";

export const InputField = (props) => {
  const {
    type,
    location,
    onChangeHandler,
    onBlurHandler,
    placeholder,
    maxLength,
    onFocusHandler,
    value
  } = props;
  return (
    <TextInputContainer
      type={"text"}
      location={location}
      onChange={(e) => onChangeHandler(e)}
      onBlur={(e) => onBlurHandler(e)}
      placeholder={placeholder}
      maxLength={maxLength}
      onFocus={onFocusHandler}
      value={value}
    ></TextInputContainer>
  );
};
