import React from "react";

import EmptyFolder from "../../../assets/lobby/EmptyFolder.png";

import {
  Container,
  EmptyFolderIconWrapper,
  MessageText,
} from "./tourneyFlightsPlaceholderStyles";

function TourneyFlightsPlaceholder(props) {
  return (
    <Container>
      <EmptyFolderIconWrapper src={EmptyFolder} />
      <MessageText>{props.message ? props.message : ""}</MessageText>
    </Container>
  );
}

export default TourneyFlightsPlaceholder;
