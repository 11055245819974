import { useDispatch } from "react-redux";
import { addClientLog } from "../../Poker/redux/slices/clientLogsSlice";
import {
    getClientLogInfoPayload,
    getClientLogErrorPayload,
    getClientLogPayload
} from "../../Poker/utils/ClientLogUtils";
import { CLIENT_ERROR_EVENT, CLIENT_INFO_EVENT, CLIENT_LOG_EVENT } from "../../Poker/data/Constants";

const logPayloadBuilders = {
    [CLIENT_LOG_EVENT]: getClientLogPayload,
    [CLIENT_ERROR_EVENT]: getClientLogErrorPayload,
    [CLIENT_INFO_EVENT]: getClientLogInfoPayload,
};

const useClientLogger = () => {
    const dispatch = useDispatch();

    const sendLog = ({ type = CLIENT_INFO_EVENT, payload }) => {
        if (!payload) {
            console.log('No payload provided. Skipping log.');
            return;
        }

        const caller = getCaller();
        console.log(`Log called from: ${caller}`);

        const logPayload = logPayloadBuilders?.[type]?.(payload);
        dispatch(addClientLog(logPayload));
    };

    return { sendLog };
};

const getCaller = () => {
    const error = new Error();
    const stack = error.stack.split('\n');
    return stack[3].trim();
};

export default useClientLogger;