export const MP_WALLET_BUTTON_CLICK = "WalletButtonClicked";
export const MP_NOTIFICATION_BUTTON_CLICKED = "NotificationButtonClicked";
export const MP_POKER_HOME_MAIN_TILE="PokerHomeMainTile";
export const MP_POKER_HOME_RECOMMEND_TILE="PokerHomeRecommendTile";
export const MP_POKER_RAKEBACK_TILE_CLICKED="PokerRakebackTileClicked";
export const MP_POKER_MISSIONS_TILE_CLICKED="PokerHomeMissionTileClicked";
export const MP_POKER_LEADERBOARD_TILE_CLICKED="PokerLeaderboardTileClicked";
export const MP_POKER_HOME_PROMOTION_BANNER_CLICKED="PokerHomePromotionBannerClicked";
export const MP_POKER_HOME_REFER_N_EARN="PokerHomeReferEarnClicked";    
export const MP_POKER_GAMES_LOBBY_ICON_CLICKED="PokerGamesLobbyIconClicked";
export const MP_POKER_PROFILE_ICON_CLICKED="PokerProfileIconClicked";
export const MP_POKER_TOURNEY_LOBBY_ICON_CLICKED="PokerTourneyLobbyIconClicked";
export const MP_POKER_PROMOTION_CARD_CLICKED="PokerPromotionCardClicked";
export const MP_POKER_HOME_POKER_BAWSE_CLICKED="PokerHomePokerBawseClicked";
export const MP_POKER_CASH_PRACTICE_TOGGLE_CLICKED="PokerCashPracticeToggleClicked";
export const MP_POKER_GAME_TILE_CLICKED="PokerGameTileClicked";
export const MP_POKER_TOURNEY_TILE_CLICKED="PokerTourneyTileClicked";
export const MP_POKER_TOURNEY_MY_TICKETS_ICON_CLICKED="PokerTourneyMyTicketsIconClicked";
export const MP_POKER_TOURNEY_REMIND_ME_CLICKED="PokerTourneyRemindMeClicked";
export const MP_POKER_GAMETABLE_SIT_HERE_CLICKED="PokerGametableSitHereClicked";
export const MP_POKER_GAMETABLE_BUYIN_CLICKED="PokerGametableBuyinClicked";
export const MP_POKER_GAMETABLE_ADD_TABLE_CLICKED="PokerGametableAddtableClicked";
export const MP_POKER_GAMETABLE_AUTO_REBUY_TOGGLE="PokerGameTableAutoRebuyToggle";
export const MP_POKER_GAMETABLE_AUTO_TOPUP_TOGGLE="PokerGameTableAutoTopupToggle"
export const MP_POKER_GAMETABLE_LEAVE_SEAT_CLICKED="PokerGametableLeaveSeatClicked";
export const MP_POKER_GAMETABLE_EXIT_TABLE_CLICKED="PokerGametableExitTableClicked";
export const MP_POKER_GAMETABLE_HAND_HISTORY_CLICKED="PokerGametableHandHistoryClicked";
export const MP_POKER_GAMETABLE_TOPUP_CLICKED="PokerGametableTopupClicked";
export const MP_POKER_GAMETABLE_TOPUP_BUY_CLICKED="PokerGametableTopupBuyClicked";
export const MP_POKER_GAMETABLE_STATISTICS_CLICKED="PokerGametableStatisticsClicked";
export const MP_POKER_GAMETABLE_SITOUT_TOGGLE_CLICKED="PokerGametableSitoutToggleClicked";
export const MP_POKER_GAMETABLE_CHIPS_IN_BB_TOGGLE_CLICKED="PokerGametableChipsinBBToggleClicked";
export const MP_POKER_GAMETABLE_AUTO_SWITCHING_CLICKED="PokerGametableAutoSwitchingClicked";
export const MP_POKER_GAMETABLE_SETTINGS="PokerGametableSettings";
export const MP_POKER_GAMETABLE_PLAY_NEXT_CLICKED="PokerGametablePlaynextClicked";
export const MP_POKER_GAMETABLE_LOADED="PokerGameTableLoaded";
export const MP_POKER_GAMETABLE_BUYIN_POPUP_LOADED="PokerGameTableBuyinPopupLoaded";
export const MP_POKER_GAMETABLE_SEAT_TAKEN_LOADED="PokerGameTableSeatTakenLoaded";
export const MP_POKER_TOURNEY_REGISTER_CLICKED="PokerTourneyRegisterClicked";
export const MP_POKER_TOURNEY_INFO_TABLES_CLICKED="PokerTourneyInfoTablesClicked";
export const MP_POKER_GAME_LOBBY_TOURNEY_REG_CONFIRMATION_LOADED="PokerGameLobbyTourneyRegConfirmationLoaded";
export const MP_POKER_TOURNEY_REENTRY_CLICKED="PokerTourneyReentryClicked";
export const MP_POKER_GAMETABLE_GESTURES_TOGGLE_CLICKED="PokerGametableGesturesToggleClicked";
