import React, { useEffect, useRef } from "react";

import MalachiteInfo from "../../../assets/lobby/MalachiteInfo.png";
import tourneyHorizontalSeperator from "../../../assets/lobby/TourneyHorizontalSeperator.svg";
import tourneyVerticalSeperator from "../../../assets/lobby/TourneyVerticalSeperator.svg";

import {
  Container,
  TourneyStackDetailsWrapper,
  TourneyStackItemWrapper,
  StackDetailsText,
  StackDetailsValueText,
  TourneyTimeWrapper,
  TourneyStartAndEndTimeWrapper,
  TourneySkeletonWrapper,
  TourneyQualifiedPlayersText,
  InfoIcon,
  HorizontalSeperator,
  QualifiedTextContainer,
  VerticalSeperator,
} from "./flightTourneyCompletedInfoCardStyles";
import Skeleton from "react-loading-skeleton";
import colors from "../../../Common/styleGuide/Colors";
import { TICKET_BASED, TICKETS } from "../../data/Constants";
import { useDispatch, useSelector } from "react-redux";
import { requestFlightTourneyStackDetails } from "../../redux/slices/lobbySlice";
import moment from "moment";

function FlightTourneyCompletedInfoCard(props) {
  const dispatch = useDispatch();
  const elementRef = useRef(null);
  let formatString = "DD MMM, hh:mmA";
  const { tournamentType, buyIn, tourneyRake, tourneyId } =
    props.tourneyDetails;
  const stackDetails = props.tourneyDetails?.stackDetails
    ? props.tourneyDetails?.stackDetails
    : null;

  useEffect(() => {
    if (!stackDetails) {
      dispatch(requestFlightTourneyStackDetails({ tourneyId: tourneyId }));
    }
    if (elementRef.current) {
      elementRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);

  const formatTourneyDate = (timeStamp, formatString) => {
    try {
      return moment.unix(timeStamp).format(formatString);
    } catch (error) {
      console.warn("ERROR IN FORMATTING DATE IN TOURNEY GAME TILE:", error);
    }
  };

  return !stackDetails ? (
    <TourneySkeletonWrapper>
      <Skeleton
        baseColor={colors.primaryBlue}
        highlightColor={colors.tempNavy2}
        count={1}
        height={60}
        borderRadius={8}
      />
      {!props.isSatelliteTourney && (
        <Skeleton
          baseColor={colors.primaryBlue}
          highlightColor={colors.tempNavy2}
          count={1}
          height={26}
          borderRadius={8}
        />
      )}
    </TourneySkeletonWrapper>
  ) : (
    <Container ref={elementRef}>
      <TourneyStackDetailsWrapper>
        <TourneyStackItemWrapper>
          <StackDetailsText>Buy-In</StackDetailsText>
          <StackDetailsValueText>{stackDetails?.buyIn}</StackDetailsValueText>
        </TourneyStackItemWrapper>
        <VerticalSeperator src={tourneyVerticalSeperator} />
        <TourneyStackItemWrapper>
          <StackDetailsText>Total Entries</StackDetailsText>
          <StackDetailsValueText>
            {stackDetails?.numberOfEntries}
          </StackDetailsValueText>
        </TourneyStackItemWrapper>
        <VerticalSeperator src={tourneyVerticalSeperator} />
        {!props.isSatelliteTourney ? (
          <TourneyStackItemWrapper>
            <StackDetailsText>Qualified Players</StackDetailsText>
            <TourneyQualifiedPlayersText
              onClick={() => {
                props.handleQualifiedPlayers(tourneyId);
              }}
            >
              {stackDetails?.numberOfQualifiedPlayers}
              <InfoIcon src={MalachiteInfo} />
            </TourneyQualifiedPlayersText>
          </TourneyStackItemWrapper>
        ) : (
          <TourneyStackItemWrapper>
            <StackDetailsText>Tickets Awarded</StackDetailsText>
            <StackDetailsValueText>
              {stackDetails?.numberOfTicketsAssigned}
            </StackDetailsValueText>
          </TourneyStackItemWrapper>
        )}
      </TourneyStackDetailsWrapper>
      {!props.isSatelliteTourney && (
        <>
          <HorizontalSeperator src={tourneyHorizontalSeperator} />
          <TourneyStackDetailsWrapper>
            <TourneyStackItemWrapper>
              <StackDetailsText>Smallest Stack</StackDetailsText>
              <StackDetailsValueText>
                {stackDetails?.minStack}
              </StackDetailsValueText>
            </TourneyStackItemWrapper>
            <VerticalSeperator src={tourneyVerticalSeperator} />
            <TourneyStackItemWrapper>
              <StackDetailsText>Average Stack</StackDetailsText>
              <StackDetailsValueText>
                {stackDetails?.averageStack}
              </StackDetailsValueText>
            </TourneyStackItemWrapper>
            <VerticalSeperator src={tourneyVerticalSeperator} />
            <TourneyStackItemWrapper>
              <StackDetailsText>Largest Stack</StackDetailsText>
              <StackDetailsValueText>
                {stackDetails?.maxStack}
              </StackDetailsValueText>
            </TourneyStackItemWrapper>
          </TourneyStackDetailsWrapper>
        </>
      )}
      {!props.isSatelliteTourney && stackDetails?.qualified && (
        <>
          <HorizontalSeperator src={tourneyHorizontalSeperator} />
          <QualifiedTextContainer>
            <StackDetailsValueText>{`You have qualified with ${stackDetails?.qualifiedStack} Chips`}</StackDetailsValueText>
          </QualifiedTextContainer>
        </>
      )}
      <TourneyStartAndEndTimeWrapper>
        <TourneyTimeWrapper>
          <StackDetailsText>Started :</StackDetailsText>
          <StackDetailsValueText>{` ${formatTourneyDate(
            stackDetails?.startedDate,
            formatString
          )}`}</StackDetailsValueText>
        </TourneyTimeWrapper>
        <TourneyTimeWrapper>
          <StackDetailsText>Ended :</StackDetailsText>
          <StackDetailsValueText>{` ${formatTourneyDate(
            stackDetails?.endedDate,
            formatString
          )}`}</StackDetailsValueText>
        </TourneyTimeWrapper>
      </TourneyStartAndEndTimeWrapper>
    </Container>
  );
}

export default FlightTourneyCompletedInfoCard;
