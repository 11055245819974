import { getISTDateTimeFormat } from "../../Common/utils/GameUtils";
import { LB_PLAY, LB_REAL } from "../data/Constants";
import { MP_POKER_CASH_PRACTICE_TOGGLE_CLICKED, MP_WALLET_BUTTON_CLICK, MP_POKER_GAME_TILE_CLICKED, MP_POKER_TOURNEY_TILE_CLICKED, MP_POKER_TOURNEY_MY_TICKETS_ICON_CLICKED, MP_POKER_TOURNEY_REMIND_ME_CLICKED, MP_POKER_GAMETABLE_SIT_HERE_CLICKED, MP_POKER_GAMETABLE_BUYIN_CLICKED, MP_POKER_GAMETABLE_ADD_TABLE_CLICKED, MP_POKER_GAMETABLE_AUTO_REBUY_TOGGLE, MP_POKER_GAMETABLE_AUTO_TOPUP_TOGGLE, MP_POKER_GAMETABLE_LEAVE_SEAT_CLICKED, MP_POKER_GAMETABLE_EXIT_TABLE_CLICKED, MP_POKER_GAMETABLE_HAND_HISTORY_CLICKED, MP_POKER_GAMETABLE_TOPUP_CLICKED, MP_POKER_GAMETABLE_TOPUP_BUY_CLICKED, MP_POKER_GAMETABLE_STATISTICS_CLICKED, MP_POKER_GAMETABLE_SITOUT_TOGGLE_CLICKED, MP_POKER_GAMETABLE_SETTINGS, MP_POKER_GAMETABLE_PLAY_NEXT_CLICKED, MP_POKER_GAMETABLE_LOADED, MP_POKER_TOURNEY_REGISTER_CLICKED, MP_POKER_TOURNEY_INFO_TABLES_CLICKED, MP_POKER_PROMOTION_CARD_CLICKED } from "../data/MixPanelConstant";
import { processDate } from "../routes/TourneyInfo/TourneyInfo";
const eventParamMapper = {
    getCommonParams(playerData, userBalance, channel, gameDefinition) {
        return {
            Channel: channel,
            Timestamp: getISTDateTimeFormat(),
            Username: playerData?.userName,
            Acelevel: playerData?.level,
        };
    },
    WalletButtonClicked(playerData, userBalance, channel, gameDefinition) {
        return {
            'Wallet Balance': userBalance?.totalBalance,
        }
    },
    PokerGameTileClicked(playerData, userBalance, channel, gameDefinition) {
        return {
            Gametype: gameDefinition?.flavourType ? gameDefinition?.flavourType === LB_REAL ? "Cash" : LB_PLAY : null,
            Variant: gameDefinition?.gameType,
            Stakes: gameDefinition?.smallBlind + '/' + gameDefinition?.bigBlind,
            'Players per Table': gameDefinition?.maxPlayers,


        }
    },
    PokerTourneyTileClicked(playerData, userBalance, channel, gameDefinition) {
        return {
            Status: gameDefinition?.tourneyActiveStatus,
            Type: gameDefinition?.tournamentType,
            TourneyStarttime: processDate(gameDefinition?.tourneyStartTime),
            RegistrationStarttime: processDate(gameDefinition?.registrationStartTime),
            BuyinAmount: gameDefinition?.entryFee,
            TourneyID: gameDefinition?.tourneyId,
            PrizePool: gameDefinition?.prizePool,
            // 'Pin on Top':gameDefinition?.,
            'Tournament Name': gameDefinition?.tourneyName,
            'Entry Type': gameDefinition?.reEntryType,
            'Players per Table': gameDefinition?.playersPerTable,
            'Min. Players Required': gameDefinition?.minPlayers,
            'Pin on Top': gameDefinition?.isFeatured ? true : false,
        }
    },
    PokerGametableSitHereClicked(playerData, userBalance, channel, gameDefinition) {
        return {
            TableName: gameDefinition?.tableName,
            bigBlind: gameDefinition?.bigBlind,
            Gametype: gameDefinition?.gameType,
            "No. of Players": gameDefinition?.tourneyId ? gameDefinition?.playersPerTable : gameDefinition?.maxPlayers,
            "Game Variant": gameDefinition?.GameVariant,
            isTourney: gameDefinition?.tourneyId ? true : false,
        }
    },
    PokerGameTableAutoRebuyToggle(playerData, userBalance, channel, gameDefinition) {
        return {
            status: gameDefinition?.autoRebuy ? "On" : gameDefinition?.autoToggle ? "On" : "Off",
            'Falls Below Value': gameDefinition?.fallsBelow,
            'Topup to Value': gameDefinition?.topUpAmount,
            'Click Location': 'Table Settings',
        }
    },
    PokerGametableSettings(playerData, userBalance, channel, gameDefinition) {
        return {
            Status: gameDefinition?.status,
            Gametype: gameDefinition?.gameType,
            GameFormat: gameDefinition?.flavourType ? gameDefinition?.flavourType === LB_REAL ? "Cash" : LB_PLAY : null,
            isTourney: gameDefinition?.tourneyId ? true : false,
        }
    },
    PokerTourneyRegisterClicked(playerData, userBalance, channel, gameDefinition) {
        return {
            'Register Mode': gameDefinition?.entryType,
        }
    },
    PokerHomeLobbyRedirectionData(playerData, userBalance, channel, gameDefinition) {
        return {
            'Redirection Name': gameDefinition?.Location ? gameDefinition?.Location : gameDefinition?.redirectionData?.Location ? gameDefinition?.redirectionData?.Location : "NA",
            'Click Location': "Home Lobby",
            'Slot': gameDefinition?.index,

        }
    }

};
export function getEventParams(eventName, playerData, userBalance, channel, gameDefinition) {
    const eventHandler = eventParamMapper[eventName] || null;
    let eventParams;
    if (eventHandler !== null) {
        eventParams = eventHandler(playerData, userBalance, channel, gameDefinition);
    }
    const commonParams = eventParamMapper.getCommonParams(playerData, userBalance, channel, gameDefinition);
    const walletBalance = eventParamMapper.WalletButtonClicked(playerData, userBalance, channel, gameDefinition);
    const tourneyInfo = eventParamMapper.PokerTourneyTileClicked(playerData, userBalance, channel, gameDefinition);
    const gameTableParams = eventParamMapper.PokerGametableSitHereClicked(playerData, userBalance, channel, gameDefinition);
    const gameTableSettingParams = eventParamMapper.PokerGametableSettings(playerData, userBalance, channel, gameDefinition);
    const redirectionParams = eventParamMapper.PokerHomeLobbyRedirectionData(playerData, userBalance, channel, gameDefinition);

    if (eventName === MP_WALLET_BUTTON_CLICK) {
        return {
            ...commonParams,
            ...eventParams,
        }
    }
    if (eventName === MP_POKER_CASH_PRACTICE_TOGGLE_CLICKED ) {
        delete commonParams.Acelevel;
        return {
            ...commonParams,
            ...eventParams,
        }
    }
    if (eventName === MP_POKER_GAMETABLE_SETTINGS) {
        delete commonParams.Acelevel;
        return {
            ...commonParams,
            ...eventParams,
        }
    }
    if (eventName === MP_POKER_GAME_TILE_CLICKED || eventName === MP_POKER_TOURNEY_TILE_CLICKED || eventName === MP_POKER_TOURNEY_MY_TICKETS_ICON_CLICKED) {
        return {
            ...commonParams,
            ...eventParams,
            ...walletBalance,
        }
    }
    if (eventName === MP_POKER_TOURNEY_REMIND_ME_CLICKED) {
        const { TourneyID, TourneyStarttime, ...rest } = tourneyInfo;
        return {
            TourneyID,
            TourneyStarttime,
            ...walletBalance,
            ...commonParams,

        }
    }
    if (eventName === MP_POKER_GAMETABLE_BUYIN_CLICKED || eventName === MP_POKER_GAMETABLE_AUTO_REBUY_TOGGLE || eventName === MP_POKER_GAMETABLE_AUTO_TOPUP_TOGGLE || eventName === MP_POKER_GAMETABLE_EXIT_TABLE_CLICKED || eventName === MP_POKER_GAMETABLE_HAND_HISTORY_CLICKED || eventName === MP_POKER_GAMETABLE_TOPUP_CLICKED || eventName === MP_POKER_GAMETABLE_TOPUP_BUY_CLICKED || eventName === MP_POKER_GAMETABLE_PLAY_NEXT_CLICKED) {
        delete commonParams.Acelevel;
        return {
            ...commonParams,
            ...walletBalance,
            ...gameTableParams,
            ...eventParams,
        }
    }
    if (eventName === MP_POKER_GAMETABLE_LOADED) {
        delete commonParams.Acelevel;
        delete gameTableSettingParams.Status;
        delete gameTableSettingParams.isTourney;
        return {
            ...commonParams,
            ...walletBalance,
            ...gameTableParams,
            ...gameTableSettingParams,
            ...eventParams,
        }
    }
    if (eventName === MP_POKER_GAMETABLE_SIT_HERE_CLICKED || eventName === MP_POKER_GAMETABLE_ADD_TABLE_CLICKED || eventName === MP_POKER_GAMETABLE_LEAVE_SEAT_CLICKED) {
        delete commonParams.Acelevel;
        return {
            ...commonParams,
            ...walletBalance,
            ...gameTableParams,
            ...eventParams,
        }
    }
    if (eventName === MP_POKER_GAMETABLE_STATISTICS_CLICKED || eventName === MP_POKER_GAMETABLE_SITOUT_TOGGLE_CLICKED) {
        delete commonParams.Acelevel;
        return {
            ...commonParams,
            ...gameTableParams,
            ...eventParams,
        }
    }
    if (eventName === MP_POKER_TOURNEY_REGISTER_CLICKED) {
        const { TourneyID, BuyinAmount } = tourneyInfo;
        return {
            ...commonParams,
            ...eventParams,
            TourneyID,
            BuyinAmount,
        }
    }
    if (eventName === MP_POKER_TOURNEY_INFO_TABLES_CLICKED) {
        const { TourneyID } = tourneyInfo;
        delete commonParams.Acelevel;
        return {
            ...commonParams,
            TourneyID
        }

    }
    if (eventName === MP_POKER_PROMOTION_CARD_CLICKED) {
        delete commonParams.Acelevel;
        return {
            ...commonParams,
            ...redirectionParams,
            ...walletBalance,

        }
    }
    const finalParams = {
        ...commonParams,
        ...eventParams
    };
    return finalParams;
}