import React, { useEffect, useRef, useState } from "react";
import Lottie from "lottie-react";
import lottieFile from './RIT.json';
import { useSelector } from "react-redux";

export const RITLottie = ({ size, margin, loop, tempTableId }) => {
    const lottieAnimationRef = useRef(null);
    const lottieTimeout = useRef(null);
    const [hideRIT, setHideRIT] = useState(true);
    const isRITEnabled = useSelector((state) => state.gameTable.games[tempTableId].isRITEnabled);

    useEffect(() => {
        if (isRITEnabled) {
            setHideRIT(false);
            lottieTimeout.current = setTimeout(() => {
                // lottieAnimationRef?.current?.setSpeed(2);
                lottieAnimationRef?.current?.play();
            }, 100);
        } else {
            setHideRIT(true);
        }

        return () => clearTimeout(lottieTimeout.current);
    }, [isRITEnabled]);

    return (
        !hideRIT ? (
            <Lottie
                style={{
                    width: size ? size + 'px' : "100%",
                    marginBottom: '16px',
                    // height: size ? size + 'px' : "100%",
                    // position: 'absolute',
                    // top: '15%',
                    // left: '50%',
                    // transform: 'translateY(-50%)',
                    // zIndex: 1000,
                }}
                onAnimationEnd={() => hideRIT && setHideRIT(true)}
                animationData={lottieFile}
                ref={lottieAnimationRef.current}
                autoPlay={true}
                loop={loop ? loop : false}
            />
        ) : null
    );
};
