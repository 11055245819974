import styled, { keyframes } from "styled-components";
import {
  Typo12LeafyGreenContoMediumText,
  Typo12PrimaryGoldContoMediumText,
  Typo12WhiteContoMediumText,
} from "../../../Common/styleGuide/Typos";
import colors from "../../../Common/styleGuide/Colors";

export const FlightTourneySimilarFlightsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 58px;
  padding: 12px 16px 12px 12px;
  box-sizing: border-box;
  background: transparent;
  border: 1px solid rgba(7, 80, 99, 1);
  border-radius: 8px;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${(props) =>
    props.highlightTile ? " 0px 0px 6px 0px rgba(234, 191, 105, 1)" : ""};
`;

export const FlightTourneyParentTourneyContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 58px;
  padding: 12px 16px 12px 12px;
  box-sizing: border-box;
  background: linear-gradient(
    91.67deg,
    rgba(51, 96, 112, 0.65) 0%,
    rgba(7, 80, 99, 0.65) 100%
  );
  border-radius: 8px;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;
`;

export const IconAndTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const IconWrapper = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const RotatingIcon = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 36px;
  width: 36px;

  animation: ${rotate} 8s linear infinite;
`;

export const ParentTourneyText = styled(Typo12PrimaryGoldContoMediumText)`
  line-height: 16.26px;
  margin-left: 4px;
`;

export const SimilarFlightsText = styled(Typo12WhiteContoMediumText)`
  line-height: 16.26px;
  margin-left: 4px;
`;

export const CenterIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 24px;
  width: 24px;
  transform: translate(-50%, -50%);
`;

export const BlindsRightArrow = styled.img`
  height: 8px;
  width: 5px;
  margin-left: 4px;
`;

export const PrizeBreakupText = styled(Typo12LeafyGreenContoMediumText)`
  line-height: 17px;
  border-bottom: 1.2px solid ${colors.leafyGreen};
`;
