import Handler from "../Handler";

class PrivateTablesLobbyDataHandler extends Handler {
  execute(data) {
    let parsedData  = JSON.parse(data);
    this.service.processPrivateTablesLobbyData(parsedData);
  }
}

export default PrivateTablesLobbyDataHandler;

// privatetablesLD#{
//     "activeTables": [
//         {
//             "isPrivateTable": true,
//             "hostName": "topgun366464",
//             "accessCode": "129561",
//             "autoStart": false,
//             "PTCompletionTime": 1739185208,
//             "orderId": 0,
//             "gameDefId": 129561,
//             "poolId": "129561",
//             "gameType": "TEXAS_HOLDEM",
//             "tableName": "Utdhfgj",
//             "smallBlind": 10,
//             "bigBlind": 20,
//             "isWatchPlayerEnable": true,
//             "tableTime": 10,
//             "startGameTime": 3,
//             "disconnectionExtraTime": 0,
//             "nextGameTimer": 5,
//             "minBuyInAmount": 20,
//             "maxbuyInAmount": 5000,
//             "isRITEnabled": false,
//             "maxPlayers": 6,
//             "maxJoinAndWatchPlayers": 0,
//             "maxWatchPlayersCount": 0,
//             "maxJoinWaitListCount": 0,
//             "isWaitListEnabled": true,
//             "isSplitEnabled": true,
//             "minPlayersToStartGame": 2,
//             "isEnabled": true,
//             "status": "Active",
//             "maxTables": 0,
//             "onlineCount": 0,
//             "channelApplicable": [
//                 "IPAPS",
//                 "IPIPS",
//                 "A23APS",
//                 "A23IPS",
//                 "IPWIN",
//                 "IPMAC"
//             ],
//             "isStraddleEnabled": false,
//             "isSNG": false,
//             "sgtExtraTime": 0,
//             "sgtETCount": 0,
//             "rake": 0,
//             "headsUpRake": 0,
//             "sitOutTimeInMins": 0,
//             "reserveTime": 10,
//             "rakeCapForStartPlayersLETo3": 0,
//             "rakeCapForStartPlayersGT3": 0,
//             "limitType": "NL",
//             "buyInTimer": 10,
//             "rebuyInTimer": 10,
//             "timeBank": 0,
//             "extraTimeBank": 0,
//             "isRankSuggestionEnabled": true,
//             "anonymous": false,
//             "leaderboard": false,
//             "turbo": false,
//             "prizePotLimit": "NL",
//             "bonusPercentage": 0,
//             "antiBankingTime": 0,
//             "passiveUserSitOutCount": 3,
//             "extraTimeTopup": 0,
//             "extraTimeTopupInterval": 0,
//             "extraTimeMaxCap": 0,
//             "disconnectionTimeBank": 10,
//             "disconnectionTimeTopup": 0,
//             "disconnectionTimeTopupInterval": 0,
//             "disconnectionTimeMaxCap": 0,
//             "happyHoursEnabled": false,
//             "happyHoursStartTime": 0,
//             "happyHoursEndTime": 0,
//             "happyHoursRake": 0,
//             "happyHoursHeadsUpRake": 0,
//             "happyHoursConfiguredTime": 0,
//             "happyHoursTimerStarted": false
//         },
//     ],
//     "completedTables": []
// }
