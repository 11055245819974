import React, { useEffect } from "react";
import moment from "moment";

import tourneyBgImage from "../../../assets/lobby/Tourney3x.png";
import specialTourneyBgImage from "../../../assets/lobby/SpecialTourneys3x.png";
import dLetterIcon from "../../../assets/lobby/DIcon.svg";
import emptyIcon from "../../../assets/lobby/empty-box.png";
import cancelledIcon from "../../../assets/lobby/ErrorRoundIcon.svg";
import TrophySmallIcon from "../../../assets/gameTable/TrophySmallIcon.svg";
import multiPlayersIcon from "../../../assets/lobby/MultiplePlayersIcon.svg";
import goldCompleted from "../../../assets/lobby/GoldCompleted.svg";
import leaderboardSmallIcon from "../../../assets/lobby/LeaderboardSmallIcon.svg";
import starIcon from "../../../assets/lobby/StarIcon.svg";

import {
  TourneyRunningStatusWrapper,
  LiveIcon,
  TourneyRunningStatusText,
  GoldOpenIcon,
  ListViewcontainer,
  TourneyGameTileWrapper,
  TourneyTileWrapper,
  TourneyNameAndDeadlineWrapper,
  TourneyDetailsWrapper,
  TourneyTitleAndEndTimeWrapper,
  TourneyGameIconsAndPlayersWrapper,
  TourneyTitleText,
  TourneyTimerText,
  GameTypeIconsWrapper,
  GameTypeIconElement,
  GroupPlayerIconElement,
  PlayersCountText,
  BonusBarWrapper,
  BonusBarFilled,
  SeperatorLine,
  PrizeAmountWrapper,
  BuyInWrapper,
  WinnersWrapper,
  CTAButtonWrapper,
  TourneyDetailItemTitleText,
  WinningAmountText,
  DetailsSeperatorLine,
  TourneyDetailInfoText,
  AnnouncementInfoWrapper,
  AnnouncementInfoContainer,
  AnnouncementIconWrapper,
  AnnouncementTitleText,
  AnnouncementDescriptionText,
  StatusTypeWrapper,
  StatusTypeIconWrapper,
  FeaturedStarIcon,
} from "./tourneyGameTilesListStyles";
import Button from "../../../Common/components/Button/Button";
import colors from "../../../Common/styleGuide/Colors";
import { useDispatch, useSelector } from "react-redux";
import {
  requestTourneyEntriesDynamicData,
  requestTourneyInfoData,
  setIsPlayerJoiningFromLB,
  requestTourneyTablesDynamicData,
  clearDeeplink,
  addLobbyToastMessage,
} from "../../redux/slices/lobbySlice";
import {
  Typo12PrimaryGoldContoBoldText,
  Typo12WhiteContoBoldText,
} from "../../../Common/styleGuide/Typos";
import {
  MIXPANEL_EVENT,
  REDIRECTION_FAIL,
  SIT_AND_GO_TOURNEYS,
  TICKET_BASED,
  TOAST_INFO,
  TOURNEY_ANNOUNCED,
  TOURNEY_MAP,
  TOURNEY_REGISTRATION,
  TOURNEY_REGISTRATION_STARTED,
  TOURNEY_STATUS_CANCELLED,
  TOURNEY_STATUS_COMPLETED,
  TOURNEY_STATUS_LATE_REGISTRATION,
  TOURNEY_STATUS_LIVE,
  TOURNEY_STATUS_MISSED,
  TOURNEY_STATUS_WON,
} from "../../data/Constants";
import { messageToNativeClient } from "../../../Common/utils/platformCommunicationUtil";
import TourneyTileCard from "../TourneyTileCard/TourneyTileCard";
import { Poker_Redirection_Fail } from "../../data/ClevertapConstants";
import { getISTDateTimeFormat } from "../../../Common/utils/GameUtils";
import CleverTapUtil from "../../../Common/utils/CleverTapUtil";
import { sendFaroLog } from "../../../Common/utils/FaroUtil";
import { logCleverTapEvent } from "../../../Common/utils/CleverTapUtilNew";
import { MP_POKER_TOURNEY_TILE_CLICKED } from "../../data/MixPanelConstant";
import { getEventParams } from "../../utils/MixPanelUtils";

function TourneyGameTilesList(props) {
  const { tourneysIdsList, isActiveTabList, isCompletedTabList } = props;
  const dispatch = useDispatch();
  const START_DATE_TIME_FORMAT_STRING = "Do MMM, hh:mm A";
  const TOURNEY_REGISTRATION_END_TIME_FORMAT_STRING = "mm:ss";
  const leaderBoardGameDefinition = useSelector(
    (state) => state.lobby.leaderBoardGameDefinition
  );
  const showTourneyInfoPage = useSelector(
    (state) => state.lobby.showTourneyInfoPage
  );
  const isPlayerJoiningFromLB = useSelector(
    (state) => state.lobby.isPlayerJoiningFromLB
  );
  const masterTourneysData = useSelector(
    (state) => state.lobby.tourneys.masterTourneysData
  );
  const receivedTourneyData = useSelector(
    (state) => state.lobby.receivedTourneyData
  );
  const applicableLeaderboards = useSelector(
    (store) => store.lobby.applicableLeaderboards
  );
  const userBalance = useSelector((state) => state.lobby.balance);
  const channel = useSelector((store) => store.lobby.channel);
  const player = useSelector((store) => store.lobby.player);
  const deeplinkData = useSelector((store) => store.lobby.deeplinkData);
  const formatTourneyDate = (timeStamp, formatString) => {
    try {
      return moment.unix(timeStamp).format(formatString);
    } catch (e) {
      console.warn("ERROR IN FORMATTING DATE IN TOURNEY GAME TILE");
    }
  };

  const renderEmptyAnnouncement = () => {
    return (
      <AnnouncementInfoWrapper id='tourney-lobby'>
        <AnnouncementInfoContainer>
          <AnnouncementIconWrapper src={emptyIcon} />
          {/* <AnnouncementTitleText>{`Oops!`}</AnnouncementTitleText> */}
          <AnnouncementDescriptionText>{`Taking a dealer break! New tourneys starting soon.`}</AnnouncementDescriptionText>
        </AnnouncementInfoContainer>
      </AnnouncementInfoWrapper>
    );
  };

  const handleTourneyTileClick = (tourneyDetails) => {
    let temp = new Date();
    let timeStamp = new Date(temp.getTime() + 330 * 60 * 1000);
    timeStamp = timeStamp.toISOString();
    messageToNativeClient({
      type: "plotlineEvents",
      eventName: "PR_Client_TourneyTile_Click",
      eventProperties: {
        userName: player.userName,
        channel: channel,
        currentCashBalance: userBalance.totalBalance,
        tourneyType: tourneyDetails.tournamentType,
        tourneyName: tourneyDetails.tourneyName,
        tourneyId: tourneyDetails.tourneyId,
        entryFee: tourneyDetails.buyIn + tourneyDetails.tourneyRake,
        registerMode: tourneyDetails.isAlreadyRegisteredTourney
          ? tourneyDetails.tournamentType === TICKET_BASED
            ? "Ticket"
            : "Cash"
          : "NA",
        tourneyStartTime: tourneyDetails.tourneyStartTime,
        registrationStartTime: tourneyDetails.registrationStartTime,
        timeStamp,
      },
    });
    const MixPanel_params = getEventParams(
      MP_POKER_TOURNEY_TILE_CLICKED,
      player,
      userBalance,
      channel,
      tourneyDetails
    );

    messageToNativeClient({
      type: MIXPANEL_EVENT,
      eventName: MP_POKER_TOURNEY_TILE_CLICKED,
      eventProperties: MixPanel_params,
    });
    dispatch(requestTourneyInfoData({ tourneyId: tourneyDetails.tourneyId }));
    dispatch(
      requestTourneyEntriesDynamicData({ tourneyId: tourneyDetails.tourneyId })
    );
    dispatch(
      requestTourneyTablesDynamicData({ tourneyId: tourneyDetails.tourneyId })
    );
  };

  useEffect(() => {
    if (
      isPlayerJoiningFromLB &&
      props.selectedTourneyVariant ===
        leaderBoardGameDefinition?.tournamentType &&
      receivedTourneyData
    ) {
      handleTourneyTileClick(leaderBoardGameDefinition);
      dispatch(setIsPlayerJoiningFromLB({ flag: false }));
    }
  }, [
    isPlayerJoiningFromLB,
    props.selectedTourneyVariant,
    leaderBoardGameDefinition?.tournamentType,
    receivedTourneyData,
  ]);

  useEffect(() => {
    if (
      receivedTourneyData &&
      !showTourneyInfoPage &&
      deeplinkData?.TourneyID &&
      deeplinkData?.Location?.trim().toLowerCase() ===
        TOURNEY_REGISTRATION.toLowerCase()
    ) {
      let tourney = masterTourneysData.get(deeplinkData.TourneyID.trim());

      if (tourney) {
        handleTourneyTileClick(tourney);
      } else {
        dispatch(
          addLobbyToastMessage({ type: TOAST_INFO, message: REDIRECTION_FAIL })
        );
        let timeStamp = getISTDateTimeFormat();
        logCleverTapEvent(Poker_Redirection_Fail, {
          Username: player.userName,
          Channel: channel,
          Timestamp: timeStamp,
          KVPairs: deeplinkData,
        });
        sendFaroLog({
          type: Poker_Redirection_Fail,
          Username: player.userName,
          Channel: channel,
          Timestamp: timeStamp,
          KVPairs: deeplinkData,
          message: `Tourney id not found in master list ${deeplinkData.TourneyID}`,
        });
      }
      dispatch(clearDeeplink());
    }
  }, [deeplinkData?.Location, deeplinkData?.TourneyID, receivedTourneyData]);

  const isLeaderboardPresent = (
    tourneyId,
    tournamentType,
    tourneyStartTime
  ) => {
    for (const element of applicableLeaderboards.tourney) {
      let current = element;
      if (current.tourneySeries) {
        if (
          current.tourneyIds.includes(tourneyId) &&
          tourneyStartTime * 1000 >= current.startTime &&
          tourneyStartTime * 1000 <= current.endTime
        ) {
          return true;
        }
      } else {
        for (const element of current.gameDefIds) {
          let tourneyType = Number(element.split("_")[2]);
          tourneyType = Object.keys(TOURNEY_MAP).find(
            (key) => TOURNEY_MAP[key] === tourneyType
          );

          if (
            tournamentType === tourneyType &&
            tourneyStartTime * 1000 >= current.startTime &&
            tourneyStartTime * 1000 <= current.endTime
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const getTourneyTypeIcon = (tourneyDetails) => {
    const {
      winTheButton,
      tourneyId,
      gameType,
      tourneyStartTime,
      tournamentType,
    } = tourneyDetails;
    let icons = [];
    if (winTheButton) {
      icons.push(<GameTypeIconElement key={"icon-1"} src={dLetterIcon} />);
    }
    if (
      isLeaderboardPresent(
        tourneyId,
        tournamentType,
        tourneyStartTime,
        gameType
      )
    ) {
      icons.push(
        <GameTypeIconElement
          key={"leaderboard-icon"}
          src={leaderboardSmallIcon}
        />
      );
    }
    return icons;
  };

  const getCTAByTourneyStatus = (status) => {
    switch (status) {
      case TOURNEY_ANNOUNCED:
        return (
          <Button
            buttonText={"ANNOUNCED"}
            showIcon={false}
            bgColor={colors.ctaPrimaryColor1}
            //clickHandler={() => dispatch(joinGameTable())}
            // clickHandler={() => gameTileClickHandler(gameDefinition)}
          />
        );
      case TOURNEY_REGISTRATION_STARTED:
        return (
          <Button
            buttonText={"REGISTER"}
            showIcon={false}
            bgColor={colors.ctaPrimaryColor1}
            //clickHandler={() => dispatch(joinGameTable())}
            // clickHandler={() => gameTileClickHandler(gameDefinition)}
          />
        );
      case TOURNEY_STATUS_LIVE:
        return (
          <Button
            buttonText={"LIVE"}
            showIcon={false}
            bgColor={colors.ctaPrimaryColor1}
            //clickHandler={() => dispatch(joinGameTable())}
            // clickHandler={() => gameTileClickHandler(gameDefinition)}
          />
        );
      case TOURNEY_STATUS_CANCELLED:
        return (
          <StatusTypeWrapper>
            <StatusTypeIconWrapper src={cancelledIcon} />
            <Typo12WhiteContoBoldText>CANCELLED</Typo12WhiteContoBoldText>
          </StatusTypeWrapper>
        );
      case TOURNEY_STATUS_MISSED:
        return (
          <StatusTypeWrapper>
            <StatusTypeIconWrapper src={cancelledIcon} />
            <Typo12WhiteContoBoldText>MISSED</Typo12WhiteContoBoldText>
          </StatusTypeWrapper>
        );
      case TOURNEY_STATUS_WON:
        return (
          <StatusTypeWrapper>
            <StatusTypeIconWrapper src={TrophySmallIcon} />
            <Typo12PrimaryGoldContoBoldText>
              YOU WON
            </Typo12PrimaryGoldContoBoldText>
          </StatusTypeWrapper>
        );
      case TOURNEY_STATUS_COMPLETED:
        return (
          <StatusTypeWrapper>
            <StatusTypeIconWrapper src={goldCompleted} />
            <Typo12PrimaryGoldContoBoldText>
              COMPLETED
            </Typo12PrimaryGoldContoBoldText>
          </StatusTypeWrapper>
        );

      default:
        return null;
    }
  };

  const getTourneysDefinitionById = () => {
    const tourneysList = tourneysIdsList.map((eachTourneyId) =>
      masterTourneysData.get(eachTourneyId)
    );
    return tourneysList ? tourneysList : [];
  };

  const renderLateRegistrationTourneys = () => {
    const lateRegistrationTourneysList = getTourneysDefinitionById().filter(
      (eachTourney) =>
        eachTourney?.tourneyStatus === TOURNEY_STATUS_LIVE &&
        eachTourney?.lateRegistrationAllowed &&
        eachTourney?.lateRegistrationStatus !== "FINISHED" &&
        !eachTourney?.lateRegEnded
    );

    const sortedTourneysList = [...lateRegistrationTourneysList].sort(
      (currentTourney, nextTourney) =>
        currentTourney.lateRegistrationDuration -
        nextTourney.lateRegistrationDuration
    );

    return (
      sortedTourneysList.length > 0 && (
        <>
          <TourneyRunningStatusWrapper>
            <LiveIcon />
            <TourneyRunningStatusText>
              LATE REGISTRATION
            </TourneyRunningStatusText>
          </TourneyRunningStatusWrapper>

          {sortedTourneysList.map((eachTourney) => {
            if (eachTourney) {
              return (
                <TourneyTileCard
                  key={eachTourney.tourneyId}
                  tourneyDetails={eachTourney}
                  hideStatusSection={false}
                />
              );
            }
          })}
        </>
      )
    );
  };

  const renderFeaturedTourneys = () => {
    const featuredTourneysList = getTourneysDefinitionById().filter(
      (eachTourney) =>
        eachTourney.isFeatured === true &&
        eachTourney?.tourneyStatus !== TOURNEY_STATUS_LIVE &&
        eachTourney?.tourneyStatus !== TOURNEY_STATUS_MISSED
    );

    // In the tourneys section wise list keeping SNG tourneys as last priority and adding them to the last.
    const sitAndGoTourneys = [];
    const filteredTourneysList = featuredTourneysList.filter((eachTourney) => {
      if (eachTourney.tournamentType === SIT_AND_GO_TOURNEYS) {
        sitAndGoTourneys.push(eachTourney);
        return false;
      }
      return eachTourney;
    });
    let sortedTourneysList = filteredTourneysList.sort(
      (currentTourney, nextTourney) =>
        currentTourney.tourneyStartTime - nextTourney.tourneyStartTime
    );
    sortedTourneysList = [...sortedTourneysList].concat(sitAndGoTourneys);

    return (
      sortedTourneysList.length > 0 && (
        <>
          <TourneyRunningStatusWrapper>
            <FeaturedStarIcon src={starIcon} />
            <TourneyRunningStatusText>FEATURED</TourneyRunningStatusText>
          </TourneyRunningStatusWrapper>

          {sortedTourneysList.map((eachTourney) => {
            if (eachTourney) {
              return (
                <TourneyTileCard
                  key={eachTourney.tourneyId}
                  tourneyDetails={eachTourney}
                  hideStatusSection={false}
                />
              );
            }
          })}
        </>
      )
    );
  };

  const renderWonTourneys = () => {
    const wonTourneys = getTourneysDefinitionById().filter(
      (eachTourney) => eachTourney?.tourneyStatus === TOURNEY_STATUS_WON
    );

    // In the tourneys section wise list keeping SNG tourneys as last priority and adding them to the last.
    const sitAndGoTourneys = [];
    const filteredTourneysList = wonTourneys.filter((eachTourney) => {
      if (eachTourney.tournamentType === SIT_AND_GO_TOURNEYS) {
        sitAndGoTourneys.push(eachTourney);
        return false;
      }
      return eachTourney;
    });

    let sortedTourneysList = filteredTourneysList.sort(
      (currentTourney, nextTourney) =>
        currentTourney.tourneyStartTime - nextTourney.tourneyStartTime
    );

    sortedTourneysList = [...sortedTourneysList].concat(sitAndGoTourneys);

    return (
      sortedTourneysList.length > 0 &&
      sortedTourneysList.map((eachTourney) => {
        if (eachTourney) {
          return (
            <TourneyTileCard
              key={eachTourney.tourneyId}
              tourneyDetails={eachTourney}
              hideStatusSection={false}
            />
          );
        }
      })
    );
  };

  const renderCompletedTourneys = () => {
    const completedTourneys = getTourneysDefinitionById().filter(
      (eachTourney) => eachTourney?.tourneyStatus === TOURNEY_STATUS_COMPLETED
    );

    // In the tourneys section wise list keeping SNG tourneys as last priority and adding them to the last.
    const sitAndGoTourneys = [];
    const filteredTourneysList = completedTourneys.filter((eachTourney) => {
      if (eachTourney.tournamentType === SIT_AND_GO_TOURNEYS) {
        sitAndGoTourneys.push(eachTourney);
        return false;
      }
      return eachTourney;
    });

    let sortedTourneysList = filteredTourneysList.sort(
      (currentTourney, nextTourney) =>
        currentTourney.tourneyStartTime - nextTourney.tourneyStartTime
    );

    sortedTourneysList = [...sortedTourneysList].concat(sitAndGoTourneys);

    return (
      sortedTourneysList.length > 0 &&
      sortedTourneysList.map((eachTourney) => {
        if (eachTourney) {
          return (
            <TourneyTileCard
              key={eachTourney.tourneyId}
              tourneyDetails={eachTourney}
              hideStatusSection={false}
            />
          );
        }
      })
    );
  };

  const renderCancelledTourneys = () => {
    const cancelledTourneys = getTourneysDefinitionById().filter(
      (eachTourney) => eachTourney?.tourneyStatus === TOURNEY_STATUS_CANCELLED
    );

    // In the tourneys section wise list keeping SNG tourneys as last priority and adding them to the last.
    const sitAndGoTourneys = [];
    const filteredTourneysList = cancelledTourneys.filter((eachTourney) => {
      if (eachTourney.tournamentType === SIT_AND_GO_TOURNEYS) {
        sitAndGoTourneys.push(eachTourney);
        return false;
      }
      return eachTourney;
    });

    let sortedTourneysList = filteredTourneysList.sort(
      (currentTourney, nextTourney) =>
        currentTourney.tourneyStartTime - nextTourney.tourneyStartTime
    );
    sortedTourneysList = [...sortedTourneysList].concat(sitAndGoTourneys);

    return (
      sortedTourneysList.length > 0 &&
      sortedTourneysList.map((eachTourney) => {
        if (eachTourney) {
          return (
            <TourneyTileCard
              key={eachTourney.tourneyId}
              tourneyDetails={eachTourney}
              hideStatusSection={false}
            />
          );
        }
      })
    );
  };
  const renderMissedTourneys = () => {
    const missedTourneys = getTourneysDefinitionById().filter(
      (eachTourney) => eachTourney?.tourneyStatus === TOURNEY_STATUS_MISSED
    );
    // In the tourneys section wise list keeping SNG tourneys as last priority and adding them to the last.
    const sitAndGoTourneys = [];
    const filteredTourneysList = missedTourneys.filter((eachTourney) => {
      if (eachTourney.tournamentType === SIT_AND_GO_TOURNEYS) {
        sitAndGoTourneys.push(eachTourney);
        return false;
      }
      return eachTourney;
    });

    let sortedTourneysList = filteredTourneysList.sort(
      (currentTourney, nextTourney) =>
        currentTourney.tourneyStartTime - nextTourney.tourneyStartTime
    );

    sortedTourneysList = [...sortedTourneysList].concat(sitAndGoTourneys);

    return (
      sortedTourneysList.length > 0 &&
      sortedTourneysList.map((eachTourney) => {
        if (eachTourney) {
          return (
            <TourneyTileCard
              key={eachTourney.tourneyId}
              tourneyDetails={eachTourney}
              hideStatusSection={false}
            />
          );
        }
      })
    );
  };

  const renderLiveTourneys = () => {
    // const lateRegistrationTourneysList = getTourneysDefinitionById().filter(
    //   (eachTourney) =>
    //     eachTourney?.tourneyStatus === TOURNEY_STATUS_LIVE &&
    // eachTourney?.lateRegistrationAllowed &&
    //   eachTourney?.lateRegistrationStatus !== "FINISHED" &&
    //   !eachTourney?.lateRegEnded;
    // );
    const liveTourneysList = getTourneysDefinitionById().filter(
      (eachTourney) =>
        (eachTourney?.tourneyStatus === TOURNEY_STATUS_LIVE ||
          eachTourney?.tourneyStatus === TOURNEY_STATUS_MISSED) &&
        (!eachTourney?.lateRegistrationAllowed ||
          eachTourney.lateRegEnded ||
          eachTourney.lateRegistrationStatus === "FINISHED")
    );

    // In the tourneys section wise list keeping SNG tourneys as last priority and adding them to the last.
    const sitAndGoTourneys = [];
    const filteredTourneysList = liveTourneysList.filter((eachTourney) => {
      if (eachTourney.tournamentType === SIT_AND_GO_TOURNEYS) {
        sitAndGoTourneys.push(eachTourney);
        return false;
      }
      return eachTourney;
    });
    let sortedTourneysList = filteredTourneysList.sort(
      (currentTourney, nextTourney) =>
        currentTourney.tourneyStartTime - nextTourney.tourneyStartTime
    );
    sortedTourneysList = [...sortedTourneysList].concat(sitAndGoTourneys);

    return (
      sortedTourneysList.length > 0 && (
        <>
          <TourneyRunningStatusWrapper>
            <GoldOpenIcon />
            <TourneyRunningStatusText>LIVE</TourneyRunningStatusText>
          </TourneyRunningStatusWrapper>

          {sortedTourneysList.map((eachTourney) => {
            if (eachTourney) {
              return (
                <TourneyTileCard
                  key={eachTourney.tourneyId}
                  tourneyDetails={eachTourney}
                  hideStatusSection={false}
                />
              );
            }
          })}
        </>
      )
    );
  };

  const renderOpenTourneys = () => {
    const openTourneysList = getTourneysDefinitionById().filter(
      (eachTourney) =>
        eachTourney?.tourneyStatus !== TOURNEY_ANNOUNCED &&
        eachTourney?.tourneyStatus !== TOURNEY_STATUS_LIVE &&
        eachTourney?.tourneyStatus !== TOURNEY_STATUS_MISSED &&
        eachTourney.isFeatured === false
    );
    // In the tourneys section wise list keeping SNG tourneys as last priority and adding them to the last.
    const sitAndGoTourneys = [];
    const filteredTourneysList = openTourneysList.filter((eachTourney) => {
      if (eachTourney.tournamentType === SIT_AND_GO_TOURNEYS) {
        sitAndGoTourneys.push(eachTourney);
        return false;
      }
      return eachTourney;
    });
    let sortedTourneysList = filteredTourneysList.sort(
      (currentTourney, nextTourney) =>
        currentTourney.tourneyStartTime - nextTourney.tourneyStartTime
    );

    sortedTourneysList = [...sortedTourneysList].concat(sitAndGoTourneys);

    return (
      sortedTourneysList.length > 0 && (
        <>
          <TourneyRunningStatusWrapper>
            <GoldOpenIcon />
            <TourneyRunningStatusText>OPEN</TourneyRunningStatusText>
          </TourneyRunningStatusWrapper>

          {sortedTourneysList.map((eachTourney) => {
            if (eachTourney) {
              return (
                <TourneyTileCard
                  key={eachTourney.tourneyId}
                  tourneyDetails={eachTourney}
                  hideStatusSection={false}
                />
              );
            }
          })}
        </>
      )
    );
  };

  const renderAnnouncedTourneys = () => {
    const announcedTourneysList = getTourneysDefinitionById().filter(
      (eachTourney) =>
        eachTourney?.tourneyStatus === TOURNEY_ANNOUNCED &&
        !eachTourney.isFeatured
    );

    // In the tourneys section wise list keeping SNG tourneys as last priority and adding them to the last.
    const sitAndGoTourneys = [];
    const filteredTourneysList = announcedTourneysList.filter((eachTourney) => {
      if (eachTourney.tournamentType === SIT_AND_GO_TOURNEYS) {
        sitAndGoTourneys.push(eachTourney);
        return false;
      }
      return eachTourney;
    });
    let sortedTourneysList = filteredTourneysList.sort(
      (currentTourney, nextTourney) =>
        currentTourney.registrationStartTime - nextTourney.registrationStartTime
    );

    sortedTourneysList = [...sortedTourneysList].concat(sitAndGoTourneys);

    return (
      sortedTourneysList.length > 0 && (
        <>
          <TourneyRunningStatusWrapper>
            <LiveIcon />
            <TourneyRunningStatusText>Announced</TourneyRunningStatusText>
          </TourneyRunningStatusWrapper>

          {sortedTourneysList.map((eachTourney) => {
            if (eachTourney) {
              return (
                <TourneyTileCard
                  key={eachTourney.tourneyId}
                  tourneyDetails={eachTourney}
                  hideStatusSection={false}
                />
              );
            }
          })}
        </>
      )
    );
  };

  const renderMyTourneys = () => {
    const sitAndGoTourneys = [];
    const filteredTourneysList = getTourneysDefinitionById().filter(
      (eachTourney) => {
        if (eachTourney.tournamentType === SIT_AND_GO_TOURNEYS) {
          sitAndGoTourneys.push(eachTourney);
          return false;
        }
        return eachTourney;
      }
    );
    let sortedTourneysList = [...filteredTourneysList].concat(sitAndGoTourneys);

    return (
      sortedTourneysList.length > 0 &&
      sortedTourneysList.map((eachTourney) => {
        if (eachTourney) {
          return (
            <TourneyTileCard
              key={eachTourney.tourneyId}
              tourneyDetails={eachTourney}
              hideStatusSection={false}
            />
          );
        }
      })
    );
  };

  if (isActiveTabList) {
    return tourneysIdsList && getTourneysDefinitionById().length > 0 ? (
      <ListViewcontainer id='tourney-lobby'>
        {renderLateRegistrationTourneys()}
        {renderFeaturedTourneys()}
        {renderOpenTourneys()}
        {renderAnnouncedTourneys()}
        {renderLiveTourneys()}
      </ListViewcontainer>
    ) : (
      renderEmptyAnnouncement()
    );
  } else if (isCompletedTabList) {
    return tourneysIdsList && getTourneysDefinitionById().length > 0 ? (
      <ListViewcontainer id='tourney-lobby'>
        {renderWonTourneys()}
        {renderCompletedTourneys()}
        {renderMissedTourneys()}
        {renderCancelledTourneys()}
      </ListViewcontainer>
    ) : (
      renderEmptyAnnouncement()
    );
  } else {
    return tourneysIdsList && getTourneysDefinitionById().length > 0 ? (
      <ListViewcontainer id='tourney-lobby'>
        {renderMyTourneys()}
      </ListViewcontainer>
    ) : (
      renderEmptyAnnouncement()
    );
  }
}

export default TourneyGameTilesList;
