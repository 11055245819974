import Handler from "../Handler";

class StartPrivateTableAckHandler extends Handler {
  // StartPTA#
  execute(message) {
    this.service.processPrivateTableGameStart();
  }
}

export default StartPrivateTableAckHandler;
