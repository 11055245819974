import styled from "styled-components";
import privateTableBg from "../../../../assets/lobby/private-table-BG.png";
import colors from "../../../../Common/styleGuide/Colors";

export const LandingPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: url(${privateTableBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const HostTableBox = styled.div`
  width: 50%;
  height: 12%;
  margin: 10px auto 8px auto;
  position: relative;
`;

export const HostTableLabel = styled.div`
  font-size: 20px;
  font-weight: 700;
  background: linear-gradient(
    91.77deg,
    #eabf69 0.39%,
    #fffdea 50.39%,
    #eabf69 100.39%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  text-align: center;
`;

export const SubLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  // color : #FFFDEA;
  text-align: center;
  padding-top: 7px;
  background: linear-gradient(
    91.77deg,
    ${colors.primaryGold} 0.39%,
    #fffdea 50.39%,
    ${colors.primaryGold} 100.39%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
`;

export const StarImage = styled.img`
  width: ${(props) => props.width}px;
  position: absolute;
  top: ${(props) => (props.top ? props.top + "px" : "unset")};
  left: ${(props) => (props.left ? props.left + "px" : "unset")};
  right: ${(props) => (props.right ? props.right + "px" : "unset")};
`;

export const HostedLobbyContainer = styled.div`
  padding: 3% 3% 0 3%;
  height: 75%;
  overflow: hidden;
`;

export const HostedTableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: linear-gradient(270deg, #075063 0%, #336070 100%);
  border: 1px solid rgba(234, 191, 105, 1);
  justify-content: space-around;
  align-items: center;
  padding: 3% 2%;
  border-radius: 5px;
  width: 100%;
  margin: 10px auto;
`;

export const HostedTableDetails = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: ${(props) => (props.showHostedCount ? "column" : "row")};
  align-items: left;
  img {
    width: 30px;
    margin-right: 10px;
  }
`;

export const CreateTableLabel = styled.div`
  border-bottom: 1px solid ${colors.primaryGold};
  padding-bottom: 5px;
  display: flex;
  align-items: center;
`;

export const PlusIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 4px;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 80%;
  margin: 0 auto;
`;

export const Image = styled.img`
  width: 100%;
`;

export const TextOnTable = styled.div`
  width: 60%;
  position: absolute;
  top: 30%;
  left: 20%;
  text-align: center;
`;

export const CreateTableButton = styled.button`
  outline: 0px;
  width: 50%;
  background: linear-gradient(rgb(230, 198, 131) 0%, rgb(255, 184, 52) 100%);
  box-shadow: rgb(2, 24, 30) 4px 4px 4px 0px;
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  top: 60%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  border-radius: 4px;
  line-height: 40px;
  font-family: "Conto";
`;

export const AlreadyInvitedContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row;
  margin-top: 20px;
  button {
    margin-left: 4%;
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
`;

export const CompletedIcon = styled.img`
  width: 15px;
  margin-right: 5px;
`;

export const ActiveIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #42be40;
  margin-right: 5px;
`;

export const GameTileContainer = styled.div`
  height: 52vh;
  overflow-y: auto;
  padding-bottom: 10px;
`;

export const EnterCodeFooter = styled.div`
  background-color: #042932;
  position: absolute;
  bottom: 0;
  padding: 2% 4%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10%;
  width: 100%;
  button {
    margin-left: 10%;
    width: 100px;
    height: 35px;
  }
`;
