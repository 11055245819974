import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";
import privateTableBg from "../../../assets/lobby/private-table-BG.png";

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  height: 88vh;
  width: 100%;
  animation: ${(props) =>
    props.sliderDirection === "open"
      ? "themes-move-up forwards 0.25s"
      : "themes-move-down forwards 0.25s"};

  background: url(${privateTableBg});
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  padding: 20px 0;
  border-bottom: 1px solid #075063;
`;

export const GeneralInfoWrapper = styled.div`
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 5% 0;
  align-items: center;
`;

export const GeneralLabel = styled.span`
  background: linear-gradient(155.76deg, #e6c683 23.99%, #a78132 90.12%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
`;

export const StarImg = styled.img`
  width: ${(props) => props.width}px;
  height: auto;
  padding-left: 3px;
`;

export const BaseLine = styled.div`
  width: ${(props) => (props.width ? props.width : 25)}%;
  height: 1.5px;
`;

export const StrokeLineTowardsLeft = styled(BaseLine)`
  background: ${(props) =>
    props.base === "white"
      ? `linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255 255 255 / 41%), rgb(255 254 252))`
      : `
linear-gradient(
  to right,
  rgba(255, 255, 255, 0),
  rgb(230, 198, 110),
  rgb(230, 198, 131)
)`};

  margin-right: 9px;
`;

export const StrokeLineTowardsRight = styled(BaseLine)`
  background: ${(props) =>
    props.base === "white"
      ? `
  
  linear-gradient(to right, rgb(255, 254, 252), rgba(255, 255, 255, 0.41),rgba(255, 255, 255, 0))`
      : ` linear-gradient(
  to right,
  rgb(230, 198, 131),
  rgb(230, 198, 110),
  rgba(255, 255, 255, 0)
)
`};
  margin-left: 9px;
`;
export const DetailsWrapper = styled.div`
  overflow-y: auto;
  height: 70vh;
`;

export const HostDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5%;
  width: 100%;
`;

export const Row = styled.div`
  width: 100%;
  margin-bottom: 7%;
  display: ${(props) => (props.dBlock ? "block" : "flex")};
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const PlayersPerTableWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

export const PlayersPerTable = styled.span`
  border: 1.5px solid #0c3743;
    linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  width: 50px;
  text-align: center;
  background: ${(props) =>
    props.active
      ? `linear-gradient(3.18deg, #075063 7.51%,#216579 86.57%)`
      : "#032028"};
  border-radius: 5px;
  padding: 7px 4px;
  color: ${(props) => (props.active ? colors.primaryGold : colors.whiteTwo)};
  margin-right: 10px;
  transition: all 0.2s ease;
  font-weight: ${(props) => (props.active ? 800 : 400)};
  
  :last-child{
    margin-right:0;
  }
`;

export const Toggle = styled.img`
  height: 25px;
  width: 50px;
  margin-left: 15px;
`;

export const Footer = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid #075063;
  margin-top: 6px;
  padding-top: 10px;
`;

export const BaseButton = styled.button`
  width: 43%;
  height: 37px;
  outline: none;
  border: none;
  font-family: "Conto";
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  &:disabled {
    opacity: 0.7;
    pointer-events: none;
  }
`;

export const Button = styled(BaseButton)`
  background: ${colors.primaryGreen};
  color: ${colors.white};
`;

export const SecondaryButton = styled(BaseButton)`
  border: 1px solid
    ${(props) => (props.showBorder ? colors.leafyGreen : colors.transparent)};
  background: transparent;
  color: ${(props) =>
    props.showBorder ? colors.leafyGreen : colors.pebbleGray};
`;

export const SavedTemplateIcon = styled.img`
  width: 15px;
  margin-right: 5px;
`;
