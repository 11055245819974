import styled from "styled-components";
import colors from "../../../Common/styleGuide/Colors";
import {
  Typo12GrayContoMediumText,
  Typo12WhiteTwoContoMediumText,
  Typo14OffWhiteContoBoldText,
  Typo15LightCornflowerBlueBoldText,
  Typo15PrimaryGoldContoMediumText,
} from "../../../Common/styleGuide/Typos";

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 12px 12px 12px;
  box-sizing: border-box;
  height: 96px;

  background: "linear-gradient(0deg, #075063, #075063), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))";
`;

export const LogoAndLiveCountContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 12px;
  box-sizing: border-box;
  height: auto;
  justify-content: space-between;
  align-items: center;
`;

export const A23LogoWrapper = styled.img`
  height: 24px;
`;

export const NotificationAndLiveCountContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  align-items: center;
  position: relative;
`;

export const NotificationIcon = styled.img`
  margin-right: 16px;
`;

export const BackToPlatformButton = styled.div`
  padding: 5px 16px;
  font-size: 12px;
  color: white;
  background: ${colors.cashFunToggleActiveColor};
  border: 2px solid black;
  border-radius: 25px;
  font-family: "Conto";
`;

export const LivePlayerCountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  align-items: center;
`;

export const PlayerIcon = styled.img`
  margin-right: 4px;
  height: 12px;
`;

export const LiveCountText = styled(Typo12GrayContoMediumText)``;

export const AddCashAndDynamicHeaderItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 4px;
  box-sizing: border-box;
  height: auto;
  justify-content: space-between;
  align-items: center;
`;

export const WalletAndAddCashCTAWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  align-items: center;
`;

export const CashAndPracticeGamesWrapper = styled.div`
  display: flex;
  width: fit-content;
  padding: 2px;
  border-radius: 8px;
  height: 28px;
  background-color: ${colors.pitchBlack};
  align-items: center;
  justify-content: center;
`;

export const CashAndPracticeGameToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 60px;
  height: 100%;
  border-radius: 6px;
  background: ${(props) =>
    props.isActive ? colors.cashFunToggleActiveColor : "transparent"};
  justify-content: center;
  align-items: center;
`;

export const CashAndPracticeItemText = styled(Typo12WhiteTwoContoMediumText)``;

export const WalletWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 28px;
  width: auto;
  border-bottom: ${(props) =>
    props.isPseudoLockedpLayer
      ? `1px solid ${colors.gray}`
      : `1px solid ${colors.primaryGold}`};
  margin-right: 16px;
  margin-top: -2px;
`;

export const WalletIcon = styled.img`
  height: 12px;
  width: 12px;
  margin-right: 8px;
`;

export const WalletAmountText = styled(Typo15PrimaryGoldContoMediumText)`
  color: ${(props) =>
    props.isPseudoLockedpLayer ? colors.gray : colors.primaryGold};
`;

export const DynamicHeaderTitleText = styled(Typo14OffWhiteContoBoldText)`
  line-height: normal;
  letter-spacing: -0.28px;
  text-transform: uppercase;
`;

export const FunChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  height: 28px;
  justify-content: flex-end;
  /* background-color: red; */
  width: 100%;
  align-items: center;
`;

export const FunChipsBalanceText = styled(Typo15LightCornflowerBlueBoldText)`
  margin-left: 6px;
  margin-right: 10px;
`;

export const ChipIcon = styled.img`
  width: 12px;
  height: 12px;
`;

export const ReloadIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const RedDotIcon = styled.span`
  display: flex;
  height: 8px;
  width: 8px;
  border-radius: 5px;
  background-color: ${colors.primaryRed};
  position: absolute;
  z-index: 5;
  top: -2px;
  left: 7px;
`;

export const Icon = styled.img`
  position: absolute;
  width: 8px;
  left: 15px;
`;
