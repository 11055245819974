import Handler from "../Handler";

class NextGameCancel extends Handler {
  execute(message) {
    let data;
    try {
     let receivedData = JSON.parse(message);
      data = {
        status: receivedData?.status,
        isPrivateTable: receivedData?.isPrivateTable || false
      };
    } catch (err) {
      console.log("Error at next game cancel handler ", err);
    }
    this.service.processNextGameCancel(data);
  }
}

export default NextGameCancel;
