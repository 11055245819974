import { messageToNativeClient } from "../../../Common/utils/platformCommunicationUtil";
import * as Constants from "../../data/Constants";
import LobbyConnection from "../../io/LobbyConnection";
import { sendLobbyDebugProtocol } from "../../utils/ProtocolUtils";
import {
  getGameSlice,
  getLobbySlice,
  getModalSlice,
} from "../../utils/ReduxUtils";
import {
  closeExistingTourneyGameTable,
  handleTourneyStartTableCreationBasedOnActiveGames,
  updateGameDefinition,
  updatePlayerSettingsConfig,
} from "../slices/gameTableSlice";
import {
  lobbyPlayData,
  lobbyRealData,
  lobbyConAck,
  updateLackGamesDetails,
  updateGameDefinitionsMasterdata,
  updateLobbyHeartBeatDetails,
  lobbyUpdatedGameDefinition,
  upadatePlayersCount,
  handleTourneysLobbyData,
  updateTourneyInfoData,
  handleTourneyRegisteredCountChange,
  handleTourneyStatusChange,
  updateTourneyPlayerStatusChange,
  handleTourneyStart,
  handleDynamicTourneyLobbyData,
  handleTourneyPrize,
  handleTourneyComplete,
  handleTourneyRanksData,
  handleTourneyCancle,
  handleTourneyTablesData,
  updateGameActiveStatus,
  updateTourneySlabChange,
  updatePlayerPlayChipsBalance,
  updateLackReceivedStatus,
  handlePlayChipsBalanceReload,
  updateTourneyPrizePoolUpdate,
  processFunChipsBalance,
  updateTourneyLateRegistrationEnded,
  showTourneyMaxTablesLimitReached,
  handleDynamicTourneysStartData,
  updateShowLobbyLoading,
  handleTourneyTicketsData,
  requestUpdatedTourneyPlayerStatus,
  handleTourneyPlayerAutoRegister,
  handleNewChildTourneyData,
  handleTourneyRemove,
  handleTourneyDynamicCountsUpdate,
  updateDepositorTourneyDetails,
  updateTourneyInTheMoney,
  updateTourneyInBreak,
  requestTourneyInfoData,
  sortLobbyGamesByHappyHours,
  handleHandRankingTagLineData,
  handleBlockedStatesDetails,
  handlePrivateTableCreateGameDef,
  handlePrivateTableCreateGameDefFailure,
  handleProcessPrivateTablesLobbyData,
  handleDynamicPrivateTableLobbyData,
  handlePrivateTableStatusChange,
  handlePrivateTableGameDefInfo,
  handleTourneyCompletedStackDetails,
  handleTourneyQualifiedPlayersDetails,
  handlePrivateTableGameDefPlayerCount,
  handlePrivateTableGameDefInfoFailure,
} from "../slices/lobbySlice";
import { closeModal, openModal } from "../slices/modalSlice";

class LobbyService {
  static instance;
  constructor(dispatchFunction) {
    this.dispatchFunction = dispatchFunction;
  }

  processRealStake(data) {
    let realStakeGames = data.filter(
      (eachGameDefinition) =>
        eachGameDefinition.flavourType.toLowerCase() === Constants.LB_REAL
    );
    let texasHoldemGames = realStakeGames.filter(
      (eachGameDefinition) =>
        eachGameDefinition.gameType === Constants.TEXAS_HOLDEM
    );
    let omahaGames = realStakeGames.filter(
      (eachGameDefinition) => eachGameDefinition.gameType === Constants.OMAHA
    );
    let omaha5Games = realStakeGames.filter(
      (eachGameDefinition) => eachGameDefinition.gameType === Constants.OMAHA5
    );
    let omaha6Games = realStakeGames.filter(
      (eachGameDefinition) => eachGameDefinition.gameType === Constants.OMAHA6
    );
    this.dispatchFunction(
      lobbyRealData({
        texasHoldem: texasHoldemGames,
        omaha: omahaGames,
        omaha5: omaha5Games,
        omaha6: omaha6Games,
      })
    );
    // this.dispatchFunction(
    //   updateShowLobbyLoading({
    //     showLobbyLoading: false,
    //   })
    // );
  }

  processPlayStake(data) {
    let funGameDefinitions = [...data].filter(
      (eachGameDefinition) =>
        eachGameDefinition.flavourType.toLowerCase() === Constants.LB_PLAY
    );
    let playStakeGames = funGameDefinitions.map((eachFunGame) => {
      let updatedGameDetails = { ...eachFunGame };
      updatedGameDetails.originalGameType = eachFunGame.gameType;
      updatedGameDetails.gameType = eachFunGame.gameVariant;

      return updatedGameDetails;
    });

    let texasHoldemGames = playStakeGames.filter(
      (eachGameDefinition) =>
        eachGameDefinition.gameType === Constants.TEXAS_HOLDEM
    );
    let omahaGames = playStakeGames.filter(
      (eachGameDefinition) => eachGameDefinition.gameType === Constants.OMAHA
    );
    let omaha5Games = playStakeGames.filter(
      (eachGameDefinition) => eachGameDefinition.gameType === Constants.OMAHA5
    );
    let omaha6Games = playStakeGames.filter(
      (eachGameDefinition) => eachGameDefinition.gameType === Constants.OMAHA6
    );
    this.dispatchFunction(
      lobbyPlayData({
        texasHoldem: texasHoldemGames,
        omaha: omahaGames,
        omaha5: omaha5Games,
        omaha6: omaha6Games,
      })
    );
  }
  lobbyConAck(data) {
    // this.dispatchFunction(lobbyConAck({ data: data }));
    // TODO: currently there is no action receiver in redux with name 'lobbyConAck', hence commented, have to re-check this @yashwanth
    // messageToNativeClient({ type: "appLoaded" });
    let lobbyData = getLobbySlice();
    if (lobbyData.resourceLoadFailure) {
      LobbyConnection.getInstance().removeLobbyConnection();
      this.dispatchFunction(openModal({ type: Constants.ASSET_ERROR }));
    }
  }
  reconnectionAck(data) {
    // Write reconnection code for lobby here if required.
    let lobbyData = getLobbySlice();
    if (lobbyData.resourceLoadFailure) {
      LobbyConnection.getInstance().removeLobbyConnection();
      this.dispatchFunction(openModal({ type: Constants.ASSET_ERROR }));
    }
    if (lobbyData?.selectedTourneyDetailsId) {
      this.dispatchFunction(
        requestTourneyInfoData({
          tourneyId: lobbyData.selectedTourneyDetailsId,
        })
      );
    }
  }
  processLackGames(data) {
    const { realActiveGames, realWaitingGames, tourneyActiveGames } = data;
    let updatedActiveGamesData = { playerId: "", activeGames: [] };

    if (realWaitingGames && realWaitingGames.waitingGamesList.length > 0) {
      updatedActiveGamesData = {
        playerId: realWaitingGames.playerId,
        activeGames: [...realWaitingGames.waitingGamesList],
      };
    }

    if (realActiveGames && realActiveGames.activeGames.length > 0) {
      updatedActiveGamesData = {
        playerId: realActiveGames.playerId,
        activeGames: [
          ...updatedActiveGamesData.activeGames,
          ...realActiveGames.activeGames,
        ],
      };
    }

    if (
      updatedActiveGamesData &&
      updatedActiveGamesData.activeGames.length > 0
    ) {
      this.dispatchFunction(
        updateLackGamesDetails({ ...updatedActiveGamesData })
      );
    }

    if (
      tourneyActiveGames &&
      tourneyActiveGames.tourneyActiveGameList.length > 0
    ) {
      this.dispatchFunction(
        handleTourneyStart({
          tourneys: [...tourneyActiveGames.tourneyActiveGameList],
          isTourneyLackGame: true,
        })
      );
    }

    this.dispatchFunction(updateLackReceivedStatus());
  }

  processLobbyGameDefinitionsMasterData(data) {
    this.dispatchFunction(
      updateGameDefinitionsMasterdata({ key: Constants.LOBBY_DATA, data: data })
    );
  }

  porcessSpecialBets(data) {
    this.dispatchFunction(
      updateGameDefinitionsMasterdata({
        key: Constants.SPECIAL_BETS_DATA,
        data: data,
      })
    );
  }
  processLobbyHeartBeat(data) {
    this.dispatchFunction(updateLobbyHeartBeatDetails(data));
  }
  processGameDefinitionUpdateData(data) {
    this.dispatchFunction(
      lobbyUpdatedGameDefinition({ gameDefinition: { ...data } })
    );
    this.dispatchFunction(
      updateGameDefinition({ gameDefinition: { ...data } })
    );
    this.dispatchFunction(sortLobbyGamesByHappyHours());
  }
  processUpdatePlayerCount(receivedData) {
    this.dispatchFunction(upadatePlayersCount(receivedData));
  }
  processTourneyLobbyData(receivedData) {
    this.dispatchFunction(handleTourneysLobbyData(receivedData));
  }
  processTourneyInfoData(tourneyInfoData) {
    this.dispatchFunction(updateTourneyInfoData(tourneyInfoData));
  }

  processTourneyRegisteredCount(tourneyRegistrationCountMap) {
    this.dispatchFunction(
      handleTourneyRegisteredCountChange(tourneyRegistrationCountMap)
    );
  }

  processTourneyStatusChange(tourneyData) {
    if (
      tourneyData?.status === Constants.TOURNEY_STATUS_LIVE ||
      tourneyData?.status === Constants.TOURNEY_STATUS_COMPLETED
    ) {
      this.dispatchFunction(
        requestUpdatedTourneyPlayerStatus({
          tourneyId: tourneyData?.tourneyId,
        })
      );
    }
    this.dispatchFunction(handleTourneyStatusChange(tourneyData));
  }

  processTourneyPlayerStatusChange(tourneyData) {
    this.dispatchFunction(updateTourneyPlayerStatusChange(tourneyData));
  }

  processTourneyStart(tourneyStartData) {
    let gamesOnTable = getGameSlice()?.games;
    let activeGamesIds = Object.keys(gamesOnTable);
    let activeTourneyGamesInTable = 0;
    let maxTablesAllowed = 4;

    let defaultMaxTablesLimitMessage =
      "Your tourney is about to begin. Please close one of the existing tables in order to participate in the tourney.";
    let allTablesaAreTourneysMessage =
      "Your tourney is about to begin. Please complete any one of the tourneys in order to participate in the new tourney.";

    if (activeGamesIds?.length === maxTablesAllowed) {
      activeGamesIds.map((eachGameId) => {
        let gameDetails = gamesOnTable[eachGameId];
        if (
          gameDetails &&
          gameDetails?.isTourneyGame &&
          !gameDetails?.watchPlayer
        ) {
          activeTourneyGamesInTable = activeTourneyGamesInTable + 1;
        }
      });

      let message =
        activeTourneyGamesInTable === maxTablesAllowed
          ? allTablesaAreTourneysMessage
          : defaultMaxTablesLimitMessage;
      this.dispatchFunction(
        showTourneyMaxTablesLimitReached({ message: message })
      );
      this.dispatchFunction(
        handleTourneyStartTableCreationBasedOnActiveGames({ message: message })
      );
    }

    // this.dispatchFunction(
    //   handleTourneyStart({
    //     tourneys: [tourneyStartData],
    //     isTourneyLackGame: false,
    //   })
    // );

    //NOTE: Commenting below dispatch method as this is suspect of "Error: Minified Redux error" issue.
    // this.dispatchFunction(
    //   closeExistingTourneyGameTable({ tourneyId: tourneyStartData?.tourneyId })
    // );

    this.dispatchFunction(
      handleDynamicTourneysStartData({
        tourneyDetails: { ...tourneyStartData, watchPlayer: false },
      })
    );

    let openedModal = getModalSlice();
    console.log("Yashwanth logging openedModal:", openedModal);
    if (
      openedModal.type === Constants.TOURNEY_LATE_REGISTRATION_SUCCESS &&
      openedModal.visible
    ) {
      this.dispatchFunction(closeModal());
    }
  }

  processDynamicTourneyLobbyData(tournaments) {
    this.dispatchFunction(handleDynamicTourneyLobbyData(tournaments));
  }
  processTourneyPrizeData(prizeData) {
    this.dispatchFunction(requestUpdatedTourneyPlayerStatus());
    this.dispatchFunction(handleTourneyPrize(prizeData));
  }
  processTourneyComplete(tourneyDetails) {
    this.dispatchFunction(
      requestUpdatedTourneyPlayerStatus({
        tourneyId: tourneyDetails?.tourneyId,
      })
    );
    this.dispatchFunction(handleTourneyComplete(tourneyDetails));
  }
  processTourneyRanksData(ranksData) {
    this.dispatchFunction(handleTourneyRanksData(ranksData));
  }
  processTourneyCancle(tourneyDetails) {
    this.dispatchFunction(handleTourneyCancle(tourneyDetails));
  }
  processTourneyTableData(tablesData) {
    this.dispatchFunction(handleTourneyTablesData(tablesData));
  }
  processGameActiveStatus(receivedData) {
    this.dispatchFunction(updateGameActiveStatus(receivedData));
  }
  processPlayerConfig(receivedData) {
    this.dispatchFunction(updatePlayerPlayChipsBalance(receivedData));
    this.dispatchFunction(updatePlayerSettingsConfig(receivedData));
  }
  processTourneySlabChange(receivedData) {
    this.dispatchFunction(updateTourneySlabChange(receivedData));
  }
  processPlayerChipsReload(receivedData) {
    this.dispatchFunction(handlePlayChipsBalanceReload(receivedData));
    this.dispatchFunction(closeModal());
  }
  processTourneyPrizePoolUpdateHandler(receivedData) {
    this.dispatchFunction(updateTourneyPrizePoolUpdate(receivedData));
  }
  processPlayerChipsLatestBalance(receivedData) {
    this.dispatchFunction(processFunChipsBalance(receivedData));
  }
  processTourneyLateRegistrationEnded(receivedData) {
    this.dispatchFunction(updateTourneyLateRegistrationEnded(receivedData));
  }
  processTourneyTicketsData(receivedData) {
    this.dispatchFunction(handleTourneyTicketsData(receivedData));
  }
  processTourneyPlayerAutoRegister(receivedData) {
    this.dispatchFunction(handleTourneyPlayerAutoRegister(receivedData));
  }
  processNewChildTourneyData(receivedData) {
    this.dispatchFunction(handleNewChildTourneyData(receivedData));
  }
  processTourneyRemove(receivedData) {
    this.dispatchFunction(handleTourneyRemove(receivedData));
  }
  processTourneyDynamicCountsUpdate(receivedData) {
    this.dispatchFunction(handleTourneyDynamicCountsUpdate(receivedData));
  }
  processUpdateDepositorTourneyDetails(receivedData) {
    this.dispatchFunction(updateDepositorTourneyDetails(receivedData));
  }
  processTourneyInTheMoneyData(receivedData) {
    this.dispatchFunction(
      requestUpdatedTourneyPlayerStatus({ tourneyId: receivedData?.tourneyId })
    );
    this.dispatchFunction(updateTourneyInTheMoney(receivedData));
  }
  processTourneyInBreakRequest(receivedData) {
    this.dispatchFunction(
      updateTourneyInBreak({ breakInProgress: true, ...receivedData })
    );
  }
  processTourneyDepositorTicketRequest(receivedData) {
    this.dispatchFunction(
      openModal({
        type: Constants.TOURNEY_TICKET_RECEIVED_THROUGH_ADD_CASH,
        config: { tourneyName: receivedData?.tourneyName },
      })
    );
  }

  processHandrankingTagLineData = (receivedData) => {
    this.dispatchFunction(handleHandRankingTagLineData(receivedData));
  };

  processBlockedStatesData = (receivedData) => {
    this.dispatchFunction(handleBlockedStatesDetails(receivedData));
  };
  processPrivateTableCreateGameDef = (data) => {
    this.dispatchFunction(
      handlePrivateTableCreateGameDef({
        ...data,
        mode: Constants.HOSTED_PRIVATE_TABLE,
      })
    );
  };

  processPrivateTableCreateGameDefFailure = () => {
    this.dispatchFunction(handlePrivateTableCreateGameDefFailure());
  };

  processPrivateTablesLobbyData = (data) => {
    this.dispatchFunction(handleProcessPrivateTablesLobbyData({ ...data }));
  };

  processDynamicPrivateTableLobbyData = (data) => {
    this.dispatchFunction(handleDynamicPrivateTableLobbyData({ ...data }));
  };

  processDynamicPrivateTableStatusChange = (data) => {
    this.dispatchFunction(handlePrivateTableStatusChange({ ...data }));
  };
  processPrivateTableGameDefInfo = (data) => {
    this.dispatchFunction(handlePrivateTableGameDefInfo({ data: data }));
  };

  processPrivateTableGameDefInfoFailure = () => {
    this.dispatchFunction(handlePrivateTableGameDefInfoFailure());
  };

  processFlightStackDetailsAck = (receivedData) => {
    this.dispatchFunction(handleTourneyCompletedStackDetails(receivedData));
  };

  processFlightQualifiedPlayersAck = (receivedData) => {
    this.dispatchFunction(handleTourneyQualifiedPlayersDetails(receivedData));
  };

  processPrivateTableGameDefPlayerCount = (data) => {
    this.dispatchFunction(handlePrivateTableGameDefPlayerCount({ data: data }));
  };
}

export default LobbyService;
