import React, { useEffect, useState } from "react";
import { Container, ModalWrapper, ModalBody } from "./saveTemplateStyle";
import {
  ButtonText,
  CloseIcon,
  CTAContainer,
  HeaderTitleText,
  ModalHeader,
  PrimaryCTAButton,
} from "../../../../Poker/components/ReportIssue/reportIssueStyles";
import closeIcon from "../../../../assets/common/close.svg";
import { closeModal } from "../../../../Poker/redux/slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../../Poker/components/Loader/Loader";
import { InputField } from "../../Inputs/TextOrNumber/InputField";
import { Typo12GrayContoMediumText } from "../../../styleGuide/Typos";
import {
  INPUT_TYPE_TEXT,
  TEMPLATE_SAVED_SUCCESSFULLY,
  TOAST_ERROR,
  TOAST_INFO,
} from "../../../../Poker/data/Constants";
import axios from "axios";
import {
  addLobbyToastMessage,
  handleTemplateSaveStatus,
  sendLobbyDebugLog,
} from "../../../../Poker/redux/slices/lobbySlice";

export const SaveTemplate = (props) => {
  const { modalConfig } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [templateData, setTemplateData] = useState({});

  const { userId, userName } = useSelector((state) => state.lobby.player);
  const accessToken = useSelector((state) => state.lobby.accessToken);

  useEffect(() => {
    setTemplateData({
      userId: userId,
      hostName: userName,
      isPrivateTable: true,
      maxPlayers: modalConfig.playersPerTable,
      maxbuyInAmount: modalConfig.maxBuyInAmount,
      minBuyInAmount: modalConfig.minBuyInAmount,
      bigBlind: modalConfig.bigBlind,
      smallBlind: modalConfig.smallBlind,
      gameType: modalConfig.gameType,
      autoStart: modalConfig.autoStart,
      minPlayersToStartGame: modalConfig.minPlayersBeforeAutoStartGame,
      tableName: modalConfig.tableName,
      tableTime: modalConfig.completionTime,
      turnTimer: modalConfig.turnTimer,
      completionTime: modalConfig.completionTime,
      autoReloadChips : modalConfig.autoReloadChips,
    });
    return () => {};
  }, []);

  const dispatch = useDispatch();

  const handleModalClose = () => {
    dispatch(closeModal());
  };

  const handleClick = async () => {
    if (templateName !== "") {
      let payload = {
        ...templateData,
        templateName: templateName,
      };
      setIsLoading(true);
      let url = process.env.REACT_APP_SAVE_TEMPLATE;
      let headerVar = {
        headers: {
          Authorization: accessToken,
        },
      };

      try {
        let result = await axios.post(url, payload, headerVar);
        let data = result.data;
        if (data?.statusCode === TEMPLATE_SAVED_SUCCESSFULLY) {
          dispatch(
            addLobbyToastMessage({
              type: TOAST_INFO,
              message: data.message,
            })
          );
          dispatch(
            handleTemplateSaveStatus({
              status: TEMPLATE_SAVED_SUCCESSFULLY,
            })
          );
        }
      } catch (e) {
        dispatch(
          addLobbyToastMessage({
            type: TOAST_ERROR,
            message: "Failed to save template, please try again later",
          })
        );
        sendLobbyDebugLog({
          type: "SAVE TEMPLATE FAILED",
          error: JSON.stringify(e),
        });
      }
      handleModalClose();
      setIsLoading(false);
    }
  };

  const onInputChange = (e) => {
    setTemplateName(e.target.value);
  };

  return (
    <Container>
      <ModalWrapper>
        <ModalHeader>
          <HeaderTitleText>Save as Template</HeaderTitleText>
          <CloseIcon src={closeIcon} onClick={handleModalClose} />
        </ModalHeader>
        <ModalBody>
          <Typo12GrayContoMediumText>
            Name of the Template
          </Typo12GrayContoMediumText>
          <InputField
            type={INPUT_TYPE_TEXT}
            onChangeHandler={onInputChange}
            onBlurHandler={onInputChange}
          />
        </ModalBody>
        <CTAContainer>
          <PrimaryCTAButton
            onClick={() => handleClick()}
            disabled={isLoading || templateName === ""}
          >
            {isLoading ? (
              <Loader size={20} />
            ) : (
              <ButtonText>Save Template</ButtonText>
            )}
          </PrimaryCTAButton>
        </CTAContainer>
      </ModalWrapper>
    </Container>
  );
};
