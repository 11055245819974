import styled from "styled-components";
import colors from "../../../../Common/styleGuide/Colors";

export const Container = styled.div`
  position: absolute;
  bottom: 0px;
  height: ${(props) => (props.isInputFocused ? 80 : 50)}vh;
  width: 100%;
  animation: ${(props) =>
    props.sliderDirection === "open"
      ? "themes-move-up forwards 0.25s"
      : "themes-move-down forwards 0.25s"};
  background-color: rgb(4, 54, 66);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
`;

export const CodeContainer = styled.div`
  width: 100%;
  padding: 4%;
`;
export const GoodTimesWrapper = styled.div`
  width: 60%;
  margin: 3% auto 10% auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const GoodTimesImg = styled.img`
  width: 35%;
  margin-bottom: 10px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${colors.primaryGold};
`;

export const AccessCodeInputField = styled.input`
  background-color: ${colors.inputBG};
  color: ${colors.primaryGold};
  outline: none;
  border: none;
  text-align: center;
  width: 35%;
  height: 40px;
  ::placeholder {
    font-weight: 900;
    font-size: 40px;
    color: ${colors.inputPlaceholder};
  }
`;
