import React, { useState } from "react";
import goldCardsIcon from "../../../assets/lobby/GoldCardsIcon.svg";
import defaultTourneysIcon from "../../../assets/lobby/DefaultTourneysIcon.svg";

import defaultCardsIcon from "../../../assets/lobby/DefaultCardsIcon.svg";
import activeTourneysIcon from "../../../assets/lobby/ActiveTourneysIcon.svg";
import defaultPromotionIcon from "../../../assets/lobby/defaultPromotion.svg";
import activePromotionIcon from "../../../assets/lobby/activePromotion.svg";
import defaultRulesIcon from "../../../assets/lobby/DefaultRulesIcon.svg";
import goldHamburgerIcon from "../../../assets/lobby/GoldHamburger.svg";
import defaultHamburger from "../../../assets/lobby/DefaultHamburger.svg";
import homeActiveIcon from "../../../assets/lobby/homeIcon.png";
import homeInactiveIcon from "../../../assets/lobby/inactiveHomeIcon.png";
import rakebackIcon from "../../../assets/lobby/footerRakebackIcon.svg";
import defaultContactUs from "../../../assets/lobby/DefaultContactUs.svg";
import activeContactUs from "../../../assets/lobby/ActiveContactUs.svg";
import defaultLearn from "../../../assets/lobby/DefaultLearn.svg";
import activeLearn from "../../../assets/lobby/ActiveLearn.svg";

import {
  FooterWrapper,
  FooterItemWrapper,
  FooterItemIconWrapper,
  FooterItemTextWrapper,
  UserProfileWrapper,
  UserAvatarIcon,
  HamburgerIconWrapper,
} from "./styledComponents";
import { FooterTabConstants } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import { messageToNativeClient } from "../../../Common/utils/platformCommunicationUtil";
import CleverTapUtil from "../../../Common/utils/CleverTapUtil";
import {
  IP_Client_LBicon_Click,
  IP_TourneyTab_Click,
  PR_learnpoker,
  PR_ContactUs_view,
  PR_Hamburger_view,
} from "../../data/ClevertapConstants";
import { FUN_CHANNELS, MIXPANEL_EVENT } from "../../data/Constants";
import { getISTDateTimeFormat } from "../../../Common/utils/GameUtils";
import { logCleverTapEvent } from "../../../Common/utils/CleverTapUtilNew";
import { getEventParams } from "../../utils/MixPanelUtils";
import {
  MP_POKER_GAMES_LOBBY_ICON_CLICKED,
  MP_POKER_PROFILE_ICON_CLICKED,
  MP_POKER_TOURNEY_LOBBY_ICON_CLICKED,
} from "../../data/MixPanelConstant";

function Footer(props) {
  const playerData = useSelector((state) => state.lobby.player);
  const channel = useSelector((state) => state.lobby.channel);
  const userBalance = useSelector((state) => state.lobby.balance);
  const hideRakeBack = useSelector((state) => state.lobby.hideRakeBack);

  const footerItemsList = [
    !FUN_CHANNELS.includes(channel)
      ? {
          activeIcon: homeActiveIcon,
          defaultIcon: homeInactiveIcon,
          label: "Home",
          value: FooterTabConstants.home,
          hasCustomIcon: false,
        }
      : null,
    {
      activeIcon: goldCardsIcon,
      defaultIcon: defaultCardsIcon,
      label: "Games",
      value: FooterTabConstants.games,
      hasCustomIcon: false,
    },
    !FUN_CHANNELS.includes(channel)
      ? {
          activeIcon: activeTourneysIcon,
          defaultIcon: defaultTourneysIcon,
          label: "Tourneys",
          value: FooterTabConstants.tourneys,
          hasCustomIcon: false,
        }
      : null,
    !FUN_CHANNELS.includes(channel)
      ? {
          activeIcon: activePromotionIcon,
          defaultIcon: defaultPromotionIcon,
          label: "Promotions",
          value: FooterTabConstants.promotions,
          hasCustomIcon: false,
        }
      : null,
    // Removed Leaderboard option from footer as per product requirement (UAT changes)
    // !FUN_CHANNELS.includes(channel) && process.env.REACT_APP_ENVIRONMENT !== 'production'
    //   ? {
    //       activeIcon: activeLeaderboardIcon,
    //       defaultIcon: defaultLeaderboardIcon,
    //       label: "Leaderboard",
    //       value: FooterTabConstants.leaderboard,
    //       hasCustomIcon: false,
    //     }
    //   : null,
    // FUN_CHANNELS.includes(channel)
    //   ? {
    //       activeIcon: defaultRulesIcon,
    //       defaultIcon: defaultRulesIcon,
    //       label: "Rules",
    //       value: FooterTabConstants.rules,
    //       hasCustomIcon: false,
    //     }
    //   : null,

    FUN_CHANNELS.includes(channel)
      ? {
          activeIcon: activeLearn,
          defaultIcon: defaultLearn,
          label: "Learn Poker",
          value: FooterTabConstants.learnPoker,
          hasCustomIcon: false,
        }
      : null,
    FUN_CHANNELS.includes(channel)
      ? {
          activeIcon: activeContactUs,
          defaultIcon: defaultContactUs,
          label: "Contact Us",
          value: FooterTabConstants.contactUs,
          hasCustomIcon: false,
        }
      : null,
    !FUN_CHANNELS.includes(channel) &&
    // process.env.REACT_APP_ENVIRONMENT !== "production"
    !hideRakeBack
      ? {
          activeIcon: rakebackIcon,
          defaultIcon: rakebackIcon,
          label: "Rakeback",
          value: FooterTabConstants.rakeback,
          hasCustomIcon: false,
        }
      : null,
    {
      activeIcon: () => (
        <UserProfileWrapper
          isActive={props.selectedTab === FooterTabConstants.hamburgerMenu}
        >
          <UserAvatarIcon
            src={
              playerData?.avatar &&
              playerData?.avatar !==
                "https://images.fanfight.com/avatars/avatar1.png"
                ? playerData?.avatar
                : "https://images.fanfight.com/avatars/avatar1.png"
              // playerData?.avatar ?? playerAvatar
              // playerAvatar
            }
          />
          <HamburgerIconWrapper src={goldHamburgerIcon} />
        </UserProfileWrapper>
      ),
      defaultIcon: () => (
        <UserProfileWrapper
          isActive={props.selectedTab === FooterTabConstants.hamburgerMenu}
        >
          <UserAvatarIcon
            src={
              playerData?.avatar &&
              playerData?.avatar !==
                "https://images.fanfight.com/avatars/avatar1.png"
                ? playerData?.avatar
                : playerData?.avatar ??
                  "https://images.fanfight.com/avatars/avatar1.png"
              // playerAvatar
            }
          />
          <HamburgerIconWrapper src={defaultHamburger} />
        </UserProfileWrapper>
      ),
      label: playerData.userName,
      value: FooterTabConstants.hamburgerMenu,
      hasCustomIcon: true,
    },
  ];

  const handleTabChange = (value) => {
    // if (value === "games") {
    // if (showGames) {
    // } else {
    //   props.onTabChange && props.onTabChange(value);
    //   dispatch(showLobbyMultiGameTables());
    // }
    // props.onTabChange && props.onTabChange(value);
    // } else {
    if (value === FooterTabConstants.games) {
      let MixPanel_params = getEventParams(
        MP_POKER_GAMES_LOBBY_ICON_CLICKED,
        playerData,
        {},
        channel,
        {}
      );

      messageToNativeClient({
        type: MIXPANEL_EVENT,
        eventName: MP_POKER_GAMES_LOBBY_ICON_CLICKED,
        eventProperties: MixPanel_params,
      });
    }
    let timeStamp = getISTDateTimeFormat();
    if (value === FooterTabConstants.tourneys) {
      messageToNativeClient({
        type: "plotlineEvents",
        eventName: "PR_Client_TourneyIcon_click",
        eventProperties: {
          userName: playerData.userName,
          channel,
          currentCashBalance: userBalance.totalBalance,
          timeStamp,
        },
      });
      logCleverTapEvent(IP_TourneyTab_Click, {
        Username: playerData.userName,
        Channel: channel,
        "Wallet balance": userBalance?.totalBalance,
        Timestamp: timeStamp,
        "tab Type": "Tournaments",
      });
      let MixPanel_params = getEventParams(
        MP_POKER_TOURNEY_LOBBY_ICON_CLICKED,
        playerData,
        {},
        channel,
        {}
      );
      messageToNativeClient({
        type: MIXPANEL_EVENT,
        eventName: MP_POKER_TOURNEY_LOBBY_ICON_CLICKED,
        eventProperties: MixPanel_params,
      });
    } else if (value === FooterTabConstants.leaderboard) {
      messageToNativeClient({
        type: "plotlineEvents",
        eventName: "PR_Client_LeaderboardIcon_click",
        eventProperties: {
          userName: playerData.userName,
          channel,
          currentCashBalance: userBalance.totalBalance,
          timeStamp,
        },
      });
      logCleverTapEvent(IP_Client_LBicon_Click, {
        Username: playerData.userName,
        Channel: channel,
        "Wallet balance": userBalance?.totalBalance,
        Timestamp: timeStamp,
      });
    } else if (value === FooterTabConstants.rakeback) {
      messageToNativeClient({
        type: "plotlineEvents",
        eventName: "PR_Client_RBIcon_click",
        eventProperties: {
          userName: playerData.userName,
          channel,
          currentCashBalance: userBalance.totalBalance,
          timeStamp,
        },
      });
    } else if (value === FooterTabConstants.learnPoker) {
      logCleverTapEvent(PR_learnpoker, {
        Username: playerData.userName,
        Channel: channel,
        Timestamp: timeStamp,
        "Page Name": "Footer",
      });
    } else if (value === FooterTabConstants.contactUs) {
      logCleverTapEvent(PR_ContactUs_view, {
        Username: playerData.userName,
        Channel: channel,
        Timestamp: timeStamp,
        "Page Name": "Footer",
      });
    } else if (value === FooterTabConstants.hamburgerMenu) {
      logCleverTapEvent(PR_Hamburger_view, {
        Username: playerData.userName,
        Channel: channel,
        Timestamp: timeStamp,
        "Page Name": "Footer",
      });

      let MixPanel_params = getEventParams(
        MP_POKER_PROFILE_ICON_CLICKED,
        playerData,
        {},
        channel,
        {}
      );
      messageToNativeClient({
        type: MIXPANEL_EVENT,
        eventName: MP_POKER_PROFILE_ICON_CLICKED,
        eventProperties: MixPanel_params,
      });
    }
    props.onTabChange && props.onTabChange(value);
    // }
  };

  const renderFooterItems = () => {
    const footerItems = footerItemsList.map((footerItem) => {
      if (!footerItem) return;
      const isActive = footerItem.value === props.selectedTab;
      if (footerItem?.label === "Rakeback" && hideRakeBack) return;

      return (
        <FooterItemWrapper
          //id for plotline
          id={"footer-" + footerItem.value}
          key={footerItem.value}
          onTouchStart={() => {
            handleTabChange(footerItem.value);
            console.log("CHANGING TAB");
          }}
        >
          {/* {footerItem.value === "games" && MultiGameTables.length > 0 ? (
            <NumberOfGames>{MultiGameTables.length}</NumberOfGames>
          ) : null} */}
          {footerItem.hasCustomIcon ? (
            isActive ? (
              footerItem.activeIcon()
            ) : (
              footerItem.defaultIcon()
            )
          ) : (
            <>
              <FooterItemIconWrapper
                src={isActive ? footerItem.activeIcon : footerItem.defaultIcon}
              />
              {/* { footerItem.value === FooterTabConstants.leaderboard && isLeaderboardWinner && <span id="winner-symbol" style={{position:'absolute' ,fontSize:'8px',fontWeight:'bold', backgroundColor:'#EABF69',padding:'2px 8px', borderRadius:'10px', top:'10%'}}>Winner</span> } */}
              {/* {footerItem.value === FooterTabConstants.leaderboard && isNewLeaderboard && !isLeaderboardWinner && 
              <NewSymbol id="new-symbol"> New </NewSymbol>} */}
            </>
          )}

          <FooterItemTextWrapper isActive={isActive}>
            {footerItem.label}
          </FooterItemTextWrapper>
        </FooterItemWrapper>
      );
    });
    return footerItems;
  };

  return (
    <FooterWrapper id='footer-wrapper'>{renderFooterItems()}</FooterWrapper>
  );
}

export default Footer;
