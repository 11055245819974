import styled from "styled-components";
import colors from "../../styleGuide/Colors";

export const DropdownWrapper = styled.div`
  width: 30%;
  position: relative;
`;

export const DropdownButton = styled.div`
  color: ${colors.primaryGold};
  padding: 5px 0;
  text-align: center;
  background-color: #032028;
  border: 1.5px solid #0c3743;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: ${(props) => (props.hideBottomBorder ? 0 : 4)}px;
  border-bottom-right-radius: ${(props) => (props.hideBottomBorder ? 0 : 4)}px;
`;

export const DropArrow = styled.img`
  position: absolute;
  top: 15px;
  right: 8px;
  width: 10px;
`;

export const DropdownItemsWrapper = styled.ul`
  position: absolute;
  width: 100%;
  z-index: 6;
  animation: fade-in 0.15s forwards;
`;

export const DropdownItems = styled.li`
  list-style: none;
  background-color: #032028;
  text-align: center;
  padding: 6px 4px;
  border-left: 1px;
  border-bottom: 1px;
  border-right: 1px;
  border-style: solid;
  border-color: #0c3743;
  border-top: 0;
  color: ${colors.pebbleGray};
  :last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
