import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closePrivateTableSliders,
  updateSliderAnimatingFlag,
} from "../../../redux/slices/sliderSlice";
import closeIcon from "../../../../assets/lobby/CloseIcon.svg";

import {
  Column,
  Container,
  GameTileContainer,
  Header,
  InfoContainer,
  Row,
  TabbarWrapper,
} from "./gameDefInfoSliderStyle";
import { Icon } from "../privateTableSliderStyle";
import {
  handlePrivateTableCreateGameDef,
  resetInitiateMatchmakingForGdid,
  resetPrivateTableGameDefInfo,
} from "../../../redux/slices/lobbySlice";
import { GameTile } from "../../../routes/PokerLobby/PrivateTable/GameTile/GameTile";
import {
  GAME_STATUS_ACTIVE,
  HOSTED_PRIVATE_TABLE,
  PRIVATE_TABLE_GAME_DEF_INFO,
  PRIVATE_TABLE_GAME_DEF_INFO_SLIDER,
  PRIVATE_TABLE_GAME_DEF_PLAYER,
} from "../../../data/Constants";
import Tabbar from "../../../../Common/components/Tabbar/Tabbar";
import { AccessCodeLayout } from "../../../../Common/components/PrivateTableAccessCodeLayout/AccessCodeLayout";
import {
  Typo12GrayContoMediumText,
  Typo14LightYellowContoMediumText,
} from "../../../../Common/styleGuide/Typos";
import { convertEpochToHoursMinutesSeconds, getGameLabel } from "../../../../Common/utils/GameUtils";
import { Table } from "../../../../Common/components/Table/Table";
import { Button } from "../createHostingDetailsStyle";
import {
  createTableData,
  showGameTable,
} from "../../../redux/slices/gameTableSlice";
import { generateUniqueId } from "../../../../Common/utils/PokerUtils";

export const GameDefInfoSlider = (props) => {
  const { sliderDirection, config } = props;
  const [selectedTab, setSelectedTab] = useState(PRIVATE_TABLE_GAME_DEF_INFO);
  const [disableGoToTableButton, setDisableGoToTableButton] = useState(false);
  const initiateMatchmakingForGdid = useSelector(
    (state) => state.lobby.privateTable?.initiateMatchmakingForGdid
  );
  const navigateToGameTable = useSelector(
    (state) => state.lobby.privateTable.navigateToGameTable
  );
  const playerName = useSelector((state) => state.lobby.player.userName);
  const dispatch = useDispatch();

  useEffect(() => {
    if (initiateMatchmakingForGdid) {
      dispatch(
        createTableData({
          tempTableId: generateUniqueId(),
          gameDefinition: {
            gameDefId: initiateMatchmakingForGdid,
            ...config,
          },
          watchPlayer: true,
          isPrivateTable: true,
        })
      );

      dispatch(resetInitiateMatchmakingForGdid());
      handleSliderClose();
    }
  }, [initiateMatchmakingForGdid]);

  useEffect(() => {
    if (navigateToGameTable) {
      dispatch(showGameTable());
      dispatch(resetInitiateMatchmakingForGdid());
      handleSliderClose();
    }
  }, [navigateToGameTable]);

  const handleSliderClose = () => {
    dispatch(closePrivateTableSliders());
    dispatch(resetPrivateTableGameDefInfo({}));
  };

  const getTabItems = () => {
    const tabItems = [
      {
        label: "Info",
        value: PRIVATE_TABLE_GAME_DEF_INFO,
      },
      {
        label: "Players",
        value: PRIVATE_TABLE_GAME_DEF_PLAYER,
      },
    ];
    return tabItems;
  };

  const handleTabChange = (tab) => {
    if (selectedTab !== tab) {
      setSelectedTab(tab);
    }
  };

  const getSelectedTabDetails = () => {
    switch (selectedTab) {
      case PRIVATE_TABLE_GAME_DEF_INFO:
        return createGameDefInfoSection();
      case PRIVATE_TABLE_GAME_DEF_PLAYER:
        return createGameDefPlayerSection();
    }
  };

  const createGameDefInfoSection = () => {
    return (
      <InfoContainer>
        {config.status === GAME_STATUS_ACTIVE && (
          <AccessCodeLayout accessCode={config.gameDefId}></AccessCodeLayout>
        )}
        <Row>
          <Column>
            <Row label>
              <Typo12GrayContoMediumText>Type</Typo12GrayContoMediumText>
            </Row>
            <Row>
              <Typo14LightYellowContoMediumText>
                {getGameLabel(config.gameType)}
              </Typo14LightYellowContoMediumText>
            </Row>
          </Column>
          <Column>
            <Row label>
              <Typo12GrayContoMediumText>Limit</Typo12GrayContoMediumText>
            </Row>
            <Row>
              <Typo14LightYellowContoMediumText>
                {config.limitType === "NL"
                  ? "No Limit"
                  : config.limitType === "FL"
                  ? "Fixed Limit"
                  : config.limitType === "PL"
                  ? "Pot Limit"
                  : null}
              </Typo14LightYellowContoMediumText>
            </Row>
          </Column>
        </Row>

        <Row>
          <Column>
            <Row label>
              <Typo12GrayContoMediumText>Stakes</Typo12GrayContoMediumText>
            </Row>
            <Row>
              <Typo14LightYellowContoMediumText>
                {config.smallBlind} / {config.bigBlind}
              </Typo14LightYellowContoMediumText>
            </Row>
          </Column>
          <Column>
            <Row label>
              <Typo12GrayContoMediumText>Turn Time</Typo12GrayContoMediumText>
            </Row>
            <Row>
              <Typo14LightYellowContoMediumText>
                {config.tableTime} sec
              </Typo14LightYellowContoMediumText>
            </Row>
          </Column>
        </Row>

        <Row>
          <Column>
            <Row label>
              <Typo12GrayContoMediumText>Game duration</Typo12GrayContoMediumText>
            </Row>
            <Row>
              <Typo14LightYellowContoMediumText>
                {convertEpochToHoursMinutesSeconds(config.tableDuration)}
              </Typo14LightYellowContoMediumText>
            </Row>
          </Column>
          <Column>
            <Row label>
              <Typo12GrayContoMediumText>Total games played</Typo12GrayContoMediumText>
            </Row>
            <Row>
              <Typo14LightYellowContoMediumText>
                {config.totalGamesPlayed}
              </Typo14LightYellowContoMediumText>
            </Row>
          </Column>
        </Row>
      </InfoContainer>
    );
  };

  const createGameDefPlayerSection = () => {
    return (
      <InfoContainer>
        <Table
          tableData={config.privateTableInfos}
          noDataMessage={"No players joined"}
        />
      </InfoContainer>
    );
  };

  const handleGoToTableClick = () => {
    const { accessCode, gameDefId } = config;
    setDisableGoToTableButton(true);
    dispatch(
      handlePrivateTableCreateGameDef({
        accessCode: accessCode,
        gdid: gameDefId,
        gameDefinition: { ...config },
        mode: HOSTED_PRIVATE_TABLE,
      })
    );
  };

  return (
    <Container
      onAnimationEnd={() => {
        if (sliderDirection === "close") {
          handleSliderClose();
        }
        dispatch(updateSliderAnimatingFlag({ value: false }));
      }}
      sliderDirection={sliderDirection}
      showGoToTableButton={
        config.mode === HOSTED_PRIVATE_TABLE &&
        config.status === GAME_STATUS_ACTIVE
      }
      isCompletedTable={config.status !== GAME_STATUS_ACTIVE}
    >
      <>
        <Header>
          Game Info
          <Icon
            src={closeIcon}
            right={15}
            width={14}
            onClick={() => {
              handleSliderClose();
            }}
          ></Icon>
        </Header>
        <GameTileContainer>
          <GameTile
            gameDefinition={{
              type: PRIVATE_TABLE_GAME_DEF_INFO_SLIDER,
              ...config,
            }}
          />
        </GameTileContainer>

        <TabbarWrapper>
          <Tabbar
            key={selectedTab}
            isInfiniteTabbar={true}
            tabItems={getTabItems()}
            selectedTab={selectedTab}
            onTabChange={handleTabChange}
          />
        </TabbarWrapper>
        {getSelectedTabDetails()}
        {config.hostName === playerName &&
          config.status === GAME_STATUS_ACTIVE && (
            <Row justifyCenter>
              <Button
                disabled={disableGoToTableButton}
                onClick={() => {
                  handleGoToTableClick();
                }}
              >
                Go to Table
              </Button>
            </Row>
          )}
      </>
    </Container>
  );
};
