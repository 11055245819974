import styled from "styled-components";
import Lottie from "lottie-react";

import colors from "../../../Common/styleGuide/Colors";
import {
  Typo10GrayContoRegularText,
  Typo12PureWhiteContoBoldText,
  Typo14PrimaryGoldContoBoldText,
  Typo14WhiteContoBoldText,
  Typo14WhiteContoRegularText,
  Typo16PrimaryGoldBoldText,
} from "../../../Common/styleGuide/Typos";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--app-height);
  justify-content: center;
  align-items: center;
  background-color: ${colors.black40};
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  max-width: 450px;
  min-height: 140px;
  height: auto;
  border-radius: 10px;
  background: ${colors.confirmPopupBackground};
  animation: springIn 0.4s forwards;
`;
export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 43px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${colors.deepTeal60};
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 16px 20px 16px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
`;

export const HeaderTitleText = styled(Typo14PrimaryGoldContoBoldText)`
  line-height: normal;
  margin: 0 auto;
  /* padding-left: 24px; */
`;

export const IconWrapper = styled.img`
  width: 100px;
  height: 100px;
  margin-top: 12px;
`;

export const LottieWrapper = styled(Lottie)`
  position: absolute;
  z-index: 2;
  margin-top: -20px;
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 12px;
`;

export const MessageText = styled(Typo12PureWhiteContoBoldText)`
  line-height: normal;
  /* margin-top: 24px; */
  text-align: center;
`;

export const MessageHighLightText = styled(Typo16PrimaryGoldBoldText)`
  margin-left: 4px;
  margin-right: 4px;
`;

export const NoteText = styled(Typo10GrayContoRegularText)`
  margin-top: 12px;
  margin-bottom: 24px;
  text-align: center;
`;

export const CTAContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`;

export const PrimaryCTAButton = styled.button`
  display: flex;
  width: 125px;
  height: 33px;
  padding: 4px 8px;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  outline: none;
  border: none;
  border-top: 1px solid ${colors.dragonGreen};
  box-shadow: 0px 0.5px 0.5px 0px rgba(0, 0, 0, 0.4);
  background: ${colors.confirmPrimaryCTAbackground};
  border-radius: 5px;
`;

export const ButtonText = styled(Typo14WhiteContoRegularText)`
  line-height: normal;
  font-weight: 700;
`;

export const CloseIcon = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 24px;
`;

export const SecondaryCTAButton = styled.button`
  display: flex;
  width: 125px;
  height: 33px;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  outline: none;
  border: none;
  border-top: 1px solid ${colors.wedgeWood};
  box-shadow: 0px 0.5px 0.5px 0px rgba(0, 0, 0, 0.4);
  background: ${colors.confirmSecondaryCTAbackground};
  border-radius: 5px;
  &:disabled {
    opacity: 0.6;
  }
`;
