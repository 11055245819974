import {
  AnimatePresence,
  motion,
  useDragControls,
  useAnimation,
} from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cardThemes } from "../../../assets/cards";
import colors from "../../../Common/styleGuide/Colors";
import {
  getNumberOfCardsBasedOnGameType,
  getWinningPlayersData,
} from "../../../Common/utils/GameUtils";
import {
  CARD_SIZE_LARGE,
  CARD_SIZE_MEDIUM,
  CARD_SIZE_SMALL,
  FLUSH,
  FOLD_CONFIRM,
  FOUR_OF_A_KIND,
  FULL_HOUSE,
  GAME_STAGE_SHOWDOWN,
  HIGH_CARD,
  OMAHA,
  OMAHA5,
  OMAHA6,
  ONE_PAIR,
  POT_WINNER_EXECUTION_TIME,
  ROYAL_FLUSH,
  STRAIGHT,
  STRAIGHT_FLUSH,
  TEXAS_HOLDEM,
  THREE_OF_A_KIND,
  TWO_PAIRS,
} from "../../data/Constants";
import {
  activateButtons,
  deactivateButtons,
} from "../../redux/slices/buttonSlice";
import {
  disableMultitableSwipe,
  enableMultitableSwipe,
  openLocalGameModal,
  updateReleaseToFoldStatus,
} from "../../redux/slices/gameTableSlice";
import { sendFoldProtocol } from "../../utils/ProtocolUtils";

const MyCards = ({ tempTableId, position }) => {
  const dispatch = useDispatch();
  const [dragStart, setDragStart] = useState(0);
  const applicationVisibility = useSelector(
    (state) => state.lobby.applicationVisibility
  );
  const [verticalDragDistance, setVerticalDragDistance] = useState(0);
  const controls = useDragControls();
  const animationControls = useAnimation();
  const playerCards = useSelector(
    (state) => state.gameTable.games[tempTableId].playerCards
  );
  const gameId = useSelector(
    (state) => state.gameTable.games[tempTableId].gameId
  );
  const cardDeckTheme = useSelector(
    (state) => state.gameTable.themeData.cardDeck
  );
  const shouldPlayCardAnimation = useSelector(
    (state) => state.gameTable.games[tempTableId]?.shouldPlayCardAnimation
  );
  const cardSize = useSelector((state) => state.gameTable?.themeData?.cardSize);
  const gameType = useSelector(
    (state) => state.gameTable.games[tempTableId].gameDefinition.gameType
  );
  const possibleWinningHandType = useSelector(
    (state) =>
      state.gameTable.games[tempTableId].playerData.find(
        (player) => player.position === position
      )?.possibleWinningHandType
  );
  const [handStrengthPercentage, setHandStrengthPercentage] = useState(0);
  const canUserSwipe = useSelector((state) => state.gameTable.canUserSwipe);
  const { showHandStrength, BBDisplay } = useSelector(
    (state) => state.gameTable.tableSettings
  );
  const isWinnerDeclared = useSelector(
    (state) => state.gameTable.games[tempTableId].isWinnerDeclared
  );
  const gameStage = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameStage
  );
  const playerTurn = useSelector(
    (state) => state.gameTable.games[tempTableId]?.playerTurn
  );
  const playerData = useSelector(
    (state) => state.gameTable.games[tempTableId]?.playerData
  );
  const playerDetails = useSelector((state) =>
    state.gameTable.games[tempTableId]?.playerData.find(
      (player) => player.position === position
    )
  );
  const winningPotData = useSelector(
    (state) => state.gameTable.games[tempTableId]?.winningPotDisplay
  );
  const activeGame = useSelector((state) => state.gameTable.games[tempTableId]);
  const { smartGestureOptions, smartGestures } = useSelector(
    (state) => state.gameTable.tableSettings
  );
  const minimumFoldDistance = 65;
  const [winningHand, setWinningHand] = useState([]);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (isWinnerDeclared && winningPotData) {
      getWinningHandCards();
    } else {
      setWinningHand([]);
      clearTimeout(intervalRef.current);
    }

    return () => {
      clearTimeout(intervalRef.current);
    };
  }, [isWinnerDeclared, winningPotData]);

  // const returnHandStrengthPercentage = () => {
  //   switch (possibleWinningHandType) {
  //     case HIGH_CARD:
  //       return 10;
  //     case ONE_PAIR:
  //       return 20
  //     case TWO_PAIRS:
  //       return 30
  //     case THREE_OF_A_KIND:
  //       return 40
  //     case STRAIGHT:
  //       return 50
  //     case FLUSH:
  //       return 60
  //     case FULL_HOUSE:
  //       return 70
  //     case FOUR_OF_A_KIND:
  //       return 80
  //     case STRAIGHT_FLUSH:
  //       return 90
  //     case ROYAL_FLUSH:
  //       return 100
  //   }
  // };

  const getWinningHandCards = () => {
    if (winningPotData) {
      if (
        winningPotData?.winnerNames.includes(playerDetails?.userName) &&
        playerDetails?.winningHand
      ) {
        setWinningHand(playerDetails?.winningHand);
      } else {
        setWinningHand([]);
      }
    }
  };

  const handleSmartFold = () => {
    if (
      activeGame?.showCheck &&
      activeGame?.showFold &&
      activeGame?.showDefaultActions
    ) {
      dispatch(
        openLocalGameModal({
          type: FOLD_CONFIRM,
          tempTableId: tempTableId,
        })
      );
      bringPlayerCardsBack();
    } else {
      if (activeGame?.showFold && activeGame?.showDefaultActions) {
        animationControls.start(returnSmartFoldTypeAnimation()).then(() => {
          animationControls.start(cardMovingBackToBottom).then(() => {
            animationControls.start(cardAppearingAfterFoldVariant);
          });
        });
        handleFold();
        return;
      }
      bringPlayerCardsBack();
      return;
    }
  };

  const returnSmartFoldTypeAnimation = () => {
    if (gameType === TEXAS_HOLDEM) {
      return {
        rotateZ: [0, 360, 720, 1080],
        opacity: [1, 0.5, 0],
        transition: {
          times: [0, 0.5, 1],
        },
      };
    } else {
      return {
        scale: 0,
        opacity: 0,
        transition: {
          type: "spring",
          stiffness: 500,
          damping: 30,
        },
      };
    }
  };

  const handleFold = () => {
    let payload = {
      playerName: playerDetails?.userName,
      tempTableId: tempTableId,
    };
    // dispatch(toggleFoldAction(payload));
    //hitting protocol directly @yashwanth
    sendFoldProtocol(payload);
    return;
  };

  useEffect(() => {
    if (applicationVisibility) {
      dispatch(
        updateReleaseToFoldStatus({
          tempTableId: tempTableId,
          status: false,
        })
      );
    }
  }, [applicationVisibility]);

  useEffect(() => {
    if (!canUserSwipe) {
      enableUserSwipeAndButtons();
    }
  }, [gameId]);

  const returnCardWidth = () => {
    try {
      if (gameType === TEXAS_HOLDEM) {
        switch (cardSize) {
          case CARD_SIZE_SMALL:
            return 35;
          case CARD_SIZE_MEDIUM:
            return 40;
          case CARD_SIZE_LARGE:
            return 45;
        }
      }
      if (gameType === OMAHA || gameType === OMAHA5) {
        return 36;
      }

      return 32;
    } catch (err) {
      return 40;
    }
  };

  const startDrag = (e) => {
    if (
      gameStage !== GAME_STAGE_SHOWDOWN &&
      smartGestureOptions?.smartFold &&
      smartGestures
    ) {
      console.log("Starting the drag! ", e);
      setDragStart(e?.screenY);
      controls.start(e);
      dispatch(deactivateButtons());
      dispatch(disableMultitableSwipe());
    }
  };

  useEffect(() => {
    if (
      smartGestureOptions?.smartFold &&
      smartGestures &&
      playerDetails?.position === playerTurn
    ) {
      if (Math.abs(verticalDragDistance - dragStart) > minimumFoldDistance) {
        dispatch(
          updateReleaseToFoldStatus({ tempTableId: tempTableId, status: true })
        );
      } else {
        dispatch(
          updateReleaseToFoldStatus({ tempTableId: tempTableId, status: false })
        );
      }
    } else {
      dispatch(
        updateReleaseToFoldStatus({ tempTableId: tempTableId, status: false })
      );
    }
  }, [verticalDragDistance]);

  const handleDragEnd = (_, info) => {
    if (
      Math.abs(verticalDragDistance - dragStart) > minimumFoldDistance &&
      smartGestureOptions?.smartFold &&
      smartGestures &&
      playerDetails?.position === playerTurn
    ) {
      handleSmartFold();
    } else {
      bringPlayerCardsBack();
    }
    setDragStart(0);
    setVerticalDragDistance(0);
    enableUserSwipeAndButtons();
  };

  const bringPlayerCardsBack = () => {
    animationControls.start({
      x: 0,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 500,
        damping: 30,
      },
    });
    setVerticalDragDistance(0);
    setDragStart(0);
  };

  const returnHorizontalTranslationValueDependingOnGameTypeAndIndex = (
    index
  ) => {
    if (gameType === TEXAS_HOLDEM) {
      return -50;
    }

    if (gameType === OMAHA) {
      // switch (index) {
      //   case 0:
      //     return -45;
      //   case 1:
      //     return -45;
      //   case 2:
      //     return -45;
      //   case 3:
      //     return -45;
      // }
      return -45;
    }

    if (gameType === OMAHA5) {
      return -47.5;
    }

    if (gameType === OMAHA6) {
      return -57.5;
    }
  };

  const returnRotateValuesDependingOnGameTypeAndIndex = (index) => {
    if (gameType === TEXAS_HOLDEM) {
      if (index === 0) {
        return -10;
      } else {
        return 10;
      }
    }

    if (gameType === OMAHA) {
      switch (index) {
        case 0:
          return -8;
        case 1:
          return -2;
        case 2:
          return 2;
        case 3:
          return 8;
      }
    }

    if (gameType === OMAHA5) {
      switch (index) {
        case 0:
          return -8;
        case 1:
          return -4;
        case 2:
          return 0;
        case 3:
          return 4;
        case 4:
          return 8;
      }
    }

    if (gameType === OMAHA6) {
      switch (index) {
        case 0:
          return -10;
        case 1:
          return -6;
        case 2:
          return -2;
        case 3:
          return 2;
        case 4:
          return 6;
        case 5:
          return 10;
      }
    }
  };

  const returnVerticalTranslateValueDependingOnGameTypeAndIndex = (index) => {
    if (gameType === TEXAS_HOLDEM) {
      return null;
    }

    if (gameType === OMAHA) {
      switch (index) {
        case 0:
          return 0;
        case 1:
          return -2;
        case 2:
          return -2;
        case 3:
          return 1;
      }
    }

    if (gameType === OMAHA5) {
      switch (index) {
        case 0:
          return 1;
        case 1:
          return -2;
        case 2:
          return -3;
        case 3:
          return -2;
        case 4:
          return 1;
      }
    }

    if (gameType === OMAHA6) {
      switch (index) {
        case 0:
          return -2;
        case 1:
          return -3.5;
        case 2:
          return -4;
        case 3:
          return -4;
        case 4:
          return -3.5;
        case 5:
          return -1.5;
      }
    }
  };

  const returnHandStrengthWidth = () => {
    let numberOfCards = getNumberOfCardsBasedOnGameType(gameType);
    let cardWidth = returnCardWidth();
    const reductionRatios = {
      TEXAS_HOLDEM: 0.5,
      OMAHA: 0.75,
      OMAHA5: 0.75,
      OMAHA6: 0.75,
    };
    let reductionRatio = reductionRatios[gameType] ?? 0.85;
    return reductionRatio * (numberOfCards * cardWidth) + "px";
  };

  const enableUserSwipeAndButtons = () => {
    dispatch(enableMultitableSwipe());
    dispatch(activateButtons());
  };

  const cardDealingVariant = {
    left: gameType === TEXAS_HOLDEM ? "140%" : "120%",
    top: 0,
    scale: 1,
    opacity: 1,
    rotate: gameType === TEXAS_HOLDEM ? [0, 360, 720] : null, // Rotates 3 times
  };

  const cardMovingBackToBottom = {
    x: 0,
    y: 0,
    top: 0,
    left: "120%",
  };

  const cardAppearingAfterFoldVariant = {
    scale: 1,
    opacity: 1,
  };

  useEffect(() => {
    console.log("Smart Gestures Value is ", smartGestures);
  }, [smartGestures]);

  return (
    <AnimatePresence
      key={playerCards.map(
        (item, index) => item + "-card-" + index + "tempTableId-" + tempTableId
      )}
    >
      {shouldPlayCardAnimation && (
        <motion.div
          key={"cardsContainerKey-" + tempTableId}
          initial={
            shouldPlayCardAnimation
              ? {
                  position: "absolute",
                  top: -200,
                  left: "50%",
                  scale: 0,
                  opacity: 1,
                  transformOrigin: "center",
                }
              : false
          }
          animate={cardDealingVariant}
          onPointerDown={(e) =>
            smartGestureOptions?.smartFold &&
            smartGestures &&
            activeGame?.showFold &&
            startDrag(e)
          }
          onPointerUp={(e) => enableUserSwipeAndButtons()}
          exit={{
            position: "absolute",
            top: -350,
            left: "0%",
            scale: 0,
            opacity: 0,
            transition: {
              delay: 2.5,
              times: 1,
            },
            rotate: gameType === TEXAS_HOLDEM ? [0, 360, 720, 1080] : null,
            transformOrigin: "center",
          }}
          transition={{
            // duration: 0.5,
            times: gameType === TEXAS_HOLDEM ? [0, 0.25, 0.5, 0.75] : null, // Each rotation takes 0.25s
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <motion.div
            key={"cardsInnerContainerKey-" + tempTableId}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              touchAction: "none",
            }}
            dragElastic={0}
            drag={
              activeGame?.showDefaultActions &&
              activeGame?.showFold &&
              smartGestureOptions?.smartFold &&
              smartGestures
            }
            animate={animationControls}
            onDrag={(e) => setVerticalDragDistance(e.screenY)}
            onDragEnd={handleDragEnd}
          >
            {playerCards.map((card, index) => {
              return (
                <motion.div
                  key={
                    "myPlayerCard-" +
                    index +
                    "-" +
                    card +
                    "-tempTableId-" +
                    tempTableId
                  }
                  initial={{
                    translateX: -100 * index + "%",
                    rotateZ: 0,
                  }}
                  animate={{
                    translateX:
                      returnHorizontalTranslationValueDependingOnGameTypeAndIndex(
                        index
                      ) *
                        index +
                      "%",
                    translateY:
                      returnVerticalTranslateValueDependingOnGameTypeAndIndex(
                        index
                      ),
                    // transition: {
                    //   type: "spring",
                    //   delay: gameType === TEXAS_HOLDEM ? 0.25 * index : null,
                    // },
                    rotateZ:
                      returnRotateValuesDependingOnGameTypeAndIndex(index),
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0px",
                    width: returnCardWidth() + "px",
                    // zIndex:
                    //   gameType === TEXAS_HOLDEM
                    //     ? 2 - index
                    //     : gameType === OMAHA
                    //     ? 4 - index
                    //     : 5 - index,
                  }}
                >
                  <motion.img
                    // animate={shouldCardTranslate(card)}
                    animate={{
                      y: winningHand.includes(card) ? -15 : null,
                      filter:
                        (!winningHand.includes(card) && isWinnerDeclared) ||
                        playerDetails?.isFolded
                          ? // ? playerDetails?.isWinner
                            // ? null
                            "brightness(0.6)"
                          : null,
                    }}
                    style={{
                      width: "100%",
                      boxShadow:
                        index > 0 ? "-1px 0px 8px 0px rgba(0,0,0,0.8)" : "",
                      borderRadius: "5%",
                      border: "1px solid rgba(0,0,0,0.5)",
                    }}
                    src={cardThemes[cardDeckTheme][card]}
                  ></motion.img>
                  {playerDetails?.possibleWinningHandCards?.includes(card) &&
                  !isWinnerDeclared &&
                  !playerDetails?.isFolded &&
                  gameStage !== GAME_STAGE_SHOWDOWN &&
                  showHandStrength ? (
                    <motion.div
                      // animate={shouldCardTranslate(card)}
                      animate={{
                        y: winningHand.includes(card) ? -15 : null,
                      }}
                      style={{
                        width: "100%",
                        backgroundColor: colors.winnerCardHighLight,
                        border: "1px solid green",
                        height: "4px",
                        borderRadius: "0px 0px 3px 3px",
                      }}
                    ></motion.div>
                  ) : null}
                </motion.div>
              );
            })}
          </motion.div>
          {possibleWinningHandType &&
          showHandStrength &&
          !isWinnerDeclared &&
          gameStage !== GAME_STAGE_SHOWDOWN &&
          !playerDetails?.isFolded ? (
            <motion.div
              key={"handstrength-" + tempTableId}
              initial={{ opacity: 0, scale: 0 }}
              animate={{
                // translateX: gameType === TEXAS_HOLDEM ? "50%" : "25%",
                opacity: 1,
                scale: 1,
                translateX: "-100%",
                // 70% because 50% + 20% since the container is at 120% to the left of player component.
                x: "70%",
                translateY: "25%",
              }}
              style={{
                textAlign: "center",
                width: returnHandStrengthWidth(),
                fontSize: gameType === TEXAS_HOLDEM ? "8px" : "10px",
                // letterSpacing: '-0.25px',
                whiteSpace: "nowrap",
                color: colors.primaryGold,
                fontWeight: "bold",
                // background: "red",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ marginBottom: "2px" }}>
                {possibleWinningHandType}
              </div>
              {/* <div
                id="hand-strength-outer-progress-container"
                style={{
                  width: "60px",
                  background: `linear-gradient(to right, ${colors.primaryRed}, ${colors.primaryGold}, ${colors.ripplePrimaryGreen})`,
                  height: "5px",
                  borderRadius: "5px",
                  border: `1px solid ${colors.primaryBlue}`,
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <div
                  id="hand-strength-inner-progress-hider"
                  style={{
                    backgroundColor: "black",
                    position: "absolute",
                    right: 0,
                    width: 100 - returnHandStrengthPercentage() + "%",
                    height: "100%",
                    transition: "0.5s ease",
                  }}
                ></div>
              </div> */}
            </motion.div>
          ) : null}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MyCards;
