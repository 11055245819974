import styled from "styled-components";
import {
  PRIVATE_TABLE_ENTER_CODE_SLIDER,
  PRIVATE_TABLE_HOSTING_DETAILS_SLIDER,
} from "../../../../Poker/data/Constants";
import colors from "../../../styleGuide/Colors";

export const TextInputContainer = styled.input`
  background-color: ${colors.inputBG};
  color: ${(props) =>
    props.location
      ? props.location === PRIVATE_TABLE_HOSTING_DETAILS_SLIDER ||
        PRIVATE_TABLE_ENTER_CODE_SLIDER
        ? colors.primaryGold
        : colors.white
      : colors.white};

  display: block;
  width: 100%;
  outline: none;
  border: none;
  height: 40px;
  padding: 4%;
  border-bottom: ${(props) =>
    props.location === PRIVATE_TABLE_HOSTING_DETAILS_SLIDER
      ? `1px solid ${colors.primaryGold}`
      : "none"};

  margin-top: 4%;
`;
