import Handler from "../Handler";

class PrivateTableGameDefInfoHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processPrivateTableGameDefInfo(receivedData);
  }
}
export default PrivateTableGameDefInfoHandler;
/*privateTableInfoA#
{
    "tableId": "PTR190225-164590437ayk",
    "gameType": "TEXAS_HOLDEM",
    "noOfTables": 1,
    "hostName": "topgun366464",
    "totalGamesPlayed": 0,
    "createdAt": 0,
    "privateTableInfos": [
        {
            "playerName": "topgun366464",
            "buyInAmount": 0,
            "profitLoss": 0
        }
    ]
}
*/
