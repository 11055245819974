export const FooterTabConstants = {
  home: "home",
  games: "games",
  tourneys: "tourneys",
  promotions: "promotions",
  leaderboard: "leaderboard",
  rules: "rules",
  more: "more",
  rakeback: "rakeback",
  hamburgerMenu: "hamburgerMenu",
  contactUs: "contactUs",
  learnPoker: "learnPoker",
};
