import React, { useEffect, useState } from "react";
import closeIcon from "../../../assets/common/close.svg";

import defaultRadio from "../../../assets/gameTable/DefaultRadio.svg";
import selectedRadio from "../../../assets/gameTable/SelectedRadio.svg";
import accordianSmallIcon from "../../../assets/gameTable/AccordianSmallIcon.svg";
import emptyCheckbox from "../../../assets/lobby/emptyCheckbox.svg";
import checkbox from "../../../assets/lobby/Checkbox.svg";

import {
  Container,
  ModalWrapper,
  ModalHeader,
  HeaderTitleText,
  ModalBody,
  CTAContainer,
  PrimaryCTAButton,
  ButtonText,
  CloseIcon,
  IssueTypesContainer,
  RadioContainer,
  RadioElement,
  IssueTypeText,
  TextArea,
  FraudReportContainer,
  SelectUsersContainer,
  SelectUsersWrapper,
  UsersListContainer,
  UserWrapper,
  CheckboxIcon,
  SelectPlaceholderText,
  AccordianIconWrapper,
  UserNameText,
} from "./reportIssueStyles";
import {
  closeLocalGameModal,
  reportHandHistoryIssue,
} from "../../redux/slices/gameTableSlice";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../Loader/Loader";
import LobbyConnection from "../../io/LobbyConnection";
import { getDisplayName } from "../../utils/ReduxUtils";

function ReportIssue(props) {
  const [selectedIssueType, setSelectedIssueType] = useState("Winnings");
  const [openUsersList, setOpenUsersList] = useState(false);
  const [commentsText, setCommentsText] = useState("");
  const dispatch = useDispatch();
  const handHistoryDetails = useSelector(
    (state) => state.gameTable.handHistory.details
  );
  const userId = useSelector((state) => state.lobby.player.userId);
  const channel = useSelector((state) => state.lobby.channel);
  const accessToken = useSelector((state) => state.lobby.accessToken);
  const a23Token = useSelector((state) => state.lobby.a23Token);
  const loggedPlayer = useSelector((state) => state.lobby.player)
  let playerDisplayNameMap = useSelector((state) =>
    state.gameTable.games[props.tempTableId]?.displayNameMap);
  const displayName = useSelector((state) => state.lobby?.displayName);
  const gameOrientation = useSelector(
    (state) => state.gameTable.tableOrientation
  );
  const [selectedFraudUsersList, setSelectedFraudUsersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleIssueTypeChange = (event, value) => {
    event.preventDefault();
    event.stopPropagation();
    if (!isLoading) {
      window.localStorage.setItem("report-issue-select", value);
      setSelectedIssueType(value);
    }
  };

  const handleUserListOpen = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!isLoading) {
      setOpenUsersList(!openUsersList);
    }
  };

  const handleModalClose = () => {
    dispatch(closeLocalGameModal({ tempTableId: props.tempTableId }));
  };

  const handleSubmit = () => {
    setIsLoading(true);
    let message = {
      gameId: handHistoryDetails.gameId,
      tempTableId: props.tempTableId,
      fraudPlayers: selectedFraudUsersList,
      issueType: selectedIssueType,
      comments: commentsText,
      channel: channel,
      accessToken: accessToken,
      a23Token: a23Token,
    };
    dispatch(reportHandHistoryIssue(message));
    LobbyConnection.getInstance().writeMessage(
      "RAP#" + JSON.stringify(message)
    );
    window.localStorage.removeItem("report-issue-select");
    window.localStorage.removeItem("report-issue-text");
  };

  useEffect(() => {
    let textData = window.localStorage.getItem("report-issue-text");
    let selectData = window.localStorage.getItem("report-issue-select");
    if (textData) {
      setCommentsText(textData);
    }
    if (selectData) {
      setSelectedIssueType(selectData);
    }
  }, []);

  const getRadioIcon = (selectedValue) => {
    return selectedValue === selectedIssueType ? selectedRadio : defaultRadio;
  };

  const handleCommentsTextChange = (event) => {
    setCommentsText(event.target.value);
    window.localStorage.setItem("report-issue-text", event.target.value);
  };

  const getSubmitDisableStatus = () => {
    if (selectedIssueType === "Fraud Report") {
      return !(commentsText.length > 0 && selectedFraudUsersList.length > 0);
    }
    return commentsText.length === 0;
  };

  const handleFraudUsersSelectionChange = (userName) => {
    if (!isLoading) {
      if (selectedFraudUsersList.includes(userName)) {
        let filteredFraudUsers = [...selectedFraudUsersList].filter(
          (eachuser) => eachuser !== userName
        );
        setSelectedFraudUsersList(filteredFraudUsers);
      } else {
        setSelectedFraudUsersList([...selectedFraudUsersList, userName]);
      }
    }
  };

  return (
    <Container orientation={gameOrientation}>
      <ModalWrapper>
        <ModalHeader>
          <HeaderTitleText>Select Issue Type</HeaderTitleText>
          <CloseIcon src={closeIcon} onClick={handleModalClose} />
        </ModalHeader>

        <ModalBody
          onClick={() => {
            setOpenUsersList(false);
          }}
        >
          <IssueTypesContainer>
            <RadioContainer>
              <RadioElement
                src={getRadioIcon("Winnings")}
                onClick={(event) => handleIssueTypeChange(event, "Winnings")}
              />
              <IssueTypeText>Winnings</IssueTypeText>
            </RadioContainer>
            <RadioContainer>
              <RadioElement
                src={getRadioIcon("Game Related")}
                onClick={(event) =>
                  handleIssueTypeChange(event, "Game Related")
                }
              />
              <IssueTypeText>Game Related</IssueTypeText>
            </RadioContainer>
            <RadioContainer>
              <RadioElement
                src={getRadioIcon("Technical Issues")}
                onClick={(event) =>
                  handleIssueTypeChange(event, "Technical Issues")
                }
              />
              <IssueTypeText>Technical Issues</IssueTypeText>
            </RadioContainer>
            <FraudReportContainer>
              <RadioContainer>
                <RadioElement
                  src={getRadioIcon("Fraud Report")}
                  onClick={(event) =>
                    handleIssueTypeChange(event, "Fraud Report")
                  }
                />
                <IssueTypeText>Fraud Report</IssueTypeText>
              </RadioContainer>
              {selectedIssueType === "Fraud Report" && (
                <SelectUsersContainer>
                  <SelectUsersWrapper onClick={handleUserListOpen}>
                    <SelectPlaceholderText>
                      {selectedFraudUsersList.length === 0
                        ? "Select Users"
                        : selectedFraudUsersList.length === 1
                        ? `${getDisplayName(selectedFraudUsersList[0] , playerDisplayNameMap,loggedPlayer,displayName)}`
                        : selectedFraudUsersList.length > 1
                        ? `Selected (${selectedFraudUsersList.length})`
                        : "Select Users"}
                    </SelectPlaceholderText>
                    <AccordianIconWrapper src={accordianSmallIcon} />
                  </SelectUsersWrapper>
                  {openUsersList && (
                    <UsersListContainer>
                      {Object.keys(handHistoryDetails.playerDetails).map(
                        (eachUser) => (
                          <UserWrapper key={eachUser}>
                            <CheckboxIcon
                              src={
                                selectedFraudUsersList.includes(eachUser)
                                  ? checkbox
                                  : emptyCheckbox
                              }
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                handleFraudUsersSelectionChange(eachUser);
                              }}
                            />
                            <UserNameText>
                            {getDisplayName(eachUser , playerDisplayNameMap,loggedPlayer,displayName)}</UserNameText>
                          </UserWrapper>
                        )
                      )}
                    </UsersListContainer>
                  )}
                </SelectUsersContainer>
              )}
            </FraudReportContainer>
            <RadioContainer>
              <RadioElement
                src={getRadioIcon("Other")}
                onClick={(event) => handleIssueTypeChange(event, "Other")}
              />
              <IssueTypeText>Other</IssueTypeText>
            </RadioContainer>
          </IssueTypesContainer>
          <TextArea
            type={"text"}
            placeholder={"Enter Comments (Upto 500 characters)"}
            maxLength={500}
            value={commentsText}
            onChange={handleCommentsTextChange}
          />
          <CTAContainer>
            <PrimaryCTAButton
              onClick={handleSubmit}
              disabled={getSubmitDisableStatus()}
            >
              {isLoading ? (
                <Loader size={20} />
              ) : (
                <ButtonText>Submit Report</ButtonText>
              )}
            </PrimaryCTAButton>
          </CTAContainer>
        </ModalBody>
      </ModalWrapper>
    </Container>
  );
}

export default ReportIssue;
