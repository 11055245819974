import styled from "styled-components";
import {
  Typo10GrayContoRegularText,
  Typo10MalachiteContoMediumText,
  Typo10WhiteContoMediumText,
} from "../../../Common/styleGuide/Typos";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 180px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #336070;
`;

export const TourneyStackDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const TourneyStackItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 12px;
  width: 33%;
`;

export const StackDetailsText = styled(Typo10GrayContoRegularText)`
  line-height: 13.55px;
`;

export const StackDetailsValueText = styled(Typo10WhiteContoMediumText)`
  line-height: 13.55px;
`;

export const TourneyStartAndEndTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #053c4a;
  height: 26px;
  width: 100%;
  margin-top: 12px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 0px 12px;
  box-sizing: border-box;
`;

export const TourneyTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const TourneySkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 130px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  box-sizing: border-box;
  border: 1px solid #336070;
  padding: 16px;
  gap: 8px;
`;

export const TourneyQualifiedPlayersText = styled(
  Typo10MalachiteContoMediumText
)`
  display: flex;
  line-height: 13.55px;
  align-items: center;
`;

export const InfoIcon = styled.img`
  height: 12px;
  width: 12px;
  margin-left: 4px;
`;

export const HorizontalSeperator = styled.img`
  width: 100%;
  margin-top: 12px;
`;

export const QualifiedTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 12px;
`;

export const VerticalSeperator = styled.img`
  height: 40px;
`;
