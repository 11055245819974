import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addLobbyToastMessage,
  handleTemplateSaveStatus,
  resetInitiateMatchmakingForGdid,
  updatePrivateTableHostingDetails,
} from "../../redux/slices/lobbySlice";
import {
  closePrivateTableSliders,
  updateSliderAnimatingFlag,
} from "../../redux/slices/sliderSlice";
import {
  Container,
  DetailsWrapper,
  Footer,
  GeneralLabel,
  GeneralInfoWrapper,
  Header,
  HostDetailsContainer,
  PlayersPerTable,
  PlayersPerTableWrapper,
  Row,
  Toggle,
  StarImg,
  StrokeLineTowardsLeft,
  StrokeLineTowardsRight,
  Button,
  SecondaryButton,
  SavedTemplateIcon,
} from "./createHostingDetailsStyle";
import { Icon } from "./privateTableSliderStyle";
import closeIcon from "../../../assets/lobby/CloseIcon.svg";
import toggleOff from "../../../assets/lobby/toggleOff.png";
import toggleOn from "../../../assets/lobby/toggleOn.png";
import star from "../../../assets/lobby/ninja-star.png";
import checkMarkGray from "../../../assets/lobby/checkMarkGray.png";

import {
  Typo12GrayContoMediumText,
  Typo14GrayContoMediumText,
} from "../../../Common/styleGuide/Typos";
import {
  HOSTED_PRIVATE_TABLE,
  INPUT_TYPE_TEXT,
  LB_REAL,
  MAX_TABLES_ALLOWED,
  MAX_TABLE_LIMIT,
  MIN_REQUIRED_FIELD_IN_PRIVATE_TABLE_CREATION,
  PRIVATE_TABLE_HOSTING_DETAILS_SLIDER,
  PRIVATE_TABLE_SAVE_AS_TEMPLATE,
  TOAST_ERROR,
  TWO_PLAYERS_TABLE,
} from "../../data/Constants";
import { Dropdown } from "../../../Common/components/Dropdown/Dropdown";

import { Slider } from "@ganesh.karedla/a23coreui";
import { createTableData } from "../../redux/slices/gameTableSlice";
import { generateUniqueId } from "../../../Common/utils/PokerUtils";
import {
  calculateEndTimeInEpoch,
  getGameLabel,
} from "../../../Common/utils/GameUtils";
import { InputField } from "../../../Common/components/Inputs/TextOrNumber/InputField";
import { sendCreatePrivateTableProtocol } from "../../utils/ProtocolUtils";
import { openModal } from "../../redux/slices/modalSlice";

export const CreateHostingDetailsSlider = (props) => {
  const { sliderDirection, config } = props;

  const [tableName, setTableName] = useState(config.newTable);
  const [sendHostDetails, setSendHostDetails] = useState(false);

  const [hostDetails, setHostDetails] = useState({});
  const [collapseDropdown, setCollapseDropdown] = useState(false);
  const { userName, userId } = useSelector((state) => state.lobby.player);
  const initiateMatchmakingForGdid = useSelector(
    (state) => state.lobby.privateTable?.initiateMatchmakingForGdid
  );

  const isTemplateSaved = useSelector(
    (state) => state.lobby.privateTable.isTemplateSaved
  );

  const activeGames = useSelector((state) => state.gameTable.games);
  const dispatch = useDispatch();

  const {
    blinds,
    turnTimer,
    playerPerTable,
    tableEndTimer,
    autoAssignSeat,
    autoReloadChips,
    autoStart,
    minPlayersBeforeStart,
  } = useSelector((state) => state.lobby.privateTable.configs);

  useEffect(() => {
    let details = {};
    if (config.gameDefinition) {
      // data from template
      let gameDefinition = config.gameDefinition;
      details = {
        smallBlind: gameDefinition.smallBlind,
        bigBlind: gameDefinition.bigBlind,
        gameType: gameDefinition.gameType,
        autoStart: gameDefinition.autoStart,
        // autoAssignSeat: gameDefinition.autoAssignSeat,
        // autoReloadChips: gameDefinition.autoReloadChips,
        playersPerTable: gameDefinition.maxPlayers,
        minPlayersBeforeAutoStartGame: gameDefinition.minPlayersToStartGame,
        turnTimer: gameDefinition.turnTimer,
        completionTime: gameDefinition.tableTime,
        minBuyIn: gameDefinition.minBuyInAmount,
        maxBuyIn: gameDefinition.maxbuyInAmount,
        maxbuyInAmount: gameDefinition.maxbuyInAmount,
        minBuyInAmount: gameDefinition.minBuyInAmount,
        tableName: gameDefinition.tableName,
        gameType: gameDefinition.gameType,
      };
    } else {
      details = {
        tableName: "",
        gameType: config.gameType,
        autoStart: autoStart,
        // autoReloadChips: autoReloadChips,
        minPlayersBeforeAutoStartGame: minPlayersBeforeStart[0],
        turnTimer: turnTimer[0],
        completionTime: config.completionTime,
        ...blinds[0],
        minBuyInAmount: blinds[0].bigBlind,
        maxbuyInAmount: 250 * blinds[0].bigBlind,
        minBuyIn: blinds[0].bigBlind,
        maxBuyIn: 250 * blinds[0].bigBlind,
        completionTime: tableEndTimer[0],
      };
    }
    updateHostDetails(details);
    return () => {};
  }, [config]);

  useEffect(() => {
    if (initiateMatchmakingForGdid) {
      dispatch(
        updatePrivateTableHostingDetails({
          gameDefId: initiateMatchmakingForGdid,
          gameDefinition: {
            ...hostDetails,
            accessCode: initiateMatchmakingForGdid,
          },
        })
      );

      dispatch(
        createTableData({
          tempTableId: generateUniqueId(),
          gameDefinition: {
            gameDefId: initiateMatchmakingForGdid,
            accessCode: initiateMatchmakingForGdid,
            maxPlayers: hostDetails.playersPerTable,
            gameType: hostDetails.gameType,
            minBuyInAmount: hostDetails.minBuyIn,
            maxBuyInAmount: hostDetails.maxBuyIn,
            accessCode: initiateMatchmakingForGdid,
            mode: HOSTED_PRIVATE_TABLE,
            tableTime: hostDetails.turnTimer,
            flavourType: LB_REAL,
            ...hostDetails,
          },
          watchPlayer: true,
          isPrivateTable: true,
        })
      );

      dispatch(closePrivateTableSliders());
      dispatch(resetInitiateMatchmakingForGdid());
    }
  }, [initiateMatchmakingForGdid]);

  const handleSliderClose = () => {
    dispatch(closePrivateTableSliders());
    dispatch(
      handleTemplateSaveStatus({
        status: false,
      })
    );
  };

  const updateHostDetails = (data) => {
    setHostDetails({ ...hostDetails, ...data });
  };

  const createSectionLabel = (type) => {
    return (
      <GeneralInfoWrapper>
        <StrokeLineTowardsLeft />
        <GeneralLabel>{type}</GeneralLabel>
        <StarImg src={star} width={10}></StarImg>
        <StarImg src={star} width={15}></StarImg>
        <StrokeLineTowardsRight />
      </GeneralInfoWrapper>
    );
  };

  const renderGeneralInfo = () => {
    return (
      <>
        {createSectionLabel("General Info")}

        <HostDetailsContainer>
          <Row dBlock>
            <Typo12GrayContoMediumText>Table Name</Typo12GrayContoMediumText>
            <InputField
              type={INPUT_TYPE_TEXT}
              location={PRIVATE_TABLE_HOSTING_DETAILS_SLIDER}
              onChangeHandler={onTableNameInputChange}
              onBlurHandler={onTableNameBlurHandler}
              value={hostDetails.tableName}
            ></InputField>
          </Row>

          <Row>
            <Typo14GrayContoMediumText>
              Players Per Table
            </Typo14GrayContoMediumText>
            {getPlayerPerTable()}
          </Row>
        </HostDetailsContainer>
      </>
    );
  };
  const onTableNameInputChange = (e) => {
    updateHostDetails({ tableName: e.target.value });
  };

  const onTableNameBlurHandler = (e) => {
    updateHostDetails({ tableName: e.target.value });
  };

  const handlePlayersPerTableConfigClick = (numberOfPlayers) => {
    updateHostDetails({
      playersPerTable: numberOfPlayers,
    });
  };

  const toggleCollapseDropdown = () => {
    setCollapseDropdown(false);
  };

  const getPlayerPerTable = () => {
    return (
      <PlayersPerTableWrapper>
        {playerPerTable.map((item) => {
          return (
            <PlayersPerTable
              active={item === hostDetails.playersPerTable}
              onClick={() => {
                handlePlayersPerTableConfigClick(item);
              }}
            >
              {item}
            </PlayersPerTable>
          );
        })}
      </PlayersPerTableWrapper>
    );
  };

  const getBlindValue = (blind) => {
    updateHostDetails({
      minBuyIn: blind.bigBlind,
      maxBuyIn: 250 * blind.bigBlind,
      ...blind,
    });
  };

  const getTimerValue = (timerValue) => {
    updateHostDetails({
      turnTimer: timerValue,
    });
  };

  const getTableEndTimerValue = (timerValue) => {
    updateHostDetails({
      completionTime: timerValue,
    });
  };

  const renderGameInfo = () => {
    return (
      <HostDetailsContainer>
        {createSectionLabel("Game Info")}

        <Row>
          <Typo14GrayContoMediumText>Game type</Typo14GrayContoMediumText>
          <Typo14GrayContoMediumText>
            {getGameLabel(
              config.gameType ? config.gameType : hostDetails.gameType
            )}
          </Typo14GrayContoMediumText>
        </Row>

        <Row>
          <Typo14GrayContoMediumText>Blinds</Typo14GrayContoMediumText>
          <Dropdown
            dropdownKeys={blinds}
            defaultKey={[
              {
                smallBlind: hostDetails.smallBlind,
                bigBlind: hostDetails.bigBlind,
              },
            ]}
            onClickHandler={getBlindValue}
            type={"blinds"}
            collapseDropdown={collapseDropdown}
            toggleCollapseDropdown={toggleCollapseDropdown}
          />
        </Row>

        <Row>
          <Typo14GrayContoMediumText>
            Select Buy-In Range
          </Typo14GrayContoMediumText>
        </Row>
        <Row>
          <Slider
            min={hostDetails?.minBuyIn}
            max={hostDetails?.maxBuyIn}
            defaultMin = {hostDetails?.minBuyIn}
            defaultMax = {hostDetails?.maxBuyIn}
            onChange={(e) => {
              updateHostDetails({
                maxBuyInAmount: e.max,
                minBuyInAmount: e.min,
              });
            }}
          ></Slider>
        </Row>

        <Row>
          <Typo14GrayContoMediumText>
            Turn Timer (sec)
          </Typo14GrayContoMediumText>
          <Dropdown
            dropdownKeys={turnTimer}
            onClickHandler={getTimerValue}
            defaultKey={hostDetails.turnTimer}
            collapseDropdown={collapseDropdown}
            toggleCollapseDropdown={toggleCollapseDropdown}
          />
        </Row>
        <Row>
          {/* min is temproary --- only for development / testing */}
          <Typo14GrayContoMediumText>
            Completion time (min)
          </Typo14GrayContoMediumText>
          <Dropdown
            dropdownKeys={tableEndTimer}
            defaultKey={hostDetails.completionTime}
            onClickHandler={getTableEndTimerValue}
            collapseDropdown={collapseDropdown}
            toggleCollapseDropdown={toggleCollapseDropdown}
          />
        </Row>
      </HostDetailsContainer>
    );
  };

  const onAutoStartToggleClick = () => {
    updateHostDetails({
      autoStart: !hostDetails.autoStart,
    });
  };

  const onAutoReloadChipsToggleClick = () => {
    updateHostDetails({
      autoReloadChips: !hostDetails.autoReloadChips,
    });
  };

  const getMinPlayerBeforeAutoStartGame = (player) => {
    updateHostDetails({
      minPlayersBeforeAutoStartGame: player,
    });
  };

  const renderAdvancedSettings = () => {
    return (
      <HostDetailsContainer>
        {createSectionLabel("Advanced Settings")}
        {/* <Row>
          <Typo14GrayContoMediumText>
            Auto Assign Seat
          </Typo14GrayContoMediumText>
          <Toggle
            src={hostDetails.autoAssignSeat ? toggleOn : toggleOff}
            onClick={() => {
              onAutoAssignSeatToggleClick();
            }}
          />
        </Row> */}

        <Row>
          <Typo14GrayContoMediumText>Auto Start</Typo14GrayContoMediumText>
          <Toggle
            src={hostDetails.autoStart ? toggleOn : toggleOff}
            onClick={() => {
              onAutoStartToggleClick();
            }}
          />
        </Row>
        {hostDetails.autoStart && (
          <Row>
            <Typo14GrayContoMediumText>
              Min players before start
            </Typo14GrayContoMediumText>
            <Dropdown
              dropdownKeys={
                hostDetails.playersPerTable === TWO_PLAYERS_TABLE
                  ? [TWO_PLAYERS_TABLE]
                  : minPlayersBeforeStart
              }
              onClickHandler={getMinPlayerBeforeAutoStartGame}
              defaultKey={
                hostDetails.playersPerTable === TWO_PLAYERS_TABLE
                  ? TWO_PLAYERS_TABLE
                  : minPlayersBeforeStart[0]
              }
              updateKeysOnDataChange={hostDetails.playersPerTable}
              collapseDropdown={collapseDropdown}
              toggleCollapseDropdown={toggleCollapseDropdown}
            />
          </Row>
        )}
        <Row>
          <Typo14GrayContoMediumText>
            Auto Reload Chips
          </Typo14GrayContoMediumText>
          <Toggle
            src={hostDetails.autoReloadChips ? toggleOn : toggleOff}
            onClick={() => {
              onAutoReloadChipsToggleClick();
            }}
          />
        </Row>
      </HostDetailsContainer>
    );
  };
  const validateDetails = () => {
    let flag = false;
    // mandatory details
    const { tableName, playersPerTable, smallBlind, bigBlind, turnTimer } =
      hostDetails;
    if (tableName && playersPerTable && smallBlind && bigBlind && turnTimer) {
      flag = true;
      // secondayrDetails
      if (
        hostDetails?.autoStart &&
        !hostDetails?.minPlayersBeforeAutoStartGame
      ) {
        flag = false;
      }
    }
    return flag;
  };

  const onHostTableClick = () => {
    if (Object.keys(activeGames).length === MAX_TABLES_ALLOWED) {
      dispatch(
        openModal({
          type: MAX_TABLE_LIMIT,
        })
      );
      return;
    }

    if (validateDetails()) {
      setSendHostDetails(true);
      setTableName(hostDetails.tableName);
      sendCreatePrivateTableProtocol({
        playerName: userName,
        hostId: userId,
        completionTime: calculateEndTimeInEpoch(hostDetails.completionTime),
        tableName: hostDetails.tableName,
        gameType: hostDetails.gameType,
        autoStart: hostDetails.autoStart,
        // autoReloadChips: hostDetails.autoReloadChips,
        minPlayersBeforeAutoStartGame:
          hostDetails.minPlayersBeforeAutoStartGame,
        turnTimer: hostDetails.turnTimer,
        smallBlind: hostDetails.smallBlind,
        bigBlind: hostDetails.bigBlind,
        playersPerTable: hostDetails.playersPerTable,
        minBuyInAmount: hostDetails.minBuyInAmount || hostDetails.minBuyIn,
        maxBuyInAmount: hostDetails.maxBuyInAmount || hostDetails.maxBuyIn,
      });
    } else {
      dispatch(
        addLobbyToastMessage({
          type: TOAST_ERROR,
          message: "Please enter all the details",
        })
      );
    }
  };

  const onSaveAsTemplateClick = () => {
    console.log("sneha log >> hostDetails",hostDetails);
    if (!isTemplateSaved) {
      if (validateDetails()) {
        dispatch(
          openModal({
            type: PRIVATE_TABLE_SAVE_AS_TEMPLATE,
            config: { ...hostDetails },
          })
        );
      } else {
        dispatch(
          addLobbyToastMessage({
            type: TOAST_ERROR,
            message: "Please enter all the details",
          })
        );
      }
    }
  };

  return (
    <Container
      onAnimationEnd={() => {
        if (sliderDirection === "close") {
          handleSliderClose();
        }
        dispatch(updateSliderAnimatingFlag({ value: false }));
      }}
      sliderDirection={sliderDirection}
      onClick={() => {
        setCollapseDropdown(true);
      }}
    >
      <Header>
        Create Table
        <Icon
          src={closeIcon}
          right={15}
          width={14}
          onClick={() => {
            handleSliderClose();
          }}
        ></Icon>
      </Header>
      <DetailsWrapper>
        {Object.keys(hostDetails).length >=
        MIN_REQUIRED_FIELD_IN_PRIVATE_TABLE_CREATION ? (
          <>
            {renderGeneralInfo()}
            {renderGameInfo()}
            {renderAdvancedSettings()}
          </>
        ) : null}
      </DetailsWrapper>
      <Footer>
        <SecondaryButton
          onClick={() => onSaveAsTemplateClick()}
          showBorder={!isTemplateSaved}
        >
          {isTemplateSaved && <SavedTemplateIcon src={checkMarkGray} />}
          {isTemplateSaved ? "Saved as Template" : "Save as Template"}
        </SecondaryButton>
        <Button onClick={() => onHostTableClick()} isDisabled={sendHostDetails}>
          Host Table
        </Button>
      </Footer>
    </Container>
  );
};
