import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeToastMessage } from "../../../Poker/redux/slices/gameTableSlice";
import errorIcon from "../../../assets/common/ErrorCircleIcon.svg";
import infoIcon from "../../../assets/common/InfoIcon.svg";
import successIcon from "../../../assets/common/SuccessIcon.svg";
import warningIcon from "../../../assets/common/WarningIcon.svg";

import closeIcon from "../../../assets/common/CloseLightIcon.svg";

import {
  ToastContainer,
  ErrorToastWrapper,
  ErrorToastMessageText,
  IconWrapper,
  CloseIconWrapper,
  InfoToastWrapper,
  InfoToastMessageText,
  SuccessToastWrapper,
  SuccessToastMessageText,
  WarningToastWrapper,
  WarningToastMessageText,
} from "./toastStyle";
import {
  LANDSCAPE,
  TOAST_ERROR,
  TOAST_INFO,
  TOAST_SUCCESS,
  TOAST_WARNING,
} from "../../../Poker/data/Constants";
import { removeLobbyToastMessage } from "../../../Poker/redux/slices/lobbySlice";

export const Toast = (props) => {
  const { isLobbyToasts } = props;
  const activeGameTable = useSelector((state) => state.gameTable.activeGame);
  const lobbyToastMessages = useSelector((state) => state.lobby.toastMessages);
  const toastMessages = useSelector(
    (state) => state.gameTable.games[activeGameTable]?.toastMessages
  );
  const toastTimeout = useRef(null);
  const [toasts, setToasts] = useState(
    isLobbyToasts ? lobbyToastMessages : toastMessages
  );
  const gameOrientation = useSelector(
    (state) => state.gameTable.tableOrientation
  );
  const [autoCloseDuration, setAutoCloseDuration] = useState(3);
  // const [autoCloseDuration, setAutoCloseDuration] = useState(10); //temp value @yashwanth - to be reset to 3 seconds as above after null cookie testing
  const [toastToBeRemoveId, setToastToBeRemovedId] = useState(false);
   const complianceToastmessage = useSelector((state) => state.lobby.complianceToastMessage);
 
  const dispatch = useDispatch();

  let timer;
  useEffect(() => {
    if (
      toastMessages?.length > 0 &&
      toasts.length <= toastMessages.length &&
      activeGameTable === props.tempTableId &&
      !checkIfToastExists()
    ) {
      showToast(toastMessages[toastMessages.length - 1]);
    }

    return () => clearTimeout(toastTimeout.current);
  }, [toastMessages, toasts]);

  useEffect(() => {
    if (isLobbyToasts && lobbyToastMessages && toasts.length === 0) {
      lobbyToastMessages.forEach((toast) => showToast(toast));
    }
  }, [lobbyToastMessages, isLobbyToasts]);

  useEffect(() => {
    if (toasts?.length > 0) {
      toastTimeout.current = setTimeout(() => {
        setToastToBeRemovedId(toasts[0].id);
      }, autoCloseDuration - 2 * 1000);
    }
  }, [toasts]);

  const checkIfToastExists = () => {
    let filteredToast =
      toasts &&
      toastMessages.length > 0 &&
      toasts.filter(
        (toast) =>
          toast.message === toastMessages[toastMessages.length - 1].message
      );
    if (filteredToast.length > 0) {
      let allToasts = toasts;
      allToasts.map((eachToast) => {
        if (eachToast?.message === filteredToast[0]?.message) {
          return {
            ...eachToast,
            isExist: true,
          };
        }
      });

      setToasts(allToasts);
      dispatch(
        removeToastMessage({
          tempTableId: activeGameTable,
          message: filteredToast[0].message,
        })
      );
    }
    return filteredToast.length;
  };

  const showToast = (toastData) => {
    const toast = {
      id: Date.now(),
      type: toastData.type,
      message: toastData.message,
    };

    setToasts([...toasts, toast]);

    timer = setTimeout(() => {
      removeToast(toast.id, timer);
      if (isLobbyToasts) {
        dispatch(removeLobbyToastMessage({ message: toastData }));
      } else {
        dispatch(
          removeToastMessage({
            tempTableId: activeGameTable,
            message: toastData,
          })
        );
      }
    }, autoCloseDuration * 1000);
  };

  const removeToast = (id, timer) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    if (timer) {
      clearTimeout(timer);
    }
  };

  const getToastByType = (type, message, toastId, isExist) => {
    switch (type) {
      case TOAST_ERROR:
        return (
          <ErrorToastWrapper
            id={`toast-${toastId}`}
            key={toastId}
            fadeOut={toastId === toastToBeRemoveId}
          >
            <IconWrapper src={errorIcon} />

            <ErrorToastMessageText>{message}</ErrorToastMessageText>
            <CloseIconWrapper
              src={closeIcon}
              onClick={() => {
                removeToast(toastId, timer);
              }}
            />
          </ErrorToastWrapper>
        );
      case TOAST_INFO:
        return (
          <InfoToastWrapper
            id={`toast-${toastId}`}
            key={toastId}
            fadeOut={toastId === toastToBeRemoveId}
            isExist={isExist}
          >
            <IconWrapper src={infoIcon} />
            <InfoToastMessageText>{message}</InfoToastMessageText>
            <CloseIconWrapper
              src={closeIcon}
              onClick={() => {
                removeToast(toastId, timer);
              }}
            />
          </InfoToastWrapper>
        );
      case TOAST_SUCCESS:
        return (
          <SuccessToastWrapper
            id={`toast-${toastId}`}
            key={toastId}
            fadeOut={toastId === toastToBeRemoveId}
            isExist={isExist}
          >
            <IconWrapper src={successIcon} />
            <SuccessToastMessageText>{message}</SuccessToastMessageText>
            <CloseIconWrapper
              src={closeIcon}
              onClick={() => {
                removeToast(toastId, timer);
              }}
            />
          </SuccessToastWrapper>
        );
      case TOAST_WARNING:
        return (
          <WarningToastWrapper
            id={`toast-${toastId}`}
            key={toastId}
            fadeOut={toastId === toastToBeRemoveId}
          >
            <IconWrapper src={warningIcon} />
            <WarningToastMessageText>{message}</WarningToastMessageText>
            <CloseIconWrapper
              src={closeIcon}
              onClick={() => {
                removeToast(toastId, timer);
              }}
            />
          </WarningToastWrapper>
        );
      default:
        return (
          <InfoToastWrapper
            id={`toast-${toastId}`}
            key={toastId}
            fadeOut={toastId === toastToBeRemoveId}
          >
            <InfoToastMessageText>{message}</InfoToastMessageText>
            <CloseIconWrapper
              src={closeIcon}
              onClick={() => {
                removeToast(toastId, timer);
              }}
            />
          </InfoToastWrapper>
        );
    }
  };

  const showToastMessage = () => {
    return (
      <ToastContainer isLandscapeOrientation={gameOrientation === LANDSCAPE} isComplianceMessage={complianceToastmessage.length>0?true:false}>
        {toasts &&
          toasts.map((item) => {
            return getToastByType(
              item.type,
              item.message,
              item.id,
              item.isExist
            );
          })}
      </ToastContainer>
    );
  };

  return <>{showToastMessage()}</>;
};
