import React, { useState } from "react";

import A23PokerLogo from "../../../assets/lobby/A23Logo.png";
import notificationIconSource from "../../../assets/lobby/NotificationIcon.svg";
import goldPlayerIcon from "../../../assets/lobby/GoldPlayerIcon.svg";
import goldWalletIcon from "../../../assets/lobby/GoldWalletIcon.svg";
import greyLockIcon from "../../../assets/lobby/greyLockIcon.svg";
import funChipsIcon from "../../../assets/lobby/FunChipIcon.svg";
import reloadChipsIcon from "../../../assets/lobby/Reload.svg";
import addIcon from "../../../assets/lobby/AddIcon.svg";
import * as Constants from "../../data/Constants.js";
import backArrow from "../../../assets/lobby/back.png";

import {
  HeaderWrapper,
  A23LogoWrapper,
  NotificationAndLiveCountContainer,
  NotificationIcon,
  LivePlayerCountWrapper,
  PlayerIcon,
  LiveCountText,
  LogoAndLiveCountContainer,
  AddCashAndDynamicHeaderItemContainer,
  WalletAndAddCashCTAWrapper,
  CashAndPracticeGamesWrapper,
  CashAndPracticeGameToggleWrapper,
  CashAndPracticeItemText,
  WalletWrapper,
  WalletIcon,
  WalletAmountText,
  DynamicHeaderTitleText,
  FunChipsContainer,
  FunChipsBalanceText,
  ChipIcon,
  ReloadIcon,
  BackToPlatformButton,
  RedDotIcon,
  HomeTitleText,
  Icon,
} from "./headerStyles";
import Button from "../../../Common/components/Button/Button";
import colors from "../../../Common/styleGuide/Colors";
import { messageToNativeClient } from "../../../Common/utils/platformCommunicationUtil";
import { footerTabConstants } from "../../routes/PokerLobby/LobbyConstants";
import GameAudio from "../../audio/GameAudio";
import { useDispatch, useSelector } from "react-redux";
import {
  getISTDateTimeFormat,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";
import { openModal } from "../../redux/slices/modalSlice.js";
import {
  requestFunChipsReload,
  setShowPrivateTableLobby,
} from "../../redux/slices/lobbySlice.js";
import { FooterTabConstants } from "../Footer/constants.js";
import CleverTapUtil from "../../../Common/utils/CleverTapUtil";
import { IP_Client_CashFunToggle_Click } from "../../data/ClevertapConstants";
import { getGameSlice } from "../../utils/ReduxUtils";
import { HeaderIcon, HomePageTitle } from "../../routes/PokerLobby/HomeStyle";
import { logCleverTapEvent } from "../../../Common/utils/CleverTapUtilNew.js";
import { getEventParams } from "../../utils/MixPanelUtils.js";
import {
  MP_WALLET_BUTTON_CLICK,
  MP_NOTIFICATION_BUTTON_CLICKED,
  MP_POKER_CASH_PRACTICE_TOGGLE_CLICKED,
} from "../../data/MixPanelConstant.js";

function Header(props) {
  const {
    userBalance,
    isCashGamesToggled,
    handleCashAndPracticeGameToggle,
    onHeaderA23LogoClick,
    currentTab,
    channel,
  } = props;
  const dispatch = useDispatch();
  const onlinePlayerCount = useSelector(
    (state) => state.lobby.onlinePlayerCount
  );
  const notificationsCount = useSelector(
    (state) => state.lobby.notificationsCount
  );
  const playerData = useSelector((state) => state.lobby.player);
  const isPseudoLockedpLayer =
    playerData.playerStatus === Constants.PSEUDO_LOCKED_PLAYER;
  const activeGames = useSelector((state) =>
    Object.keys(state.gameTable.games)
  );
  const isPlaySoundEnabled = useSelector(
    (state) => state.gameTable.tableSettings?.sound
  );
  const isHapticsEnabled = useSelector(
    (state) => state.gameTable.tableSettings?.haptics
  );
  const userLocationCompliance = useSelector(
    (state) => state.lobby.userLocationCompliance
  );
  const pushToKyc = useSelector((state) => state.lobby.pushToKyc);
  const addCash_banner = useSelector((state) => state.lobby.addCash_banner);
  const location_addcash = useSelector((state) => state.lobby.location_addcash);

  const showPrivateTableLobby = useSelector(
    (state) => state.lobby.showPrivateTableLobby
  );

  const handleAddCash = () => {
    let temp = new Date();
    let timeStamp = new Date(temp.getTime() + 330 * 60 * 1000);
    timeStamp = timeStamp.toISOString();
    messageToNativeClient({
      type: "plotlineEvents",
      eventName: "AddCash_Click",
      eventProperties: {
        gameType: "Poker",
        channel: channel,
        clickLocation: "CashLobby",
        currentCashBalance: userBalance.totalBalance,
        timeStamp: timeStamp,
        userName: playerData.userName,
      },
    });
    // if (!playerData?.isAllowLocation) {
    //   return dispatch(openModal({ type: Constants.RESTRICTED_LOCATION }));
    // }
    // if(!userLocationCompliance && !playerData?.isAllowLocation){
    //     return dispatch(openModal({ type: Constants.RESTRICTED_LOCATION }));
    // }
    if (pushToKyc && addCash_banner) {
      messageToNativeClient({
        type: "showKyc",
        from: "deeplink_addcash",
        show: true,
      });
    } else if (!location_addcash) {
      if (userLocationCompliance) {
        dispatch(
          openModal({
            type: Constants.RESTRICTED_LOCATION_HOURS,
          })
        );
      } else {
        dispatch(
          openModal({
            type: Constants.RESTRICTED_LOCATION,
          })
        );
      }
    } else {
      messageToNativeClient({ type: "openaddcash" });
    }
  };

  const handleWallet = () => {
    messageToNativeClient({ type: "openMyWallet", isOpened: true });
    let MixPanel_params = getEventParams(
      MP_WALLET_BUTTON_CLICK,
      playerData,
      userBalance,
      channel,
      {}
    );
    messageToNativeClient({
      type: Constants.MIXPANEL_EVENT,
      eventName: MP_WALLET_BUTTON_CLICK,
      eventProperties: {
        ...MixPanel_params,
        ClickLocation: currentTab + "Lobby",
      },
    });
  };

  const handleToggleChange = () => {
    GameAudio.getInstance().playAudio(
      !isPlaySoundEnabled,
      Constants.BUTTON_SOUND,
      true,
      false
    );
    let timeStamp = getISTDateTimeFormat();

    messageToNativeClient({
      type: "plotlineEvents",
      eventName: "PR_Client_CashFunToggle_Click",
      eventProperties: {
        userName: playerData.userName,
        channel: channel,
        currentCashBalance: userBalance.totalBalance,
        timeStamp: timeStamp,
        clickLocation: !isCashGamesToggled ? "Cash" : "Practice",
      },
    });
    logCleverTapEvent(IP_Client_CashFunToggle_Click, {
      Username: playerData.userName,
      Channel: channel,
      "Wallet balance": userBalance.totalBalance,
      Timestamp: timeStamp,
      "Click location": isCashGamesToggled ? "Cash" : "Fun",
    });
    const MixPanel_params = getEventParams(
      MP_POKER_CASH_PRACTICE_TOGGLE_CLICKED,
      playerData,
      {},
      channel,
      {}
    );
    messageToNativeClient({
      type: Constants.MIXPANEL_EVENT,
      eventName: MP_POKER_CASH_PRACTICE_TOGGLE_CLICKED,
      eventProperties: {
        ...MixPanel_params,
        "New Status": !isCashGamesToggled ? "Cash" : Constants.LB_PLAY,
      },
    });
    try {
      if (isHapticsEnabled) {
        GameAudio.getInstance().playVibration(Constants.VIBRATE_LIGHT);
      }
    } catch (e) {
      console.log("Error at play haptic on cash and fun toggle");
    }
    handleCashAndPracticeGameToggle(!isCashGamesToggled);
  };

  const handleFunChipsReload = () => {
    if (userBalance.playChips < Constants.MIN_RELOAD_CHIPS_LIMIT) {
      dispatch(requestFunChipsReload());
    } else {
      dispatch(
        openModal({
          type: Constants.RESTRICT_FUN_CHIPS_RELOAD,
        })
      );
    }
  };

  const handleLogoClick = () => {};

  const navigateBackToHome = () => {
    return (
      <Icon
        src={backArrow}
        onClick={() => {
          dispatch(
            setShowPrivateTableLobby({
              showPrivateTableLobby: false,
            })
          );
        }}
      ></Icon>
    );
  };

  const renderDynamicHeaderItem = () => {
    const { home, games, tourneys, leaderboard, rules, promotions } =
      footerTabConstants;
    switch (currentTab) {
      case footerTabConstants.games:
        return Constants.FUN_CHANNELS.includes(channel) ? null : (
          <CashAndPracticeGamesWrapper id='cash-fun-toggle'>
            <CashAndPracticeGameToggleWrapper
              onClick={handleToggleChange}
              isActive={isCashGamesToggled}
            >
              <CashAndPracticeItemText>Cash</CashAndPracticeItemText>
            </CashAndPracticeGameToggleWrapper>
            <CashAndPracticeGameToggleWrapper
              onClick={handleToggleChange}
              isActive={!isCashGamesToggled}
            >
              <CashAndPracticeItemText>Practice</CashAndPracticeItemText>
            </CashAndPracticeGameToggleWrapper>
          </CashAndPracticeGamesWrapper>
        );
      case tourneys:
        return (
          <DynamicHeaderTitleText id={"plotline-tournaments"}>
            TOURNAMENTS
          </DynamicHeaderTitleText>
        );
      case promotions:
        return (
          <DynamicHeaderTitleText id={"plotline-promotions"}>
            PROMOTIONS
          </DynamicHeaderTitleText>
        );
      case leaderboard:
        return (
          <DynamicHeaderTitleText id={"plotline-leaderboard"}>
            LEADERBOARD
          </DynamicHeaderTitleText>
        );
      case rules:
        return (
          <DynamicHeaderTitleText id={"plotline-rules"}>
            RULES
          </DynamicHeaderTitleText>
        );
      case home:
        return (
          <DynamicHeaderTitleText id={"plotline-home"}>
            {!showPrivateTableLobby ? "HOME" : navigateBackToHome()}
          </DynamicHeaderTitleText>
        );
      // (
      //   // <div
      //   //   style={{
      //   //     display: "flex",
      //   //     flexDirection: "row",
      //   //     justifyContent: 'flex-start',
      //   //     alignItems: "center",
      //   //     gap: "6px",
      //   //   }}
      //   // >
      //     {/* <img src={homeIcon} style={{ height: "16px", position: 'relative', bottom: '2px' }}></img> */}
      //   // </div>
      // );
      default:
        return <div></div>;
    }
  };

  const handleLogout = () => {
    let gamesLength = Object.keys(getGameSlice().games);
    if (gamesLength?.length > 0) {
      dispatch(openModal({ type: Constants.LOGOUT_WARNING }));
    } else {
      messageToNativeClient({ type: "closeClient" });
    }
  };

  const handleNotificationIconClick = () => {
    messageToNativeClient({ type: "openNotifications" });
    const MixPanel_params = getEventParams(
      MP_NOTIFICATION_BUTTON_CLICKED,
      playerData,
      userBalance,
      channel,
      {}
    );
    messageToNativeClient({
      type: "mixpanelEvent",
      eventName: MP_NOTIFICATION_BUTTON_CLICKED,
      eventProperties: {
        ...MixPanel_params,
        ClickLocation: currentTab + "lobby",
      },
    });
  };

  return (
    <HeaderWrapper id='header-wrapper'>
      <LogoAndLiveCountContainer id={"plotline-logo-live-count"}>
        <A23LogoWrapper onClick={handleLogoClick} src={A23PokerLogo} />
        <NotificationAndLiveCountContainer id={"plotline-notification"}>
          <>
            {notificationsCount && notificationsCount > 0 ? (
              <RedDotIcon />
            ) : null}
            <NotificationIcon
              src={notificationIconSource}
              onClick={handleNotificationIconClick}
            />
          </>

          {onlinePlayerCount > 0 && (
            <LivePlayerCountWrapper id={"plotline-live-player-count"}>
              <PlayerIcon src={goldPlayerIcon} />
              <LiveCountText>{onlinePlayerCount}</LiveCountText>
            </LivePlayerCountWrapper>
          )}
        </NotificationAndLiveCountContainer>
      </LogoAndLiveCountContainer>
      <AddCashAndDynamicHeaderItemContainer id={"plotline-dynamic-header"}>
        {renderDynamicHeaderItem()}
        {currentTab === footerTabConstants.promotions ? null : (
          <>
            {" "}
            {(isCashGamesToggled ||
              currentTab === FooterTabConstants.tourneys) &&
            !Constants.FUN_CHANNELS.includes(channel) ? (
              <WalletAndAddCashCTAWrapper>
                <WalletWrapper
                  id={"plotline-player-wallet"}
                  isPseudoLockedpLayer={isPseudoLockedpLayer}
                  onClick={handleWallet}
                >
                  <WalletIcon
                    src={isPseudoLockedpLayer ? greyLockIcon : goldWalletIcon}
                  />
                  <WalletAmountText
                    id='wallet-amount'
                    isPseudoLockedpLayer={isPseudoLockedpLayer}
                  >{`₹${parseDecimalValuesOfNumber(
                    userBalance.totalBalance,
                    2
                  )}`}</WalletAmountText>
                </WalletWrapper>
                <Button
                  id={"plotline-add-cash-header"}
                  clickHandler={handleAddCash}
                  buttonText={"Cash"}
                  bgColor={colors.ctaPrimaryColor1}
                  showIcon={true}
                  iconUrl={addIcon}
                />
              </WalletAndAddCashCTAWrapper>
            ) : (
              <FunChipsContainer id={"plotline-fun-chips-container"}>
                <ChipIcon src={funChipsIcon} />
                <FunChipsBalanceText>
                  {userBalance.playChips}
                </FunChipsBalanceText>
                <ReloadIcon
                  src={reloadChipsIcon}
                  onClick={handleFunChipsReload}
                />
              </FunChipsContainer>
            )}
          </>
        )}
      </AddCashAndDynamicHeaderItemContainer>
    </HeaderWrapper>
  );
}

export default Header;
