import Handler from "./../Handler";

class DynamicPrivateTableStatusChangeHandler extends Handler {
  // dptsc# 
  execute(data) {
    let receivedData = JSON.parse(data);
    this.service.processDynamicPrivateTableStatusChange(receivedData);
  }
}

export default DynamicPrivateTableStatusChangeHandler;
