import { MIXPANEL_EVENT } from "../../Poker/data/Constants";

export const messageToNativeClient = (data) => {
  try {
    if (data.type === MIXPANEL_EVENT) {
      if (process.env.REACT_APP_LOGGING_MIXPANEL_EVENTS==="false") {
        console.warn("Turned off Mixpanel Events Logging");
        return;
      }
     
    }
    data = JSON.stringify(data);
    let userAgent = window.navigator.userAgent.toLocaleLowerCase();
    let date = new Date();
    console.log(
      `Message to Platform ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} >>> ${data}`
    );
    if (userAgent.indexOf("android") === -1) {
      //iOS webview
      window.webkit.messageHandlers.RedirectTo.postMessage(data);
    } else if (userAgent.toString().includes("wv")) {
      //android webview
      window.android.redirectTo(data);
    } else {
      console.warn("Client is not in webview, unable to send message: ", data);
    }
  } catch (e) {
    console.log("Error occured ..");
  }
};
