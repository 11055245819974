import Handler from "../Handler";

class AnnouncementBroadcastPrivateTableHandler extends Handler {
  //    announcementptb --- {
  //     "message": " X bdbd",
  //     "hostName": "amigo588252"
  // }
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processAnnouncementBroadcastPrivateTable(receivedData);
  }
}

export default AnnouncementBroadcastPrivateTableHandler;
