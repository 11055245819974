import React from "react";
import { useDispatch, useSelector } from "react-redux";
import restrictedIcon from "../../../../assets/lobby/restricted_location.svg";
import { closeModal } from "../../../../Poker/redux/slices/modalSlice";
import colors from "../../../styleGuide/Colors";
import { CTAContainer, PrimaryCTAButton } from "../ConfirmationPopupUI/confirmationPopupStyles";
import A23cares from "../../../../assets/lobby/A23Cares.svg"
import ConfirmationPopupUI from "../ConfirmationPopupUI/ConfirmationPopupUI";
import { InsufficientFundsText, TamilNaduRestrictionPopupCustomStyles } from "../Confirm/confirmStyles";
import { messageToNativeClient } from "../../../utils/platformCommunicationUtil";
import { IconWrapper } from "../../../../Poker/components/GameLooserModal/gameLooserModalStyles";

const RestrictedLocationHours = () => {
  const locationComplianceMessage = useSelector((state) => state.lobby.locationComplianceMessage);
  const dispatch = useDispatch();
  return (
    <>
      <ConfirmationPopupUI
        headerTitle={"New Game Locked"}
        headerCustomStyles={TamilNaduRestrictionPopupCustomStyles}
        title={
          <>
            <IconWrapper src={A23cares} />
            <InsufficientFundsText style={{ fontSize: '12px' }}>
              {locationComplianceMessage ? (
                locationComplianceMessage
              ) : (
                <>
                  <p style={{ fontSize: '14px', fontWeight: '600' }}>As per Tamil Nadu State Regulations</p>
                  <br />
                  <p>You cannot play cash games between 12AM to 5AM</p>
                </>
              )}
              <p style={{ textDecoration: 'underline', color: '#91CFDE' }} onClick={() =>
                messageToNativeClient({
                  type: "redirection",
                  kind: "internal",
                  url: process.env.REACT_APP_TERMS,
                })}>
                Know More
              </p>
            </InsufficientFundsText>
            <CTAContainer>
              <PrimaryCTAButton
                onClick={() => dispatch(closeModal())}
                style={{ color: "white", fontFamily: "conto" }}
              >
                Close
              </PrimaryCTAButton>
            </CTAContainer>
          </>
        }
      />
    </>

  );
};

export default RestrictedLocationHours;
