import React, { useState, memo, useRef } from "react";
import { CollapseCard } from "../../../Common/components/CollapseCard/CollapseCard";
import close from "../../../assets/common/close.svg";
// import { CardImages } from "../../../assets/cards/index";
import { CardImages } from "../../../assets/cards/CardBacks/index";
import { cardThemes } from "../../../assets/cards";
import {
  ActionIcon,
  ChipIcon,
  CommunityCardImage,
  CommunityCardsAndPotWrapper,
  CommunityCardsContainer,
  CommunityCardsImageContainer,
  Container,
  DetailLabel,
  EmptyContainer,
  EmptyHandHistoryText,
  GameInfoBox,
  GameInfoContainer,
  GameInfoWrapper,
  HandHistoryClose,
  HandHistoryTitle,
  HandHistoryTopSection,
  HandRankingNumberText,
  HandSummaryMiddleSection,
  HandSummaryWrapper,
  HeaderWrapper,
  LoaderContainer,
  PaginationContainer,
  PlayerAction,
  PlayerActionAmount,
  PlayerActionAndAmount,
  PlayerActionBox,
  PlayerAmount,
  PlayerAmountsAndStrength,
  PlayerCard,
  PlayerCards,
  PlayerHandStrength,
  PlayerName,
  PlayerNameBox,
  PlayerPosition,
  PlayerSummary,
  PotAmountInWrapper,
  PotAmountText,
  PotAmountWrapper,
  PotSummaryContainer,
  RoundInfoBox,
  RoundInfoDetails,
  SelectLeftRoundButton,
  SelectLeftTriangle,
  SelectRightRoundButton,
  SelectRightTriangle,
  SelectRoundCenter,
  SelectRoundWrapper,
  SummaryDetails,
  TitleLabel,
  ReportAnIssueContainer,
  ReportIssueText,
  LastHandsText,
  HappyHoursText,
  RunNumber,
} from "./handHistoryStyles";
import callIcon from "../../../assets/player/Call.png";
import checkIcon from "../../../assets/player/Check.png";
import foldIcon from "../../../assets/player/Fold.png";
import raiseIcon from "../../../assets/player/Raise.png";
// import chipIcon from "../../../assets/gameTable/chips.svg";
import chipIcon from "../../../assets/gameTable/chips.png";
import allInIcon from "../../../assets/player/all-in.svg";
import happyHoursGlass from "../../../assets/lobby/happy-hours-glass.png";
import {
  closeSlider,
  updateSliderAnimatingFlag,
} from "../../redux/slices/sliderSlice";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../Loader/Loader";
import { RelativeWrapper } from "../../routes/Game/gameTableStyles";
import {
  openLocalGameModal,
  requestHandHistory,
  setHandHistoryLoading,
} from "../../redux/slices/gameTableSlice";
import {
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  getHandType,
  getNumberOfCardsBasedOnGameType,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";
import { REPORT_AN_ISSUE, TEXAS_HOLDEM } from "../../data/Constants";
import {
  GreenDot,
  HappyHoursContainer,
  HappyHoursGlassImage,
} from "../LobbyGameTilesList/styledComponents";

import { getDisplayName } from "../../utils/ReduxUtils";
export const HandHistory = memo(({ sliderDirection, closeSliderProp }) => {
  // const renderCount = useRef(0);
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(true);
  const activeGame = useSelector((state) => state.gameTable.activeGame);
  const handHistoryLoading = useSelector(
    (state) => state.gameTable.handHistoryLoading
  );
  const gameDefinition = useSelector(
    (state) => state.gameTable.games[activeGame].gameDefinition
  );
  const loggedPlayer = useSelector((state) => state.lobby.player);
  let playerDisplayNameMap = useSelector(
    (state) => state.gameTable.games[activeGame]?.displayNameMap
  );
  const displayName = useSelector((state) => state.lobby?.displayName);
  // console.log("Anvita handhistory", playerDisplayNameMap)
  const handHistory = useSelector((state) => state.gameTable.handHistory);
  const gameIds = useSelector((state) => state.gameTable.handHistory.gameIds);
  const handIndex = useSelector(
    (state) => state.gameTable.handHistory.activeHandIndex
  );
  const cardDeckTheme = useSelector(
    (state) => state.gameTable.themeData.cardDeck
  );
  const activeUsers = handHistory?.details?.playerDetails
    ? Object.keys(handHistory?.details?.playerDetails)
    : [];
  const playerName = useSelector((state) => state.lobby.player.userName);
  const tableOrientation = useSelector(
    (state) => state.gameTable.tableOrientation
  );
  const isTourneyGame = useSelector(
    (state) => state.gameTable.games[activeGame]?.isTourneyGame
  );
  const isFunGame = useSelector(
    (state) => state.gameTable.games[activeGame].isFunGame
  );
  const cardBackTheme = useSelector(
    (state) => state.gameTable.themeData.cardBack
  );
  const isPrivateTable = useSelector(
    (state) => state.gameTable.games[activeGame].privateTable.isPrivateTable
  );
  // const handHistoryLoadingTimeout = useRef(null);

  // useEffect(() => {
  //   if (handHistory?.details?.tableActionHistory) {
  //     // handHistoryLoadingTimeout.current = setTimeout(() => {
  //       setLoading(false);
  //     // }, 1000);
  //   }
  //   renderCount.current += 1;
  //   return () => clearTimeout(handHistoryLoadingTimeout.current);
  // }, [handHistory?.details?.tableActionHistory]);

  const createGameInfoDetails = () => {
    let gameInfoDetails = [
      {
        key: isTourneyGame ? "Tourney" : "Game",
        detail: gameDefinition.tableName,
      },
      {
        key: "Hand ID",
        detail: handHistoryLoading ? "-" : handHistory.details.gameId,
      },
      {
        key: "Blinds",
        detail: `${parseDecimalValuesOfNumber(
          gameDefinition.smallBlind,
          2,
          checkToDisplayValueInK(gameDefinition.smallBlind),
          checkToDisplayValueInL(gameDefinition.smallBlind)
        )} / ${parseDecimalValuesOfNumber(
          gameDefinition.bigBlind,
          2,
          checkToDisplayValueInK(gameDefinition.bigBlind),
          checkToDisplayValueInL(gameDefinition.bigBlind)
        )}`,
      },
    ];

    return gameInfoDetails.map((item, index) => {
      return (
        <React.Fragment key={index + "-handHistory-gameInfo"}>
          <GameInfoBox>
            <TitleLabel>{item.key}</TitleLabel>
            {handHistoryLoading && item.key === "Hand ID" ? (
              <Loader size={10} />
            ) : (
              <DetailLabel>
                {gameIds?.length <= 0 && item.key === "Hand ID"
                  ? "--"
                  : item.detail}
                {handHistory?.details?.happyHoursEnabled &&
                item.key === "Hand ID" ? (
                  <HappyHoursText>
                    {/* <GreenDot /> */}
                    Happy Hour <HappyHoursGlassImage src={happyHoursGlass} />
                  </HappyHoursText>
                ) : null}
              </DetailLabel>
            )}
          </GameInfoBox>
        </React.Fragment>
      );
    });
  };

  const onRoundDecrementClick = () => {
    if (handIndex === 0) {
      return;
    } else {
      // Request for previous hand
      // setLoading(true);
      dispatch(setHandHistoryLoading());
      dispatch(
        requestHandHistory({
          index: handIndex - 1,
          gameId: gameIds[handIndex - 1],
        })
      );
    }
  };

  const onRoundIncrementClick = () => {
    if (handIndex === gameIds?.length - 1) {
      return;
    } else {
      // Request for next hand
      // setLoading(true);
      dispatch(setHandHistoryLoading());
      dispatch(
        requestHandHistory({
          index: handIndex + 1,
          gameId: gameIds[handIndex + 1],
        })
      );
    }
  };

  const returnCommunityCardsSection = () => {
    let bigBlindIndex = retrieveIndexOfPlayerType("BB");
    let players = [...activeUsers];
    let utgIndex;
    if (bigBlindIndex === players?.length - 1) {
      utgIndex = 0;
    } else {
      utgIndex = bigBlindIndex + 1;
      let firstHalf = players.splice(0, utgIndex);
      players = players.concat(firstHalf);
    }
    let potAmount = handHistory?.details?.potAmount;
    return (
      <>
        {handHistory.details.communityCards?.length > 0 ? (
          <CommunityCardsContainer>
            <CommunityCardsAndPotWrapper>
              <CommunityCardsImageContainer>
                {handHistory.details.communityCards.map((card, index) => {
                  return (
                    <CommunityCardImage
                      id="hand-history-card"
                      src={cardThemes[cardDeckTheme][card]}
                      key={card + index + "handHistory-communityCards"}
                    />
                  );
                })}
                {handHistory.details.ritCommunityCards?.length > 0 ? (
                  <CommunityCardsImageContainer type={"RIT"}>
                    {Array.from({
                      length: Math.max(
                        0,
                        5 - handHistory.details.ritCommunityCards.length
                      ),
                    }).map((_, index) => (
                      <CommunityCardImage
                        type={"RIT"}
                        id="hand-history-dummy-card"
                        key={"dummy-card-" + index}
                        // No src for dummy cards
                      />
                    ))}
                    {handHistory.details.ritCommunityCards.map(
                      (card, index) => {
                        return (
                          <CommunityCardImage
                            type={"RIT"}
                            id="hand-history-card"
                            src={cardThemes[cardDeckTheme][card]}
                            key={card + index + "handHistory-communityCards"}
                          />
                        );
                      }
                    )}
                  </CommunityCardsImageContainer>
                ) : null}
              </CommunityCardsImageContainer>
              <PotSummaryContainer>
                <PotAmountText>Total pot</PotAmountText>
                <PotAmountWrapper>
                  <ChipIcon src={chipIcon} />
                  <PotAmountInWrapper>{potAmount}</PotAmountInWrapper>
                </PotAmountWrapper>
              </PotSummaryContainer>
            </CommunityCardsAndPotWrapper>
          </CommunityCardsContainer>
        ) : null}

        <SummaryDetails
          ritCommunityCards={handHistory.details.ritCommunityCards?.length > 0}
        >
          {players.map((user, index) => {
            return returnPlayerSummary(
              user,
              index,
              index === activeUsers?.length - 1
            );
          })}
        </SummaryDetails>
      </>
    );
  };

  const retrieveIndexOfPlayerType = (type) => {
    let smallBlindPlayer;
    for (let user in activeUsers) {
      if (handHistory.details.playerDetails[activeUsers[user]]?.type === type) {
        smallBlindPlayer = activeUsers[user];
        break;
      }
    }

    let index = activeUsers.indexOf(smallBlindPlayer);
    return index;
  };

  const returnGameStageSection = (stage) => {
    function getPlayerActionsByStage(gameStage) {
      let data = handHistory.details.tableActionHistory;
      const playerActions = [];

      for (const playerName in data) {
        playerActions.push(
          ...data[playerName].filter(
            (action) => action.gameStatus === gameStage
          )
        );
      }

      // Sort actions by timestamp
      playerActions.sort((a, b) => a.timestamp - b.timestamp);

      return playerActions;
    }

    let players = getPlayerActionsByStage(stage);

    // let dealerIndex =
    //   stage === "Pre_FLOP"
    //     ? retrieveIndexOfPlayerType("BB")
    //     : retrieveIndexOfPlayerType("BTN");
    // let firstPlayerToActIndex;
    // let players = [...activeUsers];
    // // Need to delete first half and append to the end so that small blind starts first.
    // if (dealerIndex === players?.length) {
    //   firstPlayerToActIndex = 0;
    // } else {
    //   firstPlayerToActIndex = dealerIndex + 1;
    //   let firstHalf = players.splice(0, firstPlayerToActIndex);
    //   // if (players?.length > 0) {
    //   players = players.concat(firstHalf);
    //   // }
    // }
    return players?.length >= 1 ? (
      <>
        <SummaryDetails>
          {players.map((item, index) => {
            return returnPlayersActions(
              item,
              index,
              stage,
              index === activeUsers?.length - 1
            );
          })}
        </SummaryDetails>
      </>
    ) : null;
  };

  const returnPlayerSummary = (user, index, lastChild) => {
    let ritAmount1, ritAmount2, ritSummaryAmounts, ritPlayerHandRanks;
    let amount = handHistory.details.summaryAmounts[user];
    let type = handHistory.details.playerDetails[user].type;
    let playerHand = handHistory?.details?.handCards[user];
    let playerHandRanks = handHistory.details.playerHandRanks[user];
    ritSummaryAmounts = handHistory.details?.ritSummaryAmounts;
    if (ritSummaryAmounts) {
      ritAmount1 = handHistory?.details?.ritSummaryAmounts[user]?.run1;
      ritAmount2 = handHistory?.details?.ritSummaryAmounts[user]?.run2;
    }
    if (handHistory?.details?.ritPlayerHandRanks) {
      ritPlayerHandRanks = handHistory.details?.ritPlayerHandRanks[user];
    }

    if (!handHistory.details?.playerDetails[user]?.type) {
      return null;
    }
    // console.log('RIT Amount 1 is ', ritAmount2)
    return (
      <PlayerSummary
        lastChild={lastChild}
        key={"PlayerSummary-handhistory-" + index}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            gap: "12px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "4px",
              width: "100%",
            }}
          >
            <PlayerPosition type={type}>{type}</PlayerPosition>
            <PlayerNameBox>
              <PlayerName userName={user === playerName}>
                {getDisplayName(user, playerDisplayNameMap,loggedPlayer,displayName)}
              </PlayerName>
            </PlayerNameBox>
          </div>
          {/* {gameDefinition?.gameType !== TEXAS_HOLDEM ? ( */}
          <PlayerCards>
            {playerHand?.length > 0
              ? playerHand.map((hand, index) => {
                  return (
                    <PlayerCard
                      index={index}
                      key={"handHistory-PlayerCard-" + index}
                      src={cardThemes[cardDeckTheme][hand]}
                      gameType={gameDefinition?.gameType}
                    ></PlayerCard>
                  );
                })
              : Array(getNumberOfCardsBasedOnGameType(gameDefinition?.gameType))
                  .fill("cardBack")
                  .map((back, index) => {
                    return (
                      <PlayerCard
                        index={index}
                        key={"handHistory-backCard-" + user + "-index-" + index}
                        src={CardImages[cardBackTheme]}
                        gameType={gameDefinition?.gameType}
                      ></PlayerCard>
                    );
                  })}
          </PlayerCards>
          {/* // ) : null} */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            // alignItems: "center",
            justifyContent: "space-between",
            width: "50%",
            // background: 'black',
            // width: ritAmount1 ? "80%" : "50%",
            // height: "100%",
          }}
        >
          {/* {ritPlayerHandRanks ? ( */}
          <>
            <PlayerAmountsAndStrength>
              <PlayerAmount amount={ritAmount1 ? ritAmount1 : amount}>
                {parseDecimalValuesOfNumber(
                  ritAmount1 ? ritAmount1 : amount,
                  2,
                  checkToDisplayValueInK(ritAmount1 ? ritAmount1 : amount),
                  checkToDisplayValueInL(ritAmount1 ? ritAmount1 : amount)
                )}
              </PlayerAmount>
              <PlayerHandStrength>
                {getHandType(playerHandRanks)}
              </PlayerHandStrength>
            </PlayerAmountsAndStrength>{" "}
            {ritSummaryAmounts && ritAmount2 ? (
              <PlayerAmountsAndStrength>
                <PlayerAmount amount={ritAmount2}>
                  {parseDecimalValuesOfNumber(
                    ritAmount2,
                    2,
                    checkToDisplayValueInK(ritAmount2),
                    checkToDisplayValueInL(ritAmount2)
                  )}
                </PlayerAmount>
                <PlayerHandStrength>
                  {getHandType(ritPlayerHandRanks)}
                </PlayerHandStrength>
              </PlayerAmountsAndStrength>
            ) : null}
          </>
        </div>
      </PlayerSummary>
    );
  };

  const returnPlayersActions = (item, index, stage, lastChild) => {
    const returnActionIcon = (actionType) => {
      switch (actionType?.toLowerCase()) {
        case "call":
          return callIcon;
        case "fold":
          return foldIcon;
        case "raise":
          return raiseIcon;
        case "check":
          return checkIcon;
        case "all-in":
          return allInIcon;
      }
    };

    return (
      (item.action || item.allIn) && (
        <PlayerSummary lastChild={lastChild} key={"PlayerSummary" + index}>
          <PlayerPosition>{item.playerPositionName}</PlayerPosition>
          <PlayerNameBox>
            <PlayerName userName={item.playerName === playerName}>
              {getDisplayName(
                item.playerName,
                playerDisplayNameMap,
                loggedPlayer,
                displayName
              )}
            </PlayerName>
          </PlayerNameBox>
          <PlayerActionAndAmount>
            <PlayerActionBox
              actionType={item.allIn ? "all-in" : item.action.toLowerCase()}
            >
              <ActionIcon
                src={returnActionIcon(item.allIn ? "all-in" : item.action)}
              ></ActionIcon>
              <PlayerAction>
                {item.action === "all-in" || item.allIn
                  ? "All in"
                  : item.action}
              </PlayerAction>
            </PlayerActionBox>
            {item.amount !== null || item.amount !== undefined ? (
              <PlayerActionAmount>
                {item.action.toLowerCase() !== "fold" &&
                item.action.toLowerCase() !== "check"
                  ? "-" +
                    parseDecimalValuesOfNumber(
                      item.amount,
                      2,
                      checkToDisplayValueInK(item.amount),
                      checkToDisplayValueInL(item.amount)
                    )
                  : null}
              </PlayerActionAmount>
            ) : null}
          </PlayerActionAndAmount>
        </PlayerSummary>
      )
    );
  };

  const createEmptyHandHistoryNotice = () => {
    return (
      <EmptyContainer>
        <EmptyHandHistoryText>
          Hand history will be available after first game
        </EmptyHandHistoryText>
        {/* <EmptyHandHistoryText>Start playing!</EmptyHandHistoryText> */}
      </EmptyContainer>
    );
  };

  const createCollapseCard = () => {
    let cards = ["Summary", "Pre-Flop", "Flop", "Turn", "River"];
    // let actionHistoryKeys = Object.keys(handHistory.details.tableActionHistory);
    // let actionHistoryLength = 0;
    // Need to count the number of unique game stages.
    const uniqueGameStatuses = new Set();

    Object.values(handHistory.details.tableActionHistory).forEach(
      (playerActions) => {
        playerActions.forEach((action) => {
          uniqueGameStatuses.add(action.gameStatus);
        });
      }
    );

    const totalUniqueGameStatuses = uniqueGameStatuses.size;
    // This is removing unnecessary game stages that we do not require.
    // There is a +1 after actionHistoryLength because we always require Community Card Details / Summary. Minimum is PreFlop for Hand History.
    cards.splice(totalUniqueGameStatuses + 1, cards?.length);

    let communityCardDetails = returnCommunityCardsSection();
    let preflopDetails = returnGameStageSection("Pre_FLOP");
    let flopDetails = returnGameStageSection("FLOP");
    let turnDetails = returnGameStageSection("TURN");
    let riverDetails = returnGameStageSection("RIVER");

    let cardDetails = {
      Summary: communityCardDetails,
      "Pre-Flop": preflopDetails,
      Flop: flopDetails,
      Turn: turnDetails,
      River: riverDetails,
    };

    return (
      <HandSummaryWrapper>
        {Object.keys(cardDetails).map((item, i) => {
          if (cardDetails[item]) {
            return (
              <CollapseCard
                cardsProp={handHistory?.details?.communityCards}
                key={"handhistoryCollapseCard-" + i}
                cardLabel={
                  isPrivateTable && item === "Summary"
                    ? `${item} (Pvt Tables)`
                    : item
                }
                cardDetails={cardDetails[item]}
              ></CollapseCard>
            );
          } else {
            return null;
          }
        })}
      </HandSummaryWrapper>
    );
  };

  const handleReportIssue = () => {
    closeSliderProp();
    dispatch(
      openLocalGameModal({ type: REPORT_AN_ISSUE, tempTableId: activeGame })
    );
  };
  return (
    <Container
      onAnimationEnd={() => {
        if (sliderDirection === "close") {
          dispatch(closeSlider());
        }
        dispatch(updateSliderAnimatingFlag({ value: false }));
      }}
      sliderDirection={sliderDirection}
    >
      <RelativeWrapper>
        <HandHistoryTopSection>
          <HeaderWrapper>
            <HandHistoryTitle>
              {/* <HandHistoryIcon
                src={gameAssets.handHistoryIcon}
              ></HandHistoryIcon> */}
              Hand History
            </HandHistoryTitle>
            <HandHistoryClose
              src={close}
              onClick={() => closeSliderProp()}
            ></HandHistoryClose>
          </HeaderWrapper>

          <GameInfoContainer>
            <GameInfoWrapper>{createGameInfoDetails()}</GameInfoWrapper>
          </GameInfoContainer>
        </HandHistoryTopSection>

        {handHistoryLoading ? (
          <LoaderContainer>
            <Loader size={50} />
          </LoaderContainer>
        ) : (
          <>
            <HandSummaryMiddleSection
              happyHoursEnabled={handHistory?.details?.happyHoursEnabled}
              tableOrientation={tableOrientation}
            >
              {gameIds?.length > 0
                ? createCollapseCard()
                : createEmptyHandHistoryNotice()}
            </HandSummaryMiddleSection>
            {gameIds?.length > 0 ? (
              <SelectRoundWrapper
                isFunGame={isFunGame}
                tableOrientation={tableOrientation}
              >
                {/* <HandHistoryFooterText> */}
                {/* <div style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        color: "white",
                        fontSize: "12px",
                        marginBottom: "2px",
                      }}
                    >
                      Select the hand
                    </span>
                    <span style={{ color: "gray", fontSize: "8px" }}>
                      Showing the last 20 hands.
                    </span>
                  </div> */}
                {/* </HandHistoryFooterText> */}
                {}
                {isFunGame ? null : (
                  <ReportAnIssueContainer>
                    <ReportIssueText onClick={handleReportIssue}>
                      Report an issue
                    </ReportIssueText>
                    <LastHandsText>Showing upto 20 hands</LastHandsText>
                  </ReportAnIssueContainer>
                )}
                <PaginationContainer>
                  <SelectLeftRoundButton
                    onTouchStart={() => onRoundDecrementClick()}
                    handIndex={handIndex}
                    gamesLength={gameIds?.length - 1}
                    id="round-button-left"
                  >
                    <SelectLeftTriangle></SelectLeftTriangle>
                  </SelectLeftRoundButton>
                  <SelectRoundCenter>
                    <RoundInfoBox>
                      <RoundInfoDetails>
                        <HandRankingNumberText>
                          {handIndex + 1 + "/" + gameIds?.length}
                        </HandRankingNumberText>
                      </RoundInfoDetails>
                    </RoundInfoBox>
                  </SelectRoundCenter>
                  <SelectRightRoundButton
                    onTouchStart={() => onRoundIncrementClick()}
                    handIndex={handIndex}
                    gamesLength={gameIds?.length - 1}
                    id="round-button-right"
                  >
                    <SelectRightTriangle></SelectRightTriangle>
                  </SelectRightRoundButton>
                </PaginationContainer>
              </SelectRoundWrapper>
            ) : null}
          </>
        )}
      </RelativeWrapper>
    </Container>
  );
});
