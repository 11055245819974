import styled from "styled-components";
import colors from "./Colors";

export const BaseContoText = styled.span`
  font-family: "Conto";
`;
export const BaseContoMediumText = styled(BaseContoText)`
  font-weight: 500;
`;

export const BaseContoSemiBoldText = styled(BaseContoText)`
  font-weight: 600;
`;

export const BaseContoBoldText = styled(BaseContoText)`
  font-weight: 700;
`;

//6px
export const Typo6PureWhiteContoRegularText = styled(BaseContoText)`
  font-size: 6px;
  color: ${colors.pureWhite};
`;

export const Typo6WhiteContoRegularText = styled(BaseContoText)`
  font-size: 6px;
  color: ${colors.white};
`;

//8px
export const Typo8PureWhiteContoRegularText = styled(BaseContoText)`
  font-size: 8px;
  color: ${colors.pureWhite};
`;

export const Typo8WhiteTwoRegularText = styled(BaseContoText)`
  font-size: 8px;
  color: ${colors.whiteTwo};
`;

//10px
export const Typo10PureWhiteContoRegularText = styled(BaseContoText)`
  font-size: 10px;
  color: ${colors.pureWhite};
`;

export const Typo10PureWhiteContoMediumText = styled(BaseContoMediumText)`
  font-size: 10px;
  color: ${colors.pureWhite};
`;

export const Typo10MalachiteContoMediumText = styled(BaseContoMediumText)`
  font-size: 10px;
  color: ${colors.malachite};
`;

export const Typo10PastelGreenContoBoldText = styled(BaseContoBoldText)`
  font-size: 10px;
  color: ${colors.pastelGreen};
`;

export const Typo10GrayContoMediumText = styled(BaseContoMediumText)`
  font-size: 10px;
  color: ${colors.gray};
`;

export const Typo10LightYellowContoBoldText = styled(BaseContoBoldText)`
  font-size: 10px;
  color: ${colors.lightYellow};
`;

export const Typo10LightYellowContoMediumText = styled(BaseContoMediumText)`
  font-size: 10px;
  color: ${colors.lightYellow};
`;

export const Typo10GrayContoRegularText = styled(BaseContoText)`
  font-size: 10px;
  color: ${colors.gray};
`;

export const Typo10GrayContoBoldText = styled(BaseContoBoldText)`
  font-size: 10px;
  color: ${colors.gray};
`;

export const Typo10BlackContoBoldText = styled(BaseContoBoldText)`
  font-size: 10px;
  color: ${colors.black};
`;

export const Typo10OffWhiteContoRegularText = styled(BaseContoText)`
  font-size: 10px;
  color: ${colors.offWhite};
`;

export const Typo10PebbleGrayContoMediumText = styled(BaseContoMediumText)`
  font-size: 10px;
  color: ${colors.pebbleGray};
`;

export const Typo10PebbleGrayContoBoldText = styled(BaseContoBoldText)`
  font-size: 10px;
  color: ${colors.pebbleGray};
`;

export const Typo10WhiteContoRegularText = styled(BaseContoText)`
  font-size: 10px;
  color: ${colors.white};
`;

export const Typo10WhiteContoMediumText = styled(BaseContoMediumText)`
  font-size: 10px;
  color: ${colors.white};
`;

export const Typo10WhiteContoBoldText = styled(BaseContoBoldText)`
  font-size: 10px;
  color: ${colors.white};
`;

export const Typo10SecondaryRedContoMediumText = styled(BaseContoMediumText)`
  font-size: 10px;
  color: ${colors.secondaryRed};
`;

export const Typo10CarnationContoBoldText = styled(BaseContoBoldText)`
  font-size: 10px;
  color: ${colors.carnation};
`;

export const Typo10CloudyContoBoldText = styled(BaseContoBoldText)`
  font-size: 10px;
  color: ${colors.cloudy};
`;

export const Typo10SilverChaliceContoMediumText = styled(BaseContoMediumText)`
  font-size: 10px;
  color: ${colors.silverChalice};
`;

export const Typo10SilverChaliceContoRegularText = styled(BaseContoText)`
  font-size: 10px;
  color: ${colors.silverChalice};
`;

export const Typo10OffWhiteContoMediumText = styled(BaseContoMediumText)`
  font-size: 10px;
  color: ${colors.offWhite};
`;

export const Typo10TourneySecondaryGreenMediumText = styled(
  BaseContoMediumText
)`
  font-size: 10px;
  color: ${colors.tourneySecondaryGreen};
`;

export const Typo10WhiteTwoBoldText = styled(BaseContoBoldText)`
  font-size: 10px;
  color: ${colors.whiteTwo};
`;

export const Typo10YellowOrangeBoldText = styled(BaseContoBoldText)`
  font-size: 10px;
  color: ${colors.yellowOrange};
`;

//11px

export const Typo11TourneySecondaryGreenMediumText = styled(
  BaseContoMediumText
)`
  font-size: 11px;
  color: ${colors.tourneySecondaryGreen};
`;

export const Typo11PrimaryGoldMediumText = styled(BaseContoMediumText)`
  font-size: 11px;
  color: ${colors.primaryGold};
`;

export const Typo11OffWhiteContoBoldText = styled(BaseContoBoldText)`
  font-size: 11px;
  color: ${colors.offWhite};
`;

export const Typo11LightYellowContoMediumText = styled(BaseContoMediumText)`
  font-size: 11px;
  color: ${colors.lightYellow};
`;
export const Typo11WhiteContoBoldText = styled(BaseContoBoldText)`
  font-size: 11px;
  color: ${colors.white};
`;
export const Typo11GrayContoMediumText = styled(BaseContoMediumText)`
  font-size: 11px;
  color: ${colors.gray};
`;

export const Typo11GrayContoRegularText = styled(BaseContoText)`
  font-size: 11px;
  color: ${colors.gray};
  font-weight: 400;
`;

export const Typo11DoveGrayContoMediumText = styled(BaseContoMediumText)`
  font-size: 11px;
  color: ${colors.doveGray};
`;

export const Typo11PrimaryRedContoMediumText = styled(BaseContoMediumText)`
  font-size: 11px;
  color: ${colors.primaryRed};
`;

export const Typo11TourneyBonusBarFilledContoBoldText = styled(
  BaseContoBoldText
)`
  font-size: 11px;
  color: ${colors.tourneyBonusBarFilled};
`;

export const Typo11PebbleGrayContoBoldText = styled(BaseContoBoldText)`
  font-size: 11px;
  color: ${colors.pebbleGray};
`;

export const Typo11DownyContoMediumText = styled(BaseContoMediumText)`
  font-size: 11px;
  color: ${colors.downy};
`;

//12px
export const Typo12WhiteTwoContoMediumText = styled(BaseContoMediumText)`
  font-size: 12px;
  color: ${colors.whiteTwo};
`;

export const Typo12WhiteTwoContoBoldText = styled(BaseContoBoldText)`
  font-size: 12px;
  color: ${colors.whiteTwo};
`;

export const Typo12TourneySecondaryGreenMediumText = styled(
  BaseContoMediumText
)`
  font-size: 12px;
  color: ${colors.tourneySecondaryGreen};
`;

export const Typo12CasalContoMediumText = styled(BaseContoMediumText)`
  font-size: 12px;
  color: ${colors.casal};
`;

export const Typo12WhiteContoBoldText = styled(BaseContoBoldText)`
  font-size: 12px;
  color: ${colors.white};
`;

export const Typo12PureWhiteContoBoldText = styled(BaseContoBoldText)`
  font-size: 12px;
  color: ${colors.pureWhite};
`;

export const Typo12WhiteContoMediumText = styled(BaseContoMediumText)`
  font-size: 12px;
  color: ${colors.white};
`;
export const Typo12LightYellowContoMediumText = styled(BaseContoMediumText)`
  font-size: 12px;
  color: ${colors.lightYellow};
`;
export const Typo12OffWhiteContoMediumText = styled(BaseContoMediumText)`
  font-size: 12px;
  color: ${colors.offWhite};
`;
export const Typo12BlueContoBoldText = styled(BaseContoBoldText)`
  font-size: 12px;
  color: ${colors.primaryBlue};
`;

export const Typo12OffWhiteContoBoldText = styled(BaseContoBoldText)`
  font-size: 12px;
  color: ${colors.offWhite};
`;

export const Typo10PrimaryGoldContoMediumText = styled(BaseContoMediumText)`
  font-size: 10px;
  color: ${colors.primaryGold};
`;
export const Typo12PrimaryGoldContoRegularText = styled(BaseContoText)`
  font-size: 12px;
  color: ${colors.primaryGold};
`;

export const Typo12PrimaryGoldContoMediumText = styled(BaseContoMediumText)`
  font-size: 12px;
  color: ${colors.primaryGold};
`;
export const Typo12PrimaryGoldContoBoldText = styled(BaseContoBoldText)`
  font-size: 12px;
  color: ${colors.primaryGold};
`;
export const Typo12LeafyGreenContoMediumText = styled(BaseContoMediumText)`
  font-size: 12px;
  color: ${colors.leafyGreen};
`;

export const Typo12GrayContoRegularText = styled(BaseContoText)`
  font-size: 12px;
  color: ${colors.gray};
`;

export const Typo12WhiteContoRegularText = styled(BaseContoText)`
  font-size: 12px;
  color: ${colors.white};
`;

export const Typo12GoldContoRegularText = styled(BaseContoText)`
  font-size: 12px;
  color: ${colors.primaryGold};
`;

export const Typo12GreenContoMediumText = styled(BaseContoMediumText)`
  font-size: 12px;
  color: ${colors.winnerCardHighLight};
`;

export const Typo12PebbleGrayContoMediumText = styled(BaseContoMediumText)`
  font-size: 12px;
  color: ${colors.pebbleGray};
`;
export const Typo12PebbleGrayContoRegularText = styled(BaseContoText)`
  font-size: 12px;
  color: ${colors.pebbleGray};
`;

export const Typo12SecondaryRedContoMediumText = styled(BaseContoMediumText)`
  font-size: 12px;
  color: ${colors.secondaryRed};
`;

export const Typo12SecondaryLightCornFlowerBlueContoMediumText = styled(
  BaseContoMediumText
)`
  font-size: 12px;
  color: ${colors.lightCornflowerBlue};
`;

export const Typo12GrayContoMediumText = styled(BaseContoMediumText)`
  font-size: 12px;
  color: ${colors.gray};
`;

export const Typo12GrayContoBoldText = styled(BaseContoBoldText)`
  font-size: 12px;
  color: ${colors.gray};
`;

export const Typo12SecondaryLeafyGreenContoMediumText = styled(
  BaseContoMediumText
)`
  font-size: 12px;
  color: ${colors.leafyGreen};
`;

export const Typo12SecondaryBrandyPunchContoMediumText = styled(
  BaseContoMediumText
)`
  font-size: 12px;
  color: ${colors.brandyPunch};
`;

export const Typo12DoveGrayContoBoldText = styled(BaseContoBoldText)`
  font-size: 12px;
  color: ${colors.doveGray};
`;

// 13px

export const Typo13WhiteTwoContoMediumText = styled(BaseContoMediumText)`
  font-size: 13px;
  color: ${colors.whiteTwo};
`;

export const Typo13PebbleGrayContoMediumText = styled(BaseContoMediumText)`
  font-size: 13px;
  color: ${colors.pebbleGray};
`;

export const Typo13DeepTealContoRegularText = styled(BaseContoText)`
  font-size: 13px;
  color: ${colors.deepTeal};
`;

export const Typo13DeepTealContoBoldText = styled(BaseContoBoldText)`
  font-size: 13px;
  color: ${colors.deepTeal};
`;

//14px
export const Typo14WhiteContoBoldText = styled(BaseContoSemiBoldText)`
  font-size: 14px;
  color: ${colors.white};
`;

export const Typo14LeafyGreenContoBoldText = styled(BaseContoBoldText)`
  font-size: 14px;
  color: ${colors.leafyGreen};
`;

export const Typo14LightYellowContoBoldText = styled(BaseContoBoldText)`
  font-size: 14px;
  color: ${colors.lightYellow};
`;

export const Typo14LightYellowContoMediumText = styled(BaseContoMediumText)`
  font-size: 14px;
  color: ${colors.lightYellow};
`;

export const Typo14OffWhiteContoBoldText = styled(BaseContoSemiBoldText)`
  font-size: 14px;
  color: ${colors.offWhite};
`;

export const Typo14OffWhiteContoMediumText = styled(BaseContoMediumText)`
  font-size: 14px;
  color: ${colors.offWhite};
`;
export const Typo14OffWhiteContoRegularText = styled(BaseContoMediumText)`
  font-size: 14px;
  color: ${colors.offWhite};
`;

export const Typo14WhiteContoMediumText = styled(BaseContoMediumText)`
  font-size: 14px;
  color: ${colors.white};
`;

export const Typo14WhiteContoRegularText = styled(BaseContoText)`
  font-size: 14px;
  color: ${colors.white};
`;

export const Typo14PrimaryGoldContoMediumText = styled(BaseContoMediumText)`
  font-size: 14px;
  color: ${colors.primaryGold};
`;

export const Typo14PureWhiteContoMediumText = styled(BaseContoMediumText)`
  font-size: 14px;
  color: ${colors.pureWhite};
`;

export const Typo14PureWhiteContoBoldText = styled(BaseContoBoldText)`
  font-size: 14px;
  color: ${colors.pureWhite};
`;

export const Typo14PrimaryGoldContoBoldText = styled(BaseContoBoldText)`
  font-size: 14px;
  color: ${colors.primaryGold};
`;

export const Typo14CasalContoMediumText = styled(BaseContoMediumText)`
  font-size: 14px;
  color: ${colors.casal};
`;

export const Typo14SecondaryRedContoMediumText = styled(BaseContoMediumText)`
  font-size: 14px;
  color: ${colors.secondaryRed};
`;

export const Typo14BlueContoBoldText = styled(BaseContoMediumText)`
  font-size: 14px;
  color: ${colors.primaryBlue};
`;

export const Typo14GrayContoMediumText = styled(BaseContoMediumText)`
  font-size: 14px;
  color: ${colors.gray};
`;

export const Typo13PrimaryBlueContoMediumText = styled(BaseContoMediumText)`
  font-size: 13px;
  color: ${colors.primaryBlue};
`;

//15px
export const Typo15GrayContoMediumText = styled(BaseContoMediumText)`
  font-size: 15px;
  color: ${colors.gray};
`;

export const Typo15PrimaryGoldContoMediumText = styled(BaseContoMediumText)`
  font-size: 15px;
  color: ${colors.primaryGold};
`;

export const Typo15LightCornflowerBlueBoldText = styled(BaseContoBoldText)`
  font-size: 15px;
  color: ${colors.lightCornflowerBlue};
`;

export const Typo15WhiteContoMediumText = styled(BaseContoMediumText)`
  font-size: 15px;
  color: ${colors.white};
`;

//16px
export const Typo16WhiteContoBoldText = styled(BaseContoBoldText)`
  font-size: 16px;
  color: ${colors.white};
`;

export const Typo16WhiteTwoContoBoldText = styled(BaseContoBoldText)`
  font-size: 16px;
  color: ${colors.whiteTwo};
`;

export const Typo16WhiteContoMediumText = styled(BaseContoMediumText)`
  font-size: 16px;
  color: ${colors.white};
`;

export const Typo16OffWhiteContoBoldText = styled(BaseContoBoldText)`
  font-size: 16px;
  color: ${colors.offWhite};
`;

export const Typo16PrimaryGoldBoldText = styled(BaseContoBoldText)`
  font-size: 16px;
  color: ${colors.primaryGold};
`;
export const Typo16PrimaryGoldMediumText = styled(BaseContoMediumText)`
  font-size: 16px;
  color: ${colors.primaryGold};
`;

//18px
export const Typo18GrayContoMediumText = styled(BaseContoMediumText)`
  font-size: 18px;
  color: ${colors.gray};
`;

export const Typo18WhiteContoMediumText = styled(BaseContoMediumText)`
  font-size: 18px;
  color: ${colors.white};
`;
export const Typo18WhiteContoBoldText = styled(BaseContoBoldText)`
  font-size: 18px;
  color: ${colors.white};
`;

//24
export const Typo24GrayContoMediumText = styled(BaseContoMediumText)`
  font-size: 24px;
  color: ${colors.gray};
`;

export const TypoGoldGradientMediumText = styled(BaseContoMediumText)`
  font-size: ${(props) => (props.size ? props.size : 12)}px;
  font-weight: ${(props) => (props.weight ? props.weight : 500)};
  background: linear-gradient(
    91.77deg,
    #eabf69 0.39%,
    #fffdea 50.39%,
    #eabf69 100.39%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  letter-spacing: ${props => props.spacing ? props.spacing+"px" : "normal"};
`;
