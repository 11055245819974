//TourneyFlightStackDetailsAckHandler.js

import Handler from "../Handler";

class TourneyFlightStackDetailsAckHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processFlightStackDetailsAck(receivedData);
  }
}
export default TourneyFlightStackDetailsAckHandler;

// FlightStackDetailsA#{double buyIn, int numberOfEntries, int numberOfReEntries, int numberOfQualifiedPlayers, double minStack, double maxStack, double averageStack, long startedDate, long endedDate, boolean qualified, double qualifiedStack}
