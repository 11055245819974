import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import winnerTrophyLottie from "./winner-trophy.json";

import trophyIcon from "../../../assets/gameTable/TourneyTrophy.svg";

import {
  closeLocalGameModal,
  deleteGameTable,
  hideGameTable,
  resetTableData,
} from "../../redux/slices/gameTableSlice";
import {
  Container,
  ModalWrapper,
  ModalHeader,
  HeaderTitleText,
  ModalBody,
  IconWrapper,
  MessageText,
  CTAContainer,
  PrimaryCTAButton,
  ButtonText,
  LottieWrapper,
  NoteText,
  MessageWrapper,
  MessageHighLightText,
  SecondaryCTAButton,
} from "./tourneyWinnerModalStyles";
import {
  closeTourneyInfoPage,
  requestTourneyEntriesDynamicData,
  requestTourneyInfoData,
  requestTourneyTablesDynamicData,
} from "../../redux/slices/lobbySlice";
import TableUtils from "../../utils/TableUtils";
import { sendFaroLog } from "../../../Common/utils/FaroUtil";
import { TOURNEY_INFO_TABLES_TAB } from "../../data/Constants";
import {
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";

function TourneyWinnerModal(props) {
  const dispatch = useDispatch();

  const tourneyPrizeDetails = useSelector(
    (state) => state.gameTable.games[props.tempTableId].tourneyPrizeDetails
  );

  const tourneyGameCompleted = useSelector(
    (state) => state.gameTable.games[props.tempTableId].tourneyGameCompleted
  );
  // const playerName = useSelector((state) => state.lobby.player.userName);
  const tourneyId = useSelector(
    (state) => state.gameTable.games[props.tempTableId].gameDefinition.tourneyId
  );
  const masterTourneysData = useSelector(
    (state) => state.lobby.tourneys.masterTourneysData
  );

  const lottieAnimationRef = useRef(null);

  // const handleModalClose = () => {
  //   TableUtils.getInstance().overrideRemovedTableObserver(props.tempTableId);
  //   dispatch(
  //     resetTableData({ tempTableId: props.tempTableId, isTourneyGame: true })
  //   );
  //   sendFaroLog({
  //     type: "Initiate Watch player channel connection at tourney winner",
  //     tempTableId: props?.tempTableId,
  //   });
  //   dispatch(closeLocalGameModal({ tempTableId: props.tempTableId }));
  // };

  const getTourneyDefinitionByTourneyId = (tourneyId) => {
    const tourney = masterTourneysData.get(tourneyId);
    return tourney ? tourney : false;
  };

  const handlePrimaryCTAClick = () => {
    // handleModalClose();
    dispatch(closeLocalGameModal({ tempTableId: props.tempTableId }));
    dispatch(closeTourneyInfoPage());
    dispatch(hideGameTable());
    dispatch(deleteGameTable({ tempTableId: props.tempTableId }));
    sendFaroLog({
      type: "Exit Table from tourney winner popup",
      tempTableId: props?.tempTableId,
    });
    // dispatch(
    //   exitGameTable({ tempTableId: props.tempTableId, playerName: playerName })
    // );
  };

  const handleSpectateClick = () => {
    let gameDefinition = getTourneyDefinitionByTourneyId(tourneyId);
    if (gameDefinition) {
      dispatch(closeLocalGameModal({ tempTableId: props.tempTableId }));
      TableUtils.getInstance().removeTableObserver(props.tempTableId, true);
      dispatch(hideGameTable());
      dispatch(
        requestTourneyInfoData({
          tourneyId: gameDefinition?.tourneyId,
          defaultSelectedTab: TOURNEY_INFO_TABLES_TAB,
        })
      );
      dispatch(
        requestTourneyEntriesDynamicData({
          tourneyId: tourneyId,
        })
      );
      dispatch(requestTourneyTablesDynamicData({ tourneyId: tourneyId }));
    }
  };

  return (
    <Container>
      <ModalWrapper>
        <ModalHeader>
          <HeaderTitleText>Congratulations</HeaderTitleText>
          {/* {!tourneyGameCompleted && (
            <CloseIcon src={closeIcon} onClick={handleModalClose} />
          )} */}
        </ModalHeader>

        <ModalBody>
          <IconWrapper src={trophyIcon} />
          <LottieWrapper
            style={{
              width: "150px",
              height: "150px",
            }}
            animationData={winnerTrophyLottie}
            ref={lottieAnimationRef.current}
            autoPlay={true}
            loop={true}
          />

          {Object.keys(tourneyPrizeDetails?.flightChildWinnerAck || {}).length >
          0 ? (
            <MessageWrapper>
              <MessageText>You’ve qualified for the</MessageText>
              <MessageHighLightText>
                {tourneyPrizeDetails.flightChildWinnerAck
                  ?.destinationTourneyName || "Tourney"}
              </MessageHighLightText>
              <MessageText>
                {`Make sure to join the felts back on time.`}
              </MessageText>
              <MessageText>{"Good Luck on the tables!"}</MessageText>
            </MessageWrapper>
          ) : (
            <MessageWrapper>
              <MessageText>
                You have finished the tournament in place
              </MessageText>
              {tourneyPrizeDetails?.rank > 0 && (
                <MessageHighLightText>{`#${tourneyPrizeDetails?.rank}`}</MessageHighLightText>
              )}
              <MessageText>with</MessageText>
              {tourneyPrizeDetails?.playerWinning > 0 && (
                <MessageHighLightText>{` ₹${parseDecimalValuesOfNumber(
                  tourneyPrizeDetails?.playerWinning,
                  2,
                  checkToDisplayValueInK(tourneyPrizeDetails?.playerWinning),
                  checkToDisplayValueInL(tourneyPrizeDetails?.playerWinning)
                )}`}</MessageHighLightText>
              )}
              {Object.keys(tourneyPrizeDetails?.ticketWonDetails || {}).length >
                0 && (
                <>
                  {tourneyPrizeDetails?.playerWinning > 0 && (
                    <MessageText>and</MessageText>
                  )}
                  <MessageHighLightText>Ticket</MessageHighLightText>
                  <MessageText>to the</MessageText>
                  <MessageHighLightText>
                    {tourneyPrizeDetails?.ticketWonDetails?.tourneyName ||
                      "Unknown Tourney"}
                  </MessageHighLightText>
                  <MessageText>Tourney</MessageText>
                </>
              )}
              {Object.keys(tourneyPrizeDetails?.flightChildWinnerAck || {})
                .length > 0 && (
                <>
                  {tourneyPrizeDetails?.playerWinning > 0 && (
                    <MessageText>and</MessageText>
                  )}
                  <MessageHighLightText>Ticket</MessageHighLightText>
                  <MessageText>to the</MessageText>
                  <MessageHighLightText>
                    {tourneyPrizeDetails.flightChildWinnerAck
                      ?.destinationTourneyName || "Unknown Tourney"}
                  </MessageHighLightText>
                  <MessageText>Tourney</MessageText>
                </>
              )}
            </MessageWrapper>
          )}
          {/* <NoteText>
            Note: Winnings undergo fraud checks and may take up to 24 hours to
            credit.
          </NoteText> */}
          {tourneyGameCompleted ? (
            <CTAContainer>
              <PrimaryCTAButton onClick={handlePrimaryCTAClick}>
                <ButtonText>Exit Table</ButtonText>
              </PrimaryCTAButton>
            </CTAContainer>
          ) : (
            <CTAContainer>
              <SecondaryCTAButton onClick={handlePrimaryCTAClick}>
                <ButtonText>Exit Table</ButtonText>
              </SecondaryCTAButton>

              <PrimaryCTAButton onClick={handleSpectateClick}>
                <ButtonText>Spectate</ButtonText>
              </PrimaryCTAButton>
            </CTAContainer>
          )}
        </ModalBody>
      </ModalWrapper>
    </Container>
  );
}

export default TourneyWinnerModal;
