import React, { useEffect, useRef, useState } from "react";
import {
  ButtonText,
  CloseIcon,
  CTAContainer,
  HeaderTitleText,
  ModalBody,
  ModalHeader,
  ModalWrapper,
  PrimaryCTAButton,
} from "../../../../Poker/components/ReportIssue/reportIssueStyles";
import { AnnouncementMessage, Container, TextArea } from "./AnnouncementStyle";
import closeIcon from "../../../../assets/common/close.svg";
import { useDispatch } from "react-redux";
import { closeLocalGameModal } from "../../../../Poker/redux/slices/gameTableSlice";
import { Loader } from "../../../../Poker/components/Loader/Loader";
import { sendAnnouncementProtocol } from "../../../../Poker/utils/ProtocolUtils";

export const Announcement = (props) => {
  const { tempTableId, modalConfig } = props;
  const dispatch = useDispatch();

  const [announcementText, setAnnouncementText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const timeOut = useRef(null);

  useEffect(() => {
    if (modalConfig?.autoCloseDuration) {
      timeOut.current = setTimeout(() => {
        handleModalClose();
      }, modalConfig.autoCloseDuration);
    }

    return () => {
      clearTimeout(timeOut.current);
    };
  }, [modalConfig]);

  useEffect(() => {
    if (modalConfig?.announcementMessage || announcementText) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [announcementText, modalConfig?.announcementMessage]);

  const handleModalClose = () => {
    dispatch(closeLocalGameModal({ tempTableId: props.tempTableId }));
  };

  const handleAnnouncementTextChange = (e) => {
    setAnnouncementText(e.target.value);
  };

  const handleClick = () => {
    if (modalConfig?.announcementMessage) {
      handleModalClose();
    } else {
      setIsLoading(true);
      sendAnnouncementProtocol({
        tempTableId: tempTableId,
        message: announcementText,
      });
    }
  };

  return (
    <Container>
      <ModalWrapper>
        <ModalHeader>
          <HeaderTitleText>Announcement</HeaderTitleText>
          <CloseIcon src={closeIcon} onClick={handleModalClose} />
        </ModalHeader>
        <ModalBody>
          {modalConfig?.announcementMessage ? (
            <AnnouncementMessage>
              {modalConfig.announcementMessage}
            </AnnouncementMessage>
          ) : (
            <TextArea
              type={"text"}
              placeholder={"Type your notes here... (Upto 500 characters)"}
              maxLength={500}
              value={
                modalConfig?.announcementMessage
                  ? modalConfig?.announcementMessage
                  : announcementText
              }
              onChange={handleAnnouncementTextChange}
            ></TextArea>
          )}
        </ModalBody>
        <CTAContainer>
          <PrimaryCTAButton
            onClick={handleClick}
            disabled={isLoading || isDisabled}
          >
            {isLoading ? (
              <Loader size={20} />
            ) : (
              <ButtonText>
                {modalConfig?.announcementMessage ? "OK" : "Publish"}
              </ButtonText>
            )}
          </PrimaryCTAButton>
        </CTAContainer>
      </ModalWrapper>
    </Container>
  );
};
