import React, { useState } from "react";

import goldTicket from "../../../assets/lobby/GoldTicket.svg";
import goldGloryIcon from "../../../assets/lobby/goldGloryIcon.png";
import RightLeafyGreenArrow from "../../../assets/lobby/RightLeafyGreenArrow.png";
import GrayAccordianArrow from "../../../assets/lobby/GrayAccordianArrow.png";

import GoldRoundShimmer from "../../../assets/lobby/GoldRoundShimmer.png";
import FlightTickets from "../../../assets/lobby/FlightTickets.png";
import GameConsole from "../../../assets/lobby/GameConsole.png";
import CloseIcon from "../../../assets/lobby/CloseIcon.svg";

import {
  Container,
  TourneyStartsInAndPrizeAmountWrapper,
  PrizeAmountContainer,
  TrophyIconWrapper,
  PrizeAmountWrapper,
  PrizeWorthText,
  PrizeAmountText,
  PrizeBreakupText,
  AddIconText,
  TicketsCountText,
  TicketIcon,
  FlightTourneysTabbarAndTableWrapper,
  FlightsBasicTabBarrContainer,
  FlightTourneyTabItem,
  FlightTourneyTabItemText,
  FlightTourneyDetailsTableContainer,
  TableHeader,
  TableHeaderText,
  Column,
  TableRow,
  TableItemValueText,
  RunningStatusWrapper,
  DetailsWrapper,
  DetailsTextWrapper,
  StatusAccordianIconWrapper,
  ChildFlightTourneyDetailsWrapper,
  TableRowDetailsAndChildTourneyWrapper,
  TableColumnElementsWrapper,
  ChildFlightTourneyDetails,
  ChildTourneyStackDetailsWrapper,
  ChildTourneyStackItemWrapper,
  StackDetailsText,
  StackDetailsValueText,
  TourneyStartAndEndTimeWrapper,
  TourneyTimeWrapper,
  EmptyTourneysContainer,
  GameConsoleImageWrapper,
  EmptyTourneysText,
  BlindsRightArrow,
  ModalContainer,
  QualifiedPlayersModalContainer,
  Header,
  HeaderText,
  CloseIconWrapper,
  ModalBodyWrapper,
  TableWrapper,
  SectionWrapper,
  SectionIconWrapper,
  SectionTitleText,
  CompletedWrapper,
  CompletedTextWrapper,
  AccordianIconWrapper,
  ParentPrizeWorthText,
  LoaderContainer,
} from "./tourneyFlightsDetailsStyles";
import {
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  TOURNEY_INFO_FLIGHTS_TAB,
  TOURNEY_STATUS_CANCELLED,
  TOURNEY_STATUS_COMPLETED,
  TOURNEY_STATUS_LIVE,
  TOURNEY_STATUS_MISSED,
  TOURNEY_STATUS_WON,
} from "../../data/Constants";
import TourneyFlightParentAndSimilarFlightsCard from "../TourneyFlightParentAndSimilarFlightsCard/TourneyFlightParentAndSimilarFlightsCard";
import FlightTourneyCompletedInfoCard from "../FlightTourneyCompletedInfoCard/FlightTourneyCompletedInfoCard";
import TourneyFlightsPlaceholder from "../TourneyFlightsPlaceholder/TourneyFlightsPlaceholder";
import colors from "../../../Common/styleGuide/Colors";
import {
  requestFlightTourneyQualifiedPlayers,
  updateSelectedFlightDetailsTabbar,
} from "../../redux/slices/lobbySlice";
import Skeleton from "react-loading-skeleton";
import { Loader } from "../Loader/Loader";
import moment from "moment";

function TourneyFlightsDetails(props) {
  const {
    buyIn,
    tourneyRake,
    registeredPlayerCount,
    maxPlayers,
    displayWinners,
    totalAddOns,
    addOnAmount,
    rebuyFee,
    startingStack,
    playersPerTable,
    prizePool,
    currentLevel,
    nextLevel,
    nextBreak,
    highestStack,
    blindStructure,
    tourneyStartTime,
    tourneyStatus,
    lateRegistrationAllowed,
    maxRebuyCount,
    tourneyRules,
    winnersPayoutWithBonus,
    breakEven,
    dynamicPoolPrize,
    tournamentType,
    lateRegistrationDuration,
    rebuyLevel,
    childTourneys,
    prizePoolTickets,
    lateRegEnded,
    reEntry,
    parentTourney,
    levelDuration,
    totalAddOnCount,
    totalRebuyCount,
    totalReEntryCount,
    isAlreadyRegisteredTourney,
    tourneyId,
    prizePoolType,
    payoutType,
    hideRegistrationCTAButtons,
    tourneyIdentifier,
    activePlayerForTourney,
    breakInProgress,
    breakEndTime,
    averageChips,
    lateRegEndTime,
    depositTourneyFlag,
    allowedFreeRegistrations,
    allowedTicketRegistrations,
    isFlightTourney,
    isFlightParentTourney,
    similarFlights,
    parentTourneyName,
    qualifiedPlayers,
  } = props.tourneyDetails;
  const dispatch = useDispatch();
  const masterTourneysData = useSelector(
    (state) => state.lobby.tourneys.masterTourneysData
  );
  let formatString = "DD MMM, hh:mmA";

  const selectedFlightDetailsTabbar = useSelector(
    (state) => state.lobby.selectedFlightDetailsTabbar
  );

  const [expandedFlightTourneyDetailsId, setExpandedFlightTourneyDetailsId] =
    useState(0);

  const [openQualifiedPlayersModal, setOpenQualifiedPlayersModal] = useState({
    isOpen: false,
    tourneyId: null,
  });

  const navigateToFlightsTab = () => {
    props.handleTourneysTabChange(TOURNEY_INFO_FLIGHTS_TAB);
  };

  const handleOpenQualifiedPlayersModal = (tourneyId) => {
    dispatch(requestFlightTourneyQualifiedPlayers({ tourneyId: tourneyId }));
    setOpenQualifiedPlayersModal({
      isOpen: true,
      tourneyId: tourneyId,
    });
  };

  const closeQualifiedPlayersModal = () => {
    setOpenQualifiedPlayersModal({
      isOpen: false,
      tourneyId: null,
    });
  };

  const formatTourneyDate = (timeStamp, formatString) => {
    try {
      return moment.unix(timeStamp).format(formatString);
    } catch (error) {
      console.warn("ERROR IN FORMATTING DATE IN TOURNEY GAME TILE:", error);
    }
  };

  const renderChildFlightTourneyDetails = (tourneyDetails) => {
    const { tourneyName, buyIn, tourneyRake, tourneyStatus } = tourneyDetails;
    console.log(
      "GANESH_LOGS",
      tourneyDetails.tourneyIdentifier === "completedTourneys"
    );
    return (
      <TableRow>
        <TableRowDetailsAndChildTourneyWrapper>
          <TableColumnElementsWrapper>
            <Column width={"25%"}>
              <TableItemValueText>{tourneyName}</TableItemValueText>
            </Column>
            <Column width={"20%"}>
              <TableItemValueText>{`₹${
                buyIn + tourneyRake
              }`}</TableItemValueText>
            </Column>
            <Column width={"30%"}>
              <TableItemValueText>
                {` ${formatTourneyDate(
                  tourneyDetails.tourneyStartTime,
                  formatString
                )}`}
              </TableItemValueText>
            </Column>
            <Column width={"25%"}>
              {/* <TableItemValueText>Details</TableItemValueText> */}
              <RunningStatusWrapper>
                {tourneyDetails.tourneyIdentifier === "completedTourneys" ? (
                  <CompletedWrapper
                    onClick={() => {
                      if (
                        tourneyDetails.tourneyStatus !==
                        TOURNEY_STATUS_CANCELLED
                      ) {
                        setExpandedFlightTourneyDetailsId(
                          expandedFlightTourneyDetailsId ===
                            tourneyDetails.tourneyId
                            ? null
                            : tourneyDetails.tourneyId
                        );
                      }
                    }}
                  >
                    <CompletedTextWrapper>
                      {tourneyDetails.tourneyStatus !== TOURNEY_STATUS_CANCELLED
                        ? "Completed"
                        : "Cancelled"}
                    </CompletedTextWrapper>
                    {tourneyDetails.tourneyStatus !==
                      TOURNEY_STATUS_CANCELLED && (
                      <AccordianIconWrapper
                        isOpen={
                          expandedFlightTourneyDetailsId ===
                          tourneyDetails.tourneyId
                        }
                        src={GrayAccordianArrow}
                      />
                    )}
                  </CompletedWrapper>
                ) : (
                  <DetailsWrapper
                    onClick={() => {
                      setExpandedFlightTourneyDetailsId(null);
                      handleTourneyNavigation(tourneyDetails.tourneyId);
                    }}
                  >
                    <DetailsTextWrapper>Details</DetailsTextWrapper>
                    <StatusAccordianIconWrapper src={RightLeafyGreenArrow} />
                  </DetailsWrapper>
                )}
              </RunningStatusWrapper>
            </Column>
          </TableColumnElementsWrapper>
          {expandedFlightTourneyDetailsId === tourneyDetails.tourneyId && (
            <ChildFlightTourneyDetailsWrapper
              isOpen={
                expandedFlightTourneyDetailsId === tourneyDetails.tourneyId
              }
            >
              <FlightTourneyCompletedInfoCard
                tourneyDetails={tourneyDetails}
                isSatelliteTourney={selectedFlightDetailsTabbar !== "similar"}
                handleQualifiedPlayers={handleOpenQualifiedPlayersModal}
              />
            </ChildFlightTourneyDetailsWrapper>
          )}
        </TableRowDetailsAndChildTourneyWrapper>
      </TableRow>
    );
  };

  const getTourneyDefinitionByTourneyId = (tourneyId) => {
    const tourney = masterTourneysData.get(tourneyId);
    return tourney ? tourney : false;
  };

  const handleTourneyNavigation = (tourneyId) => {
    props.handleTourneyNavigation && props.handleTourneyNavigation(tourneyId);
  };

  const handleParentTourneyNavigation = () => {
    props.handleTourneyNavigation &&
      props.handleTourneyNavigation(parentTourney);
  };

  const renderFlightTourneysDetails = (selectedTabBar) => {
    const tourneyIdsList =
      selectedTabBar === "similar" ? similarFlights : childTourneys;
    const tourneyDefinitions = tourneyIdsList.map((tourneyDetails) =>
      getTourneyDefinitionByTourneyId(tourneyDetails)
    );

    if (tourneyDefinitions.length === 0) {
      return selectedTabBar !== "similar" ? (
        <EmptyTourneysContainer>
          <GameConsoleImageWrapper src={GameConsole} />
          <EmptyTourneysText>
            All Satellites for this tournament have successfully concluded
          </EmptyTourneysText>
        </EmptyTourneysContainer>
      ) : (
        <TourneyFlightsPlaceholder message={"No Similar flights available"} />
      );
    }

    const runningTourneys = [];
    const completedTourneys = [];
    const yetToStartTourneysList = [];
    const cancelledTourneys = [];

    tourneyDefinitions.forEach((tourneyDetails) => {
      if (tourneyDetails) {
        switch (tourneyDetails?.tourneyStatus) {
          case TOURNEY_STATUS_COMPLETED:
          case TOURNEY_STATUS_WON:
          case TOURNEY_STATUS_MISSED:
            completedTourneys.push(tourneyDetails);
            break;
          case TOURNEY_STATUS_CANCELLED:
            cancelledTourneys.push(tourneyDetails);
            break;
          case TOURNEY_STATUS_LIVE:
            runningTourneys.push(tourneyDetails);
            break;
          default:
            yetToStartTourneysList.push(tourneyDetails);
        }
      }
    });

    return (
      <FlightTourneyDetailsTableContainer>
        <TableHeader>
          <Column width={"25%"}>
            <TableHeaderText>Name</TableHeaderText>
          </Column>
          <Column width={"20%"}>
            <TableHeaderText>Buy-In</TableHeaderText>
          </Column>
          <Column width={"30%"}>
            <TableHeaderText>Start Time</TableHeaderText>
          </Column>
          <Column width={"25%"}>
            <TableHeaderText>Action</TableHeaderText>
          </Column>
        </TableHeader>
        {runningTourneys.length > 0 && (
          <>
            <SectionWrapper>
              <SectionIconWrapper fill={colors.leafyGreen} />
              <SectionTitleText>LIVE</SectionTitleText>
            </SectionWrapper>
            {runningTourneys.map((tourneyDetails) =>
              renderChildFlightTourneyDetails(tourneyDetails)
            )}
          </>
        )}
        {yetToStartTourneysList.length > 0 && (
          <>
            <SectionWrapper>
              <SectionIconWrapper fill={colors.casal} />
              <SectionTitleText>YET TO START</SectionTitleText>
            </SectionWrapper>
            {yetToStartTourneysList.map((tourneyDetails) =>
              renderChildFlightTourneyDetails(tourneyDetails)
            )}
          </>
        )}
        {completedTourneys.length > 0 && (
          <>
            <SectionWrapper>
              <SectionIconWrapper fill={colors.doveGray} />
              <SectionTitleText>COMPLETED</SectionTitleText>
            </SectionWrapper>

            {completedTourneys.map((tourneyDetails) =>
              renderChildFlightTourneyDetails(tourneyDetails)
            )}
          </>
        )}
        {cancelledTourneys.length > 0 && (
          <>
            <SectionWrapper>
              <SectionIconWrapper fill={colors.doveGray} />
              <SectionTitleText>CANCELLED</SectionTitleText>
            </SectionWrapper>

            {cancelledTourneys.map((tourneyDetails) =>
              renderChildFlightTourneyDetails(tourneyDetails)
            )}
          </>
        )}{" "}
      </FlightTourneyDetailsTableContainer>
    );
  };
  console.log("GANESH_TOURNEY_DETAILS", props.tourneyDetails);

  return (
    <Container>
      <TourneyStartsInAndPrizeAmountWrapper>
        <PrizeAmountContainer>
          <TrophyIconWrapper src={goldGloryIcon} />
          {isFlightParentTourney ? (
            <PrizeAmountWrapper>
              <ParentPrizeWorthText>
                Survivors From All Flights <br />
                will be auto registered to this event
              </ParentPrizeWorthText>
            </PrizeAmountWrapper>
          ) : (
            <PrizeAmountWrapper>
              <PrizeWorthText>Survivors Qualify for</PrizeWorthText>

              <PrizeAmountText>{parentTourneyName}</PrizeAmountText>
            </PrizeAmountWrapper>
          )}
        </PrizeAmountContainer>
        {!isFlightParentTourney && (
          <PrizeBreakupText onClick={handleParentTourneyNavigation}>
            Details
            <BlindsRightArrow src={RightLeafyGreenArrow} />
          </PrizeBreakupText>
        )}
      </TourneyStartsInAndPrizeAmountWrapper>
      <FlightTourneysTabbarAndTableWrapper>
        {childTourneys.length > 0 && (
          <FlightsBasicTabBarrContainer>
            <FlightTourneyTabItem
              isActive={selectedFlightDetailsTabbar === "similar"}
              onClick={() => {
                dispatch(
                  updateSelectedFlightDetailsTabbar({ selectedTab: "similar" })
                );
              }}
            >
              <FlightTourneyTabItemText>
                Similar Flights
              </FlightTourneyTabItemText>
            </FlightTourneyTabItem>
            <FlightTourneyTabItem
              isActive={selectedFlightDetailsTabbar === "satellites"}
              onClick={() => {
                dispatch(
                  updateSelectedFlightDetailsTabbar({
                    selectedTab: "satellites",
                  })
                );
              }}
            >
              <FlightTourneyTabItemText>Satellites</FlightTourneyTabItemText>
            </FlightTourneyTabItem>
          </FlightsBasicTabBarrContainer>
        )}
        {renderFlightTourneysDetails(selectedFlightDetailsTabbar)}
      </FlightTourneysTabbarAndTableWrapper>
      {!isFlightParentTourney && (
        <TourneyFlightParentAndSimilarFlightsCard
          isParentTourney={true}
          parentTourneyName={parentTourneyName}
          handleTourneyNavigation={handleParentTourneyNavigation}
        />
      )}

      {openQualifiedPlayersModal.isOpen && (
        <ModalContainer onClick={closeQualifiedPlayersModal}>
          <QualifiedPlayersModalContainer
            isOpen={openQualifiedPlayersModal.isOpen}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Header>
              <HeaderText>Qualified Players</HeaderText>
              <CloseIconWrapper
                src={CloseIcon}
                onClick={closeQualifiedPlayersModal}
              />
            </Header>
            <ModalBodyWrapper>
              <TableWrapper>
                <TableHeader>
                  <Column width={"33.33%"} align={"start"}>
                    <TableHeaderText>Position</TableHeaderText>
                  </Column>
                  <Column width={"33.33%"} align={"center"}>
                    <TableHeaderText>Name</TableHeaderText>
                  </Column>
                  <Column width={"33.33%"} align={"end"}>
                    <TableHeaderText>Stack</TableHeaderText>
                  </Column>
                </TableHeader>
                {!getTourneyDefinitionByTourneyId(
                  openQualifiedPlayersModal.tourneyId
                )?.qualifiedPlayers ? (
                  <LoaderContainer>
                    <Loader size={20} />
                  </LoaderContainer>
                ) : (
                  getTourneyDefinitionByTourneyId(
                    openQualifiedPlayersModal.tourneyId
                  ).qualifiedPlayers?.map(
                    ({ chipStack, displayName, rank }) => (
                      <TableRow>
                        <Column width={"33.33%"} align={"start"}>
                          <TableItemValueText>{rank}</TableItemValueText>
                        </Column>
                        <Column width={"33.33%"} align={"center"}>
                          <TableItemValueText>{displayName}</TableItemValueText>
                        </Column>
                        <Column width={"33.33%"} align={"end"}>
                          <TableItemValueText>{chipStack}</TableItemValueText>
                        </Column>
                      </TableRow>
                    )
                  )
                )}
              </TableWrapper>
            </ModalBodyWrapper>
          </QualifiedPlayersModalContainer>
        </ModalContainer>
      )}
    </Container>
  );
}

export default TourneyFlightsDetails;
