import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NLH,
  OMAHA,
  OMAHA5,
  OMAHA6,
  PLO4,
  PLO5,
  PLO6,
  PRIVATE_TABLE_HOSTING_DETAILS_SLIDER,
  PRIVATE_TABLE_TEMPLATE,
  TEXAS_HOLDEM,
} from "../../data/Constants";
import {
  closePrivateTableSliders,
  openSlider,
  updateSliderAnimatingFlag,
} from "../../redux/slices/sliderSlice";
import {
  GameTypeCards,
  GameTypeContainer,
  GameTypeImg,
  GameTypeLabel,
  Header,
  Icon,
  Label,
  PlusIcon,
  SelectedImg,
  SelectFromTemplateContainer,
  StartFreshBox,
  TemplateIcon,
  TemplateLabelWrapper,
  Wrapper,
} from "./privateTableSliderStyle";

import texasGameType from "../../../assets/lobby/texasGameType.png";
import omaha4GameType from "../../../assets/lobby/omaha4GameType.png";
import omaha5GameType from "../../../assets/lobby/omaha5GameType.png";
import omaha6GameType from "../../../assets/lobby/omaha6GameType.png";

import checkMark from "../../../assets/lobby/checkMark.png";
import backArrow from "../../../assets/lobby/back.png";
import closeIcon from "../../../assets/lobby/CloseIcon.svg";
import addIcon from "../../../assets/lobby/goldAddIcon.png";
import selectTemplateIcon from "../../../assets/lobby/selectTemplate.png";
import {
  Typo12OffWhiteContoMediumText,
  Typo14LightYellowContoMediumText,
  Typo14PrimaryGoldContoMediumText,
} from "../../../Common/styleGuide/Typos";
import {
  StrokeLineTowardsLeft,
  StrokeLineTowardsRight,
  Row,
} from "./createHostingDetailsStyle";
import { getGameLabel } from "../../../Common/utils/GameUtils";
import { fetchPrivateTableTemplates } from "../../redux/slices/lobbySlice";
import { GameTile } from "../../routes/PokerLobby/PrivateTable/GameTile/GameTile";
export const PrivateTableSlider = (props) => {
  const { sliderDirection } = props;
  const variantTypes = useSelector(
    (state) => state.lobby.privateTable.configs.variantTypes
  );
  const { templateData, isTemplateDeleted } = useSelector(
    (state) => state.lobby.privateTable
  );

  const { userId } = useSelector((state) => state.lobby.player);
  const accessToken = useSelector((state) => state.lobby.accessToken);

  const [selectedGameType, setSelectedGameType] = useState(null);
  const [viewType, setViewType] = useState("startFreshOrTemplate");
  const dispatch = useDispatch();

  useEffect(() => {
    getTemplateData();
  }, []);

  useEffect(() => {
    if (isTemplateDeleted) {
      getTemplateData();
    }
  }, [isTemplateDeleted]);

  const getTemplateData = () => {
    dispatch(
      fetchPrivateTableTemplates({
        apiURL: process.env.REACT_APP_FETCH_TEMPLATE_DATA,
        payload: {
          userId: userId,
        },
        headers: {
          headers: {
            Authorization: accessToken,
          },
        },
      })
    );
  };

  const renderGameType = () => {
    let gameVariant = [];
    if (variantTypes?.length > 0) {
      variantTypes.forEach((varient) => {
        if (varient === NLH) {
          gameVariant.push({
            type: TEXAS_HOLDEM,
            imgSrc: texasGameType,
          });
        }
        if (varient === PLO4) {
          gameVariant.push({
            type: OMAHA,
            imgSrc: omaha4GameType,
          });
        }
        if (varient === PLO5) {
          gameVariant.push({
            type: OMAHA5,
            imgSrc: omaha5GameType,
          });
        }
        if (varient === PLO6) {
          gameVariant.push({
            type: OMAHA6,
            imgSrc: omaha6GameType,
          });
        }
      });
    }

    return (
      gameVariant.length > 0 && (
        <>
          <Label>Select a Game format to proceed</Label>
          {
            <GameTypeContainer>
              {gameVariant.map((game) => {
                return (
                  <GameTypeCards
                    onClick={() => onGameTypeCardClick(game.type)}
                    active={selectedGameType === game.type}
                  >
                    {selectedGameType === game.type && (
                      <SelectedImg src={checkMark} />
                    )}
                    <GameTypeImg src={game.imgSrc}></GameTypeImg>
                    <GameTypeLabel isTexasGame={game.type === TEXAS_HOLDEM}>
                      {getGameLabel(game.type)}
                    </GameTypeLabel>
                  </GameTypeCards>
                );
              })}
            </GameTypeContainer>
          }
        </>
      )
    );
  };
  const onGameTypeCardClick = (gameType) => {
    setSelectedGameType(gameType);
    dispatch(
      openSlider({
        type: PRIVATE_TABLE_HOSTING_DETAILS_SLIDER,
        config: {
          gameType: gameType,
          newTable: "New Table",
        },
      })
    );
  };

  const onNextClick = () => {
    dispatch(
      openSlider({
        type: PRIVATE_TABLE_HOSTING_DETAILS_SLIDER,
        config: {
          gameType: selectedGameType,
          newTable: "New Table",
        },
      })
    );
  };

  const renderStartFreshUI = () => {
    return (
      <>
        <StartFreshBox onClick={() => onStartFreshClick()}>
          <PlusIcon src={addIcon} />
          <Typo14PrimaryGoldContoMediumText>
            Start Fresh
          </Typo14PrimaryGoldContoMediumText>
        </StartFreshBox>
        <Row>
          <StrokeLineTowardsLeft width={40} base={"white"} />
          <Typo12OffWhiteContoMediumText>OR</Typo12OffWhiteContoMediumText>
          <StrokeLineTowardsRight width={40} base={"white"} />
        </Row>
        {showTemplateData()}
      </>
    );
  };

  const showTemplateData = () => {
    return (
      <SelectFromTemplateContainer
        isTemplateDataAvailable={templateData.length > 0}
      >
        <TemplateLabelWrapper>
          <TemplateIcon src={selectTemplateIcon} />
          <Typo14LightYellowContoMediumText>
            Select from Templates
          </Typo14LightYellowContoMediumText>
        </TemplateLabelWrapper>
        {templateData.length === 0 ? (
          // <Loader size={20} />
          <GameTypeContainer>
            <Typo12OffWhiteContoMediumText>
              No Templates found!
            </Typo12OffWhiteContoMediumText>
          </GameTypeContainer>
        ) : (
          templateData.map((template) => {
            return (
              <GameTile
                gameDefinition={{
                  isTemplate: true,
                  type: PRIVATE_TABLE_TEMPLATE,
                  ...template,
                }}
              />
            );
          })
        )}
      </SelectFromTemplateContainer>
    );
  };

  const onStartFreshClick = () => {
    setViewType("gameFormat");
  };

  const handleSliderClose = () => {
    dispatch(closePrivateTableSliders());
  };

  return (
    <Wrapper
      isTemplateDataAvailable={templateData.length > 0}
      onAnimationEnd={() => {
        if (sliderDirection === "close") {
          handleSliderClose();
        }
        dispatch(updateSliderAnimatingFlag({ value: false }));
      }}
      sliderDirection={sliderDirection}
    >
      <Header>
        Host Private Table
        {viewType === "gameFormat" && (
          <Icon
            src={backArrow}
            left={15}
            width={11}
            onClick={() => {
              setViewType("startFreshOrTemplate");
            }}
          ></Icon>
        )}
        <Icon
          src={closeIcon}
          right={15}
          width={11}
          onClick={() => {
            handleSliderClose();
          }}
        ></Icon>
      </Header>
      {viewType === "gameFormat" && renderGameType()}
      {viewType === "startFreshOrTemplate" && renderStartFreshUI()}
      {/* {viewType === "gameFormat" && (
        <ButtonWrapper>
          <Button
            buttonText={"Next"}
            location={PRIVATE_TABLE}
            isDisabled={!selectedGameType}
            clickHandler={onNextClick}
          ></Button>
        </ButtonWrapper>
      )} */}
    </Wrapper>
  );
};
