import { AnimatePresence, motion } from "framer-motion";
import React, { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { cardThemes } from "../../../assets/cards";
import { GAME_STAGE_SHOWDOWN } from "../../data/Constants";
import { getWinningPlayersData } from "../../../Common/utils/GameUtils";
import colors from "../../../Common/styleGuide/Colors";
import "./flipCardStyles.css";
import FlipCard from "./NewFlipCard";
import RITCommunityCards from "./RITCommunityCards";

const CommunityCards = (props) => {
  const { currentGameId, tempTableId } = props;
  const communityCards = useSelector(
    (state) => state.gameTable.games[tempTableId]?.communityCards
  );
  const RITCommunityCardsArray = useSelector(
    (state) => state.gameTable.games[tempTableId]?.RITCommunityCards
  );
  const showCommunityCardsImmediately = useSelector((state) => state.gameTable.games[tempTableId].showCommunityCardsImmediately);
  const gameTableDimensions = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameTableDimensions
  );
  const cardDeckTheme = useSelector(
    (state) => state.gameTable.themeData.cardDeck
  );
  const [winningHand, setWinningHand] = useState([]);
  const playerDetails = useSelector((state) =>
    state.gameTable.games[tempTableId]?.playerData.find(
      (player) =>
        player.position === state.gameTable.games[tempTableId]?.playerSeat
    )
  );
  const showStartGameTimer = useSelector(
    (state) => state.gameTable.games[tempTableId]?.showStartGameTimer
  );
  const doCommunityCardsExist = useSelector(
    (state) => state.gameTable.games[tempTableId]?.doCommunityCardsExist
  );
  const gameStage = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameStage
  );
  const playerData = useSelector(
    (state) => state.gameTable.games[tempTableId]?.playerData
  );
  const { showHandStrength } = useSelector(
    (state) => state.gameTable.tableSettings
  );
  const isWinnerDeclared = useSelector(
    (state) => state.gameTable.games[tempTableId].isWinnerDeclared
  );
  const [communityCardsBackup, setCommunityCardsBackup] = useState([]);
  const [cardWidth, setCardWidth] = useState(null);
  const [isRabbitCardFlipped, setIsRabbitCardFlipped] = useState(false);
  const winningPotData = useSelector(
    (state) => state.gameTable.games[tempTableId]?.winningPotDisplay
  );
  const isRITEnabled = useSelector(
    (state) => state.gameTable.games[tempTableId]?.isRITEnabled
  );
  const intervalRef = useRef(null);

  useEffect(() => {
    setIsRabbitCardFlipped(false);
  }, [currentGameId]);

  useEffect(() => {
    if (gameTableDimensions?.width) {
      setCardWidth((gameTableDimensions?.width * 0.6) / 5);
    }
    return () => clearTimeout(intervalRef?.current);
  }, []);

  useEffect(() => {
    if (isWinnerDeclared && winningPotData) {
      getWinningHandCards();
    } else {
      setWinningHand([]);
      clearTimeout(intervalRef?.current);
    }

    return () => {
      clearTimeout(intervalRef?.current);
    };
  }, [isWinnerDeclared, winningPotData]);

  const getWinningHandCards = () => {
    if (winningPotData) {
      let winningPlayer = playerData.find(
        (player) => player.userName === winningPotData?.winnerNames[0]
      );
      if (
        winningPotData?.winnerNames.includes(winningPlayer?.userName) &&
        winningPlayer?.winningHand
      ) {
        setWinningHand(winningPlayer?.winningHand);
      } else {
        setWinningHand([]);
      }
    }
  };

  const returnHorizontalExitAmountForCard = (index) => {
    switch (index) {
      case 0:
        return cardWidth * 2;
      case 1:
        return cardWidth;
      case 2:
        return 0;
      case 3:
        return -cardWidth;
      case 4:
        return -cardWidth * 2;
    }
  };

  useEffect(() => {
    if (communityCards.length > 0) {
      setCommunityCardsBackup(communityCards);
    }
  }, [communityCards]);

  // const highlightWinningCards = () => {
  //   let showHighLight = false;
  //   if (isWinnerDeclared) {
  //     showHighLight =
  //       winningHand.length === 5 && winningHand.includes(props.source);
  //   } else if (
  //     showHandStrength &&
  //     playerDetails?.possibleWinningHandType &&
  //     playerDetails?.possibleWinningHandCards &&
  //     playerDetails.lastAction !== FOLD_ACTION
  //   ) {
  //     showHighLight = playerDetails?.possibleWinningHandCards?.includes(
  //       props.source
  //     );
  //   }
  //   return showHighLight;
  // };

  const handleCardFlip = () => {
    setIsRabbitCardFlipped(true);
  };

  const returnCommunityCardDelay = (index) => {
    if (showCommunityCardsImmediately) {
      return 0;
    }
    if (index < 3) {
      return 0;
    } else {
      return index * 0.55;
    }

    // switch (gameStage) {
    //   case GAME_STAGE_FLOP:
    //     return index * 0.25;
    //   case GAME_STAGE_TURN:
    //     return 0.5;
    //   case GAME_STAGE_RIVER:
    //     return 0.5;
    //   case GAME_STAGE_SHOWDOWN:
    //     switch (index) {
    //       case 0:
    //       case 1:
    //       case 2:
    //         return index * 0.15;
    //       case 3:
    //         return index * 0.35;
    //       case 4:
    //         return index * 0.35;
    //     }
    // }
  };

  const shouldDimCommunityCardsAfterRIT = (index) => {
    if (isWinnerDeclared || RITCommunityCardsArray.length === 0) return false;

    const runItTwiceCards = RITCommunityCardsArray.filter(
      (item) => item !== "cardBack"
    );
    const cardsCount = runItTwiceCards.length;

    if (cardsCount === 5) return true;
    if (cardsCount === 2) return index >= 3;
    if (cardsCount === 1) return index >= 4;

    return false;
  };

  const renderCards = (array) => {
    return array.map((card, index) => {
      if (card?.rabbitHunting && card?.source) {
        return (
          <FlipCard
            key={
              "RabbitHuntingCard-" +
              card?.source +
              "-tempTableId-" +
              tempTableId
            }
            isRabbitCardFlipped={isRabbitCardFlipped}
            flipCard={handleCardFlip}
            source={card?.source}
            cardWidth={cardWidth}
            horizontalExitAmount={returnHorizontalExitAmountForCard(index)}
            doCommunityCardsExist={doCommunityCardsExist}
            darkenCard={!winningHand.includes(card?.source) && isWinnerDeclared}
          />
        );
      }
      if (!cardThemes[cardDeckTheme][card]) return;
      return (
        <AnimatePresence
          key={"AnimatePresence-CommunityCard-" + card + "-" + index}
        >
          {doCommunityCardsExist && (
            <motion.div
              key={"new-community-card-" + card + "-" + index}
              initial={{ opacity: 0, scale: 0 }}
              exit={{
                y: -50,
                x: returnHorizontalExitAmountForCard(index),
                scale: 0.25,
                opacity: 0,
                transition: {
                  delay: 0,
                },
                // transition: {
                //   delay: 2.5,
                // },
              }}
              animate={{
                opacity: 1,
                scale: 1,
                y:
                  winningHand.includes(card) &&
                  RITCommunityCardsArray.length === 0
                    ? -10
                    : null,
                filter:
                  !winningHand.includes(card) && isWinnerDeclared
                    ? "brightness(0.6)"
                    : shouldDimCommunityCardsAfterRIT(index)
                    ? "brightness(0.6)"
                    : null,
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              transition={{
                opacity: {
                  delay: returnCommunityCardDelay(index),
                },
                scale: {
                  delay: returnCommunityCardDelay(index),
                },
              }}
            >
              <img
                style={{
                  width: cardWidth,
                  height: "auto",
                }}
                src={cardThemes[cardDeckTheme][card]}
              ></img>
              {playerDetails?.possibleWinningHandCards?.includes(card) &&
              !playerDetails?.isFolded &&
              showHandStrength &&
              !isWinnerDeclared &&
              gameStage !== GAME_STAGE_SHOWDOWN ? (
                <div
                  style={{
                    height: "4px",
                    background: colors.winnerCardHighLight,
                    width: "99%",
                    border: "1px solid green",
                    borderRadius: "0px 0px 100px 100px",
                  }}
                ></div>
              ) : null}
            </motion.div>
          )}
        </AnimatePresence>
      );
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        gap: "5px",
        minHeight: isRITEnabled ? "95px" : "55px",
        position: "relative",
        width: gameTableDimensions?.width * 0.65,
      }}
    >
      {/* <AnimatePresence> */}
      {cardWidth && communityCards.length > 0
        ? renderCards(communityCards)
        : renderCards(communityCardsBackup)}
      {/* </AnimatePresence> */}
      {RITCommunityCardsArray.length > 0 ? (
        <RITCommunityCards
          currentGameId={currentGameId}
          tempTableId={tempTableId}
        />
      ) : null}
    </div>
  );
};

export default memo(CommunityCards);
