import React from "react";
import { Icon, Header } from "../privateTableSliderStyle";
import {
  Container,
  HostControlBody,
  OptionsIcon,
  OptionsRow,
  Row,
} from "./gameTableHostControlStyle";
import closeIcon from "../../../../assets/lobby/CloseIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  closeSlider,
  updateSliderAnimatingFlag,
} from "../../../redux/slices/sliderSlice";
import { AccessCodeLayout } from "../../../../Common/components/PrivateTableAccessCodeLayout/AccessCodeLayout";
import {
  annoucement,
  goToPrivateTableLobby,
  terminateTable,
} from "../../../routes/Game/gameAssets";
import { Typo14LightYellowContoMediumText } from "../../../../Common/styleGuide/Typos";
import {
  hideGameTable,
  openLocalGameModal,
} from "../../../redux/slices/gameTableSlice";
import {
  GAME_CONNECTION_PROTOCOL_SUCCESS,
  PRIVATE_TABLE_ADD_ANNOUNCEMENT,
  TERMINATE_PRIVATE_TABLE,
} from "../../../data/Constants";
import { sendTerminatePrivateTableProtocol } from "../../../utils/ProtocolUtils";

export const GameTableHostControlSlider = ({ sliderDirection }) => {
  const activeGame = useSelector((state) => state.gameTable.activeGame);
  const accessCode = useSelector(
    (state) => state.gameTable.games[activeGame].gameDefinition.accessCode
  );
  const gameConnectionStatus = useSelector(
    (state) => state.gameTable.games[activeGame].gameConnectionStatus
  );
  const dispatch = useDispatch();

  const handleSliderClose = () => {
    dispatch(closeSlider());
  };

  const hostControlOptions = () => {
    const options = [
      {
        icon: annoucement,
        label: "Add Announcement",
        onClick: () => openAddAnnouncement(),
      },
      {
        icon: terminateTable,
        label: "Terminate Table",
        onClick: () => terminatePrivateTable(),
      },
      {
        icon: goToPrivateTableLobby,
        label: "Go to Private Tables Lobby",
        onClick: () => {
          dispatch(hideGameTable());
        },
      },
    ];
    return options.map((option) => {
      return (
        <OptionsRow onClick={option.onClick}>
          <OptionsIcon src={option.icon}></OptionsIcon>
          <Typo14LightYellowContoMediumText>
            {option.label}
          </Typo14LightYellowContoMediumText>
        </OptionsRow>
      );
    });
  };

  const openAddAnnouncement = () => {
    dispatch(
      openLocalGameModal({
        type: PRIVATE_TABLE_ADD_ANNOUNCEMENT,
        tempTableId: activeGame,
      })
    );
    dispatch(closeSlider());
  };

  const terminatePrivateTable = () => {
    if (gameConnectionStatus === GAME_CONNECTION_PROTOCOL_SUCCESS) {
      dispatch(
        openLocalGameModal({
          type: TERMINATE_PRIVATE_TABLE,
          tempTableId: activeGame,
        })
      );
      dispatch(closeSlider());
    }
  };

  return (
    <Container
      onAnimationEnd={() => {
        if (sliderDirection === "close") {
          handleSliderClose();
        }
        dispatch(updateSliderAnimatingFlag({ value: false }));
      }}
      sliderDirection={sliderDirection}
    >
      <Header>
        Host Controls
        <Icon
          src={closeIcon}
          right={15}
          width={11}
          onClick={() => {
            handleSliderClose();
          }}
        ></Icon>
      </Header>
      <HostControlBody>
        <AccessCodeLayout accessCode={accessCode}></AccessCodeLayout>
        {hostControlOptions()}
      </HostControlBody>
    </Container>
  );
};
