import Handler from "../Handler";

class ConcludePrivateTableHandler extends Handler {
  execute(data) {
    //concludePrivateTable
    // passing object from base client
    //   {
    //     "status": 800,
    //     "isPrivateTable": true,
    //     "isPrivateTableHostTerminated": false
    // }
    console.log("sneha log >> data in ConcludePrivateTableHandler", data);

    this.service.processConcludePrivateTable(data);
  }
}

export default ConcludePrivateTableHandler;
