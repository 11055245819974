import Handler from "../Handler";

class AnnouncementPrivateTableAckHandler extends Handler {
  // announcementpta
  execute(message) {
    this.service.processAnnouncementPrivateTableAck();
  }
}

export default AnnouncementPrivateTableAckHandler;
