import React, { useEffect, useState } from "react";
import {
  CloseButton,
  Color,
  ColorOptions,
  ColorSection,
  EnableControls,
  EnableTitle,
  KYCStatusBox,
  KYCText,
  MainStats,
  Notes,
  NotesSection,
  OtherStats,
  Player,
  PlayerAvatar,
  PlayerAvatarBox,
  PlayerInfoBox,
  PlayerMoney,
  PlayerName,
  PlayerStatsActionArea,
  PlayerStatsBody,
  PlayerStatsCancelButton,
  PlayerStatsModalContainer,
  PlayerStatsModalHeader,
  PlayerStatsSaveButton,
  SelectedColor,
  ShowdownBarContainer,
  ShowdownInnerProgressBar,
  Statistic,
  StatisticName,
  StatisticValue,
  StatsHeader,
  StatsWrapper,
  StatText,
  Toggle,
  WentToShowdownInnerProgressBar,
  WonShowdownInnerProgressBar,
} from "./PlayerStatsStyle";
import close from "../../../../assets/common/close.svg";
import { useDispatch, useSelector } from "react-redux";
import infoIcon from "../../../../assets/gameTable/info.svg";
import toggleOff from "../../../../assets/lobby/NavyToggleOffIcon.svg";
import toggleOn from "../../../../assets/lobby/GreenOnToggle.svg";
import { parseDecimalValuesOfNumber } from "../../../utils/GameUtils";
import { closeLocalGameModal } from "../../../../Poker/redux/slices/gameTableSlice";
import axios from "axios";
import GameConnectionsN from "../../../../Poker/io/GameConnectionsN";
import { getPlayerObject } from "../../../../Poker/utils/ReduxUtils";
import { Loader } from "../../../../Poker/components/Loader/Loader";
import yellowChip from "../../../../assets/gameTable/chip-yellow.png";
import { Tooltip } from "react-tooltip";
import { sendLobbyDebugLog } from "../../../../Poker/redux/slices/lobbySlice";
import { RSA_FAILURE_STATUS_ERROR_CODES } from "../../../../Poker/data/Constants";
import { encryptAPIPayload } from "../../../utils/EncryptionUtil";
import { getDisplayName } from "../../../../Poker/utils/ReduxUtils";

const PlayerStats = ({ tempTableId }) => {
  const dispatch = useDispatch();
  const [statisticTypes, setStatisticTypes] = useState({
    vpip: {},
    steal: {},
    pfr: {},
    wpf: {},
  });
  const statisticToolTipContent = {
    vpip: "Voluntary Put In Pot : Refers to the probability of a player voluntarily putting money into the pot pre-flop, either by raising or calling",
    steal:
      "The % raise from Cut-Off, Dealer or Small Blind pre-flop when the pot is unopened",
    pfr: "Refers to the frequency of player raising pre-flop",
    wpf: "Refers to win probability of player, having seen the flop",
  };
  const [data, setData] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [showdownStats, setShowdownStats] = useState({
    wentToShowdown: 0,
    wonAtShowdown: 0,
  });
  const channel = useSelector((state) => state.lobby.channel);
  const isFunGame = useSelector(
    (state) => state.gameTable.games[tempTableId].isFunGame
  );
  const loggedPlayer = useSelector((state) => state.lobby.player);
  let playerDisplayNameMap = useSelector(
    (state) => state.gameTable.games[tempTableId]?.displayNameMap
  );
  const displayName = useSelector((state) => state.lobby?.displayName);
  const rawPlayerProfileData = window.sessionStorage.getItem(
    "player-profile-data"
  );
  const playerData = rawPlayerProfileData
    ? JSON.parse(rawPlayerProfileData)
    : {};

  const loggedInPlayerName = useSelector(
    (state) => state.gameTable.userDetails.userName
  );
  const [colorsEnabled, setColorsEnabled] = useState(
    playerData?.enabledPlayerStats ?? false
  );
  const [selectedHex, setSelectedHex] = useState(playerData?.colorCode ?? null);
  const [playerNotes, setPlayerNotes] = useState(playerData?.playerNotes ?? "");
  const [unsavedDataExists, setUnsavedDataExists] = useState(false);
  const [savedData, setSavedData] = useState({
    statsEnabled: colorsEnabled,
    selectedHex: selectedHex,
    playerNotes: playerNotes,
  });
  const orientation = useSelector((state) => state.gameTable.tableOrientation);

  useEffect(() => {
    if (colorsEnabled !== savedData?.statsEnabled) {
      setUnsavedDataExists(true);
    } else if (selectedHex !== savedData?.selectedHex) {
      setUnsavedDataExists(true);
    } else if (playerNotes !== savedData?.playerNotes) {
      setUnsavedDataExists(true);
    } else {
      setUnsavedDataExists(false);
    }
  }, [colorsEnabled, selectedHex, playerNotes]);

  const fetchPlayerStatsData = async () => {
    let encryptedQueryParams = encryptAPIPayload(
      // `screenName=${playerData?.userName}&channel=${channel}`
      { screenName: playerData?.userName, channel: channel },
      true
    );

    let queryValue = "";
    Object.keys(encryptedQueryParams).forEach(function (key, index) {
      queryValue += `${key}=${encryptedQueryParams[key]}${
        index < Object.keys(encryptedQueryParams).length - 1 ? "&" : ""
      }`;
    });

    await axios
      .get(process.env.REACT_APP_PLAYER_STATS_API_URL + `?${queryValue}`)
      .then((response) => {
        console.log("Player Stats Response >>> ", response);
        setData(response?.data?.playerStats);
        setDataLoading(false);
        let res = response?.data?.playerStats;
        // let res = {
        //   pk: 4234234,
        //   sk: 4234,
        //   createdAt: 43,
        //   LOGIN_NAME_TXT: 432,
        //   PFR: 132,
        //   STEAL: 234,
        //   updatedAt: 16,
        //   USER_ID: 15,
        //   VPIP: 32,
        //   WENT_TO_SHOWDOWN: 40,
        //   WINS_POST_FLOP: 20,
        //   WON_AT_SHOWDOWN: 50,
        //   kyc: true,
        // };
        setStatisticTypes({
          vpip: { value: res?.VPIP ? res?.VPIP : null, name: "VPIP" },
          steal: { value: res?.STEAL ? res?.STEAL : null, name: "Steal" },
          pfr: { value: res?.PFR ? res?.PFR : null, name: "Pre-flop raise" },
          wpf: {
            value: res?.WINS_POST_FLOP ? res?.WINS_POST_FLOP : null,
            name: "Wins post-flop",
          },
        });
        setShowdownStats({
          wentToShowdown: res?.WENT_TO_SHOWDOWN ? res?.WENT_TO_SHOWDOWN : 0,
          wonAtShowdown: res?.WON_AT_SHOWDOWN ? res?.WON_AT_SHOWDOWN : 0,
        });

        if (
          response?.data?.errorCode &&
          RSA_FAILURE_STATUS_ERROR_CODES.includes(response?.data?.errorCode)
        ) {
          sendLobbyDebugLog({
            type: `ENCRYPTION_FAILED_WITH_CODE - ${response?.data?.errorCode}`,
            responsePayload: response,
          });
        }
      })
      .catch((err) => {
        // setDataLoading(false);
        // console.log("Player stats error is >>> ", err);
      });
  };

  useEffect(() => {
    fetchPlayerStatsData();
  }, []);

  const colors = [
    {
      hex: "FFFFFF",
    },
    {
      hex: "9C9D9F",
    },
    {
      hex: "196A29",
    },
    {
      hex: "42BE40",
    },
    {
      hex: "FFB834",
    },
    {
      hex: "CD7F32",
    },
    {
      hex: "EABF69",
    },
    {
      hex: "F0745C",
    },
    {
      hex: "C62828",
    },
    {
      hex: "4A75BC",
    },
  ];

  const handleColorEnabling = () => {
    if (colorsEnabled) {
      setSelectedHex(null);
    } else {
      setSelectedHex("FFFFFF");
    }
    setColorsEnabled(!colorsEnabled);
  };

  const pickColor = (hex) => {
    if (colorsEnabled) {
      setSelectedHex(hex);
    }
  };

  const handleSavePlayerStats = () => {
    let dataObject = {
      playerName: getPlayerObject().userName,
      opponetName: playerData?.userName,
      enabledPlayerStats: colorsEnabled,
      playerNotes: playerNotes,
      colorCode: selectedHex,
    };
    setSavedData({
      statsEnabled: colorsEnabled,
      selectedHex: selectedHex,
      playerNotes: playerNotes,
    });
    window.sessionStorage.setItem(
      "PS-Success-Data",
      JSON.stringify(dataObject)
    );
    let gameConnection =
      GameConnectionsN.getInstance().getGameConnection(tempTableId);
    gameConnection.listener.requestSavePlayerStats(dataObject);
    setButtonLoading(true);
  };

  const handleCancelClick = () => {
    if (buttonLoading) {
      dispatch(closeLocalGameModal());
    }
    setSelectedHex(savedData?.selectedHex);
    setPlayerNotes(savedData?.playerNotes);
    setColorsEnabled(savedData?.statsEnabled);
    return;
  };

  return (
    <PlayerStatsModalContainer
      orientation={orientation}
      id='player-stats-modal'
    >
      <PlayerStatsModalHeader id='player-stats-header'>
        {isFunGame
          ? "Player Notes"
          : loggedInPlayerName === playerData?.userName
          ? "My Stats"
          : "Player Stats"}
        <CloseButton
          tempTableId={tempTableId}
          src={close}
          onClick={() =>
            dispatch(closeLocalGameModal({ tempTableId: tempTableId }))
          }
        />
      </PlayerStatsModalHeader>
      <PlayerStatsBody id='player-stats-body'>
        <StatsHeader id='stats-header'>
          <Player id='stats-player'>
            <PlayerAvatarBox id='stats-avatar-box'>
              <PlayerAvatar
                color={"#" + selectedHex}
                src={
                  playerData?.avatar
                    ? playerData?.avatar
                    : "https://images.fanfight.com/avatars/avatar1.png"
                  // playerAvatar
                }
                id='stats-avatar'
              />
              {!dataLoading && (
                <KYCStatusBox id='kyc-stats-box' verified={data?.kyc}>
                  <KYCText id='kyc-stats-text'>
                    {data?.kyc ? "KYC Verified" : "KYC not verified"}
                  </KYCText>
                </KYCStatusBox>
              )}
            </PlayerAvatarBox>
            <PlayerInfoBox>
              <PlayerName>
                {getDisplayName(
                  playerData?.userName,
                  playerDisplayNameMap,
                  loggedPlayer,
                  displayName
                )}
              </PlayerName>
              {playerData?.atTableAmount ? (
                <PlayerMoney>
                  {isFunGame ? (
                    <img src={yellowChip} style={{ width: "10px" }}></img>
                  ) : (
                    "₹"
                  )}
                  {parseDecimalValuesOfNumber(playerData?.atTableAmount, 2)}
                </PlayerMoney>
              ) : null}
            </PlayerInfoBox>
          </Player>
          {!isFunGame ? (
            <OtherStats>
              <StatText>
                <span style={{ color: "gold", fontSize: "10px" }}>
                  {showdownStats.wentToShowdown}%
                </span>{" "}
                <span style={{ fontSize: "10px" }}>went to showdown</span>
              </StatText>
              <StatText>
                <span style={{ color: "green", fontSize: "10px" }}>
                  {showdownStats.wonAtShowdown}%
                </span>{" "}
                <span style={{ fontSize: "10px" }}>won at showdown</span>
              </StatText>
              <ShowdownBarContainer>
                <WentToShowdownInnerProgressBar
                  width={showdownStats.wentToShowdown}
                />
                <WonShowdownInnerProgressBar
                  width={
                    (showdownStats.wonAtShowdown *
                      showdownStats.wentToShowdown) /
                    100
                  }
                />
              </ShowdownBarContainer>
            </OtherStats>
          ) : null}
        </StatsHeader>

        {!isFunGame ? (
          <MainStats id='main-stats'>
            {Object.keys(statisticTypes).map((type, index) => {
              // if (statisticTypes[type]) {
              return (
                <Statistic id='stats' key={"statistic-type-" + type}>
                  <StatisticValue>
                    {statisticTypes[type].value
                      ? statisticTypes[type].value + "%"
                      : "--"}
                  </StatisticValue>
                  <StatisticName>
                    {statisticTypes[type].name}{" "}
                    <>
                      <a
                        // data-tooltip-content={statisticToolTipContent[type]}
                        className={`anchor-tooltip-${type}`}
                      >
                        <img
                          style={{ width: "10px", marginLeft: "5px" }}
                          src={infoIcon}
                        ></img>
                      </a>
                      <Tooltip
                        opacity={1}
                        style={{
                          maxWidth: "275px",
                          height: "auto",
                          overflow: "clip",
                          whiteSpace: "pre-wrap",
                        }}
                        variant='info'
                        openOnClick={true}
                        clickable
                        anchorSelect={`.anchor-tooltip-${type}`}
                        place='bottom'
                      >
                        {statisticToolTipContent[type]}
                      </Tooltip>
                    </>
                  </StatisticName>
                </Statistic>
              );
              // }
            })}
          </MainStats>
        ) : null}

        {loggedInPlayerName !== playerData?.userName ? (
          <NotesSection>
            <Notes
              value={playerNotes}
              onChange={(e) => setPlayerNotes(e.target.value)}
              placeholder='Type your notes here'
            ></Notes>
          </NotesSection>
        ) : null}

        {loggedInPlayerName !== playerData?.userName ? (
          <ColorSection id='color-section'>
            <EnableControls id='enable-colors'>
              <EnableTitle id='enable-colors-title'>
                Colour tag this player
              </EnableTitle>
              <Toggle
                id='toggle-button-for-colors'
                onTouchStart={() => handleColorEnabling()}
                src={colorsEnabled ? toggleOn : toggleOff}
              />
            </EnableControls>
            <ColorOptions id='color-options' colorsEnabled={colorsEnabled}>
              {colors.map((color, index) => {
                return (
                  <Color
                    key={color.hex + "-player-stats-colors"}
                    id='color-component'
                    active={selectedHex === color.hex}
                    onTouchStart={() => pickColor(color.hex)}
                    hex={"#" + color.hex}
                  >
                    {selectedHex === color.hex ? (
                      <SelectedColor
                        id='active-inner-color'
                        hex={"#" + color.hex}
                      />
                    ) : null}
                  </Color>
                );
              })}
            </ColorOptions>
          </ColorSection>
        ) : null}

        {/* If unsaved data exists, add a save button */}
        {unsavedDataExists && loggedInPlayerName !== playerData?.userName ? (
          <PlayerStatsActionArea>
            <PlayerStatsCancelButton
              onClick={
                () => handleCancelClick()
                // dispatch(closeLocalGameModal({ tempTableId: tempTableId }))
              }
            >
              Cancel
            </PlayerStatsCancelButton>
            <PlayerStatsSaveButton onClick={() => handleSavePlayerStats()}>
              {buttonLoading ? <Loader size={10} /> : "Save changes"}
            </PlayerStatsSaveButton>
          </PlayerStatsActionArea>
        ) : null}
      </PlayerStatsBody>
    </PlayerStatsModalContainer>
  );
};

export default PlayerStats;
