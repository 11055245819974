import React from "react";

import {
  Container,
  ConfirmWrapper,
  ConfirmHeader,
  HeaderTitleText,
  ConfirmBodyWrapper,
  ConfirmText,
  DescriptionWrapper,
  DescriptionText,
  NoteText,
  CTAContainer,
  SecondaryCTAButton,
  PrimaryCTAButton,
  ButtonText,
} from "./confirmationPopupStyles";
import { Loader } from "../../../../Poker/components/Loader/Loader";

function ConfirmationPopupUI(props) {
  const {
    headerTitle,
    title,
    description,
    secondaryCTAText,
    primaryCTAText,
    onSecondaryCTAClick,
    onPrimaryCTAClick,
    handleCloseModal,
    headerCustomStyles,
    leftAlignText
  } = props;

  const handleSecondaryCTAClick = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    onSecondaryCTAClick && onSecondaryCTAClick();
  };

  const handlePrimaryCTAClick = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    onPrimaryCTAClick && onPrimaryCTAClick();
  };

  const handleContainerClick = (event) => {
    handleCloseModal && handleCloseModal();
  };

  return (
    <Container
      id='confirm-revamp-container'
      onClick={(event) => handleContainerClick(event)}
    >
      <ConfirmWrapper
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <ConfirmHeader>
          <HeaderTitleText headerCustomStyles={headerCustomStyles}>
            {headerTitle}
          </HeaderTitleText>
        </ConfirmHeader>
        <ConfirmBodyWrapper>
          <ConfirmText>{title}</ConfirmText>
          {description && (
            <DescriptionWrapper leftAlignText = {leftAlignText}>
              {!props?.hideNoteTextInDescription && <NoteText leftAlignText = {leftAlignText}>Note:</NoteText>}
              <DescriptionText leftAlignText = {leftAlignText}>{description}</DescriptionText>
            </DescriptionWrapper>
          )}
          <CTAContainer>
            {secondaryCTAText && (
              <SecondaryCTAButton
                onClick={handleSecondaryCTAClick}
                disabled={props?.isLoading}
              >
                <ButtonText>{secondaryCTAText}</ButtonText>
              </SecondaryCTAButton>
            )}
            {primaryCTAText && (
              <PrimaryCTAButton
                onClick={handlePrimaryCTAClick}
                disabled={props?.isLoading}
              >
                {props?.customPrimaryButton ? (
                  props?.customPrimaryButton()
                ) : props?.isLoading ? (
                  <Loader size={20} />
                ) : (
                  <ButtonText>{primaryCTAText}</ButtonText>
                )}
              </PrimaryCTAButton>
            )}
          </CTAContainer>
        </ConfirmBodyWrapper>
      </ConfirmWrapper>
    </Container>
  );
}

export default ConfirmationPopupUI;
