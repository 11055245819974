/*
    -> tableData : array of objects(same key across) containing the data that needs to be displayed inside the table 
    -> table header is sticky
    -> table will occupy 100% width of the parent container in which you are rendering the table 
    -> Column width is adjusted according to the number of keys
    -> If there are more than 4 columns , table becomes scrollable(X axis)
    -> pass table height as props
*/

import React, { useEffect, useState } from "react";
import { Typo14GrayContoMediumText } from "../../styleGuide/Typos";
import {
  Column,
  HeaderColumn,
  NoDataWrapper,
  Row,
  StrokeLine,
  TableBody,
  TableContainer,
  TableHeader,
  TableWrapper,
} from "./tableStyle";

export const Table = (props) => {
  const { tableData, noDataMessage } = props;
  const [columnKeys, setColumnKeys] = useState([]);
  const [totalColumns, setTotalColumns] = useState(0);

  useEffect(() => {
    if (tableData?.length > 0) {
      setColumnKeys(Object.keys(tableData[0]));
      setTotalColumns(columnKeys.length);
    }
    return () => {};
  }, [tableData]);

  const createTableHeader = () => {
    return (
      <TableHeader>
        {columnKeys.map((key) => {
          return (
            <HeaderColumn
              colWidth={totalColumns <= 4 ? 100 / totalColumns : 30}
            >
              {key}
            </HeaderColumn>
          );
        })}
      </TableHeader>
    );
  };

  const createTableBody = () => {
    return (
      <TableBody>
        {tableData.map((row, index) => {
          return (
            <>
              <Row>
                {columnKeys.map((item) => {
                  return (
                    <Column
                      colWidth={totalColumns <= 4 ? 100 / totalColumns : 30}
                    >
                      {row[item]}
                    </Column>
                  );
                })}
              </Row>
              {index < tableData.length - 1 && <StrokeLine></StrokeLine>}
            </>
          );
        })}
      </TableBody>
    );
  };
  return tableData?.length > 0 ? (
    <TableWrapper>
      <TableContainer width={totalColumns > 4 ? totalColumns * 25 : 100}>
        {createTableHeader()}
        {createTableBody()}
      </TableContainer>
    </TableWrapper>
  ) : (
    <NoDataWrapper>
      <Typo14GrayContoMediumText>
        {noDataMessage || "No Data found"}
      </Typo14GrayContoMediumText>
    </NoDataWrapper>
  );
};
