import styled from "styled-components";

export const AccessCodeContainer = styled.div`
  background: #00000080;
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7% 5%;
`;

export const Box = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1% 5%;
`;

export const ShareLogo = styled.img`
  width: 17px;
  margin-bottom: 7px;
`;

