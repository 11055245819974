import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import specialMessage from "./../../../../assets/gameTable/specialMessage.png";
import defaultAvatar from "./../../../../assets/gameTable/defaultAvatar.png";
import colors from "../../../styleGuide/Colors";
import {
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  getISTDateTimeFormat,
  parseDecimalValuesOfNumber,
} from "../../../utils/GameUtils";
import "./AcknowledgeHighStrengthWinStyles";
import {
  AcknowlegeImage,
  CongratulationsLabel,
  DetailsBox,
  DetailsContainer,
  GameLabel,
  HandLabel,
  ImageContainer,
  InfoBox,
  PlayerAvatar,
  Wrapper,
  Details,
  PlayerName,
  TagLine,
  ButtonWrapper,
  PrimaryCTAButton,
  SecondaryCTAButton,
  Acknowledgement
} from "./AcknowledgeHighStrengthWinStyles";
import { Taglines } from "./Tagline";
import { messageToNativeClient } from "../../../utils/platformCommunicationUtil";
import { closeLocalGameModal } from "../../../../Poker/redux/slices/gameTableSlice";
import html2canvas from "html2canvas";
import { PR_HighHandWin_Click, PR_HighHandWin_View } from "../../../../Poker/data/ClevertapConstants";
import CleverTapUtil from "../../../utils/CleverTapUtil";
import { logCleverTapEvent } from "../../../utils/CleverTapUtilNew";
// import downloadjs from "downloadjs";

export const AcknowledgeHighStrengthWin = (props) => {
  const { tempTableId, modalConfig } = props;
  const pageRef = useRef(null);
  const dispatch = useDispatch();
  const playerData = useSelector((state) => state.lobby.player);
  const isFileSaveEnabled = useSelector((state) => state.lobby.isFileSaveEnabled);
  const playerAvatar = useSelector((state)=> state.gameTable.playerAvatar);
  const totalPot = useSelector(
    (state) => state.gameTable.games[tempTableId].totalPotAmt
  );
  const { smallBlind, bigBlind } = useSelector(
    (state) => state.gameTable.games[tempTableId].gameDefinition
  );

  const [ totalPotAmount, setTotalPotAmount] = useState("");

  const isWinnerDeclared = useSelector(
      (state) => state.gameTable.games[tempTableId]?.isWinnerDeclared
  );
  const sitOutOption = useSelector(
      (state) => state.gameTable.games[tempTableId].sitOutOption
  );

  const gameId = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameId
  );
  const currentGameType = useSelector((state) => state.gameTable.games[tempTableId]?.gameDefinition?.gameType);

  const [randomIndex, setRandomIndex] = useState(Math.floor(Math.random() * 5)); // will return a number between 0 to 4

  const getTagline = (handType) => {
    if(Taglines?.[handType.toUpperCase()]){
      return Taglines[handType.toUpperCase()][randomIndex];
    }
  };
  const channel = useSelector((store) => store.lobby.channel);
  


  useEffect(()=>{
    if(!isWinnerDeclared && !sitOutOption && gameId!=""){
      dispatch(closeLocalGameModal({tempTableId: tempTableId }))
    }
  },[isWinnerDeclared])

  let timeoutId;

  useEffect(()=>{
    setTotalPotAmount(totalPot);
    triggerCTEvent("view")
    timeoutId = setTimeout(()=>{
      dispatch(closeLocalGameModal({tempTableId: tempTableId }))
      clearTimeout(timeoutId);
    },10000)
  },[])

  const [userAvatar, setUserAvatar] = useState('')


  // const splitDataURLIntoChunks = (data, chunkSizeinKB) => {
  //     const chunkSize = chunkSizeinKB * 1024; // Convert KB to bytes
  //     const chunks = [];

  //     for(let index = 0; index < data.length; index += chunkSize) {
  //       chunks.push(data.substring(index, index + chunkSize));
  //     }
  //     return chunks;
  // }
  // const chunks = splitDataURLIntoChunks(testURL, 500);

  const triggerCTEvent = (type) => {
    let timeStamp = getISTDateTimeFormat();
    let params = {
      "Username": playerData.userName,
      "Channel": channel,
      "Blinds" :  `${smallBlind}/${bigBlind}`,
      "Hand strength" : modalConfig.handType,
      "Game format" : currentGameType,
      "Date" : timeStamp,
    }
    
    let eventName = (type === "view" ? PR_HighHandWin_View : PR_HighHandWin_Click)
    if(type !== "view"){
      params={
        ...params,
        "Click Type" : type === "save" ? "Save" : "Share"
      }
    }
    logCleverTapEvent(eventName, params);
  }


  const saveHandStrength = async (type) => {
    triggerCTEvent(type)
    const canvas = await html2canvas(pageRef.current,
      {
        scale: 3
      }
    );
    
    const dataURL = canvas.toDataURL('image/png');
    let payloadObj = {
      type: "imagedataurl",
      request: type,
      dataurl: dataURL,
      // chunkcount: `${index + 1}/${chunks.length}`,
      // chunkfinished: index === chunks.length - 1 ? "true" : "false",
      fileName: playerData.userName
    }
    if(type === "share"){
      payloadObj = {
        ...payloadObj,
        // shareMessage : `🎉I’ve won big playing on A23 Poker! 🎉 Want to experience the thrill of victory too? Join me on A23 Poker and start your winning streak today! 🃏💰
        //     👉Download the app now and get started:`,
        shareMessage: `🎉I’ve won big playing on A23 Poker!🎉
Want to experience the thrill of victory too? Join me on A23 Poker and start your winning streak today! 🃏💰
👉Download the app now and get started:`,
        shareUrl: process.env.REACT_APP_SHARE_URL
      }
    }
    else{
      payloadObj = {
        ...payloadObj,
        shareMessage : "Image saved"
      }
    }
    messageToNativeClient(payloadObj);
    // downloadjs(dataURL, `${modalConfig.handType}.png`, 'image/png');
    // handleDownload(dataURL)
    
  }

  return (
    <>
    <Wrapper>
      <Acknowledgement ref={pageRef}>
        <AcknowlegeImage src={specialMessage} />
        <HandLabel>{modalConfig.handType}</HandLabel>
        {/* <CongratulationsLabel>CONGRATULATIONS </CongratulationsLabel> */}
        <InfoBox>
          <ImageContainer>
            <PlayerAvatar src={(playerData.avatar).includes("avatar1") ? defaultAvatar : playerAvatar!=null ? playerAvatar :  playerData.avatar} />
            <PlayerName>{playerData.userName}</PlayerName>
          </ImageContainer>
          <DetailsContainer>
            <DetailsBox>
              <Details color={colors.pebbleGray}>Blinds: </Details>
              <Details>
                {smallBlind}/{bigBlind}
              </Details>
            </DetailsBox>

            <DetailsBox>
              <Details color={colors.pebbleGray}>Pot: </Details>
              <Details>
                {parseDecimalValuesOfNumber(
                  totalPotAmount,
                  2,
                  checkToDisplayValueInK(totalPotAmount),
                  checkToDisplayValueInL(totalPotAmount)
                )}{" "}
                ({parseDecimalValuesOfNumber(totalPotAmount / bigBlind, 1)}BB)
              </Details>
            </DetailsBox>
            <DetailsBox>
              <GameLabel>HOLD'EM</GameLabel>
            </DetailsBox>
          </DetailsContainer>
        </InfoBox>
        <TagLine>
          <Details color={colors.primaryGold} weight={600}>
            {getTagline(modalConfig.handType)}
          </Details>
        </TagLine>
      </Acknowledgement>
      {isFileSaveEnabled ?
      <ButtonWrapper>
        <SecondaryCTAButton onClick={()=>saveHandStrength("share")}>Share</SecondaryCTAButton>
        <PrimaryCTAButton onClick={()=>saveHandStrength("save")}>Save</PrimaryCTAButton>
      </ButtonWrapper> : ""}
    </Wrapper>
    </>
  );
};
