import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  bottom: 0px;
  height: 50vh;
  width: 100%;
  animation: ${(props) =>
    props.sliderDirection === "open"
      ? "themes-move-up forwards 0.25s"
      : "themes-move-down forwards 0.25s"};
  background: rgba(4, 42, 52, 1);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  animation: ${(props) =>
    props.sliderDirection === "open"
      ? "themes-move-up forwards 0.25s"
      : "themes-move-down forwards 0.25s"};
`;

export const HostControlBody = styled.div`
  padding: 3%;
`;


export const OptionsRow = styled.div`
  display: flex;
  justify-items: flex-start;
  flex-direction: row;
  align-items: center;
  padding: 6% 2%;
  border-bottom:1px solid #FFFDEA26;

  &:last-child{
    border-bottom: none;
  }
`;

export const OptionsIcon = styled.img`
width: 22px;
margin-right: 5%;
`;
