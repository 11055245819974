import React, { useEffect, useRef, useState } from "react";
import moment from "moment";

import goldWinnerBadge from "../../../assets/lobby/GoldWinnerBadge.svg";
import goldChipsStackIcon from "../../../assets/lobby/GoldChipsStack.svg";
import goldNewTrophyIcon from "../../../assets/lobby/GoldNewTrophyIcon.svg";
import tileDividerIcon from "../../../assets/lobby/TileDivider.svg";
import playIcon from "../../../assets/lobby/PlayIcon.svg";
import multiPlayersIcon from "../../../assets/lobby/MultiplePlayersIcon.svg";
import dLetterIcon from "../../../assets/lobby/DIcon.svg";
import leaderboardSmallIcon from "../../../assets/lobby/LeaderboardSmallIcon.svg";
import rightGreenArrowIcon from "../../../assets/lobby/RightGreenArrow.svg";
import podiumIcon from "../../../assets/lobby/PodiumIcon.svg";
import grayDoneIcon from "../../../assets/lobby/DoneGrayIcon.svg";
import cancelledGrayIcon from "../../../assets/lobby/CancelledGrayIcon.svg";
import goldDoneTickIcon from "../../../assets/lobby/GoldDoneTickIcon.svg";
import missedGrayIcon from "../../../assets/lobby/MissedGrayIcon.svg";
import tourneyTileDivider from "../../../assets/lobby/TourneyTileDivider.svg";
import TrophySmallIcon from "../../../assets/gameTable/TrophySmallIcon.svg";
import goldTicket from "../../../assets/lobby/GoldTicket.svg";
import * as Constants from "../../data/Constants.js";

import {
  TourneyNameAndTypeWrapper,
  RegCountAndMaxPlayerWrapper,
  TourneyGameTileContainer,
  TourneyTileHeaderWrapper,
  LateRegStatusText,
  TimerText,
  RegPlayerCountText,
  TourneyTileDetailsWrapper,
  PrizeWrapper,
  BuyInFeeWrapper,
  WinnersDetailsWrapper,
  TrophyIconWrapper,
  ChipsIconWrapper,
  WinnerBadgeIcon,
  TourneyDetailsTextWrapper,
  TourneyDetailPrimaryText,
  TourneyDetailSecondaryText,
  Divider,
  PlayIconWrapper,
  TourneyFooterWrapper,
  TourneyNameText,
  TourneyTypeWrapper,
  TourneyTypeText,
  BonusBarWrapper,
  BonusBarFilled,
  GroupPlayerIconElement,
  StatusText,
  MissedStatusText,
  GameTypeIconsWrapper,
  GameTypeIconElement,
  TourneyFooterCardIconsAndTypeWrapper,
  DetailsWrapper,
  DetailsText,
  ArrowIcon,
  WinnersText,
  TourneyTileDynamicCtaOrStatusWrapper,
  ButtonTextWrapper,
  ButtonPrimaryText,
  ButtonSecondaryText,
  JoinBackText,
  AnnouncedText,
  TourneyStatusWrapper,
  CompletedText,
  TourneyStatusIconWrapper,
  CancelledText,
  TimeText,
  DividerWrapper,
  YouWonText,
  RegistrationDetailsWrapper,
  RegStartsText,
  RegStartsTimeText,
  GoldOpenIcon,
  RegisrationsFullText,
  TicketIcon,
  NoTicketButtonWrapper,
  MediumTicketIcon,
  AddIconText,
  IconsAndDetailsWrapper,
  MissedText,
  TicketsCountText,
  LateRegRunningTimerWrapper,
  LateRegTimerText,
  TourneyGameFlightTileContainer,
  FlightTourneyTrophyIconWrapper,
  FlightPrizeWrapper,
} from "./tourneyTileCardStyles";
import {
  TICKET_BASED,
  TOURNEY_ANNOUNCED,
  TOURNEY_MAP,
  TOURNEY_REGISTRATION_ENDED,
  TOURNEY_REGISTRATION_STARTED,
  TOURNEY_STATUS_CANCELLED,
  TOURNEY_STATUS_COMPLETED,
  TOURNEY_STATUS_LATE_REGISTRATION,
  TOURNEY_STATUS_LIVE,
  TOURNEY_STATUS_MISSED,
  TOURNEY_STATUS_WON,
} from "../../data/Constants";
import { messageToNativeClient } from "../../../Common/utils/platformCommunicationUtil";
import {
  requestTourneyEntriesDynamicData,
  requestTourneyInfoData,
  requestTourneyTablesDynamicData,
  requestUpdatedTourneyPlayerStatus,
} from "../../redux/slices/lobbySlice";
import { useDispatch, useSelector } from "react-redux";
import CleverTapUtil from "../../../Common/utils/CleverTapUtil";
import { IP_Client_TourneyTile_Click } from "../../data/ClevertapConstants";
import Button from "../../../Common/components/Button/Button";
import colors from "../../../Common/styleGuide/Colors";
import {
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  getISTDateTimeFormat,
  getISTDateTimeFormatFromEpochFormat,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";

import { openModal } from "../../redux/slices/modalSlice";
import { logCleverTapEvent } from "../../../Common/utils/CleverTapUtilNew.js";
import { getEventParams } from "../../utils/MixPanelUtils.js";
import { MP_POKER_TOURNEY_TILE_CLICKED } from "../../data/MixPanelConstant.js";

function TourneyTileCard(props) {
  const { tourneyDetails, hideStatusSection } = props;
  const dispatch = useDispatch();
  const userBalance = useSelector((state) => state.lobby.balance);
  const channel = useSelector((store) => store.lobby.channel);
  const player = useSelector((store) => store.lobby.player);
  const applicableLeaderboards = useSelector(
    (store) => store.lobby.applicableLeaderboards
  );
  const activeGames = useSelector((state) => state.gameTable.games);
  const tourneyTickets = useSelector((state) => state.lobby.tourneyTickets);
  const [lateRegEndTimestamp, setLateRegEndTimestamp] = useState(0);
  const lateRegEndTimerIntervalRef = useRef(null);
    const pushToKyc = useSelector(
      (state) => state.lobby.pushToKyc
    );
    const cashGames_banner = useSelector(
      (state) => state.lobby.cashGames_banner
    );
    const userLocationCompliance = useSelector( 
      (state) => state.lobby.userLocationCompliance
    );
    const location_games = useSelector(
      (state) => state.lobby.location_games
    );
  const currentLobbyTime = useSelector((state) => state.lobby.currentLobbyTime);
  const {
    tourneyName,
    tourneyStatus,
    tournamentType,
    breakEven,
    dynamicPoolPrize,
    prizePool,
    buyIn,
    tourneyRake,
    displayWinners,
    registeredPlayerCount,
    maxPlayers,
    tourneyStartTime,
    tourneyIdentifier,
    isAlreadyRegisteredTourney,
    lateRegistrationAllowed,
    lateRegistrationStatus,
    tourneyPlayerStatus,
    registrationStartTime,
    tourneyId,
    prizePoolTickets,
    lateRegEnded,
    lateRegEndTime,
    isFeatured,
    isFlightTourney,
    isFlightParentTourney,
  } = tourneyDetails;
  const formatTourneyDate = (timeStamp, formatString) => {
    try {
      return moment.unix(timeStamp).format(formatString);
    } catch (error) {
      console.warn("ERROR IN FORMATTING DATE IN TOURNEY GAME TILE:", error);
    }
  };

  useEffect(() => {
    // Ensure we initialize the timer only once
    if (
      tourneyStatus === TOURNEY_STATUS_LIVE &&
      !lateRegEnded &&
      lateRegEndTime > 0 &&
      lateRegEndTimerIntervalRef.current === null
    ) {
      handleLateRegisterTimer(); // Set the initial timestamp
      runLateRegisterEndsInTimer(); // Start the interval
    }

    return () => {
      // Cleanup interval on unmount or dependency changes
      if (lateRegEndTimerIntervalRef.current) {
        clearInterval(lateRegEndTimerIntervalRef.current);
        lateRegEndTimerIntervalRef.current = null;
      }
    };
  }, [tourneyStatus, lateRegEnded, lateRegEndTime, currentLobbyTime]);

  const handleLateRegisterTimer = () => {
    if (lateRegEndTime > 0 && tourneyStatus === TOURNEY_STATUS_LIVE) {
      // Calculate the difference using the most accurate current time
      const now = new Date().getTime();
      const difference = lateRegEndTime * 1000 - now;
      setLateRegEndTimestamp(Math.max(Math.floor(difference / 1000), 0));
    }
  };

  // const getStatusTextForTourney = (status) => {
  //   switch (status) {
  //     case TOURNEY_ANNOUNCED:
  //       return <StatusText>ANNOUNCED</StatusText>;
  //     case TOURNEY_REGISTRATION_STARTED:
  //     case TOURNEY_REGISTRATION_ENDED:
  //       return <StatusText>OPEN</StatusText>;

  //     case TOURNEY_STATUS_LIVE:
  //       return <StatusText>LIVE</StatusText>;
  //     case TOURNEY_STATUS_CANCELLED:
  //       return <LateRegStatusText>CANCELLED</LateRegStatusText>;
  //     case TOURNEY_STATUS_MISSED:
  //       return <MissedStatusText>MISSED</MissedStatusText>;

  //     case TOURNEY_STATUS_LATE_REGISTRATION:
  //       return <LateRegStatusText>LATE REG</LateRegStatusText>;
  //     case TOURNEY_STATUS_WON:
  //       return <StatusText>YOU WON</StatusText>;
  //     case TOURNEY_STATUS_COMPLETED:
  //       return <StatusText>COMPLETED</StatusText>;
  //   }
  // };

  const runLateRegisterEndsInTimer = () => {
    lateRegEndTimerIntervalRef.current = setInterval(() => {
      setLateRegEndTimestamp((prev) => {
        if (prev <= 1) {
          clearInterval(lateRegEndTimerIntervalRef.current);
          lateRegEndTimerIntervalRef.current = null;
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const getTourneyTypeDisplayLabel = (tourneyType) => {
    switch (tourneyType) {
      case Constants.TOURNEY_TYPES.SIT_AND_GO:
        return "Sit & Go";
      case Constants.TOURNEY_TYPES.TICKET:
        return "Ticket";
      case Constants.TOURNEY_TYPES.FLIGHT_PARENT:
      case Constants.TOURNEY_TYPES.FLIGHT_CHILD:
        return "FLIGHT";
      default:
        return tourneyType;
    }
  };

  const isLeaderboardPresent = (
    tourneyId,
    tournamentType,
    tourneyStartTime
  ) => {
    for (const element of applicableLeaderboards.tourney) {
      let current = element;
      if (current.tourneySeries) {
        if (
          current.tourneyIds.includes(tourneyId) &&
          tourneyStartTime * 1000 >= current.startTime &&
          tourneyStartTime * 1000 <= current.endTime
        ) {
          return true;
        }
      } else {
        for (const element of current.gameDefIds) {
          let tourneyType = Number(element.split("_")[2]);
          tourneyType = Object.keys(TOURNEY_MAP).find(
            (key) => TOURNEY_MAP[key] === tourneyType
          );

          if (
            tournamentType === tourneyType &&
            tourneyStartTime * 1000 >= current.startTime &&
            tourneyStartTime * 1000 <= current.endTime
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const getTourneyTypeIcon = (tourneyDetails) => {
    const {
      winTheButton,
      tourneyId,
      gameType,
      tourneyStartTime,
      tournamentType,
    } = tourneyDetails;
    let icons = [];
    if (winTheButton) {
      icons.push(<GameTypeIconElement key={"icon-1"} src={dLetterIcon} />);
    }
    if (
      isLeaderboardPresent(
        tourneyId,
        tournamentType,
        tourneyStartTime,
        gameType
      )
    ) {
      icons.push(
        <GameTypeIconElement
          key={"leaderboard-icon"}
          src={leaderboardSmallIcon}
        />
      );
    }
    return icons;
  };

  const checkUserHasTicket = (tourneyId) => {
    let ticketTourneyIds = tourneyTickets.map((eachTicket) => {
      return eachTicket.tourneyId;
    });
    return ticketTourneyIds.includes(tourneyId);
  };

  const checkTourneyGameTableIsAvailable = () => {
    try {
      let isAlreadyActivePlayer = false;
      Object.values(activeGames).forEach((eachGame) => {
        if (
          eachGame.gameDefinition.tourneyId === tourneyId &&
          !eachGame.watchPlayer
        ) {
          isAlreadyActivePlayer = true;
        }
      });

      return isAlreadyActivePlayer;
    } catch (e) {
      console.log("ERROR AT JOIN BACK", e);
    }
  };
  const formatSeconds = (seconds) => {
    // Ensure time is non-negative
    if (seconds <= 0) {
      return "0m:0s";
    }

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes}m:${remainingSeconds.toString().padStart(2, "0")}s`;
  };

  const handleTourneyTileClick = (tourneyDetails) => {
    let timeStamp = getISTDateTimeFormat();

    messageToNativeClient({
      type: "plotlineEvents",
      eventName: "PR_Client_TourneyTile_Click",
      eventProperties: {
        userName: player.userName,
        channel: channel,
        currentCashBalance: userBalance.totalBalance,
        tourneyType: tourneyDetails.tournamentType,
        tourneyName: tourneyDetails.tourneyName,
        tourneyId: tourneyDetails.tourneyId,
        entryFee: tourneyDetails.buyIn + tourneyDetails.tourneyRake,
        registerMode: tourneyDetails.isAlreadyRegisteredTourney
          ? tourneyDetails.tournamentType === TICKET_BASED
            ? "Ticket"
            : "Cash"
          : "NA",
        tourneyStartTime: tourneyDetails.tourneyStartTime,
        registrationStartTime: tourneyDetails.registrationStartTime,
        timeStamp,
      },
    });
    const MixPanel_params = getEventParams(
      MP_POKER_TOURNEY_TILE_CLICKED,
      player,
      userBalance,
      channel,
      tourneyDetails
    );

    messageToNativeClient({
      type: Constants.MIXPANEL_EVENT,
      eventName: MP_POKER_TOURNEY_TILE_CLICKED,
      eventProperties: MixPanel_params,
    });
    let tourneyStartTime = getISTDateTimeFormatFromEpochFormat(
      tourneyDetails.tourneyStartTime
    );

    let registrationStartTime = getISTDateTimeFormatFromEpochFormat(
      tourneyDetails.registrationStartTime
    );

    logCleverTapEvent(IP_Client_TourneyTile_Click, {
      Username: player.userName,
      Channel: channel,
      "Wallet balance": userBalance.totalBalance,
      "Tourney type": tourneyDetails.tournamentType,
      "Tourney name": tourneyDetails.tourneyName,
      "Tourney ID": tourneyDetails.tourneyId,
      "Entry fee": tourneyDetails.buyIn + tourneyDetails.tourneyRake,
      "Register mode": tourneyDetails.entryType,
      "Tourney start time": tourneyStartTime,
      "Registration start time": registrationStartTime,
    });
    // if (!player.isAllowLocation) {
    //   if (pushToKyc && cashGames_banner) {
    //     messageToNativeClient({
    //       type: "showKyc",
    //       from: "tourney",
    //       show: true,
    //     });
    //     return;
    //   }
    //   else if (userLocationCompliance && !location_games) {
    //     return dispatch(
    //       openModal({
    //         type: Constants.RESTRICTED_LOCATION_HOURS,
    //       })
    //     );
    //   }
    //   return dispatch(openModal({ type: Constants.RESTRICTED_LOCATION }));
    // }
    if (pushToKyc && cashGames_banner) {
      messageToNativeClient({
        type: "showKyc",
        from: "tourney",
        show: true,
      });
      return;
    }
    else if (!location_games) {
      if (userLocationCompliance) {
        return dispatch(
          openModal({
            type: Constants.RESTRICTED_LOCATION_HOURS,
          })
        );
      }
      else {
        return dispatch(openModal({ type: Constants.RESTRICTED_LOCATION }));
      }
    }
    // else if (pushToKyc && cashGames_banner) {
    //   messageToNativeClient({
    //     type: "showKyc",
    //     from: "tourney",
    //     show: true,
    //   });
    //   return;
    // } 
    //not adding tamil nadu location compliance check for tourney registration since it's handled operationally
    dispatch(
      requestUpdatedTourneyPlayerStatus({
        tourneyId: tourneyDetails?.tourneyId,
      })
    );
    dispatch(requestTourneyInfoData({ tourneyId: tourneyDetails.tourneyId }));
    dispatch(
      requestTourneyEntriesDynamicData({ tourneyId: tourneyDetails.tourneyId })
    );
    dispatch(
      requestTourneyTablesDynamicData({ tourneyId: tourneyDetails.tourneyId })
    );
  };

  const getTourneyCardTime = () => {
    switch (tourneyStatus) {
      case TOURNEY_ANNOUNCED:
      case TOURNEY_REGISTRATION_STARTED:
      case TOURNEY_REGISTRATION_ENDED:
        return (
          <TimerText>
            {`Tourney starts at ${formatTourneyDate(
              tourneyStartTime,
              "Do MMM - h:mm A"
            )}`}
          </TimerText>
        );
      case TOURNEY_STATUS_LIVE:
      case TOURNEY_STATUS_MISSED:
        if (
          !isAlreadyRegisteredTourney &&
          lateRegistrationAllowed &&
          lateRegistrationStatus !== "FINISHED"
        ) {
          return (
            <TimerText>
              {`Reg Ends in`}
              <LateRegRunningTimerWrapper>
                <LateRegTimerText>{`${formatSeconds(
                  lateRegEndTimestamp
                )}`}</LateRegTimerText>
              </LateRegRunningTimerWrapper>
            </TimerText>
          );
        }
        return (
          <TimerText>
            {`Started at ${formatTourneyDate(tourneyStartTime, "h:mm A")}`}
          </TimerText>
        );

      default:
        return <TimeText></TimeText>;
    }
  };

  const getTourneyTileDynamicCtaOrStatus = (status) => {
    if (
      isFlightTourney &&
      isFlightParentTourney &&
      tourneyIdentifier !== "completedTourneys"
    ) {
      return (
        <Button bgColor={colors.ctaPrimaryColor1}>
          <ButtonTextWrapper>
            <ButtonPrimaryText>View Series</ButtonPrimaryText>
          </ButtonTextWrapper>
        </Button>
      );
    }
    switch (status) {
      case TOURNEY_ANNOUNCED:
        return (
          <RegistrationDetailsWrapper>
            <RegStartsText>Reg Starts At</RegStartsText>
            <RegStartsTimeText>
              {formatTourneyDate(registrationStartTime, "h:mmA")}
            </RegStartsTimeText>
          </RegistrationDetailsWrapper>
        );
      case TOURNEY_REGISTRATION_STARTED:
      case TOURNEY_REGISTRATION_ENDED:
        if (isAlreadyRegisteredTourney) {
          return (
            <TourneyStatusWrapper>
              <TourneyStatusIconWrapper src={goldDoneTickIcon} />
              <YouWonText>{`Registered`}</YouWonText>
            </TourneyStatusWrapper>
          );
        } else if (
          tournamentType === Constants.TOURNEY_TYPES.TICKET ||
          checkUserHasTicket(tourneyId)
        ) {
          return checkUserHasTicket(tourneyId) ? (
            <Button bgColor={colors.ctaPrimaryColor1}>
              <ButtonTextWrapper>
                <ButtonPrimaryText>Ticket</ButtonPrimaryText>
                <TicketIcon src={goldTicket} />
              </ButtonTextWrapper>
            </Button>
          ) : (
            <Button bgColor={colors.vampireGrey}>
              <NoTicketButtonWrapper>
                <ButtonPrimaryText>No Ticket</ButtonPrimaryText>
                <TicketIcon src={goldTicket} />
              </NoTicketButtonWrapper>
            </Button>
          );
        } else if (registeredPlayerCount === maxPlayers) {
          return (
            <TourneyStatusWrapper>
              <RegisrationsFullText>FULL</RegisrationsFullText>
            </TourneyStatusWrapper>
          );
        }
        return (
          <Button bgColor={colors.ctaPrimaryColor1}>
            <ButtonTextWrapper>
              <ButtonPrimaryText>Register for</ButtonPrimaryText>
              {buyIn + tourneyRake === 0 ? (
                <ButtonSecondaryText>Free</ButtonSecondaryText>
              ) : (
                <ButtonSecondaryText>{`₹${
                  buyIn + tourneyRake
                }`}</ButtonSecondaryText>
              )}
            </ButtonTextWrapper>
          </Button>
        );
      case TOURNEY_STATUS_LIVE:
        if (
          !isAlreadyRegisteredTourney &&
          lateRegistrationAllowed &&
          lateRegistrationStatus !== "FINISHED"
        ) {
          return checkUserHasTicket(tourneyId) ? (
            <Button bgColor={colors.ctaPrimaryColor1}>
              <ButtonTextWrapper>
                <ButtonPrimaryText>Ticket</ButtonPrimaryText>
                <TicketIcon src={goldTicket} />
              </ButtonTextWrapper>
            </Button>
          ) : (
            <Button bgColor={colors.ctaPrimaryColor1}>
              <ButtonTextWrapper>
                <ButtonPrimaryText>Late Reg for </ButtonPrimaryText>
                {buyIn + tourneyRake === 0 ? (
                  <ButtonSecondaryText>Free</ButtonSecondaryText>
                ) : (
                  <ButtonSecondaryText>{`₹${
                    buyIn + tourneyRake
                  }`}</ButtonSecondaryText>
                )}
              </ButtonTextWrapper>
            </Button>
          );
        } else if (
          tourneyPlayerStatus === Constants.TOURNEY_PLAYER_STATUS_RE_ENTRY
        ) {
          return (
            <Button bgColor={colors.ctaPrimaryColor1}>
              <ButtonTextWrapper>
                <ButtonSecondaryText>Re-Entry</ButtonSecondaryText>
              </ButtonTextWrapper>
            </Button>
          );
        } else if (
          isAlreadyRegisteredTourney &&
          checkTourneyGameTableIsAvailable()
        ) {
          return (
            <Button bgColor={"transparent"} className={"join-back-cta"}>
              <JoinBackText>Join Back</JoinBackText>
            </Button>
          );
        } else {
          return (
            <TourneyStatusWrapper>
              {/* <TourneyStatusIconWrapper src={GoldOpenIcon} /> */}
              <GoldOpenIcon />
              <YouWonText>{`Live`}</YouWonText>
            </TourneyStatusWrapper>
          );
        }

      case TOURNEY_STATUS_CANCELLED:
        return (
          <TourneyStatusWrapper>
            <TourneyStatusIconWrapper src={cancelledGrayIcon} />
            <CancelledText>{`Cancelled`}</CancelledText>
          </TourneyStatusWrapper>
        );
      case TOURNEY_STATUS_MISSED:
        return (
          <TourneyStatusWrapper>
            <TourneyStatusIconWrapper src={missedGrayIcon} />
            <MissedText>{`Missed`}</MissedText>
          </TourneyStatusWrapper>
        );
      case TOURNEY_STATUS_WON:
        if (isFlightTourney && !isFlightParentTourney) {
          return (
            <TourneyStatusWrapper>
              <TourneyStatusIconWrapper src={grayDoneIcon} />
              <CompletedText>{`Qualified`}</CompletedText>
            </TourneyStatusWrapper>
          );
        } else {
          return (
            <TourneyStatusWrapper>
              <TourneyStatusIconWrapper src={TrophySmallIcon} />
              <YouWonText>{`You Won`}</YouWonText>
            </TourneyStatusWrapper>
          );
        }
      case TOURNEY_STATUS_COMPLETED:
        return (
          <TourneyStatusWrapper>
            <TourneyStatusIconWrapper src={grayDoneIcon} />
            <CompletedText>{`Completed`}</CompletedText>
          </TourneyStatusWrapper>
        );
    }
  };

  const renderTourneyTileCardByType = () => {
    if (isFlightTourney) {
      return (
        <TourneyGameFlightTileContainer
          id={tourneyDetails.tourneyId}
          onClick={() => handleTourneyTileClick(tourneyDetails)}
          highlightTile={tourneyDetails.highlighted}
        >
          <TourneyTileHeaderWrapper>
            <TourneyNameAndTypeWrapper>
              <TourneyNameText>{tourneyName}</TourneyNameText>
              <TourneyTypeWrapper>
                <TourneyTypeText>
                  {getTourneyTypeDisplayLabel(tournamentType)}
                </TourneyTypeText>
              </TourneyTypeWrapper>
            </TourneyNameAndTypeWrapper>

            <IconsAndDetailsWrapper>
              {getTourneyTypeIcon(tourneyDetails)}

              <DetailsWrapper>
                <DetailsText>Details</DetailsText>
                <ArrowIcon src={rightGreenArrowIcon} />
              </DetailsWrapper>
            </IconsAndDetailsWrapper>
          </TourneyTileHeaderWrapper>
          <TourneyTileDetailsWrapper>
            <FlightPrizeWrapper>
              <FlightTourneyTrophyIconWrapper src={goldNewTrophyIcon} />
              <TourneyDetailsTextWrapper>
                <TourneyDetailSecondaryText>
                  {isFlightTourney && !isFlightParentTourney
                    ? "Qualify for"
                    : " Win Upto"}
                </TourneyDetailSecondaryText>

                {isFlightParentTourney ? (
                  <TourneyDetailPrimaryText>
                    {breakEven
                      ? dynamicPoolPrize > 0
                        ? `₹${parseDecimalValuesOfNumber(
                            dynamicPoolPrize,
                            2,
                            checkToDisplayValueInK(dynamicPoolPrize),
                            checkToDisplayValueInL(dynamicPoolPrize)
                          )}`
                        : ""
                      : prizePool > 0
                      ? `₹${parseDecimalValuesOfNumber(
                          prizePool,
                          2,
                          checkToDisplayValueInK(prizePool),
                          checkToDisplayValueInL(prizePool)
                        )}`
                      : ""}

                    {prizePoolTickets > 0 && (
                      <>
                        {((breakEven && dynamicPoolPrize > 0) ||
                          prizePool > 0) && <AddIconText>+</AddIconText>}
                        {((breakEven && dynamicPoolPrize <= 0) ||
                          prizePool <= 0) && (
                          <TicketsCountText>
                            {prizePoolTickets}
                          </TicketsCountText>
                        )}
                        <MediumTicketIcon src={goldTicket} />
                      </>
                    )}
                  </TourneyDetailPrimaryText>
                ) : (
                  <TourneyDetailPrimaryText>
                    {tourneyDetails?.parentTourneyName}
                  </TourneyDetailPrimaryText>
                )}
              </TourneyDetailsTextWrapper>
            </FlightPrizeWrapper>
            <Divider src={tileDividerIcon} />
            {/* <WinnersDetailsWrapper>
              <WinnerBadgeIcon src={goldWinnerBadge} />
              <WinnersText>{`${displayWinners} Winners`}</WinnersText>
            </WinnersDetailsWrapper> */}
            <TourneyTileDynamicCtaOrStatusWrapper>
              {!hideStatusSection &&
                getTourneyTileDynamicCtaOrStatus(tourneyStatus)}
            </TourneyTileDynamicCtaOrStatusWrapper>
          </TourneyTileDetailsWrapper>
          <DividerWrapper src={tourneyTileDivider} />
          <TourneyFooterWrapper>
            <RegCountAndMaxPlayerWrapper>
              {isFlightParentTourney && registeredPlayerCount === 0 ? null : (
                <GroupPlayerIconElement src={multiPlayersIcon} />
              )}
              {tourneyIdentifier === "completedTourneys" ? (
                <RegPlayerCountText>
                  {isFlightParentTourney
                    ? `${displayWinners} Winners`
                    : `${registeredPlayerCount} Qualified`}
                </RegPlayerCountText>
              ) : isFlightParentTourney && registeredPlayerCount > 0 ? (
                <RegPlayerCountText>{`${registeredPlayerCount} Qualified`}</RegPlayerCountText>
              ) : !isFlightParentTourney ? (
                <>
                  <RegPlayerCountText>{`${registeredPlayerCount}/${maxPlayers}`}</RegPlayerCountText>
                  <BonusBarWrapper>
                    <BonusBarFilled
                      fillPercentage={
                        (registeredPlayerCount / maxPlayers) * 100
                      }
                    />
                  </BonusBarWrapper>
                </>
              ) : null}
            </RegCountAndMaxPlayerWrapper>
            {tourneyIdentifier !== "completedTourneys" &&
              tournamentType !== "Sit And Go" &&
              getTourneyCardTime()}
          </TourneyFooterWrapper>
        </TourneyGameFlightTileContainer>
      );
    } else {
      return (
        <TourneyGameTileContainer
          id={tourneyDetails.tourneyId}
          onClick={() => handleTourneyTileClick(tourneyDetails)}
        >
          <TourneyTileHeaderWrapper>
            <TourneyNameAndTypeWrapper>
              <TourneyNameText>{tourneyName}</TourneyNameText>
              <TourneyTypeWrapper>
                <TourneyTypeText>
                  {getTourneyTypeDisplayLabel(tournamentType)}
                </TourneyTypeText>
              </TourneyTypeWrapper>
            </TourneyNameAndTypeWrapper>

            <IconsAndDetailsWrapper>
              {getTourneyTypeIcon(tourneyDetails)}

              <DetailsWrapper>
                <DetailsText>Details</DetailsText>
                <ArrowIcon src={rightGreenArrowIcon} />
              </DetailsWrapper>
            </IconsAndDetailsWrapper>
          </TourneyTileHeaderWrapper>
          <TourneyTileDetailsWrapper>
            <PrizeWrapper>
              <TrophyIconWrapper src={goldNewTrophyIcon} />
              <TourneyDetailsTextWrapper>
                <TourneyDetailSecondaryText>
                  Win Upto
                </TourneyDetailSecondaryText>

                <TourneyDetailPrimaryText>
                  {breakEven
                    ? dynamicPoolPrize > 0
                      ? `₹${parseDecimalValuesOfNumber(
                          dynamicPoolPrize,
                          2,
                          checkToDisplayValueInK(dynamicPoolPrize),
                          checkToDisplayValueInL(dynamicPoolPrize)
                        )}`
                      : ""
                    : prizePool > 0
                    ? `₹${parseDecimalValuesOfNumber(
                        prizePool,
                        2,
                        checkToDisplayValueInK(prizePool),
                        checkToDisplayValueInL(prizePool)
                      )}`
                    : ""}

                  {prizePoolTickets > 0 && (
                    <>
                      {((breakEven && dynamicPoolPrize > 0) ||
                        prizePool > 0) && <AddIconText>+</AddIconText>}
                      {((breakEven && dynamicPoolPrize <= 0) ||
                        prizePool <= 0) && (
                        <TicketsCountText>{prizePoolTickets}</TicketsCountText>
                      )}
                      <MediumTicketIcon src={goldTicket} />
                    </>
                  )}
                </TourneyDetailPrimaryText>
              </TourneyDetailsTextWrapper>
            </PrizeWrapper>
            <Divider src={tileDividerIcon} />
            <WinnersDetailsWrapper>
              <WinnerBadgeIcon src={goldWinnerBadge} />
              <WinnersText>{`${displayWinners} Winners`}</WinnersText>
            </WinnersDetailsWrapper>
            <TourneyTileDynamicCtaOrStatusWrapper>
              {!hideStatusSection &&
                getTourneyTileDynamicCtaOrStatus(tourneyStatus)}
            </TourneyTileDynamicCtaOrStatusWrapper>
          </TourneyTileDetailsWrapper>
          <DividerWrapper src={tourneyTileDivider} />
          <TourneyFooterWrapper>
            <RegCountAndMaxPlayerWrapper>
              <GroupPlayerIconElement src={multiPlayersIcon} />
              <RegPlayerCountText>{`${registeredPlayerCount}/${maxPlayers}`}</RegPlayerCountText>
              <BonusBarWrapper>
                <BonusBarFilled
                  fillPercentage={(registeredPlayerCount / maxPlayers) * 100}
                ></BonusBarFilled>
              </BonusBarWrapper>
            </RegCountAndMaxPlayerWrapper>
            {tourneyIdentifier !== "completedTourneys" &&
              tournamentType !== "Sit And Go" &&
              getTourneyCardTime()}
          </TourneyFooterWrapper>
        </TourneyGameTileContainer>
      );
    }
  };

  return renderTourneyTileCardByType();
}

export default TourneyTileCard;
