import Handler from "../Handler";

class PrivateTableCreateGameDefAckHandler extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processPrivateTableCreateGameDef(receivedData);
  }
}
export default PrivateTableCreateGameDefAckHandler;

// PTCreateGDA#{
//   "success": true,
//   "gdid": 622,
//   "accessCode": "983934",
//   "tableName": "Testing pt"
// }
