import React from "react";
import {
  Typo10GrayContoMediumText,
  Typo12GrayContoMediumText,
  TypoGoldGradientMediumText,
} from "../../styleGuide/Typos";
import { AccessCodeContainer, Box, ShareLogo } from "./accessCodeLayoutStyle";
import { shareIcon } from "../../../Poker/routes/Game/gameAssets";
import { messageToNativeClient } from "../../utils/platformCommunicationUtil";
export const AccessCodeLayout = (props) => {
  const { accessCode } = props;

  const onShareClick = () => {
    messageToNativeClient({
      type: "shareVia",
      message: `Join my Private Poker Table! Click on the link below, and enter the Join Code to start playing.\nClick to play - ${process.env.REACT_APP_SHARE_URL}\nJoin Code - ${accessCode}`,
    });
  };
  return (
    accessCode && (
      <AccessCodeContainer>
        <Box>
          <Typo12GrayContoMediumText>Game Code :</Typo12GrayContoMediumText>
          <TypoGoldGradientMediumText size={32} weight={700} spacing={5}>
            {accessCode}
          </TypoGoldGradientMediumText>
        </Box>
        <Box
          onClick={() => {
            onShareClick();
          }}
        >
          <ShareLogo src={shareIcon}></ShareLogo>
          <Typo10GrayContoMediumText>
            Share code with your friends and start playing
          </Typo10GrayContoMediumText>
        </Box>
      </AccessCodeContainer>
    )
  );
};
