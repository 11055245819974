import styled from "styled-components";
import colors from "../../styleGuide/Colors";
import {
  Typo12SecondaryBrandyPunchContoMediumText,
  Typo12SecondaryLeafyGreenContoMediumText,
  Typo12SecondaryLightCornFlowerBlueContoMediumText,
  Typo12SecondaryRedContoMediumText,
} from "../../styleGuide/Typos";

export const ToastContainer = styled.div`
  width: 100%;
  position: absolute;
  top: ${(props) => (props.isLandscapeOrientation ? 16 : props.isComplianceMessage ? 14 : 8)}%;
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ToastMessage = styled.div`
  color: ${colors.white};
  background-color: ${colors.toastBackgroundColor};
  text-align: center;
  padding: 5px 10px;
  min-width: 160px;
  height: fit-content;
  border-radius: 4px;
  margin: 2% 0;
  font-size: 12px;
  // animation: toast-in-left 0.2s forwards;

  @keyframes toast-in-left {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
`;

export const BaseToastWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 300px;
  width: auto;
  max-width: 80%;
  min-height: 30px;
  height: auto;
  border-radius: 4px;
  padding: 4px 8px 4px 8px;
  margin-bottom: 12px;

  animation: ${(props) =>
    props.fadeOut ? "fadeOut 0.5s forwards" : "fadeIn 0.4s forwards"};

  @keyframes fadeOut {
    0% {
      opacity: 0;
      transform: translateY(0.5rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0rem);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(1.5rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  transition: all 0.2s ease;
`;

export const ErrorToastWrapper = styled(BaseToastWrapper)`
  border: 0.5px solid ${colors.secondaryRed};
  background: ${colors.toastBackgroundColor};
`;

export const InfoToastWrapper = styled(BaseToastWrapper)`
  border: 0.5px solid ${colors.ballBlue};
  background: ${colors.toastBackgroundColor};
`;

export const ErrorToastMessageText = styled(Typo12SecondaryRedContoMediumText)`
  line-height: normal;
`;

export const InfoToastMessageText = styled(
  Typo12SecondaryLightCornFlowerBlueContoMediumText
)`
  line-height: nornal;
`;

export const SuccessToastWrapper = styled(BaseToastWrapper)`
  border: 0.5px solid ${colors.leafyGreen};
  background: ${colors.toastBackgroundColor};
`;

export const SuccessToastMessageText = styled(
  Typo12SecondaryLeafyGreenContoMediumText
)`
  line-height: nornal;
`;

export const WarningToastWrapper = styled(BaseToastWrapper)`
  border: 0.5px solid ${colors.brandyPunch};
  background: ${colors.toastBackgroundColor};
`;

export const WarningToastMessageText = styled(
  Typo12SecondaryBrandyPunchContoMediumText
)`
  line-height: nornal;
  overflow-wrap: anywhere;
`;

export const IconWrapper = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

export const CloseIconWrapper = styled.img`
  width: 10px;
  height: 10px;
  margin-left: 10px;
`;
