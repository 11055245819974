import { getLobbySlice } from "../../Poker/utils/ReduxUtils";
import CleverTapUtil from "./CleverTapUtil";
import { messageToNativeClient } from "./platformCommunicationUtil";

export const logCleverTapEvent = (eventName, paramsObject) => {
    let isCleverTapEnabled = getLobbySlice()?.isCleverTapEnabledFromNative;
    if(isCleverTapEnabled){
        if (paramsObject) {
            let data = {
                type : "webCTEvent",
                eventName:eventName,
                eventProperties : paramsObject
            }
            console.log("Clevertap event(Native call) with Params: ", data);
            messageToNativeClient(data);
        }
    }
    else{
        CleverTapUtil.getInstance().logEvent(eventName, paramsObject)
    }
   

}