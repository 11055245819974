import styled, { keyframes } from "styled-components";
import {
  Typo10GrayContoRegularText,
  Typo10PrimaryGoldContoMediumText,
  Typo10WhiteContoMediumText,
  Typo11GrayContoMediumText,
  Typo11GrayContoRegularText,
  Typo11OffWhiteContoBoldText,
  Typo11WhiteContoBoldText,
  Typo12CasalContoMediumText,
  Typo12GrayContoRegularText,
  Typo12LeafyGreenContoMediumText,
  Typo12PrimaryGoldContoMediumText,
  Typo12WhiteContoBoldText,
  Typo12WhiteContoMediumText,
  Typo14PrimaryGoldContoMediumText,
  Typo16PrimaryGoldBoldText,
  Typo16WhiteContoBoldText,
  Typo16WhiteContoMediumText,
} from "../../../Common/styleGuide/Typos";
import colors from "../../../Common/styleGuide/Colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  margin-bottom: 20px;
  /* height: 100%; */
  max-height: calc(100% - 59px);
  overflow: scroll;
`;

export const TourneyStartsInAndPrizeAmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  justify-content: ${(props) =>
    props.isSitAndGoTourney ? "space-evenly" : "space-between"};
  border-radius: 8px;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
  padding: 12px 24px 12px 24px;
  background: rgba(2, 24, 30, 1);
`;

export const PrizeAmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  align-items: center;
`;

export const TrophyIconWrapper = styled.img`
  height: 48px;
  width: 48px;
  margin-right: 12px;
`;
export const PrizeAmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PrizeWorthText = styled(Typo12GrayContoRegularText)`
  line-height: 16px;
  margin-bottom: 2px;
`;

export const PrizeAmountText = styled(Typo16PrimaryGoldBoldText)`
  line-height: 21px;
  display: flex;
  justify-content: left;
  align-items: center;
`;

export const PrizeBreakupText = styled(Typo12LeafyGreenContoMediumText)`
  line-height: 17px;
  border-bottom: 1.2px solid ${colors.leafyGreen};
`;

export const AddIconText = styled(Typo16WhiteContoMediumText)`
  font-weight: 700;
  margin-left: 4px;
  margin-right: 4px;
`;

export const TicketsCountText = styled(Typo14PrimaryGoldContoMediumText)`
  margin-right: 2px;
`;

export const TicketIcon = styled.img`
  height: 13px;
  width: 13px;
`;

export const FlightTourneysTabbarAndTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  margin-top: 24px;
`;

export const FlightsBasicTabBarrContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
`;

export const FlightTourneyTabItem = styled.div`
  display: flex;
  width: 100%;
  height: 32px;
  border-radius: 6px;
  background: ${(props) => (props.isActive ? "#336070" : "transparent")};
  justify-content: center;
  align-items: center;
  border: ${(props) =>
    props.isActive ? "none" : `1px solid rgba(255, 253, 234, 0.25)`};
`;

export const FlightTourneyTabItemText = styled(Typo12WhiteContoBoldText)`
  line-height: 16.26px;
`;

export const FlightTourneyDetailsTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(5, 58, 71, 0.8) 0%,
    rgba(4, 47, 58, 0.8) 100%
  );
  border: 0.5px solid #336070;
  border-radius: 8px; /* No need for !important */
  height: 100%;
  max-height: 440px;
  overflow-y: scroll;
  margin: 12px 0px;
`;

export const TableHeader = styled.div`
  display: flex;
  width: 100%;
  font-weight: bold;
  border-bottom: 0.5px solid #336070;
`;

export const TableRow = styled.div`
  display: flex;
  width: 100%;
  /* border-bottom: 0.5px solid #336070; */
  border-bottom: 1px solid transparent;
  border-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.2) 0%,
      /* Added black overlay */ rgba(51, 96, 112, 0.2) 10%,
      /* Blend with teal */ rgba(51, 96, 112, 0.8) 33.85%,
      rgba(51, 96, 112, 0.8) 66.67%,
      rgba(51, 96, 112, 0.2) 90%,
      rgba(0, 0, 0, 0.2) 100% /* Black overlay at the end */
    )
    1;
`;

export const Column = styled.div`
  padding: 10px;
  text-align: ${(props) => (props.align ? props.align : "start")};
  width: ${(props) => props.width || "25%"};
`;

export const TableHeaderText = styled(Typo11GrayContoRegularText)`
  line-height: 14.91px;
`;

export const TableItemValueText = styled(Typo10WhiteContoMediumText)``;

export const RunningStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const DetailsWrapper = styled.div`
  border-bottom: 1.2px solid ${colors.leafyGreen};
`;

export const CompletedWrapper = styled.div`
  border-bottom: 1.2px solid ${colors.pebbleGray};
`;

export const CompletedTextWrapper = styled(Typo10PrimaryGoldContoMediumText)`
  line-height: 14px;
  color: ${colors.pebbleGray};
`;

export const DetailsTextWrapper = styled(Typo10PrimaryGoldContoMediumText)`
  line-height: 14px;
  color: ${colors.leafyGreen};
`;

export const StatusAccordianIconWrapper = styled.img`
  height: 8px;
  width: 5px;
  margin-left: 4px;
`;

export const AccordianIconWrapper = styled.img`
  height: 5px;
  width: 8px;
  margin-left: 4px;
  transform: ${(props) => (props.isOpen ? "rotate(360deg)" : "rotate(180deg)")};
`;

export const ChildFlightTourneyDetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 6px 0px 0px 0px;
  /* border-top: 1px solid var(--Color-Greens-Secondary-Teal, #336070); */
  box-sizing: border-box;
  padding: 12px 16px;
`;

export const ChildFlightTourneyDetails = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid var(--Color-Greens-Secondary-Teal, #336070);
`;

export const TableRowDetailsAndChildTourneyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

export const TableColumnElementsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
`;

export const ChildTourneyStackDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ChildTourneyStackItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 12px;
  width: 33%;
`;

export const StackDetailsText = styled(Typo10GrayContoRegularText)`
  line-height: 13.55px;
`;

export const StackDetailsValueText = styled(Typo10WhiteContoMediumText)`
  line-height: 13.55px;
`;

export const TourneyStartAndEndTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #053c4a;
  height: 26px;
  width: 100%;
  margin-top: 12px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 0px 12px;
`;

export const TourneyTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const FlightTourneyParentTourneyContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 58px;
  padding: 12px 16px 12px 12px;
  box-sizing: border-box;
  background: transparent;
  border: 1px solid rgba(7, 80, 99, 1);
  border-radius: 8px;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;
`;

export const IconAndTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const IconWrapper = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const RotatingIcon = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 36px;
  width: 36px;

  animation: ${rotate} 8s linear infinite;
`;

export const SimilarFlightsText = styled(Typo12PrimaryGoldContoMediumText)`
  line-height: 16.26px;
  margin-left: 4px;
`;

export const CenterIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 24px;
  width: 24px;
  transform: translate(-50%, -50%);
`;
export const ParentTourneyText = styled(Typo12WhiteContoMediumText)`
  line-height: 16.26px;
  margin-left: 4px;
`;

export const EmptyTourneysContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 250px;
  /* border: 1px solid red; */
`;

export const GameConsoleImageWrapper = styled.img`
  height: 80px;
  width: 80px;
`;

export const EmptyTourneysText = styled(Typo12CasalContoMediumText)`
  width: 60%;
  text-align: center;
  margin-top: 16px;
`;

export const BlindsRightArrow = styled.img`
  height: 8px;
  width: 5px;
  margin-left: 4px;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: var(--app-width);
  height: var(--app-height);
  position: absolute;

  bottom: 0px;
  left: 0px;
  z-index: 5;
  background: ${colors.black60};
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
`;

export const QualifiedPlayersModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 0px;
  min-height: 450px;
  overflow: scroll;
  /* background: ${colors.tourneyInfoBackground}; */
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg, #075063, #075063);

  border-radius: 12px 12px 0px 0px;

  /* animation: expansion 0.25s linear;

  @keyframes expansion {
    from {
      min-height: 0px;
    }
    to {
      min-height: 320px;
    }
  } */

  animation: ${(props) =>
    props.isOpen
      ? "themes-move-up forwards 0.25s"
      : "themes-move-down forwards 0.25s"};

  @keyframes themes-move-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes themes-move-down {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 12px 12px 0px 0px;
  padding: 16px 24px;
  box-sizing: border-box;
  background-color: ${colors.deepTeal};
`;

export const HeaderText = styled(Typo16WhiteContoBoldText)`
  line-height: normal;
  margin: 0 auto;
`;

export const CloseIconWrapper = styled.img``;

export const ModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  margin-top: 4px;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 1px solid ${colors.secondaryBlue};
  /* background: ${colors.tourneyInfoBackground}; */
  border-radius: 4px;
  overflow: scroll;
  height: 360px;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 16px;
`;

export const SectionIconWrapper = styled.span`
  margin-right: 4px;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background-color: ${(props) => (props.fill ? props.fill : colors.casal)};
`;

export const SectionTitleText = styled(Typo11WhiteContoBoldText)`
  line-height: 14.91px;
`;

export const ParentPrizeWorthText = styled(Typo12GrayContoRegularText)`
  line-height: 16px;
  margin-bottom: 2px;
  margin-left: 12px;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
