import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../Common/components/Button/Button";
import {
  ActiveIndicator,
  AlreadyInvitedContainer,
  CompletedIcon,
  CreateTableButton,
  CreateTableLabel,
  EnterCodeFooter,
  GameTileContainer,
  HostedLobbyContainer,
  HostedTableDetails,
  HostedTableWrapper,
  HostTableBox,
  HostTableLabel,
  Image,
  ImageWrapper,
  LandingPageWrapper,
  PlusIcon,
  SectionHeader,
  StarImage,
  SubLabel,
  TextOnTable,
} from "./createPrivateTableStyle";
import star from "../../../../assets/lobby/star.png";
import completedPrivateTable from "../../../../assets/lobby/completedPT.png";
import privateTable from "../../../../assets/lobby/privateTable.png";
import hostTableIcon from "../../../../assets/lobby/hosted-table-icon.png";
import addIcon from "../../../../assets/lobby/goldAddIcon.png";

import {
  Typo11LightYellowContoMediumText,
  Typo14LightYellowContoMediumText,
  Typo14PrimaryGoldContoMediumText,
  Typo16OffWhiteContoBoldText,
} from "../../../../Common/styleGuide/Typos";
import {
  CREATE_PRIVATE_TABLE_SLIDER,
  PRIVATE_TABLE_ENTER_CODE_SLIDER,
  PRIVATE_TABLE_LOBBY,
  TOAST_INFO,
} from "../../../data/Constants";
import { openSlider } from "../../../redux/slices/sliderSlice";
import Slider from "../../../../Common/components/Slider/Slider";
import {
  addLobbyToastMessage,
  fetchPrivateTableCreationConfigs,
} from "../../../redux/slices/lobbySlice";
import { GameTile } from "./GameTile/GameTile";
import ActiveGamesStickyButton from "../ActiveGamesStickyButton";

export const CreatePrivateTableLobby = (props) => {
  const dispatch = useDispatch();
  const sliderStatus = useSelector((state) => state.slider.visible);
  const sliderType = useSelector((state) => state.slider.type);
  const accessToken = useSelector((state) => state.lobby.accessToken);
  const playerId = useSelector((state) => state.lobby.player.userId);
  const { activeTables, completedTables } = useSelector(
    (state) => state.lobby.privateTable.lobbyData
  );
  const hostedTableCount = useSelector(
    (state) => state.lobby.privateTable.hostedTableCount
  );

  const { allowedToHostPT, allowedToJoinPT } = useSelector(
    (state) => state.lobby.privateTable
  );

  useEffect(() => {
    let payload = {
      userId: playerId,
    };

    let headerVar = {
      headers: {
        Authorization: accessToken,
      },
    };

    dispatch(
      fetchPrivateTableCreationConfigs({
        apiURL: process.env.REACT_APP_FETCH_PRIVATE_TABLE_CREATION_CONFIG,
        payload: payload,
        headers: headerVar,
      })
    );

      // default configs
      dispatch(
        fetchPrivateTableCreationConfigs({
          apiURL: process.env.REACT_APP_PRIVATE_TABLE_DEFAULT_CONFIG,
          payload: payload,
          headers: headerVar,
        })
      );
  }, []);


  const onCreateTableClickHandler = () => {
    if (allowedToHostPT) {
      dispatch(
        openSlider({
          type: CREATE_PRIVATE_TABLE_SLIDER,
        })
      );
    } else {
      dispatch(
        addLobbyToastMessage({
          type: TOAST_INFO,
          message: "You do not have access to host a table",
        })
      );
    }
  };

  const enterCodeClickHandler = () => {
    if (allowedToJoinPT) {
      dispatch(
        openSlider({
          type: PRIVATE_TABLE_ENTER_CODE_SLIDER,
        })
      );
    } else {
      dispatch(
        addLobbyToastMessage({
          type: TOAST_INFO,
          message: "You do not have access to join a table",
        })
      );
    }
  };

  const showPrivateTableHostedLobby = () => {
    return (
      <>
        <HostedLobbyContainer>
          <HostedTableWrapper>
            <HostedTableDetails>
              <Image src={hostTableIcon} />
              <HostedTableDetails showHostedCount>
                <Typo11LightYellowContoMediumText>
                  Hosted
                </Typo11LightYellowContoMediumText>
                <Typo14LightYellowContoMediumText>
                  {hostedTableCount} Tables
                </Typo14LightYellowContoMediumText>
              </HostedTableDetails>
            </HostedTableDetails>
            <CreateTableLabel onClick={() => onCreateTableClickHandler()}>
              <PlusIcon src={addIcon} />
              <Typo14PrimaryGoldContoMediumText>
                Create Table
              </Typo14PrimaryGoldContoMediumText>
            </CreateTableLabel>
          </HostedTableWrapper>
          <GameTileContainer>
            {createActiveTableTiles()}
            {createCompletedTableTiles()}
          </GameTileContainer>
        </HostedLobbyContainer>
        <EnterCodeFooter>
          <Typo14LightYellowContoMediumText>
            Invited to a table?
          </Typo14LightYellowContoMediumText>
          <Button
            buttonText={"Enter Code"}
            clickHandler={enterCodeClickHandler}
          ></Button>
        </EnterCodeFooter>
      </>
    );
  };

  const createActiveTableTiles = () => {
    if (Object.keys(activeTables).length > 0) {
      return (
        <>
          <SectionHeader>
            <ActiveIndicator></ActiveIndicator>
            <Typo14LightYellowContoMediumText>
              Active Tables
            </Typo14LightYellowContoMediumText>
          </SectionHeader>
          {Object.values(activeTables).map((activeTile) => {
            return (
              <GameTile
                gameDefinition={{ type: PRIVATE_TABLE_LOBBY, ...activeTile }}
              />
            );
          })}
        </>
      );
    }
  };
  const createCompletedTableTiles = () => {
    if (Object.keys(completedTables).length > 0) {
      return (
        <>
          <SectionHeader>
            <CompletedIcon src={completedPrivateTable}></CompletedIcon>
            <Typo14LightYellowContoMediumText>
              Completed Tables
            </Typo14LightYellowContoMediumText>
          </SectionHeader>
          {Object.values(completedTables).map((completedTile) => {
            return (
              <GameTile
                gameDefinition={{ type: PRIVATE_TABLE_LOBBY, ...completedTile }}
              />
            );
          })}
        </>
      );
    }
  };

  const checkIfLobbyDataExists = () => {
    return (
      Object.keys(activeTables).length > 0 ||
      Object.keys(completedTables).length > 0
    );
  };

  const createTableLandingPage = () => {
    return (
      <>
        <ImageWrapper>
          <Image src={privateTable} />
          <TextOnTable>
            <Typo16OffWhiteContoBoldText>
              Create a table & <br /> Play with friends or new opponents
            </Typo16OffWhiteContoBoldText>
          </TextOnTable>
          <CreateTableButton
            onClick={() => {
              onCreateTableClickHandler();
            }}
          >
            Create Table
          </CreateTableButton>
        </ImageWrapper>
        <AlreadyInvitedContainer>
          <Typo14LightYellowContoMediumText>
            Already invited to a table?
          </Typo14LightYellowContoMediumText>
          <Button
            buttonText={"Enter Code"}
            clickHandler={enterCodeClickHandler}
          ></Button>
        </AlreadyInvitedContainer>
      </>
    );
  };
  return (
    <LandingPageWrapper>
      <HostTableBox>
        <StarImage src={star} width={12} top={0} left={8} />
        <HostTableLabel>Host a Table</HostTableLabel>
        <SubLabel>
          Gather your friends or meet new players at the tables
        </SubLabel>
        <StarImage src={star} width={8} right={-10} top={40} />
      </HostTableBox>

      {checkIfLobbyDataExists()
        ? showPrivateTableHostedLobby()
        : createTableLandingPage()}
      {sliderStatus && sliderType ? <Slider /> : null}
      {!sliderStatus && (
        <ActiveGamesStickyButton
          tileScrollStatus={props.tileScrollStatus}
          toggleGameTable={props.toggleGameTable}
        />
      )}
    </LandingPageWrapper>
  );
};
