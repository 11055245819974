import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  MultiPotContainer,
  PotAmountChipsContainer,
  PotAmountText,
  PotContainer,
} from "./FramerPotStyles";
import {
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";
import FramerChipComponent from "./FramerChipComponent";

const FramerPotAmount = ({ tempTableId }) => {
  const gameId = useSelector(
    (state) => state.gameTable.games[tempTableId].gameId
  );
  const multiPotAmounts = useSelector(
    (state) => state.gameTable.games[tempTableId]?.multiPotAmounts
  );
  const BBDisplay = useSelector(
    (state) => state.gameTable.tableSettings.BBDisplay
  );
  const bigBlindValue = useSelector(
    (state) => state.gameTable.games[tempTableId]?.gameDefinition?.bigBlind
  );
  const winningPotData = useSelector(
    (state) => state.gameTable.games[tempTableId]?.winningPotDisplay
  );
  const isWinnerDeclared = useSelector(
    (state) => state.gameTable.games[tempTableId]?.isWinnerDeclared
  );
  const [numberOfChips, setNumberOfChips] = useState(1);
  const [hiddenPots, setHiddenPots] = useState([]);

  const updateHiddenPots = useCallback(
    (shouldHidePot, index) => {
      if (shouldHidePot) {
        let alreadyHiddenPots = [];
        for (let i = 0; i < index; i++) {
          if (i < index) {
            alreadyHiddenPots.push(i);
          }
        }
        let timeout;
        timeout = setTimeout(() => {
          setHiddenPots([index, ...hiddenPots, ...alreadyHiddenPots]);
          clearTimeout(timeout);
        }, 1000);
      }
    },
    [hiddenPots]
  );

  useEffect(() => {
    if (winningPotData) {
      setNumberOfChips(10 * winningPotData?.winnerNames?.length);
    } else {
      setNumberOfChips(1);
    }
  }, [winningPotData]);

  useEffect(() => {
    setHiddenPots([]);
  }, [gameId, multiPotAmounts]);

  useEffect(() => {
    if (winningPotData) {
      updateHiddenPots(true, winningPotData?.potIndex);
    }
  }, [winningPotData, updateHiddenPots]);

  // Split pots into run 1 and run 2
  const run1Pots = multiPotAmounts?.filter((pot) => pot.run === 1) || [];
  const run2Pots = multiPotAmounts?.filter((pot) => pot.run === 2) || [];
  const chipArray = useMemo(
    () => Array.from({ length: numberOfChips }),
    [numberOfChips]
  );

  return (
    <MultiPotContainer style={{ width: "100%" }}>
      <MultiPotContainer>
        {/* Render Run 1 Pots */}
        {run1Pots.map((pot, index) => (
          <PotContainer
            key={"run1-multiPotIndex-" + index}
            initial={{
              scale: 1,
              opacity: 0,
            }}
            animate={{
              scale: hiddenPots.includes(index) ? 0 : 1,
              opacity: hiddenPots.includes(index)
                ? 0
                : winningPotData && index !== winningPotData?.potIndex
                ? 0.25
                : 1,
            }}
          >
            <PotAmountChipsContainer>
              {winningPotData ? (
                winningPotData?.winnerNames.map((winner, winnerIndex) =>
                  chipArray.map((item, chipIndex) => (
                    <FramerChipComponent
                      key={
                        "run1-winner-" +
                        winnerIndex +
                        "-potIndex-" +
                        index +
                        "-chipIndex-" +
                        chipIndex
                      }
                      index={index}
                      chipIndex={chipIndex}
                      winnerIndex={winnerIndex}
                      winningPotData={winningPotData}
                      winner={winner}
                      shouldAnimate={
                        winningPotData &&
                        index === winningPotData?.potIndex &&
                        winningPotData?.playAnimation &&
                        winningPotData?.run === pot.run
                      }
                    />
                  ))
                )
              ) : (
                <FramerChipComponent
                  key={
                    "run1-winner-" +
                    // winnerIndex +
                    "-potIndex-" +
                    index +
                    "-chipIndex-"
                    // chipIndex
                  }
                  index={index}
                  // chipIndex={chipIndex}
                  // winnerIndex={winnerIndex}
                  winningPotData={{}}
                  // winner={winner}
                  shouldAnimate={false}
                />
              )}
            </PotAmountChipsContainer>
            <PotAmountText>
              {BBDisplay
                ? parseDecimalValuesOfNumber(
                    pot?.potAmount / bigBlindValue,
                    1
                  ) + " BB"
                : parseDecimalValuesOfNumber(
                    pot?.potAmount,
                    2,
                    checkToDisplayValueInK(pot?.potAmount),
                    checkToDisplayValueInL(pot?.potAmount)
                  )}
            </PotAmountText>
          </PotContainer>
        ))}
      </MultiPotContainer>
      {/* Render Run 2 Pots */}
      {run2Pots.map((pot, index) => (
        <PotContainer
          key={"run2-multiPotIndex-" + index}
          initial={{
            scale: 1,
            opacity: 0,
          }}
          animate={{
            scale: hiddenPots.includes(index + run2Pots.length) ? 0 : 1,
            opacity: hiddenPots.includes(index + run2Pots.length)
              ? 0
              : winningPotData &&
                isWinnerDeclared &&
                index + run2Pots.length !== winningPotData?.potIndex
              ? 0.25
              : 1,
          }}
        >
          <PotAmountChipsContainer>
            {winningPotData ? (
              winningPotData?.winnerNames.map((winner, winnerIndex) =>
                chipArray.map((item, chipIndex) => (
                  <FramerChipComponent
                    key={
                      "run2-winner-" +
                      winnerIndex +
                      "-potIndex-" +
                      index +
                      "-chipIndex-" +
                      chipIndex
                    }
                    index={index}
                    chipIndex={chipIndex}
                    winnerIndex={winnerIndex}
                    winningPotData={winningPotData}
                    winner={winner}
                    shouldAnimate={
                      winningPotData &&
                      index + run2Pots.length === winningPotData?.potIndex &&
                      winningPotData?.playAnimation &&
                      winningPotData?.run === pot.run
                    }
                  />
                ))
              )
            ) : (
              <FramerChipComponent
                key={
                  "run1-winner-" +
                  // winnerIndex +
                  "-potIndex-" +
                  index +
                  "-chipIndex-"
                  // chipIndex
                }
                index={index}
                // chipIndex={chipIndex}
                // winnerIndex={winnerIndex}
                winningPotData={{}}
                // winner={winner}
                shouldAnimate={false}
              />
            )}
          </PotAmountChipsContainer>
          <PotAmountText>
            {BBDisplay
              ? parseDecimalValuesOfNumber(pot?.potAmount / bigBlindValue, 1) +
                " BB"
              : parseDecimalValuesOfNumber(
                  pot?.potAmount,
                  2,
                  checkToDisplayValueInK(pot?.potAmount),
                  checkToDisplayValueInL(pot?.potAmount)
                )}
          </PotAmountText>
        </PotContainer>
      ))}
    </MultiPotContainer>
  );
};

export default FramerPotAmount;
