import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";

import leftArrow from "../../../assets/lobby/LeftArrowIcon.svg";
import bellIcon from "../../../assets/lobby/BellIcon.svg";
import greyBellIcon from "../../../assets/lobby/GreyBellIcon.svg";
import goldTicket from "../../../assets/lobby/GoldTicket.svg";
import cancelledIcon from "../../../assets/lobby/ErrorRoundIcon.svg";
import TrophySmallIcon from "../../../assets/gameTable/TrophySmallIcon.svg";
import goldCompleted from "../../../assets/lobby/GoldCompleted.svg";

import Button from "../../../Common/components/Button/Button";
import Tabbar from "../../../Common/components/Tabbar/Tabbar";
import colors from "../../../Common/styleGuide/Colors";

import TourneyDetails from "../../components/TourneyDetails/TourneyDetails";
import TourneyEntries from "../../components/TourneyEntries/TourneyEntries";
import TourneyTables from "../../components/TourneyTables/TourneyTables";
import * as Constants from "../../data/Constants.js";
import {
  clearDeeplink,
  closeTourneyInfoPage,
  fetchRequestTourneyDeRegistration,
  fetchRequestTourneyRegistration,
  fetchTourneyReEntry,
  requestTourneyEntriesDynamicData,
  requestTourneyInfoData,
  requestTourneyTablesDynamicData,
  requestUpdatedTourneyPlayerStatus,
  sendLobbyDebugLog,
  updateShowTourneyAddCashTicketRegistrationPopup,
  updateShowTourneyInsufficientFundsModal,
  updateShowTourneyReEntryConfirmationPopup,
  updateShowTourneyReEntryRequestLoading,
  updateShowTourneyRegisterConfirmationPopup,
  updateShowTourneyRegisterOrDeregisterRequestLoading,
} from "../../redux/slices/lobbySlice";

import {
  TourneyInfoContainer,
  TourneyHeaderWrapper,
  BackLeftArrowWrapper,
  TourneyInfoFooterCTAWrapper,
  TourneyInfoTitleText,
  TourneyIdText,
  TourneyInfoBodyWrapper,
  TabbarWrapper,
  LoadingViewContainer,
  PrimaryCTAButtonText,
  SecondaryCTAButtonText,
  RegisterationsStartsInWrapper,
  RegistrationStartsInText,
  TourneyInfoAnnouncementFooterWrapper,
  TimeText,
  TicketIcon,
  NoTicketButtonWrapper,
  MaxRegFullText,
  DirectAddCashModalDescriptionContainer,
  DescriptionText,
  StatusTypeWrapper,
  StatusTypeIconWrapper,
  MinimumAddCashWrapper,
  AddCashAmountText,
} from "./tourneyInfoStyles";
import { Loader } from "../../components/Loader/Loader";
import ConfirmationPopupRevamp from "../../../Common/components/Modals/ConfirmationPopupUI/ConfirmationPopupUI";
import { Toast } from "../../../Common/components/Toast/Toast";
import { messageToNativeClient } from "../../../Common/utils/platformCommunicationUtil";
import Confirm from "../../../Common/components/Modals/Confirm/Confirm";
import { updateActiveGame } from "../../redux/slices/gameTableSlice.js";
import CleverTapUtil from "../../../Common/utils/CleverTapUtil.js";
import {
  IP_Client_TourneyInfoPage_View,
  IP_Client_Tourney_unregister_Click,
  Poker_Client_Tourney_regclick,
} from "../../data/ClevertapConstants.js";
import {
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  getISTDateTimeFormat,
  getISTDateTimeFormatFromEpochFormat,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";
import { openModal } from "../../redux/slices/modalSlice";
import {
  Typo12PrimaryGoldContoBoldText,
  Typo12WhiteContoBoldText,
} from "../../../Common/styleGuide/Typos.js";
import { logCleverTapEvent } from "../../../Common/utils/CleverTapUtilNew.js";
import TourneyFlightsDetails from "../../components/TourneyFlightsDetails/TourneyFlightsDetails.js";

import { getEventParams } from "../../utils/MixPanelUtils.js";
import {
  MP_POKER_GAME_LOBBY_TOURNEY_REG_CONFIRMATION_LOADED,
  MP_POKER_TOURNEY_INFO_TABLES_CLICKED,
  MP_POKER_TOURNEY_REENTRY_CLICKED,
  MP_POKER_TOURNEY_REGISTER_CLICKED,
  MP_POKER_TOURNEY_REMIND_ME_CLICKED,
  MP_POKER_TOURNEY_TILE_CLICKED,
} from "../../data/MixPanelConstant.js";
export const processDate = (data) => {
  let formattedDate;
  const receivedDate = new Date(data * 1000);
  let time = receivedDate.toLocaleTimeString();
  let day = receivedDate.toLocaleString("en-US", { weekday: "short" });
  let date = receivedDate.toLocaleString("en-US", { day: "numeric" });
  let month = receivedDate.toLocaleString("en-US", { month: "short" });
  let year = receivedDate.toLocaleString("en-US", { year: "numeric" });
  formattedDate =
    year +
    "-" +
    day +
    "-" +
    date +
    "-" +
    month +
    " " +
    receivedDate.getHours() +
    ":" +
    receivedDate.getMinutes();
  return formattedDate;
};
function TourneyInfo(props) {
  const {
    TOURNEY_INFO_DETAILS_TAB,
    TOURNEY_INFO_ENTRIES_TAB,
    TOURNEY_INFO_TABLES_TAB,
    TOURNEY_INFO_FLIGHTS_TAB,
  } = Constants;

  const masterTourneysData = useSelector(
    (state) => state.lobby.tourneys.masterTourneysData
  );

  const selectedTourneyDetailsId = useSelector(
    (state) => state.lobby.selectedTourneyDetailsId
  );

  const selectedTourneyInfoDetails =
    masterTourneysData.get(selectedTourneyDetailsId) ?? {};
  const getTourneyInfoTabs = () => {
    let tabItems = [
      {
        label: "Details",
        value: TOURNEY_INFO_DETAILS_TAB,
      },

      {
        label: "Entries",
        value: TOURNEY_INFO_ENTRIES_TAB,
      },

      {
        label: "Tables",
        value: TOURNEY_INFO_TABLES_TAB,
      },
    ];
    if (selectedTourneyInfoDetails.isFlightTourney) {
      if (
        selectedTourneyInfoDetails.isFlightParentTourney &&
        selectedTourneyInfoDetails.tourneyIdentifier === "completedTourneys"
      ) {
        tabItems = tabItems
          .filter((eachTab) => eachTab.label !== "Tables") // Remove "Tables"
          .map((eachTab) => ({
            ...eachTab,
            label: eachTab.label === "Entries" ? "Winners" : eachTab.label, // Update "Entries" to "Winners"
          }));
        const newItem = { label: "Flights", value: TOURNEY_INFO_FLIGHTS_TAB };
        tabItems.splice(1, 0, newItem);
      } else {
        const newItem = { label: "Flights", value: TOURNEY_INFO_FLIGHTS_TAB };
        tabItems.splice(1, 0, newItem);
      }
    }
    return tabItems;
  };

  const dispatch = useDispatch();

  const showTourneyInfoLoading = useSelector(
    (state) => state.lobby.showTourneyInfoLoading
  );
  const playerDetails = useSelector((state) => state.lobby.player);
  const userBalance = useSelector((state) => state.lobby.balance);
  const channel = useSelector((state) => state.lobby.channel);
  const a23Token = useSelector((state) => state.lobby.a23Token);

  const tourneyRegisterAPIURL = useSelector(
    (state) => state.lobby.tourneyRegisterAPIURL
  );
  const tourneyDeRegisterAPIURL = useSelector(
    (state) => state.lobby.tourneyDeRegisterAPIURL
  );

  const tourneyReEntryAPIURL = useSelector(
    (state) => state.lobby.tourneyReEntryAPIURL
  );

  const showTourneyRegisterConfirmationPopup = useSelector(
    (state) => state.lobby.showTourneyRegisterConfirmationPopup
  );

  const showTourneyAddCashTicketRegistrationPopup = useSelector(
    (state) => state.lobby.showTourneyAddCashTicketRegistrationPopup
  );

  const showTourneyReEntryConfirmationPopup = useSelector(
    (state) => state.lobby.showTourneyReEntryConfirmationPopup
  );

  const showTourneyReEntryRequestLoading = useSelector(
    (state) => state.lobby.showTourneyReEntryRequestLoading
  );

  const showTourneyRegisterOrDeregisterRequestLoading = useSelector(
    (state) => state.lobby.showTourneyRegisterOrDeregisterRequestLoading
  );
  const tourneyTickets = useSelector((state) => state.lobby.tourneyTickets);

  const showTourneyInsufficientFundsModal = useSelector(
    (state) => state.lobby.showTourneyInsufficientFundsModal
  );
  const currentLobbyTime = useSelector((state) => state.lobby.currentLobbyTime);
  const activeGames = useSelector((state) => state.gameTable.games);
  const showTourneyFairPlayPolicyRestrictionPopup = useSelector(
    (state) => state.lobby.showTourneyFairPlayPolicyRestrictionPopup
  );

  const showLateRegisterSuccessPopup = useSelector(
    (state) => state.lobby.showLateRegisterSuccessPopup
  );

  // const [showCTALoader, setShowCTALoader] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    selectedTourneyInfoDetails?.defaultSelectedTab
      ? selectedTourneyInfoDetails?.defaultSelectedTab
      : TOURNEY_INFO_DETAILS_TAB
  );
  const [registrationStartTime, setRegistrationStartTime] = useState(-1);
  // const [timerId, setTimerId] = useState(null);
  const intervalRef = useRef(null);
  const tpsIntervalRef = useRef(null);

  useEffect(() => {
    if (selectedTourneyInfoDetails.tourneyPlayerStatus === "Registered") {
      const MixPanel_params = getEventParams(
        MP_POKER_TOURNEY_REGISTER_CLICKED,
        playerDetails,
        userBalance,
        channel,
        selectedTourneyInfoDetails
      );

      messageToNativeClient({
        type: Constants.MIXPANEL_EVENT,
        eventName: MP_POKER_GAME_LOBBY_TOURNEY_REG_CONFIRMATION_LOADED,
        eventProperties: MixPanel_params,
      });
    }
  }, [selectedTourneyInfoDetails.tourneyPlayerStatus]);

  useEffect(() => {
    let timeStamp = getISTDateTimeFormat();
    console.log("GANESH_FLIGHT", selectedTourneyInfoDetails);
    messageToNativeClient({
      type: "plotlineEvents",
      eventName: "PR_Client_TourneyInfoPage_View",
      eventProperties: {
        userName: playerDetails.userName,
        channel: channel,
        currentCashBalance: userBalance.totalBalance,
        tourneyType: selectedTourneyInfoDetails.tournamentType,
        tourneyName: selectedTourneyInfoDetails.tourneyName,
        tourneyId: selectedTourneyInfoDetails.tourneyId,
        entryFee:
          selectedTourneyInfoDetails.buyIn +
          selectedTourneyInfoDetails.tourneyRake,
        registerMode: selectedTourneyInfoDetails.isAlreadyRegisteredTourney
          ? selectedTourneyInfoDetails.tournamentType === Constants.TICKET_BASED
            ? "Ticket"
            : "Cash"
          : "NA",
        tourneyStartTime: selectedTourneyInfoDetails.tourneyStartTime,
        registrationStartTime: selectedTourneyInfoDetails.registrationStartTime,
        timeStamp,
      },
    });

    let tourneyStartTime = getISTDateTimeFormatFromEpochFormat(
      selectedTourneyInfoDetails.tourneyStartTime
    );

    let registrationStartTime = getISTDateTimeFormatFromEpochFormat(
      selectedTourneyInfoDetails.registrationStartTime
    );

    logCleverTapEvent(IP_Client_TourneyInfoPage_View, {
      Username: playerDetails.userName,
      Channel: channel,
      "Wallet balance": userBalance.totalBalance,
      "Tourney type": selectedTourneyInfoDetails.tournamentType,
      "Tourney name": selectedTourneyInfoDetails.tourneyName,
      "Entry fee":
        selectedTourneyInfoDetails.buyIn +
        selectedTourneyInfoDetails.tourneyRake,
      "Register mode": selectedTourneyInfoDetails.entryType,
      "Tourney start time": tourneyStartTime,
      "Tourney ID": selectedTourneyInfoDetails.tourneyId,
      "Registration start time": registrationStartTime,
    });
    const MixPanel_params = getEventParams(
      MP_POKER_TOURNEY_INFO_TABLES_CLICKED,
      playerDetails,
      userBalance,
      channel,
      selectedTourneyInfoDetails
    );

    messageToNativeClient({
      type: Constants.MIXPANEL_EVENT,
      eventName: MP_POKER_TOURNEY_INFO_TABLES_CLICKED,
      eventProperties: MixPanel_params,
    });

    const difference =
      new Date(selectedTourneyInfoDetails?.registrationStartTime * 1000) -
      currentLobbyTime;
    setRegistrationStartTime(Math.floor(difference / 1000));

    runDynamicScheduleTPSProtocolDetails();

    return () => {
      // if (timerId) {
      clearInterval(intervalRef.current);
      clearInterval(tpsIntervalRef.current);
      intervalRef.current = null;
      tpsIntervalRef.current = null;
      // }
    };
  }, []);

  useEffect(() => {
    if (showTourneyInsufficientFundsModal) {
      const { tourneyRake } = selectedTourneyInfoDetails;
      if (Number(tourneyRake) < Number(userBalance?.totalBalance)) {
        handleModalClose();
      }
    }
  }, [userBalance?.totalBalance]);

  useEffect(() => {
    getRegistrationStartTimeInSeconds();
  }, [registrationStartTime]);

  useEffect(() => {
    if (showTourneyFairPlayPolicyRestrictionPopup) {
      dispatch(
        openModal({ type: Constants.TOURNEY_FAIRPLAY_POLICY_RESTRICTION })
      );
    }
  }, [showTourneyFairPlayPolicyRestrictionPopup]);

  useEffect(() => {
    if (showLateRegisterSuccessPopup) {
      dispatch(
        openModal({ type: Constants.TOURNEY_LATE_REGISTRATION_SUCCESS })
      );
    }
  }, [showLateRegisterSuccessPopup]);

  useEffect(() => {
    if (
      selectedTourneyInfoDetails?.hideRegistrationCTAButtons &&
      showTourneyRegisterConfirmationPopup
    ) {
      closeRegistrationConfirmationModal();
    }
  }, [selectedTourneyInfoDetails?.hideRegistrationCTAButtons]);

  const runDynamicScheduleTPSProtocolDetails = () => {
    //NOTE: For every 15seconds interval we are trigerring tps# from our end to fulfill re-entry cases.
    if (!tpsIntervalRef?.current) {
      tpsIntervalRef.current = setInterval(() => {
        dispatch(
          requestUpdatedTourneyPlayerStatus({
            tourneyId: selectedTourneyInfoDetails?.tourneyId,
          })
        );
      }, 15000);
    }
  };

  const getRegistrationStartTimeInSeconds = () => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        setRegistrationStartTime((prev) => prev - 1);
      }, 1000);
      // setTimerId(intervalTimerId);
    }
  };

  const handleTourneysTabChange = (tab) => {
    if (selectedTab !== tab) {
      setSelectedTab(tab);
    }
  };

  const handleLeftArrow = () => {
    dispatch(clearDeeplink());
    dispatch(closeTourneyInfoPage());
    // alert('CLOSING TOURNEY INFO!! ');
  };

  const calculateTime = (timeRemaining, showUnits) => {
    if (timeRemaining >= 0) {
      let seconds = timeRemaining;
      if (seconds === 0) {
        return " 00:00:00";
      } else if (seconds < 60) {
        if (showUnits) {
          return seconds < 10
            ? " 00:00:0" + seconds + "s"
            : " 00:00:" + seconds + "s";
        } else {
          return seconds < 10 ? " 00:00:0" + seconds : " 00:00:" + seconds;
        }
      } else if (seconds >= 60 && seconds < 3600) {
        let mins = Math.floor((seconds % 3600) / 60);
        let secs = seconds % 60;
        if (showUnits) {
          return `${
            " 00:" +
            (mins < 10 ? `${"0" + mins + "m"}` : mins + "m") +
            ":" +
            (secs < 10 ? `${"0" + secs + "s"}` : secs + "s")
          }`;
        } else {
          return `${
            " 00:" +
            (mins < 10 ? `${"0" + mins}` : mins) +
            ":" +
            (secs < 10 ? `${"0" + secs}` : secs)
          }`;
        }
      } else if (seconds >= 3600 && seconds < 86400) {
        let hrs = Math.floor(seconds / 3600);
        let mins = Math.floor((seconds % 3600) / 60);
        let secs = Math.floor((seconds % 3600) % 60);
        if (showUnits) {
          return `${
            (hrs < 10 ? `${" 0" + hrs + "h"}` : hrs + "h") +
            ":" +
            (mins < 10 ? `${"0" + mins + "m"}` : mins + "m") +
            ":" +
            (secs < 10 ? `${"0" + secs + "s"}` : secs + "s")
          }`;
        } else {
          return `${
            (hrs < 10 ? `${" 0" + hrs}` : hrs) +
            ":" +
            (mins < 10 ? `${"0" + mins}` : mins) +
            ":" +
            (secs < 10 ? `${"0" + secs}` : secs)
          }`;
        }
      } else if (seconds > 86400) {
        let days = Math.floor(seconds / 86400);
        let hrs = Math.floor((seconds % 86400) / 3600);
        let mins = Math.floor(((seconds % 86400) % 3600) / 60);
        if (showUnits) {
          return `${
            (days < 10 ? `${" 0" + days + "d"}` : days + "d") +
            ":" +
            (hrs < 10 ? `${"0" + hrs + "h"}` : hrs + "h") +
            ":" +
            (mins < 10 ? `${"0" + mins + "m"}` : mins + "m")
          }`;
        } else {
          return `${days < 2 ? `${days + " Day"}` : days + " Days"}`;
        }
      }
    }
    // if (timerId) {
    // clearInterval(intervalRef.current);
    // }
    if (
      selectedTourneyInfoDetails?.tourneyStatus ===
        Constants.TOURNEY_REGISTRATION_STARTED ||
      selectedTourneyInfoDetails?.tourneyStatus === Constants.TOURNEY_ANNOUNCED
    ) {
      // clearInterval(intervalRef.current);
      return "Opening shortly";
    }
    return "--/--/--";
  };

  const getTourneyStatusText = (currentTourneyStatus) => {
    switch (currentTourneyStatus) {
      case Constants.TOURNEY_STATUS_CANCELLED:
        return (
          <StatusTypeWrapper>
            <StatusTypeIconWrapper src={cancelledIcon} />
            <Typo12WhiteContoBoldText>CANCELLED</Typo12WhiteContoBoldText>
          </StatusTypeWrapper>
        );
      case Constants.TOURNEY_STATUS_MISSED:
        return (
          <StatusTypeWrapper>
            <StatusTypeIconWrapper src={cancelledIcon} />
            <Typo12WhiteContoBoldText>MISSED</Typo12WhiteContoBoldText>
          </StatusTypeWrapper>
        );
      case Constants.TOURNEY_STATUS_WON:
        if (
          selectedTourneyInfoDetails.isFlightTourney &&
          !selectedTourneyInfoDetails.isFlightParentTourney
        ) {
          return (
            <StatusTypeWrapper>
              <StatusTypeIconWrapper src={goldCompleted} />
              <Typo12PrimaryGoldContoBoldText>
                QUALIFIED
              </Typo12PrimaryGoldContoBoldText>
            </StatusTypeWrapper>
          );
        }

        return (
          <StatusTypeWrapper>
            <StatusTypeIconWrapper src={TrophySmallIcon} />
            <Typo12PrimaryGoldContoBoldText>
              YOU WON
            </Typo12PrimaryGoldContoBoldText>
          </StatusTypeWrapper>
        );
      case Constants.TOURNEY_STATUS_COMPLETED:
        return (
          <StatusTypeWrapper>
            <StatusTypeIconWrapper src={goldCompleted} />
            <Typo12PrimaryGoldContoBoldText>
              COMPLETED
            </Typo12PrimaryGoldContoBoldText>
          </StatusTypeWrapper>
        );
    }
  };

  const handleTourneyRegister = () => {
    if (!showTourneyRegisterOrDeregisterRequestLoading) {
      const { userName, IP, userId, avatar, deviceId, state, playerStatus } =
        playerDetails;
      if (
        playerStatus === Constants.PSEUDO_LOCKED_PLAYER ||
        playerStatus === Constants.PSEUDO_EXPIRED_PLAYER
      ) {
        return dispatch(
          openModal({
            type: Constants.RESTRICTED_ACCESS,
          })
        );
      }

      const requestPayload = {
        playerName: userName,
        tourneyId: selectedTourneyInfoDetails?.tourneyId,
        channel: channel,
        appId: 135,
        ip: IP,
        userId: userId,
        profilePic: avatar,
        requestor: "client",
        entryType: 1, // For register entry type is 1
        deviceId: deviceId,
        state: state,
        a23Token: a23Token,
      };
      dispatch(
        updateShowTourneyRegisterOrDeregisterRequestLoading({ isLoading: true })
      );
      dispatch(
        fetchRequestTourneyRegistration({
          data: requestPayload,
          apiURL: tourneyRegisterAPIURL,
        })
      );
    }
  };

  const checkTourneyGameTableIsAvailable = () => {
    try {
      let isAlreadyActiveTableDetails = null;
      Object.values(activeGames).forEach((eachGame) => {
        if (
          eachGame.gameDefinition.tourneyId ===
            selectedTourneyInfoDetails?.tourneyId &&
          !eachGame.watchPlayer
        ) {
          isAlreadyActiveTableDetails = eachGame;
        }
      });
      return isAlreadyActiveTableDetails;
    } catch (e) {
      dispatch(
        sendLobbyDebugLog(
          `Error at tourney join back cta ${selectedTourneyInfoDetails?.tourneyId}, Error --> ${e}`
        )
      );
    }
  };

  const handleFlightTourneyNavigation = (tourneyId) => {
    dispatch(
      requestUpdatedTourneyPlayerStatus({
        tourneyId: tourneyId,
      })
    );
    dispatch(
      requestTourneyInfoData({
        tourneyId: tourneyId,
      })
    );
    dispatch(
      requestTourneyEntriesDynamicData({
        tourneyId: tourneyId,
      })
    );
    dispatch(requestTourneyTablesDynamicData({ tourneyId: tourneyId }));
  };

  const handleJoinBackCTA = () => {
    try {
      const selectedTourneyRunningGameDetails =
        checkTourneyGameTableIsAvailable();
      if (selectedTourneyRunningGameDetails) {
        dispatch(
          updateActiveGame(selectedTourneyRunningGameDetails?.tempTableId)
        );
        props.toggleGameTable && props.toggleGameTable();
      }
    } catch (e) {
      dispatch(
        sendLobbyDebugLog(
          `Error at tourney join back cta ${selectedTourneyInfoDetails?.tourneyId}, Error --> ${e}`
        )
      );
    }
  };

  const openRegisterConfirmationModal = () => {
    if (playerDetails.playerStatus === "plocked") {
      return dispatch(openModal({ type: Constants.RESTRICTED_ACCESS }));
    }
    if (
      selectedTourneyInfoDetails.tournamentType !== "Free Roll" &&
      (playerDetails.userConsent === "true" ||
        playerDetails.gameJoinCheckAck === "true")
    ) {
      messageToNativeClient({ type: "gameJoinCheck" });
      return;
    }
    let payload = {
      Username: playerDetails.userName,
      Channel: channel,
      "Wallet balance": userBalance.totalBalance,
      "Tourney type": selectedTourneyInfoDetails.tournamentType,
      "Tourney name": selectedTourneyInfoDetails.tourneyName,
      "Entry fee":
        selectedTourneyInfoDetails.buyIn +
        selectedTourneyInfoDetails.tourneyRake,
      "No. of players": selectedTourneyInfoDetails.maxPlayers,
      "Game format": selectedTourneyInfoDetails.gameType,
      Turbo: selectedTourneyInfoDetails.isTurbo,
      "Tourney description": selectedTourneyInfoDetails.tourneyIdentifier,
      blinds: `${
        selectedTourneyInfoDetails.bigBlind /
        selectedTourneyInfoDetails.smallBlind
      }`,
      "Tourney Type": selectedTourneyInfoDetails.tournamentType,
      "Register mode": selectedTourneyInfoDetails.entryType,

      "Tourney ID": selectedTourneyInfoDetails.tourneyId,
      timeStamp: getISTDateTimeFormat(),
      "Tourney Announced timestamp": getISTDateTimeFormatFromEpochFormat(
        selectedTourneyInfoDetails.announcementStartDate
      ),
    };
    if (
      selectedTourneyInfoDetails.tourneyPlayerStatus === "NOT_REGISTERED" &&
      !selectedTourneyInfoDetails.isAlreadyRegisteredTourney
    ) {
      logCleverTapEvent(Poker_Client_Tourney_regclick, {
        ...payload,
        "Tourney Registered timestamp": getISTDateTimeFormat(),
      });
      const MixPanel_params = getEventParams(
        MP_POKER_TOURNEY_REGISTER_CLICKED,
        playerDetails,
        userBalance,
        channel,
        selectedTourneyInfoDetails
      );

      messageToNativeClient({
        type: Constants.MIXPANEL_EVENT,
        eventName: MP_POKER_TOURNEY_REGISTER_CLICKED,
        eventProperties: MixPanel_params,
      });
    } else if (
      selectedTourneyInfoDetails.isAlreadyRegisteredTourney &&
      selectedTourneyInfoDetails.tourneyPlayerStatus === "REGISTERED"
    ) {
      logCleverTapEvent(IP_Client_Tourney_unregister_Click, {
        ...payload,
        "Tourney Un_Registered timestamp": getISTDateTimeFormat(),
      });
    }
    if (
      !selectedTourneyInfoDetails.isAlreadyRegisteredTourney &&
      selectedTourneyInfoDetails.depositTourneyFlag &&
      selectedTourneyInfoDetails.addCashTicketRegistration &&
      !checkUserHasTicket(selectedTourneyInfoDetails.tourneyId)
    ) {
      // dispatch(
      //   updateShowTourneyAddCashTicketRegistrationPopup({ isOpen: true })
      // );
      handleTourneyRegister();
      return;
    }
    dispatch(updateShowTourneyRegisterConfirmationPopup({ isOpen: true }));
  };

  const closeRegistrationConfirmationModal = () => {
    dispatch(updateShowTourneyRegisterConfirmationPopup({ isOpen: false }));
  };

  const openReEntryConfirmationModal = () => {
    const MixPanel_params = getEventParams(
      MP_POKER_TOURNEY_TILE_CLICKED,
      playerDetails,
      userBalance,
      channel,
      selectedTourneyInfoDetails
    );

    messageToNativeClient({
      type: Constants.MIXPANEL_EVENT,
      eventName: MP_POKER_TOURNEY_REENTRY_CLICKED,
      eventProperties: MixPanel_params,
    });
    dispatch(updateShowTourneyReEntryConfirmationPopup({ isOpen: true }));
  };

  const closeReEntryConfirmationModal = () => {
    dispatch(updateShowTourneyReEntryConfirmationPopup({ isOpen: false }));
  };

  const closeTourneyAddCashTicketRegistrationConfirmation = () => {
    dispatch(
      updateShowTourneyAddCashTicketRegistrationPopup({ isOpen: false })
    );
  };

  const handleModalClose = () => {
    dispatch(updateShowTourneyInsufficientFundsModal({ isOpen: false }));
  };

  const handleOpenInsufficientFundsModal = () => {
    dispatch(updateShowTourneyInsufficientFundsModal({ isOpen: true }));
  };

  const handleTourneyDeRegister = () => {
    if (!showTourneyRegisterOrDeregisterRequestLoading) {
      const { userName, IP, userId, avatar, deviceId } = playerDetails;

      const requestPayload = {
        playerName: userName,
        tourneyId: selectedTourneyInfoDetails?.tourneyId,
        channel: channel,
        appId: 135,
        ip: IP,
        userId: userId,
        profilePic: avatar,
        requestor: "client",
        entryType: 2, // For Deregister entry type is 2
        deviceId: deviceId,
        a23Token: a23Token,
      };
      dispatch(
        updateShowTourneyRegisterOrDeregisterRequestLoading({ isLoading: true })
      );
      dispatch(
        fetchRequestTourneyDeRegistration({
          data: requestPayload,
          apiURL: tourneyDeRegisterAPIURL,
        })
      );
    }
  };

  const handleRemindMe = () => {
    if (selectedTourneyInfoDetails?.isAlreadyRegisteredTourney) {
      let remindMeUrl = Constants.PLATFORM_CHANNELS.includes(channel)
        ? process.env
            .REACT_APP_REMINDME_DEEP_LINK_URL_FOR_STANDALONE_APP_FOR_PLATFORM_APPS
        : process.env.REACT_APP_REMINDME_DEEP_LINK_URL_FOR_STANDALONE_APP;

      if (!selectedTourneyInfoDetails?.reminder) {
        messageToNativeClient({
          type: "RemindMe",
          data: {
            tournamentId: selectedTourneyInfoDetails?.tourneyId,
            tournmentStartTime: processDate(
              selectedTourneyInfoDetails?.tourneyStartTime
            ),
            tournamentEndTime: 0,
            regStartTime: processDate(
              selectedTourneyInfoDetails?.registrationStartTime
            ),
            tournmentName: selectedTourneyInfoDetails?.tourneyName,
            tournmentDesc: selectedTourneyInfoDetails?.tourneyDescType,
            tourneyStatus: selectedTourneyInfoDetails?.tourneyStatus,
            Game: "poker",
            remindme_deeplink_url: remindMeUrl,
          },
        });
        const MixPanel_params = getEventParams(
          MP_POKER_TOURNEY_REMIND_ME_CLICKED,
          playerDetails,
          userBalance,
          channel,
          selectedTourneyInfoDetails
        );

        messageToNativeClient({
          type: Constants.MIXPANEL_EVENT,
          eventName: MP_POKER_TOURNEY_REMIND_ME_CLICKED,
          eventProperties: MixPanel_params,
        });
      }
    }
  };

  const getSelectedTourneyTabDetails = () => {
    switch (selectedTab) {
      case TOURNEY_INFO_DETAILS_TAB:
        return (
          <TourneyDetails
            tourneyDetails={selectedTourneyInfoDetails}
            handleTourneysTabChange={handleTourneysTabChange}
            handleTourneyNavigation={handleFlightTourneyNavigation}
          />
        );
      case TOURNEY_INFO_ENTRIES_TAB:
        return (
          <TourneyEntries
            tourneyDetails={selectedTourneyInfoDetails}
            userName={playerDetails.userName}
            handleTourneyNavigation={handleFlightTourneyNavigation}
            handleTourneysTabChange={handleTourneysTabChange}
          />
        );
      case TOURNEY_INFO_TABLES_TAB:
        return <TourneyTables tourneyDetails={selectedTourneyInfoDetails} />;
      case TOURNEY_INFO_FLIGHTS_TAB:
        return (
          <TourneyFlightsDetails
            tourneyDetails={selectedTourneyInfoDetails}
            handleTourneyNavigation={handleFlightTourneyNavigation}
            handleTourneysTabChange={handleTourneysTabChange}
          />
        );
    }
  };

  const handleReEntryCTA = () => {
    if (!showTourneyReEntryRequestLoading) {
      const { userName, IP, userId, avatar, deviceId, state } = playerDetails;
      const requestPayload = {
        playerName: userName,
        tourneyId: selectedTourneyInfoDetails?.tourneyId,
        channel: channel,
        appId: 135,
        ip: IP,
        userId: userId,
        profilePic: avatar,
        requestor: "client",
        entryType: 1,
        deviceId: deviceId,
        state: state,
        a23Token: a23Token,
      };
      dispatch(updateShowTourneyReEntryRequestLoading({ isLoading: true }));
      dispatch(
        fetchTourneyReEntry({
          data: requestPayload,
          apiURL: tourneyReEntryAPIURL,
        })
      );
    }
  };

  const handleAddCash = () => {
    let temp = new Date();
    let timeStamp = new Date(temp.getTime() + 330 * 60 * 1000);
    timeStamp = timeStamp.toISOString();
    messageToNativeClient({
      type: "plotlineEvents",
      eventName: "PR_Client_CashFunToggle_Click",
      eventProperties: {
        userName: playerDetails.userName,
        channel: channel,
        currentCashBalance: userBalance.totalBalance,
        timeStamp: timeStamp,
        clickLocation: "InsufficientBalance",
      },
    });
    messageToNativeClient({ type: "openaddcash" });
    closeTourneyAddCashTicketRegistrationConfirmation();
  };

  const checkUserHasTicket = (tourneyId) => {
    let ticketTourneyIds = tourneyTickets.map((eachTicket) => {
      return eachTicket.tourneyId;
    });
    return ticketTourneyIds.includes(tourneyId);
  };

  const getTourneyInfoFooterBasedOnTourneyStats = (tourneyDetails) => {
    const {
      tourneyStatus,
      tourneyPlayerStatus,
      isAlreadyRegisteredTourney,
      reminder,
      tourneyRake,
      buyIn,
      registeredPlayerCount,
      tournamentType,
      maxPlayers,
      hideRegistrationCTAButtons,
      inTheMoney,
      bustedOut,
    } = tourneyDetails;
    switch (tourneyStatus) {
      case Constants.TOURNEY_ANNOUNCED:
        if (tournamentType === Constants.TOURNEY_TYPES.SIT_AND_GO) {
          return null;
        }
        return (
          <TourneyInfoAnnouncementFooterWrapper
            reminded={
              selectedTourneyInfoDetails?.reminder ||
              !tourneyDetails.isAlreadyRegisteredTourney
            }
          >
            <RegisterationsStartsInWrapper>
              <RegistrationStartsInText>
                {/* moved the TimeText inside as it is going into the scrollable area (getting hidden) in Samsung fold device 
                and we fixed the main section (which includes this) as not scrollable*/}
                Registration available in{" "}
                {
                  <TimeText>
                    {calculateTime(registrationStartTime, true)}
                  </TimeText>
                }
              </RegistrationStartsInText>
              {/* <TimeText>{calculateTime(registrationStartTime, true)}</TimeText> */}
            </RegisterationsStartsInWrapper>
            <Button
              clickHandler={handleRemindMe}
              bgColor={"transparent"}
              showIcon={!selectedTourneyInfoDetails?.reminder}
              iconUrl={greyBellIcon}
            >
              <SecondaryCTAButtonText
                reminded={
                  selectedTourneyInfoDetails?.reminder ||
                  !tourneyDetails.isAlreadyRegisteredTourney
                }
              >
                {selectedTourneyInfoDetails?.reminder
                  ? "Reminder Set"
                  : "Remind Me"}
              </SecondaryCTAButtonText>
            </Button>
          </TourneyInfoAnnouncementFooterWrapper>
        );
      case Constants.TOURNEY_REGISTRATION_STARTED:
        if (
          !tourneyDetails.isAlreadyRegisteredTourney &&
          tourneyDetails.isFlightParentTourney
        ) {
          //NOTE: If player is not registered and it is a flight parent tourney then we are not showing any CTA buttons.
          return;
        }
        if (tourneyDetails.isAlreadyRegisteredTourney) {
          if (hideRegistrationCTAButtons) {
            return (
              <TourneyInfoFooterCTAWrapper>
                <MaxRegFullText>Tourney is about to start.</MaxRegFullText>
              </TourneyInfoFooterCTAWrapper>
            );
          }
          return (
            <TourneyInfoFooterCTAWrapper
              reminded={selectedTourneyInfoDetails?.reminder}
            >
              <Button
                clickHandler={handleRemindMe}
                bgColor={"transparent"}
                showIcon={!selectedTourneyInfoDetails?.reminder}
                iconUrl={bellIcon}
                className={"secondary-cta"}
              >
                <SecondaryCTAButtonText
                  reminded={selectedTourneyInfoDetails?.reminder}
                >
                  {reminder ? "Reminder set" : "Remind Me"}
                </SecondaryCTAButtonText>
              </Button>
              {tourneyDetails.isAlreadyRegisteredTourney &&
              !selectedTourneyInfoDetails.isFlightParentTourney ? (
                <Button
                  clickHandler={openRegisterConfirmationModal}
                  bgColor={colors.redBtn}
                  showIcon={false}
                >
                  <PrimaryCTAButtonText>De-register</PrimaryCTAButtonText>
                </Button>
              ) : tourneyDetails.isAlreadyRegisteredTourney &&
                selectedTourneyInfoDetails.isFlightParentTourney ? (
                <Button bgColor={"transparent"}>
                  <StatusTypeIconWrapper src={goldCompleted} />
                  <Typo12PrimaryGoldContoBoldText>
                    Registered
                  </Typo12PrimaryGoldContoBoldText>
                </Button>
              ) : checkUserHasTicket(tourneyDetails.tourneyId) ? (
                <Button
                  clickHandler={openRegisterConfirmationModal}
                  bgColor={colors.secondaryGreen}
                  showIcon={false}
                >
                  {tourneyDetails.tournamentType ===
                    Constants.TOURNEY_TYPES.TICKET ||
                  checkUserHasTicket(tourneyDetails.tourneyId) ? (
                    <>
                      <PrimaryCTAButtonText>{"Ticket"}</PrimaryCTAButtonText>
                      <TicketIcon src={goldTicket} />
                    </>
                  ) : (
                    <PrimaryCTAButtonText id='register-button'>
                      {"Register"}
                    </PrimaryCTAButtonText>
                  )}
                </Button>
              ) : (
                <Button
                  // clickHandler={openRegisterConfirmationModal}
                  bgColor={
                    tourneyDetails.tournamentType ===
                    Constants.TOURNEY_TYPES.TICKET
                      ? colors.vampireGrey
                      : colors.secondaryGreen
                  }
                  showIcon={false}
                  disabled={true}
                  // className={checkUserHasTicket(tourneyDetails.tourneyId) }
                >
                  {tourneyDetails.tournamentType ===
                  Constants.TOURNEY_TYPES.TICKET ? (
                    <NoTicketButtonWrapper>
                      <PrimaryCTAButtonText>{"No Ticket"}</PrimaryCTAButtonText>
                      <TicketIcon src={goldTicket} />
                    </NoTicketButtonWrapper>
                  ) : (
                    <PrimaryCTAButtonText id='register-button'>
                      {"Register"}
                    </PrimaryCTAButtonText>
                  )}
                </Button>
              )}
            </TourneyInfoFooterCTAWrapper>
          );
        } else if (
          registeredPlayerCount < maxPlayers &&
          !hideRegistrationCTAButtons
        ) {
          if (
            userBalance.totalBalance < buyIn + tourneyRake &&
            !checkUserHasTicket(tourneyDetails.tourneyId)
          ) {
            return (
              <TourneyInfoFooterCTAWrapper
                reminded={selectedTourneyInfoDetails?.reminder}
              >
                <Button
                  clickHandler={handleAddCash}
                  bgColor={colors.secondaryGreen}
                  showIcon={false}
                >
                  <PrimaryCTAButtonText>{"Add Cash"}</PrimaryCTAButtonText>
                </Button>
              </TourneyInfoFooterCTAWrapper>
            );
          }
          return (
            <TourneyInfoFooterCTAWrapper
              reminded={
                selectedTourneyInfoDetails?.reminder ||
                !tourneyDetails.isAlreadyRegisteredTourney
              }
            >
              {tournamentType !== Constants.TOURNEY_TYPES.SIT_AND_GO && (
                <Button
                  clickHandler={handleRemindMe}
                  bgColor={"transparent"}
                  showIcon={!selectedTourneyInfoDetails?.reminder}
                  iconUrl={greyBellIcon}
                  className={"secondary-cta"}
                >
                  <SecondaryCTAButtonText
                    reminded={
                      selectedTourneyInfoDetails?.reminder ||
                      !tourneyDetails.isAlreadyRegisteredTourney
                    }
                  >
                    {reminder ? "Reminder set" : "Remind Me"}
                  </SecondaryCTAButtonText>
                </Button>
              )}
              {tourneyDetails.isAlreadyRegisteredTourney &&
              !selectedTourneyInfoDetails.isFlightParentTourney ? (
                <Button
                  clickHandler={openRegisterConfirmationModal}
                  bgColor={colors.redBtn}
                  showIcon={false}
                >
                  <PrimaryCTAButtonText>De-register</PrimaryCTAButtonText>
                </Button>
              ) : checkUserHasTicket(tourneyDetails.tourneyId) ? (
                <Button
                  clickHandler={openRegisterConfirmationModal}
                  bgColor={colors.secondaryGreen}
                  showIcon={false}
                >
                  <>
                    <PrimaryCTAButtonText>{"Ticket"}</PrimaryCTAButtonText>
                    <TicketIcon src={goldTicket} />
                  </>
                </Button>
              ) : (
                <Button
                  clickHandler={
                    tourneyDetails.tournamentType ===
                    Constants.TOURNEY_TYPES.TICKET
                      ? () => {}
                      : openRegisterConfirmationModal
                  }
                  bgColor={
                    tourneyDetails.tournamentType ===
                    Constants.TOURNEY_TYPES.TICKET
                      ? colors.vampireGrey
                      : colors.secondaryGreen
                  }
                  showIcon={false}
                >
                  {tourneyDetails.tournamentType ===
                  Constants.TOURNEY_TYPES.TICKET ? (
                    <NoTicketButtonWrapper>
                      <PrimaryCTAButtonText>{"No Ticket"}</PrimaryCTAButtonText>
                      <TicketIcon src={goldTicket} />
                    </NoTicketButtonWrapper>
                  ) : (
                    <PrimaryCTAButtonText id='register-button'>
                      {"Register"}
                    </PrimaryCTAButtonText>
                  )}
                </Button>
              )}
            </TourneyInfoFooterCTAWrapper>
          );
        } else if (registeredPlayerCount === maxPlayers) {
          return <MaxRegFullText>Tourney Registrations Full</MaxRegFullText>;
        } else if (hideRegistrationCTAButtons) {
          return (
            <TourneyInfoFooterCTAWrapper>
              <MaxRegFullText>
                Registrations closed, Tourney is about to start.
              </MaxRegFullText>
            </TourneyInfoFooterCTAWrapper>
          );
        }
      case Constants.TOURNEY_STATUS_LIVE:
        console.log("CHECK_BUSTED_OUT_DETAILS", tourneyPlayerStatus, bustedOut);
        const {
          isAlreadyRegisteredTourney,
          lateRegistrationAllowed,
          lateRegistrationStatus,
        } = tourneyDetails;
        if (isAlreadyRegisteredTourney && checkTourneyGameTableIsAvailable()) {
          return (
            <TourneyInfoFooterCTAWrapper>
              <Button
                clickHandler={handleJoinBackCTA}
                bgColor={colors.secondaryGreen}
                showIcon={false}
              >
                <PrimaryCTAButtonText>Join Back</PrimaryCTAButtonText>
              </Button>
            </TourneyInfoFooterCTAWrapper>
          );
        } else if (inTheMoney) {
          //NOTE: if tourney is in the money based on this flag we are hiding all cta buttons.
          return (
            <TourneyInfoFooterCTAWrapper>
              <MaxRegFullText>Tourney is in the money</MaxRegFullText>
            </TourneyInfoFooterCTAWrapper>
          );
        } else if (
          isAlreadyRegisteredTourney &&
          !checkTourneyGameTableIsAvailable() &&
          tourneyPlayerStatus === Constants.TOURNEY_REGISTERED &&
          !bustedOut
        ) {
          return (
            <TourneyInfoFooterCTAWrapper>
              <MaxRegFullText>You will join shortly</MaxRegFullText>
            </TourneyInfoFooterCTAWrapper>
          );
        } else if (
          !isAlreadyRegisteredTourney &&
          lateRegistrationAllowed &&
          lateRegistrationStatus !== "FINISHED"
        ) {
          if (
            userBalance.totalBalance < buyIn + tourneyRake &&
            !checkUserHasTicket(tourneyDetails.tourneyId)
          ) {
            return (
              <TourneyInfoFooterCTAWrapper
                reminded={selectedTourneyInfoDetails?.reminder}
              >
                <Button
                  clickHandler={handleAddCash}
                  bgColor={colors.secondaryGreen}
                  showIcon={false}
                >
                  <PrimaryCTAButtonText>{"Add Cash"}</PrimaryCTAButtonText>
                </Button>
              </TourneyInfoFooterCTAWrapper>
            );
          } else if (checkUserHasTicket(tourneyDetails.tourneyId)) {
            return (
              <TourneyInfoFooterCTAWrapper>
                <Button
                  clickHandler={openRegisterConfirmationModal}
                  bgColor={colors.secondaryGreen}
                  showIcon={false}
                >
                  <>
                    <PrimaryCTAButtonText>{"Ticket"}</PrimaryCTAButtonText>
                    <TicketIcon src={goldTicket} />
                  </>
                </Button>
              </TourneyInfoFooterCTAWrapper>
            );
          }
          return (
            <TourneyInfoFooterCTAWrapper>
              <Button
                clickHandler={openRegisterConfirmationModal}
                bgColor={colors.secondaryGreen}
                showIcon={false}
              >
                <PrimaryCTAButtonText>Late Register</PrimaryCTAButtonText>
              </Button>
            </TourneyInfoFooterCTAWrapper>
          );
        } else if (
          tourneyPlayerStatus === Constants.TOURNEY_PLAYER_STATUS_RE_ENTRY
        ) {
          return (
            <TourneyInfoFooterCTAWrapper>
              <Button
                clickHandler={openReEntryConfirmationModal}
                bgColor={colors.secondaryGreen}
                showIcon={false}
                disabled={showTourneyReEntryRequestLoading}
              >
                <PrimaryCTAButtonText>Re-Entry</PrimaryCTAButtonText>
              </Button>
            </TourneyInfoFooterCTAWrapper>
          );
        }
        return;
      default:
        return getTourneyStatusText(tourneyStatus);
    }
  };

  return (
    <TourneyInfoContainer>
      <TourneyHeaderWrapper>
        <BackLeftArrowWrapper src={leftArrow} onClick={handleLeftArrow} />
        <TourneyInfoTitleText>
          {selectedTourneyInfoDetails?.tourneyName}
        </TourneyInfoTitleText>
        <TourneyIdText>{`ID : ${selectedTourneyInfoDetails?.tourneyId}`}</TourneyIdText>
      </TourneyHeaderWrapper>
      {showTourneyInfoLoading ? (
        <LoadingViewContainer>
          <Skeleton
            baseColor={colors.primaryBlue}
            highlightColor={colors.tempNavy2}
            style={{ margin: "7px 0px" }}
            count={1}
            height={75}
            // width={40}
            borderRadius={8}
          />
          <Skeleton
            baseColor={colors.primaryBlue}
            highlightColor={colors.tempNavy2}
            style={{ margin: "7px 0px" }}
            count={1}
            height={200}
            // width={40}
            borderRadius={8}
          />
          <Skeleton
            baseColor={colors.primaryBlue}
            highlightColor={colors.tempNavy2}
            style={{ margin: "7px 0px" }}
            count={1}
            height={25}
            width={250}
            borderRadius={8}
          />
          <Skeleton
            baseColor={colors.primaryBlue}
            highlightColor={colors.tempNavy2}
            style={{ margin: "7px 0px" }}
            count={1}
            height={25}
            width={150}
            borderRadius={8}
          />
          <Skeleton
            baseColor={colors.primaryBlue}
            highlightColor={colors.tempNavy2}
            style={{ margin: "7px 0px" }}
            count={1}
            height={280}
            // width={40}
            borderRadius={8}
          />
        </LoadingViewContainer>
      ) : (
        <>
          <TourneyInfoBodyWrapper>
            <TabbarWrapper>
              <Tabbar
                key={selectedTab}
                isInfiniteTabbar={true}
                tabItems={getTourneyInfoTabs()}
                slidesPerView={getTourneyInfoTabs().length}
                selectedTab={selectedTab}
                onTabChange={handleTourneysTabChange}
              />
            </TabbarWrapper>
            {getSelectedTourneyTabDetails()}
          </TourneyInfoBodyWrapper>
          {getTourneyInfoFooterBasedOnTourneyStats(selectedTourneyInfoDetails)}
        </>
      )}
      {showTourneyRegisterConfirmationPopup && (
        <ConfirmationPopupRevamp
          headerTitle={"Info"}
          title={`Do you want to ${
            selectedTourneyInfoDetails.isAlreadyRegisteredTourney
              ? "deregister from "
              : "register for"
          }  ${selectedTourneyInfoDetails?.tourneyName} ?`}
          hideNoteTextInDescription={true}
          description={
            selectedTourneyInfoDetails.tournamentType ===
              Constants.TOURNEY_TYPES.TICKET ||
            checkUserHasTicket(selectedTourneyInfoDetails.tourneyId)
              ? ""
              : `Amount : ₹${
                  selectedTourneyInfoDetails.buyIn +
                  selectedTourneyInfoDetails.tourneyRake
                }`
          }
          secondaryCTAText={"No"}
          primaryCTAText={
            showTourneyRegisterOrDeregisterRequestLoading ? (
              <Loader size={25} />
            ) : (
              "Yes"
            )
          }
          onSecondaryCTAClick={closeRegistrationConfirmationModal}
          onPrimaryCTAClick={
            selectedTourneyInfoDetails.isAlreadyRegisteredTourney
              ? handleTourneyDeRegister
              : handleTourneyRegister
          }
          handleCloseModal={closeRegistrationConfirmationModal}
        />
      )}

      {showTourneyReEntryConfirmationPopup && (
        <ConfirmationPopupRevamp
          headerTitle={"Info"}
          title={`Do you want to re-enter for ${selectedTourneyInfoDetails?.tourneyName} ?`}
          description={
            selectedTourneyInfoDetails.tournamentType ===
            Constants.TOURNEY_TYPES.TICKET
              ? ""
              : `Amount : ₹${selectedTourneyInfoDetails?.reEntry?.reEntrySettings?.reentryEntryFee}`
          }
          hideNoteTextInDescription={true}
          secondaryCTAText={"No"}
          primaryCTAText={
            showTourneyReEntryRequestLoading ? <Loader size={25} /> : "Yes"
          }
          onSecondaryCTAClick={closeReEntryConfirmationModal}
          onPrimaryCTAClick={handleReEntryCTA}
          handleCloseModal={closeReEntryConfirmationModal}
        />
      )}
      {showTourneyAddCashTicketRegistrationPopup && (
        <ConfirmationPopupRevamp
          headerTitle={"Info"}
          title={
            <DirectAddCashModalDescriptionContainer>
              {selectedTourneyInfoDetails?.addCashTicketRegistration &&
                selectedTourneyInfoDetails?.allowedFreeRegistrations > 0 && (
                  <DescriptionText>
                    {"Direct entries are Full!"}
                  </DescriptionText>
                )}
              <DescriptionText>
                {"Please add cash to get a Free ticket"}
                <TicketIcon src={goldTicket} />
              </DescriptionText>
              <DescriptionText>{"to this tourney"}</DescriptionText>
              <MinimumAddCashWrapper>
                <DescriptionText>Minimum Add cash: </DescriptionText>
                <AddCashAmountText>{`₹ ${parseDecimalValuesOfNumber(
                  selectedTourneyInfoDetails?.minAddCashAmount,
                  2,
                  checkToDisplayValueInK(
                    selectedTourneyInfoDetails?.minAddCashAmount
                  ),
                  checkToDisplayValueInL(
                    selectedTourneyInfoDetails?.minAddCashAmount
                  )
                )}`}</AddCashAmountText>
              </MinimumAddCashWrapper>
            </DirectAddCashModalDescriptionContainer>
          }
          hideNoteTextInDescription={true}
          primaryCTAText={"Add Cash"}
          onPrimaryCTAClick={handleAddCash}
          handleCloseModal={closeTourneyAddCashTicketRegistrationConfirmation}
        />
      )}

      <Toast isLobbyToasts={true} />
      {showTourneyInsufficientFundsModal && (
        <Confirm
          type={Constants.INSUFFICIENT_BALANCE}
          handleModalClose={handleModalClose}
          modalConfig={{ ...selectedTourneyInfoDetails.lowBalanceDetails }}
          isTourneyGame={true}
        />
      )}
    </TourneyInfoContainer>
  );
}

export default TourneyInfo;
