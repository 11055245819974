import { getGameSlice, getLobbySlice, getPlayerObject } from "../../utils/ReduxUtils";
import TableUtils from "../../utils/TableUtils";
import {
  DISCONNECTION,
  GAME_AWS_COOKIE_PROTOCOL_SUCCESS,
  GAME_CONNECTION_PROTOCOL_SUCCESS,
  GAME_TABLE_WATCH_CHANNEL_EXIST,
  INSUFFICIENT_BALANCE,
  RELOGIN_FAILURE,
  TABLE_STATS,
  TOURNEY_CANCEL_WITH_HOLD,
  TOURNEY_CANCEL_WITH_REFUND,
  TOURNEY_GAME_COMPLETE,
  TOURNEY_WATCH_GAME_COMPLETED,
  TOURNEY_TPS_REQUEST_WITH_DELAY,
  RESTRICTED_LOCATION_HOURS,
  DELETED_PLAYER,
  TO_BE_DELETED_PLAYER,
  LB_REAL,
} from "../../data/Constants";
import {
  processPlayersDataOnTable,
  processLeaveSeatAck,
  setBuyInValueForPlayerPosition,
  setRemoveReserveSeatForPlayer,
  handleGameJoinRequest,
  handlePlayerJoinBroadcast,
  handleReserveSeatBroadcast,
  handleRemoveReserveSeatBroadcast,
  handleStartGame,
  handlePlayerAutoActions,
  handleTableTimerChangeRequest,
  handleStartGameTimer,
  handleTurnChange,
  processLeaveSeatFailure,
  handleRevealCards,
  handleCallActionAck,
  handleCheckActionAck,
  handleFoldActionAck,
  handleRaiseActionAck,
  handleAutoCallActionAck,
  handleAutoCheckActionAck,
  handleAutoFoldActionAck,
  handleAutoCallAnyActionAck,
  handleAutoCheckFoldActionAck,
  removePlayerFromTable,
  updateGameConnectionFailureProtocolStatus,
  handleJoinFailureAction,
  handleCallActionBroadcast,
  handleCheckActionBroadcast,
  handleFoldActionBroadcast,
  handleRaiseActionBroadcast,
  handlePotWinner,
  handlePrizePotAmount,
  updateHandHistory,
  syncUpTableTimers,
  sitInAction,
  sitOutAction,
  updateWaitingForBB,
  sitOutBroadcastAction,
  handleRankDetails,
  handleResult,
  processPlayersDataOnTableForAlreadyRunningGame,
  sitInBroadcastAction,
  sitOutFailAction,
  sitInFailAction,
  processLeaveSeatAckFromSitOut,
  leaveSeatBroadcastAction,
  sitOutCancelAction,
  handleRSeatBroadcast,
  handlePlayerExtraTime,
  initiateNextGameStartRequest,
  handleCloseFailure,
  handleCleanTable,
  handleAutoRebuySuccess,
  handleAutoRebuyFailure,
  handleRebuyIn,
  handleDRebuyIn,
  handleRebuyFailure,
  handleRebuySuccessAck,
  handleRebuySuccessBroadcast,
  handleAutoRebuyBroadcast,
  handleTopupSuccessAck,
  handleTopupBroadcast,
  handleTopupFailure,
  checkPlayerIsAvailableInSeats,
  processReconnectionAck,
  handleRefreshProtocolData,
  handleReloginFailure,
  handlePreSelectedAutoAction,
  processRabbitHuntingDetails,
  updateTableStats,
  openLocalGameModal,
  closeInternetDisconnectionGameModal,
  handleNextGameTimer,
  updateGameConnectionStatusN,
  updatePlayerDetails,
  handleTourneyPrize,
  handleBlindsUpDetails,
  handleTourneyBreakBroadcast,
  handleTourneyNextLevelUp,
  updateAutoTableSwitchQueue,
  handlePlayerDisconnectionTime,
  handleTourneyEliminate,
  updateUserRestrictionStatus,
  processPlayerStatsOnDemand,
  processPlayerStatsOnDemandFailure,
  processSavePlayerStatsResponse,
  processPlayerStatsData,
  handlersTourneyRebuyIn,
  handleTourneyRebuyAck,
  handleTourneyRebuyCancle,
  handleTourneyRebuyBroadcast,
  handleTourneyRebuyFailure,
  processAutoTableSwitch,
  processReserveFailure,
  handleTourneyAddonAck,
  handleMaintenancePopup,
  handleTourneyAddonFailureAck,
  handleTourneyRankingLeaderBoard,
  handleTourneyPlayerRankDetails,
  handleTourneyAddOn,
  handleTourneyTableSwitching,
  handleTourneyNodeSwitch,
  handleTourneyBreakEnd,
  handleTourneyRebuyEnded,
  handleJoinWaitListAck,
  handleRemoveFromWaitList,
  handleJoinOrRemoveFromWaitListBroadcast,
  handleWaitListFailure,
  processPlayerConfigAck,
  handleTourneyWatchPlayerAddOn,
  updateAmountToAdd,
  handleTourneyPlayerInTheMoneyDetails,
  handleTourneyWaitingForMerging,
  handleTopupCancel,
  checkForTableMergeBannerAndClose,
  handleRevealOtherPlayerCards,
  updateWinningPotDisplay,
  handleTourneyAutoPlayAcknowledgement,
  handlePlayerExtraTimeLeft,
  processHappyHoursInfo,
  processHappyHoursInfoFailure,
  updateComplianceBlocked,
  handleStartPrivateTableAck,
  handleConcludePrivateTable,
  handleAnnouncementPrivateTableAck,
  handleAnnouncementBroadcastPrivateTable,
  handleTerminatePrivateTableAck,
  handleTerminatePrivateTableFailure,
  enableRIT,
  handleRITRevealCards,
  handleRepaintPotAmounts,
  removePrivateTableRunningGameDef,
} from "../slices/gameTableSlice";
import { closeSlider, openSlider } from "../slices/sliderSlice";
import {
  requestCurrentFunChipsBalance,
  requestUpdatedTourneyPlayerStatus,
  updateTourneyPlayerBustedOut,
  updateComplianceToastMessage,
} from "../slices/lobbySlice";
import { returnGameTypeText } from "../../../Common/utils/PokerUtils";

class GameTableService {
  constructor(dispatchFunction, tempTableId) {
    this.dispatchFunction = dispatchFunction;
    this.tempTableId = tempTableId;
    this.isComplainceBlocked = false;
  }

  initGame() {
    this.dispatchFunction(
      updateGameConnectionStatusN({
        tempTableId: this.tempTableId,
        status: GAME_CONNECTION_PROTOCOL_SUCCESS,
      })
    );
  }

  heartBeat(message) {
    // Making sure there is a time update before dispatching an action
    if (message.time) {
      this.dispatchFunction(
        syncUpTableTimers({ data: message, tempTableId: this.tempTableId })
      );
    }
  }

  connectionFailResponder() {
    this.dispatchFunction(
      updateGameConnectionFailureProtocolStatus({
        tempTableId: this.tempTableId,
      })
    );
  }

  reconnectionAck(messageObject) {
    messageObject.tempTableId = this.tempTableId;
    this.dispatchFunction(processReconnectionAck(messageObject));

    this.dispatchFunction(
      updateGameConnectionStatusN({
        tempTableId: this.tempTableId,
        status: GAME_CONNECTION_PROTOCOL_SUCCESS,
      })
    );
  }

  handleAwsAlbCookieAck() {
    TableUtils.getInstance()
      .getTableObserver(this.tempTableId)
      .triggerConnectionProtocol();
    this.dispatchFunction(
      updatePlayerDetails({ lobbyPlayerData: getPlayerObject() })
    );
    this.dispatchFunction(
      updateGameConnectionStatusN({
        tempTableId: this.tempTableId,
        status: GAME_AWS_COOKIE_PROTOCOL_SUCCESS,
      })
    );
  }

  processSeatsOnTable(gameDetails) {
    this.dispatchFunction(
      checkPlayerIsAvailableInSeats({
        ...gameDetails,
        tempTableId: this.tempTableId,
      })
    );

    this.dispatchFunction(
      processPlayersDataOnTable({
        ...gameDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processRunningTableChairs(gameDetails) {
    this.dispatchFunction(
      checkPlayerIsAvailableInSeats({
        ...gameDetails,
        tempTableId: this.tempTableId,
      })
    );

    this.dispatchFunction(
      processPlayersDataOnTableForAlreadyRunningGame({
        ...gameDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  cleanTableUp() {
    this.dispatchFunction(
      handleCleanTable({
        tempTableId: this.tempTableId,
      })
    );
  }

  closeTableAck() {
    // this.dispatchFunction(
    //   updateAutoTableSwitchQueue({ tempTableId: this.tempTableId })
    // );

    // this.dispatchFunction(requestCurrentFunChipsBalance());
    // TableUtils.getInstance().removeTableObserver(this.tempTableId); //deleteGameTable reducer method will be invoked from table observer 'cleanUp' method

    console.log("At close A:", this.isComplainceBlocked);
    if (!this.isComplainceBlocked) {
      this.dispatchFunction(
        updateAutoTableSwitchQueue({ tempTableId: this.tempTableId })
      );

      // remove gdid from running game def ids array for private table
      this.dispatchFunction(
        removePrivateTableRunningGameDef({
          tempTableId: this.tempTableId,
        })
      );

      TableUtils.getInstance().removeTableObserver(this.tempTableId); //deleteGameTable reducer method will be invoked from table observer 'cleanUp' method
    }

    this.dispatchFunction(requestCurrentFunChipsBalance());
  }

  leaveTableAck(gameDetails) {
    gameDetails.tempTableId = this.tempTableId;
    this.dispatchFunction(
      updateAutoTableSwitchQueue({ tempTableId: this.tempTableId })
    );
    this.dispatchFunction(processLeaveSeatAck(gameDetails));
  }

  leaveTableOnSitOutTimerEnd(gameDetails) {
    gameDetails.tempTableId = this.tempTableId;
    this.dispatchFunction(
      updateAutoTableSwitchQueue({ tempTableId: this.tempTableId })
    );
    this.dispatchFunction(processLeaveSeatAckFromSitOut(gameDetails));
  }

  processLeaveBroadcast(data) {
    data.tempTableId = this.tempTableId;
    this.dispatchFunction(leaveSeatBroadcastAction(data));
  }

  processSitIn(message) {
    this.dispatchFunction(
      sitInAction({ data: message, tempTableId: this.tempTableId })
    );
  }

  processSitOut(message) {
    this.dispatchFunction(
      sitOutAction({ data: message, tempTableId: this.tempTableId })
    );
  }

  processSitInFailure(message) {
    let gameDefinition = getGameSlice()?.games[this.tempTableId].gameDefinition;
    if (message.statusCode === 502) {
      this.dispatchFunction(
        openLocalGameModal({
          type: INSUFFICIENT_BALANCE,
          tempTableId: this.tempTableId,
          config: {
            blindsText: gameDefinition
              ? `(${returnGameTypeText(gameDefinition.gameType)} ${
                  gameDefinition.smallBlind
                }/${gameDefinition.bigBlind})`
              : "",
          },
        })
      );
      if (message?.amountToAdd) {
        this.dispatchFunction(
          updateAmountToAdd({
            tempTableId: this.tempTableId,
            amountToAdd: message.amountToAdd,
          })
        );
      }
    } else if (message.statusCode === 112) {
      this.isComplainceBlocked = true;
      //User trying to join during restricted hours (tamilnadu state applicable for now)
      TableUtils.getInstance().removeTableObserver(this.tempTableId, false);

      this.dispatchFunction(
        updateComplianceBlocked({
          tempTableId: this.tempTableId,
          isComplainceBlocked: this.isComplainceBlocked,
        })
      );

      this.dispatchFunction(
        openLocalGameModal({
          type: RESTRICTED_LOCATION_HOURS,
          tempTableId: this.tempTableId,
        })
      );
    }
    this.dispatchFunction(
      sitInFailAction({
        tempTableId: this.tempTableId,
        statusCode: message.statusCode,
      })
    );
  }

  processSitOutFailure(message) {
    this.dispatchFunction(
      sitOutFailAction({
        tempTableId: this.tempTableId,
        statusCode: message.statusCode,
      })
    );
  }

  processSitOutBroadcast(message) {
    this.dispatchFunction(
      sitOutBroadcastAction({ data: message, tempTableId: this.tempTableId })
    );
  }

  processSitInBroadcast(message) {
    this.dispatchFunction(
      sitInBroadcastAction({ data: message, tempTableId: this.tempTableId })
    );
  }

  processSitOutCancel(message) {
    this.dispatchFunction(
      sitOutCancelAction({ data: message, tempTableId: this.tempTableId })
    );
  }

  processWaitForBBMessage(message) {
    let { isWaitingForBigBlind } = message;
    this.dispatchFunction(
      updateWaitingForBB({
        tempTableId: this.tempTableId,
        isWaitingForBigBlind,
      })
    );
  }

  processLeaveSeatFailure() {
    this.dispatchFunction(
      processLeaveSeatFailure({ tempTableId: this.tempTableId })
    );
  }

  processReserveAck(reserveDetails) {
    this.dispatchFunction(
      setBuyInValueForPlayerPosition({
        ...reserveDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processReserveFailure(reserveDetails) {
    this.dispatchFunction(
      processReserveFailure({
        ...reserveDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processRemoveReserveSeat(reserveDetails) {
    this.dispatchFunction(
      setRemoveReserveSeatForPlayer({
        ...reserveDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processGameJoinRequest(gameJoinDetails) {
    this.dispatchFunction(
      handleGameJoinRequest({
        userDetails: gameJoinDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processHappyHoursInfo(data) {
    console.log("Spruha log >>> processing happy hours info in service");
    this.dispatchFunction(processHappyHoursInfo(data));
  }

  processHappyHoursInfoFailure(data) {
    this.dispatchFunction(processHappyHoursInfoFailure(data));
  }

  processGameJoinBroadcastMessage(gameJoinDetails) {
    this.dispatchFunction(
      handlePlayerJoinBroadcast({
        userDetails: gameJoinDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  handleJoinFailure(receivedData) {
    console.log("receivedData:", receivedData.statusCode);
    if (receivedData?.statusCode === 112) {
      console.log("Setting close A processing");
      this.isComplainceBlocked = true;
      console.log(
        "AFTER Setting close A processing:",
        this.isComplainceBlocked
      );
      TableUtils.getInstance().removeTableObserver(this.tempTableId, false);

      this.dispatchFunction(
        updateComplianceBlocked({
          tempTableId: this.tempTableId,
          isComplainceBlocked: this.isComplainceBlocked,
        })
      );
    }

    this.dispatchFunction(
      handleJoinFailureAction({
        tempTableId: this.tempTableId,
        status: receivedData?.status,
        statusCode: receivedData?.statusCode,
        position: receivedData?.position,
        tableId: receivedData?.tableId,
        lowBalanceData: receivedData?.lowBalanceData,
      })
    );
  }

  processReserveSeatBroadcast(reserveDetails) {
    this.dispatchFunction(
      handleReserveSeatBroadcast({
        ...reserveDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processRemoveReserveSeatBroadcast(dReserveDetails) {
    this.dispatchFunction(
      handleRemoveReserveSeatBroadcast({
        ...dReserveDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processStartGameTimer(timerDetails) {
    this.dispatchFunction(
      handleStartGameTimer({
        ...timerDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processPlayerDisconnectionTime(timerDetails) {
    this.dispatchFunction(
      handlePlayerDisconnectionTime({
        ...timerDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processStartGame(gameDetails) {
    this.dispatchFunction(
      handleStartGame({
        ...gameDetails,
        tempTableId: this.tempTableId,
      })
    );
    this.dispatchFunction(
      checkForTableMergeBannerAndClose({
        tempTableId: this.tempTableId,
      })
    );
  }

  processAutoUserActions(autoActions) {
    this.dispatchFunction(
      handlePlayerAutoActions({
        autoActions: autoActions,
        tempTableId: this.tempTableId,
      })
    );
  }

  processTableTimer(timerDetails) {
    this.dispatchFunction(
      handleTableTimerChangeRequest({
        ...timerDetails,
        tempTableId: this.tempTableId,
      })
    );
  }
  processTurnChange(turnDetails) {
    let gamesData = getGameSlice();
    let autoTableSwitchSetting = gamesData.tableSettings.autoTableSwitch;
    if (autoTableSwitchSetting) {
      this.dispatchFunction(closeSlider());
    }
    this.dispatchFunction(
      handleTurnChange({
        turnDetails: turnDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processUserActionStatus(data) {
    this.dispatchFunction(
      updateUserRestrictionStatus({
        tempTableId: this.tempTableId,
        status: data?.actionDisabled,
      })
    );
  }

  processCallActionAck(actionDetails) {
    this.dispatchFunction(
      handleCallActionAck({
        ...actionDetails,
        tempTableId: this.tempTableId,
      })
    );
    this.dispatchFunction(closeSlider());
    let timeout;
    timeout = setTimeout(() => {
      this.dispatchFunction(
        processAutoTableSwitch({
          tempTableId: this.tempTableId,
        })
      );
      clearTimeout(timeout);
    }, 1000);
  }
  processCallActionBroadcast(actiondetails) {
    this.dispatchFunction(
      handleCallActionBroadcast({
        ...actiondetails,
        tempTableId: this.tempTableId,
      })
    );
  }
  processCheckActionAck(actionDetails) {
    this.dispatchFunction(
      handleCheckActionAck({
        ...actionDetails,
        tempTableId: this.tempTableId,
      })
    );
    this.dispatchFunction(closeSlider());
    let timeout;
    timeout = setTimeout(() => {
      this.dispatchFunction(
        processAutoTableSwitch({
          tempTableId: this.tempTableId,
        })
      );
      clearTimeout(timeout);
    }, 1000);
  }

  processCheckActionBroadcast(actionDetails) {
    this.dispatchFunction(
      handleCheckActionBroadcast({
        ...actionDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processFoldActionAck(actionDetails) {
    this.dispatchFunction(
      handleFoldActionAck({
        ...actionDetails,
        tempTableId: this.tempTableId,
      })
    );
    this.dispatchFunction(closeSlider());
    let timeout;
    timeout = setTimeout(() => {
      this.dispatchFunction(
        processAutoTableSwitch({
          tempTableId: this.tempTableId,
        })
      );
      clearTimeout(timeout);
    }, 1000);
  }

  processFoldActionBroadcast(actionDetails) {
    this.dispatchFunction(
      handleFoldActionBroadcast({
        ...actionDetails,
        tempTableId: this.tempTableId,
      })
    );
  }
  processRaiseActionAk(actionDetails) {
    this.dispatchFunction(
      handleRaiseActionAck({
        ...actionDetails,
        tempTableId: this.tempTableId,
      })
    );
    this.dispatchFunction(closeSlider());
    let timeout;
    timeout = setTimeout(() => {
      this.dispatchFunction(
        processAutoTableSwitch({
          tempTableId: this.tempTableId,
        })
      );
      clearTimeout(timeout);
    }, 1000);
  }

  processRaiseActionBroadcast(actionDetails) {
    this.dispatchFunction(
      handleRaiseActionBroadcast({
        ...actionDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processAutoCallActionAck(actionDetails) {
    this.dispatchFunction(
      handleAutoCallActionAck({
        ...actionDetails,
        tempTableId: this.tempTableId,
      })
    );
  }
  processPlayerExitTableBroadcast(exitedPlayerDetails) {
    this.dispatchFunction(
      removePlayerFromTable({
        userName: exitedPlayerDetails.playerName,
        tempTableId: this.tempTableId,
      })
    );
  }
  processPotWinner(winnerDetails) {
    // if (winnerDetails.rit) {
    //   this.dispatchFunction(
    //     handleRepaintPotAmounts({
    //       tempTableId: this.tempTableId,
    //       ...winnerDetails,
    //     })
    //   );
    // }
    this.dispatchFunction(
      handlePotWinner({
        tempTableId: this.tempTableId,
      })
    );

    // Have to loop over each pot here and update the pot details and participants in redux. Give 1 second for each pot.
    // In the UI, depending on the active pot, we need to display the participants hand strength.
    //Incase of split pot, the hand strength of winners will always be equal. Chip animation should occur simultaneously and not 1 by 1.
    //'Split Pot' text to be shown on the table
    const { potWinners, netWinningsPlayers } = winnerDetails;
    const { potWinnersList } = potWinners;

    let dynamicTimeout;

    dynamicTimeout = 1500;

    potWinnersList.forEach((pot, index) => {
      let timeout;
      let potData = pot;
      potData.potIndex = winnerDetails.rit
        ? potWinnersList.length + index
        : index;
      potData.netWinningPlayers = netWinningsPlayers;
      potData.run = winnerDetails.rit ? 2 : 1;
      if (index === 0) {
        this.dispatchFunction(
          updateWinningPotDisplay({
            tempTableId: this.tempTableId,
            pot: potData,
          })
        );
      } else {
        timeout = setTimeout(() => {
          this.dispatchFunction(
            updateWinningPotDisplay({
              tempTableId: this.tempTableId,
              pot: potData,
            })
          );
          clearTimeout(timeout);
        }, dynamicTimeout * index);
      }
    });

    let loggedInPlayerObject = getPlayerObject();
    let showDeletedPlayerAlert =
      loggedInPlayerObject.playerStatus === DELETED_PLAYER ||
      loggedInPlayerObject.playerStatus === TO_BE_DELETED_PLAYER;

    let showLocationRestrictionAlert = false;
  const location_games = getLobbySlice().location_games;
    if (!location_games) {
      let gameDefinition =
        getGameSlice()?.games[this.tempTableId].gameDefinition;
      if (gameDefinition && gameDefinition.flavourType === LB_REAL) {
        showLocationRestrictionAlert = true;
      }
    }

    if (showDeletedPlayerAlert || showLocationRestrictionAlert) {
      TableUtils.getInstance().removeTableObserver(this.tempTableId, false);
    }

    this.dispatchFunction(
      initiateNextGameStartRequest({
        tempTableId: this.tempTableId,
        loggedInPlayerObject,
        showDeletedPlayerAlert,
        showLocationRestrictionAlert,
      })
    );

    // this.dispatchFunction(
    //   initiateNextGameStartRequest({
    //     tempTableId: this.tempTableId,
    //     loggedInPlayerObject: getPlayerObject(),
    //   })
    // );
  }

  processAutoCallAnyActionAck(actionDetails) {
    this.dispatchFunction(
      handleAutoCallAnyActionAck({
        ...actionDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processAutoCheckActionAck(actionDetails) {
    this.dispatchFunction(
      handleAutoCheckActionAck({
        ...actionDetails,
        tempTableId: this.tempTableId,
      })
    );
  }
  processAutoCheckFoldActionAck(actionDetails) {
    this.dispatchFunction(
      handleAutoCheckFoldActionAck({
        ...actionDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processAutoFoldActionAck(actionDetails) {
    this.dispatchFunction(
      handleAutoFoldActionAck({
        ...actionDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processPrizePot(potAmountDetails) {
    this.dispatchFunction(
      handlePrizePotAmount({
        potAmountDetails: potAmountDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processMaintenancePopup() {
    this.dispatchFunction(
      handleMaintenancePopup({ tempTableId: this.tempTableId })
    );
  }

  processConcludePrivateTable(data) {
    this.dispatchFunction(
      handleConcludePrivateTable({
        tempTableId: this.tempTableId,
        ...data,
      })
    );
  }

  processRevealCards(revealCardsDetails) {
    this.dispatchFunction(
      handleRevealCards({
        revealCardsDetails: revealCardsDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processRankDetails(rankDetails) {
    this.dispatchFunction(
      handleRankDetails({
        data: rankDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processRevealOthersCards(result) {
    this.dispatchFunction(
      handleRevealOtherPlayerCards({
        resultDetails: result,
        tempTableId: this.tempTableId,
      })
    );
  }

  processResult(result) {
    // Added reveal other player cards here also for IM-3084. Edge case where only 2ms time gap between start and result. Result is declared immediately and then start# execution undoes changes.
    this.processRevealOthersCards(result);

    this.dispatchFunction(
      handleResult({
        resultDetails: result,
        tempTableId: this.tempTableId,
      })
    );
  }

  processHandHistory(details) {
    this.dispatchFunction(
      updateHandHistory({
        details: details,
        tempTableId: this.tempTableId,
      })
    );
  }

  processTableStats(details) {
    this.dispatchFunction(
      updateTableStats({
        details: details,
        tempTableId: this.tempTableId,
      })
    );
    this.dispatchFunction(openSlider({ type: TABLE_STATS }));
  }

  processRSeatsBroadcast(details) {
    this.dispatchFunction(
      handleRSeatBroadcast({
        ...details,
        tempTableId: this.tempTableId,
      })
    );
  }

  processPlayerExtraTime(details) {
    this.dispatchFunction(
      handlePlayerExtraTime({
        ...details,
        tempTableId: this.tempTableId,
      })
    );
  }

  processPlayerExtraTimeLeft(data) {
    this.dispatchFunction(
      handlePlayerExtraTimeLeft({
        ...data,
        tempTableId: this.tempTableId,
      })
    );
  }

  processCloseFailure() {
    this.dispatchFunction(
      handleCloseFailure({ tempTableId: this.tempTableId })
    );
  }

  processNextGameCancel(data, cleanGameId = true) {
    if (data?.status === 800) {
      TableUtils.getInstance().removeTableObserver(this.tempTableId, false);
    }
    this.dispatchFunction(
      handleCleanTable({
        tempTableId: this.tempTableId,
        status: data?.status,
        cleanGameId: cleanGameId,
      })
    );
  }

  processNextGameTimer() {
    this.dispatchFunction(
      handleNextGameTimer({
        tempTableId: this.tempTableId,
      })
    );
  }

  processAutoRebuySuccessAck(rebuyDetails) {
    this.dispatchFunction(
      handleAutoRebuySuccess({
        ...rebuyDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processAutoRebuyBroadcast(rebuyDetails) {
    this.dispatchFunction(
      handleAutoRebuyBroadcast({
        ...rebuyDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processAutoRebuyFailure(receivedData) {
    this.dispatchFunction(
      handleAutoRebuyFailure({
        tempTableId: this.tempTableId,
        ...receivedData,
      })
    );
  }

  processRebuyIn(rebuyinDetails) {
    this.dispatchFunction(
      handleRebuyIn({
        ...rebuyinDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processDRebuyIn(dRebuyDetails) {
    this.dispatchFunction(
      handleDRebuyIn({
        ...dRebuyDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processRebuyFailure(data) {
    this.dispatchFunction(
      handleRebuyFailure({
        tempTableId: this.tempTableId,
        ...data,
      })
    );
  }

  processRebuyInSuccess(rebuyDetails) {
    this.dispatchFunction(
      handleRebuySuccessAck({
        ...rebuyDetails,
        tempTableId: this.tempTableId,
      })
    );
  }
  processRebuyInSuccessBroadcast(rebuyDetails) {
    this.dispatchFunction(
      handleRebuySuccessBroadcast({
        ...rebuyDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processOnDemandPlayerStats(data) {
    this.dispatchFunction(
      processPlayerStatsOnDemand({ tempTableId: this.tempTableId, data: data })
    );
  }
  processPlayerStatsData(data) {
    this.dispatchFunction(
      processPlayerStatsData({ tempTableId: this.tempTableId, data: data })
    );
  }

  processOnDemandPlayerStatsFailure() {
    this.dispatchFunction(
      processPlayerStatsOnDemandFailure({ tempTableId: this.tempTableId })
    );
  }

  processSavePlayerStats(data) {
    this.dispatchFunction(
      processSavePlayerStatsResponse({ tempTableId: this.tempTableId, data })
    );
  }

  processNodeMismatch() {
    TableUtils.getInstance().initiateNodeMismatchFlow(this.tempTableId);
  }

  processTopupSuccessAcknowledgement(topupDetails) {
    this.dispatchFunction(
      handleTopupSuccessAck({
        ...topupDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processTopupBroadcast(topupDetails) {
    this.dispatchFunction(
      handleTopupBroadcast({
        ...topupDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processTopupFailure() {
    this.dispatchFunction(
      handleTopupFailure({
        tempTableId: this.tempTableId,
      })
    );
  }

  processTopupCancel(data) {
    this.dispatchFunction(
      handleTopupCancel({
        tempTableId: this.tempTableId,
        ...data,
      })
    );
  }

  processAntiBankingDetails(data) {}
  processRefreshProtocolData(refreshData) {
    this.dispatchFunction(
      handleRefreshProtocolData({
        ...refreshData,
        tempTableId: this.tempTableId,
      })
    );
    this.dispatchFunction(
      checkForTableMergeBannerAndClose({
        tempTableId: this.tempTableId,
      })
    );
    // Closing internet disconnection modal only after refresh# is received.
    this.dispatchFunction(
      closeInternetDisconnectionGameModal({
        tempTableId: this.tempTableId,
        type: DISCONNECTION,
      })
    );
  }

  processReloginConnectionAck() {
    this.dispatchFunction(
      updateGameConnectionStatusN({
        tempTableId: this.tempTableId,
        status: GAME_CONNECTION_PROTOCOL_SUCCESS,
      })
    );
  }

  processReloginFailure() {
    TableUtils.getInstance().removeTableObserver(this.tempTableId, false); //deleteGameTable reducer method will be invoked from table observer 'cleanUp' method
    this.dispatchFunction(
      handleReloginFailure({
        tempTableId: this.tempTableId,
      })
    );
    this.dispatchFunction(
      openLocalGameModal({
        type: RELOGIN_FAILURE,
        tempTableId: this.tempTableId,
      })
    );
  }

  processPreSelectedAutoAction(preSelectedAutoActionDetails) {
    this.dispatchFunction(
      handlePreSelectedAutoAction({
        tempTableId: this.tempTableId,
        ...preSelectedAutoActionDetails,
      })
    );
  }

  processRabbitHunting(data) {
    this.dispatchFunction(
      processRabbitHuntingDetails({
        tempTableId: this.tempTableId,
        cards: [...data],
      })
    );
  }

  processRIT() {
    this.dispatchFunction(enableRIT({ tempTableId: this.tempTableId }));
  }

  processRITRevealCard(revealCardsDetails) {
    this.dispatchFunction(
      handleRITRevealCards({
        revealCardsDetails: revealCardsDetails,
        tempTableId: this.tempTableId,
      })
    );
  }

  processTourneyPrizeData(prizeData) {
    //NOTE: As soon as as we get the torueny prize we are removing channel as this channel wont exist longer.
    if (!prizeData?.tourneyEnded) {
      this.dispatchFunction(
        updateTourneyPlayerBustedOut({
          tourneyId: prizeData?.tourneyId,
          bustedOut: true,
        })
      );
      let timeout = setTimeout(() => {
        this.dispatchFunction(
          requestUpdatedTourneyPlayerStatus({ tourneyId: prizeData?.tourneyId })
        );
        if (timeout) {
          clearTimeout(timeout);
        }
      }, TOURNEY_TPS_REQUEST_WITH_DELAY);
    }
    TableUtils.getInstance().removeTableObserver(this.tempTableId, false);
    this.dispatchFunction(closeSlider());
    this.dispatchFunction(
      handleTourneyPrize({
        tempTableId: this.tempTableId,
        ...prizeData,
      })
    );
  }
  processBlindsUpHandler(blindsupData) {
    this.dispatchFunction(
      handleBlindsUpDetails({
        tempTableId: this.tempTableId,
        blindsUpDetails: blindsupData,
      })
    );
  }
  processBreakBroadcast(breakBroadcast) {
    this.dispatchFunction(
      handleTourneyBreakBroadcast({
        tempTableId: this.tempTableId,
        breakDetails: breakBroadcast,
      })
    );
    this.dispatchFunction(
      handleCleanTable({
        tempTableId: this.tempTableId,
        tourneyGameAndCheckExitTable: true,
      })
    );
  }
  processNextLevelUp(nextLevelDetails) {
    this.dispatchFunction(
      handleTourneyNextLevelUp({
        tempTableId: this.tempTableId,
        nextLevelDetails: { ...nextLevelDetails },
      })
    );
  }
  processTourneyEliminate(receivedData) {
    //NOTE: As soon as as we get the tourney eliminate we are removing channel as this channel no longer exists at server end
    TableUtils.getInstance().removeTableObserver(this.tempTableId, false);
    this.dispatchFunction(
      updateTourneyPlayerBustedOut({
        tourneyId: receivedData?.tourneyId,
        bustedOut: true,
      })
    );
    let timeout = setTimeout(() => {
      this.dispatchFunction(
        requestUpdatedTourneyPlayerStatus({
          tourneyId: receivedData?.tourneyId,
        })
      );
      if (timeout) {
        clearTimeout(timeout);
      }
    }, TOURNEY_TPS_REQUEST_WITH_DELAY);

    this.dispatchFunction(closeSlider());
    this.dispatchFunction(
      handleTourneyEliminate({
        tempTableId: this.tempTableId,
        ...receivedData,
      })
    );
  }

  processTourneyWatchPlayerChannelExist() {
    let tourneyId =
      getGameSlice().games[this.tempTableId].gameDefinition.tourneyId;
    console.log("WATCH CHANNEL GAME TABLE SERVICE TOURNEY ID:", tourneyId);
    //NOTE: As soon as as we get the tourney watch channel exits, we are removing channel as this channel no longer exists at server end
    TableUtils.getInstance().removeTableObserver(this.tempTableId, false);

    this.dispatchFunction(
      openLocalGameModal({
        type: GAME_TABLE_WATCH_CHANNEL_EXIST,
        tempTableId: this.tempTableId,
        config: {
          stage: "watchChannelExistProtocol",
          tourneyId: tourneyId,
        },
      })
    );
  }

  processTourneyGameComplete(receivedData) {
    //where are we closing the game socket channel in this case @Yashwanth
    this.dispatchFunction(closeSlider());
    this.dispatchFunction(
      openLocalGameModal({
        type: TOURNEY_GAME_COMPLETE,
        tempTableId: this.tempTableId,
      })
    );
  }

  processTourneyGameRebuyIn(receivedData) {
    this.dispatchFunction(
      handlersTourneyRebuyIn({
        tempTableId: this.tempTableId,
        rebuyInDetails: receivedData,
      })
    );
  }

  processTourneyGameRebuyAck(receivedData) {
    this.dispatchFunction(
      handleTourneyRebuyAck({
        tempTableId: this.tempTableId,
        ...receivedData,
      })
    );
  }
  processAutoTourneyRebuyCancle(receivedData) {
    this.dispatchFunction(
      handleTourneyRebuyCancle({
        tempTableId: this.tempTableId,
        ...receivedData,
      })
    );
  }
  processTourneyRebuyBroadcast(receivedData) {
    this.dispatchFunction(
      handleTourneyRebuyBroadcast({
        tempTableId: this.tempTableId,
        ...receivedData,
      })
    );
  }
  processTourneyRebuyFailureAck(receivedData) {
    this.dispatchFunction(
      handleTourneyRebuyFailure({
        tempTableId: this.tempTableId,
        ...receivedData,
      })
    );
  }

  processTourneyAddOnAckHandler(receivedData) {
    this.dispatchFunction(
      handleTourneyAddonAck({
        tempTableId: this.tempTableId,
        ...receivedData,
      })
    );
  }

  processTourneyAddOnWatchPlayer(receivedData) {
    this.dispatchFunction(
      handleTourneyWatchPlayerAddOn({
        tempTableId: this.tempTableId,
        ...receivedData,
      })
    );
  }

  processTourneyAddOnFailureAck(receivedData) {
    this.dispatchFunction(
      handleTourneyAddonFailureAck({
        tempTableId: this.tempTableId,
        ...receivedData,
      })
    );
  }
  processTourneyRankingLeaderboardData(receivedData) {
    this.dispatchFunction(
      handleTourneyRankingLeaderBoard({
        tempTableId: this.tempTableId,
        rankingLeaderboardData: receivedData,
        playerName: getPlayerObject().userName,
      })
    );
  }
  processTourneyPlayerRankData(receivedData) {
    this.dispatchFunction(
      handleTourneyPlayerRankDetails({
        tempTableId: this.tempTableId,
        rankDetails: receivedData,
      })
    );
  }
  processTourneyAddOnWithBreak(receivedData) {
    this.dispatchFunction(
      handleTourneyAddOn({
        tempTableId: this.tempTableId,
        addOnDetails: receivedData,
      })
    );
    if (receivedData?.endTime !== 0) {
      this.dispatchFunction(
        handleCleanTable({
          tempTableId: this.tempTableId,
          tourneyGameAndCheckExitTable: true,
        })
      );
    }
  }
  processTourneyTableSwitch(receivedData) {
    this.dispatchFunction(
      handleTourneyTableSwitching({
        tempTableId: this.tempTableId,
        ...receivedData,
      })
    );
  }

  processTourneyBreakEnd(receivedData) {
    this.dispatchFunction(
      handleTourneyBreakEnd({ tempTableId: this.tempTableId, ...receivedData })
    );
  }

  processTourneyAutoPlay(receivedData) {
    this.dispatchFunction(
      handleTourneyAutoPlayAcknowledgement({
        tempTableId: this.tempTableId,
        ...receivedData,
      })
    );
  }

  processTourneyStartNodeSwitch(receivedData) {
    TableUtils.getInstance().removeTableObserver(this.tempTableId, false);
    TableUtils.getInstance().overrideRemovedTableObserver(this.tempTableId);

    this.dispatchFunction(
      handleTourneyNodeSwitch({
        tempTableId: this.tempTableId,
        ...receivedData,
      })
    );
  }

  processTourneyRebuyEnded(receivedData) {
    this.dispatchFunction(
      handleTourneyRebuyEnded({
        tempTableId: this.tempTableId,
        ...receivedData,
      })
    );
  }

  processJoinWaitListAck(data) {
    this.dispatchFunction(
      handleJoinWaitListAck({
        tempTableId: this.tempTableId,
        ...data,
      })
    );
  }

  processRemoveFromWaitListAck(data) {
    this.dispatchFunction(
      handleRemoveFromWaitList({
        tempTableId: this.tempTableId,
        ...data,
      })
    );
  }

  processRemoveFromWaitListBroadcast(data) {
    this.dispatchFunction(
      handleJoinOrRemoveFromWaitListBroadcast({
        tempTableId: this.tempTableId,
        ...data,
      })
    );
  }

  processAddToWaitListBroadCast(data) {
    this.dispatchFunction(
      handleJoinOrRemoveFromWaitListBroadcast({
        tempTableId: this.tempTableId,
        ...data,
      })
    );
  }

  processWaitListFailure() {
    this.dispatchFunction(
      handleWaitListFailure({
        tempTableId: this.tempTableId,
      })
    );
  }

  processPlayerConfigAck(receivedData) {
    this.dispatchFunction(
      processPlayerConfigAck({
        tempTableId: this.tempTableId,
        ...receivedData,
      })
    );
  }

  processTourneyPlayerInTheMoneyDetails(receivedData) {
    this.dispatchFunction(
      handleTourneyPlayerInTheMoneyDetails({
        tempTableId: this.tempTableId,
        ...receivedData,
      })
    );
  }

  processTourneyWaitingForMergingDetails(receivedData) {
    this.dispatchFunction(
      handleTourneyWaitingForMerging({
        tempTableId: this.tempTableId,
        ...receivedData,
      })
    );
  }

  processTableEnded() {
    //NOTE: As soon as as we get the table ended in tourney we are removing channel as this channel no longer exists at server end
    TableUtils.getInstance().removeTableObserver(this.tempTableId, false);

    this.dispatchFunction(closeSlider());
    this.dispatchFunction(
      openLocalGameModal({
        type: TOURNEY_WATCH_GAME_COMPLETED,
        tempTableId: this.tempTableId,
      })
    );
  }

  processTourneyCancel(receivedData) {
    const { refundType } = receivedData;
    TableUtils.getInstance().removeTableObserver(this.tempTableId, false);
    this.dispatchFunction(
      openLocalGameModal({
        type:
          refundType.toLowerCase() === TOURNEY_CANCEL_WITH_REFUND.toLowerCase()
            ? TOURNEY_CANCEL_WITH_REFUND
            : TOURNEY_CANCEL_WITH_HOLD,
        tempTableId: this.tempTableId,
      })
    );
  }

  processGPSStateBlocked() {
    this.isComplainceBlocked = true;
    TableUtils.getInstance().removeTableObserver(this.tempTableId, false);

    this.dispatchFunction(
      updateComplianceBlocked({
        tempTableId: this.tempTableId,
        isComplainceBlocked: this.isComplainceBlocked,
      })
    );

    this.dispatchFunction(
      openLocalGameModal({
        type: RESTRICTED_LOCATION_HOURS,
        tempTableId: this.tempTableId,
      })
    );
  }
  processPrivateTableGameStart() {
    this.dispatchFunction(
      handleStartPrivateTableAck({
        tempTableId: this.tempTableId,
      })
    );
  }

  processAnnouncementPrivateTableAck() {
    this.dispatchFunction(
      handleAnnouncementPrivateTableAck({
        tempTableId: this.tempTableId,
      })
    );
  }

  processAnnouncementBroadcastPrivateTable(data) {
    this.dispatchFunction(
      handleAnnouncementBroadcastPrivateTable({
        tempTableId: this.tempTableId,
        ...data,
      })
    );
  }

  processTerminatePrivateTableAck(data) {
    this.dispatchFunction(
      handleTerminatePrivateTableAck({
        tempTableId: this.tempTableId,
        ...data,
      })
    );
  }
  processTerminatePrivateTableFailure() {
    this.dispatchFunction(handleTerminatePrivateTableFailure());
  }
  processGameDurationWarn(receivedData){
    this.dispatchFunction(
      updateComplianceToastMessage({
        ...receivedData,
      })
    )
  }
}

export default GameTableService;
