import React from "react";

import {
  Container,
  HeaderWrapper,
  ChildrenWrapper,
  FooterWrapper,
} from "./styledComponents";

function HomeLayout(props) {
  return (
    <Container id={"home-layout-container"} isWebView={props.isWebView}>
      <HeaderWrapper isWebView={props.isWebView} id="header-wrapper-parent">
        {props.header}
      </HeaderWrapper>
      <ChildrenWrapper id={"home-layout-children"} isWebView={props.isWebView}>
        {props.children}
      </ChildrenWrapper>
      {props.footer && <FooterWrapper id="footer-wrapper-parent">{props.footer}</FooterWrapper>}
    </Container>
  );
}

export default HomeLayout;
