
import Handler from "../Handler";

class PrivateTableGameDefPlayerCount extends Handler {
  execute(message) {
    let receivedData = JSON.parse(message);
    this.service.processPrivateTableGameDefPlayerCount(receivedData);
  }
}
export default PrivateTableGameDefPlayerCount;

// privatetablespc#{"playerCount":"", "gameDefId":""}