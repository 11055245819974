import React, { useEffect, useState } from "react";
import {
  DropArrow,
  DropdownButton,
  DropdownItems,
  DropdownItemsWrapper,
  DropdownWrapper,
} from "./dropdownStyles";

import dropDownArrow from "../../../assets/lobby/dropdownArrow.png";

export const Dropdown = (props) => {
  const {
    dropdownKeys,
    onClickHandler,
    type,
    defaultKey,
    updateKeysOnDataChange,
    collapseDropdown,
    toggleCollapseDropdown,
  } = props;

  const [newKeys, setNewKeys] = useState([...dropdownKeys]);
  const [selectedKeys, setSelectedKeys] = useState(defaultKey || "");
  const [showDropDownKeys, setShowDropDownKeys] = useState(false);

  useEffect(() => {
    if (defaultKey) {
      if (type === "blinds") {
        setSelectedKeys(defaultKey[0]);
      } else {
        setSelectedKeys(defaultKey);
      }
    }
  }, []);

  useEffect(() => {
    if (collapseDropdown) {
      setShowDropDownKeys(false);
    }
  }, [collapseDropdown]);

  useEffect(() => {
    setShowDropDownKeys(false);
    if (updateKeysOnDataChange) {
      setNewKeys(dropdownKeys);
      setSelectedKeys(defaultKey);
    }
  }, [updateKeysOnDataChange]);

  const selectItemFromDropdown = (key) => {
    setSelectedKeys(key);
    onClickHandler(key);
    setShowDropDownKeys(false);
  };

  const createDropdownKeys = () => {
    return (
      <DropdownItemsWrapper>
        {newKeys.map((key, index) => {
          return (
            <DropdownItems
              key={`${key}-${index}`}
              onClick={() => selectItemFromDropdown(key)}
            >
              {type === "blinds" ? key?.smallBlind + "/" + key?.bigBlind : key}
            </DropdownItems>
          );
        })}
      </DropdownItemsWrapper>
    );
  };
  return (
    <DropdownWrapper>
      <DropdownButton
        hideBottomBorder={showDropDownKeys}
        onClick={(e) => {
          setShowDropDownKeys(!showDropDownKeys);
          toggleCollapseDropdown();
          e.stopPropagation();
        }}
      >
        {type === "blinds"
          ? selectedKeys.smallBlind + "/" + selectedKeys.bigBlind
          : selectedKeys}

        <DropArrow src={dropDownArrow}></DropArrow>
      </DropdownButton>
      {showDropDownKeys && createDropdownKeys()}
    </DropdownWrapper>
  );
};
