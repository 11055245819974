import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import GoldUpArrow from "../../../assets/gameTable/GoldUpArrow.svg";
import OrangeCircle from "../../../assets/gameTable/OrangeCircle.svg";
import goldTicket from "../../../assets/lobby/GoldTicket.svg";
import goldGloryIcon from "../../../assets/lobby/goldGloryIcon.png";
import greySingleChip from "../../../assets/gameTable/GreySingleChip.png";
import greyMultipleChips from "../../../assets/gameTable/GreyMultipleChips.png";
import tourneyVerticalSeperator from "../../../assets/lobby/TourneyVerticalSeperator.svg";
import close from "../../../assets/common/close.svg";
import maskPlayerIcon from "../../../assets/lobby/MaskPlayerIcon.png";

import Tabbar from "../../../Common/components/Tabbar/Tabbar";
import { ScrollDownArrow } from "../../../Common/components/ScrollDownArrow/ScrollDownArrow";
import {
  checkExistingTourneyWatchTable,
  checkToDisplayValueInK,
  checkToDisplayValueInL,
  parseDecimalValuesOfNumber,
} from "../../../Common/utils/GameUtils";

import {
  GAME_TABLE_WATCH_CHANNEL_EXIST,
  MAX_TABLE_LIMIT,
  TOURNEY_BLINDS_TAB,
  TOURNEY_PRIZE_TAB,
  TOURNEY_RANKING_TAB,
  TOURNEY_TABLES_TAB,
} from "../../data/Constants";
import {
  closeSlider,
  updateSliderAnimatingFlag,
} from "../../redux/slices/sliderSlice";
import { handleTourneyStart } from "../../redux/slices/lobbySlice";
import {
  openLocalGameModal,
  showGameTable,
  updateActiveGame,
} from "../../redux/slices/gameTableSlice";

import { Loader } from "../Loader/Loader";
import {
  RankingLeaderboardContainer,
  RankingLeaderboardHeaderWrapper,
  HeaderTitleText,
  RankingLeaderboardDetailsWrapper,
  LevelWrapper,
  CurrentLevelWrapper,
  NextLevelWrapper,
  LevelHeadingLabel,
  LevelText,
  TourneyPaidRankAndEntrantsWrapper,
  PaidItemWrapper,
  Labeltext,
  LabelValueText,
  EntriesItemWrapper,
  YourRankWrapper,
  HorizontalSeperatorLine,
  AddonsAndRebuysWrapper,
  AddOnsWrapper,
  RebuyWrapper,
  TotalAddOnsWrapper,
  AddOnFeeWrapper,
  TotalRebuysWrapper,
  RebuyFeeWrapper,
  RebuyDescriptionText,
  BlindsupWrapper,
  BlindsUpTimeText,
  GoldUpArrowIcon,
  TourneyStatsWrapper,
  StatsItemWrapper,
  LateRegistrationWrapper,
  LateRegistrationText,
  DotIcon,
  TabbarWrapper,
  TourneySelectedTabWrapper,
  TableWrapper,
  TableHeaderWrapper,
  TableHeaderItemWrapper,
  TableRowWrapper,
  TableRowItemWrapper,
  CloseIcon,
  LoadingWrapper,
  TableIdText,
  TableValueText,
  TableRow,
  PrizeAmountText,
  AddIconText,
  TicketIcon,
  TicketsCountText,
  RankingTableRowWrapper,
  PrizeTableRowWrapper,
  PrizeItemPositionWrapper,
  PrizeItemAmountWrapper,
  RankingTableWrapper,
  CloseIconWrapper,
  EntriesText,
  DynamicPayoutStructureText,
  SlicedTourneyIDText,
  TextWrapper,
  TourneyDetailsWrapper,
  PrizePoolContainer,
  GoldTrophyIconWrapper,
  PrizeAmountWrapper,
  PrizeWorthText,
  TourneyLevelsAndBlindsContainer,
  TourneyBlindsHeadingWrapper,
  BlindGreySingleChipIcon,
  TourneySectionTitleText,
  ChipsContainer,
  ChipsHeadingWrapper,
  GreyChipsIcon,
  TabbarAndTabSectionContainer,
  ActivePlayersCountDetails,
  ActivePlayerCountText,
  RemainingText,
  VerticalSeperator,
  TourneyRankingLeaderBoardScrollableWrapper,
  PlayerIconWrapper,
} from "./tourneyRankingLeaderboardStyles";

function TourneyRankingLeaderboard(props) {
  const { sliderDirection, closeSliderProp, config } = props;
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(TOURNEY_RANKING_TAB);
  const playerName = useSelector((state) => state.lobby.player.userName);

  const currentActiveGame = useSelector(
    (state) => state.gameTable.games[config.tempTableId]
  );
  const tourneyRankingLeaderboardDetails = useSelector(
    (state) =>
      state.gameTable.games[config.tempTableId].tourneyRankingLeaderboardDetails
  );
  const isTourneyRankingLeaderboardRequestInProgress = useSelector(
    (state) =>
      state.gameTable.games[config.tempTableId]
        .isTourneyRankingLeaderboardRequestInProgress
  );
  const watchPlayer = useSelector(
    (state) => state.gameTable.games[config.tempTableId].watchPlayer
  );
  const BBDisplay = useSelector(
    (state) => state.gameTable.tableSettings.BBDisplay
  );

  const activeGames = useSelector((state) => state.gameTable.games);

  const smallBlindValue = useSelector(
    (state) =>
      state.gameTable.games[config.tempTableId]?.gameDefinition.smallBlind
  );

  const bigBlindValue = useSelector(
    (state) =>
      state.gameTable.games[config.tempTableId]?.gameDefinition.bigBlind
  );

  const payoutType = useSelector(
    (state) =>
      state.gameTable.games[config.tempTableId]?.gameDefinition.payoutType
  );

  const prizePoolType = useSelector(
    (state) =>
      state.gameTable.games[config.tempTableId]?.gameDefinition.prizePoolType
  );
  const { isFlightTourney, isFlightParentTourney } = useSelector(
    (state) => state.gameTable.games[config.tempTableId]?.gameDefinition
  );
  const ganeshGameDefinition = useSelector(
    (state) => state.gameTable.games[config.tempTableId]?.gameDefinition
  );
  console.log("ganesh_definition", tourneyRankingLeaderboardDetails);
  const handleTourneysTabChange = (tab) => {
    if (selectedTab !== tab) {
      setSelectedTab(tab);
    }
  };

  const getTourneyRankingDetailsTabs = () => {
    const tabItems = [
      {
        label: "Ranking",
        value: TOURNEY_RANKING_TAB,
      },
      {
        label: "Prize",
        value: TOURNEY_PRIZE_TAB,
      },
      {
        label: "Tables",
        value: TOURNEY_TABLES_TAB,
      },
      {
        label: "Blinds",
        value: TOURNEY_BLINDS_TAB,
      },
    ];

    if (isFlightTourney && !isFlightParentTourney) {
      return tabItems.filter((tab) => tab.value !== TOURNEY_PRIZE_TAB);
    }

    return tabItems;
  };

  const handleTableNameClick = (event, tableDetails) => {
    event.preventDefault();
    event.stopPropagation();

    if (Object.keys(activeGames).length === 4) {
      dispatch(
        openLocalGameModal({
          type: MAX_TABLE_LIMIT,
          tempTableId: config.tempTableId,
        })
      );
    } else {
      if (tourneyRankingLeaderboardDetails?.tourneyId) {
        let tableToStartDetails = {
          tourneyId: tourneyRankingLeaderboardDetails.tourneyId,
          playerName: playerName,
          tableId: tableDetails.tableID,
          gameId: "",
          domainName: tableDetails.domainName,
          nodeIp: tableDetails.nodeIp,
          watchPlayer: true,
        };

        // dispatch(
        //   handleTourneyStart({
        //     tourneys: [tableToStartDetails],
        //     isTourneyLackGame: false,
        //   })
        // );

        //returns an object
        let watchTableCheck = checkExistingTourneyWatchTable(
          tourneyRankingLeaderboardDetails.tourneyId,
          tableDetails.tableID
        );
        console.log("watchTableCheck at tourney tables: ", watchTableCheck);

        if (watchTableCheck.requestedAlreadyOpenedTempTableId) {
          console.log("IF WATCH TOURNEY RAKING LEADERBOARD");
          dispatch(
            updateActiveGame(watchTableCheck.requestedAlreadyOpenedTempTableId)
          );
          dispatch(showGameTable());
        } else if (
          watchTableCheck.openedWatchTableTempTableId &&
          !watchTableCheck.openedWatchTableConnectionRemoved
        ) {
          console.log(
            "ELSE IF WATCH TOURNEY RAKING LEADERBOARD:",
            tableToStartDetails
          );
          dispatch(
            updateActiveGame(watchTableCheck.openedWatchTableTempTableId)
          );
          dispatch(showGameTable());

          dispatch(
            openLocalGameModal({
              type: GAME_TABLE_WATCH_CHANNEL_EXIST,
              tempTableId: watchTableCheck.openedWatchTableTempTableId,
              config: {
                stage: "watchChannelExistCheck",
                tableToStartDetails: tableToStartDetails,
              },
            })
          );
        } else {
          console.log(
            "ELSE FINAL WATCH TOURNEY RAKING LEADERBOARD:",
            tableToStartDetails
          );
          dispatch(
            handleTourneyStart({
              tourneys: [tableToStartDetails],
              isTourneyLackGame: false,
            })
          );
        }

        // if (watchTableCheck.isTableFound) {
        //   dispatch(updateActiveGame(watchTableCheck.openedTableTempTableId));
        //   dispatch(showGameTable());

        //   if (!watchTableCheck.isSameTableIdOpened) {
        //     dispatch(
        //       openLocalGameModal({
        //         type: GAME_TABLE_WATCH_CHANNEL_EXIST,
        //         tempTableId: watchTableCheck.openedTableTempTableId,
        //         data: tableToStartDetails,
        //       })
        //     );
        //   }
        // } else {
        //   dispatch(
        //     handleTourneyStart({
        //       tourneys: [tableToStartDetails],
        //       isTourneyLackGame: false,
        //     })
        //   );
        // }
      } else {
        console.log(
          "not opening watch table as tourney id not available tourneyRankingLeaderboardDetails:",
          tourneyRankingLeaderboardDetails
        );
      }
    }
    closeSliderProp();
  };

  const renderTourneyRankingSelectedTab = () => {
    const { rankDataList } = tourneyRankingLeaderboardDetails;
    let originalRanksData = rankDataList;
    console.log("originalRanksData: ", originalRanksData, payoutType);

    return (
      <TourneySelectedTabWrapper>
        <RankingTableWrapper>
          <TableHeaderWrapper>
            <TableHeaderItemWrapper isPlayerName={true}>
              <Labeltext>Players</Labeltext>
            </TableHeaderItemWrapper>
            <TableHeaderItemWrapper>
              <Labeltext>Rank</Labeltext>
            </TableHeaderItemWrapper>
            <TableHeaderItemWrapper>
              <Labeltext>Stack</Labeltext>
            </TableHeaderItemWrapper>
            {/* NOTE: Removing this prize column for this phase-1. IM-2381 */}
            {/* <TableHeaderItemWrapper>
              <Labeltext>Prize</Labeltext>
            </TableHeaderItemWrapper> */}
          </TableHeaderWrapper>

          {originalRanksData &&
            originalRanksData?.length > 0 &&
            originalRanksData.map((eachRankDetails, index) =>
              //one object "eachRankDetails" is received null in a scenario, adding a precautionary check here
              eachRankDetails ? (
                <Fragment key={eachRankDetails.playerName}>
                  <RankingTableRowWrapper
                    isOwnPlayerName={eachRankDetails.playerName === playerName}
                  >
                    <TableRowItemWrapper isPlayerName={true}>
                      <LabelValueText
                        isPlayerName={true}
                        isBustedOutPlayer={eachRankDetails.isBustedOut}
                      >
                        {eachRankDetails.displayName
                          ? eachRankDetails.displayName
                          : eachRankDetails.playerName}
                      </LabelValueText>
                    </TableRowItemWrapper>
                    <TableRowItemWrapper>
                      <LabelValueText
                        isBustedOutPlayer={eachRankDetails.isBustedOut}
                      >
                        {eachRankDetails.rank}
                      </LabelValueText>
                    </TableRowItemWrapper>
                    <TableRowItemWrapper>
                      <LabelValueText
                        isBustedOutPlayer={eachRankDetails.isBustedOut}
                      >
                        {BBDisplay
                          ? parseDecimalValuesOfNumber(
                              eachRankDetails.playerChips / bigBlindValue,
                              1
                            ) + " BB"
                          : parseDecimalValuesOfNumber(
                              eachRankDetails.playerChips,
                              2,
                              checkToDisplayValueInK(
                                eachRankDetails.playerChips
                              ),
                              checkToDisplayValueInL(
                                eachRankDetails.playerChips
                              )
                            )}
                      </LabelValueText>
                    </TableRowItemWrapper>
                    {/* NOTE: Removing this prize column for this phase-1. IM-2381 */}
                    {/* <TableRowItemWrapper>
                      <LabelValueText
                        isBustedOutPlayer={eachRankDetails.isBustedOut}
                      >{`₹${eachRankDetails.winningAmount}`}</LabelValueText>
                    </TableRowItemWrapper> */}
                  </RankingTableRowWrapper>
                  {eachRankDetails.playerName !== playerName &&
                    index < originalRanksData.length - 1 && (
                      <HorizontalSeperatorLine
                        key={`${eachRankDetails.playerName}-horizontal-line`}
                        // hideMargin={true}
                      ></HorizontalSeperatorLine>
                    )}
                </Fragment>
              ) : null
            )}

          {originalRanksData && originalRanksData?.length > 20 && (
            <RankingTableRowWrapper>
              <EntriesText>{`Showing ${originalRanksData?.length} ranks`}</EntriesText>
            </RankingTableRowWrapper>
          )}
        </RankingTableWrapper>
        {originalRanksData.length > 6 && <ScrollDownArrow />}
      </TourneySelectedTabWrapper>
    );
  };

  const renderTourneyPrizeTab = () => {
    const { winnersPayoutWithBonus } = tourneyRankingLeaderboardDetails;
    const positionsList = Object.keys(winnersPayoutWithBonus);
    return (
      <TourneySelectedTabWrapper>
        <TableWrapper>
          <TableHeaderWrapper>
            <TableHeaderItemWrapper>
              <Labeltext>Position</Labeltext>
            </TableHeaderItemWrapper>
            <TableHeaderItemWrapper>
              <Labeltext>Prize</Labeltext>
            </TableHeaderItemWrapper>
          </TableHeaderWrapper>
          {positionsList.map((eachPosition, index) => (
            <Fragment key={eachPosition}>
              <PrizeTableRowWrapper>
                <PrizeItemPositionWrapper>
                  <LabelValueText>{eachPosition}</LabelValueText>
                </PrizeItemPositionWrapper>
                <PrizeItemAmountWrapper>
                  {winnersPayoutWithBonus && (
                    <>
                      <LabelValueText isCurrentBlind={true}>
                        {winnersPayoutWithBonus[eachPosition]?.amount > 0 &&
                          `₹${parseDecimalValuesOfNumber(
                            winnersPayoutWithBonus[eachPosition]?.amount,
                            2,
                            checkToDisplayValueInL(
                              winnersPayoutWithBonus[eachPosition]?.amount
                            ),
                            checkToDisplayValueInL(
                              winnersPayoutWithBonus[eachPosition]?.amount
                            )
                          )}`}
                        {winnersPayoutWithBonus[eachPosition]?.bonus > 0 && (
                          <>
                            {winnersPayoutWithBonus[eachPosition]?.amount >
                              0 && <AddIconText>+</AddIconText>}
                            {`₹${parseDecimalValuesOfNumber(
                              winnersPayoutWithBonus[`${eachPosition}`]?.bonus,
                              2,
                              checkToDisplayValueInL(
                                winnersPayoutWithBonus[`${eachPosition}`]?.bonus
                              ),
                              checkToDisplayValueInL(
                                winnersPayoutWithBonus[`${eachPosition}`]?.bonus
                              )
                            )}
                                  (Bonus)`}
                          </>
                        )}
                        {winnersPayoutWithBonus[eachPosition]?.ticket && (
                          <>
                            {(winnersPayoutWithBonus[`${eachPosition}`]
                              ?.amount > 0 ||
                              winnersPayoutWithBonus[`${eachPosition}`]?.bonus >
                                0) && <AddIconText>+</AddIconText>}
                            <TicketIcon src={goldTicket} />
                          </>
                        )}
                      </LabelValueText>
                    </>
                  )}
                </PrizeItemAmountWrapper>
              </PrizeTableRowWrapper>

              {index < positionsList.length - 1 && (
                <HorizontalSeperatorLine
                  key={`${eachPosition}-line`}
                ></HorizontalSeperatorLine>
              )}
            </Fragment>
          ))}
        </TableWrapper>
        {positionsList.length > 6 && (
          <ScrollDownArrow bottom={prizePoolType === "Dynamic" ? 75 : 26} />
        )}
        {prizePoolType === "Dynamic" && (
          <DynamicPayoutStructureText>{`Note: The prize breakdown may change based on the number of entries.`}</DynamicPayoutStructureText>
        )}
      </TourneySelectedTabWrapper>
    );
  };

  const renderTourneyTablesTab = () => {
    const { tableDetailsForClientList } = tourneyRankingLeaderboardDetails;

    let originalTableDetails = [...tableDetailsForClientList];
    if (originalTableDetails.length > 0) {
      let index = tableDetailsForClientList.findIndex(
        (item) => item["tableID"] === currentActiveGame.tableId
      );

      if (index !== -1) {
        let currentTableDetailsDetails = tableDetailsForClientList[index];
        let updatedTablesList = tableDetailsForClientList.filter(
          (eachRankDetail) =>
            eachRankDetail.tableID !== currentActiveGame.tableId
        );
        originalTableDetails = [
          currentTableDetailsDetails,
          ...updatedTablesList,
        ];
      }
    }
    return (
      <TourneySelectedTabWrapper>
        <TableWrapper>
          <TableHeaderWrapper>
            <TableHeaderItemWrapper isTableName={true}>
              <Labeltext>Table</Labeltext>
            </TableHeaderItemWrapper>
            <TableHeaderItemWrapper>
              <Labeltext>Players</Labeltext>
            </TableHeaderItemWrapper>
            <TableHeaderItemWrapper>
              <Labeltext>Largest</Labeltext>
            </TableHeaderItemWrapper>
            <TableHeaderItemWrapper>
              <Labeltext>Smallest</Labeltext>
            </TableHeaderItemWrapper>
          </TableHeaderWrapper>
          {originalTableDetails.map((eachTable, index) => (
            <Fragment key={eachTable.tableID}>
              <TableRow
                isActive={currentActiveGame.tableId !== eachTable.tableID}
              >
                <TableIdText
                  onClick={(event) => {
                    handleTableNameClick(event, eachTable);
                  }}
                >
                  {`Table #`}
                  <SlicedTourneyIDText>{`${eachTable.tableID?.slice(
                    -2
                  )}`}</SlicedTourneyIDText>
                </TableIdText>

                <TableValueText>{eachTable.numberOfPlayers}</TableValueText>

                <TableValueText>
                  {BBDisplay
                    ? parseDecimalValuesOfNumber(
                        eachTable.highestChips / bigBlindValue,
                        1
                      ) + " BB"
                    : parseDecimalValuesOfNumber(
                        eachTable.highestChips,
                        2,
                        checkToDisplayValueInK(eachTable.highestChips),
                        checkToDisplayValueInL(eachTable.highestChips)
                      )}
                </TableValueText>

                <TableValueText>
                  {BBDisplay
                    ? parseDecimalValuesOfNumber(
                        eachTable.lowestChips / bigBlindValue,
                        1
                      ) + " BB"
                    : parseDecimalValuesOfNumber(
                        eachTable.lowestChips,
                        2,
                        checkToDisplayValueInK(eachTable.lowestChips),
                        checkToDisplayValueInL(eachTable.lowestChips)
                      )}
                </TableValueText>
              </TableRow>
              {index < originalTableDetails - 1 && (
                <HorizontalSeperatorLine
                  key={`${eachTable.tableID}-line`}
                ></HorizontalSeperatorLine>
              )}
            </Fragment>
          ))}
        </TableWrapper>
        {originalTableDetails.length > 6 && <ScrollDownArrow />}
      </TourneySelectedTabWrapper>
    );
  };

  const renderTourneyBlindsTab = () => {
    const { blindStructure } = tourneyRankingLeaderboardDetails;
    return (
      <TourneySelectedTabWrapper>
        <TableWrapper>
          <TableHeaderWrapper>
            <TableHeaderItemWrapper isLevelField={true}>
              <Labeltext>Level</Labeltext>
            </TableHeaderItemWrapper>
            <TableHeaderItemWrapper>
              <Labeltext>SB</Labeltext>
            </TableHeaderItemWrapper>
            <TableHeaderItemWrapper>
              <Labeltext>BB</Labeltext>
            </TableHeaderItemWrapper>
            <TableHeaderItemWrapper>
              <Labeltext>Ante</Labeltext>
            </TableHeaderItemWrapper>
          </TableHeaderWrapper>
          {blindStructure.map((eachBlind, index) => (
            <Fragment key={`level-${eachBlind.level}`}>
              <TableRowWrapper
                disableRow={
                  isFlightTourney
                    ? eachBlind.level >
                      tourneyRankingLeaderboardDetails?.maxLevel
                    : false
                }
              >
                <TableRowItemWrapper isLevelField={true}>
                  <LabelValueText
                    isCurrentBlind={
                      eachBlind.sb == smallBlindValue &&
                      eachBlind.bb == bigBlindValue
                    }
                  >
                    {eachBlind.level}
                  </LabelValueText>
                </TableRowItemWrapper>
                <TableRowItemWrapper>
                  <LabelValueText
                    isCurrentBlind={
                      eachBlind.sb == smallBlindValue &&
                      eachBlind.bb == bigBlindValue
                    }
                  >
                    {parseDecimalValuesOfNumber(
                      eachBlind.sb,
                      2,
                      checkToDisplayValueInK(eachBlind.sb),
                      checkToDisplayValueInL(eachBlind.sb)
                    )}
                  </LabelValueText>
                </TableRowItemWrapper>
                <TableRowItemWrapper>
                  <LabelValueText
                    isCurrentBlind={
                      eachBlind.sb == smallBlindValue &&
                      eachBlind.bb == bigBlindValue
                    }
                  >
                    {parseDecimalValuesOfNumber(
                      eachBlind.bb,
                      2,
                      checkToDisplayValueInK(eachBlind.bb),
                      checkToDisplayValueInL(eachBlind.bb)
                    )}
                  </LabelValueText>
                </TableRowItemWrapper>
                <TableRowItemWrapper>
                  <LabelValueText
                    isCurrentBlind={
                      eachBlind.sb == smallBlindValue &&
                      eachBlind.bb == bigBlindValue
                    }
                  >
                    {parseDecimalValuesOfNumber(
                      eachBlind.ante,
                      2,
                      checkToDisplayValueInK(eachBlind.ante),
                      checkToDisplayValueInL(eachBlind.ante)
                    )}
                  </LabelValueText>
                </TableRowItemWrapper>
              </TableRowWrapper>
              {index < blindStructure.length - 1 && (
                <HorizontalSeperatorLine
                  key={`${eachBlind.level}-line`}
                ></HorizontalSeperatorLine>
              )}
            </Fragment>
          ))}
        </TableWrapper>
        {blindStructure.length > 6 && <ScrollDownArrow />}
      </TourneySelectedTabWrapper>
    );
  };

  const renderSelectedTabDetails = () => {
    switch (selectedTab) {
      case TOURNEY_RANKING_TAB:
        return renderTourneyRankingSelectedTab();
      case TOURNEY_PRIZE_TAB:
        return renderTourneyPrizeTab();
      case TOURNEY_TABLES_TAB:
        return renderTourneyTablesTab();
      case TOURNEY_BLINDS_TAB:
        return renderTourneyBlindsTab();
    }
  };

  return (
    <RankingLeaderboardContainer
      onAnimationEnd={() => {
        if (sliderDirection === "close") {
          dispatch(closeSlider());
        }
        dispatch(updateSliderAnimatingFlag({ value: false }));
      }}
      sliderDirection={sliderDirection}
    >
      <RankingLeaderboardHeaderWrapper>
        <HeaderTitleText>
          {tourneyRankingLeaderboardDetails?.tourneyName}
        </HeaderTitleText>
        <CloseIconWrapper onClick={() => closeSliderProp()}>
          <CloseIcon src={close} />
        </CloseIconWrapper>
      </RankingLeaderboardHeaderWrapper>
      {isTourneyRankingLeaderboardRequestInProgress &&
      !tourneyRankingLeaderboardDetails ? (
        <LoadingWrapper>
          <Loader size={30} />
        </LoadingWrapper>
      ) : tourneyRankingLeaderboardDetails ? (
        <TourneyRankingLeaderBoardScrollableWrapper>
          <RankingLeaderboardDetailsWrapper>
            <TourneyDetailsWrapper>
              <PrizePoolContainer>
                <GoldTrophyIconWrapper src={goldGloryIcon} />
                <PrizeAmountWrapper>
                  <PrizeWorthText>Prizes Worth</PrizeWorthText>
                  <TextWrapper>
                    <PrizeAmountText>
                      {tourneyRankingLeaderboardDetails?.prizePool > 0 &&
                        `₹${parseDecimalValuesOfNumber(
                          tourneyRankingLeaderboardDetails?.prizePool,
                          2,
                          checkToDisplayValueInK(
                            tourneyRankingLeaderboardDetails?.prizePool
                          ),
                          checkToDisplayValueInL(
                            tourneyRankingLeaderboardDetails?.prizePool
                          )
                        )}`}
                    </PrizeAmountText>
                    {tourneyRankingLeaderboardDetails?.prizePoolTickets > 0 && (
                      <>
                        {tourneyRankingLeaderboardDetails?.prizePool > 0 && (
                          <AddIconText>+</AddIconText>
                        )}
                        <TicketsCountText>
                          {tourneyRankingLeaderboardDetails?.prizePoolTickets}
                        </TicketsCountText>
                        <TicketIcon src={goldTicket} />
                      </>
                    )}
                  </TextWrapper>
                  <PrizeAmountText></PrizeAmountText>
                </PrizeAmountWrapper>
              </PrizePoolContainer>
              <TourneyPaidRankAndEntrantsWrapper>
                <PaidItemWrapper>
                  <Labeltext>Winners</Labeltext>
                  <LabelValueText>{`${tourneyRankingLeaderboardDetails?.totalNumberOfWinners}`}</LabelValueText>
                </PaidItemWrapper>
                <VerticalSeperator src={tourneyVerticalSeperator} />

                <YourRankWrapper>
                  <Labeltext>Your Rank</Labeltext>
                  <LabelValueText>
                    {watchPlayer
                      ? "-"
                      : `${tourneyRankingLeaderboardDetails?.playerRank}/${tourneyRankingLeaderboardDetails?.currRegistration}`}
                  </LabelValueText>
                </YourRankWrapper>
                <VerticalSeperator src={tourneyVerticalSeperator} />

                <EntriesItemWrapper>
                  <Labeltext>Entries</Labeltext>
                  <LabelValueText>{`${parseDecimalValuesOfNumber(
                    tourneyRankingLeaderboardDetails?.currRegistration,
                    2,
                    checkToDisplayValueInK(
                      tourneyRankingLeaderboardDetails?.currRegistration
                    ),
                    checkToDisplayValueInL(
                      tourneyRankingLeaderboardDetails?.currRegistration
                    )
                  )}`}</LabelValueText>
                  <ActivePlayersCountDetails>
                    <PlayerIconWrapper src={maskPlayerIcon} />
                    <ActivePlayerCountText>
                      {`${parseDecimalValuesOfNumber(
                        tourneyRankingLeaderboardDetails?.qualifiedPlayersCount,
                        2,
                        checkToDisplayValueInK(
                          tourneyRankingLeaderboardDetails?.qualifiedPlayersCount
                        ),
                        checkToDisplayValueInL(
                          tourneyRankingLeaderboardDetails?.qualifiedPlayersCount
                        )
                      )}`}
                    </ActivePlayerCountText>
                    <RemainingText>Active</RemainingText>
                  </ActivePlayersCountDetails>
                </EntriesItemWrapper>
              </TourneyPaidRankAndEntrantsWrapper>
              {((tourneyRankingLeaderboardDetails &&
                Object.keys(tourneyRankingLeaderboardDetails?.addonSettings)
                  .length > 0) ||
                Object.keys(tourneyRankingLeaderboardDetails?.rebuySettings)
                  .length > 0) && (
                <HorizontalSeperatorLine></HorizontalSeperatorLine>
              )}
              <AddonsAndRebuysWrapper>
                {tourneyRankingLeaderboardDetails &&
                  Object.keys(tourneyRankingLeaderboardDetails?.addonSettings)
                    .length > 0 && (
                    <AddOnsWrapper>
                      <TotalAddOnsWrapper>
                        <Labeltext>Total Add-ons</Labeltext>
                        <LabelValueText className={"value"}>
                          {tourneyRankingLeaderboardDetails?.totalAddOnCount}
                        </LabelValueText>
                      </TotalAddOnsWrapper>
                      <AddOnFeeWrapper>
                        <Labeltext className={"value"}>Add-on fee</Labeltext>
                        <LabelValueText className={"value"}>
                          {`₹${tourneyRankingLeaderboardDetails?.addonSettings.addonEntryFee}`}
                        </LabelValueText>
                      </AddOnFeeWrapper>
                    </AddOnsWrapper>
                  )}
                {Object.keys(tourneyRankingLeaderboardDetails?.rebuySettings)
                  .length > 0 && (
                  <>
                    <RebuyWrapper>
                      <TotalRebuysWrapper>
                        <Labeltext>Total Re-buys</Labeltext>
                        <LabelValueText className={"value"}>
                          {tourneyRankingLeaderboardDetails?.totalRebuyCount}
                        </LabelValueText>
                      </TotalRebuysWrapper>
                      <RebuyFeeWrapper>
                        <Labeltext>Re-buy fee</Labeltext>
                        <LabelValueText className={"value"}>
                          {`₹${tourneyRankingLeaderboardDetails?.rebuySettings.rebuyEntryFee}`}
                        </LabelValueText>
                      </RebuyFeeWrapper>
                    </RebuyWrapper>
                    <HorizontalSeperatorLine></HorizontalSeperatorLine>
                    {tourneyRankingLeaderboardDetails?.rebuySettings
                      .noOfRebuys > 0 && (
                      <RebuyDescriptionText>
                        {`${tourneyRankingLeaderboardDetails?.rebuySettings.noOfRebuys} re-buys allowed till Level ${tourneyRankingLeaderboardDetails?.rebuySettings.rebuyLevel}`}
                      </RebuyDescriptionText>
                    )}
                  </>
                )}

                {Object.keys(tourneyRankingLeaderboardDetails?.reEntrySettings)
                  .length > 0 &&
                  tourneyRankingLeaderboardDetails?.reEntrySettings
                    ?.noOfReentry > 0 && (
                    <>
                      <RebuyWrapper>
                        <TotalRebuysWrapper>
                          <Labeltext>Total Re-Entries</Labeltext>
                          <LabelValueText className={"value"}>
                            {tourneyRankingLeaderboardDetails?.totalReEntry}
                          </LabelValueText>
                        </TotalRebuysWrapper>
                        <RebuyFeeWrapper>
                          <Labeltext>Re-entry fees</Labeltext>
                          <LabelValueText className={"value"}>
                            {`₹${tourneyRankingLeaderboardDetails?.reEntrySettings?.reentryEntryFee}`}
                          </LabelValueText>
                        </RebuyFeeWrapper>
                      </RebuyWrapper>
                    </>
                  )}

                {tourneyRankingLeaderboardDetails?.reEntrySettings
                  ?.noOfReentry > 0 && (
                  <>
                    <HorizontalSeperatorLine></HorizontalSeperatorLine>
                    <RebuyDescriptionText>
                      {`${tourneyRankingLeaderboardDetails?.reEntrySettings.noOfReentry} re-entries allowed till Level ${tourneyRankingLeaderboardDetails?.reEntrySettings.reentryLevel}`}
                    </RebuyDescriptionText>
                  </>
                )}
              </AddonsAndRebuysWrapper>
            </TourneyDetailsWrapper>
            <TourneyLevelsAndBlindsContainer>
              <TourneyBlindsHeadingWrapper>
                <BlindGreySingleChipIcon src={greySingleChip} />
                <TourneySectionTitleText>Blinds</TourneySectionTitleText>
              </TourneyBlindsHeadingWrapper>
              <LevelWrapper>
                <CurrentLevelWrapper>
                  <LevelHeadingLabel>{`Current Level (${tourneyRankingLeaderboardDetails?.currentLevel})`}</LevelHeadingLabel>
                  <LevelText>{`${tourneyRankingLeaderboardDetails?.currentLevel}/${tourneyRankingLeaderboardDetails?.maxLevel}`}</LevelText>
                </CurrentLevelWrapper>

                <NextLevelWrapper>
                  <LevelHeadingLabel>Next Level</LevelHeadingLabel>
                  {tourneyRankingLeaderboardDetails?.nextLevel >
                  tourneyRankingLeaderboardDetails?.maxLevel ? (
                    <LevelText>-</LevelText>
                  ) : (
                    <LevelText>{`${tourneyRankingLeaderboardDetails?.nextLevel}/${tourneyRankingLeaderboardDetails?.maxLevel}`}</LevelText>
                  )}
                </NextLevelWrapper>
                {tourneyRankingLeaderboardDetails?.nextBlindIn >= 0 && (
                  <BlindsupWrapper>
                    <Labeltext>Blinds up in </Labeltext>

                    <BlindsUpTimeText>
                      {`${
                        tourneyRankingLeaderboardDetails?.nextBlindIn === 0
                          ? "<1"
                          : tourneyRankingLeaderboardDetails?.nextBlindIn
                      } m`}
                      <GoldUpArrowIcon src={GoldUpArrow} />
                    </BlindsUpTimeText>
                  </BlindsupWrapper>
                )}
              </LevelWrapper>
            </TourneyLevelsAndBlindsContainer>

            <ChipsContainer>
              <ChipsHeadingWrapper>
                <GreyChipsIcon src={greyMultipleChips} />
                <TourneySectionTitleText>Chips</TourneySectionTitleText>
              </ChipsHeadingWrapper>
              <TourneyStatsWrapper>
                <StatsItemWrapper>
                  <Labeltext>Largest</Labeltext>
                  <LabelValueText>{`${
                    BBDisplay
                      ? parseDecimalValuesOfNumber(
                          tourneyRankingLeaderboardDetails?.highestChipStack /
                            bigBlindValue,
                          1
                        ) + " BB"
                      : parseDecimalValuesOfNumber(
                          tourneyRankingLeaderboardDetails?.highestChipStack,
                          2,
                          checkToDisplayValueInK(
                            tourneyRankingLeaderboardDetails?.highestChipStack
                          ),
                          checkToDisplayValueInL(
                            tourneyRankingLeaderboardDetails?.highestChipStack
                          )
                        )
                  }`}</LabelValueText>
                </StatsItemWrapper>

                <StatsItemWrapper>
                  <Labeltext>Average</Labeltext>
                  <LabelValueText>{`${
                    BBDisplay
                      ? parseDecimalValuesOfNumber(
                          tourneyRankingLeaderboardDetails?.averageChipStack /
                            bigBlindValue,
                          1
                        ) + " BB"
                      : parseDecimalValuesOfNumber(
                          tourneyRankingLeaderboardDetails?.averageChipStack,
                          2,
                          checkToDisplayValueInK(
                            tourneyRankingLeaderboardDetails?.averageChipStack
                          ),
                          checkToDisplayValueInL(
                            tourneyRankingLeaderboardDetails?.averageChipStack
                          )
                        )
                  }`}</LabelValueText>
                </StatsItemWrapper>

                <StatsItemWrapper>
                  <Labeltext>Smallest</Labeltext>
                  <LabelValueText>{`${
                    BBDisplay
                      ? parseDecimalValuesOfNumber(
                          tourneyRankingLeaderboardDetails?.lowestChipStack /
                            bigBlindValue,
                          1
                        ) + " BB"
                      : parseDecimalValuesOfNumber(
                          tourneyRankingLeaderboardDetails?.lowestChipStack,
                          2,
                          checkToDisplayValueInK(
                            tourneyRankingLeaderboardDetails?.lowestChipStack
                          ),
                          checkToDisplayValueInL(
                            tourneyRankingLeaderboardDetails?.lowestChipStack
                          )
                        )
                  }`}</LabelValueText>
                </StatsItemWrapper>
              </TourneyStatsWrapper>
            </ChipsContainer>

            {tourneyRankingLeaderboardDetails?.lateReg &&
              !tourneyRankingLeaderboardDetails?.lateRegEnded && (
                <LateRegistrationWrapper>
                  <DotIcon src={OrangeCircle} />
                  <LateRegistrationText>
                    {`Late registration available for ${tourneyRankingLeaderboardDetails?.lateRegEndTimeInMin} min`}
                  </LateRegistrationText>
                </LateRegistrationWrapper>
              )}
            {tourneyRankingLeaderboardDetails?.lateRegEnded && (
              <LateRegistrationWrapper>
                <DotIcon src={OrangeCircle} />
                <LateRegistrationText>{`Late registration ended.`}</LateRegistrationText>
              </LateRegistrationWrapper>
            )}
          </RankingLeaderboardDetailsWrapper>
          <TabbarAndTabSectionContainer>
            <TabbarWrapper>
              <Tabbar
                key={selectedTab}
                isInfiniteTabbar={true}
                slidesPerView={4}
                tabItems={getTourneyRankingDetailsTabs()}
                selectedTab={selectedTab}
                onTabChange={handleTourneysTabChange}
              />
            </TabbarWrapper>
            {renderSelectedTabDetails()}
          </TabbarAndTabSectionContainer>
        </TourneyRankingLeaderBoardScrollableWrapper>
      ) : (
        <></>
      )}
    </RankingLeaderboardContainer>
  );
}

export default TourneyRankingLeaderboard;
