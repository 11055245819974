import { useEffect, useRef } from "react";
import store from "../redux/store";

export function getPlayerObject() {
  let lobbySlice = getLobbySlice();
  return lobbySlice.player;
}

export function getPlayerDisplayName() {
  let lobbySlice = getLobbySlice();
  try {
    let displayName = lobbySlice?.displayName;
    return displayName && displayName !== ''
      ? displayName
      : lobbySlice?.player?.userName;
  } catch (error) {
    console.error("Error retrieving player display name:", error);
    return lobbySlice?.player?.userName; // Default fallback
  }
}
export function getLobbySlice() {
  return getReduxState()["lobby"];
}

export function getGameSlice() {
  return getReduxState()["gameTable"];
}

export function getModalSlice() {
  //being used in lobby modals
  return getReduxState()["modal"];
}

function getReduxState() {
  return store.getState();
}

export function useTraceUpdate(props, component) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log(`Changed props in component: ${component} -->`, changedProps);
    }
    prev.current = props;
  });
}
export function getDisplayName(userName, playerDisplayNameMap, loggedPlayer,displayName) {
  return loggedPlayer?.userName === userName
    ? displayName || loggedPlayer.userName
    : playerDisplayNameMap[userName] || userName;
}
// export function getDisplayNamefromMap(userName, playerDisplayNameMap) {
//   return playerDisplayNameMap[userName] || userName;
// }
