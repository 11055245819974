import styled from "styled-components";
import colors from "../../../styleGuide/Colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: var(--app-height);
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  transform: translate(5%, -10%);
  button {
    margin-bottom: 5%;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 20vh;
  font-size: 12px;
  color: ${colors.whiteTwo};
  background: #0f1d22;
  border: 0;
  outline: 0;
  padding: 3%;
  font-family: "Conto";
  ::placeholder {
    color: ${colors.pebbleGray};
  }
  :disabled {
    pointer-events: none;
  }
`;

export const AnnouncementMessage = styled.div`
  color: ${colors.whiteTwo};
  background: #0f1d22;
  font-size: 12px;
  text-align: left;
  width: 100%;
  min-height: 40px;
  max-height: 20vh;
  height: auto;
  padding: 3%;
  line-height: 20px;
`;
