import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closePrivateTableSliders,
  updateSliderAnimatingFlag,
} from "../../../redux/slices/sliderSlice";
import { Header } from "../createHostingDetailsStyle";
import { ButtonWrapper, Icon } from "../privateTableSliderStyle";
import {
  AccessCodeInputField,
  CodeContainer,
  Container,
  GoodTimesImg,
  GoodTimesWrapper,
  InputContainer,
} from "./enterCodeToJoinTableStyles";
import closeIcon from "../../../../assets/lobby/CloseIcon.svg";
import goodTimes from "../../../../assets/lobby/goodTimes.png";
import {
  Typo12GrayContoMediumText,
  TypoGoldGradientMediumText,
} from "../../../../Common/styleGuide/Typos";
import {
  INPUT_TYPE_NUMBER,
  MAX_TABLES_ALLOWED,
  MAX_TABLE_LIMIT,
  NUMBER_OF_DIGITS_IN_ACCESS_CODE,
} from "../../../data/Constants";
import Button from "../../../../Common/components/Button/Button";
import {
  privateTableJoinRequest,
  resetInitiateMatchmakingForGdid,
} from "../../../redux/slices/lobbySlice";
import {
  createTableData,
  showGameTable,
} from "../../../redux/slices/gameTableSlice";
import { generateUniqueId } from "../../../../Common/utils/PokerUtils";
import { isIOS } from "react-device-detect";
import { openModal } from "../../../redux/slices/modalSlice";

export const EnterCodeToJoinTable = (props) => {
  const { sliderDirection, config } = props;
  const [inputAccessCode, setInputAccessCode] = useState(
    new Array(NUMBER_OF_DIGITS_IN_ACCESS_CODE).fill("")
  );
  const [accessCode, setAccssCode] = useState("");
  const [disableJoinBtn, setDisableJoinBtn] = useState(true);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const accessToken = useSelector((state) => state.lobby.accessToken);
  const playerId = useSelector((state) => state.lobby.player.userId);
  const navigateToGameTable = useSelector(
    (state) => state.lobby.privateTable.navigateToGameTable
  );
  const initiateMatchmakingForGdid = useSelector(
    (state) => state.lobby.privateTable?.initiateMatchmakingForGdid
  );

  const gameDefinition = useSelector(
    (state) =>
      state.lobby.privateTable.tableDetails?.[initiateMatchmakingForGdid]
        ?.gameDefinition
  );
  const defaultConfig = useSelector(
    (state) => state.lobby.privateTable.configs.defaultConfig
  );

  const isJoinTableResponseReceived = useSelector(
    (state) => state.lobby.privateTable.isJoinTableResponseReceived
  );

  const activeGames = useSelector((state) => state.gameTable.games);
  const inputRefs = useRef([]);
  const dispatch = useDispatch();

  const handleSliderClose = () => {
    dispatch(closePrivateTableSliders());
  };

  useEffect(() => {
    return () => {
      inputRefs.current = [];
    };
  }, []);

  useEffect(() => {
    if (navigateToGameTable) {
      handleSliderClose();
      dispatch(showGameTable());
      dispatch(resetInitiateMatchmakingForGdid());
    }
  }, [navigateToGameTable]);

  useEffect(() => {
    if (
      inputAccessCode &&
      inputAccessCode.join("").length === NUMBER_OF_DIGITS_IN_ACCESS_CODE
    ) {
      setAccssCode(inputAccessCode.join(""));
      setDisableJoinBtn(false);
    } else {
      setDisableJoinBtn(true);
    }
  }, [inputAccessCode]);

  useEffect(() => {
    if (initiateMatchmakingForGdid && gameDefinition) {
      dispatch(
        createTableData({
          tempTableId: generateUniqueId(),
          gameDefinition: {
            gameDefId: initiateMatchmakingForGdid,
            ...gameDefinition,
            ...defaultConfig,
          },
          watchPlayer: true,
          isPrivateTable: true,
        })
      );

      dispatch(closePrivateTableSliders());
      dispatch(resetInitiateMatchmakingForGdid());
    }
  }, [initiateMatchmakingForGdid, gameDefinition]);

  useEffect(() => {
    setDisableJoinBtn(!isJoinTableResponseReceived);
  }, [isJoinTableResponseReceived]);

  const onInputChange = (index, e) => {
    const value = e.target.value.replace(/\D/g, ""); // Allow only numbers
    if (value) {
      const newAccessCode = [...inputAccessCode];
      newAccessCode[index] = value.charAt(0);
      setInputAccessCode(newAccessCode);
      if (
        index < NUMBER_OF_DIGITS_IN_ACCESS_CODE &&
        inputRefs.current[index + 1]
      ) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const onInputBlur = (e) => {
    setAccssCode(inputAccessCode.join(""));
    setIsInputFocused(false);
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace") {
      const newAccessCode = [...inputAccessCode];
      if (inputAccessCode[index]) {
        newAccessCode[index] = "";
      } else if (index > 0) {
        newAccessCode[index - 1] = "";
        inputRefs.current[index - 1].focus();
      }

      setInputAccessCode(newAccessCode);
    } else if (
      e.key === "Enter" &&
      inputAccessCode.join("").length === NUMBER_OF_DIGITS_IN_ACCESS_CODE
    ) {
      joinTableClickHandler();
    }
  };

  const createAccessCodeInputField = () => {
    return (
      <InputContainer>
        {inputAccessCode.map((digit, index) => (
          <AccessCodeInputField
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            type={INPUT_TYPE_NUMBER}
            inputMode={"numeric"}
            placeholder={"."}
            maxLength={1}
            value={digit}
            onChange={(e) => onInputChange(index, e)}
            onBlur={(e) => onInputBlur(e)}
            onFocus={() => setIsInputFocused(true)}
            onKeyDown={(e) => handleKeyDown(index, e)}
          />
        ))}
      </InputContainer>
    );
  };

  const joinTableClickHandler = () => {
    if (Object.keys(activeGames).length === MAX_TABLES_ALLOWED) {
      dispatch(
        openModal({
          type: MAX_TABLE_LIMIT,
        })
      );
      return;
    }

    dispatch(
      privateTableJoinRequest({
        apiURL: process.env.REACT_APP_JOIN_PRIVATE_TABLE,
        payload: {
          userId: playerId,
          entryCode: accessCode,
        },
        headers: {
          headers: {
            Authorization: accessToken,
          },
        },
      })
    );
    setInputAccessCode(new Array(NUMBER_OF_DIGITS_IN_ACCESS_CODE).fill(""));
  };
  
  return (
    <Container
      onAnimationEnd={() => {
        if (sliderDirection === "close") {
          handleSliderClose();
        }
        dispatch(updateSliderAnimatingFlag({ value: false }));
      }}
      sliderDirection={sliderDirection}
      isInputFocused={!isIOS && isInputFocused}
    >
      <Header>
        Join a Table
        <Icon
          src={closeIcon}
          right={15}
          width={14}
          onClick={() => {
            handleSliderClose();
          }}
        ></Icon>
      </Header>
      <CodeContainer>
        <GoodTimesWrapper>
          <GoodTimesImg src={goodTimes}></GoodTimesImg>
          <TypoGoldGradientMediumText size={16}>
            Good times are 1 step away
          </TypoGoldGradientMediumText>
        </GoodTimesWrapper>
        <Typo12GrayContoMediumText>
          Enter the 6 digit code to join
        </Typo12GrayContoMediumText>
        {createAccessCodeInputField()}
        <ButtonWrapper>
          <Button
            buttonText={"Join Table"}
            clickHandler={joinTableClickHandler}
            disabled={disableJoinBtn}
          ></Button>
        </ButtonWrapper>
      </CodeContainer>
    </Container>
  );
};
