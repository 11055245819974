import styled from "styled-components";
export const Container = styled.div`
  position: absolute;
  bottom: 0;
  height: ${(props) =>
    props.showGoToTableButton ? 85 : props.isCompletedTable ? 70 : 75}vh;
  width: 100%;
  animation: ${(props) =>
    props.sliderDirection === "open"
      ? "themes-move-up forwards 0.25s"
      : "themes-move-down forwards 0.25s"};
  background: rgba(4, 42, 52, 1);
`;

export const GameTileContainer = styled.div`
  padding: 0 3%;
`;

export const Header = styled.div`
  background: rgba(4, 42, 52, 1);
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  height: 54px;
  color: white;
  font-weight: 700;
  font-size: 16px;
  position: relative;
`;

export const TabbarWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 52px;
  min-height: 52px;
  .swiper .swiper-wrapper .swiper-slide {
    width: 50% !important;
  }
  .tab-item-container {
    height: 52px;
    min-height: 52px;
  }
  .tab-item {
    width: 100%;
    height: 52px;
    min-height: 52px;
  }
`;

export const InfoContainer = styled.div`
  padding: 3%;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.justifyCenter ? "center" : "space-between"};
  margin-top: ${(props) => (props.label ? "2px" : "4%")};
  button {
    position: absolute;
    bottom: 25px;
  }
`;

export const Column = styled.div`
  width: 47%;
  line-height: 20px;
  height: 65px;
  padding: 2% 3%;
  border-radius: 4px;
  background: linear-gradient(0deg, #075063, #075063),
    linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
`;
